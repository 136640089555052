import { FeatureConfig } from "./environment.common";
export const defaultTextResource: FeatureConfig["text"]["default"] = {
    signUpTitle: "Create Account",
    signupInstruction: "",
    signupAction: "Get Started",
    loginTitle: "Log in",
    loginDescription: "",
    loginAction: "Login",
    loginFooter: "",
    createPasswordTitle: "Set your password",
    createPasswordDescription: "",
    createPasswordAction: "Continue",
    verifyEmailTitle: "We've sent you an email!",
    verifyEmailDescription: "To allow us to verify your identity please click on the verification link sent to ",
    verifyMobileTitle: "Verify your identity",
    verifyMobileDescription: "Enter the code we sent to your mobile below to finalise your profile",
    verifyMobileAction: "SEND PIN",
    verifyMobileFooter:
        "Your mobile number will not be shared with third parties or used for marketing communications.",
    verifyCodeTitle: "Verify your identity",
    verifyCodeDescription: "We've sent a PIN to your mobile, enter it below to continue.",
    verifyCodeFooter: "Once verified, you'll be taken to your standard log in screen.",
    verifyCodeAction: "Verify",
    notInvitedToMeeting: "You are not in this meeting.", // TODO: Get James to give us some nicer text, or add a button to request an invite
};
