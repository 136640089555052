import { Pipe, PipeTransform } from "@angular/core";
import { ITimeline, IParticipant } from "@visoryplatform/threads";
import { IThreadGroupListing } from "../components/thread-list/thread-grouper";

@Pipe({ name: "threadsSearch", pure: true })
export class ThreadsSearchPipe implements PipeTransform {
    transform(groups: IThreadGroupListing[], searchTerm: string): IThreadGroupListing[] {
        if (!searchTerm || !groups) {
            return groups;
        }

        return groups.map((group) => {
            return {
                ...group,
                children: group.children.filter((thread) => this.searchThread(thread, searchTerm.toLowerCase())),
            };
        });
    }

    private searchThread(thread: ITimeline, term: string): boolean | IParticipant {
        const threadContains =
            (thread.title && thread.title.toLowerCase().includes(term)) ||
            (thread.type && thread.type.toLowerCase().includes(term)) ||
            thread?.account?.label?.toLowerCase().includes(term) ||
            (thread.participants &&
                thread.participants.find((participant) => this.searchParticipant(participant, term)));

        return threadContains;
    }

    private searchParticipant(participant: IParticipant, term: string): boolean {
        if (!participant || !participant.profile) {
            return false;
        }

        const { name, emailAddress } = participant.profile;
        return (
            (name && name.toLowerCase().includes(term)) || (emailAddress && emailAddress.toLowerCase().includes(term))
        );
    }
}
