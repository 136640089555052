export enum PreferenceMethodFrequency {
    Frequent = "frequent",
    Daily = "daily",
    Weekly = "weekly"
}

export interface DigestPreferences {
    enabled: boolean;
    frequency: PreferenceMethodFrequency;
    dayOfWeek: number;
    hourOfDay: number;
}

type PossiblePreferenceMethods = DigestPreferences;

export interface RecipientSettings {
    channelPrefix: string;
    recipientId: string;
    preference: Record<string, PossiblePreferenceMethods>
}