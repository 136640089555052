import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    Account,
    AccountMetadata,
    CreateAccountPayload,
    IAccountListing,
    IThreadListing,
    IntegrationTypes,
} from "@visoryplatform/threads";
import { EMPTY, Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { expand, map, toArray } from "rxjs/operators";
import { IPaginated } from "@visoryplatform/datastore-types";
import { IPaginatorSort } from "../../fx-table/components/fx-paginator/fx-paginator.component";

@Injectable({ providedIn: "root" })
export class AccountsService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    getAccount(accountId: string): Observable<Account> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}/${accountId}`;
        return this.http.get<Account>(url);
    }

    createAccount(payload: CreateAccountPayload): Observable<Account> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/account`;
        return this.http.post<Account>(url, payload);
    }

    renameAccountLabel(accountId: string, newAccountLabel: string): Observable<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/label`;

        return this.http.put<void>(url, { newAccountLabel });
    }

    toggleInsights(accountId: string, enabled: boolean): Promise<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/insights`;

        return this.http.post<void>(url, { enabled }).toPromise();
    }

    refreshPeriod(accountId: string, periodStart: string, periodEnd: string): Promise<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/insights/periods/refresh`;

        return this.http.post<void>(url, { periodStart, periodEnd }).toPromise();
    }

    refreshLatestPeriods(accountId: string, numPeriods: number): Promise<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/insights/periods/refresh`;

        return this.http.post<void>(url, { numPeriods }).toPromise();
    }

    updateAccountMetadata(accountId: string, accountMetadata: any): Observable<AccountMetadata> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/metadata`;

        return this.http.put<AccountMetadata>(url, { accountMetadata });
    }

    listAccountsByContact(contactId: string, next?: string, limit?: number): Observable<IPaginated<Account>> {
        const { contacts, accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${contacts}/${contactId}${accounts}`;
        const params = { next: next || "", limit: limit || "" };

        return this.http.get<IPaginated<Account>>(url, { params });
    }

    listAccounts(
        next?: string,
        limit?: number,
        sort?: IPaginatorSort,
        searchFilter?: string,
    ): Observable<IPaginated<IAccountListing>> {
        const { accounts } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${accounts}`;

        const sortBy = sort?.sort || "";
        const order = sort?.order || "";

        const params = {
            next: next || "",
            limit: limit || "",
            sortBy,
            order,
            searchFilter: searchFilter ? encodeURIComponent(searchFilter) : "",
        };

        return this.http.get<IPaginated<IAccountListing>>(url, { params });
    }

    listThreads(accountId: string, next?: string, limit?: number): Observable<IPaginated<IThreadListing>> {
        const { accounts, threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const baseUrl = `${base}${accounts}/${accountId}${threads}`;
        const queryParams = { next, limit: limit?.toString() };
        const url = this.appendQuery(queryParams, baseUrl);
        return this.http.get<IPaginated<IThreadListing>>(url);
    }

    listAllThreads(accountId: string): Observable<IThreadListing[]> {
        return this.listThreads(accountId).pipe(
            expand((page) => (page?.next ? this.listThreads(accountId, page.next, page.limit) : EMPTY)),
            map((page) => page.result),
            toArray(),
            map((result) => result.flat().filter((thread) => !!thread)),
        );
    }

    listAllAccounts(): Observable<IAccountListing[]> {
        return this.listAccounts(null, 50).pipe(
            expand((page) => (page?.next ? this.listAccounts(page.next, 50) : EMPTY)),
            map((page) => page?.result),
            toArray(),
            map((pages) => pages.flat()),
        );
    }

    listAllAccountsByContact(contactId: string): Observable<IAccountListing[]> {
        return this.listAccountsByContact(contactId, null, 50).pipe(
            expand((page) => (page?.next ? this.listAccountsByContact(contactId, page.next, 50) : EMPTY)),
            map((page) => page?.result),
            toArray(),
            map((pages) => pages.flat()),
        );
    }

    getConnectedIntegrations(account: Account): IntegrationTypes[] {
        const integrations = account?.metadata?.integrations;

        if (!integrations) {
            return [];
        }

        const mappedIntegrations = Object.entries(account?.metadata?.integrations);
        const connectedIntegrations = mappedIntegrations
            .filter(([, integration]) => integration?.token !== null)
            .map(([integration]) => integration as IntegrationTypes);

        return connectedIntegrations;
    }

    private appendQuery(filterParams: Record<string, string>, baseUrl: string): string {
        if (!filterParams) {
            return baseUrl;
        }

        const params = Object.entries(filterParams)
            .filter(([, value]) => value != null)
            .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`);

        return `${baseUrl}?${params.join("&")}`;
    }
}
