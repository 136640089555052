<ng-container *ngIf="card$ | async as card">
    <thread-card
        *ngIf="userId$ | async; let userId"
        title="{{ cardTitle$ | async }}"
        [thread]="thread$ | async"
        [card]="card"
        [replies]="replies$ | async"
        [invertTheme]="userId === card.createdBy"
        [loading]="loading"
        [edited]="card.status === cardStatuses.Edited"
        [isInternal]="card.isInternal"
        [edit]="edit$"
        (save)="save($event)"
    >
        <payment-details
            [packagePriceDetails]="packagePriceDetails$ | async"
            [cardState]="state$ | async"
            [accountId]="(thread$ | async)?.accountId"
            [card]="card"
            [role]="role"
            (makePayment)="action($event)"
        >
        </payment-details>
        <div
            card-actions
            *ngIf="allowEdit && role | permissions : 'DeletePaymentCard' | async"
        >
            <fx-context-menu
                #menu
                [gutter]="false"
                *ngIf="(card$ | async).createdBy === userId"
            >
                <a
                    [analyticsClick]="gaEvents.PAYMENTCARD_EDIT"
                    (click)="editMessage()"
                    >Edit</a
                >
                <a
                    [analyticsClick]="gaEvents.PAYMENTCARD_CANCEL"
                    (click)="cancelCard()"
                    *ngIf="isCancelable$ | async"
                    >Cancel</a
                >
            </fx-context-menu>
        </div>
        <div card-loader>
            <loader-icon color="light"></loader-icon>
        </div>
        <div
            class="thread-card-error"
            *ngIf="(state$ | async)?.isFailed && card?.status !== cardStatuses.Disabled"
        >
            Your payment failed to process. Please complete the payment process to try again
        </div>
        <div
            class="thread-card-error"
            *ngIf="errorMessage"
        >
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
