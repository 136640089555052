import { IVariationLabel, RoleAssigneeVariations } from "@visoryplatform/threads";
import { Pipe, PipeTransform } from "@angular/core";

import {
    ConfigTokenExtensionToken,
    MonthEndExtensionToken,
    WorkTimeExtensionToken,
} from "@visoryplatform/workflow-core";

@Pipe({ name: "workflowVariationStepLabel" })
export class WorkflowVariationStepLabelPipe implements PipeTransform {
    private roleVariationLabel = "Role automation";
    private variationLabels = {
        [ConfigTokenExtensionToken.SLA_VARIATION]: "SLA variation",
        [ConfigTokenExtensionToken.RFI_CARD_VARIATION]: "RFI card variation",
        [ConfigTokenExtensionToken.INSTRUCTIONS_CARD_VARIATION]: "Instruction card variation",
        ...this.createVariationMap(MonthEndExtensionToken, "SLA variation"),
        ...this.createVariationMap(WorkTimeExtensionToken, "Work time multiple variation"),
    };

    constructor() {
        const roleVariations = this.createVariationMap(RoleAssigneeVariations, this.roleVariationLabel, true);
        this.variationLabels = { ...this.variationLabels, ...roleVariations };
    }

    transform(variation: IVariationLabel): string {
        const tokenType: string = this.variationLabels[variation.token];

        if (!tokenType) {
            console.warn(`No variation label found for config token: ${variation.token}, ensure config token is setup`);
            return "";
        }

        const variationLabel = `${tokenType} - ${variation.label}`;
        return variationLabel;
    }

    private createVariationMap(source: object, label: string, useValues = false): Record<string, string> {
        const keys = useValues ? Object.values(source) : Object.keys(source);
        return keys.reduce<Record<string, string>>(
            (acc, key) => ({
                ...acc,
                [key]: label,
            }),
            {},
        );
    }
}
