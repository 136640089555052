import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import {
    Component,
    ComponentRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";

@Component({
    selector: "view-extension",
    templateUrl: "./view-extension.component.html",
    styleUrls: ["./view-extension.component.scss"],
})
export class ViewExtensionComponent implements OnChanges {
    @Input() componentRef: ComponentType<unknown>;
    @Input() injector: Injector;
    @Output() componentMounted = new EventEmitter<ComponentRef<unknown>>();

    mounted: ComponentPortal<unknown>;

    ngOnChanges(changes: SimpleChanges): void {
        const { componentRef, injector } = changes;

        if (componentRef || injector) {
            this.mounted = this.getMounted(this.componentRef, this.injector);
        }
    }

    onComponentRendering(ref): void {
        this.componentMounted.emit(ref);
    }

    private getMounted(component: ComponentType<unknown>, injector: Injector): ComponentPortal<unknown> {
        if (!component || !injector) {
            return null;
        }

        return new ComponentPortal(component, null, injector);
    }
}
