import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    EnvironmentSpecificConfig,
    environmentCommon,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";

type EnrichedConfigurationAccount = { id: string; label: string };

@Injectable({
    providedIn: "root",
})
export class ConfigurationsFilterService {
    constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    getUserConfigurationsFilters(userId: string): Observable<EnrichedConfigurationAccount[]> {
        const { base } = this.environment.commonEndpoints;
        const { users, replaceConfigurations, filters } = environmentCommon.threadsEndpoints;
        const url = `${base}${users}${replaceConfigurations}${filters}`;

        const params = {
            userId,
        };

        return this.httpClient.get<EnrichedConfigurationAccount[]>(url, { params });
    }
}
