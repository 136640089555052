<div class="fx-onboarding-back">
    <button
        class="fx-onboarding-back-button"
        (click)="closeDialog(false)"
    >
        <i class="fx-onboarding-back-icon las la-times"></i>
    </button>
</div>
<change-password-form
    [userDetails]="userDetails"
    (passwordUpdated)="closeDialog(true)"
></change-password-form>
