import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormRecord, Validators } from "@angular/forms";
import {
    IThreadCard,
    CardReply,
    ITimeline,
    BillApprovalState,
    BillApprovalUpdateType,
    BillApprovalUpdate,
    Role,
    BillApprovalUpdatedItem,
    InvoiceItem,
} from "@visoryplatform/threads";
import {
    RequestActionButtonLabel,
    RequestStatuses,
} from "projects/default-plugins/vault/components/request/constants/request.constants";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, Observable, Subscription } from "rxjs";
import {
    switchMap,
    shareReplay,
    take,
    pairwise,
    scan,
    startWith,
    map,
    filter,
    distinctUntilChanged,
} from "rxjs/operators";
import { BillApprovalService } from "../../services/bill-approval.service";
import { Invoice } from "@visoryplatform/copilot";
import {
    BillApprovalModalData,
    BillApprovalFormItem,
    BillApprovalForm,
    BillApprovalDatePickerFormControl,
    BILL_APPROVAL_DATE_FORMAT,
} from "../../interfaces/BillApproval";
import { BillApprovalFormStateService } from "../../services/bill-approval-form-state.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { DateTime } from "luxon";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "edit-bill-approval",
    templateUrl: "./edit-bill-approval.component.html",
    styleUrls: ["./edit-bill-approval.component.scss"],
})
export class EditBillApprovalComponent implements OnInit, OnDestroy {
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;
    readonly gaEvents = GA_EVENTS;

    replyMessage = new FormControl("");
    dialogRef: DialogRef;
    modalData: BillApprovalModalData;

    card$: Observable<IThreadCard>;
    form$: Observable<FormGroup<BillApprovalForm>>;
    state$: Observable<BillApprovalState>;
    thread$: Observable<ITimeline>;
    userId$: Observable<string>;
    replies$: Observable<CardReply[]>;
    invoices$: Observable<Invoice[]>;
    cardDescription = new FormControl("", Validators.required);
    range = new FormControl<BillApprovalDatePickerFormControl>(
        {
            startDate: "",
            endDate: "",
        },
        Validators.required,
    );
    plannedPaymentDate = new FormControl("", Validators.required);
    loader = new Loader();
    updatedTableData: Invoice[];
    role: Role;

    private stateSub: Subscription;
    private cardSub: Subscription;
    private refreshSub: Subscription;
    private threadId$: Observable<string>;

    constructor(
        private authService: AuthService,
        private dialogService: DialogService,
        private billApprovalService: BillApprovalService,
        private threadCardService: ThreadCardService,
        private injector: Injector,
        private billApprovalFormStateService: BillApprovalFormStateService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.initModalData();

        this.thread$ = this.modalData.thread$;
        this.card$ = this.modalData.card$.pipe(shareReplay(1));
        this.state$ = this.modalData.state$.pipe(shareReplay(1));
        this.replies$ = this.modalData.replies$;
        this.userId$ = this.authService.getUserId();
        this.role = this.modalData.role;

        this.threadId$ = this.thread$.pipe(
            map((thread) => thread.id),
            distinctUntilChanged(),
        );

        this.cardSub = this.card$.subscribe((card) => {
            this.cardDescription.setValue(card.description);
        });

        const state = await this.state$.pipe(take(1)).toPromise();
        this.plannedPaymentDate.setValue(state.plannedDate);
        this.range.setValue({
            startDate: state.fromDate,
            endDate: state.toDate,
        });

        const formGroup = new FormGroup<BillApprovalForm>({
            invoiceItems: new FormRecord<FormGroup<BillApprovalFormItem>>({}),
        });

        const invoices$ = this.state$.pipe(
            filter((state) => !!state.attachments),
            switchMap((state) => this.downloadAttachment(state)),
            shareReplay(1),
        );

        this.invoices$ = combineLatest([invoices$, this.rangeUpdates().pipe(startWith([]))]).pipe(
            map(([invoices, newInvoices]) => this.getUniqueInvoices(invoices, newInvoices)),
        );

        this.form$ = combineLatest([this.state$, this.invoices$]).pipe(
            map(([state, invoices]) => this.updateStateInvoiceItems(state, invoices, formGroup)),
            startWith(null),
            pairwise(),
            scan(
                (_, [oldState, currState]) =>
                    this.billApprovalFormStateService.setRequestItems(formGroup, oldState, currState),
                formGroup,
            ),
        );
    }

    ngOnDestroy(): void {
        this.cardSub?.unsubscribe();
        this.stateSub?.unsubscribe();
        this.refreshSub?.unsubscribe();
    }

    save(thread: ITimeline, card: IThreadCard, state: BillApprovalState): void {
        const deletedItems$ = this.form$.pipe(
            take(1),
            switchMap((form) => {
                const { startDate, endDate } = this.range.value;
                const plannedPaymentDate = this.plannedPaymentDate.value;

                const startIso = this.parseAndFormatDate(startDate);
                const endIso = this.parseAndFormatDate(endDate);
                const paymentDateIso = this.parseAndFormatDate(plannedPaymentDate);
                const invoiceItems = this.getInvoiceItems(form);

                const payload: BillApprovalUpdate = {
                    type: BillApprovalUpdateType.Edit,
                    vaultId: state.vaultId,
                    updatedInvoiceItems: this.updatedTableData,
                    invoiceItems,
                    plannedDate: paymentDateIso,
                    fromDate: startIso,
                    toDate: endIso,
                };
                return this.billApprovalService.updateRequestItems(thread.id, card.id, payload);
            }),
        );

        const updatedDescription = this.cardDescription.value;
        const description$ = this.threadCardService.updateCardDescription(thread.id, card.id, updatedDescription);
        const updates$ = combineLatest([deletedItems$, description$]);

        this.loader
            .wrap(updates$)
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    updateTable(tableData: Invoice[]): void {
        this.updatedTableData = tableData;
    }

    refresh(threadId: string, invoices: Invoice[]): void {
        const { startDate, endDate } = this.range.value;
        const startIso = this.parseAndFormatDate(startDate);
        const endIso = this.parseAndFormatDate(endDate);

        const newInvoices$ = this.billApprovalService
            .listInvoices(threadId, startIso, endIso)
            .pipe(map((invoices) => invoices.sort((a, b) => this.compareInvoices(a, b))));

        this.invoices$ = combineLatest([
            newInvoices$.pipe(take(1)),
            this.rangeUpdates().pipe(startWith(invoices)),
        ]).pipe(
            map(([invoices, newInvoices]) => this.getUniqueInvoices(invoices, newInvoices)),
            shareReplay(1),
        );
    }

    private getUniqueInvoices(invoices: Invoice[], newInvoices: Invoice[]): Invoice[] {
        const mergedInvoices = [...invoices, ...newInvoices];
        return [...new Map(mergedInvoices.map((invoice) => [invoice.invoiceID, invoice])).values()];
    }

    private rangeUpdates(): Observable<Invoice[]> {
        return combineLatest([this.threadId$, this.range.valueChanges]).pipe(
            filter(([_, formVal]) => !!formVal?.startDate || !!formVal?.endDate),
            switchMap(([threadId, { startDate, endDate }]) => {
                const startIso = this.parseAndFormatDate(startDate);
                const endIso = this.parseAndFormatDate(endDate);

                const invoices$ = this.billApprovalService
                    .listInvoices(threadId, startIso, endIso)
                    .pipe(map((invoices) => invoices.sort((a, b) => this.compareInvoices(a, b))));

                return invoices$;
            }),
        );
    }

    private parseAndFormatDate(date?: string | DateTime): string {
        if (typeof date === "string") {
            return DateTime.fromISO(date).toFormat(BILL_APPROVAL_DATE_FORMAT);
        } else {
            return date.toFormat(BILL_APPROVAL_DATE_FORMAT);
        }
    }

    private compareInvoices(a: Invoice, b: Invoice): number {
        const contactCompare = this.compareBillContacts(a, b);
        const areContactsSame = contactCompare === 0;

        if (areContactsSame) {
            return this.compareBillDueDates(a, b);
        }

        return contactCompare;
    }

    private compareBillContacts(a: Invoice, b: Invoice): number {
        const aContact = a?.contact?.name;
        const bContact = b?.contact?.name;

        return aContact?.localeCompare(bContact) || 0;
    }

    private compareBillDueDates(a: Invoice, b: Invoice): number {
        const aDueDate = a?.dueDate;
        const bDueDate = b?.dueDate;

        return aDueDate?.localeCompare(bDueDate) || 0;
    }

    private downloadAttachment(state: BillApprovalState): Observable<Invoice[]> {
        const fileId = state.attachments.fileId;
        const vaultId = state.vaultId;
        return this.billApprovalService.downloadVaultPayRunReport(vaultId, fileId);
    }

    private getInvoiceItems(formGroup: FormGroup<BillApprovalForm>): Record<string, BillApprovalUpdatedItem> {
        const invoiceItems = Object.entries(formGroup.value.invoiceItems).map(([invoiceId, item]) => {
            const invoiceItem = {
                fileId: item.fileId,
                approved: item.approved || false,
                declined: item.declined || false,
                externalComment: item.externalComment || "",
                internalComment: item.internalComment || "",
            };
            return [invoiceId, invoiceItem];
        });
        return Object.fromEntries(invoiceItems);
    }

    private async initModalData(): Promise<void> {
        this.dialogRef = await this.loader.wrap(this.dialogService.getRef(this.injector)).toPromise();
        this.modalData = await this.loader
            .wrap(this.dialogService.getData<BillApprovalModalData>(this.injector))
            .toPromise();
    }

    private updateStateInvoiceItems(
        state: BillApprovalState,
        invoices: Invoice[],
        formGroup: FormGroup<BillApprovalForm>,
    ): BillApprovalState {
        const newInvoices = this.findNewInvoices(state, invoices);
        const newInvoiceItems = newInvoices.map((invoice) => this.createNewInvoiceItem(formGroup, invoice));
        return {
            ...state,
            invoiceItems: [...state.invoiceItems, ...newInvoiceItems],
        };
    }

    private findNewInvoices(state: BillApprovalState, invoices: Invoice[]): Invoice[] {
        return invoices.filter((invoice) => !state.invoiceItems.find((item) => item.description === invoice.invoiceID));
    }

    private createNewInvoiceItem(formGroup: FormGroup<BillApprovalForm>, invoice: Invoice): InvoiceItem {
        const existingItem = formGroup.controls.invoiceItems.value[invoice.invoiceID];
        return {
            description: invoice.invoiceID,
            response: {
                approved: {
                    actionId: "",
                    state: false,
                },
                declined: {
                    actionId: "",
                    state: false,
                },
            },
            externalComment: existingItem?.externalComment || "",
            internalComment: existingItem?.internalComment || "",
            fileId: null,
        };
    }
}
