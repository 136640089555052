<div class="transformer-results-modal">
    <loader-icon
        color="light"
        *ngIf="loader.counter"
        [showBackground]="true"
    ></loader-icon>

    <div class="transformer-results-modal-container">
        <a
            class="transformer-results-modal-back-button"
            (click)="goBack()"
            ><i class="las la-angle-left"></i> Back</a
        >

        <div class="transformer-results-modal-header">Quality check results</div>

        <h3 class="transformer-results-modal-title">{{ data?.transformer.label }}</h3>
        <div class="transformer-results-modal-desc">
            Please review each failed quality check below and take action to resolve it. If resolution is not possible
            provide detailed response or attachment explaining the reason.
        </div>

        <div
            class="threads checks table-container"
            *ngIf="tableData$ | async as tableData"
        >
            <fx-table
                [tableData]="tableData"
                [enableFooter]="false"
                [stickyHeader]="true"
                [enableExpandAll]="true"
                [sortEnabled]="false"
                [trackBy]="'label' | trackProperty"
                class="background-porcelain"
            >
                <ng-container
                    *fxColumn="
                        'label';
                        label: '';
                        mobileCollapse: true;
                        sortable: false;
                        expandable: true;
                        type: 'string';
                        mobileGrid: true;
                        hjSupressData: false;
                        let check
                    "
                >
                    <div>
                        {{ check.label || "-" }}
                    </div>
                </ng-container>

                <ng-container
                    *fxColumn="
                        'total';
                        label: '';
                        mobileCollapse: true;
                        sortable: false;
                        expandable: false;
                        type: 'string';
                        mobileGrid: true;
                        hjSupressData: false;
                        let check
                    "
                >
                    <div>
                        {{ check.total + (check.total === 1 ? " record" : " records") }}
                    </div>
                </ng-container>

                <ng-container *fxRowChild="let row">
                    <div class="transformer-results-expanded">
                        <div class="transformer-results-expanded-wide">
                            <table class="transformer-results-expanded-table">
                                <tr>
                                    <th
                                        class="transformer-results-expanded-table-header"
                                        *ngFor="let result of row.result.data[0]"
                                    >
                                        {{ result[0] }}
                                    </th>
                                </tr>
                                <ng-container *ngFor="let data of row.result.data">
                                    <tr>
                                        <ng-container *ngFor="let result of data">
                                            <td>
                                                <p>{{ result[1] }}</p>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </fx-table>
        </div>
    </div>
</div>
