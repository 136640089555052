import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { VaultService } from "@visoryplatform/vault";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

export type CompleteRfiData = {
    vaultId: string;
    title?: string;
    subhead?: string;
    analyticsPrefix?: string;
};

@Component({
    selector: "complete-request",
    templateUrl: "./complete-request-modal.component.html",
    styleUrls: ["./complete-request-modal.component.scss"],
})
export class CompleteRequestModalComponent {
    readonly ANALYTICS_PREFIX = GA_EVENTS.RFI_CONFIRM_COMPLETE;
    public loader = new Loader();
    vaultId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CompleteRfiData,
        private dialogRef: MatDialogRef<CompleteRequestModalComponent>,
        private vaultService: VaultService,
    ) {
        this.vaultId = data?.vaultId;
    }

    async completeRfi() {
        if (this.vaultId) {
            this.loader.show();
            await this.vaultService.closeVault(this.vaultId).toPromise();
            this.loader.hide();
        }
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
