<div class="calendar-modal">
    <multi-component-layout
        [stepConfigurations]="stepConfigurations"
        (emitComplete)="onClose(true)"
        (emitTransition)="handleTransition($event)"
        [enableHeader]="true"
        [enableFooter]="true"
        [loading]="loader.counter !== 0"
        contentClass="multi-component-layout--book-meeting"
    >
        <div class="calendar-modal-header multi-component-layout-header">
            <div class="calendar-modal-title">Schedule Meeting</div>
            <div class="calendar-modal-header-close">
                <div
                    class="fx-l-modal-header-icon"
                    (click)="onClose(false)"
                >
                    <i class="las la-times icon-size"></i>
                </div>
            </div>
        </div>
        <step-layout stepTitle="Calendar">
            <app-calendar-choose-time
                *ngIf="maxDate && duration"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [slots]="slots$ | async"
                [duration]="duration"
                (emitMonthSelection)="monthChange($event)"
                (emitSelection)="handleSelection($event)"
            ></app-calendar-choose-time>
            <div
                *ngIf="errorMessage"
                class="calendar-modal-error"
            >
                {{ errorMessage }}
            </div>
        </step-layout>
        <step-layout [stepTitle]="'Review and Book'">
            <app-calendar-review-meeting
                *ngIf="meetingReviewData"
                [data]="meetingReviewData"
            ></app-calendar-review-meeting>
        </step-layout>
        <loader-icon
            *ngIf="!errorMessage && loader.counter"
            color="light"
        ></loader-icon>
    </multi-component-layout>
</div>
