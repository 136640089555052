import { Pipe, PipeTransform } from "@angular/core";
import { AutomatedCardEventExtensionHelpers, IStep } from "@visoryplatform/workflow-core";

@Pipe({
    name: "enableStepAction",
})
export class EnableStepActionPipe implements PipeTransform {
    transform(step: IStep): boolean {
        const automatedCardExtensions = step.extensions?.filter(
            AutomatedCardEventExtensionHelpers.isAutomatedCreateCardExtension,
        );
        if (!automatedCardExtensions?.length) {
            return true;
        }

        return automatedCardExtensions.every((extension) => {
            return !!AutomatedCardEventExtensionHelpers.getCardIdFromExtension(extension);
        });
    }
}
