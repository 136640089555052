import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { Component, Inject, Injector, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IThreadCard, Role, SubjectType } from "@visoryplatform/threads";
import { PreviewAction, VAULT_ACTION, VaultService } from "@visoryplatform/vault";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { IDocument } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card-document/create-card-document.component";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { RenameFileModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/rename-file-modal/rename-file-modal.component";
import { CreateReportModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-report-modal/create-report-modal.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { from, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";
import { environmentCommon } from "src/environments/environment";
import { DocumentCategory } from "../../enums/DocumentCategory";
import { WindowListenersService } from "../../../../portal-modules/src/lib/shared/services/window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../portal-modules/src/lib/environment/environment.common";
import { retryBackoff } from "backoff-rxjs";
import {
    ICreateReportModalInput,
    ICreateReportModalOutput,
} from "projects/portal-modules/src/lib/threads-ui/interfaces/ICreateReportModal";
import { DocumentSignDeclarations } from "../../enums/DocumentSignDeclarations";
import { VaultCardService } from "../vault-card/services/vault-card.service";
import { MessageService } from "@visoryplatform/portal-ui";
import { ToastKeys, ToastSeverity } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { ToastMessage } from "@visoryplatform/portal-ui/lib/toast/ToastMessage";
import { ErrorMessagesConstants } from "projects/portal-modules/src/lib/shared/constants/error-messages.constants";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

interface ICreateMessageCardEvent extends ICreateCardEvent {
    cardDescription?: string;
}

@Component({
    selector: "app-message-attach-modal",
    templateUrl: "./message-attach-modal.component.html",
    styleUrls: ["./message-attach-modal.component.scss"],
})
export class MessageAttachModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly messageSizeQuotaInKB = 128;

    attachmentErrorMessages = [];
    documents: IDocument[] = [];
    formError: string = null;
    isMobileView = false;
    loader = new Loader();
    messageFormControl = new FormControl("", [Validators.required]);
    quillError: boolean;
    role$: Observable<Role>;
    markAllAsSignature: boolean;
    markAllAsReport?: boolean;
    requiredUploadCount: number;
    hasPermissionToCreateVaultCard$: Observable<boolean>;
    hasPermissionToRequestSignature$: Observable<boolean>;

    modalData: ICreateMessageCardEvent;
    private dialogRef: DialogRef<string>;

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private vaultService: VaultService,
        private vaultCardService: VaultCardService,
        private cardService: ThreadCardService,
        private unsavedDialogService: UnsavedModalDialogService,
        private windowListenersService: WindowListenersService,
        private messageService: MessageService,
        private permissionService: PermissionService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialogService: DialogService,
        private injector: Injector,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    onChange(): void {
        this.validateForm(this.messageFormControl);
    }

    async ngOnInit(): Promise<void> {
        this.modalData = await this.dialogService.getData<ICreateMessageCardEvent>(this.injector).toPromise();
        this.dialogRef = await this.dialogService.getRef<string>(this.injector).toPromise();

        this.requiredUploadCount = this.modalData?.data?.requiredUploadCount;
        this.markAllAsSignature = this.modalData?.data?.markAllAsSignature;
        this.markAllAsReport = this.modalData?.data?.markAllAsReport;

        this.role$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );

        this.messageFormControl.setValue(this.modalData.cardDescription);
        this.hasPermissionToCreateVaultCard$ = this.roleHasPermission(this.role$, "CreateVaultCard");
        this.hasPermissionToRequestSignature$ = this.roleHasPermission(this.role$, "UploadSignableDocumentVaultCard");
    }

    attachFile(file: File): void {
        let document: IDocument = {
            description: file.name,
            file,
        };

        this.hasPermissionToRequestSignature$.pipe(take(1)).subscribe((hasPermissionToRequestSignature) => {
            if (hasPermissionToRequestSignature && this.markAllAsSignature) {
                document = {
                    ...document,
                    signature: this.markAllAsSignature,
                };
            }

            this.documents = [...this.documents, document];
            this.validateForm(this.messageFormControl);
        });
    }

    renameFile(document: IDocument): void {
        this.dialog
            .open(RenameFileModalComponent, { data: document, panelClass: ["centered-modal"] })
            .afterClosed()
            .subscribe((updatedDescription: string) => {
                if (!updatedDescription) {
                    return;
                }
                document.description = updatedDescription;
            });
    }

    markAsReport(index: number): void {
        const file = this.documents[index];

        if (file.category === DocumentCategory.Report) {
            this.documents = this.documents.map((document, documentIndex) => {
                if (documentIndex === index) {
                    return this.resetReportState(document);
                }
                return document;
            });
            return;
        }

        const modalData: ICreateReportModalInput = {
            title: file.description,
            fileType: file.file.type,
        };

        this.dialog
            .open<CreateReportModalComponent>(CreateReportModalComponent, {
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                autoFocus: true,
                data: modalData,
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .subscribe((data: ICreateReportModalOutput) => {
                if (data) {
                    const { description, reportingPeriod } = data;
                    this.documents = this.documents.map((document, documentIndex) => {
                        if (documentIndex === index) {
                            return {
                                file: file.file,
                                category: DocumentCategory.Report,
                                description,
                                reportingPeriod,
                            };
                        }
                        return document;
                    });
                }
            });
    }

    removeFile(document: IDocument): void {
        const index = this.documents.indexOf(document);
        this.documents = this.documents.filter((_, documentIndex) => documentIndex !== index);
        this.validateForm(this.messageFormControl);
        this.messageService.clear(ToastKeys.fileUploadError);
    }

    roleHasPermission(role$: Observable<Role>, permission: string): Observable<boolean> {
        return role$.pipe(switchMap((role) => this.permissionService.checkPermissions(role, permission)));
    }

    async close(cardId?: string): Promise<void> {
        if (cardId || (!this.messageFormControl.dirty && this.documents.length === 0)) {
            this.dialogRef.close(cardId);
        } else {
            const panelClass = await this.dialogService.getConfirmConfigPanelClass().pipe(take(1)).toPromise();
            const confirmClose = await this.unsavedDialogService.confirmClose("message-create", panelClass, "640px");
            if (confirmClose) {
                this.dialogRef.close();
                this.messageService.clear(ToastKeys.fileUploadError);
            }
        }
    }

    async createCard(): Promise<void> {
        if (this.validateForm(this.messageFormControl)) {
            this.loader.show();
            const message = this.messageFormControl.value || "";
            const files = this.documents.map((document) => document.file);
            const invalidFilenames = await this.vaultCardService.getInvalidFilenames(files);

            if (invalidFilenames?.length) {
                this.showErrorToast(invalidFilenames);
                this.loader.hide();
                return;
            }

            const cardCreation$ =
                this.documents.length > 0
                    ? from(
                          this.createVaultCard(
                              this.modalData.thread.id,
                              message,
                              this.documents,
                              this.modalData.disableEmails,
                          ),
                      )
                    : from(this.createMessageCard(message));

            cardCreation$
                .pipe(
                    take(1),
                    catchError((err: unknown) => {
                        console.error(err);
                        return of(null);
                    }),
                )
                .subscribe((card) => {
                    this.attachmentErrorMessages = [];
                    this.clear();
                    this.documents = [];
                    this.loader.hide();
                    if (card) {
                        this.close(card.id);
                    } else {
                        this.close();
                    }
                });
        }
    }

    private showErrorToast(invalidFilenames: string[]): void {
        const errorModalTitle = ErrorMessagesConstants.AttachmentsFailed;
        const messageText = this.vaultCardService.getFilesUploadErrorToastMessageText(invalidFilenames);
        const toastMessage: ToastMessage = {
            channel: ToastKeys.fileUploadError,
            severity: ToastSeverity.Error,
            summary: errorModalTitle,
            detail: messageText,
            sticky: true,
        };
        this.messageService.add(toastMessage);
    }

    private validateForm(message: FormControl<string>): boolean {
        this.formError = null;
        this.messageFormControl = message;
        const textValue = this.messageFormControl.value;

        if (
            this.messageFormControl.dirty &&
            (!textValue || this.trimParagraphTags(textValue) === "") &&
            this.documents?.length === 0
        ) {
            this.formError = "Please supply a message or upload an attachment";
            return false;
        }
        return true;
    }

    private trimParagraphTags(message: string): string {
        if (message) {
            return message.replace(/<p>|<\/p>/gi, "").trim();
        } else {
            return message;
        }
    }

    private clear(): void {
        this.messageFormControl.markAsPristine();
        this.messageFormControl.setValue("", { emitEvent: false });
    }

    private async createMessageCard(message: string): Promise<IThreadCard> {
        const messageEndpoint = environmentCommon.cardsEndpoints.message;
        const card = await this.cardService
            .createStackCard<{ message: string }, IThreadCard>(this.modalData.thread.id, messageEndpoint, { message })
            .toPromise();

        return card;
    }

    private async setReportPreview(vaultId: string, fileId: string, file: File, document: IDocument): Promise<void> {
        const actionData = {
            data: file.name,
            type: file.type,
            metaData: {
                reportingPeriod: document.reportingPeriod,
            },
        };
        await this.vaultService.setAction<PreviewAction>(vaultId, fileId, VAULT_ACTION.Preview, actionData).toPromise();
    }

    private async setSignable(vaultId: string, fileId: string): Promise<void> {
        const declaration = DocumentSignDeclarations.Authorise;
        await this.vaultService.setSignable(vaultId, fileId, declaration).toPromise();
    }

    private async createVaultCard(
        threadId: string,
        message: string,
        documents: IDocument[],
        disableEmails?: boolean,
    ): Promise<IThreadCard> {
        const vault = environmentCommon.cardsEndpoints.vault;

        const card = await this.cardService
            .createStackCard<{ description: string; disableEmails?: boolean }, IThreadCard>(threadId, vault, {
                description: message,
                disableEmails,
            })
            .toPromise();

        const vaultSubject = card.subjects?.find((subject) => subject.type === SubjectType.Vault);
        const vaultId = vaultSubject?.id;

        if (!vaultId) {
            throw new Error("Vault card missing vault subject");
        }

        try {
            for (const document of documents) {
                await this.uploadFile(vaultId, document);
            }
        } catch (err) {
            console.error("Failed to create vault card, deleting card", err);
            await this.cardService.deleteCard(threadId, card.id).toPromise();
            throw err;
        }
        return card;
    }

    private async uploadFile(vaultId: string, document: IDocument): Promise<void> {
        const { description, file, signature, category } = document;

        const fileId = await this.vaultService
            .uploadFile(vaultId, description, file, category)
            .pipe(
                retryBackoff({
                    initialInterval: 1000,
                    maxRetries: 4,
                }),
            )
            .toPromise();

        if (typeof fileId !== "string") {
            return;
        }

        if (category === DocumentCategory.Report) {
            await this.setReportPreview(vaultId, fileId, file, document);
        }

        if (signature && typeof fileId === "string") {
            await this.setSignable(vaultId, fileId);
        }
    }

    // cleans up state when marked as a report
    private resetReportState(document: IDocument): { description: string; file: File } {
        return {
            description: document.description,
            file: document.file,
        };
    }
}
