<scatter-chart
    style="display: block; width: 100%"
    *ngIf="chartData$ | async; let chartData"
    [labels]="chartLabels"
    [datasets]="chartData"
    [yLabel]="metricName"
    [unit]="unit"
></scatter-chart>
<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
