import { Component, Injector, OnInit } from "@angular/core";
import {
    CopilotCreateQuery,
    CopilotTransaction,
    CopilotTransactionsState,
    CopilotUpdateType,
    IThreadCard,
    ITimeline,
} from "@visoryplatform/threads";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RequestStatuses } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { EditQueriesResponse } from "../edit-transaction-queries/edit-transactions-queries.component";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Observable, concat, forkJoin } from "rxjs";
import { map, pairwise, startWith, take } from "rxjs/operators";
import { Query, StatementLine } from "@visoryplatform/copilot";
import { TransactionHelperService } from "../../services/transaction-helper.service";

@Component({
    selector: "update-transaction-queries",
    templateUrl: "./update-transactions-queries.component.html",
    styleUrls: ["./update-transactions-queries.component.scss"],
})
export class UpdateTransactionQueriesComponent implements OnInit {
    dialogRef: DialogRef;
    response: EditQueriesResponse;

    loader = new Loader();
    RequestStatuses = RequestStatuses;
    threadId$: Observable<string>;
    cardId$: Observable<string>;
    thread$: Observable<ITimeline>;
    card$: Observable<IThreadCard>;
    state$: Observable<CopilotTransactionsState>;
    cardDescription$: Observable<string>;
    transactions$: Observable<CopilotTransaction[]>;

    constructor(
        private injector: Injector,
        private dialogService: DialogService,
        private threadCardService: ThreadCardService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.loader.show();
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        const data = await this.dialogService.getData<CardResources>(this.injector).toPromise();
        this.loader.hide();

        const { thread$, card$, state$ } = data;
        this.thread$ = thread$;
        this.threadId$ = thread$.pipe(map((thread) => thread.id));
        this.cardId$ = card$.pipe(map((card) => card.id));
        this.card$ = card$;
        this.cardDescription$ = card$.pipe(map((card) => card.description));
        this.state$ = state$;

        this.transactions$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            map(([oldState, state]) => TransactionHelperService.mapTransactionOrder(state, oldState)),
        );
    }

    handleUpdate(response: EditQueriesResponse): void {
        this.response = response;
    }

    async update(
        startDate: string,
        endDate: string,
        description: string,
        statementLines: Record<string, StatementLine>,
        queries: Partial<Query>[],
    ): Promise<void> {
        console.log("Update", startDate, endDate, description, statementLines, queries);

        const threadId = await this.threadId$.pipe(take(1)).toPromise();
        const cardId = await this.cardId$.pipe(take(1)).toPromise();
        const state = await this.state$.pipe(take(1)).toPromise();
        const existingTransactions = state.transactions;
        const useDeprecatedId = state.useDeprecatedId;

        const removedTransactions = this.filterRemovedQueries(existingTransactions, queries);
        const addedTransactions = this.filterAddedQueries(existingTransactions, queries);
        const updatedTransactions = this.filterUpdatedQueries(existingTransactions, queries);

        const description$ = this.threadCardService.updateCardDescription(threadId, cardId, description);

        const createQueries: CopilotCreateQuery[] = addedTransactions.map((query) => ({
            statementLineId: query.subjectId,
            accountId: statementLines[query.subjectId]?.accountId,
            query: query.query,
        }));

        if (createQueries?.length) {
            await this.updateNewQueries(threadId, cardId, startDate, endDate, createQueries, useDeprecatedId);
        }

        const updates$ = updatedTransactions.map((query) =>
            this.threadCardService.updateCardExtension(threadId, cardId, {
                type: CopilotUpdateType.EditQuery,
                queryId: query.id,
                update: query,
            }),
        );

        const remove$ = removedTransactions.map((transaction) =>
            this.threadCardService.updateCardExtension(threadId, cardId, {
                type: CopilotUpdateType.DeleteQuery,
                queryId: transaction.query.id,
            }),
        );

        await this.loader.wrap(concat(description$, forkJoin(updates$), forkJoin(remove$))).toPromise();

        this.dialogRef.close();
    }

    private filterRemovedQueries(
        existingTransactions: CopilotTransaction[],
        queries: Partial<Query>[],
    ): Partial<CopilotTransaction>[] {
        return existingTransactions.filter((transaction) =>
            queries.every((query) => transaction.query.id !== query.id),
        );
    }

    private filterAddedQueries(
        existingTransactions: CopilotTransaction[],
        queries: Partial<Query>[],
    ): Partial<Query>[] {
        return queries.filter((query) =>
            existingTransactions.every((existing) => existing.statementLine.id !== query.subjectId),
        );
    }

    private filterUpdatedQueries(
        existingTransactions: CopilotTransaction[],
        queries: Partial<Query>[],
    ): Partial<Query>[] {
        return queries.filter((query) =>
            existingTransactions.some((existing) => existing.statementLine.id === query.subjectId),
        );
    }

    private async updateNewQueries(
        threadId: string,
        cardId: string,
        startDate: string,
        endDate: string,
        createQueries: CopilotCreateQuery[],
        useDeprecatedId?: boolean,
    ): Promise<void> {
        const update$ = this.threadCardService.updateCardExtension(threadId, cardId, {
            type: CopilotUpdateType.Refresh,
            startDate,
            endDate,
            createQueries,
            useDeprecatedId,
        });

        await this.loader.wrap(update$).toPromise();
    }
}
