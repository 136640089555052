import { Injectable, NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { FindexUiModule } from "../findex-ui";
import { CommonModule } from "@angular/common";
import { PortalModule } from "@angular/cdk/portal";

import { ThreadComponent } from "./components/thread/thread.component";
import { ThreadCardComponent } from "./components/thread-card/thread-card.component";
import { ThreadsContextMenuComponent } from "./components/threads-context-menu/threads-context-menu.component";
import { UiCardPortalComponent } from "./components/ui-card-portal/ui-card-portal.component";
import { UserItemComponent } from "./components/user-item/user-item.component";
import { UpcomingMeetingDetailComponent } from "./components/upcoming-meeting-detail/upcoming-meeting-detail.component";
import { ThreadsInputComponent } from "./components/threads-input/threads-input.component";
import { ThreadsCheckboxComponent } from "./components/threads-checkbox/threads-checkbox.component";
import { ResizeBeforeInteractionDirective } from "./directives/resize-before-interaction.directive";
import { UnreadStatusDirective } from "./directives/unread-status.directive";
import { AnalyticsModule } from "../analytics";
import { FxTableModule } from "../fx-table/fx-table.module";
import { ThreadsListEmptyStateComponent } from "./components/threads-list-empty-state/threads-list-empty-state.component";
import { NestedMessageComponent } from "./components/nested-message/nested-message.component";
import { CreateCardComponent } from "./components/create-card/create-card.component";
import { CreateCardDocumentComponent } from "./components/create-card/create-card-document/create-card-document.component";
import { RenameFileModalComponent } from "./components/create-card/rename-file-modal/rename-file-modal.component";
import { SlideMenuIconComponent } from "./components/slide-menu-icon/slide-menu-icon.component";
import { UiLayoutsModule } from "./../ui-layouts/ui-layouts.module";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared";
import { NotificationsModule } from "../notifications/notifications.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InviteClientModalComponent } from "./components/invite-client/invite-client-modal.component";
import { StaffDetailComponent } from "./components/staff-detail/staff-detail.component";
import { QuillModule } from "ngx-quill";
import { CardComponent } from "./components/card/card.component";
import { UiToastComponent } from "./components/toast/ui-toast.component";
import { QuillEditorWrapperComponent } from "./components/quill-wrapper/quill-editor-wrapper.component";
import { QuillViewWrapperComponent } from "./components/quill-view-wrapper/quill-view-wrapper.component";
import { MentionTooltipComponent } from "./components/mention-tooltip/mention-tooltip.component";

import { CloseThreadPromptComponent } from "./components/close-thread-prompt/close-thread-prompt.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TuiHintModule } from "@taiga-ui/core";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { UserProfileDropdownComponent } from "./components/user-profile-dropdown/user-profile-dropdown.component";
import { ThreadCardRepliesComponent } from "./components/thread-card-replies/thread-card-replies.component";
import { ReplyComponent } from "./components/reply/reply.component";
import { CreateReportModalComponent } from "./components/create-report-modal/create-report-modal.component";

import { ThreadsUiPipesModule } from "./pipes/threads-ui-pipes.module";
import { PluginsModule } from "../plugins";
import { FocusActionWizardComponent } from "./components/focus-action-wizard/focus-action-wizard.component";
import { DashboardGreetingBannerComponent } from "./../../../../default-plugins/dashboard/components/greeting/dashboard-greeting-banner.component";
import { ReplyButtonsComponent } from "./components/reply-buttons/reply-buttons.component";
import { ConfirmPromptComponent } from "./components/confirm-prompt/confirm-prompt.component";
import { LuxonDateModule } from "@angular/material-luxon-adapter";
import { CalendarPageComponent } from "./components/calendar-page/calendar-page.component";
import { MatSelectModule } from "@angular/material/select";
import {
    CalendarDateFormatter,
    CalendarModule,
    CalendarNativeDateFormatter,
    DateAdapter,
    DateFormatterParams,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CalendarAgendaComponent } from "./components/calendar-agenda/calendar-agenda.component";
import { CalendarHeaderComponent } from "./components/calendar-header/calendar-header.component";
import { CalendarMilestoneComponent } from "./components/calendar-milestone/calendar-milestone.component";
import { CalendarMeetingComponent } from "./components/calendar-meeting/calendar-meeting.component";
import { ReadMoreModule } from "./modules/read-more/read-more.module";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { ReplyComposeComponent } from "./components/reply-compose/reply-compose.component";
import { ThreadCardRepliesListComponent } from "./components/thread-card-replies-list/thread-card-replies-list.component";
import { ParticipantAvatarsModule } from "./modules/participant-avatars/participant-avatars.module";
import { CreateThreadModule } from "./modules/create-thread/create-thread.module";
import { UiComponentsModule } from "../ui-components";
import { ThreadParticipantsModule } from "./modules/thread-participants";
import { WorkflowStatusModule } from "../workflow-status/workflow-status.module";
import { ForwardMessageModule } from "./modules/forward-message/forward-message.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { MentionsInfoBarComponent } from "./components/mentions-info-bar/mentions-info-bar.component";
import { QuillInlineEditorWrapperComponent } from "./components/quill-inline-editor-wrapper/quill-inline-editor-wrapper.component";
@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
    // solution to format days of weeks to be short in calendar
    public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);
    }
}

const exportDeclarations = [
    UserItemComponent,
    ThreadCardRepliesComponent,
    ReplyComposeComponent,
    ThreadCardRepliesListComponent,
    UserProfileDropdownComponent,
    CalendarComponent,
    CardComponent,
    StaffDetailComponent,
    InviteClientModalComponent,
    ThreadCardComponent,
    ThreadsContextMenuComponent,
    ThreadComponent,
    ThreadsInputComponent,
    ThreadsCheckboxComponent,
    ThreadsListEmptyStateComponent,
    NestedMessageComponent,
    CreateCardComponent,
    CreateCardDocumentComponent,
    RenameFileModalComponent,
    SlideMenuIconComponent,
    UiToastComponent,
    UpcomingMeetingDetailComponent,
    CloseThreadPromptComponent,
    QuillEditorWrapperComponent,
    QuillInlineEditorWrapperComponent,
    ReplyButtonsComponent,
    CreateReportModalComponent,
    FocusActionWizardComponent,
    DashboardGreetingBannerComponent,
    ConfirmPromptComponent,
    QuillViewWrapperComponent,
    MentionTooltipComponent,
    MentionsInfoBarComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FindexUiModule,
        FxUiModule,
        PortalModule,
        AnalyticsModule,
        RouterModule,
        UiLayoutsModule,
        UiComponentsModule,
        ThreadParticipantsModule,
        SharedModule,
        NotificationsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        QuillModule,
        FormsModule,
        MatDialogModule,
        MatTableModule,
        FxTableModule,
        ThreadsUiPipesModule,
        PluginsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        TuiHintModule,
        LuxonDateModule,
        OverlayModule,
        MatSelectModule,
        ParticipantAvatarsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        PortalUiModule,
        WorkflowStatusModule,
        CreateThreadModule,
        ForwardMessageModule,
        ReadMoreModule,
    ],
    declarations: [
        ...exportDeclarations,
        UiCardPortalComponent,
        ResizeBeforeInteractionDirective,
        UnreadStatusDirective,
        ReplyComponent,
        ReplyButtonsComponent,
        CalendarPageComponent,
        CalendarAgendaComponent,
        CalendarHeaderComponent,
        CalendarMilestoneComponent,
        CalendarMeetingComponent,
    ],
    exports: [
        ...exportDeclarations,
        ThreadsUiPipesModule,
        UiLayoutsModule,
        CreateThreadModule,
        UiComponentsModule,
        ThreadParticipantsModule,
    ],
    providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
})
export class ThreadsUiModule {}
