import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "findIn" })
export class FindInPipe implements PipeTransform {
    private compare(arrItem: any, searchFor: any, propName?: string) {
        if (propName == null) {
            return arrItem === searchFor;
        } else if (!arrItem) {
            return false;
        } else {
            return arrItem[propName] === searchFor;
        }
    }

    transform(item: any, array: any[], propName?: string): any {
        if (!item || !array) {
            return null;
        }
        return array.find((arrItem) => this.compare(arrItem, item, propName));
    }
}
