import { Component, EventEmitter, Output, Input, SimpleChanges, OnChanges, OnInit } from "@angular/core";
import { ICalendarResponse, ICalendarDate } from "./calendar";
import { ITimePickerOptions } from "@visoryplatform/fx-ui";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";

export interface ICalendarOptions {
    timepicker: ITimePickerOptions | { inputName: string; headerAlign: string };
}

@Component({
    selector: "app-calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnChanges {
    @Input() slots: ISlot[];
    @Input() minDate: string = new Date().toISOString();
    @Input() maxDate: string;
    @Input() showDatePreview = false;
    @Output() monthChange = new EventEmitter();
    @Output() dateTimeSelected: EventEmitter<ISlot> = new EventEmitter();

    @Input() options: ICalendarOptions = {
        timepicker: {
            inputName: "time",
            headerAlign: "center",
        },
    };

    startDate: string;
    selectedDate: ICalendarResponse = {
        date: null,
    };
    timezoneSubtitle: string;

    constructor(private calendarService: CalendarService) {}

    ngOnInit() {
        this.timezoneSubtitle = this.calendarService.getTimezoneSubtitle();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minDate && changes.minDate.currentValue) {
            this.startDate = changes.minDate.currentValue;
        }
    }

    setDate(event: ICalendarDate) {
        this.selectedDate.date = event || null;
        this.selectedDate.time = null;
        this.dateTimeSelected.next(null);
    }

    setTime(event: string) {
        const eventTime = new Date(event).getTime();
        const matchedSlot = this.slots.find((slot) => new Date(slot.start).getTime() === eventTime);
        this.dateTimeSelected.next(matchedSlot);
    }
}
