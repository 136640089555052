import { Component, EventEmitter, Input, Output } from "@angular/core";

/**
 * USAGE NOTES:
 * <centered-modal-layout (save)="()" (close)="()" [disableSave]="boolean" [loading]="boolean" [cancelButtonTitle]="string" [saveButtonTitle]="string" analyticsPrefix="string">
        <div modal-header></div>
        <div modal-subhead></div>
        <div modal-content></div>
    </centered-modal-layout>
 */
@Component({
    selector: "centered-modal-layout",
    templateUrl: "./centered-modal-layout.component.html",
    styleUrls: ["./centered-modal-layout.component.scss"],
})
export class CenteredModalLayoutComponent {
    @Input() loading = false;
    @Input() disableSave = false;
    @Input() cancelButtonTitle = "Cancel";
    @Input() saveButtonTitle = "Save";
    @Input() error: string;
    @Input() analyticsPrefix = "modal";
    @Input() analyticsPostfix = "_save-button";
    @Input() showSaveButton = true;

    @Output() close = new EventEmitter();
    @Output() save = new EventEmitter();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
}
