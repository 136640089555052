<div class="recent-notifications">
    <div class="recent-notifications-header">
        <div class="recent-notifications-header-main">
            <div class="recent-notifications-header-main-title"><i class="las la-bell"></i> Notifications</div>
            <fx-context-menu
                #allNotificationsMenu
                [gutter]="false"
                class="recent-notifications-header-main-mark-all"
            >
                <a (click)="settingsNavigate.emit()">
                    <i class="las la-cog"></i>
                    Settings</a
                >
            </fx-context-menu>
        </div>

        <div
            *ngIf="canViewTabs$ | async"
            class="recent-notifications-header-tabs"
        >
            <v-tabs [activeTab]="currentFilter">
                <v-tab
                    [analyticsClick]="gaEvents.APP_NOTIFICATION_PANEL + '_all'"
                    [id]="FilterBy.All"
                    (activated)="setCurrentFilter(FilterBy.All)"
                >
                    <p>All</p>
                </v-tab>
                <v-tab
                    [analyticsClick]="gaEvents.APP_NOTIFICATION_PANEL + '_workflows'"
                    [id]="FilterBy.Workflow"
                    (activated)="setCurrentFilter(FilterBy.Workflow)"
                >
                    <p>Workflows</p>
                </v-tab>
                <v-tab
                    [analyticsClick]="gaEvents.APP_NOTIFICATION_PANEL + '_internal_chat'"
                    [id]="FilterBy.InternalChat"
                    (activated)="setCurrentFilter(FilterBy.InternalChat)"
                >
                    <p>Internal Chat</p>
                </v-tab>
            </v-tabs>
        </div>

        <div class="recent-notifications-filters">
            <v-toggle [formControl]="showUnreadOnly">Show unread only</v-toggle>
            <button
                class="recent-notifications-filters-mark-all"
                [disabled]="!unreadNotificationCount"
                (click)="markAllNotificationsAsRead()"
            >
                <i class="ion-md-checkmark"></i>
                <span>Mark all as read</span>
            </button>
        </div>
    </div>

    <loader-icon
        *ngIf="!notifications || !filteredNotifications || loader.counter"
        color="light"
    ></loader-icon>
    <div
        *ngIf="notifications && filteredNotifications"
        class="recent-notifications-content"
    >
        <div
            *ngIf="!!(filteredNotifications | countNotifications)"
            class="recent-notifications-list-container"
        >
            <cdk-virtual-scroll-viewport
                (scrolledIndexChange)="handleNextPage()"
                [itemSize]="100"
                class="recent-notifications-viewport"
            >
                <notification
                    *cdkVirtualFor="let notification of filteredNotifications; trackBy: trackNotification"
                    [notification]="notification"
                    (markAsUnread)="markNotificationUnread(notification)"
                    (markAsRead)="markNotificationRead(notification)"
                    (delete)="deleteNotification(notification)"
                    (open)="openNotification(notification)"
                >
                </notification>
            </cdk-virtual-scroll-viewport>
        </div>

        <div
            *ngIf="!(filteredNotifications | countNotifications)"
            class="recent-notifications-none"
        >
            <div>
                <img
                    width="126"
                    height="122"
                    src="./assets/images/notifications-none.svg"
                />
            </div>
            <p>You are all caught up!</p>
            <p>Check back later for new notifications</p>
        </div>
    </div>
</div>
