import { Component, OnChanges, OnDestroy, Input, Inject, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { DueDateAnchor, ICardTaskDetail, ITimeline, Role, WorkflowDueDateService } from "@visoryplatform/threads";
import { Observable, Subscription } from "rxjs";
import { GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "../../../../portal-modules/src/lib/environment/environment.common";
import { NgChanges } from "../../../../portal-modules/src/lib/shared/interfaces/ng-changes.interface";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { ENVIRONMENT } from "src/app/injection-token";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { DateTime } from "luxon";
import { WorkflowTextConstants } from "projects/portal-modules/src/lib/workflow-status/constants/workflow-text.constants";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";
import {
    AssignedTo,
    DashboardTimelinesTableHeaders,
    DashboardTimelinesEmptyState,
} from "../../constants/dashboard.constants";
import { ThreadListingJoinTask } from "../dashboard-timelines-paginated/dashboard-timelines-paginated.component";
import { TableThreadListing } from "projects/portal-modules/src/lib/threads-ui/services/threads-enrichment.service";

interface IEnrichedTimeline extends TableThreadListing {
    currentStepDueDate: string;
    isOverdue: boolean;
}

type EnrichedThreadListingJoinTask = IEnrichedTimeline | ICardTaskDetail;

@Component({
    selector: "dashboard-timelines-table",
    templateUrl: "./dashboard-timelines-table.component.html",
    styleUrls: ["./dashboard-timelines-table.component.scss"],
})
export class DashboardTimelinesTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() threads: ThreadListingJoinTask[];
    @Input() userId: string;
    @Input() assignedTo: AssignedTo;
    @Input() role: Role;

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;
    readonly gaEvents = GA_EVENTS;
    readonly hideStatusCol = this.environment.featureFlags.hideDashboardThreadsStatusCol;
    readonly tableHeaders = DashboardTimelinesTableHeaders;
    readonly AssignedTo = AssignedTo;
    readonly WorkflowConstants = WorkflowTextConstants;
    readonly EmptyStateConstants = DashboardTimelinesEmptyState;
    readonly badgeColors = VBadgeColors;
    readonly emptyState = EmptyStateImageType;

    loader = new Loader();
    tableData = new MatTableDataSource<EnrichedThreadListingJoinTask>();

    loading: boolean;
    workflowDashboardDueDateEnabled$: Observable<boolean>;
    showSlaRemaining$: Observable<boolean>;

    private tableSubscription: Subscription;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private featureFlagService: FeatureFlagService,
    ) {}

    trackThreadTask(_index: number, cardTask: ICardTaskDetail): string {
        return `${cardTask.threadId}/${cardTask.cardId}/${cardTask.taskId}`;
    }

    ngOnInit(): void {
        this.workflowDashboardDueDateEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowDashboardDueDate,
        );
        this.showSlaRemaining$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableSlaRemainingTime);
    }

    ngOnChanges(changes: NgChanges<DashboardTimelinesTableComponent>): void {
        const { threads } = changes;

        if (threads && this.threads) {
            this.tableData.data = this.enrichTimelines(this.threads);
        }
    }

    ngOnDestroy(): void {
        this.tableSubscription?.unsubscribe();
    }

    private enrichTimelines(timelines: ThreadListingJoinTask[]): EnrichedThreadListingJoinTask[] {
        return timelines.map((threadListing) => {
            if (this.isThreadCardTask(threadListing)) {
                return threadListing;
            }
            const currentStepId = threadListing.workflow?.currentStepId;
            const currentStepDueDate = this.getCurrentStepDueDate(threadListing, currentStepId);
            const isOverdue = this.getIsOverdue(threadListing, currentStepId);

            return {
                ...threadListing,
                currentStepDueDate,
                isOverdue,
            };
        });
    }

    private getCurrentStepDueDate(threadListing: ITimeline, currentStepId: string): string | null {
        const step = threadListing.workflow?.steps[currentStepId];

        if (!step) {
            return null;
        }

        const duration = WorkflowDueDateService.getExtensionDueDate(step)?.toISO();
        return duration && typeof duration === "string" ? duration : null;
    }

    private getIsOverdue(threadListing: ITimeline, currentStepId: string): boolean {
        const dateNow = DateTime.now();
        const step = threadListing.workflow?.steps[currentStepId];

        if (!step) {
            return false;
        }

        const dueDate = WorkflowDueDateService.getDueDates(DueDateAnchor.StartDate, dateNow, [step]);
        return dueDate?.[step.id]?.isOverdue;
    }

    private isThreadCardTask(threadJoinTask: ThreadListingJoinTask): threadJoinTask is ICardTaskDetail {
        return "taskId" in threadJoinTask;
    }
}
