import { Notification, PossibleDeliveryData } from "@visoryplatform/notifications-core";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { IPaginated } from "@visoryplatform/datastore-types";
import { NotificationsService } from "./notifications.service";
import { PaginatedDataset } from "@visoryplatform/datastore-types-frontend";

export class NotificationsPage extends PaginatedDataset<Notification> {
    websocketUpdateSub: Subscription;

    constructor(
        private channelPrefix: string,
        private limit: number,
        private shouldShowUnreadOnly: boolean,
        private groupType?: string,
        private notificationsService?: NotificationsService,
    ) {
        super();

        this.websocketUpdateSub = this.notificationsService
            .subscribeToChannel(this.channelPrefix, this.shouldShowUnreadOnly, this.groupType)
            .pipe(
                map((notifications) =>
                    notifications.filter((notifications) => notifications.channel.includes(channelPrefix)),
                ),
                shareReplay(1),
            )
            .subscribe((result) => {
                const dataSet = this.getDataSet();
                this.emitData(this.updateData(dataSet, result));
            });
    }

    updateData(existingData: Notification[], newPage: Notification[]): Notification[] {
        return this.notificationsService.updateNotifications(existingData, newPage);
    }

    getPage(token: string): Observable<IPaginated<Notification<PossibleDeliveryData>>> {
        return this.notificationsService.getNotifications(
            this.channelPrefix,
            token,
            this.limit,
            this.shouldShowUnreadOnly,
            this.groupType,
        );
    }

    destroy(): void {
        if (this.websocketUpdateSub) {
            this.websocketUpdateSub.unsubscribe();
        }
    }
}
