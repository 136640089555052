<centered-modal-layout
    (close)="close()"
    [cancelButtonTitle]="closeButtonTitle"
    [showSaveButton]="false"
>
    <div modal-header>{{ modalTitle }}</div>
    <div modal-content>
        <ng-container *ngIf="data?.items?.length; else noItems">
            <div
                *ngFor="let item of data?.items"
                class="item-card"
            >
                {{ item }}
            </div>
        </ng-container>

        <ng-template #noItems>
            <div>{{ noItemsDescription }}</div>
        </ng-template>
    </div>
</centered-modal-layout>
