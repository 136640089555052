import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "timezoneLabel",
})
export class TimezoneLabelPipe implements PipeTransform {
    transform(value: string | null): string {
        if (!value) {
            return "";
        }
        return value.replace(/_|-/g, " ");
    }
}
