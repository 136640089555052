import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ActivityChannelService {
    getCardReplyChannel(threadId: string, cardId: string, cardReplyId = ""): string {
        return `activity/threads/${threadId}/cards/${cardId}/cardReplies/${cardReplyId}`;
    }

    getCardContentChannel(threadId: string, cardId: string): string {
        return `activity/threads/${threadId}/cards/${cardId}/cardContent`;
    }

    getAllCardActivityChannel(threadId: string, cardId: string): string {
        return `activity/threads/${threadId}/cards/${cardId}/`;
    }
}
