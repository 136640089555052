import { NgModule } from "@angular/core";

import { InsightsRoutingModule } from "./insights-routing.module";
import { InsightsReportListComponent } from "./components/insights-reporting/insights-report-list/insights-report-list.component";
import { InsightsReportComponent } from "./components/insights-reporting/insights-report/insights-report.component";
import { InsightsReportListItemComponent } from "./components/insights-reporting/insights-report-list-item/insights-report-list-item.component";
import { InsightsAccountListingComponent } from "./components/account-listing/insights-account-listing.component";
import { CommonModule } from "@angular/common";
import { InsightsReportingComponent } from "./components/insights-reporting/insights-reporting.component";
import { InsightsRouteComponent } from "./components/insights-route/insights-route.component";
import { InsightsOverviewComponent } from "./components/insights-overview/insights-overview.component";
import { InsightsMetricWidgetComponent } from "./components/insights-metric-widget/insights-metric-widget.component";
import { InsightsMetricDetailRouteComponent } from "./components/insights-metric-detail-route/insights-metric-detail-route.component";
import { ScatterReportComponent } from "./components/scatter-report/scatter-report.component";
import { HistoricalComponent } from "./components/historical-report/historical-report.component";
import { VaultModule } from "../vault/vault.module";
import { InsightsPlugin } from "../insights/insights.plugin";
import { OpenMeasuresService } from "./services/open-measures.service";
import { AbsolutePipe } from "./pipes/absolute.pipe";
import { DateRangePipe } from "./pipes/date-range.pipe";
import { VisualisationModule } from "./modules/visualisation";
import { ThreadsUiModule } from "../../portal-modules/src/lib/threads-ui";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { PLUGIN_FACTORY } from "../../portal-modules/src/lib/plugins";
import { InsightsMetricSummaryComponent } from "./components/insights-metric-summary/insights-metric-summary.component";
import { InsightsTrendPillComponent } from "./components/insights-trend-pill/insights-trend-pill.component";
import { SharedPipesModule } from "projects/portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { MatTableModule } from "@angular/material/table";
import { InsightsPermissionsComponent } from "projects/default-plugins/insights/components/insights-permissions/insights-permissions.component";
import { EnterprisePermissionsComponent } from "projects/portal-modules/src/lib/user-profile/components/user-profile/enterprise-permissions/enterprise-permissions.component";
import { AddEnterpriseDialogComponent } from "projects/portal-modules/src/lib/user-profile/components/user-profile/add-enterprise-dialog/add-enterprise-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TuiHintModule } from "@taiga-ui/core";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { AccountInsightsComponent } from "./components/account-insights/account-insights.component";
import { SharedModule } from "../../portal-modules/src/lib/shared";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
    declarations: [
        InsightsTrendPillComponent,
        InsightsMetricSummaryComponent,
        InsightsReportingComponent,
        InsightsReportListComponent,
        InsightsReportComponent,
        InsightsReportListItemComponent,
        InsightsAccountListingComponent,
        InsightsOverviewComponent,
        InsightsMetricWidgetComponent,
        InsightsRouteComponent,
        InsightsMetricDetailRouteComponent,
        ScatterReportComponent,
        HistoricalComponent,
        AbsolutePipe,
        DateRangePipe,
        InsightsPermissionsComponent,
        EnterprisePermissionsComponent,
        AddEnterpriseDialogComponent,
        AccountInsightsComponent,
    ],
    imports: [
        CommonModule,
        InsightsRoutingModule,
        ThreadsUiModule,
        FindexUiModule,
        VaultModule,
        FxTableModule,
        VisualisationModule,
        SharedPipesModule,
        MatTableModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        TuiHintModule,
        PortalUiModule,
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: InsightsPlugin, multi: true }, OpenMeasuresService],
})
export class InsightsModule {}
