<ng-container *ngIf="payRunReport">
    <div class="summary">
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Pay period</span>
            </div>
            <div class="summary-column-value">
                {{ payRunReport.periodStart | date : "dd/MM/yyyy" }} -
                {{ payRunReport.periodEnd | date : "dd/MM/yyyy" }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Pay date</span>
            </div>
            <div class="summary-column-value">
                {{ payRunReport.payDate | date : "EEE dd MMM, YYYY" }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Net payroll</span>
                <i
                    class="las la-exclamation-circle"
                    [tuiHint]="payRunReportInfo.NetPayroll"
                    tuiHintDirection="top"
                ></i>
            </div>
            <div
                data-hj-suppress
                class="summary-column-value"
            >
                {{ payRunReport.netAmount | currency : currency.toUpperCase() }}
            </div>
        </div>
        <div class="summary-column">
            <div class="summary-column-title">
                <span>Previous net payroll</span>
                <i
                    class="las la-exclamation-circle"
                    [tuiHint]="payRunReportInfo.PreviousNetPayroll"
                    tuiHintDirection="top"
                ></i>
            </div>
            <div
                data-hj-suppress
                class="summary-column-value"
            >
                {{ payRunReport.previousAmount | currency : currency.toUpperCase() }}
            </div>
        </div>
    </div>
</ng-container>
