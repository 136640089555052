import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authGuard } from "projects/portal-modules/src/lib/findex-auth";
import { VaultListRouteComponent } from "./components/vault-list-route/vault-list-route.component";

const routes: Routes = [
    { path: "", component: VaultListRouteComponent, canActivate: [authGuard] },
    { path: "user/:userId", component: VaultListRouteComponent, canActivate: [authGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class VaultRoutingModule {}
