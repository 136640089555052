import { Component, Inject, Injector, OnInit } from "@angular/core";
import { IThread, Role } from "@visoryplatform/threads";
import { CreateMenuExtension, CreateMenuExtensionOption, ILibrary } from "projects/portal-modules/src/lib/plugins";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";
import { EXTENSION_MENU_LIBRARY } from "src/app/injection-token";
import { AnalyticsService, GA_EVENTS } from "../../../analytics";
import { AuthService } from "../../../findex-auth";
import { DialogService, DialogRef } from "../../../shared/services/dialog.service";

interface IExtensionModalData {
    extensionConfig: {
        extensionId: string;
    };
    thread: IThread;
}

@Component({
    selector: "calendar-create-modal",
    templateUrl: "./extension-menu-modal.component.html",
    styleUrls: ["./extension-menu-modal.component.scss"],
})
export class ExtensionMenuModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;

    currentSelection: CreateMenuExtensionOption;
    extension: CreateMenuExtension;
    extensionItem: CreateMenuExtensionOption[];
    loader = new Loader();
    role$: Observable<Role>;

    data: IExtensionModalData;
    private dialogRef: DialogRef;

    constructor(
        @Inject(EXTENSION_MENU_LIBRARY) private meetingExtensions: ILibrary<CreateMenuExtension>,
        private analytics: AnalyticsService,
        private authService: AuthService,
        private injector: Injector,
        private dialogService: DialogService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.data = await this.dialogService.getData<IExtensionModalData>(this.injector).toPromise();

        this.extension = this.meetingExtensions.resolve(this.data.extensionConfig.extensionId);
        this.role$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            shareReplay(1),
        );
    }

    setExtensionAction($event: boolean, extension: CreateMenuExtensionOption): void {
        this.analytics.recordEvent(
            "mouse-click",
            `${this.data.extensionConfig.extensionId}-${extension.analyticsEvent}-${this.gaEvents.APP_EXTENSION_CHECKBOX}`,
        );

        if (!$event) {
            this.currentSelection = null;
        } else {
            this.currentSelection = extension;
        }
    }

    async continue(currentSelection: CreateMenuExtensionOption): Promise<void> {
        if (!("componentRef" in currentSelection) && "action$" in currentSelection) {
            await this.createCard(currentSelection);
        }

        if ("componentRef" in currentSelection && !("action$" in currentSelection)) {
            const cardId = await this.createModal(currentSelection);
            this.dialogRef.close(cardId);
            return;
        }

        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    private async createCard(extension: CreateMenuExtensionOption): Promise<void> {
        this.loader.show();
        if (!("action$" in extension)) {
            return;
        }
        await extension.action$(this.data.thread).toPromise();
        this.loader.hide();
        this.dialogRef.close();
    }

    private async createModal(currentSelection: CreateMenuExtensionOption): Promise<string | null> {
        if (!("componentRef" in currentSelection)) {
            return null;
        }

        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            autoFocus: false,
            data: this.data,
        };

        const modalResponse = await this.dialogService.open(currentSelection.componentRef, config).toPromise();
        if (!modalResponse || typeof modalResponse !== "string") {
            return null;
        }

        return modalResponse;
    }
}
