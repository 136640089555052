<v-tabs>
    <v-tab
        [tabRouterLink]="['.', 'activity']"
        [analyticsClick]="gaEvents.APP_WORKFLOW_TAB_ACTIVITY"
    >
        Activity
    </v-tab>

    <ng-container *ngFor="let extension of threadExtensions$ | async; trackBy: trackByExtension">
        <v-tab
            *ngIf="!extension?.redirect; else redirectTab"
            [tabRouterLink]="['.', extension.route?.path ?? extension.label.toLowerCase()]"
            [analyticsClick]="gaEvents.APP_WORKFLOW_TAB + extension.label"
        >
            <i
                *ngIf="extension.icon"
                class="las {{ extension.icon }} tab-icon"
            ></i>
            {{ extension.label }}
        </v-tab>

        <ng-template #redirectTab>
            <v-tab
                [analyticsClick]="gaEvents.APP_WORKFLOW_TAB + extension.label"
                (activated)="handleRedirect(extension)"
            >
                <i class="las {{ extension.icon }} tab-icon"></i>
                {{ extension.label }}
            </v-tab>
        </ng-template>
    </ng-container>
</v-tabs>
