import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReportingPeriod } from "../create-card/create-card-document/create-card-document.component";
import { ICreateReportModalInput } from "../../interfaces/ICreateReportModal";

@Component({
    selector: "app-create-report-modal",
    templateUrl: "./create-report-modal.component.html",
    styleUrls: ["./create-report-modal.component.scss"],
})
export class CreateReportModalComponent implements OnInit {
    readonly PDF_FILE_TYPE = "application/pdf";

    reportingPeriod = ReportingPeriod;
    createReportForm = new FormGroup({
        title: new FormControl("", [Validators.required]),
        reportingPeriod: new FormControl("", [Validators.required]),
    });
    isFileValid: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public modalData: ICreateReportModalInput,
        public dialogRef: MatDialogRef<CreateReportModalComponent>,
    ) {}

    ngOnInit(): void {
        if (this.modalData.title) {
            this.createReportForm.patchValue({ title: this.modalData.title });
        }

        this.isFileValid = this.modalData.fileType === this.PDF_FILE_TYPE;
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close({
            description: this.createReportForm.value.title,
            reportingPeriod: this.createReportForm.value.reportingPeriod,
        });
    }

    onSave(): void {
        this.isFileValid ? this.save() : this.close();
    }
}
