import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
    CardStatus,
    ITimeline,
    IRequestItem,
    IThreadCard,
    IUpdatedRequestItems,
    IVaultRequestCardState,
    Role,
} from "@visoryplatform/threads";
import {
    RequestActionButtonLabel,
    RequestStatuses,
    VaultCardType,
} from "../../../vault/components/request/constants/request.constants";
import { IRequestAnalyticsTags } from "../../../vault/components/request/interfaces/IRequestAnalyticsTags";
import { combineLatest, from, Observable, of, Subscription } from "rxjs";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { IRequestForm, IRequestItemFormGroup } from "../../../vault/components/request/interfaces/IRequestForms";
import { IRequestReviewModalTitles } from "../../../vault/components/request/interfaces/IRequestReviewModalTitles";
import { MatDialog } from "@angular/material/dialog";
import { IRequestModalData } from "../../../vault/components/request/interfaces/IRequestModalData";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import { RfiService } from "../../../vault/services/rfi.service";
import { VaultRequestService } from "../../../vault/services/vault-request.service";
import { AnalyticsService, GA_EVENTS_PREFIX } from "../../../../portal-modules/src/lib/analytics";
import { mapTo, pairwise, scan, startWith, switchMap, take } from "rxjs/operators";
import { CompleteRequestModalComponent } from "../../../vault/components/upload/complete-rfi-modal/complete-request-modal.component";
import { RfiTodosComponent } from "../../../vault/components/request/rfi-todos/rfi-todos.component";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";

@Component({
    selector: "instructions-action",
    templateUrl: "./instructions-action.component.html",
    styleUrls: ["./instructions-action.component.scss"],
})
export class InstructionsActionComponent implements OnInit, OnDestroy {
    @ViewChild("rfiTodosComponent") instructionsTodos: RfiTodosComponent;

    readonly CARD_STATUSES = CardStatus;
    readonly buttonLabels = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    actionedPercentage = 0;
    actionedRequestItems = 0;
    analyticsTags: IRequestAnalyticsTags;
    canReopenRequest$: Observable<boolean>;
    canUpdateTodoListItem$: Observable<boolean>;
    card$: Observable<IThreadCard>;
    currentUserRole: Role;
    form$: Observable<FormGroup<IRequestForm>>;
    readonly: boolean;
    requestItemsCompletedChangesSub: Subscription;
    requestReviewModalTitles: IRequestReviewModalTitles;
    secondaryTitle = "Instructions";
    state$: Observable<IVaultRequestCardState>;
    stateSubscription: Subscription;
    thread$: Observable<ITimeline>;
    todosTitle = "The following must be actioned to complete this workflow step:";
    updateRequestItemsSub: Subscription;
    userId$: Observable<string>;
    enableMemories$: Observable<boolean>;

    public modalData: IRequestModalData;
    public dialogRef: DialogRef;

    constructor(
        private authService: AuthService,
        private rfiService: RfiService,
        private analytics: AnalyticsService,
        private vaultRequestService: VaultRequestService,
        private dialog: MatDialog,
        private injector: Injector,
        private dialogService: DialogService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.enableMemories$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowRelevantMemories);
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.modalData = await this.dialogService.getData<IRequestModalData>(this.injector).toPromise();

        this.setLocalVariables(this.modalData);
        this.setForm();
    }

    ngOnDestroy(): void {
        this.stateSubscription?.unsubscribe();
        this.updateRequestItemsSub?.unsubscribe();
        this.requestItemsCompletedChangesSub?.unsubscribe();
    }

    save(thread: ITimeline, card: IThreadCard): void {
        const canBeCompleted = (this.modalData.allowRecomplete || !this.readonly) && this.actionedPercentage === 100;

        if (!canBeCompleted) {
            return;
        }

        this.dialog
            .open(CompleteRequestModalComponent, {
                panelClass: "centered-modal",
                data: this.vaultRequestService.getCompleteRequestModalData(card.type, this.analyticsTags),
            })
            .afterClosed()
            .pipe(
                switchMap((complete) => {
                    const requestCardComplete$ = this.vaultRequestService.sendRequestCardEvent(
                        thread.id,
                        card.id,
                        card.type,
                        {
                            body: { isCompleted: true },
                        },
                    );
                    return complete ? requestCardComplete$.pipe(mapTo(true)) : of(null);
                }),
                take(1),
            )
            .subscribe((result) => this.dialogRef.close(result));
    }

    markAllComplete(
        checkBoxState: boolean,
        card: IThreadCard,
        state: IVaultRequestCardState,
        threadId: string,
        form: FormGroup<IRequestForm>,
    ): void {
        this.rfiService.markAsComplete(checkBoxState, form);
        const updatedRequestItems: IUpdatedRequestItems[] = state.requestItems
            .filter((requestItem) => requestItem.response.complete.state !== checkBoxState)
            .map((requestItem) => ({
                fileId: requestItem.fileId,
                complete: checkBoxState,
            }));
        this.actionedRequestItems = checkBoxState ? state.requestItems.length : 0;
        this.actionedPercentage = checkBoxState ? 100 : 0;
        const updateRequestPromise = this.vaultRequestService.updateRequest(
            updatedRequestItems,
            card.id,
            threadId,
            state.vaultId,
        );
        from(updateRequestPromise).subscribe();
    }

    updateRequestItemsComplete(control: FormControl<IRequestItem>, analyticsPrefix: GA_EVENTS_PREFIX): void {
        this.markItemComplete(control.value.response.complete.state, control, analyticsPrefix);
    }

    private setRequestItems(
        formGroup: FormGroup<IRequestForm>,
        oldState: IVaultRequestCardState,
        state: IVaultRequestCardState,
    ): FormGroup<IRequestForm> {
        this.updateRequestItems(formGroup, oldState?.requestItems, state?.requestItems);
        return formGroup;
    }

    private updateRequestItems(
        formGroup: FormGroup<IRequestForm>,
        oldItems: IRequestItem[],
        currItems: IRequestItem[],
    ): void {
        const removeItems =
            oldItems?.filter((oldItem) => !currItems?.some((currItem) => currItem.fileId === oldItem.fileId)) || [];
        const addItems =
            currItems?.filter((currItem) => !oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId)) || [];
        const updateItems =
            currItems?.filter((currItem) => oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId)) || [];

        const requestItems = formGroup.controls.requestItems;

        for (const item of removeItems) {
            const controlIndex = this.findControlIndexByFileId(item.fileId, requestItems);
            requestItems.removeAt(controlIndex);
        }

        for (const item of addItems) {
            this.addItem(item, requestItems);
        }

        for (const item of updateItems) {
            const oldItem = oldItems.find((oItem) => oItem.fileId === item.fileId);
            this.updateItem(oldItem, item, requestItems);
        }
    }

    private addItem(item: IRequestItem, requestItems: FormArray<FormGroup<IRequestItemFormGroup>>): void {
        const newControl = new FormGroup<IRequestItemFormGroup>({
            requestItem: new FormControl({ value: item, disabled: false }),
            description: new FormControl({ value: item.description, disabled: true }),
            completed: new FormControl({ value: item.response?.complete?.state, disabled: false }),
        });
        requestItems.push(newControl);
    }

    private updateItem(
        oldItem: IRequestItem,
        item: IRequestItem,
        requestItems: FormArray<FormGroup<IRequestItemFormGroup>>,
    ): void {
        const controlIndex = this.findControlIndexByFileId(item.fileId, requestItems);
        const requestItemGroup = requestItems.at(controlIndex);

        if (this.hasItemStateChanged(oldItem, item)) {
            requestItemGroup.patchValue({
                completed: item.response?.complete?.state,
            });
        } else {
            requestItemGroup.patchValue({
                description: item.description,
            });
        }
    }

    private hasItemStateChanged(oldItem: Partial<IRequestItem>, currItem: Partial<IRequestItem>): boolean {
        return oldItem?.response?.complete?.state !== currItem?.response?.complete?.state;
    }

    private findControlIndexByFileId(
        fileId: string,
        requestItemControls: FormArray<FormGroup<IRequestItemFormGroup>>,
    ): number {
        return requestItemControls.controls.findIndex((control) => control.value.requestItem.fileId === fileId);
    }

    private markItemComplete(
        isTicked: boolean,
        requestItemControl: FormControl<IRequestItem>,
        analyticsPrefix: GA_EVENTS_PREFIX,
    ): void {
        this.analytics.recordEvent("mouse-click", `${analyticsPrefix}_markitemcomplete`);

        if (!requestItemControl || !requestItemControl.value?.fileId) {
            return;
        }

        const fileId = requestItemControl.value?.fileId;
        const stateThreadCard$ = combineLatest([this.state$, this.thread$, this.card$]).pipe(take(1));
        const updateRequestItem$ = stateThreadCard$.pipe(
            switchMap(([state, thread, card]) => this.updateRequestItem(state, thread.id, card.id, isTicked, fileId)),
        );

        this.updateRequestItemsSub?.unsubscribe();
        this.updateRequestItemsSub = updateRequestItem$.subscribe(() => {
            requestItemControl.markAsPristine();
        });
    }

    private updateRequestItem(
        state: IVaultRequestCardState,
        threadId: string,
        cardId: string,
        isTicked: boolean,
        fileId: string,
    ): Observable<void> {
        const isStateComplete = state.isCompleted;
        if (isStateComplete) {
            return of(null);
        }

        const requestUpdate = { fileId, complete: isTicked };
        const stateVaultId = state.vaultId;

        return from(this.vaultRequestService.updateRequest([requestUpdate], cardId, threadId, stateVaultId));
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        this.currentUserRole = modalData.role;
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.card$ = modalData.card$;
        this.state$ = modalData.state$;
        this.userId$ = this.authService.getUserId();
        this.canReopenRequest$ = of(false);
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultInstructionsRequest);

        this.canUpdateTodoListItem$ = this.rfiService.getCanUpdateItem(
            this.state$,
            this.thread$,
            this.card$,
            this.canReopenRequest$,
            this.readonly,
        );
        this.requestReviewModalTitles = this.vaultRequestService.getRequestReviewModalTitles(
            VaultCardType.VaultInstructionsRequest,
        );
    }

    private setForm(): void {
        const initialForm = {
            title: new FormControl(""),
            cardDescription: new FormControl(""),
            requestItems: new FormArray<FormGroup<IRequestItemFormGroup>>([]),
        };

        const formGroup = new FormGroup<IRequestForm>(initialForm);

        this.form$ = this.state$.pipe(
            startWith(null),
            pairwise(),
            scan((_, [oldState, currState]) => this.setRequestItems(formGroup, oldState, currState), formGroup),
        );

        this.requestItemsCompletedChangesSub = formGroup.valueChanges.subscribe((value) => {
            const requestItemsValues = Object.keys(value.requestItems).map((key) => value.requestItems[key]?.completed);
            const numberOfItems = requestItemsValues.length;
            const lengthOfCompletedItems = requestItemsValues.filter((value) => !!value);
            this.actionedRequestItems = lengthOfCompletedItems?.length || 0;
            this.actionedPercentage = Math.floor((this.actionedRequestItems / numberOfItems) * 100);
        });
    }
}
