import { Component, SimpleChanges, ViewChild } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { map, shareReplay, switchMap, take } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastSeverity, ToastSummary } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { MessageService } from "@visoryplatform/portal-ui";
import { MatDialog } from "@angular/material/dialog";
import { combineLatest, EMPTY, Observable, of, Subscription } from "rxjs";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import { MatDialogConfig } from "@angular/material/dialog";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { ThreadFilterService } from "projects/portal-modules/src/lib/threads-ui/services/thread-filter.service";
import { MatOption } from "@angular/material/core";
import { ALL_OPTION } from "projects/portal-modules/src/lib/timeline/constants/option-constants";
import { FilterOption } from "projects/portal-modules/src/lib/timeline/interfaces/timeline-filters";
import { ConfigurationsFilterService } from "../../services/configurations-filter.service";
import { UserProfileRouteService } from "projects/portal-modules/src/lib/user-profile/services/user-profile-route.service";

type AccountFilter = { label: string; id: string };
@Component({
    selector: "bulk-replace-user",
    templateUrl: "./bulk-replace-user.component.html",
    styleUrls: ["./bulk-replace-user.component.scss"],
})
export class BulkReplaceUserComponent {
    @ViewChild("allAccountsSelected") private allAccountsSelected: MatOption;

    userId$: Observable<string>;
    totalUserConfigurations$: Observable<number>;
    totalUserActiveWorkflows$: Observable<number>;

    loader = new Loader();

    tableHeaders = {
        service: "Service",
        reviewChange: "Review change",
        account: "Account",
        timelineTitle: "Workflow",
        replace: "Replace",
    };

    form = new FormGroup({
        updateConfigurations: new FormControl<boolean>(true, [Validators.required]),
        updateActiveWorkflows: new FormControl<boolean>(true, [Validators.required]),
        keepAsParticipant: new FormControl<boolean>(false, [Validators.required]),
        newUsers: new FormControl<string[]>([], [Validators.required]),
        excludeThreadIds: new FormControl<string[]>([]),
        excludeConfigurationIds: new FormControl<string[]>([]),
        selectedAccountIds: new FormControl<string[]>([], [Validators.required]),
    });

    readonly selectAllOption: FilterOption = {
        key: ALL_OPTION.key,
        value: ALL_OPTION.value,
    };

    accounts$: Observable<FilterOption[]>;

    private accountsLength = 0;
    private accountsSubscription: Subscription;

    constructor(
        private dialog: MatDialog,
        private userAssigneeService: UserAssigneeService,
        private messageService: MessageService,
        private threadFilterService: ThreadFilterService,
        private configurationsFilterService: ConfigurationsFilterService,
        private userProfileRouteService: UserProfileRouteService,
    ) {
        this.userId$ = this.userProfileRouteService.getUserId();

        this.accounts$ = combineLatest([
            this.userId$.pipe(switchMap((userId) => this.getActiveWorkflowAccounts(userId))),
            this.userId$.pipe(switchMap((userId) => this.getConfigurationAccounts(userId))),
        ]).pipe(
            map(([workflowAccounts, configAccounts]) =>
                this.deduplicateAccounts([...workflowAccounts, ...configAccounts]),
            ),
            shareReplay(1),
        );

        this.accountsSubscription = this.accounts$.subscribe((accounts) => {
            this.accountsLength = accounts.length;
            this.form.controls.selectedAccountIds.patchValue([
                this.selectAllOption.key,
                ...accounts.map((account) => account.key),
            ]);
        });
    }

    public toggleOneItem(): void {
        if (this.allAccountsSelected.selected) {
            this.allAccountsSelected.deselect();
        }
        if (this.form.controls.selectedAccountIds.value?.length === this.accountsLength) {
            this.allAccountsSelected.select();
        }
    }

    public toggleSelectAll(): void {
        if (this.allAccountsSelected.selected) {
            this.accounts$.pipe(take(1)).subscribe((accounts) => {
                this.form.controls.selectedAccountIds.patchValue([
                    this.selectAllOption.key,
                    ...accounts.map((account) => account.key),
                ]);
            });
        } else {
            this.form.controls.selectedAccountIds.patchValue([]);
        }
    }

    ngOnInit(): void {
        this.totalUserConfigurations$ = this.form.valueChanges.pipe(
            switchMap(() => {
                const { updateConfigurations } = this.form.value;
                return this.getTotalUserConfigurations(updateConfigurations);
            }),
        );

        this.totalUserActiveWorkflows$ = this.form.valueChanges.pipe(
            switchMap(() => {
                const { updateActiveWorkflows } = this.form.value;
                return this.getTotalUserActiveWorkflows(updateActiveWorkflows);
            }),
        );
    }

    ngOnDestroy(): void {
        this.accountsSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { selectedAccountIds } = changes;
        if (selectedAccountIds) {
            this.form.controls.excludeThreadIds.reset([]);
            this.form.controls.excludeConfigurationIds.reset([]);
        }
    }

    async confirmBulkAndReplace(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const dialogConfig = await this.getConfirmModalConfig();
        this.dialog
            .open(ConfirmModalComponent, dialogConfig)
            .afterClosed()
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return EMPTY;
                    }

                    const bulkUpdateAssignees$ = this.updateActiveWorkflows().pipe(take(1));
                    const bulkUpdateConfigurations$ = this.updateConfigurations().pipe(take(1));
                    return this.loader.wrap(combineLatest([bulkUpdateAssignees$, bulkUpdateConfigurations$]));
                }),
            )
            .subscribe(() => {
                this.messageService.add({
                    severity: ToastSeverity.Success,
                    summary: ToastSummary.Success,
                    detail: "Success! It may take some time for bulk changes to be reflected. You will receive a notification when it is completed.",
                });
            });
    }

    private deduplicateAccounts<T extends { key: string }>(accounts: T[]): T[] {
        return Array.from(new Map(accounts.map((item) => [item.key, item])).values());
    }

    private updateActiveWorkflows(): Observable<void | null> {
        const { newUsers, keepAsParticipant, excludeThreadIds, updateActiveWorkflows, selectedAccountIds } =
            this.form.value;
        if (!updateActiveWorkflows) {
            return of(null);
        }

        return this.userId$.pipe(
            switchMap((userId) => {
                return this.userAssigneeService.replaceUserWorkflows(
                    userId,
                    keepAsParticipant,
                    newUsers,
                    excludeThreadIds,
                    selectedAccountIds,
                );
            }),
        );
    }

    private getActiveWorkflowAccounts(userId: string): Observable<FilterOption[]> {
        return this.threadFilterService.getFilteredThreads<AccountFilter>("account", undefined, undefined, userId).pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((accounts) => accounts.map((account) => ({ key: account.id, value: account.label }))),
        );
    }

    private getConfigurationAccounts(userId: string): Observable<FilterOption[]> {
        return this.configurationsFilterService.getUserConfigurationsFilters(userId).pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((accounts) => accounts.map((account) => ({ key: account.id, value: account.label }))),
        );
    }

    private updateConfigurations(): Observable<void | null> {
        const { newUsers, excludeConfigurationIds, updateConfigurations, selectedAccountIds } = this.form.value;
        if (!updateConfigurations) {
            return of(null);
        }

        return this.userId$.pipe(
            switchMap((userId) => {
                return this.userAssigneeService.replaceUserConfigurations(
                    userId,
                    newUsers,
                    excludeConfigurationIds,
                    selectedAccountIds,
                );
            }),
        );
    }

    private async getConfirmModalConfig(): Promise<MatDialogConfig> {
        const [userConfigurations, userWorkflows] = await this.getAllTotalUser().toPromise();
        const workflowText = userWorkflows === 1 ? "workflow" : `workflows`;
        const configurationsText = userConfigurations === 1 ? "configuration" : `configurations`;
        const selectedAccountsTotal = this.form.value.selectedAccountIds.includes("all")
            ? this.form.value.selectedAccountIds.length - 1
            : this.form.value.selectedAccountIds.length;
        const accountsText =
            this.form.value.selectedAccountIds.length - 1 > 0
                ? `across ${selectedAccountsTotal} accounts`
                : "in 1 account";
        const areYouSureText = `This change will affect ${userWorkflows} ${workflowText} and ${userConfigurations} ${configurationsText} ${accountsText}.`;

        return {
            data: {
                confirmText: "confirm",
                declineText: "Cancel",
                promptText: "Are you sure you want to make this change?",
                areYouSureText,
                analyticsPrefix: GA_EVENTS_PREFIX.BULK_REPLACE_CONFIRM_MODAL,
            },
            panelClass: ["centered-modal"],
            width: "420px",
        };
    }

    private getAllTotalUser(): Observable<number[]> {
        const { updateActiveWorkflows, updateConfigurations } = this.form.value;
        const totalUserConfigurations$ = this.loader
            .wrap(this.getTotalUserConfigurations(updateConfigurations))
            .pipe(take(1));
        const totalUserActiveWorkflows$ = this.loader
            .wrap(this.getTotalUserActiveWorkflows(updateActiveWorkflows))
            .pipe(take(1));

        return combineLatest([totalUserConfigurations$, totalUserActiveWorkflows$]);
    }

    private getTotalUserConfigurations(updateConfigurations: boolean): Observable<number> {
        if (!updateConfigurations) {
            return of(0);
        }

        const { excludeConfigurationIds } = this.form.value;

        return this.userId$.pipe(
            switchMap((userId) => {
                const filteredAccountIds = this.form.value.selectedAccountIds.includes(this.selectAllOption.key)
                    ? null
                    : this.form.value.selectedAccountIds;
                return this.userAssigneeService.listConfigurations(userId, 0, 0, filteredAccountIds);
            }),
            map((paginated) => paginated?.total || 0),
            map((total) => total - excludeConfigurationIds.length),
        );
    }

    private getTotalUserActiveWorkflows(updateActiveWorkflows: boolean): Observable<number> {
        if (!updateActiveWorkflows) {
            return of(0);
        }

        const { excludeThreadIds } = this.form.value;

        return this.userId$.pipe(
            switchMap((userId) => {
                const filteredAccountIds = this.form.value.selectedAccountIds.includes(this.selectAllOption.key)
                    ? null
                    : this.form.value.selectedAccountIds;
                return this.userAssigneeService.listTimelines(userId, 0, 0, undefined, filteredAccountIds);
            }),
            map((paginated) => paginated?.total || 0),
            map((total) => total - excludeThreadIds.length),
        );
    }
}
