import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { MessageCardComponent } from "./components/message-card/message-card.component";
import { MessagePlugin } from "./message.plugin";
import { SharedModule } from "projects/portal-modules/src/lib/shared";

@NgModule({
    imports: [CommonModule, FxUiModule, ThreadsUiModule, MatDialogModule, FindexUiModule, SharedModule],
    exports: [MessageCardComponent],
    declarations: [MessageCardComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: MessagePlugin, multi: true }],
})
export class MessagePluginModule {}
