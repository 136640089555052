import { Injectable } from "@angular/core";
import { ICardTaskDetail, IThread, ITimeline, ThreadFilters } from "@visoryplatform/threads";
import { ThreadCardService } from "../../services/thread-card.service";
import { TaskNotificationsService } from "../../../notifications/services/task-notifications.service";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";
import { PortalService } from "../../../shared/services/portal.service";
import { EMPTY, Observable } from "rxjs";
import { mergeMap, toArray } from "rxjs/operators";
import { expand } from "rxjs/operators";

@Injectable()
export class CloseThreadService {
    constructor(
        private cardsService: ThreadCardService,
        private taskNotificationService: TaskNotificationsService,
        private calendarService: CalendarService,
        private portalService: PortalService,
    ) {}

    async getPendingTasks(threadId: string): Promise<ICardTaskDetail[]> {
        return this.taskNotificationService.getThreadCardTasks(threadId).toPromise();
    }

    async threadHasUpcomingMeetings(threadId: string): Promise<boolean> {
        const allUpcomingMeetings = await this.calendarService.getUpcomingMeetings(threadId).toPromise();
        return !!allUpcomingMeetings?.length;
    }

    async findThreadsToMigrateMeetings(thread: IThread): Promise<IThread[]> {
        const requiredParticipantIds = thread.participants.map((participant) => participant.id);

        const activeThreads = await this.getAllThreads({
            internalStepStatus: "active",
            account: thread.accountId,
        }).toPromise();

        return activeThreads
            .filter((item) => item.id !== thread.id)
            .filter((item) => {
                const participantIds = item.participants.map((participant) => participant.id);
                return requiredParticipantIds.every((requiredParticipantId) =>
                    participantIds.some((participantId) => requiredParticipantId === participantId),
                );
            });
    }

    async migrateCalendarCards(threadId: string, targetThreadId: string): Promise<void> {
        return this.cardsService.migrateCalendarCards(threadId, targetThreadId).toPromise();
    }

    private getAllThreads(searchParams?: ThreadFilters): Observable<ITimeline[]> {
        return this.portalService.getSearchThreadList(undefined, 100, searchParams).pipe(
            expand((page) =>
                page?.next ? this.portalService.getSearchThreadList(page.next, 100, searchParams) : EMPTY,
            ),
            mergeMap((page) => page.result),
            toArray(),
        );
    }
}
