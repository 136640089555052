import { Component, Injector, OnInit } from "@angular/core";
import { VaultRequestService } from "../../../services/vault-request.service";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { ITimeline, IThreadCard, IVaultRequestCardState } from "@visoryplatform/threads";
import { IUpdatedRequestState } from "../interfaces/IUpdatedRequestState";
import { RequestActionButtonLabel, RequestStatuses, VaultCardType } from "../constants/request.constants";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { take, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IRequestForm } from "../interfaces/IRequestForms";
import { forkJoin, Observable } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

@Component({
    selector: "edit-payrun-request",
    templateUrl: "./edit-payrun-request.component.html",
    styleUrls: ["./edit-payrun-request.component.scss"],
})
export class EditPayrunRequestComponent extends SubscriberBaseComponent implements OnInit {
    readonly buttonLabel = RequestActionButtonLabel;
    readonly requestStatuses = RequestStatuses;

    actionedPercentage = 0;
    analyticsTags: IRequestAnalyticsTags;
    card$: Observable<IThreadCard>;
    form: FormGroup<IRequestForm>;
    loader = new Loader();
    readonly: boolean;
    state$: Observable<IVaultRequestCardState>;
    thread$: Observable<ITimeline>;
    updatedRequestState: IUpdatedRequestState;

    modalData: IRequestModalData;
    private dialogRef: DialogRef;

    constructor(
        private unsavedDialogService: UnsavedModalDialogService,
        private vaultRequestService: VaultRequestService,
        private injector: Injector,
        private dialogService: DialogService,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.modalData = await this.dialogService.getData<IRequestModalData>(this.injector).toPromise();

        this.initForm();
        this.setLocalVariables(this.modalData);
        this.updateCardDescription(this.card$);

        this.updatedRequestState = {
            attachmentsAdded: [],
            attachmentsDeleted: [],
            requestItemsRemoved: [],
        };
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultRequest);
    }

    updateRequestState(card: IThreadCard, thread: ITimeline, state: IVaultRequestCardState): void {
        const saved$ = this.vaultRequestService.sendRequestCardEvent(thread.id, card.id, card.type, {
            body: { saved: true },
        });
        const updateRequestState$ = this.vaultRequestService.updateRequestState(
            card,
            thread,
            this.form,
            state,
            this.updatedRequestState,
        );

        this.loader
            .wrap(forkJoin([saved$, updateRequestState$]))
            .pipe(take(1))
            .subscribe(() => {
                this.loader.hide();
                this.dialogRef.close();
            });
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payrun-edit");
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }

    private initForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(""),
            cardDescription: new FormControl("", Validators.required),
        });
    }

    private setLocalVariables(modalData: IRequestModalData): void {
        this.readonly = modalData.readonly;
        this.thread$ = modalData.thread$;
        this.state$ = modalData.state$;
        this.state$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
            this.updateRequestProgress(state);
        });
        this.card$ = modalData.card$;
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultPayrollApprovalRequest);
    }

    private updateRequestProgress(state: IVaultRequestCardState): void {
        const progress = VaultRequestService.calculateProgress(state.requestItems);
        this.actionedPercentage = progress.actionedPercentage;
    }

    private updateCardDescription(card$: Observable<IThreadCard>): void {
        card$.pipe(take(1)).subscribe((card) => {
            this.form.patchValue({
                cardDescription: card.description,
            });
        });
    }
}
