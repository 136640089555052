import { Injectable } from "@angular/core";
import { VisoryLegacyWorkflows } from "src/environments/sigma-feature-flags";
import { CalendarEventColor } from "../interfaces/CalendarEventColor";

@Injectable({
    providedIn: "root",
})
export class ColorService {
    public readonly defaultColors = {
        background: "#00adf0",
        selectedSolid: "#fed4e8",
        targetLine: "#3f3f3f",
        lightGrey: "#bbbbbb",
    };

    private colorStack = ["#004B78", "#CCD6DC", "#2367AB", "#78A2CC", "#00ADF0", "#8FF8FF", "#6B7683", "#16D3D3"];
    private colorMap = new Map();

    constructor() {
        this.colorMap.set(VisoryLegacyWorkflows.Bookkeeping, "#004B78");
        this.colorMap.set(VisoryLegacyWorkflows.AccountsFortnightlyPayable, "#007FB0");
        this.colorMap.set(VisoryLegacyWorkflows.AccountsWeeklyPayable, "#007FB0");
        this.colorMap.set(VisoryLegacyWorkflows.AccountsMonthlyPayable, "#007FB0");
        this.colorMap.set(VisoryLegacyWorkflows.PayrollWeekly, "#A58CD3");
        this.colorMap.set(VisoryLegacyWorkflows.PayrollFortnightly, "#A58CD3");
        this.colorMap.set(VisoryLegacyWorkflows.PayrollMonthly, "#A58CD3");
        this.colorMap.set(VisoryLegacyWorkflows.AccountManagement, "#7979A9");
        this.colorMap.set(VisoryLegacyWorkflows.PeopleAndCulture, "#8E9395");
    }

    public getColor(classification: string): string {
        const color = this.colorMap.get(classification);

        return color ?? this.getAssignedColor(classification);
    }

    public getCalendarEventColor(type: string): CalendarEventColor {
        const primaryColor = this.getColor(type);
        const secondaryColor = primaryColor + "20";

        return { primary: primaryColor, secondary: secondaryColor };
    }

    private getAssignedColor(classification: string): string {
        const colorFromStack = this.colorStack.pop();
        const assignedColor = colorFromStack ?? this.getGeneratedColor();
        this.colorMap.set(classification, assignedColor);

        return this.colorMap.get(classification);
    }

    private getGeneratedColor(): string {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
}
