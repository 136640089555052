import { Component, Input, OnDestroy, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IParticipant, Role } from "@visoryplatform/threads";
import { Observable, Subscription, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, mapTo, switchMap } from "rxjs/operators";

import { GA_EVENTS } from "../../../../../analytics";
import { Loader } from "../../../../../shared/services/loader";
import { ParticipantService } from "../../../../services/participant.service";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddParticipantComponent),
    multi: true,
};

@Component({
    selector: "add-participant",
    templateUrl: "./add-participant.component.html",
    styleUrls: ["./add-participant.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class AddParticipantComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() label = "Assign to";
    @Input() disabledDescription;

    assignees = new FormControl<string[]>([]);
    loader = new Loader();
    searchLoader = new Loader();
    searchResults$: Observable<IParticipant[]>;
    searchTerm = new FormControl<string>("");
    subscription: Subscription;

    onChanged?: (newValue?: string[]) => void;
    onTouched?: () => void;

    protected readonly Role = Role;
    protected readonly GA_EVENTS = GA_EVENTS;

    constructor(private participantService: ParticipantService) {}

    writeValue(obj: string[]): void {
        this.assignees.setValue(obj);
    }

    registerOnChange(fn: (newValue?: string[]) => void): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchTerm.disable();
            this.assignees.disable();
        } else {
            this.searchTerm.enable();
            this.assignees.enable();
        }
    }

    ngOnInit(): void {
        const searchResults$ = this.searchTerm.valueChanges.pipe(
            filter((searchTerm) => searchTerm?.length > 2),
            distinctUntilChanged(),
            debounceTime(500),
            switchMap((searchTerm) => this.searchLoader.wrap(this.search(searchTerm))),
        );

        const noResults$ = this.searchTerm.valueChanges.pipe(
            filter((searchTerm) => searchTerm?.length <= 2),
            mapTo(null),
        );

        this.searchResults$ = merge(searchResults$, noResults$);

        this.subscription = this.assignees.valueChanges.subscribe((values) => {
            if (this.onChanged) {
                this.onChanged(values);
            }
            if (this.onTouched) {
                this.onTouched();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    selectUser(searchResult: IParticipant): void {
        const hasAssignee = this.assignees.value.indexOf(searchResult.id) !== -1;
        if (!hasAssignee) {
            const newAssignees = [...this.assignees.value, searchResult.id];
            this.setAssignees(newAssignees);
        }
        this.searchTerm.setValue("");
    }

    removeAssignee(assigneeId: string): void {
        const newAssignees = this.assignees.value.filter((id) => id !== assigneeId);
        this.setAssignees(newAssignees);
    }

    private setAssignees(assigneeIds: string[]): void {
        this.assignees?.patchValue(assigneeIds);
        this.assignees?.markAsDirty();
        this.assignees?.markAsTouched();
    }

    private search(term: string): Observable<IParticipant[]> {
        return this.participantService.searchAllUsers(term);
    }
}
