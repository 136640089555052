import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { InsightsMetricService } from "../../services/insights-metric.service";
import { MetricBenchmark, Period, LatestTableData, TableReportRow } from "@visoryplatform/openmeasures-core";

@Component({
    selector: "insights-metric-summary",
    templateUrl: "./insights-metric-summary.component.html",
    styleUrls: ["./insights-metric-summary.component.scss"],
})
export class InsightsMetricSummaryComponent implements OnChanges {
    @Input() public set tableReportRow(tableReportRow: TableReportRow) {
        this.setMetricsUI(tableReportRow);
        this._tableReportRow = tableReportRow;
    }

    @Input() public showBenchmarking: boolean;
    @Input() public report: LatestTableData;
    @Input() public comparisonArea = "In comparison to upper quartile";

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    public get tableReportRow(): TableReportRow {
        return this._tableReportRow;
    }

    public targetValue: number;
    public isMetricBelowTarget: boolean;
    public isMetricBetterThenTarget: boolean;
    public period: Period;

    private _tableReportRow: TableReportRow;

    constructor(private insightsMetricService: InsightsMetricService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { report, tableReportRow } = changes;

        if (report || tableReportRow) {
            this.period = this.report?.periods?.find((period) => period.id === this.tableReportRow?.result?.periodId);
        }
    }

    public setTargetValue(tableReportRow: TableReportRow): void {
        this.targetValue =
            ((tableReportRow?.result?.value - tableReportRow?.target?.targetValue) /
                tableReportRow?.target?.targetValue) *
            100;
    }

    public getBenchmarkValue(tableReportRow: TableReportRow, benchmark: MetricBenchmark): number {
        return ((tableReportRow?.result?.value - benchmark?.percentiles[3]) / benchmark?.percentiles[3]) * 100;
    }

    private setMetricsUI(tableReportRow: TableReportRow): void {
        this.isMetricBelowTarget = this.insightsMetricService.isMetricBelow(tableReportRow);
        this.isMetricBetterThenTarget = this.insightsMetricService.isMetricBetterThanTarget(tableReportRow);
        this.setTargetValue(tableReportRow);
    }
}
