<centered-modal-layout
    cancelButtonTitle="Cancel"
    saveButtonTitle="Add"
    analyticsPrefix="add-enterprise-dialog"
    [disableSave]="false"
    class="add-enterprise"
    (save)="save(dialogData.userId, enterprisesControl.value)"
    (close)="close()"
>
    <div modal-header>Add enterprise</div>
    <div modal-subhead>
        <app-thread-search
            [analyticsClickEvent]="gaEvents.INSIGHTS_ADD_ENTERPISE_SEARCH"
            [isMobileView]="isMobileView"
            (search)="updateSearchTerm($event)"
            placeholder="Search enterprise name"
        ></app-thread-search>
    </div>
    <div modal-content>
        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>
        <div
            class="add-enterprise-list"
            *ngIf="enterprises$ | async as enterprises"
        >
            <fx-checkbox
                *ngFor="let enterprise of enterprises; trackBy: 'id' | trackProperty"
                class="add-enterprise-list-item"
                [checked]="enterprisesControl.value.includes(enterprise.id)"
                (change)="toggleEnterprise(enterprise.id)"
            >
                {{ enterprise.label }}
            </fx-checkbox>
        </div>
    </div>
</centered-modal-layout>
