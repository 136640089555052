<centered-modal-layout
    (save)="acceptPrompt()"
    (close)="close()"
    cancelButtonTitle="Cancel"
    saveButtonTitle="Continue"
    [analyticsPrefix]="gaEvents.VAULT_LIST_CONFIRM_DELETE"
>
    <div modal-header>Are you sure you want to delete this file?</div>
    <div modal-content>Deleting files will permanently remove them from the platform.</div>
</centered-modal-layout>
