<thread-card
    [title]=""
    [thread]="cardResources.thread$ | async"
    [card]="cardResources.card$ | async"
    [loading]="!!loader.counter"
    [inCardReplies]="false"
    [isInternal]="true"
>
    <div
        *ngIf="cardResources.state$ | async as state"
        [ngClass]="{ 'transforms-card-panel--active': isThreadActive$ | async }"
        class="transforms-card-panel"
        (click)="openModal()"
    >
        <div class="transforms-card-panel-header">
            <h4 class="transforms-card-heading">{{ state.label }}</h4>

            <fx-badge
                [label]="(isComplete$ | async) ? 'Completed' : 'Pending'"
                [type]="(isComplete$ | async) ? 'success' : 'default'"
            ></fx-badge>
        </div>

        <div class="transforms-card-panel-info">
            <div class="transforms-card-panel-progress">
                <progress-bar [progress]="actionedPercentage$ | async"></progress-bar>
                <div class="transforms-card-panel-progress-actioned">
                    {{ actionAmmount$ | async }} / {{ actionTotal$ | async }} actioned
                </div>
            </div>
        </div>
    </div>

    <button
        *ngIf="hasPermissionToAction$ | async"
        class="transforms-card-button fx-btn fx-btn--primary"
        (click)="openModal()"
    >
        REVIEW
    </button>

    <div card-loader>
        <loader-icon color="light"></loader-icon>
    </div>
</thread-card>
