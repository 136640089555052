import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { HttpClient } from "@angular/common/http";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { TransformSourceResult } from "@visoryplatform/transformers";

@Injectable({
    providedIn: "root",
})
export class TransformersService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    runSource(threadId: string, sourceId: string, event: unknown): Observable<void> {
        const url = `${this.environment.threadsEndpoints.base}/transforms/threads/${threadId}/sources/${sourceId}`;
        return this.http.post<void>(url, event);
    }

    getSourceResults(transformerId: string): Observable<TransformSourceResult[]> {
        const url = `${this.environment.threadsEndpoints.base}/checks/${transformerId}/results`;
        return this.http.get<TransformSourceResult[]>(url);
    }
}
