import {
    DUE_DATE_EXTENSION_TYPE,
    IStep,
    IWorkflow,
    IWorkflowDesign,
    MILESTONE_EXTENSION_TYPE,
    SLA_EXTENSION_TYPE,
    WorkflowTypesService,
} from "@visoryplatform/workflow-core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class WorkflowValidationService {
    validateWorkflow(workflowDesign: IWorkflowDesign, defaultWorkflow?: IWorkflow): boolean {
        if (!defaultWorkflow) {
            return true;
        }

        const workflowDesignSteps: IStep[] = Object.values(workflowDesign.steps);
        const defaultWorkflowSteps: IStep[] = Object.values(defaultWorkflow.steps);

        if (workflowDesignSteps?.length !== defaultWorkflowSteps?.length) {
            return false;
        }

        return workflowDesignSteps.every((designStep) => {
            const defaultWorkflowStep = defaultWorkflow.steps[designStep.id];

            if (!defaultWorkflowStep) {
                return false;
            }

            return this.checkExtensionInputsMatchDesign(defaultWorkflowStep, designStep);
        });
    }

    private checkExtensionInputsMatchDesign(defaultWorkflowStep: IStep, designStep: IStep): boolean {
        const defaultWorkflowExtensions = defaultWorkflowStep.extensions;
        const designExtensions = designStep.extensions;

        return designExtensions.every((designExtension) => {
            const defaultWorkflowExtension = defaultWorkflowExtensions.find(
                (defaultExtension) => defaultExtension?.type === designExtension?.type,
            );
            const designInput = WorkflowTypesService.getStaticExtensionsInput(
                designStep,
                defaultWorkflowExtension?.type,
            );
            const defaultInput = WorkflowTypesService.getStaticExtensionsInput(
                defaultWorkflowStep,
                defaultWorkflowExtension?.type,
            );

            if (designInput && !defaultInput) {
                return false;
            }

            switch (defaultWorkflowExtension?.type) {
                case DUE_DATE_EXTENSION_TYPE:
                    return defaultInput;
                case SLA_EXTENSION_TYPE:
                    return defaultInput?.data?.duration === designInput?.data?.duration;
                case MILESTONE_EXTENSION_TYPE:
                    return defaultInput?.data?.isMilestone === designInput?.data?.isMilestone;
                default:
                    return true;
            }
        });
    }
}
