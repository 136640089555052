import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";

import { ThreadWorkflowStatusComponent } from "./components/thread-workflow-status/thread-workflow-status.component";
import { ThreadWorkflowProgressIndicatorComponent } from "./components/thread-workflow-progress-indicator/thread-workflow-progress-indicator.component";
import { WorkflowStepListComponent } from "./components/workflow-step-list/workflow-step-list.component";
import { WorkflowStepEntryComponent } from "./components/workflow-step-entry/workflow-step-entry.component";
import { ThreadStateModalComponent } from "./components/thread-state-modal/thread-state-modal.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";
import { AnalyticsModule } from "../analytics";
import { FindexUiModule } from "../findex-ui";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { VisibleEntriesPipe } from "./pipes/visible-entries.pipe";
import { ThreadsUiPipesModule } from "../threads-ui/pipes/threads-ui-pipes.module";
import { RouterModule } from "@angular/router";
import { TuiHintModule } from "@taiga-ui/core";
import { UiComponentsModule } from "../ui-components";
import { EnableStepActionPipe } from "./pipes/enable-step-action.pipe";
import { ShowStepActionPipe } from "./pipes/show-step-action.pipe";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { TimelineParticipantsComponent } from "./components/timeline-participants/timeline-participants.component";
import { ThreadParticipantsModule } from "../threads-ui/modules/thread-participants";
import { SlaRemainingComponent } from "./components/sla-remaining/sla-remaining.component";
import { DurationBadgeComponent } from "./components/duration-badge/duration-badge.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FxUiModule,
        ThreadsUiPipesModule,
        SharedPipesModule,
        AnalyticsModule,
        FindexUiModule,
        TuiHintModule,
        UiComponentsModule,
        PortalUiModule,
        ThreadParticipantsModule,
    ],
    declarations: [
        ThreadWorkflowStatusComponent,
        SlaRemainingComponent,
        ThreadWorkflowProgressIndicatorComponent,
        WorkflowStepEntryComponent,
        WorkflowStepListComponent,
        ThreadStateModalComponent,
        StatusBadgeComponent,
        TimelineParticipantsComponent,
        VisibleEntriesPipe,
        EnableStepActionPipe,
        ShowStepActionPipe,
        DurationBadgeComponent,
    ],
    exports: [
        ThreadWorkflowStatusComponent,
        WorkflowStepListComponent,
        ThreadStateModalComponent,
        DurationBadgeComponent,
        SlaRemainingComponent,
        ShowStepActionPipe,
    ],
    providers: [],
})
export class WorkflowStatusModule {}
