import { Injectable } from "@angular/core";
import { CalendarEvent } from "angular-calendar";
import { ColorService } from "../../shared/services/color.service";
import { formatISO, isSameDay, startOfMonth } from "date-fns";
import { IUpcomingMeeting, IWorkflowEvent, InternalRoles, Role } from "@visoryplatform/threads";
import { CalendarEventsApiService } from "./calendar-events-api.service";
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import { Loader } from "../../shared/services/loader";
import { Router } from "@angular/router";
import { AppUser, AuthService } from "../../findex-auth";
import { environmentCommon } from "../../environment/environment.common";
import { CalendarFilters } from "../interfaces/CalendarFilters";

@Injectable({
    providedIn: "root",
})
export class CalendarEventsService {
    private calendarEventsSource$ = new BehaviorSubject<CalendarEvent[]>([]);
    public readonly calendarEvents$ = this.calendarEventsSource$.asObservable();

    private calendarViewDateSource$ = new BehaviorSubject<Date>(new Date());
    public readonly calendarViewDate$ = this.calendarViewDateSource$.asObservable();

    public readonly loader = new Loader();

    private eventSubscription: Subscription;
    private calendarFiltersSource$ = new BehaviorSubject<CalendarFilters>({
        selectedAccounts: [],
        selectedServices: [],
    });
    private colorService = new ColorService();
    private readonly tags = {
        meeting: "meeting",
        milestone: "milestone",
    };

    constructor(
        private calendarEventsApiService: CalendarEventsApiService,
        private authService: AuthService,
        private router: Router,
    ) {}

    public setCalendarEvents(viewDate: Date): void {
        const calendarFilters = this.calendarFiltersSource$.value;
        const selectedAccounts = calendarFilters.selectedAccounts.filter(
            (account) => account !== environmentCommon.calendarFilterSelectAllOptions.allAccounts,
        );
        const selectedServices = calendarFilters.selectedServices.filter(
            (service) => service !== environmentCommon.calendarFilterSelectAllOptions.allServices,
        );
        const ISODate = formatISO(viewDate);
        const startDate = startOfMonth(viewDate);

        const userRole$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            shareReplay(1),
        );

        //Not a fan of doing this, but mainly here to fix the Sentry bug
        this.eventSubscription?.unsubscribe();
        this.eventSubscription = userRole$
            .pipe(
                switchMap((userRole) =>
                    this.getCalendarEvents(
                        userRole,
                        ISODate,
                        startDate,
                        selectedAccounts,
                        selectedServices,
                        calendarFilters,
                    ),
                ),
            )
            .subscribe((calendarEvents) => this.calendarEventsSource$.next(calendarEvents));
    }

    private getCalendarEvents(
        userRole: Role,
        isoDate: string,
        startDate: Date,
        selectedAccounts: string[],
        selectedServices: string[],
        calendarFilters: CalendarFilters,
    ): Observable<CalendarEvent[]> {
        const upcomingMeetings$ = this.loader.wrap(this.calendarEventsApiService.getUpcomingMeetings());
        const workflowEvents$ = this.loader.wrap(
            this.calendarEventsApiService.getWorkflowEvents(isoDate, selectedAccounts, selectedServices),
        );

        return combineLatest([upcomingMeetings$, workflowEvents$]).pipe(
            map(([upcomingMeetings, workflowEvents]) => {
                const mappedMeetings = this.mapUpcomingMeetings(upcomingMeetings, startDate, calendarFilters);
                const eventsFilteredByRole = workflowEvents.filter(
                    (milestone: IWorkflowEvent) => !milestone.hideToRoles?.includes(userRole),
                );
                const mappedEvents = this.mapWorkflowEvents(eventsFilteredByRole);
                const combined = [...mappedEvents, ...mappedMeetings];
                // we need this sort for Agenda View
                return combined.sort((a, b) => Number(a.start) - Number(b.start));
            }),
        );
    }

    public setViewDate(viewDate: Date): void {
        this.calendarViewDateSource$.next(viewDate ?? new Date());
    }

    public setCalendarFilters(userId: string, filters: CalendarFilters): Observable<void> {
        this.calendarFiltersSource$.next(filters);
        return this.calendarEventsApiService.saveCalendarFilter(userId, filters);
    }

    private mapUpcomingMeetings(
        meetings: IUpcomingMeeting[],
        startDate: Date,
        filters: CalendarFilters,
    ): CalendarEvent[] {
        if (!meetings?.length) {
            return [];
        }

        const allMeetingInstances = meetings.map((meeting) => {
            return meeting.instances?.map((instance) => {
                return {
                    ...meeting,
                    nextInstance: instance,
                    instances: [],
                };
            });
        });

        const meetingsInSelectedMonth: IUpcomingMeeting[] = []
            .concat(...allMeetingInstances)
            .filter((meeting: IUpcomingMeeting) => {
                const meetingMonth = startOfMonth(new Date(meeting?.nextInstance.start));
                return isSameDay(meetingMonth, startDate);
            });

        const filterUpcomingMeetings = this.filterUpcomingMeetings(filters, meetingsInSelectedMonth);

        return filterUpcomingMeetings.map((meeting: IUpcomingMeeting) => {
            const { cardId, threadId, title } = meeting;
            const { start, end } = meeting.nextInstance;
            const greyColor = this.colorService.defaultColors.lightGrey;
            const color = { primary: greyColor, secondary: greyColor };
            const tag = this.tags.meeting;
            const calendarEvent = {
                id: tag + cardId + threadId,
                start: new Date(start),
                end: new Date(end),
                title: title,
                color: color,
                meta: meeting,
            };
            return calendarEvent;
        });
    }

    private filterUpcomingMeetings(filters: CalendarFilters, meetingsInSelectedMonth: IUpcomingMeeting[]) {
        if (filters.selectedAccounts?.length) {
            return meetingsInSelectedMonth.filter((meeting: IUpcomingMeeting) =>
                this.filterIncludesAccount(filters, meeting.accountId),
            );
        } else {
            return [];
        }
    }

    private filterIncludesAccount(filters: any, accountId: string): boolean {
        return filters.selectedAccounts?.length ? filters.selectedAccounts.includes(accountId) : true;
    }

    private mapWorkflowEvents(workflowEvents: IWorkflowEvent[]): CalendarEvent[] {
        if (!workflowEvents?.length) {
            return [];
        }

        return workflowEvents.map((event: IWorkflowEvent) => {
            const { clientFacingId, threadId, endDate, clientFacingName, type } = event;
            const color = this.colorService.getCalendarEventColor(type);
            const tag = this.tags.milestone;
            const calendarEvent = {
                id: tag + clientFacingId + threadId,
                start: new Date(endDate),
                end: new Date(endDate),
                title: clientFacingName,
                color: color,
                meta: event,
            };

            return calendarEvent;
        });
    }

    public calendarEventClicked(event: CalendarEvent): Observable<boolean> {
        return this.authService.getUser().pipe(
            switchMap((user: AppUser) => {
                if (InternalRoles.includes(user.globalRole)) {
                    const threadId = event.meta.threadId;
                    return this.router.navigate(["/timelines", threadId]);
                } else {
                    return EMPTY;
                }
            }),
        );
    }
}
