import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChildFn, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AppUser } from "../model/AppUser";

const MOBILE_VERIFICATION_PATH = "/register/verify-mobile";

const registrationUrls = ["/register/signup", "/register/email-success", "/login", "/register/verify-mobile"];
const verificationUrls = [MOBILE_VERIFICATION_PATH];

export const verifiedGuard: CanActivateChildFn = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean> => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const checkVerification = (user: AppUser, url: string): Observable<boolean> => {
        if (user && !user.mobileNumberVerified) {
            if (url !== MOBILE_VERIFICATION_PATH) {
                router.navigateByUrl(MOBILE_VERIFICATION_PATH);
                return of(false);
            }
        } else {
            if (verificationUrls.includes(url) || registrationUrls.includes(url)) {
                router.navigateByUrl("/dashboard");
                return of(false);
            }
        }
        return of(true);
    };

    return authService.getUserWithoutRole().pipe(
        switchMap((user) => {
            if (user) {
                return checkVerification(user, state.url);
            } else {
                // Leave it to the auth guard
                return of(true);
            }
        }),
    );
};
