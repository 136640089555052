import { Account, ITimeline, ThreadTypes } from "@visoryplatform/threads";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Injectable } from "@angular/core";
import { ListAccountMemoriesComponent } from "./components/list-account-memories/list-account-memories.component";
import { ListThreadMemoriesComponent } from "./components/list-thread-memories/list-thread-memories.component";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";

@Injectable()
export class MemoriesPlugin implements IPluginFactory {
    readonly id = "MemoriesPlugin";
    readonly gaEvents = GA_EVENTS;

    private featureEnabled$: Observable<boolean>;
    private brandingEnabled$: Observable<boolean>;

    constructor(
        private libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableMemories);
        this.brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

        this.registerExtensions();
    }

    private registerExtensions(): void {
        const accountMemories: RouteExtension<Account> = {
            label: "Memories",
            icon: "ai-icon",
            showIcon: () => this.brandingEnabled$,
            showExtension: () => this.showExtension("ReadAccountMemories"),
            route: {
                path: "memories",
                component: ListAccountMemoriesComponent,
            },
        };

        const threadMemories: RouteExtension<ITimeline> = {
            label: "Memories",
            icon: "ai-icon",
            showIcon: () => this.brandingEnabled$,
            showExtension: (thread: ITimeline) => this.showMemoriesExtension(thread, "ReadServiceMemories"),
            route: {
                path: "memories",
                component: ListThreadMemoriesComponent,
            },
        };

        this.libraries.accountRoutes.register("memories", accountMemories);
        this.libraries.threadViews.register("memories", threadMemories);
    }

    private showMemoriesExtension(thread: ITimeline, permission: string): Observable<boolean> {
        if (thread?.type === ThreadTypes.Alert) {
            return of(false);
        }

        return this.showExtension(permission);
    }

    private showExtension(permission: string): Observable<boolean> {
        return this.featureEnabled$.pipe(
            switchMap((enabled) => {
                if (!enabled) {
                    return of(false);
                }

                return this.authService.getUser().pipe(
                    filter((user) => !!user),
                    switchMap((user) => this.userHasPermission(user, permission)),
                );
            }),
        );
    }

    private userHasPermission(user: AppUser, permission: string): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, permission);
    }
}
