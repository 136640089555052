import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SortDirection } from "@angular/material/sort";
import { BehaviorSubject, Observable } from "rxjs";

export interface IPaginatorSort {
    sort: string;
    order: SortDirection;
}

@Component({
    selector: "fx-paginator",
    templateUrl: "fx-paginator.component.html",
    styleUrls: ["./fx-paginator.component.scss"],
})
export class FxPaginatorComponent implements OnInit {
    @Input() enableBack?: boolean;
    @Input() enableNext?: boolean;
    @Output() goBack = new EventEmitter<number>();
    @Output() goNext = new EventEmitter<number>();

    public canGoBack: Observable<boolean>;

    private currentPageSource = new BehaviorSubject<number>(1);

    ngOnInit(): void {
        // Initialize current page to 1
        this.currentPageSource.next(1);
    }

    nextPage(): void {
        const currentPage = this.currentPageSource.value;
        this.currentPageSource.next(currentPage + 1);
        this.goNext.emit(currentPage + 1);
    }

    previousPage(): void {
        const currentPage = this.currentPageSource.value;
        this.currentPageSource.next(currentPage - 1);
        this.goBack.emit(currentPage - 1);
    }
}
