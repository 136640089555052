import { Pipe, PipeTransform } from "@angular/core";
import { SortOption } from "@visoryplatform/threads";

type Arguments<T> = [keyof T, SortOption];

@Pipe({
    name: "orderBy",
    pure: true,
})
export class OrderByPipe<T extends Record<string, string>> implements PipeTransform {
    transform(value: T[], args: Arguments<T>): T[] {
        const [sortProperty, sortOption] = args;
        if (!value?.length) {
            return value;
        }

        switch (sortOption) {
            case SortOption.ASC:
                return value.sort((a, b) => a[sortProperty]?.localeCompare(b[sortProperty]));
            case SortOption.DESC:
                return value.sort((a, b) => b[sortProperty]?.localeCompare(a[sortProperty]));
        }
    }
}
