import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "stripHtmlLinks", pure: true })
export class StripHtmlLinksPipes implements PipeTransform {
    transform(html: string): string {
        if (html) {
            return html.replace(/<a\b[^>]*>(.*?)/, "").replace(/<\/a>/, "");
        } else {
            return "";
        }
    }
}
