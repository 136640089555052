<div class="participant-picker">
    <div class="participant-picker-container-full-width">
        <div class="participant-picker-selector">
            <div class="participant-picker-selector-selected">
                <div class="participant-picker-selector-add">
                    <input
                        #clientSearchInput
                        class="fx-form-input remove-underline"
                        [placeholder]="searchPlaceholder"
                        aria-label="Search name or email"
                        [formControl]="searchTerm"
                        analyticsClick="app_searchclients"
                        autocomplete="off"
                        (blur)="handleInputBlurred()"
                    />
                </div>
            </div>
        </div>
        <div class="participant-picker-list-container">
            <participant-list
                [showLoader]="!!loader.counter"
                [participants]="searchResults$ | async"
                [showInvite]="false"
                (selectParticipant)="selectUser($event)"
            ></participant-list>
        </div>
    </div>
</div>
