import { Component, Input, QueryList } from "@angular/core";
import { StepperStepComponent } from "../step/stepper-step.component";

@Component({
    selector: "stepper-breadcrumbs",
    templateUrl: "./stepper-breadcrumbs.component.html",
    styleUrls: ["./stepper-breadcrumbs.component.scss"],
})
export class StepperBreadcrumbsComponent {
    @Input() steps: QueryList<StepperStepComponent>;
    @Input() selectedIndex: number;
}
