<div class="iframe-container">
    <iframe
        #htmlPreviewIframe
        *ngIf="dataUrlBypass"
        [srcdoc]="dataUrlBypass"
        title="Document Preview"
        width="100%"
        [height]="(height$ | async) || '100%'"
        frameborder="0"
        (load)="setHeight()"
    ></iframe>
</div>
