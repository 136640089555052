import { Component, OnInit } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { switchMap, take } from "rxjs/operators";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { throwError } from "rxjs";

@Component({
    selector: "app-confirm-email",
    styleUrls: ["./confirm-email.component.scss"],
    templateUrl: "./confirm-email.component.html",
})
export class ConfirmEmailComponent implements OnInit {
    errorMessage = "";

    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loader: Loader,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.errorMessage = "";

        this.activatedRoute.queryParams
            .pipe(
                take(1),
                switchMap((params) => {
                    const code = params.code;
                    if (!code) {
                        return throwError(
                            "There is a problem with the verification link - please contact Visory support.",
                        );
                    }
                    return this.loader.wrap(this.authService.confirmEmail(code));
                }),
            )
            .subscribe(
                (result: { status: string; message: string }) => {
                    if (result.status === "OK") {
                        this.recordAnalyticsEvent("confirm-success");
                        this.router.navigateByUrl("/profile");
                    } else {
                        const errorMessage = this.getErrorMessageForStatus(result.status);
                        this.router.navigate(["/profile"], { queryParams: { errorMessage } });
                    }
                },
                () => {
                    this.recordAnalyticsEvent("confirm-unknown-error");
                    this.errorMessage =
                        "There is a problem with the verification link - please contact Visory support.";
                },
            );
    }

    private getErrorMessageForStatus(status: string) {
        switch (status) {
            //TODO provide a resend function for this
            case "INCORRECT_CODE":
                this.recordAnalyticsEvent("confirm-incorrect");
                this.errorMessage = "An error occurred, please try again.";
                return `Sorry, the link was invalid - please try again`;
            case "EXPIRED":
                this.recordAnalyticsEvent("confirm-expired");
                return `The verification code expired, please try again.`;
            case "REQUEST_ERROR":
                this.recordAnalyticsEvent("confirm-request-error");
                return "An error occurred, please try again.";
            case "UNKNOWN_ERROR":
            default:
                this.recordAnalyticsEvent("confirm-unknown-error");
                return "An error occurred, please try again.";
        }
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("verify-email", category);
    }
}
