<div class="fx-threads-status">
    <thread-workflow-progress-indicator
        [workflow]="workflow"
        [enablePopUp]="enablePopUp"
        [enableDotStepper]="enableDotStepper"
        [showIcon]="showIcon"
        [small]="small"
        [role]="role"
        [thread]="thread"
        [shouldShowTitle]="shouldShowTitle"
        [fullWidth]="fullWidth"
        [isInternal]="role | permissions : 'ViewInternalSteps' | async"
        [hideDotStepperOnMobile]="true"
        [overrideStepperTitleColor]="overrideStepperTitleColor"
    >
    </thread-workflow-progress-indicator>
</div>
