<div class="metric">
    <h4>
        <span class="metric-label">{{ tableReportRow?.metric?.label }}</span>

        <span class="metric-info">
            <i
                [tuiHint]="tableReportRow?.metric?.description"
                tuiHintDirection="right"
                class="las la-exclamation-circle"
            >
            </i>
        </span>
    </h4>

    <div class="metric-result">
        <span
            *ngIf="tableReportRow?.result?.value !== null && tableReportRow?.metric?.unit === '$'"
            class="metric-result-value"
        >
            {{ tableReportRow?.result?.value | currency }}
        </span>
        <span
            *ngIf="tableReportRow?.result?.value !== null && tableReportRow?.metric?.unit !== '$'"
            class="metric-result-value"
        >
            {{ tableReportRow?.result?.value | number : "1.0-2" }} {{ tableReportRow?.metric?.unit }}
        </span>
        <div class="metric-result-column">
            <span
                class="metric-result-value"
                *ngIf="tableReportRow?.result?.value === null"
                >-</span
            >
            <span
                class="metric-result-update"
                *ngIf="!!period"
                >Data range: {{ period?.start | dateRange : period?.end }}</span
            >
        </div>
    </div>

    <div class="metric-comparison">
        <div class="metric-target first">
            <div class="metric-target-header"><div class="metric-target-label">Your target</div></div>

            <div class="metric-target-value">
                <div *ngIf="tableReportRow?.target?.targetValue !== null && tableReportRow?.metric?.unit === '$'">
                    {{ tableReportRow?.target?.targetValue | currency }}
                </div>

                <div *ngIf="tableReportRow?.target?.targetValue !== null && tableReportRow?.metric?.unit !== '$'">
                    {{ tableReportRow?.target?.targetValue | number : "1.0-2" }} {{ tableReportRow?.metric?.unit }}
                </div>

                <div *ngIf="tableReportRow?.target?.targetValue === null">-</div>
            </div>

            <insights-trend-pill
                [isBetterThanTarget]="isMetricBetterThenTarget"
                [isBelowTarget]="isMetricBelowTarget"
                [value]="targetValue | absolute | number : '1.0-1'"
            >
            </insights-trend-pill>
        </div>

        <ng-container *ngIf="showBenchmarking">
            <ng-container *ngFor="let benchmark of tableReportRow?.benchmarks">
                <div
                    *ngIf="benchmark?.percentiles[3]"
                    class="metric-target"
                >
                    <div class="metric-target-header">
                        <span class="metric-target-label">{{ benchmark.label }}</span>
                        <span class="metric-info">
                            <i
                                [tuiHint]="comparisonArea"
                                tuiHintDirection="right"
                                class="las la-exclamation-circle"
                            >
                            </i>
                        </span>
                    </div>

                    <div class="metric-target-value">
                        <span *ngIf="benchmark?.percentiles[3] && tableReportRow?.metric?.unit === '$'"
                            >{{ benchmark?.percentiles[3] | currency }}
                        </span>
                        <span *ngIf="benchmark?.percentiles[3] !== null && tableReportRow?.metric?.unit !== '$'"
                            >{{ benchmark?.percentiles[3] | number : "1.0-2" }} {{ tableReportRow?.metric?.unit }}
                        </span>
                        <span *ngIf="benchmark?.percentiles[3] === null">-</span>
                    </div>

                    <insights-trend-pill
                        [isBetterThanTarget]="isMetricBetterThenTarget"
                        [isBelowTarget]="isMetricBelowTarget"
                        [value]="getBenchmarkValue(tableReportRow, benchmark) | absolute | number : '1.0-1'"
                    >
                    </insights-trend-pill>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
