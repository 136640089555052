import { Component, Input, OnChanges } from "@angular/core";
import { GA_EVENTS } from "../../../analytics";
import { IParticipant, IThreadCard } from "@visoryplatform/threads";

@Component({
    selector: "card-seen-by",
    templateUrl: "card-seen-by.component.html",
    styleUrls: ["card-seen-by.component.scss"],
})
export class CardSeenByComponent implements OnChanges {
    @Input() cardSeenByParticipants: string[];
    @Input() threadParticipants: IParticipant[];
    @Input() card: IThreadCard;
    @Input() currentUser: string;

    readonly gaEvents = GA_EVENTS;

    shouldShowHint = false;
    seenByAllParticipants = false;

    ngOnChanges(): void {
        this.seenByAllParticipants = this.isCardSeenByAllParticipants();
    }

    toggleShowHint(): void {
        this.shouldShowHint = !this.shouldShowHint;
        setTimeout(() => {
            this.shouldShowHint = false;
        }, 3000);
    }

    private isCardSeenByAllParticipants(): boolean {
        return this.threadParticipants
            .filter((participant) => participant.id !== this.currentUser)
            .every(
                (participant) =>
                    this.isCardCreatedByCurrentUser(participant.id) || this.isCardSeenByParticipant(participant.id),
            );
    }

    private isCardSeenByParticipant(participant: string): boolean {
        return this.cardSeenByParticipants?.includes(participant);
    }

    private isCardCreatedByCurrentUser(participant: string): boolean {
        return this.card.createdBy === participant;
    }
}
