import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth/services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { merge, Subject } from "rxjs";
import { delay, mapTo, startWith } from "rxjs/operators";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "forgot-password-form",
    templateUrl: "./forgot-password-form.component.html",
    styleUrls: ["./forgot-password-form.component.scss"],
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
    form = new UntypedFormGroup({
        emailAddress: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });

    firstRequestSent = false;
    showLoader: boolean;
    message: {
        message: string;
        error: boolean;
    };
    readonly theme = this.environment.theme;

    private sendClicked = new Subject<true>();
    enableResend$ = merge(this.sendClicked.pipe(startWith(false)), this.sendClicked.pipe(delay(10000), mapTo(true)));

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe(async (params) => {
            this.form.setValue({
                emailAddress: params.emailAddress || "",
            });
        });
    }

    ngOnDestroy(): void {}

    async beginForgotPasswordProcess(): Promise<void> {
        this.message = null;
        try {
            this.showLoader = true;
            this.sendClicked.next(true);

            const { emailAddress } = this.form.value;
            const result = await this.authService.beginForgotPassword(emailAddress);
            if (result.success === true) {
                this.firstRequestSent = true;
                this.message = this.handleMessage(false, "If your email exists we have sent you an email.");
            } else {
                this.message = this.handleMessage(true, result.errorMessage || "Sorry, something went wrong");
            }
        } catch (error) {
            this.message = this.handleMessage(true, "Sorry, something went wrong");
        }
        this.showLoader = false;
    }

    handleMessage(isError: boolean, messageText: string): { error: boolean; message: string } {
        if (isError === undefined || !messageText) {
            return null;
        }
        return {
            error: isError,
            message: messageText,
        };
    }
}
