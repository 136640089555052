import { Component, HostBinding, Input } from "@angular/core";

/** @deprecated this component is excruciatingly complex to use and debug. Replaced with stepper-layout */
@Component({
    selector: "step-layout",
    templateUrl: "./step-layout.component.html",
    styleUrls: ["./step-layout.component.scss"],
})
export class StepLayoutComponent {
    @Input("stepTitle") title: string;
    @HostBinding("class.active") @Input() active = false;
}
