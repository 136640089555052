import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "../../../portal-modules/src/lib/environment/environment.common";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { IGlobalSearchMessage, SearchSqlRecord } from "@visoryplatform/threads";

@Injectable({
    providedIn: "root",
})
export class GlobalSearchService {
    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    getSearch(searchTerm: string): Observable<SearchSqlRecord<IGlobalSearchMessage>> {
        const { search } = environmentCommon.searchEndpoints;
        const { base } = this.environment.commonEndpoints;
        const url = `${base}${search}`;

        let params = new HttpParams();
        params = params.append("searchTerm", searchTerm);

        return this.http.get<SearchSqlRecord<IGlobalSearchMessage>>(url, { params: params });
    }

    setControlValueFromSearchQueryParams(formControl: FormControl): Subscription {
        return this.activatedRoute.queryParams.subscribe((params) => {
            const query = params?.query;
            formControl.setValue(query);
        });
    }
}
