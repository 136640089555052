import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IRequestAttachment, IRequestFileData, Role } from "@visoryplatform/threads";
import { VaultRequestService } from "../../../services/vault-request.service";
import { GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "request-attachments",
    templateUrl: "./request-attachments.component.html",
    styleUrls: ["./request-attachments.component.scss"],
})
export class RequestAttachmentsComponent {
    @Input() attachments: IRequestAttachment;
    @Input() title: string;
    @Input() vaultId: string;
    @Input() canDelete = false;
    @Input() userId: string;
    @Input() role: Role;
    @Input() analyticsPrefix: GA_EVENTS_PREFIX;

    @Output() deleteAttachment = new EventEmitter<IRequestFileData>();

    constructor(private vaultRequestService: VaultRequestService) {}

    async downloadFile(fileId: string, attachment: IRequestFileData): Promise<void> {
        await this.vaultRequestService.downloadFile(this.vaultId, fileId, attachment.filename);
    }
}
