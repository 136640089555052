import { NotificationState } from "../enums/NotificationState";
import { PossibleDeliveryData, DeliveryMethod } from "./DeliveryData";
export * from "./DeliveryData";

export type Notification<DeliveryData = PossibleDeliveryData> = {
  id: string;

  /**
   * The user/thing that created this notification
   */
  actorId: string;

  /**
   * The app that the notification belongs to
   */
  appId: string;

  /**
   * An id of the user that the notification is for. Used for websocket delivery
   */
  recipientId: string;

  /**
   * Channel to be delivered to
   */
  channel: string;

  /**
   * ISO 8601 formated string of when the notification was created
   */
  createdAt: string;

  /**
   * ISO 8601 formated string of when the notification was resolved
   */
  resolvedAt?: string;

  /**
   * ISO 8601 formated string of when the notification was delivered
   */
  deliveredAt?: string;

  /**
   * A state to determine when the notification is read/unread/deleted
   */
  state: NotificationState;

  /**
   * The method of the notification delivery
   */
  deliveryMethod: DeliveryMethod;

  /**
   * Data required depending on the delivery method
   */
  deliveryData: DeliveryData;

  /**
   * An ID used by the delivery method to identify a sent notification.
   * EG in emails, this might be a tracking ID to know when a notification is read
   */
  deliveredId?: string;

  /**
   * Internally generated as `${appId}/${recipientId}/${channel}`. Used to retrieve notifications by channel
   *
   * For example, a notification for a Sigma user 0000 in thread id 1234, for a card 5678, might be "sigma/0000/1234/5678"
   * In this example, we can retrieve all sigma notifications, or all notifications for sigma user 0000,
   * or all notifications in 1234 for sigma user, or the notification for the card 5678
   */
  fullChannel: string;

  /**
   * If a notification should be repeatedly delivered until read or repeatUntil, which ever is first
   */
  repeatMinutes?: number;

  /**
   * ISO 8601 timestamp to repeat until
   * @format date-time
   */
  repeatUntil?: string;

  /**
   * Internal property used to track previous notification in repeating notifications
   */
  repeatPreviousId?: string;

  /**
   * ISO 8601 timestamp of when to delivery the notification
   * @format date-time
   */
  deliverAfter?: string;

  /**
   * Internally generated deliverAfter timestamp in milliseconds
   */
  deliverAfterTime: number;

  /**
   * Internally generated for index
   */
  stateAppRecipient: string;

  /**
   * Internally generated for index
   */
  stateAppRecipientDeliveryMethod: string;

  /**
   * Internally generated for index
   */
  stateAppDeliveryMethod: string;

  /**
   * Internally generated for index
   */
  appRecipientUserVisible: string;

  /**
   * Internally generated for index
   */
  appRecipientUserDelivered: string;

  /**
   * Internally generated as a concatenated index of the app and recipient properties
   */
  appRecipient: string;

  /**
   * UI displayable text version of the notification
   */
  label: string;

  /**
   * For consumers to distinguish between notification types
   */
  topic: string;

  /**
   * Internally generated for index
   */
  appRecipientIsUserVisibleFirstPrefix: string;
  appRecipientUserVisibleGroupType?: string;
  appRecipientUserDeliveredGroupType?: string;
};

export type MultiNotification = {
  actorId: string;
  appId: string;
  channel: string;
  notifications: MultiDelivery[];
};

export type MultiDelivery<DeliveryData = PossibleDeliveryData> = {
  recipientId: string;
  label: string;
  topic: string;
  deliveryMethod: DeliveryMethod;
  deliveryData: DeliveryData;
  recipientName?: string;
  repeatMinutes?: number;

  /**
   * @format date-time
   */
  repeatUntil?: string;

  /**
   * @format date-time
   */
  deliverAfter?: string;
};
