<div class="new-thread-rhythm-add-assignees">
    <add-participant
        label="Assign to {{ step.isAssignedToClient ? assignedTo.client : assignedTo.visory }}:"
        [formControl]="assigneesControl"
    ></add-participant>
    <div
        class="fx-form-validation brand-danger"
        *ngIf="!assigneesControl?.valid && assigneesControl?.touched && !assigneesControl?.disabled"
    >
        Assignees are required.
    </div>
</div>
