import { Component, OnInit } from "@angular/core";
import { CalendarEventsService } from "../../services/calendar-events.service";
import { SubscriberBaseComponent } from "../../../shared/components/subscriber-base.component";
import { map, takeUntil } from "rxjs/operators";
import { CalendarEvent } from "angular-calendar";
import { Observable } from "rxjs";
import { CalendarMeetingsService } from "../../services/calendar-meetings.service";
import { IUpcomingMeeting } from "@visoryplatform/threads";

interface EventsGroup {
    date: string;
    events: CalendarEvent[];
}

@Component({
    selector: "lib-calendar-agenda",
    templateUrl: "./calendar-agenda.component.html",
    styleUrls: ["./calendar-agenda.component.scss"],
})
export class CalendarAgendaComponent extends SubscriberBaseComponent implements OnInit {
    public eventsGroups$: Observable<EventsGroup[]>;
    public loader = this.eventsService.loader;

    constructor(private eventsService: CalendarEventsService, private calendarMeetingService: CalendarMeetingsService) {
        super();
    }

    ngOnInit(): void {
        this.setEventsGroups();
    }

    public onCalendarEventClicked(event: CalendarEvent): void {
        this.eventsService.calendarEventClicked(event).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }

    public onCalendarMeetingClicked(event: IUpcomingMeeting): void {
        this.calendarMeetingService.onViewMeetingDetails(event).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }

    private setEventsGroups(): void {
        this.eventsGroups$ = this.eventsService.calendarEvents$.pipe(
            map((events: CalendarEvent[]) => {
                const groups = new Map();

                for (const event of events) {
                    const dateString = event.end.toDateString();
                    const existingEvents = groups.get(dateString) || [];
                    groups.set(dateString, [...existingEvents, event]);
                }

                return Array.from(groups, ([date, events]: [string, CalendarEvent[]]) => ({ date, events }));
            }),
        );
    }
}
