import { Pipe, PipeTransform } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PermissionService } from "../../threads-ui/services/permissions.service";

@Pipe({ name: "permissions" })
export class PermissionsPipe implements PipeTransform {
    constructor(private permissionService: PermissionService) {}

    /**
     *
     * @param permissionVarArgs only one of these args needs to be true for this check to pass (OR check).
     *  To do an AND check (must have 2+ permissions) put them in an array in one arg
     * e.g permissions:"Perm1":"Perm2" will check both and return true if at least 1 is true, permissions:["Perm1", "Perm2"] requires both to be true
     */
    transform(role: Role, ...permissionVarArgs: (string | string[])[]): Observable<boolean> {
        return combineLatest(
            permissionVarArgs.map((permissions) => this.permissionService.checkPermissions(role, permissions)),
        ).pipe(map((permissions) => permissions.some((value) => !!value)));
    }
}
