import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Inject } from "@angular/core";
import { VideoChatService as VcService } from "@visoryplatform/video-chat-sdk";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { VcDevice } from "@visoryplatform/video-chat-sdk/types/vc-device";
import { PreCallSessionComponent } from "@visoryplatform/video-chat-sdk/components/pre-call-session/pre-call-session.component";
import { VideoChatService } from "../../services/video-chat.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { IVisualEffect } from "@visoryplatform/video-chat-sdk/types/vc-visual-effect";

@Component({
    selector: "pre-call-details",
    templateUrl: "./pre-call-details.component.html",
    styleUrls: ["pre-call-details.component.scss"],
})
export class PreCallDetailsComponent implements OnInit {
    @ViewChild("session") session: PreCallSessionComponent;

    @Input() publishAudio = true;
    @Input() publishVideo = false;
    @Input() name: string;
    @Input() visualEffect?: IVisualEffect;

    @Output() joinCall = new EventEmitter();
    @Output() toggleAudio = new EventEmitter();
    @Output() toggleVideo = new EventEmitter();
    @Output() setAudioDevice = new EventEmitter();
    @Output() setAudioOutputDevice = new EventEmitter();
    @Output() addVisualEffect = new EventEmitter();

    audioDevices$: Observable<VcDevice[]>;
    audioOutputDevices$: Observable<OT.AudioOutputDevice[]>;

    selectedAudioId: string;
    selectedAudioOutputId: string;

    readonly policyUrl = this.environment.policyUrl;

    constructor(
        private vcService: VcService,
        private videoChatService: VideoChatService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.audioDevices$ = this.vcService
            .getDevices()
            .pipe(map((devices) => devices.filter((device) => device.deviceId && device.kind === "audioInput")));

        this.audioOutputDevices$ = this.vcService.getOuputDevices();
    }

    selectAudioDevice(audioDeviceId: string): void {
        this.selectedAudioId = audioDeviceId;
        this.setAudioDevice.emit(audioDeviceId);
    }

    selectAudioOutputDevice(audioDeviceId: string) {
        this.selectedAudioOutputId = audioDeviceId;
        this.setAudioOutputDevice.emit(audioDeviceId);
    }

    joinCallClicked(): void {
        // checking specifically for false to make sure it is fully loaded and set
        if (this.session?.videoCapture?.accessBlocked === false) {
            this.joinCall.emit();
        }
    }

    handleError(error): void {
        this.videoChatService.handleError(error).subscribe((allowRetry) => {
            if (allowRetry) {
                this.session.retrySession();
            }
        });
    }
}
