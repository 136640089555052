<ng-container *ngIf="!(user$ | async)">
    <loader-icon
        *ngIf="showLoader"
        color="light"
    ></loader-icon>
    <div
        class="fx-onboarding fx-onboarding-bg-password"
        *ngIf="!showLoader"
    >
        <div class="fx-onboarding-container">
            <div class="fx-onboarding-content">
                <portal-brand type="onboarding"></portal-brand>
                <h1 class="fx-onboarding-title">
                    {{ textResource.createPasswordTitle || textResource.default.createPasswordTitle }}
                </h1>
                <p
                    class="fx-onboarding-body"
                    *ngIf="textResource.createPasswordDescription"
                >
                    {{ textResource.createPasswordDescription }}
                </p>
                <form
                    class="fx-onboarding-form fx-form fx-form--dark"
                    [formGroup]="form"
                    (submit)="updatePassword()"
                >
                    <div
                        class="fx-form-group"
                        *ngIf="errorMessage"
                    >
                        <div class="fx-form-validation-panel">
                            <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                        </div>
                    </div>
                    <div class="fx-form-group">
                        <label
                            class="fx-form-label"
                            for="newPassword"
                            >New password</label
                        >
                        <div class="fx-form-control">
                            <input
                                formControlName="newPassword"
                                class="fx-form-input"
                                type="password"
                                placeholder="New password"
                                aria-label="newPassword"
                                togglePassword
                                [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                                [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                                [ngClass]="{
                                    'fx-form-input--error':
                                        (form.getError('matchingFields') === false ||
                                            !form.controls['newPassword'].valid) &&
                                        form.controls['newPassword'].touched
                                }"
                            />
                        </div>
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                (form.getError('matchingFields') === false || !form.controls['newPassword'].valid) &&
                                form.controls['newPassword'].touched
                            "
                        >
                            {{ getPasswordError("New password", form.getError("matchingFields")) }}
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>
                    <div class="fx-form-group">
                        <label
                            class="fx-form-label"
                            for="confirmPassword"
                            >Re-enter password</label
                        >
                        <div class="fx-form-control">
                            <input
                                formControlName="confirmPassword"
                                class="fx-form-input"
                                type="password"
                                placeholder="Confirm password"
                                aria-label="confirmPassword"
                                togglePassword
                                [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                                [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                                [ngClass]="{
                                    'fx-form-input--error':
                                        (form.getError('matchingFields') === false ||
                                            !form.controls['confirmPassword'].valid) &&
                                        form.controls['confirmPassword'].touched
                                }"
                            />
                        </div>
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                (form.getError('matchingFields') === false ||
                                    !form.controls['confirmPassword'].valid) &&
                                form.controls['confirmPassword'].touched
                            "
                        >
                            {{ getPasswordError("Confirm password", form.getError("matchingFields")) }}
                            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                        </div>
                    </div>
                    <div class="fx-form-group fx-onboarding-footer">
                        <p>- Must be at least 8 characters long</p>
                        <p>- Must have an uppercase character</p>
                        <p>- Must have an lowercase character</p>
                        <p>- Must have a number</p>
                    </div>
                    <div class="fx-form-group">
                        <button
                            class="fx-btn fx-btn--full-width"
                            [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                            [disabled]="!form.valid"
                            type="submit"
                        >
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>
