import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { Component, Input, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import {
    IWorkflowConfiguration,
    IWorkflowDesignType,
    VariationsControlContent,
    WorkflowConfigTokenService,
    workflowVariations,
} from "@visoryplatform/threads";

import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { SelectWorkflowTokenControl } from "../../types/SelectDesignType";
import { Subscription } from "rxjs";

interface WorkflowFormControls {
    [key: string]: FormControl;
}

type SelectWorkflowTokenChange = (obj: SelectWorkflowTokenControl) => void;

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectWorkflowTokenComponent),
    multi: true,
};

const VALIDATORS = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectWorkflowTokenComponent),
    multi: true,
};

@Component({
    selector: "select-workflow-token",
    templateUrl: "./select-workflow-token.component.html",
    styleUrls: ["./select-workflow-token.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR, VALIDATORS],
})
export class SelectWorkflowTokenComponent implements ControlValueAccessor, Validator, OnChanges {
    @Input() selectedDesignType: IWorkflowDesignType;
    @Input() selectedWorkflowDesign: IWorkflowDesign;
    @Input() selectedWorkflowConfiguration: IWorkflowConfiguration;

    onChange: (obj: SelectWorkflowTokenControl) => void;
    onTouch: () => void;
    validatorFn: () => void;

    readonly selectPackagePlaceholder = "Select a workflow package";

    workflowPackagesReadOnly = false;

    /** List of controls to loop and render in template */
    workflowTokenControls: VariationsControlContent[] = [];

    /** Map to keep track of which variation has been filled */
    workflowTokensSelectedMap = new Map<string, string | null>();

    formSub: Subscription;
    form = new FormGroup<WorkflowFormControls>({}, Validators.required);

    ngOnChanges(changes: SimpleChanges): void {
        const { selectedWorkflowConfiguration, selectedWorkflowDesign } = changes;

        const selectedWorkflowConfig = selectedWorkflowConfiguration?.currentValue;
        const selectedDesign = selectedWorkflowDesign?.currentValue && !selectedWorkflowConfig;

        this.workflowPackagesReadOnly = !!selectedWorkflowConfig;

        if (selectedWorkflowConfig) {
            this.workflowTokenControls = this.getConfigurationWorkflowTokenControls(
                selectedWorkflowConfiguration.currentValue,
                this.selectedDesignType,
            );
            this.buildForm(selectedWorkflowConfiguration.currentValue);
        }

        if (selectedDesign) {
            this.workflowTokenControls = this.getDesignWorkflowTokenControls(this.selectedDesignType);
            this.buildForm();
        }

        if (!selectedWorkflowConfig && !selectedDesign) {
            this.workflowTokenControls = [];
            this.form.reset();
        }
    }

    validate(_control: AbstractControl): ValidationErrors | null {
        const hasMissingFields = Array.from(this.workflowTokensSelectedMap.values()).find((id) => id == null);
        if (hasMissingFields) {
            return { invalid: true };
        }
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    writeValue(obj: SelectWorkflowTokenControl): void {
        this.form.patchValue(obj);
    }

    registerOnChange(fn: SelectWorkflowTokenChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    onWorkflowTokenSelected(event: { id: string }, field: string): void {
        if (this.onTouch) {
            this.onTouch();
        }

        this.workflowTokensSelectedMap.set(field, event.id);
        const workflowTokenIds = Array.from(this.workflowTokensSelectedMap.values()).filter((id) => id != null);

        if (workflowTokenIds) {
            this.onChange({ workflowTokenIds });
        }
    }

    private buildForm(workflowConfig?: IWorkflowConfiguration): void {
        this.addFormControls();
        this.setFormValues(workflowConfig);
    }

    private addFormControls(): void {
        for (let id = 0; id < this.workflowTokenControls.length; id++) {
            const tokenKey = `workflowTokenId${id}`;

            const existingControl = this.form.get(tokenKey);
            const existingControlValue = existingControl?.value?.id || null;

            this.form.addControl(tokenKey, new FormControl(existingControlValue));
            this.workflowTokensSelectedMap.set(tokenKey, existingControlValue);
        }
    }

    private setFormValues(workflowConfig?: IWorkflowConfiguration): void {
        const workflowTokenIds = workflowConfig?.workflowTokens;
        if (!workflowTokenIds?.length) {
            return;
        }

        for (let id = 0; id < workflowTokenIds.length; id++) {
            const tokenKey = `workflowTokenId${id}`;
            const control = this.form.get(tokenKey);
            if (control) {
                control.setValue(workflowTokenIds[id]);
            }
        }
    }

    private getConfigurationWorkflowTokenControls(
        config: IWorkflowConfiguration,
        designType: IWorkflowDesignType,
    ): VariationsControlContent[] {
        if (!config?.workflowTokens?.length) {
            return [];
        }

        return this.getDesignWorkflowTokenControls(designType);
    }

    private getDesignWorkflowTokenControls(designType: IWorkflowDesignType): VariationsControlContent[] {
        if (!designType?.workflowToken) {
            return [];
        }

        const controls = workflowVariations[designType.workflowToken];
        return controls.filter(WorkflowConfigTokenService.excludeMandatory);
    }
}
