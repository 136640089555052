<div class="create-bill-approval-control">
    <label>Planned payment date</label>

    <div class="date-picker">
        <div class="date-picker-input-container">
            <mat-form-field>
                <input
                    matInput
                    [formControl]="plannedPaymentDate"
                    [matDatepicker]="plannedPaymentPicker"
                    class="date-picker-input"
                />

                <mat-datepicker-toggle
                    matSuffix
                    [for]="plannedPaymentPicker"
                >
                    <i
                        class="las la-calendar date-picker-icon"
                        matDatepickerToggleIcon
                    ></i>
                </mat-datepicker-toggle>
                <mat-datepicker #plannedPaymentPicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>
