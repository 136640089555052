import { Component, Inject } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";

@Component({
    selector: "app-check-staff-role-dialog",
    templateUrl: "./check-staff-role-dialog.component.html",
    styleUrls: ["./check-staff-role-dialog.component.scss"],
})
export class CheckStaffRoleDialogComponent {
    readonly supportEmail = this.environment.featureFlags.supportEmail;
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}
}
