<ng-container *ngIf="state$ | async as state">
    <div
        *ngIf="card$ | async; let card"
        class="modal"
    >
        <ng-container
            *ngIf="{
                thread: thread$ | async,
                canSubmit: canSubmit$ | async
            } as context"
        >
            <loader-icon
                *ngIf="loader.counter"
                color="light"
            ></loader-icon>

            <section class="modal-header">
                <h1>{{ modalTitle }}</h1>
                <div
                    (click)="closeModal()"
                    [analyticsClick]="gaEvents.APP_EDIT_MESSAGE_CLOSE"
                    class="modal-header-close"
                >
                    <i class="las la-times icon-size"></i>
                </div>
            </section>

            <section class="modal-description">
                <quill-editor-wrapper
                    [mentionableUsers]="context.thread.participants | participantsToMention | async"
                    [threadType]="context.thread.type"
                    [formControl]="messageFormControl"
                    [autoFocusOnInit]="true"
                ></quill-editor-wrapper>
            </section>

            <section class="modal-attachments">
                <file-input
                    [allowMultipleFiles]="cardOptions.enableMultipleFiles"
                    [analyticsPrefix]="gaEventsPrefix.DOCUMENT_CARD"
                    (file)="uploadFile(card, $event, context.thread.id)"
                ></file-input>

                <vault-attachments
                    *ngIf="state?.groups"
                    [groups]="state?.groups"
                    [disabled]="card?.status === cardStatuses.Disabled"
                    [canRequestSignature]="role | permissions : 'RequestFileSignature' | async"
                    [editable]="true"
                    [canCreateReports]="cardOptions.enableMarkFileAsReport"
                    (download)="downloadItem($event)"
                    (rename)="renameItem($event.item, $event.name, context.thread.id, card.id)"
                    (delete)="deleteItem($event, context.thread.id, card.id)"
                    (markAsReport)="markDocumentAsReport($event)"
                ></vault-attachments>

                <warning-banner *ngIf="!cardOptions.enableMultipleFiles">
                    Only 1 file can be attached. Newly uploaded document will overwrite existing file and will be marked
                    as signature required.
                </warning-banner>

                <warning-banner *ngIf="cardOptions.reportsRequired && !context.canSubmit">
                    Newly uploaded documents will overwrite existing files. You need to mark at least one PDF file as a
                    report to proceed.
                </warning-banner>
            </section>

            <div class="modal-submit">
                <button
                    [disabled]="!context.canSubmit"
                    (click)="
                        saveCloseAndTransitionFurther(
                            context.thread.id,
                            card.id,
                            messageFormControl.value,
                            card.description
                        )
                    "
                    class="fx-btn fx-btn--primary-outlined"
                >
                    Submit
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>
