export type UniqueThreadType = {
    threadType: string;
    threadTypeLabel: string;
};

export enum WorkflowConfigurationOptions {
    Configured = "Configured",
    Blank = "Blank",
    WorkflowConfig = "WorkflowConfig",
}
