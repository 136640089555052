import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { RecipientSettings } from "@visoryplatform/notifications-core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserNotificationSettingsService {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, private http: HttpClient) {}

    getSettingsByUser(recipientId: string): Observable<RecipientSettings> {
        const { usersNotificationSettings } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${usersNotificationSettings.replace(":userId", recipientId)}`;
        return this.http.get<RecipientSettings>(url);
    }

    saveSettingsByUser(recipientId: string, userSettings: RecipientSettings): Observable<RecipientSettings> {
        const { usersNotificationSettings } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${usersNotificationSettings.replace(":userId", recipientId)}`;
        return this.http.put<RecipientSettings>(url, { userSettings });
    }
}
