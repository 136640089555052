import { Pipe, PipeTransform } from "@angular/core";
import { Duration } from "luxon";

// FIXME ED-8169 rescale() is part of Duration but requires updating @types/luxon to be recognised
interface DurationWithRescale extends Duration {
    rescale(): Duration;
}

@Pipe({ name: "duration" })
export class DurationPipe implements PipeTransform {
    static readonly ROUNDING_FACTOR = 1000 * 60;

    transform(value: number, separator = ""): string {
        if (value == null || isNaN(value)) {
            return "";
        }

        const rounded = Math.round(value / DurationPipe.ROUNDING_FACTOR) * DurationPipe.ROUNDING_FACTOR;

        if (rounded === 0) {
            return "0 mins";
        }

        const duration = Duration.fromMillis(rounded);
        const scaledDuration = this.hasRescale(duration) ? duration.rescale() : duration;
        const readableDuration = scaledDuration.toHuman({ unitDisplay: "narrow" });

        return readableDuration.replace(/,/g, separator);
    }

    private hasRescale(duration: Duration): duration is DurationWithRescale {
        return "rescale" in duration && typeof duration.rescale === "function";
    }
}
