import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({ selector: "[invertScroll]" })
export class MouseWheelDirective {
    constructor(private element: ElementRef) {}

    @HostListener("wheel", ["$event"]) onMouseWheelFirefox(event: WheelEvent) {
        event.preventDefault();
        let delta = event.deltaY;
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("firefox") > -1) {
            delta = Math.sign(delta) === 1 ? 100 : -100;
        }
        this.invertScroll(delta);
    }

    @HostListener("mousedown", ["$event"]) onClick(delta: any) {
        //stop default click action from mousewheel
        if (delta.which === 2) {
            delta.preventDefault();
        }
    }

    invertScroll(delta?: number) {
        this.element.nativeElement.scrollTop += -delta;
    }
}
