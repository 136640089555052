import { TransformedLineWithTooltip } from "../interfaces/IPayrunReportLineUI";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "checkPayrunTransformedLineTypePipe" })
export class CheckPayrunTransformedLineTypePipe implements PipeTransform {
    transform(transformedLine: string | number | TransformedLineWithTooltip): TransformedLineWithTooltip {
        if (typeof transformedLine !== "string" && typeof transformedLine !== "number") {
            return transformedLine;
        } else {
            return null;
        }
    }
}
