import { CopilotTransaction, CopilotTransactionsState } from "@visoryplatform/threads";

export class TransactionHelperService {
    static mapTransactionOrder(
        state: CopilotTransactionsState,
        oldState?: CopilotTransactionsState,
    ): CopilotTransaction[] {
        if (!oldState) {
            return state?.transactions?.sort((a, b) => this.compareTransactions(a, b)) || [];
        } else {
            return state?.transactions?.sort((a, b) => this.findTransactionOrder(oldState, a, b)) || [];
        }
    }

    private static findTransactionOrder(
        state: CopilotTransactionsState,
        a: CopilotTransaction,
        b: CopilotTransaction,
    ): number {
        const indexOfA = state?.transactions?.findIndex((transaction) => transaction.query.id === a.query.id) || 0;
        const indexOfB = state?.transactions?.findIndex((transaction) => transaction.query.id === b.query.id) || 0;

        return indexOfA - indexOfB;
    }

    private static compareTransactions(a: CopilotTransaction, b: CopilotTransaction): number {
        const isAComplete = this.hasResponse(a);
        const isBComplete = this.hasResponse(b);

        if (isAComplete && !isBComplete) {
            return 1;
        } else if (!isAComplete && isBComplete) {
            return -1;
        }

        return a?.query?.subjectDate?.localeCompare(b?.query?.subjectDate);
    }

    private static hasResponse(transaction: CopilotTransaction): boolean {
        return !!transaction?.query?.answer || !!transaction?.query?.vaultDetails?.filename;
    }
}
