import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({ name: "dateTime" })
export class DateTimePipe implements PipeTransform {
    transform(dateTime: DateTime, format: string): string {
        if (!dateTime?.isValid) {
            return "";
        } else {
            return dateTime.toFormat(format);
        }
    }
}
