import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Injectable({ providedIn: "root" })
@Pipe({ name: "valueToFriendlyName", pure: true })
export class ValueToFriendlyNamePipe implements PipeTransform {
    constructor() {}

    transform(value: string, uppercase = true): string {
        if (uppercase) {
            return value?.replace(/_/g, " ").toUpperCase() || "";
        } else {
            return value?.replace(/_/g, " ") || "";
        }
    }
}
