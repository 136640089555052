import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { Account, IAccountListing } from "@visoryplatform/threads";
import { Subscription } from "rxjs";
import { CreateRequest } from "../create-request-modal/create-request-modal.component";
import { ICreateRequestForm } from "../../types/CreateRequestForm";
import { RequestService } from "../../services/request.service";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ExpertComponent),
    multi: true,
};

const VALIDATORS = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ExpertComponent),
    multi: true,
};

@Component({
    selector: "expert",
    templateUrl: "./expert.component.html",
    styleUrls: ["./expert.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR, VALIDATORS],
})
export class ExpertComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() accounts: Account[] = [];

    formSubscription: Subscription;
    onChange: (newVal: CreateRequest) => void;
    onTouched?: () => void;
    validatorFn?: () => void;

    formGroup = new FormGroup(
        {
            account: new FormControl<IAccountListing | null>(null),
            message: new FormControl<string>("", [Validators.required]),
            relatingToAccount: new FormControl<{ label: string; value: boolean } | null>(null),
            requestCategory: new FormControl<string>("", [Validators.required]),
        },
        {
            validators: this.validateForm,
        },
    );

    readonly relatingOptions = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ];

    readonly requestCategories: string[];

    constructor(private requestService: RequestService) {
        this.requestCategories = this.requestService.getRequestCategories();
    }

    ngOnInit(): void {
        this.formSubscription = this.formGroup.valueChanges.subscribe((value) => {
            this.onChange?.({
                account: value.account,
                message: value.message,
                relatingToAccount: value.relatingToAccount?.value,
                requestCategory: value.requestCategory,
            });
        });
    }

    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    writeValue(values: CreateRequest): void {
        const relatingToAccount = this.relatingOptions.find((option) => option.value === values.relatingToAccount);
        this.formGroup.setValue({
            account: values.account,
            message: values.message,
            relatingToAccount: relatingToAccount || null,
            requestCategory: values.requestCategory || "",
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    validate(): ValidationErrors | null {
        return this.formGroup.invalid ? { invalid: true } : null;
    }

    validateForm(formGroup: FormGroup<ICreateRequestForm>): ValidationErrors | null {
        const relatingToAccount = formGroup?.controls.relatingToAccount.value?.value;
        const account = formGroup?.controls.account.value;
        return relatingToAccount && !account ? { relatingToAccountInvalid: true } : null;
    }
}
