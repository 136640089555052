import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { environmentCommon } from "../../../environment/environment.common";

@Component({
    selector: "read-more",
    templateUrl: "./read-more.component.html",
    styleUrls: ["./read-more.component.scss"],
})
export class ReadMoreComponent implements OnDestroy, AfterViewInit {
    @ViewChild("replyMessage") replyMessage: ElementRef;
    @Input("showFullDescription") showFullDescription: boolean;
    @Input("setHeight") setHeight = true;

    readonly MAX_HEIGHT = environmentCommon.quillConfig.maxHeight.reply;
    expandCardView = false;
    showSeeMore = false;

    private resizeObservable: ResizeObserver;
    constructor() {
        this.resizeObservable = new ResizeObserver(() => this.toggleSeeMoreButton());
    }

    ngAfterViewInit(): void {
        this.observeElm();
    }

    observeElm(): void {
        if (this.replyMessage?.nativeElement) {
            this.resizeObservable.observe(this.replyMessage.nativeElement);
        }
    }

    toggleSeeMoreButton(): void {
        //Measures the height of the quill editor and triggers 'see more' if longer than our max height
        this.showSeeMore = this.replyMessage?.nativeElement?.offsetHeight > this.MAX_HEIGHT;
    }

    ngOnDestroy(): void {
        if (this.resizeObservable) {
            this.resizeObservable.disconnect();
        }
    }
}
