import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { MessageCardComponent } from "./components/message-card/message-card.component";

@Injectable()
export class MessagePlugin implements IPluginFactory {
    id = "MessagePlugin";

    constructor(libraries: Libraries) {
        libraries.cardViews.register("message", MessageCardComponent);
    }
}
