import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Notification, NotificationState, NotificationTopic, WebsocketData } from "@visoryplatform/notifications-core";
import { Observable, from, of } from "rxjs";
import { ToastSeverity, ToastSummary } from "projects/portal-modules/src/lib/shared/constants/toast.constants";
import { filter, take } from "rxjs/operators";

import { ActivityNotificationsService } from "projects/portal-modules/src/lib/notifications";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { FeatureFlagService } from "projects/portal-modules/src/lib/feature-flags";
import { FormControl } from "@angular/forms";
import { FxContextMenuComponent } from "@visoryplatform/fx-ui";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { MessageService } from "@visoryplatform/portal-ui";
import { NotificationFilter } from "../notifications-panel/notifications-panel.component";
import { NotificationsService } from "../../services/notifications.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { switchMap } from "rxjs/operators";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "list-notifications",
    templateUrl: "./list-notifications.component.html",
    styleUrls: ["./list-notifications.component.scss"],
})
export class ListNotificationsComponent implements OnInit, OnChanges {
    @Input() notifications: Notification[];
    @Input() currentFilter: string = NotificationFilter.All;
    @Input() showUnreadOnly: FormControl<boolean>;
    @Input() unreadNotificationCount: number;

    @Output() open = new EventEmitter<Notification>();
    @Output() deleteMessage = new EventEmitter();
    @Output() markAsRead = new EventEmitter();
    @Output() markAsUnread = new EventEmitter();
    @Output() markAllAsRead = new EventEmitter();
    @Output() getNextPage = new EventEmitter();
    @Output() settingsNavigate = new EventEmitter();
    @Output() filterChange = new EventEmitter<string>();

    @ViewChild("allNotificationsMenu", { static: false })
    menu: FxContextMenuComponent;
    @ViewChild(CdkVirtualScrollViewport)
    scrollViewport: CdkVirtualScrollViewport;

    NotificationState = NotificationState;
    filteredNotifications = [];
    loader = new Loader();

    isListEnd = false;
    canViewTabs$: Observable<boolean>;
    gaEvents = GA_EVENTS;

    protected readonly FilterBy = NotificationFilter;

    constructor(
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private activityNotifications: ActivityNotificationsService,
        private messageService: MessageService,
    ) {}

    public ngOnInit(): void {
        const featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableNotificationFilters);

        this.canViewTabs$ = featureEnabled$.pipe(
            switchMap((enabled) => {
                if (!enabled) {
                    return of(false);
                }

                return this.authService.getUser().pipe(
                    filter((user) => !!user),
                    switchMap((user) => this.userHasPermission(user, "ReadInternalChat")),
                );
            }),
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.notifications?.currentValue) {
            this.filteredNotifications = this.filterNotifications();
        }
    }

    setCurrentFilter(assignedTo: NotificationFilter): void {
        this.currentFilter = assignedTo;
        this.filterChange.emit(assignedTo);
    }

    public handleNextPage(): void {
        const end = this.scrollViewport.getRenderedRange().end;
        const total = this.scrollViewport.getDataLength();
        if (end === total && !this.isListEnd) {
            this.isListEnd = true;
            this.getNextPage.emit();
        } else {
            this.isListEnd = false;
        }
    }

    public filterNotifications(): Notification[] {
        return this.notifications.filter(
            (notification) =>
                notification.state !== NotificationState.Deleted &&
                notification.topic !== NotificationTopic.ReadReceipt,
        );
    }

    public markAllNotificationsAsRead(): void {
        from(this.activityNotifications.markAllAsRead())
            .pipe(take(1))
            .subscribe(() => {
                this.messageService.add({
                    severity: ToastSeverity.Success,
                    summary: ToastSummary.Success,
                    detail: "All notifications have been successfully marked as read.",
                });
                this.markAllAsRead.emit();
            });
    }

    public markNotificationUnread(notification: Notification): void {
        if (!(notification.state === NotificationState.Resolved || notification.state === NotificationState.Deleted)) {
            return;
        }

        this.notificationsService.markAsUnread(notification.id);

        notification.state = NotificationState.Delivered;
        this.markAsUnread.emit();
    }

    public markNotificationRead(notification: Notification): void {
        if (notification.state === NotificationState.Resolved || notification.state === NotificationState.Deleted) {
            return;
        }

        this.notificationsService.markAsRead(notification.channel);
        notification.state = NotificationState.Resolved;
        this.markAsRead.emit();
    }

    public deleteNotification(notification: Notification): void {
        this.notificationsService.markAsDeleted(notification.id);
        notification.state = NotificationState.Deleted;
    }

    public openNotification(notification: Notification<WebsocketData>): void {
        this.open.emit(notification);
        this.markNotificationRead(notification);
    }

    public trackNotification(_index: number, notification: Notification): string {
        return notification?.id;
    }

    private userHasPermission(user: AppUser, permission: string): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, permission);
    }
}
