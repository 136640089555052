<div
    class="user-detail"
    @fadeIn
    *ngIf="user"
>
    <fx-avatar
        [name]="user?.profile?.name"
        size="medium"
        [inverted]="false"
        [image]="user.id | profilePicture | async"
    ></fx-avatar>
    <div class="user-detail-info">
        <p>{{ user?.profile?.name }}</p>

        <div class="user-detail-info-title">
            <ng-content></ng-content>
        </div>
    </div>
</div>
