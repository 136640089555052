import { Component, Inject } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    ITimeline,
    IParticipant,
    IThread,
    IUserSetupRequest,
    ParticipantType,
    Role,
    UserRoleService,
} from "@visoryplatform/threads";
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import {
    IInviteClientModalData,
    InviteClientModalComponent,
} from "projects/portal-modules/src/lib/threads-ui/components/invite-client/invite-client-modal.component";
import { AccountsService } from "projects/portal-modules/src/lib/threads-ui/services/accounts.service";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { ParticipantService } from "projects/portal-modules/src/lib/threads-ui/services/participant.service";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";

interface ThreadAddParticipantModalData {
    thread: ITimeline;
    role: Role;
}

@Component({
    selector: "thread-add-participant",
    templateUrl: "thread-add-participant.component.html",
    styleUrls: ["thread-add-participant.component.scss"],
})
export class ThreadAddParticipantComponent {
    readonly gaEvents = GA_EVENTS;

    loader = new Loader();
    searchLoader = new Loader();
    roles = Role;
    role: Role;
    searchResults: IParticipant[];
    selectedParticipants: IParticipant[] = [];
    searchTerm = new UntypedFormControl();
    userId: string;

    inviteClientsEnabled = this.environment.featureFlags.inviteClients;
    private accountParticipants$: Observable<IParticipant[]>;
    private thread: IThread;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ThreadAddParticipantModalData,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialogRef: MatDialogRef<ThreadAddParticipantComponent>,
        private threadsService: ThreadsService,
        private participantsCache: ParticipantCache,
        private accountsService: AccountsService,
        private dialog: MatDialog,
        private participantService: ParticipantService,
        private authService: AuthService,
    ) {
        this.thread = data.thread;
        this.role = data.role;

        this.getCurrentUserId();

        if (this.thread && this.thread.accountId) {
            this.accountParticipants$ = this.accountsService.getAccount(this.thread.accountId).pipe(
                map((account) => account.contacts.map((contact) => contact.id)),
                switchMap((participantIds) => this.participantsCache.getParticipants(participantIds)),
                shareReplay(1),
            );
        }

        this.searchTerm.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => (this.searchResults = null)),
                switchMap((searchTerm) => this.searchLoader.wrap(this.search(searchTerm))),
            )
            .subscribe((value) => (this.searchResults = value));

        this.searchTerm.setValue("");
    }

    launchInviteClientModal() {
        const data = {
            threadId: this.thread.id,
            additionalInformation: {
                createdTime: new Date(Date.now()).toISOString(),
                createdBy: this.userId,
            },
        };

        const config = {
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "brand-font-color"],
            maxWidth: "800px",
            height: "auto",
            autoFocus: true,
            data,
        };

        const dialogRef = this.dialog.open<InviteClientModalComponent, IInviteClientModalData, IUserSetupRequest>(
            InviteClientModalComponent,
            config,
        );

        dialogRef.afterClosed().subscribe((data) => {
            this.dialogRef.close(data);
        });
    }

    async addParticipants() {
        this.loader.show();

        await Promise.all(
            this.selectedParticipants.map((user) => {
                const roleToAdd = UserRoleService.getUserRole(user.id);

                return this.threadsService
                    .addParticipant(this.thread.id, user.id, roleToAdd, ParticipantType.User)
                    .toPromise();
            }),
        );

        this.loader.hide();
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }

    selectUser(searchResult: IParticipant) {
        this.selectedParticipants.push(searchResult);
        this.searchTerm.setValue("");
        this.searchResults = null;
    }

    unselectUser(index: number) {
        this.selectedParticipants.splice(index, 1);
    }

    private async search(term: string): Promise<IParticipant[]> {
        const results = await (this.role === Role.Client
            ? this.searchAccount(term)
            : this.participantService.searchAllUsers(term).toPromise());
        if (!results) {
            return results;
        }

        return results.filter((user) => {
            const isAlreadyParticipant = this.thread.participants.some((participant) => participant.id === user.id);
            const isAlreadySelected = this.selectedParticipants.some((selected) => selected.id === user.id);
            return !isAlreadyParticipant && !isAlreadySelected;
        });
    }

    private async searchAccount(searchTerm: string): Promise<IParticipant[]> {
        if (!this.accountParticipants$) {
            return null;
        }
        const participants = await this.accountParticipants$.toPromise();
        const lowerTerm = searchTerm.toLowerCase();

        return participants.filter((participant) => {
            if (!participant.profile) {
                return true;
            }
            return participant.profile.name.toLowerCase().includes(lowerTerm);
        });
    }

    private getCurrentUserId(): void {
        this.authService
            .getUser()
            .pipe(take(1))
            .subscribe((user) => {
                this.userId = user.id;
            });
    }
}
