import { PortalModule } from "@angular/cdk/portal";
import { Inject } from "@angular/core";
import { NgModule } from "@angular/core";
import { IPluginFactory, PLUGIN_FACTORY } from "./interfaces/IPluginFactory";
import { Libraries } from "./services/Libraries";
import { ViewExtensionComponent } from "./components/view-extension/view-extension.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ViewExtensionComponent],
    exports: [ViewExtensionComponent],
    imports: [CommonModule, PortalModule],
    providers: [Libraries],
})
export class PluginsModule {
    constructor(@Inject(PLUGIN_FACTORY) _plugins: IPluginFactory[]) {}
}
