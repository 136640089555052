import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AuthService } from "./../../../../portal-modules/src/lib/findex-auth";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ICardTaskDetail, Role } from "@visoryplatform/threads";
import { GreetingFunction } from "../../greeting-helper";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { MenuService, MenuType } from "projects/portal-modules/src/lib/shared/services/menu.service";

@Component({
    selector: "dashboard-greeting-banner",
    templateUrl: "./dashboard-greeting-banner.component.html",
    styleUrls: ["./dashboard-greeting-banner.component.scss"],
})
export class DashboardGreetingBannerComponent implements OnInit, OnChanges {
    @Input() role: Role;

    readonly gaEvents = GA_EVENTS;
    readonly appName = this.environment.appName;

    greeting$: Observable<string>;
    tasks$: Observable<ICardTaskDetail[]>;
    subscriptions: Subscription[] = [];

    canCreateNewRequest$: Observable<boolean>;

    private readonly featureFlags = LaunchDarklyFeatureFlags;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private menuService: MenuService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { role } = changes;

        if (role && this.role) {
            this.canCreateNewRequest$ = this.canCreateNewRequest(this.role);
        }
    }

    ngOnInit(): void {
        this.greeting$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => GreetingFunction.userToGreeting(user)),
        );
    }

    newRequest(): void {
        this.menuService.hide(MenuType.NotificationsMobile);
        this.menuService.toggle(MenuType.CustomerRequest);
    }

    canCreateNewRequest(role: Role): Observable<boolean> {
        const permission = this.permissionService.hasSomePermission(role, [
            "CreateCustomerRequest",
            "CreateExpertRequest",
            "ThreadUpdateAll",
        ]);
        const featureFlag = this.featureFlagService.getFlag(this.featureFlags.EnableCustomerNewRequest);

        return combineLatest([permission, featureFlag]).pipe(
            map(([hasPermission, isEnabled]) => hasPermission && isEnabled),
        );
    }
}
