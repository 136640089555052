import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { mapTo } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { ButtonType } from "projects/portal-modules/src/lib/shared/components/error/error.component";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { IThreadCard } from "@visoryplatform/threads";

@Injectable({ providedIn: "root" })
export class VideoCallService {
    constructor(
        private http: HttpClient,
        private cardService: ThreadCardService,
        private errorService: ErrorService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    createCall(threadId: string): Observable<null> {
        const { videoCall } = environmentCommon.cardsEndpoints;
        return this.cardService.createStackCard<any, IThreadCard>(threadId, videoCall).pipe(mapTo(null));
    }

    createSignature(
        threadId: string,
        sessionName: string,
        userIdentity: string,
        sessionKey: string,
    ): Observable<string> {
        const { signature } = environmentCommon.videoChatEndpoints;
        const url = `${this.environment.videoChatEndpoints.base}/threads/${threadId}/${signature}`;
        return this.http.post<string>(url, {
            sessionName,
            userIdentity,
            sessionKey,
        });
    }

    endSession(sessionKey: string, threadId: string, cardId: string): Observable<void> {
        const { endSession } = environmentCommon.videoChatEndpoints;
        const baseUrl = this.environment.videoChatEndpoints.base;
        const url = `${baseUrl}/threads/${threadId}/cards/${cardId}/${endSession}`;
        return this.http.post(url, { sessionKey }).pipe(mapTo(null));
    }

    sendSessionIdEvent(threadId: string, cardId: string, sessionId: string): Observable<void> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/threads/${threadId}/cards/${cardId}/events`;
        const body = { type: "video-call-session-joined", payload: { sessionId } };
        return this.http.post<void>(url, body);
    }

    handleError(error) {
        let title = "Sorry, something went wrong";
        let buttonTitle = ButtonType.GO_BACK;
        let allowAction = false;

        if (error === "Unable to send video/audio") {
            allowAction = true;
            buttonTitle = ButtonType.TRY_AGAIN;
            title = "Multiple devices connected";
        }

        return this.errorService.showErrorModal(title, error, buttonTitle, allowAction);
    }
}
