import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, mapTo } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { ButtonType } from "projects/portal-modules/src/lib/shared/components/error/error.component";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { IRecording, IRecordingUrl, IThreadCard, IThreadRecordings } from "@visoryplatform/threads";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";

@Injectable({ providedIn: "root" })
export class VideoChatService {
    private isUserInMeeting = new BehaviorSubject<boolean>(false);
    sharedIsUserInMeeting$ = this.isUserInMeeting.asObservable();

    constructor(
        private http: HttpClient,
        private cardService: ThreadCardService,
        private errorService: ErrorService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    createChat(threadId: string): Observable<null> {
        const { videoChat } = environmentCommon.cardsEndpoints;
        return this.cardService.createStackCard<any, IThreadCard>(threadId, videoChat).pipe(mapTo(null));
    }

    async createSession(): Promise<string> {
        const url = `${this.environment.videoChatEndpoints.base}/${environmentCommon.videoChatEndpoints.createSession}`;
        return this.http
            .post<{ sessionId: string }>(url, {})
            .pipe(map((result) => result.sessionId))
            .toPromise();
    }

    async getVideoChatToken(sessionId: string): Promise<string> {
        const url = `${this.environment.videoChatEndpoints.base}/${environmentCommon.videoChatEndpoints.generateToken}`;
        return this.http
            .post<{ token: string }>(url, { sessionId })
            .pipe(map((result) => result.token))
            .toPromise();
    }

    async getRecordingsByThread(threadId: string): Promise<IThreadRecordings[]> {
        const url = `${this.environment.videoChatEndpoints.base}/threads/${threadId}/${environmentCommon.videoChatEndpoints.recordings}`;
        return this.http
            .get<IThreadRecordings[]>(url)
            .pipe(map((result) => result))
            .toPromise();
    }

    async getRecordings(threadId: string, cardId: string): Promise<IRecording[]> {
        const url = `${this.environment.videoChatEndpoints.base}/threads/${threadId}/cards/${cardId}/${environmentCommon.videoChatEndpoints.recordings}`;
        return this.http
            .get<IRecording[]>(url)
            .pipe(map((result) => result))
            .toPromise();
    }

    async getRecordingDownloadUrl(threadId: string, cardId: string, recordingId: string): Promise<string> {
        const url = `${this.environment.videoChatEndpoints.base}/threads/${threadId}/cards/${cardId}/${environmentCommon.videoChatEndpoints.recordings}/${recordingId}/${environmentCommon.videoChatEndpoints.download}`;
        return this.http
            .get<IRecordingUrl>(url)
            .pipe(map((result) => result.url))
            .toPromise();
    }

    updateIsUserInMeeting(status: boolean) {
        this.isUserInMeeting.next(status);
    }

    async terminateSession(sessionId: string, threadId: string, cardId: string): Promise<void> {
        const url = `${this.environment.videoChatEndpoints.base}/${environmentCommon.videoChatEndpoints.terminateSession}`;
        return this.http
            .post<{ sessionId: string }>(url, { sessionId, threadId, cardId })
            .pipe(map(() => {}))
            .toPromise();
    }

    handleError(error) {
        let title = "Sorry, something went wrong";
        let buttonTitle = ButtonType.GO_BACK;
        let allowAction = false;

        if (error === "Unable to send video/audio") {
            allowAction = true;
            buttonTitle = ButtonType.TRY_AGAIN;
            title = "Multiple devices connected";
        }

        return this.errorService.showErrorModal(title, error, buttonTitle, allowAction);
    }
}
