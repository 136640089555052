<quill-view
    #quillView
    *ngIf="!useHtml"
    [style]="quillStyles"
    [content]="content | allowWhitelistLinks"
    aria-label="message"
>
</quill-view>
<quill-view-html
    #quillView
    *ngIf="useHtml"
    [style]="quillStyles"
    [content]="content | allowWhitelistLinks"
    aria-label="message"
>
</quill-view-html>
<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="mentionElement"
    [cdkConnectedOverlayOpen]="!!tooltipData"
    [cdkConnectedOverlayPositions]="[
        {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
        },
    ]"
>
    <mention-tooltip
        [name]="tooltipData.name"
        [userId]="tooltipData.userId"
        [title]="tooltipData.title"
    ></mention-tooltip>
</ng-template>
