<centered-modal-layout
    (save)="completeRfi()"
    (close)="close()"
    [loading]="!!loader.counter"
    cancelButtonTitle="No"
    saveButtonTitle="Yes"
    analyticsPostfix=""
    [analyticsPrefix]="data.analyticsPrefix"
>
    <div modal-header>{{ data.title }}</div>
    <div modal-subhead>
        {{ data.subhead }}
    </div>
</centered-modal-layout>
