<header class="workflow-header">
    <div class="workflow-header-prepend">
        <threads-services-badge [threadType]="thread.type"></threads-services-badge>
    </div>

    <div class="workflow-header-content">
        <div class="workflow-title">
            <div class="workflow-title-part">
                {{ thread.title }}
            </div>

            <div
                *ngIf="thread.accountId"
                class="workflow-title-part workflow-title-account"
            >
                <a [routerLink]="['/accounts', thread.accountId]">
                    {{ thread.account?.label }}
                </a>
            </div>
        </div>

        <workflow-tabs [thread]="thread"></workflow-tabs>
    </div>
</header>
