<thread-card
    *ngIf="cardResources.card$ | async as card"
    [title]=""
    [thread]="cardResources.thread$ | async"
    [card]="card"
    [replies]="replies$ | async"
    [loading]="!!loader.counter"
    [inCardReplies]="false"
    [isInternal]="card.isInternal"
    (openRepliesInModal)="openModal(true)"
>
    <div
        *ngIf="cardResources.state$ | async as state"
        (click)="openModal(false)"
        [ngClass]="{ 'instructions-panel--active': isThreadActive$ | async }"
        class="instructions-panel"
    >
        <div class="instructions-panel-header">
            <h4 class="instructions-heading">Transaction queries</h4>

            <fx-badge
                [label]="(isComplete$ | async) ? 'Completed' : 'Pending'"
                [type]="(isComplete$ | async) ? 'success' : 'default'"
            ></fx-badge>
        </div>

        <div class="instructions-panel-info">
            <div class="instructions-panel-progress">
                <progress-bar [progress]="actionedPercentage$ | async"></progress-bar>
                <div class="instructions-panel-progress-actioned">
                    {{ actionAmmount$ | async }} / {{ actionTotal$ | async }} actioned
                </div>
            </div>

            <div class="instructions-panel-info-icons">
                <i class="las la-comment-alt"></i>
                <span>{{ (replies$ | async)?.length || 0 }}</span>
            </div>
        </div>
    </div>

    <button
        *ngIf="hasPermissionToAction$ | async"
        class="instructions-button fx-btn fx-btn--primary"
        (click)="openModal(false)"
    >
        REVIEW
    </button>

    <div
        card-actions
        *ngIf="allowEdit"
    >
        <fx-context-menu
            *ngIf="hasPermissionToEdit$ | async"
            #menu
            [gutter]="false"
        >
            <a (click)="action(TRANSACTIONS_EDIT)">Edit</a>
            <a (click)="deleteCard(cardResources.threadId, cardResources.cardId)">Delete</a>
        </fx-context-menu>
    </div>

    <div card-loader>
        <loader-icon color="light"></loader-icon>
    </div>
</thread-card>
