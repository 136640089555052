<div class="app-container">
    <div class="messages-header">
        <div>
            <h3>Message search</h3>
            <div>Vector search over all Visory messages</div>
        </div>
    </div>

    <form
        class="fx-form-group search-controls"
        [formGroup]="form"
    >
        <div class="form-group search-controls-query">
            <label
                class="fx-form-label general-settings-form-label"
                for="searchControl"
                >Search</label
            >
            <div class="fx-form-control">
                <input
                    formControlName="query"
                    id="searchControl"
                    class="fx-form-input"
                    type="text"
                    placeholder=""
                />
            </div>
        </div>

        <div class="form-group">
            <label for="userTypeSelect">User type: </label>
            <v-select
                id="userTypeSelect"
                placeholder="All users"
                [options]="userTypeOptions"
                [optionContent]="actorTypeContent"
                [valueContent]="actorTypeContent"
                formControlName="actorType"
                [tuiTextfieldCleaner]="false"
            >
            </v-select>
            <ng-template
                #actorTypeContent
                let-option
            >
                {{ userTypeLabels[option] }}
            </ng-template>
        </div>

        <div class="form-group">
            <label for="threadTypeSelect">Workflow type: </label>
            <v-select
                id="threadTypeSelect"
                placeholder="All timelines"
                [options]="threadTypeOptions"
                [optionContent]="threadTypeContent"
                [valueContent]="threadTypeContent"
                formControlName="threadTypes"
                [tuiTextfieldCleaner]="false"
            >
            </v-select>
            <ng-template
                #threadTypeContent
                let-option
            >
                {{ threadTypeLabels[option] }}
            </ng-template>
        </div>
    </form>

    <div class="messages">
        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>

        <ng-container *ngIf="results$ | async as results">
            <message
                *ngFor="let message of $any(results)"
                [message]="message.message"
                [actorId]="message.actorId"
                [createdAt]="message.createdAt"
                [threadId]="message.threadId"
                [cardId]="message.cardId"
            ></message>
        </ng-container>
    </div>
</div>
