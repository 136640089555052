import { Pipe, PipeTransform } from "@angular/core";
import { IThread, IThreadListing } from "@visoryplatform/threads";
import { ThreadsService } from "../services/threads.service";

@Pipe({ name: "isThreadActive" })
export class IsThreadActivePipe implements PipeTransform {
    constructor(private threadsService: ThreadsService) {}

    transform(thread: IThread | IThreadListing): boolean {
        return this.threadsService.isThreadActive(thread);
    }
}
