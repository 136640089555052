<ng-container *ngIf="userPaymentDetails; else noSubscriptions">
    <div
        class="subscription-panel"
        *ngFor="let subscription of userPaymentDetails?.subscriptions?.data"
    >
        <div class="subscription-panel-content">
            <fx-layout
                [columns]="24"
                [gutters]="false"
            >
                <fx-layout-column [sizes]="{ mobile: '24' }">
                    {{ $any(subscription).plan.nickname }}
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    Next payment
                    {{ subscription.current_period_end * 1000 | date : "dd MMMM yyyy" }}
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    <div class="subscription-panel-content-right">
                        {{
                            $any(subscription).plan.amount / 100 | currency : (userPaymentDetails.currency | uppercase)
                        }}
                        <ng-container *ngFor="let data of subscription.items.data">
                            <div *ngIf="data.price.type === 'recurring'">
                                <i class="las la-sync"></i> This is a recurring direct debit
                            </div>
                        </ng-container>
                    </div>
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '24' }">
                    <a
                        class="subscription-panel-view-history"
                        [routerLink]="['/accounts', (account$ | async).id, 'billing']"
                        analyticsClick="app_viewbillinghistory"
                        >View billing history</a
                    >
                </fx-layout-column>
            </fx-layout>
        </div>
    </div>
    <div
        class="subscription-panel"
        *ngIf="!hidePaymentMethods"
    >
        <div
            class="subscription-panel-content"
            *ngIf="!!paymentMethods; else loading"
        >
            <fx-layout
                [columns]="24"
                [gutters]="false"
            >
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    <div class="subscription-panel-content-heading">Billing details</div>
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    <div class="subscription-panel-content-right">
                        <a
                            class="subscription-panel-content-action"
                            (click)="editBillingDetails(userPaymentDetails.id, paymentMethods)"
                            analyticsClick="app_editbillingdetails"
                            >Add</a
                        >
                    </div>
                </fx-layout-column>

                <fx-layout-column
                    [sizes]="{ mobile: '24' }"
                    *ngIf="paymentMethods"
                >
                    <div
                        class="subscription-panel-card"
                        *ngFor="let method of paymentMethods"
                    >
                        <fx-layout
                            [columns]="24"
                            [gutters]="false"
                        >
                            <ng-container *ngIf="method.card">
                                <fx-layout-column [sizes]="{ mobile: '6', tablet: '2' }">
                                    <div [ngSwitch]="method.card.brand">
                                        <div
                                            class="subscription-panel-card-logo"
                                            *ngSwitchCase="'visa'"
                                        >
                                            <img src="./assets/images/payments/logo-visa.svg" />
                                        </div>
                                        <div *ngSwitchDefault>
                                            <i class="lab la-cc-{{ method.card.brand }}"></i>
                                        </div>
                                    </div>
                                </fx-layout-column>
                                <fx-layout-column [sizes]="{ mobile: '18', tablet: '5' }">
                                    .... .... .... {{ method.card.last4 }}
                                </fx-layout-column>
                                <fx-layout-column [sizes]="{ mobile: '12', tablet: '5' }">
                                    {{ method.billing_details.name }}
                                </fx-layout-column>
                                <fx-layout-column [sizes]="{ mobile: '12', tablet: '5' }">
                                    expires {{ method.card.exp_month + "/" + method.card.exp_year }}
                                </fx-layout-column>
                            </ng-container>
                            <ng-container *ngIf="method.au_becs_debit">
                                <fx-layout-column [sizes]="{ mobile: '6', tablet: '2' }">
                                    <div class="subscription-panel-card-logo">
                                        <img src="./assets/images/payments/logo-becs.png" />
                                    </div>
                                </fx-layout-column>
                                <fx-layout-column [sizes]="{ mobile: '18', tablet: '5' }">
                                    BSB: {{ method.au_becs_debit.bsb_number }}
                                </fx-layout-column>
                                <fx-layout-column [sizes]="{ mobile: '12', tablet: '5' }">
                                    Account: .....{{ method.au_becs_debit.last4 }}
                                </fx-layout-column>
                            </ng-container>
                        </fx-layout>
                    </div>
                    <div *ngIf="!paymentMethods.length">You have no payment methods.</div>
                    <div
                        class="subscription-panel-card-error"
                        *ngIf="paymentMethodError"
                    >
                        {{ paymentMethodError }}
                    </div>
                </fx-layout-column>
            </fx-layout>
        </div>
        <ng-template #loading>
            <loader-icon
                [static]="true"
                color="light"
            ></loader-icon>
        </ng-template>
    </div>

    <div class="subscription-panel">
        <div class="subscription-panel-content">
            <fx-layout
                [columns]="24"
                [gutters]="false"
            >
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    <div class="subscription-panel-content-heading">Billing contact</div>
                </fx-layout-column>
                <fx-layout-column [sizes]="{ mobile: '12' }">
                    <div class="subscription-panel-content-right">
                        <a
                            class="subscription-panel-content-action"
                            (click)="
                                editBillingContactDetails(
                                    userPaymentDetails.id,
                                    userPaymentDetails.name,
                                    userPaymentDetails.email,
                                    userPaymentDetails.address
                                )
                            "
                            analyticsClick="app_editbillingcontact"
                            >Edit</a
                        >
                    </div>
                </fx-layout-column>

                <fx-layout-column [sizes]="{ mobile: '24' }">
                    <div class="subscription-panel-contacts">
                        <fx-layout
                            [columns]="24"
                            [gutters]="false"
                        >
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '6' }">
                                <div class="subscription-panel-content-small-heading">Business name</div>
                                {{ userPaymentDetails.name }}
                            </fx-layout-column>
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '18' }">
                                <div class="subscription-panel-content-small-heading">Email</div>
                                {{ userPaymentDetails.email }}
                            </fx-layout-column>
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '6' }">
                                <div class="subscription-panel-content-small-heading">Address</div>
                                {{ userPaymentDetails.address?.line1 }}
                            </fx-layout-column>
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '6' }">
                                <div class="subscription-panel-content-small-heading">City</div>
                                {{ userPaymentDetails.address?.city }}
                            </fx-layout-column>
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '6' }">
                                <div class="subscription-panel-content-small-heading">Post code</div>
                                {{ userPaymentDetails.address?.postal_code }}
                            </fx-layout-column>
                            <fx-layout-column [sizes]="{ mobile: '24', laptop: '6' }">
                                <div class="subscription-panel-content-small-heading">State</div>
                                {{ userPaymentDetails.address?.state }}
                            </fx-layout-column>
                        </fx-layout>
                    </div>
                </fx-layout-column>
            </fx-layout>
        </div>
    </div>
</ng-container>
<ng-template #noSubscriptions>
    <div>No {{ packageDisplayName }} active.</div>
</ng-template>
