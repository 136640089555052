import { Component, Input } from "@angular/core";

@Component({
    selector: "delphi-logo",
    templateUrl: "./delphi-logo.component.html",
    styleUrls: ["./delphi-logo.component.scss"],
})
export class DelphiLogoComponent {
    @Input() label: string;
}
