<ng-container *ngIf="designType">
    <div
        *ngIf="requiresCustomMonth"
        [formGroup]="form"
    >
        <label class="fx-form-label">Month:</label>

        <div class="v-select-container">
            <v-select
                placeholder="Select a month"
                [options]="newThreadMonths"
                [optionContent]="optionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="currentMonthControl"
            >
            </v-select>

            <ng-template
                #optionContent
                let-option
            >
                {{ option }}
            </ng-template>
        </div>

        <div
            *ngIf="(currentMonthControl.dirty || currentMonthControl.touched) && !currentMonthControl.valid"
            class="fx-form-validation"
        >
            Please select a month for this workflow.
            <em class="fx-form-validation-icon las la-exclamation-triangle"></em>
        </div>
    </div>

    <div *ngIf="requiresCustomTitle">
        <label class="fx-form-label">Workflow title:</label>

        <div class="fx-form-flex">
            <div
                class="fx-form-prefix"
                *ngIf="titlePrefix"
            >
                <span>{{ titlePrefix }} &nbsp;</span>
            </div>
            <input
                class="fx-form-input"
                placeholder="Enter workflow title..."
                [formControl]="customThreadTitle"
                required
            />
        </div>
        <div
            *ngIf="customThreadTitle.dirty && !customThreadTitle.valid && customThreadTitle.touched"
            class="fx-form-validation"
        >
            Please enter a workflow title less than {{ CUSTOM_TITLE_MAX_LENGTH }} characters.
            <em class="fx-form-validation-icon las la-exclamation-triangle"></em>
        </div>
        <br />
    </div>

    <div *ngIf="!requiresCustomTitle && !requiresCustomMonth && title">
        <label class="fx-form-label">Workflow title:</label>
        <div class="fx-form-flex">
            <div
                class="fx-form-prefix"
                *ngIf="titlePrefix"
            >
                <span>{{ titlePrefix }} &nbsp;</span>
            </div>
            <input
                class="fx-form-input"
                placeholder="Enter workflow title..."
                [value]="title"
                [disabled]="true"
                required
            />
        </div>
    </div>
</ng-container>
