import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexUiModule } from "../findex-ui";
import { NotificationsFrontendModule as FindexNotificationsModule } from "projects/notifications-frontend/src/notifications-frontend.module";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import {
    NOTIFICATIONS_APPID,
    NOTIFICATIONS_URL,
    NOTIFICATIONS_WS_HEADERS,
    NOTIFICATIONS_WS_URL,
} from "projects/notifications-frontend/src/injection-tokens";
import { EnvironmentSpecificConfig } from "../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { AuthService } from "../findex-auth";
import { filter, switchMap } from "rxjs/operators";
import { CardReadStatusComponent } from "./components/card-read-status/card-read-status.component";
import { CardSeenByComponent } from "./components/card-seen-by/card-seen-by.component";
import { ListNotificationBannersComponent } from "./components/list-notification-banners/list-notification-banners.component";
import { NotificationBannerComponent } from "./components/notification-banner/notification-banner.component";
import { ThreadsUiPipesModule } from "../threads-ui/pipes/threads-ui-pipes.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { PluginsModule } from "../plugins";
import { PortalUiModule } from "@visoryplatform/portal-ui";

const exportDeclarations = [
    CardReadStatusComponent,
    ListNotificationBannersComponent,
    NotificationBannerComponent,
    CardSeenByComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FindexUiModule,
        FindexNotificationsModule,
        ThreadsUiPipesModule,
        SharedPipesModule,
        PluginsModule,
        PortalUiModule,
    ],
    declarations: [...exportDeclarations],
    exports: [FindexNotificationsModule, ...exportDeclarations],
    providers: [
        {
            provide: NOTIFICATIONS_APPID,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.appId,
            deps: [ENVIRONMENT],
        },
        {
            provide: NOTIFICATIONS_WS_URL,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.notificationsEndpoints.websockets,
            deps: [ENVIRONMENT],
        },
        {
            provide: NOTIFICATIONS_WS_HEADERS,
            useFactory: (authService: AuthService) =>
                authService.getUser().pipe(
                    filter((user) => !!user),
                    switchMap(() => authService.getVerifiedHttpHeaders()),
                ),
            deps: [AuthService],
        },
        {
            provide: NOTIFICATIONS_URL,
            useFactory: (environment: EnvironmentSpecificConfig) => environment.notificationsEndpoints.base,
            deps: [ENVIRONMENT],
        },
        NotificationsService,
    ],
})
export class NotificationsModule {}
