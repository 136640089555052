<li class="menu-item">
    <a
        *ngIf="isExternalLink; else relativeLink"
        [href]="route"
        target="_blank"
        rel="noopener noreferrer"
    >
        <i
            *ngIf="icon"
            class="las {{ icon }}"
        ></i>
        <div class="menu-item-content">
            <ng-content select="[label]"></ng-content>
        </div>
    </a>
    <ng-template #relativeLink>
        <a
            [routerLink]="route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: exact }"
        >
            <i
                *ngIf="icon"
                class="las {{ icon }}"
            ></i>
            <div class="menu-item-content">
                <ng-content></ng-content>
            </div>
        </a>
    </ng-template>
</li>
