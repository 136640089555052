import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CalendarEvent } from "angular-calendar";

@Component({
    selector: "lib-calendar-meeting",
    templateUrl: "./calendar-meeting.component.html",
    styleUrls: ["./calendar-meeting.component.scss"],
})
export class CalendarMeetingComponent {
    @Input() public color: string;
    @Input() public start: Date;
    @Input() public end: Date;
    @Input() public title: string;
    @Input() public metaData: CalendarEvent;

    @Output() public calendarMeetingClicked = new EventEmitter<void>();

    public meetingClicked(): void {
        this.calendarMeetingClicked.emit();
    }
}
