import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { DurationBadgeColour } from "../duration-badge/duration-badge.component";
import { IStep, SlaExtensionHelpers, WorkTimeExtensionHelpers } from "@visoryplatform/workflow-core";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { of, timer } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { DateTime } from "luxon";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { AuthService } from "../../../findex-auth";

@Component({
    selector: "sla-remaining",
    templateUrl: "./sla-remaining.component.html",
    styleUrls: ["./sla-remaining.component.scss"],
})
export class SlaRemainingComponent implements OnChanges {
    @Input() step: IStep;
    @Input() liveCountdown: boolean;
    @Input() showLabel = true;

    workTime$: Observable<number | null>;
    slaTime$: Observable<number | null>;
    slaColour$: Observable<DurationBadgeColour | null>;

    constructor(
        private featureFlagService: FeatureFlagService,
        private permissionService: PermissionService,
        private authService: AuthService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { step, liveCountdown } = changes;

        if (step || liveCountdown) {
            this.slaTime$ = this.getSlaTimeRemaining(this.step, this.liveCountdown);
            this.workTime$ = this.getWorkTime(this.step);
            this.slaColour$ = combineLatest([this.slaTime$, this.workTime$]).pipe(
                map(([slaTime, workTime]) => this.getSlaColour(slaTime, workTime)),
            );
        }
    }

    private getSlaTimeRemaining(step: IStep, liveCountdown: boolean): Observable<number | null> {
        const flagEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableSlaRemainingTime);

        return flagEnabled$.pipe(
            switchMap((hasFlagEnabled) => {
                if (hasFlagEnabled) {
                    return this.getSlaRemainingInterval(step, liveCountdown);
                } else {
                    return of(null);
                }
            }),
        );
    }

    private getSlaRemainingInterval(step: IStep, liveCountdown: boolean): Observable<number | null> {
        const currentRemainingTime = SlaExtensionHelpers.calculateSlaRemainingTime(step?.extensions);

        if (liveCountdown) {
            const nextMinute = DateTime.now().startOf("minute").plus({ minutes: 1 }).toJSDate();
            return timer(nextMinute, 1000 * 60).pipe(
                startWith(currentRemainingTime),
                map(() => SlaExtensionHelpers.calculateSlaRemainingTime(step?.extensions)),
            );
        } else {
            return of<number | null>(currentRemainingTime);
        }
    }

    private getSlaColour(slaTime: number | null, workTime: number | null): DurationBadgeColour | null {
        if (!slaTime) {
            return DurationBadgeColour.Red;
        }

        if (!workTime) {
            return slaTime <= 0 ? DurationBadgeColour.Red : DurationBadgeColour.Green;
        }

        const atRiskSlaTime = workTime * 2;
        if (slaTime >= atRiskSlaTime) {
            return DurationBadgeColour.Green;
        } else if (slaTime >= 0) {
            return DurationBadgeColour.Yellow;
        } else {
            return DurationBadgeColour.Red;
        }
    }

    private getWorkTime(step: IStep): Observable<number | null> {
        const hasWorkTimeViewPermission$ = this.authService.getGlobalRole().pipe(
            filter((role) => !!role),
            switchMap((role) => this.permissionService.checkPermissions(role, "ViewWorkTime")),
        );
        const hasWorkTimeFeatureEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper,
        );

        const canViewWorkTime$ = combineLatest([hasWorkTimeViewPermission$, hasWorkTimeFeatureEnabled$]).pipe(
            map(
                ([hasWorkTimeViewPermission, hasWorkTimeFeatureEnabled]) =>
                    hasWorkTimeViewPermission && hasWorkTimeFeatureEnabled,
            ),
        );

        return canViewWorkTime$.pipe(
            map((canViewWorkTime) => {
                if (canViewWorkTime) {
                    return WorkTimeExtensionHelpers.getMultipliedWorkTime(step?.extensions);
                } else {
                    return null;
                }
            }),
        );
    }
}
