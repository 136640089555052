import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InlineEditComponent } from "./inline-edit.component";

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [InlineEditComponent],
    exports: [InlineEditComponent],
})
export class InlineEditModule {}
