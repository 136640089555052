<ng-container *ngIf="tenants?.length; else emptyTenants">
    <select
        [formControl]="tenantControl"
        class="fx-form-select"
    >
        <option
            value=""
            [disabled]="true"
            [selected]="true"
            [hidden]="true"
        >
            Select business
        </option>
        <option
            *ngFor="let tenant of tenants"
            [value]="tenant.id"
        >
            {{ tenant.name | titlecase }}
        </option>
    </select>

    <button
        (click)="onSelectTenant(tenants, tenantControl.value)"
        type="button"
        class="fx-btn fx-btn--primary integrations-account-connect-button"
        [disabled]="!enabled"
    >
        Save
    </button>
</ng-container>
<ng-template #emptyTenants>
    <div class="integrations-account-empty-tenants">
        <ghost-loader
            *ngIf="loading"
            [showAnimation]="true"
            [width]="100"
            shape="square"
            class="ghost-loader"
        >
        </ghost-loader>

        <div
            *ngIf="!loading"
            class="integrations-account-empty-tenants-message"
        >
            <div>There are no available tenants for this account.</div>

            <button
                (click)="this.selectTenant.emit(null)"
                [disabled]="!enabled"
                type="button"
                class="integrations-account-disconnect-button"
            >
                Disconnect
            </button>
        </div>
    </div>
</ng-template>
