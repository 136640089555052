<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<ng-container *ngIf="{ requests: requests$ | async, role: role$ | async, userId: userId$ | async }; let context">
    <div class="requests">
        <div
            class="requests-empty"
            *ngIf="context.requests?.length === 0"
        >
            <empty-state
                title="No requests found"
                htmlText="There have been no requests added to this workflow"
                [imageType]="emptyStateImages.NoRequests"
            ></empty-state>
        </div>

        <div *ngIf="context?.requests?.length">
            <fx-table
                [tableData]="tableData"
                [selectable]="false"
                [trackBy]="trackRequest"
            >
                <ng-container
                    *fxColumn="'title'; label: tableHeaders.Title; sortable: true; mobileCollapse: true; let request"
                >
                    <div
                        class="requests-title"
                        (click)="openRequestModal(request)"
                    >
                        {{ request.title }}
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'card.createdBy';
                        label: tableHeaders.CreatedBy;
                        sortable: true;
                        mobileCollapse: true;
                        let request
                    "
                >
                    <div
                        class="requests-user"
                        (click)="openRequestModal(request)"
                    >
                        <span class="mobile-cell-label">{{ tableHeaders.CreatedBy }}: </span>
                        <fx-avatar
                            class="requests-user-avatar"
                            [name]="request.card.createdBy | userToName | async"
                            [image]="request.card.createdBy | profilePicture | async"
                            size="small"
                        >
                        </fx-avatar>
                        <div>
                            {{ request.card.createdBy | userToName | async }}
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="
                        'progress';
                        label: tableHeaders.Progress;
                        sortable: true;
                        mobileCollapse: true;
                        let request
                    "
                >
                    <div
                        class="requests-progress"
                        (click)="openRequestModal(request)"
                    >
                        <span class="mobile-cell-label">{{ tableHeaders.Progress }}: </span>
                        <progress-bar [progress]="request.progress"></progress-bar>
                    </div>
                </ng-container>
                <ng-container
                    *fxColumn="'status'; label: tableHeaders.Status; sortable: true; mobileCollapse: true; let request"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.Status }}: </span>
                    <fx-badge
                        *ngIf="
                            request.card.status === cardStatuses.Disabled ||
                                request.card.status === cardStatuses.Removed;
                            else openRequest
                        "
                        [label]="requestStatuses.Cancelled"
                        [type]="requestProgress.Error"
                    >
                    </fx-badge>

                    <ng-template #openRequest>
                        <fx-badge
                            (click)="openRequestModal(request)"
                            [label]="
                                request.state?.isCompleted
                                    ? request.card.type === requestTypes.VaultRequest
                                        ? requestStatuses.Completed
                                        : requestStatuses.Approved
                                    : requestStatuses.Pending
                            "
                            [type]="request.state?.isCompleted ? requestProgress.Success : requestProgress.Default"
                        >
                        </fx-badge>
                    </ng-template>
                </ng-container>
            </fx-table>
        </div>
    </div>
</ng-container>
