import { Component } from "@angular/core";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "dashboard-header",
    templateUrl: "./dashboard-header.component.html",
    styleUrls: ["./dashboard-header.component.scss"],
})
export class DashboardHeaderComponent {
    readonly badgeColors = VBadgeColors;
    readonly gaEvents = GA_EVENTS;
}
