import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReadMoreComponent } from "./read-more.component";

const exportableDeclarations = [ReadMoreComponent];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [CommonModule],
})
export class ReadMoreModule {}
