import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { AppUser } from "../../findex-auth/model/AppUser";
import { IAnalyticsService } from "./IAnalyticsService";

export enum GA_EVENTS_PREFIX {
    APP = "app",
    CALENDAR = "calendar",
    DEFAULT_UNSAVE_ANALYTICS_PREFIX = "unsaved",
    DOCUMENTS = "documents",
    DOCUMENT_CARD = "document_card",
    ONBOARDING = "onboarding",
    ONBOARDING_TILE = "onboarding_tile",
    PAYRUN = "payrun",
    INSTRUCTIONS = "instructions",
    BANKFILE = "bankfile",
    PAYRUN_CONFIRM_REFRESH_MODAL = "payrun_confirm_refresh_modal",
    BULK_REPLACE_CONFIRM_MODAL = "bulk_replace_confirm_modal",
    REQUEST_MODAL = "request_modal",
    RFI = "rfi",
    RFI_CARD = "rfi_card",
    RFI_CREATE = "rfi_create",
    RFI_EDIT_REQUEST = "rfi-edit-request",
    THREADS = "threads",
}

export enum GA_EVENTS {
    ACCOUNTS_METADATA_UPDATE = "accounts_update_metadata",
    ACCOUNTS_SEARCHSUBMIT = "accounts_searchsubmit",
    ACCOUNTS_TABDETAILS = "accounts_tabdetails",
    ACCOUNTS_TABTIMELINELIST = "accounts_tabtimelinelist",
    ACCOUNTS_TAB_CONFIGURE_WORKFLOW = "accounts_tab_configure_workflow",
    ACCOUNTS_TIMELINE = "accounts_timeline",
    ACCOUNTS_CREATE_ACCOUNT = "accounts_create_account",

    PROFILE_TAB_DETAILS = "profile_tabdetails",
    PROFILE_TAB_NOTIFICATIONS = "profile_tabnotifications",

    APP_ADDPARTICIPANTS = "app_addparticipants",
    APP_ATTACHFILE = "app_attachfile",
    APP_CREATEMEETING = "app_createmeeting",

    APP_MEETING_EDIT_ATTENDEES = "app_edit_attendees_meeting",
    APP_MEETING_EDIT_OCCURENCE = "app_edit_occurence_meeting",
    APP_MEETING_EDIT_CANCEL = "app_cancelmeeting",

    APP_CREATE_MESSAGE_FILE_UPLOAD = "app_createmessage_fileupload",
    APP_CREATE_PAYMENT_CARD = "app_create_payment",
    APP_CREATE_TRANSACTIONS_CARD = "app_create_transactions",
    APP_CREATE_BILL_APPROVAL_CARD = "app_create_bill_approval",
    APP_CREATE_REQUEST = "app_createrequest",
    APP_CREATE_REQUEST_MEETING = "app_create_schedulemeeting",
    APP_CREATE_SCHEDULE_MEETING = "app_create_bookmeeting",
    APP_CREATE_VIDEO_CHAT = "app_create_videochat",
    APP_CREATE_VIDEO_CALL = "app_create_videocall",
    APP_DELETENOTIFICATION = "app_deletenotification",
    APP_EDIT_MESSAGE_CLOSE = "app_edit_message_close",
    APP_ENDVCSESSION = "app_endvcsession",
    APP_EXTENSION_CHECKBOX = "checkbox-select",
    APP_EXTENSION_MENU_CANCEL = "menu-cancel-button",
    APP_EXTENSION_MENU_CONTINUE = "menu-continue-button",
    APP_INVITEPARTICIPANTS = "app_inviteparticipants",
    APP_JOINVC = "app_joinvc",
    APP_MARKASREADNOTIFICATION = "app_markasreadnotification",
    APP_MARKASUNREADNOTIFICATION = "app_markasunreadnotification",
    APP_PROVIDEDOCUMENTS = "app_providedocuments",
    APP_RECENTNOTIFICATIONS = "app_recentnotifications",
    APP_REMOVEPARTICIPANT = "app_removeparticipant",
    APP_REQUESTSIGNATURE = "app_requestsignature",
    APP_SCREENSHAREVC = "app_screensharevc",
    APP_SEARCHTIMELINES = "app_searchtimelines",
    APP_SEENUNSEEN = "app_seenunseen",
    APP_SENDMESSAGE = "app_sendmessage",
    APP_SIGNDOCUMENT = "app_signdocument",
    APP_VIEWAVAILABILITY = "app_viewavialability",
    APP_VIEWINSIGHTS = "app_viewinsights",
    APP_WORKFLOWPROGRESSBAR = "app_workflowprogressbar",
    APP_WORKFLOW_TAB = "app_workflow_tab",
    APP_WORKFLOW_TAB_ACTIVITY = "app_workflow_tab_activity",
    APP_NEW_REQUEST = "app_new_request",
    APP_GLOBAL_SEARCH = "app_global_search",
    APP_GLOBAL_SEARCH_ASK_AI_ASSISTANT = "app_global_search_ask_ai_assistant",
    APP_NOTIFICATION_PANEL = "app_notification_panel",

    BILLINGHISTORY_VIEWINVOICE = "billinghistory_viewinvoice",

    CALENDAR_CREATEINVITATION = "calendar_createinvitation",
    CALENDAR_CREATESCHEDULED = "calendar_createscheduled",
    CALENDAR_OPENSCHEDULING = "calendar_openscheduling",
    CALENDAR_RESCHEDULE = "calendar_reschedulemeeting",
    CALENDAR_VIEWDETAILS = "calendar_viewdetails",

    DASHBOARD_FILTER_ACCOUNT_NAME = "dashboard_filter_account_name",
    DASHBOARD_FILTER_LAST_UPDATED = "dashboard_filter_last_updated",
    DASHBOARD_FILTER_STATUS = "dashboard_filter_status",
    DASHBOARD_FILTER_TITLE = "dashboard_filter_title",
    DASHBOARD_TASK_ITEM = "dashboard_task_item",
    DASHBOARD_TIMELINE = "dashboard_timeline_title",
    DASHBOARD_UNREAD_MESSAGES = "dashboard_unread_messages",
    DASHBOARD_VIEW_MEETING_DETAILS = "dashboard_view_meeting_details",
    DASHBOARD_ACCOUNT_NAME = "dashboard_account_name",
    DASHBOARD_TABS_ASSIGNED_TO_YOU = "dashboard_tabs_assigned_to_you",
    DASHBOARD_TABS_ASSIGNED_TO_OTHERS = "dashboard_tabs_assigned_to_others",
    DASHBOARD_VIEW_ALL_WORKFLOWS = "dashboard_view_all_workflows",
    DASHBOARD_VIEW_ALL_UPCOMING_MEETINGS = "dashboard_view_all_upcoming_meetings",

    WORKFLOW_LISTING_FILTER_SERVICE = "workflow_listing_filter_service",
    WORKFLOW_LISTING_FILTER_ACCOUNT_NAME = "workflow_listing_account_name",
    WORKFLOW_LISTING_FILTER_TITLE = "workflow_listing_filter_title",
    WORKFLOW_LISTING_FILTER_LAST_UPDATED = "workflow_listing_filter_last_updated",
    WORKFLOW_LISTING_FILTER_SLA_REMAINING = "workflow_listing_filter_sla_remaining",
    WORKFLOW_LISTING_FILTER_DUE_DATE = "workflow_listing_filter_due_date",
    WORKFLOW_LISTING_FILTER_ASSIGNEES = "workflow_listing_filter_assignees",
    WORKFLOW_LISTING_FILTER_UNREAD_MESSAGES = "workflow_listing_filter_unread_messages",
    WORKFLOW_LISTING_FILTER_STATUS = "workflow_listing_filter_status",
    WORKFLOW_LISTING_TIMELINE = "workflow_listing_timeline",
    WORKFLOW_LISTING_UNREAD_MESSAGES = "workflow_listing_unread_messages",
    WORKFLOW_LISTING_SEARCH = "workflow_listing_search",

    DOCUMENTS_DELETE = "documents_delete",
    DOCUMENTS_DOWNLOAD = "documents_download",
    DOCUMENTS_RENAME = "documents_rename",

    INSIGHTS_ADD_ENTERPISE_SEARCH = "insights_add_enterprise_search",
    INSIGHTS_DOWNLOAD = "insights_download",
    INSIGHTS_REMOVE_DIALOG = "insights_remove_dialog",
    INSIGHTS_REPORTBUTTONDOWNLOAD = "insights_reportbuttondownload",

    LOGIN = "_login",

    PAYMENTCARD_ADDPAYMENTMETHOD = "paymentcard_addpaymentmethod",
    PAYMENTCARD_CANCEL = "paymentcard_cancel",
    PAYMENTCARD_EDIT = "paymentcard_edit",
    PAYMENTCARD_PAID = "paymentcard_paid",
    PAYMENTCARD_PAYMENTMETHODREADY = "paymentcard_paymentmethodready",
    PAYMENTCARD_PAYNOW = "paymentcard_paynow",
    PAYMENTCARD_PROCESSING = "paymentcard_processing",
    PAYMENTCARD_VIEW = "paymentcard_view",

    PAYROLL_SAVE_SETTINGS = "save_payroll_settings",

    PAYRUN_CARD_CLOSE_MODAL = "payrun_card_close_modal",
    PAYRUN_CARD_EXPAND_LINE = "payrun_card_expand_line",
    PAYRUN_CARD_EXPAND_ALL = "payrun_card_expand_all",
    PAYRUN_CONFIRM_COMPLETE = "payrun_confirm_complete",
    PAYRUN_CREATE_REQUEST = "payrun_create_request",
    PAYRUN_EDIT_REQUEST = "payrun_edit_request",
    PAYRUN_LINES_APPROVE_ALL = "payrun_lines_approve_all",
    PAYRUN_REFRESH = "payrun_refresh",
    PAYRUN_REOPEN_REQUEST_MODAL = "payrun_reopen_request_modal",
    PAYRUN_REPORT_APPROVE = "payrun_report_approve",
    PAYRUN_REPORT_SELECTED = "payrun_report_selected",
    PAYRUN_REVIEW_PENDING = "payrun_review_pending",
    PAYRUN_SEND_FOR_REVIEW = "payrun_send_for_review",
    PAYRUN_VIEW_COMPLETED = "payrun_view_completed",
    PAYRUN_VIEW_SUMMARY = "payrun_view_summary",
    PAYRUN_VIEW_BREAKDOWN = "payrun_view_breakdown",

    INSTRUCTIONS_LINES_APPROVE_ALL = "instructions_lines_approve_all",
    INSTRUCTIONS_COMPLETE = "instructions_complete",
    INSTRUCTIONS_CONFIRM_COMPLETE = "instructions_confirm_complete",
    INSTRUCTIONS_CLOSE = "instructions_close",

    BANKFILE_SETTINGS_SELECTED = "bankfile_settings_selected",

    RFI_CARD_CANCEL = "rfi_card_cancel",
    RFI_CARD_EDIT = "rfi_card_edit",
    RFI_CARD_REMOVE = "rfi_card_remove",
    RFI_CARD_VIEW = "rfi_card_view",
    RFI_CREATE_DELETE = "rfi_create_delete",
    RFI_CREATE_MARK_AS_REPORT = "rfi_create_mark_as_report",
    RFI_CREATE_RENAME = "rfi_create_rename",
    RFI_CHILD_COLLAPSE = "rfi_child_collapse",
    RFI_CLOSE = "rfi_close",
    RFI_COLLAPSE = "rfi_collapse",
    RFI_COMPLETE = "rfi_complete",
    RFI_CONFIRM_COMPLETE = "rfi_confirm_complete",
    RFI_CREATE_REQUEST_BLANK = "rfi_create_request_blank",
    RFI_CREATE_REQUEST_BANK_FILE = "rfi_create_request_bank_file",
    RFI_CREATE_REQUEST_TEMPLATE = "rfi_create_request_template",
    RFI_EDIT_REQUEST = "rfi_edit_request",
    RFI_LINES_APPROVE_ALL = "rfi_lines_approve_all",
    RFI_REOPEN_REQUEST_MODAL = "rfi_reopen_request_modal",
    RFI_SEND_FOR_REVIEW = "rfi_send_for_review",

    TRANSFORMER_VIEW = "transformer_card_view",
    COPILOT_TRANSACTIONS_VIEW = "transactions_card_view",
    COPILOT_TRANSACTIONS_EDIT = "transactions_card_edit",
    SHOW_SUCCESS_MANAGER = "_show_success_manager",

    BILL_APPROVAL_VIEW = "billapproval_card_view",
    BILL_APPROVAL_EDIT = "billapproval_card_edit",
    BILL_APPROVAL_EDIT_REFRESH = "billapproval_refresh_modal",

    VAULT_LIST_CONFIRM_DELETE = "vault_list_confirm_delete",
    VAULT_LIST_CONTEXT_DELETE = "vault_list_context_delete",
    VAULT_LIST_CONTEXT_DOWNLOAD = "vault_list_context_download",
    VAULT_LIST_CONTEXT_RENAME = "vault_list_context_rename",
    VAULT_LIST_CONTEXT_VIEW_IN_TIMELINE = "vault_list_context_view_in_timeline",
    VAULT_LIST_FILTER_ACCOUNTS = "vault_list_accounts",
    VAULT_LIST_FILTER_CREATED_BY = "vault_list_filter_created_by",
    VAULT_LIST_FILTER_DATE_RANGE = "vault_list_daterange",
    VAULT_LIST_FILTER_DISPLAY_NAME = "vault_list_filter_display_name",
    VAULT_LIST_FILTER_SERVICE_TYPE = "vault_list_filter_service_type",
    VAULT_LIST_FILTER_THREAD_TITLE = "vault_list_filter_thread_title",
    VAULT_LIST_FILTER_TIMESTAMP = "vault_list_filter_timestamp",
    VAULT_LIST_SEARCHSUBMIT = "vault_list_searchsubmit",
    VAULT_LIST_SIGN_DOCUMENT = "vault_list_sign_document",

    NOTIFICATION_SETTINGS_SAVE = "notification_settings_save",
    NOTIFICATION_SETTINGS_SELECT = "notification_settings_select",

    WORKFLOW_CREATE_CONFIGURATION = "workflow_create_configuration",
    WORKFLOW_CREATE_CONFIGURATION_NEXT = "workflow_create_configuration_next",
    WORKFLOW_CREATE_CONFIGURATION_CLOSE = "workflow_create_configuration_close",
    WORKFLOW_SAVE_CONFIGURATION = "workflow_save_configuration",
    WORKFLOW_DELETE_CONFIGURATION = "workflow_delete_configuration",
    WORKFLOW_CONFIGURATION_VIEW_BACK = "workflow_configuration_view_back",
    WORKFLOW_LIST_VIEW_CONFIGURATION = "workflow_list_view_configuration",

    FORWARD_CARD_MESSAGE_SEND = "forward_card_message_send",
    FORWARD_CARD_MESSAGE_CANCEL = "forward_card_message_cancel",

    CUSTOMER_REQUEST_CREATE_SEND = "customer_request_create_send",
    CUSTOMER_REQUEST_CREATE_CANCEL = "customer_request_create_cancel",

    CUSTOMER_REQUEST_SUCCESS_VIEW = "customer_request_success_view",
    CUSTOMER_REQUEST_SUCCESS_CREATE = "customer_request_success_create",
}

declare let gtag: (...arg: any[]) => any;

declare global {
    interface Window {
        dataLayer?: any;
    }
}

@Injectable()
export class GtagAnalyticsService implements IAnalyticsService {
    configId: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        const { trackingId } = environment.analytics.google;

        this.configId = trackingId;
    }

    setUserId(user: AppUser) {
        if (!user) {
            return;
        }

        const userId = user.id;

        if (this.configId && window.dataLayer) {
            gtag("set", { user_id: userId });
        }
    }

    init(user: AppUser) {
        this.setUserId(user);
    }

    pageViewed(path: string) {
        if (this.configId) {
            gtag("config", this.configId, {
                page_path: path,
            });
        }
    }

    sendEvent(category: string, action: string, label?: string, value?: number) {
        if (this.configId && window.dataLayer) {
            gtag("event", action, {
                event_category: category,
                event_label: label,
                event_value: value,
            });
        }
    }
}
