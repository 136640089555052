import { Component, EventEmitter, Output, Input, OnInit } from "@angular/core";
import { IVaultReportGroup } from "../../../interfaces/IInsightsReportGroup";
import { IVaultReport } from "@visoryplatform/threads";

@Component({
    selector: "insights-report-list",
    templateUrl: "./insights-report-list.component.html",
    styleUrls: ["./insights-report-list.component.scss"],
})
export class InsightsReportListComponent implements OnInit {
    @Input() selectedReport: IVaultReport;
    @Input() reportGroups: IVaultReportGroup[];
    @Output() reportSelection = new EventEmitter<IVaultReport>();
    @Output() downloadReport = new EventEmitter<IVaultReport>();

    public selectedReportGroupIndex: number;

    constructor() {}

    ngOnInit(): void {
        this.setSelectedGroupIndex();
    }

    trackReport(_index: number, report: IVaultReport): string | IVaultReport {
        if (!report) {
            return report;
        }
        return `${report.vaultId}/${report.file?.id}/${report.file?.fileName}`;
    }

    private setSelectedGroupIndex(): void {
        if (this.selectedReport && this.reportGroups?.length) {
            const selectedGroup = this.reportGroups.find((group) =>
                group.reports.find((report) => report.vaultId === this.selectedReport.vaultId),
            );
            const index = this.reportGroups.indexOf(selectedGroup);
            this.selectedReportGroupIndex = index > -1 ? index : 0;
        } else {
            this.selectedReportGroupIndex = 0;
        }
    }
}
