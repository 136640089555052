<div
    *ngIf="visible"
    class="query"
>
    <div *ngIf="editMode === true && !!enableCustomQuery">
        <textarea
            #textComponent
            class="fx-form-input"
            [formControl]="control"
            [placeholder]="placeholder"
        ></textarea>
    </div>

    <div
        *ngIf="(editMode === true && !enableCustomQuery) || !editMode"
        class="query-readonly"
    >
        <quill-view
            [content]="control.value"
            aria-label="Query"
        ></quill-view>
        &nbsp;
        <i
            *ngIf="editMode === true && !enableCustomQuery"
            (click)="onEditClick()"
            class="las la-edit"
        ></i>
    </div>
</div>
