import {
    IParticipant,
    IWorkflowConfiguration,
    IWorkflowDesignType,
    IWorkflowVariation,
    WorkflowConfigTokenService,
    WorkflowCreationOptions,
    WorkflowTokenResolver,
} from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";

import { IParticipantUi } from "../interfaces/IParticipantUi";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { Injectable } from "@angular/core";
import { ParticipantService } from "./participant.service";
import { SelectWorkflowTokenControl } from "../modules/create-thread/types/SelectDesignType";
import { ServiceControl } from "../modules/create-thread/components/select-workflow-form/select-workflow-form.component";
import { WorkflowConfigurationOptions } from "../modules/create-thread/types/UniqueThreadType";
import { WorkflowVariationsService } from "../../workflow-variations/services/workflow-variations.service";

export type WorkflowDesignOrConfig = IWorkflowDesign | IWorkflowConfiguration;

export type ComparatorFunction<T extends WorkflowDesignOrConfig> = (
    item: T,
    designType: IWorkflowDesignType,
) => boolean;

@Injectable({
    providedIn: "root",
})
export class CreateWorkflowModalService {
    constructor(
        private workflowVariationsService: WorkflowVariationsService,
        private participantService: ParticipantService,
    ) {}

    getDesignTypes<T extends WorkflowDesignOrConfig>(
        workflowDesignTypes$: Observable<IWorkflowDesignType[]>,
        items$: Observable<T[]>,
        comparatorFunction: ComparatorFunction<T>,
    ): Observable<IWorkflowDesignType[]> {
        return combineLatest([workflowDesignTypes$, items$]).pipe(
            take(1),
            map(this.filterDesignTypes(comparatorFunction)),
        );
    }

    getFilteredDesigns(
        serviceValue: ServiceControl,
        currentDesignTypes$: Observable<IWorkflowDesignType[]>,
        workflowDesigns$: Observable<IWorkflowDesign[]>,
        comparatorFunction: ComparatorFunction<IWorkflowDesign>,
    ): Observable<IWorkflowDesign[]> {
        return this.getDesigns(serviceValue, currentDesignTypes$, workflowDesigns$, comparatorFunction);
    }

    getFilteredConfigurations(
        serviceValue: ServiceControl,
        currentDesignTypes$: Observable<IWorkflowDesignType[]>,
        workflowConfigurations$: Observable<IWorkflowConfiguration[]>,
        comparatorFunction: ComparatorFunction<IWorkflowConfiguration>,
    ): Observable<IWorkflowConfiguration[]> {
        return this.getDesigns(serviceValue, currentDesignTypes$, workflowConfigurations$, comparatorFunction);
    }

    getWorkflowDesignTokens(
        workflowConfig: IWorkflowConfiguration,
        selectedWorkflowTokens?: SelectWorkflowTokenControl,
    ): IWorkflowVariation[] {
        const configTokenAssignees = WorkflowConfigTokenService.getConfigRoleTokensAssignees(workflowConfig);
        if (configTokenAssignees.length || workflowConfig?.workflowTokens) {
            return workflowConfig?.workflowTokens;
        }

        const selectedTokens = this.workflowVariationsService.getWorkflowTokens(
            selectedWorkflowTokens?.workflowTokenIds,
        );
        return selectedTokens;
    }

    getResolvedWorkflowDesign(
        selectedWorkflowDesign: Observable<IWorkflowDesign>,
        selectedWorkflowConfiguration: Observable<IWorkflowConfiguration>,
    ): Observable<IWorkflowDesign> {
        const workflowDesign$ = combineLatest([selectedWorkflowDesign, selectedWorkflowConfiguration]);
        const resolvedDesign$ = workflowDesign$.pipe(
            map(([design, workflowConfig]) => {
                const workflowVariations = this.getWorkflowDesignTokens(workflowConfig);
                const { resolvedDesign } = WorkflowTokenResolver.resolveWorkflowTokens(workflowVariations, design);
                return resolvedDesign;
            }),
        );

        return resolvedDesign$;
    }

    getDefaultPreviewTitle(accountLabel?: string): string {
        const defaultTitle = "New workflow";

        if (!accountLabel) {
            return defaultTitle;
        }

        return `${accountLabel} - ${defaultTitle}`;
    }

    getParticipantsFromWorkflowConfig(
        workflowConfig: IWorkflowConfiguration,
        clonedParticipants: IParticipant[],
    ): Observable<IParticipantUi[]> {
        const steps = workflowConfig.steps;
        const observers = workflowConfig.observers;
        const workflowAssignees = this.participantService.getWorkflowConfigurationAssignees(steps);
        const workflowObservers = this.participantService.getWorkflowConfigurationObservers(observers);
        const tokenAssignees = WorkflowConfigTokenService.getConfigRoleTokensAssignees(workflowConfig);

        return this.participantService.getClonedParticipants(
            clonedParticipants,
            workflowAssignees,
            workflowObservers,
            tokenAssignees,
        );
    }

    private getDesigns<T extends WorkflowDesignOrConfig>(
        serviceValue: ServiceControl,
        currentDesignTypes$: Observable<IWorkflowDesignType[]>,
        items$: Observable<T[]>,
        comparatorFunction: ComparatorFunction<T>,
    ): Observable<T[]> {
        const creationOption = this.getCreationOption(serviceValue.configurationOption);

        return combineLatest([currentDesignTypes$, items$]).pipe(
            take(1),
            map(([designTypes, items]) => {
                const filteredDesignTypes = this.filterDesignTypesByCreationOption(
                    designTypes,
                    serviceValue.threadType,
                    creationOption,
                );
                return items.filter((item) =>
                    filteredDesignTypes.some((designType) => comparatorFunction(item, designType)),
                );
            }),
        );
    }

    private filterDesignTypes(
        comparatorFunction: ComparatorFunction<WorkflowDesignOrConfig>,
    ): (value: [IWorkflowDesignType[], WorkflowDesignOrConfig[]], index: number) => IWorkflowDesignType[] {
        return ([designTypes, items]) =>
            designTypes.filter((designType) => items.some((item) => comparatorFunction(item, designType)));
    }

    private filterDesignTypesByCreationOption(
        designTypes: IWorkflowDesignType[],
        threadType: string,
        creationOption: WorkflowCreationOptions,
    ): IWorkflowDesignType[] {
        return designTypes.filter(
            (designType) =>
                designType.threadType === threadType && !designType.disabledCreationOptions?.includes(creationOption),
        );
    }

    private getCreationOption(configurationOption: WorkflowConfigurationOptions): WorkflowCreationOptions {
        switch (configurationOption) {
            case WorkflowConfigurationOptions.Blank:
                return WorkflowCreationOptions.AdhocFromBlank;
            case WorkflowConfigurationOptions.Configured:
                return WorkflowCreationOptions.AdhocFromConfig;
            default:
                return WorkflowCreationOptions.WorkflowConfig;
        }
    }
}
