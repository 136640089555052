import { Component, OnInit } from "@angular/core";
import { CalendarEvent, DAYS_OF_WEEK } from "angular-calendar";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { CalendarEventsService } from "../../services/calendar-events.service";
import { getWeekOfMonth } from "date-fns";
import { SubscriberBaseComponent } from "../../../shared/components/subscriber-base.component";
import { takeUntil } from "rxjs/operators";
import { IUpcomingMeeting } from "@visoryplatform/threads";
import { CalendarMeetingsService } from "../../services/calendar-meetings.service";

@Component({
    selector: "lib-calendar-page",
    templateUrl: "./calendar-page.component.html",
    styleUrls: ["./calendar-page.component.scss"],
})
export class CalendarPageComponent extends SubscriberBaseComponent implements OnInit {
    public events$: Observable<CalendarEvent[]>;
    public excludeDays: number[] = [0, 6];
    public loader = this.eventsService.loader;
    public refresh = new Subject<void>();
    public selectedAccounts: string[] = [];
    public selectedServices: string[] = [];
    public shouldShowAllOnWeek = new Map<string, boolean>();
    public viewDate: Date = new Date();
    public weekStartsOn = DAYS_OF_WEEK.SUNDAY;

    public readonly rowCellLimit = 3;

    constructor(
        private router: Router,
        private eventsService: CalendarEventsService,
        private calendarMeetingService: CalendarMeetingsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setViewDate();
        this.subscribeToCalendarEvents();
    }

    public subscribeToCalendarEvents(): void {
        this.events$ = this.eventsService.calendarEvents$;
    }

    public dayClicked(): void {
        if (window.innerWidth > 768) {
            return;
        }
        this.router.navigate(["/calendar-agenda"]);
    }

    public onViewMeetingDetails(calendarEvent: IUpcomingMeeting): void {
        if (window.innerWidth < 768) {
            return;
        }
        this.calendarMeetingService.onViewMeetingDetails(calendarEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }

    public onCalendarMilestoneClicked(event: CalendarEvent): void {
        if (window.innerWidth < 768) {
            return;
        }
        this.eventsService.calendarEventClicked(event).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }

    public toggleShowAll(date: string): void {
        const week = getWeekOfMonth(new Date(date)).toString();
        const shouldShowAll: boolean = this.shouldShowAllOnWeek.get(week);

        if (shouldShowAll !== undefined) {
            this.shouldShowAllOnWeek.set(week, !shouldShowAll);
        } else {
            this.shouldShowAllOnWeek.set(week, true);
        }
    }

    private setViewDate() {
        this.eventsService.calendarViewDate$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((viewDate: Date) => (this.viewDate = viewDate));
    }
}
