<dashboard-header></dashboard-header>

<ng-container *ngIf="threadsJoinTasks$ | async as threadsJoinTasks">
    <div *ngIf="threadsJoinTasks?.length">
        <div
            class="subheader"
            *ngIf="assignedTo === AssignedTo.You"
        >
            <i class="las la-pen subheader-icon"></i>
            <span>Please action these tasks to progress your workflows</span>
        </div>
        <div
            class="subheader"
            *ngIf="assignedTo === AssignedTo.Others"
        >
            <i class="las la-user-friends subheader-icon"></i>
            <span>Your team are currently completing these workflow steps</span>
        </div>
    </div>
</ng-container>

<div class="dashboard-tabs">
    <v-tabs [activeTab]="assignedTo">
        <v-tab
            [id]="AssignedTo.You"
            [analyticsClick]="gaEvents.DASHBOARD_TABS_ASSIGNED_TO_YOU"
            (activated)="setAssignedTo(AssignedTo.You)"
        >
            <p>Assigned to you</p>
            <v-badge
                class="assigned-to-badge"
                [color]="assignedTo === AssignedTo.You ? badgeColors.pink : badgeColors.lightPink"
                [value]="(assignedToYouTotal$ | async) || '...'"
                *ngIf="!(canViewDashboardTasks$ | async)"
            >
            </v-badge>
        </v-tab>

        <v-tab
            [id]="AssignedTo.Others"
            [analyticsClick]="gaEvents.DASHBOARD_TABS_ASSIGNED_TO_OTHERS"
            (activated)="setAssignedTo(AssignedTo.Others)"
        >
            <p>Assigned to others</p>
            <v-badge
                class="assigned-to-badge"
                [color]="assignedTo === AssignedTo.Others ? badgeColors.pink : badgeColors.lightPink"
                [value]="(assignedToOthersTotal$ | async) || '...'"
            >
            </v-badge>
        </v-tab>
    </v-tabs>
</div>

<loader-icon
    *ngIf="loader.counter"
    [static]="true"
    color="light"
></loader-icon>

<ng-container *ngIf="threadsJoinTasks$ | async as threadsJoinTasks">
    <div *ngIf="!loader.counter">
        <dashboard-timelines-table
            [threads]="threadsJoinTasks"
            [role]="role$ | async"
            [userId]="userId$ | async"
            [assignedTo]="assignedTo"
        ></dashboard-timelines-table>
        <div
            *ngIf="threadsJoinTasks?.length"
            class="fx-dashboard-threads-paginated-paginator"
        >
            <fx-paginator
                [enableBack]="canGoBack$ | async"
                [enableNext]="canGoNext$ | async"
                (goNext)="goNextPage()"
                (goBack)="goBackPage()"
            ></fx-paginator>
        </div>
    </div>
</ng-container>
