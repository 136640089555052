<centered-modal-layout
    (save)="confirm()"
    (close)="close()"
    [loading]="!!loader.counter"
    cancelButtonTitle="Cancel"
    saveButtonTitle="Remove"
    analyticsPrefix="remove-particpant"
>
    <div modal-header>Remove participant</div>
    <div modal-subhead>Are you sure you want to remove {{ participant?.profile?.name }}?</div>
</centered-modal-layout>
