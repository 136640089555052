import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of } from "rxjs";
import { IPaginatorSort } from "../interfaces/IPaginatorSort";
import { IPaginated } from "@visoryplatform/datastore-types";
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";

type RequestWrapper<T> = (page: string, limit: number, sort: IPaginatorSort) => Observable<IPaginated<T>>;

const DEFAULT_SORT: IPaginatorSort = { sort: null, order: null };

export class Paginator<T> {
    private readonly defaultCurrentPage = "";
    private readonly defaultPreviousPages: string[] = [];

    previousPages$: Observable<string[]>;
    currentPage$: Observable<string>;
    nextPage$: Observable<string>;
    sort$: Observable<IPaginatorSort>;

    canGoBack$: Observable<boolean>;
    canGoNext$: Observable<boolean>;

    private requestSource: Subject<RequestWrapper<T>>;

    private currentPageSource: BehaviorSubject<string>;
    private orderSource: BehaviorSubject<IPaginatorSort>;
    private previousPagesSource: BehaviorSubject<string[]>;

    constructor(private limit: number) {
        this.startPaginator();
    }

    goBack(): void {
        const pages = this.previousPagesSource.value;
        const previousPages = pages.slice(0, pages.length - 1);
        const currentPage = previousPages[previousPages.length - 1];
        this.previousPagesSource.next(previousPages);
        this.currentPageSource.next(currentPage === undefined ? this.defaultCurrentPage : currentPage);
    }

    goNext(): void {
        const nextPage$ = this.nextPage$.pipe(take(1));
        const previousPage$ = this.previousPages$.pipe(take(1));

        combineLatest([nextPage$, previousPage$]).subscribe(([nextPage, previousPages]) => {
            this.previousPagesSource.next([...previousPages, nextPage]);
            this.currentPageSource.next(nextPage);
        });
    }

    wrap(request?: RequestWrapper<T>): Observable<T[]> {
        const currentPage$ = this.currentPageSource.asObservable();
        const order$ = this.orderSource.asObservable();
        const request$ = this.requestSource.asObservable().pipe(startWith(request));

        const response$ = combineLatest([currentPage$, order$, request$]).pipe(
            debounceTime(0),
            filter(([, , request]) => !!request),
            switchMap(([page, sort, request]) => this.request(request, page, this.limit, sort)),
            map((paginated) => ({ ...paginated, next: paginated.next ?? null })),
            shareReplay(1),
        );

        this.setNextPage(response$);

        return response$.pipe(map((paginated) => paginated.result));
    }

    refresh(request: RequestWrapper<T>): void {
        this.currentPageSource.next(this.defaultCurrentPage);
        this.orderSource.next(DEFAULT_SORT);
        this.previousPagesSource.next(this.defaultPreviousPages);
        this.requestSource.next(request);
    }

    sort(paginatorSort: IPaginatorSort): void {
        const order = paginatorSort.order !== "" ? paginatorSort : DEFAULT_SORT;
        this.currentPageSource.next(this.defaultCurrentPage);
        this.previousPagesSource.next(this.defaultPreviousPages);
        this.orderSource.next(order);
    }

    private request(
        requestWrapper: RequestWrapper<T>,
        page: string,
        limit: number,
        sort: IPaginatorSort,
    ): Observable<IPaginated<T>> {
        if (this.isPageEmpty(page)) {
            return of({ result: [], next: null });
        }

        return requestWrapper(page, limit, sort);
    }

    private startPaginator(): void {
        this.currentPageSource = new BehaviorSubject(this.defaultCurrentPage);
        this.orderSource = new BehaviorSubject(DEFAULT_SORT);
        this.previousPagesSource = new BehaviorSubject<string[]>(this.defaultPreviousPages);
        this.requestSource = new ReplaySubject(1);

        this.previousPages$ = this.previousPagesSource.asObservable();
        this.currentPage$ = this.currentPageSource.asObservable();
        this.sort$ = this.orderSource.asObservable();

        this.canGoBack$ = this.previousPages$.pipe(map((pages) => pages.length > 0));
    }

    private setNextPage(response$: Observable<IPaginated<T>>): void {
        this.nextPage$ = response$.pipe(
            map((paginated) => paginated.next),
            shareReplay(1),
        );

        this.canGoNext$ = this.nextPage$.pipe(map((nextPage) => !this.isPageEmpty(nextPage)));
    }

    private isPageEmpty(page: string | null): boolean {
        return page == null;
    }
}
