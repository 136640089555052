import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { filter, map, take, takeUntil } from "rxjs/operators";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { Observable, Subscription } from "rxjs";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
@Component({
    selector: "verify-mobile-wizard",
    templateUrl: "./verify-mobile-wizard.component.html",
    styleUrls: ["./verify-mobile-wizard.component.scss"],
})
export class VerifyMobileWizardComponent implements OnInit, OnDestroy {
    readonly stepConfigurations = [
        {
            name: "Verify Mobile",
            stepIndex: 0,
            buttons: [],
        },
    ];

    mobileNumber$: Observable<string>;

    activeStepIndex = 0;

    windowResizeSub: Subscription;

    constructor(
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private windowListenersService: WindowListenersService,
        private menuService: MenuService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit() {
        this.windowResizeSub = this.windowListenersService.resize.subscribe(() => {
            this.menuService.hide(MenuType.Navigation);
            this.menuService.hide(MenuType.CollapsedMenuIcon);
        });

        this.mobileNumber$ = this.authService.getUser().pipe(
            filter((userObj) => !!userObj?.details?.mobileNumber),
            take(1),
            map((userObj) => userObj?.details?.mobileNumber || ""),
        );

        const user$ = this.authService.getUser().pipe(takeUntil(this.authService.onLoginSuccess()));
        user$.subscribe((user) => this.checkUser(user));
    }

    private checkUser(user?: AppUser): void {
        if (user) {
            if (user.mobileNumberVerified) {
                this.router.navigate(["/dashboard"]);
            }
        } else {
            this.recordAnalyticsEvent("login-required");
            this.router.navigate(["/login"], {
                queryParams: { redirect: "/register/verify-mobile" },
            });
        }
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("verify-mobile", category);
    }

    async redirectAfterVerify() {
        await this.router.navigateByUrl(this.environment.featureFlags.mobileVerifiedRedirect);
    }

    ngOnDestroy() {
        if (this.windowResizeSub) {
            this.windowResizeSub.unsubscribe();
        }
    }
}
