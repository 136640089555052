import { Pipe, PipeTransform, Injectable, Inject } from "@angular/core";
import { ParticipantCache } from "../services/participant-cache.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../feature-flags";
import { ActorId } from "@visoryplatform/threads";

@Injectable({ providedIn: "root" })
@Pipe({ name: "userToName" })
export class UserToNamePipe implements PipeTransform {
    readonly systemName = this.environment.appName;
    readonly aiName = this.environment.aiName;

    constructor(
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private featureFlagService: FeatureFlagService,
    ) {}

    transform(userId: string): Observable<string> {
        if (userId === ActorId.System) {
            return this.featureFlagService
                .getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding)
                .pipe(map((flag) => (flag ? this.aiName : this.systemName)));
        }

        return this.participantCache
            .getParticipant(userId)
            .pipe(map((participant) => this.participantCache.cleanName(participant)));
    }
}
