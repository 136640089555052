export enum ToastSeverity {
    Success = "success",
    Error = "error",
}

export enum ToastSummary {
    Success = "Success",
    Error = "Error",
    NewWorkflowCreated = "New workflow created",
}

export enum ToastMessage {
    MarkedAsReport = "Document marked as report",
    SignatureRequested = "Signature requested",
    ReportRefreshed = "Payrun report refreshed",
    CardDescriptionUpdated = "Card description updated",
    TimelineUpdated = "Workflow updated",
    CardDeleted = "Card deleted",
    SomethingWentWrong = "Sorry, something went wrong",
    FileRenamed = "File renamed",
    FileRemoved = "File removed",
    FileUploaded = "File uploaded",
}

export enum ToastKeys {
    fileUploadError = "fileUploadError",
}
