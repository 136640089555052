import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, defer, from, Observable, of } from "rxjs";
import { IAuthenticationStrategy } from "../../model/IAuthenticationStrategy";
import { AppUser, AuthStrategy } from "../../model/AppUser";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { LoginChallengeResult, LoginStep, LoginStepDetails } from "../../model/LoginStep";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { AuthorizationLevel } from "../../model/AuthorizationLevel";
import { StorageService } from "../../../shared/services/storage.service";
import { DateTime } from "luxon";
export interface IAuthInvitation {
    mobileNumber: string;
    name: string;
    userId: string;
}

@Injectable({ providedIn: "root" })
export class InvitationAuthStrategy implements IAuthenticationStrategy {
    private user$: BehaviorSubject<AppUser>;
    private invitationId: string;
    private login$ = new BehaviorSubject<LoginStepDetails>({ step: undefined });
    private challengeParams: { session: string; challengeName: string; username: string };

    private storageKey = {
        invitationId: this.environment.appId + "InvitationId",
        authResult: this.environment.appId + "InvitationAuthResult",
        user: this.environment.appId + "InvitationAuthUser",
        authExpiry: this.environment.appId + "InvitationAuthExpiry",
    };

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private window: Window,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        const invitationId = this.getActiveInvitationId();
        const user = this.storage.getItem(this.storageKey.user);

        const validUser = this.isUser(user);

        if (!invitationId || !validUser) {
            this.user$ = new BehaviorSubject<AppUser>(null);
            return;
        }

        this.invitationId = invitationId;
        this.user$ = new BehaviorSubject<AppUser>(user);
    }

    getActiveInvitationId(): string | null {
        const invitationId = this.storage.getItem(this.storageKey.invitationId);

        if (invitationId && typeof invitationId === "string") {
            return invitationId;
        } else {
            return null;
        }
    }

    getLogin(): Observable<LoginStepDetails> {
        return this.login$.asObservable();
    }

    startLogin(invitationId: string = null): Observable<LoginStepDetails> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.invitationLogin}`;
        const headers = {
            Authorization: "none",
            "findex-invitation": invitationId,
        };
        return this.http.post<any>(url, null, { headers }).pipe(
            tap((response) => {
                this.invitationId = invitationId;
                this.challengeParams = {
                    session: response.Session,
                    challengeName: response.ChallengeName,
                    username: response.ChallengeParameters.USERNAME,
                };
            }),
            map((response) => ({
                step: LoginStep.LOGIN_CHALLENGE,
                details: {
                    type: response?.ChallengeParameters?.type || "unknown",
                },
            })),
            catchError((response: unknown, caught) => {
                if (
                    response &&
                    typeof response === "object" &&
                    (response as any)?.error?.data?.status === "STATUS_THROTTLED"
                ) {
                    return of({
                        step: LoginStep.LOGIN_THROTTLED,
                        details: {},
                    });
                } else {
                    return caught;
                }
            }),
        );
    }

    fetchInvitation(invitationId: string): Observable<IAuthInvitation> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.invitation}`;
        const headers = {
            Authorization: "none",
            "findex-invitation": invitationId,
        };
        return this.http.get<IAuthInvitation>(url, { headers }).pipe(tap(() => (this.invitationId = invitationId)));
    }

    answerChallenge(code: string): Observable<LoginChallengeResult> {
        const url = `${this.environment.auth.base}${environmentCommon.auth.invitationVerifyCode}`;
        const headers = {
            Authorization: "none",
            "findex-invitation": this.invitationId,
        };

        const params = {
            challengeName: this.challengeParams.challengeName,
            session: this.challengeParams.session,
            username: this.challengeParams.username,
            code,
        };

        return this.http.post<any>(url, params, { headers }).pipe(
            map((response: any) => {
                if (response?.ChallengeParameters) {
                    this.challengeParams.session = response.Session;
                    this.challengeParams.challengeName = response.ChallengeName;
                    this.login$.next({
                        step: LoginStep.LOGIN_CHALLENGE,
                        details: {
                            type: response?.ChallengeParameters?.type || "unknown",
                        },
                    });
                    return LoginChallengeResult.FAILED_ATTEMPT;
                }

                const prefixedUserId = `${environmentCommon.auth.userIdPrefix}-${response.userId}`;

                if (response?.authenticationResult?.IdToken && response?.authenticationResult?.ExpiresIn) {
                    const { ExpiresIn: expiresIn } = response?.authenticationResult;

                    const user: AppUser = {
                        id: prefixedUserId,
                        name: response.name,
                        type: AuthStrategy.Invitation,
                        details: response.details,
                        authorizationLevel: AuthorizationLevel.NOMINAL,
                        emailAddressVerified: true,
                        mobileNumberVerified: true,
                        globalRole: null,
                    };

                    const expiryTimestamp = DateTime.now()
                        .plus({ seconds: Number(expiresIn) })
                        .toMillis()
                        .toString();

                    this.storage.setItem(this.storageKey.invitationId, this.invitationId);
                    this.storage.setItem(this.storageKey.authResult, JSON.stringify(response.authenticationResult));
                    this.storage.setItem(this.storageKey.user, JSON.stringify(user));
                    this.storage.setItem(this.storageKey.authExpiry, expiryTimestamp);

                    this.user$.next(user);
                    this.login$.next({ step: LoginStep.LOGIN_COMPLETE });
                    return LoginChallengeResult.SUCCESS;
                }

                return LoginChallengeResult.FAILED_ATTEMPT;
            }),
        );
    }

    logout(): Observable<any> {
        this.storage.removeItem(this.storageKey.invitationId);
        this.storage.removeItem(this.storageKey.authResult);
        this.storage.removeItem(this.storageKey.user);
        this.storage.removeItem(this.storageKey.authExpiry);
        this.user$.next(null);
        return of(null);
    }

    getUser(): Observable<AppUser> {
        return this.user$.asObservable();
    }

    async checkExpiry(): Promise<boolean> {
        const expiry = this.storage.getItem(this.storageKey.authExpiry);

        if (!expiry || typeof expiry !== "string") {
            return false;
        }

        const authExpiryMillis = new Date(expiry);
        if (DateTime.now().toMillis() >= authExpiryMillis.getTime()) {
            await this.logout().toPromise();
            // TODO: this is hacky. Unfortunately since there seems to be no way to block a request inside the interceptor,
            // an error will popup if you try navigation via router. To fix, some refactoring up the chain will need to occur,
            // or we will need to try token refreshing (which could have security implications)
            this.window.location.reload();
            return true;
        }
        return false;
    }

    getHttpHeaders(): Observable<Record<string, string>> {
        return from(this.checkExpiry()).pipe(
            switchMap(() => this.getBearerToken()),
            map((bearerToken) => ({
                Authorization: `Bearer ${bearerToken}`,
                "findex-invitation": this.invitationId || "none",
            })),
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes(`${this.environment.auth.base}${environmentCommon.auth.invitationLogin}`)) {
            // Don't send bearer if trying to login
            return next.handle(req);
        }
        return this.getHttpHeaders().pipe(
            switchMap((setHeaders) => {
                if (!setHeaders) {
                    return next.handle(req);
                }

                const reqWithAuth = req.clone({ setHeaders });
                return next.handle(reqWithAuth);
            }),
        );
    }

    private getBearerToken(): Observable<string> {
        return defer(() => {
            const storedResult = this.storage.getItem(this.storageKey.authResult);

            if (this.isAuthResult(storedResult)) {
                return storedResult?.IdToken;
            } else {
                return "";
            }
        });
    }

    private isAuthResult(result: unknown): result is { IdToken: string } {
        return typeof result === "object" && result !== null && "IdToken" in result;
    }

    private isUser(result: unknown): result is AppUser {
        return result && typeof result === "object" && "id" in result;
    }
}
