import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Chart, ChartConfiguration } from "chart.js";

@Component({
    selector: "chart",
    templateUrl: "./chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: "chart",
})
export class ChartComponent implements AfterContentInit, OnChanges, OnDestroy {
    @ViewChild("canvas", { static: true }) canvas: ElementRef;
    @Input() config: ChartConfiguration;

    private chart: Chart;

    constructor(private zone: NgZone) {}

    ngAfterContentInit() {
        this.zone.runOutsideAngular(() => {
            this.chart = new Chart(this.canvas.nativeElement, this.config);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const { config } = changes;

        if (config && this.chart) {
            Object.assign(this.chart.config, config.currentValue);
        }

        this.update();
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.destroy();
            }
        });
    }

    update() {
        this.zone.runOutsideAngular(() => this.updateChart());
    }

    getChart(): Chart {
        return this.chart;
    }

    private updateChart() {
        if (this.chart) {
            this.chart.update();
        }
    }
}
