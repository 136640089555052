<div class="create-account">
    <div class="create-account-header">
        <div class="create-account-header-title">Last step, create your free Visory account</div>
        <div class="create-account-header-subtitle">
            Already have an account?
            <a
                [mat-dialog-close]="true"
                class="create-account-header-subtitle-link"
                [routerLink]="['/login']"
                analyticsClick="onboarding_login"
                [queryParams]="{ emailAddress: form.controls['emailAddress'].value }"
                >Log in now</a
            >
        </div>
    </div>
    <div
        class="fx-form-group reduced-bottom-margin"
        *ngIf="errorMessage"
    >
        <div class="fx-form-validation-panel">
            <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
        </div>
    </div>
    <div class="create-account-form">
        <form
            class="fx-onboarding-form fx-form fx-form--dark client-profile-form"
            [formGroup]="form"
        >
            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="businessName"
                    >Business name</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="businessName"
                        id="businessName"
                        class="fx-form-input"
                        type="text"
                        placeholder="eg. Doe Enterprises"
                        aria-label="Business name"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['businessName'].valid && form.controls['businessName'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['businessName'].hasError('required') && form.controls['businessName'].touched"
                >
                    Business name is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>

            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="givenName"
                    >First name</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="givenName"
                        id="givenName"
                        class="fx-form-input"
                        type="text"
                        placeholder="eg. John"
                        aria-label="First name"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['givenName'].valid && form.controls['givenName'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['givenName'].hasError('required') && form.controls['givenName'].touched"
                >
                    First name is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>

            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="familyName"
                    >Last name</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="familyName"
                        id="familyName"
                        class="fx-form-input"
                        type="text"
                        placeholder="eg. Doe"
                        aria-label="Last name"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['familyName'].valid && form.controls['familyName'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['familyName'].hasError('required') && form.controls['familyName'].touched"
                >
                    Last name is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="emailAddress"
                    >Email</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="emailAddress"
                        id="emailAddress"
                        class="fx-form-input"
                        type="text"
                        placeholder="eg. john.doe@gmail.com"
                        aria-label="Email"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched"
                >
                    Email is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                >
                    Email is invalid.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="mobileNumber"
                    >Mobile number</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="mobileNumber"
                        id="mobileNumber"
                        class="fx-form-input"
                        type="text"
                        aria-label="mobileNumber"
                        [ngClass]="{
                            'fx-form-input--error':
                                (!form.controls['mobileNumber'].valid || internationalPhoneNoValid === false) &&
                                form.controls['mobileNumber'].touched
                        }"
                        intlTel
                        [intlTelOptions]="{
                            initialCountry: 'au',
                            onlyCountries: signupCountries,
                            allowDropdown: signupCountries.length > 1,
                            customContainer: 'fx-onboarding-intl-input'
                        }"
                        [intlTelInitialValue]="form.controls.mobileNumber.value"
                        (intlTelValid)="internationalPhoneNoValid = $event"
                        (intlTelCurrentValue)="internationalPhoneNo = $event"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['mobileNumber'].hasError('required') && form.controls['mobileNumber'].touched"
                >
                    Mobile number is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="
                        (form.controls['mobileNumber'].hasError('invalid') || internationalPhoneNoValid === false) &&
                        form.controls['mobileNumber'].value
                    "
                >
                    Mobile number is invalid.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>

            <div class="fx-form-group reduced-bottom-margin create-account-form-column">
                <label
                    class="fx-form-label"
                    for="password"
                    >Create password</label
                >
                <div class="fx-form-control">
                    <input
                        formControlName="password"
                        class="fx-form-input"
                        id="password"
                        name="Password"
                        placeholder=""
                        type="password"
                        togglePassword
                        [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                        [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['password'].valid && form.controls['password'].touched
                        }"
                    />
                </div>

                <span
                    class="fx-onboarding-password-hint"
                    [ngClass]="{
                        'fx-form-validation': !form.controls['password'].valid && form.controls['password'].touched
                    }"
                >
                    {{ getPasswordError("Password", true) }}
                </span>
            </div>
            <div class="create-account-footer">
                <div class="create-account-footer-disclaimer">
                    <fx-checkbox
                        id="signup-agreed-to-terms-and-conditions-checkbox"
                        formControlName="agreedToTermsAndConditions"
                    ></fx-checkbox>
                    <div>
                        I have read and understood the Visory
                        <a
                            analyticsClick="onboarding_privacy"
                            [href]="termsUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Terms</a
                        >
                        and
                        <a
                            analyticsClick="onboarding_termsofbusiness"
                            [href]="policyUrl"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Privacy Policy</a
                        >
                        <div
                            class="fx-form-validation"
                            *ngIf="
                                !form.controls['agreedToTermsAndConditions'].value &&
                                form.controls['agreedToTermsAndConditions'].touched
                            "
                        >
                            You must agree and understand to continue.
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
