<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<section class="tab-section">
    <div class="billing-history">
        <h2 class="billing-history-header">Billing history</h2>
        <div>
            <div *ngIf="!tableData.data?.length && !loader.counter">Sorry, you have no billing history.</div>
            <ng-container *ngIf="tableData.data?.length">
                <fx-table
                    [tableData]="tableData"
                    [selectable]="false"
                    [trackBy]="trackInvoice"
                >
                    <ng-container
                        *fxColumn="
                            'created';
                            label: tableHeaders.Date;
                            sortable: true;
                            mobileCollapse: true;
                            let invoice
                        "
                    >
                        <div class="billing-title billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.Date }}: </span>
                            <span>
                                {{ invoice.created * 1000 | date : "dd MMM yyyy" }}
                            </span>
                        </div>
                    </ng-container>
                    <ng-container
                        *fxColumn="
                            'number';
                            label: tableHeaders.InvoiceNo;
                            sortable: true;
                            mobileCollapse: true;
                            let invoice
                        "
                    >
                        <div class="billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.InvoiceNo }}: </span>
                            <span>
                                {{ invoice.number }}
                            </span>
                        </div>
                    </ng-container>
                    <ng-container
                        *fxColumn="'plan'; label: tableHeaders.Item; sortable: false; mobileCollapse: true; let invoice"
                    >
                        <div class="billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.Item }}: </span>
                            <ng-container *ngFor="let plan of invoice.lines.data">
                                {{ plan?.plan?.nickname ? plan.plan.nickname : "Payment" }}
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container
                        *fxColumn="
                            'billingPeriod';
                            label: tableHeaders.BillingPeriod;
                            sortable: false;
                            mobileCollapse: true;
                            let invoice
                        "
                    >
                        <div class="billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.BillingPeriod }}: </span>
                            <ng-container *ngFor="let plan of invoice.lines.data">
                                {{
                                    (plan.price.type === "recurring" ? plan.price.recurring.interval + "ly" : "One off")
                                        | titlecase
                                }}
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container
                        *fxColumn="
                            'amount_due';
                            label: tableHeaders.Amount;
                            sortable: true;
                            mobileCollapse: true;
                            let invoice
                        "
                    >
                        <div class="billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.Amount }}: </span>
                            <b>{{ invoice.amount_due / 100 | currency : (invoice.currency | uppercase) }}</b>
                        </div>
                    </ng-container>
                    <ng-container
                        *fxColumn="
                            'status';
                            label: tableHeaders.Status;
                            sortable: true;
                            mobileCollapse: true;
                            let invoice
                        "
                    >
                        <div class="billing-col">
                            <span class="mobile-cell-label">{{ tableHeaders.Status }}: </span>
                            <b>
                                <span
                                    [ngClass]="{ paid: invoice.paid }"
                                    *ngIf="!invoice.payment_intent?.last_payment_error"
                                >
                                    {{ invoice.status | titlecase }}
                                    <a
                                        *ngIf="!invoice.paid && invoice.status !== 'processing'"
                                        class="billing-history-invoice-link"
                                        [analyticsClick]="gaEvents.BILLINGHISTORY_VIEWINVOICE"
                                        (click)="payInvoice($event, invoice)"
                                        [ngClass]="{ disabled: !(role$ | async | permissions : 'PayInvoice' | async) }"
                                        >- Pay now</a
                                    ></span
                                >
                                <span
                                    class="failed"
                                    *ngIf="invoice.payment_intent?.last_payment_error"
                                >
                                    Failed -
                                    <a
                                        class="billing-history-invoice-link"
                                        [analyticsClick]="gaEvents.BILLINGHISTORY_VIEWINVOICE"
                                        (click)="payInvoice($event, invoice)"
                                        [ngClass]="{ disabled: !(role$ | async | permissions : 'PayInvoice' | async) }"
                                        >Pay now</a
                                    ></span
                                >
                            </b>
                        </div>
                    </ng-container>
                    <ng-container *fxColumn="'receipt'; label: ''; sortable: true; mobileCollapse: true; let invoice">
                        <ng-container *ngIf="invoice.hosted_invoice_url && invoice.status === 'paid'">
                            <i class="las la-file billing-history-invoice-icon"></i>
                            <a
                                class="billing-history-invoice-link"
                                rel="noopener noreferrer"
                                [analyticsClick]="gaEvents.BILLINGHISTORY_VIEWINVOICE"
                                href="{{ invoice.hosted_invoice_url }}"
                                target="_blank"
                            >
                                Invoice
                            </a>
                        </ng-container>
                        <ng-container *ngIf="!invoice.hosted_invoice_url || invoice.status !== 'paid'">
                            <i class="las la-file billing-history-invoice-icon"></i>
                            <a
                                class="billing-history-invoice-link"
                                rel="noopener noreferrer"
                                [analyticsClick]="gaEvents.BILLINGHISTORY_VIEWINVOICE"
                                href="{{ invoice.invoice_pdf }}"
                                target="_blank"
                            >
                                Invoice
                            </a>
                        </ng-container>
                    </ng-container>
                </fx-table>
            </ng-container>
        </div>
        <div *ngIf="!!errorMessage">
            <div>{{ errorMessage }}</div>
        </div>
    </div>
</section>
