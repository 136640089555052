import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MetadataDomain, Tenant } from "@visoryplatform/threads";
import { Integration } from "../../types";
import { IntegrationsService } from "../../services/integrations.service";
import { Observable } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

export enum IntegrationsAccountStrategy {
    Default = "1",
    Select = "2",
    Connected = "3",
}

@Component({
    selector: "integrations-account",
    templateUrl: "./integrations-account.component.html",
    styleUrls: ["./integrations-account.component.scss"],
})
export class IntegrationsAccountComponent implements OnChanges {
    @Input() accountId: string;
    @Input() integration: Integration;
    @Input() metadata: MetadataDomain;
    @Input() enabled: boolean;
    @Input() loading: boolean;

    @Output() connectIntegration = new EventEmitter<Integration>();
    @Output() selectTenant = new EventEmitter<Tenant>();
    @Output() disconnectIntegration = new EventEmitter<Integration>();

    readonly strategies = IntegrationsAccountStrategy;
    tenantControl = new FormControl("", [Validators.required]);

    tenants$?: Observable<Tenant[]>;
    connectedBusiness?: string;
    strategy: IntegrationsAccountStrategy;
    loader = new Loader();

    constructor(private integrationsService: IntegrationsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.metadata) {
            this.strategy = this.getIntegrationStrategy(this.metadata);
            this.connectedBusiness = this.metadata?.tenantName;

            if (this.strategy === IntegrationsAccountStrategy.Select) {
                this.tenants$ = this.loader.wrap(
                    this.integrationsService.listTenants(this.accountId, this.integration.issuerType),
                );
            }
        }
    }

    onConnectIntegration(): void {
        this.connectIntegration.emit(this.integration);
    }

    onSelectTenant(tenant: Tenant): void {
        this.selectTenant.emit(tenant);
    }

    onDisconnectIntegration(): void {
        this.disconnectIntegration.emit(this.integration);
    }

    private getIntegrationStrategy(integrationMetadata: MetadataDomain): IntegrationsAccountStrategy {
        if (integrationMetadata?.businessId) {
            return IntegrationsAccountStrategy.Connected;
        } else if (integrationMetadata?.token) {
            return IntegrationsAccountStrategy.Select;
        } else {
            return IntegrationsAccountStrategy.Default;
        }
    }
}
