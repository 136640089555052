<div
    *ngIf="currentThread$ | async; let thread"
    class="main-route"
>
    <workflow-details
        [thread]="thread"
        [role]="globalRole$ | async"
    >
        <thread-route></thread-route>
    </workflow-details>
</div>
