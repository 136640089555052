<div
    *ngIf="open"
    class="slide-menu-overlay"
    (click)="close.emit()"
></div>
<div
    class="slide-menu"
    [ngClass]="{ 'is-open': open }"
>
    <div class="slide-menu-drawer">
        <ng-content></ng-content>
    </div>
</div>
