import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    IPayRunListItem,
    IPayRunReport,
    PayrunReportRegion,
    IKeyPayBankFileSettingsItem,
    IntegrationTypes,
} from "@visoryplatform/threads";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
import { HttpClient } from "@angular/common/http";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { VaultService } from "@visoryplatform/vault";
import { switchMap } from "rxjs/operators";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";

@Injectable({
    providedIn: "root",
})
export class PayrunReportDataService {
    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private http: HttpClient,
        private vaultService: VaultService,
    ) {}

    getPayRunList(accountId: string, provider: IntegrationTypes): Observable<IPayRunListItem[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/items`;

        return this.http.get<IPayRunListItem[]>(url);
    }

    getBankFileSettings(accountId: string, provider: IntegrationTypes): Observable<IKeyPayBankFileSettingsItem[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/bank-file/settings`;
        return this.http.get<IKeyPayBankFileSettingsItem[]>(url);
    }

    getPayRunReportById(
        reportId: string | number,
        accountId: string,
        provider: IntegrationTypes,
    ): Observable<IPayRunReport> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/payroll/${provider}/payrun/${reportId}`;

        return this.http.get<IPayRunReport>(url);
    }

    downloadVaultPayRunReport(vaultId: string, fileId: string): Observable<IPayRunReport> {
        return this.vaultService
            .getDownloadUrl(vaultId, fileId, "index.json")
            .pipe(switchMap((url) => this.downloadRequestAttachment(url)));
    }

    refreshPayRunList(threadId: string, cardId: string): Observable<void> {
        const { base } = this.environment.vaultThreadsEndpoints;
        const url = `${base}/threads/${threadId}/cards/${cardId}/actions/refresh`;

        return this.http.post<void>(url, {});
    }

    getCurrencyByRegion(region: PayrunReportRegion): AcceptedCurrencies {
        switch (region) {
            case PayrunReportRegion.AU:
                return AcceptedCurrencies.aud;
                break;
            case PayrunReportRegion.NZ:
                return AcceptedCurrencies.nzd;
                break;
            case PayrunReportRegion.SG:
                return AcceptedCurrencies.sgd;
                break;
            // case PayrunReportRegion.US:
            //     return AcceptedCurrencies.usd;
            //     break;
            default:
                return AcceptedCurrencies.aud;
        }
    }

    private downloadRequestAttachment(url: string): Observable<IPayRunReport> {
        return this.http.get<IPayRunReport>(url);
    }
}
