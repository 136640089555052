<ng-container *ngIf="loaded$ | async">
    <ng-container [ngSwitch]="notificationCardType | async">
        <div
            class="notification-card"
            *ngSwitchCase="NOTIFICATION_CARD_TYPES.USER_ADDED"
            [notificationChannel]="participantChannelUpdate$ | async"
            [enableMarkAsResolved]="true"
        >
            <i class="las la-user-plus"></i>
            <p>
                <span class="notification-card-name">{{ (actor$ | async)?.profile?.name || appName }}</span> added
                <ng-container
                    *ngTemplateOutlet="userList; context: { $implicit: participants$ | async }"
                ></ng-container>
                to the workflow
            </p>
        </div>
        <div
            class="notification-card"
            *ngSwitchCase="NOTIFICATION_CARD_TYPES.USER_REMOVED"
            [notificationChannel]="participantChannelUpdate$ | async"
            [enableMarkAsResolved]="true"
        >
            <i class="las la-user-minus"></i>
            <p>
                <span class="notification-card-name">{{ (actor$ | async)?.profile?.name || appName }}</span> removed
                <ng-container
                    *ngTemplateOutlet="userList; context: { $implicit: participants$ | async }"
                ></ng-container>
                from the workflow.
            </p>
        </div>
        <div
            class="notification-card"
            *ngSwitchCase="NOTIFICATION_CARD_TYPES.USERS_INVITED"
        >
            <i class="las la-envelope"></i>
            <p>
                <span class="notification-card-name">{{ (actor$ | async)?.profile?.name || appName }}</span> invited
                <ng-container
                    *ngTemplateOutlet="userList; context: { $implicit: participants$ | async }"
                ></ng-container>
                to the workflow.
            </p>
        </div>
        <div
            class="notification-card"
            *ngSwitchCase="NOTIFICATION_CARD_TYPES.USERS_BULK_REPLACED"
        >
            <i class="las la-user-plus"></i>
            <p>
                <span class="notification-card-name">{{ (actor$ | async)?.profile?.name || appName }}</span> has been
                bulk replaced with
                <ng-container
                    *ngTemplateOutlet="userList; context: { $implicit: participants$ | async }"
                ></ng-container>
            </p>
        </div>
    </ng-container>
</ng-container>
<ng-template
    #userList
    let-participants
>
    <span *ngFor="let participant of participants; count as count; last as isLast; first as isFirst">
        <span *ngIf="count > 1 && !isLast && !isFirst">, </span>
        <span *ngIf="count > 1 && isLast"> and </span>
        <span class="notification-card-name">{{ participant?.profile?.name }}</span>
    </span>
</ng-template>
