import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject } from "@angular/core";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth/model/AppUser";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
@Component({
    selector: "findex-header",
    templateUrl: "./findex-header.component.html",
    styleUrls: ["./findex-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindexHeaderComponent {
    readonly stage: string;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.stage = environment.stage;
    }

    @Input() user: AppUser;
    @Input() layoutOpen: boolean;
    @Input() showMenuToggle: boolean;
    @Output() login = new EventEmitter();
    @Output() logout = new EventEmitter();
    @Output() menu = new EventEmitter<boolean>();
}
