<div class="sidebar-modal">
    <div class="sidebar-modal-header">
        <div
            class="sidebar-modal-title"
            [ngSwitch]="brandingEnabled$ | async"
        >
            <delphi-logo
                *ngSwitchCase="true"
                label="{{ aiName }} Memories"
                style="margin-bottom: 2em; display: block"
            ></delphi-logo>

            <h3 *ngSwitchDefault>Relevant Memories</h3>
        </div>
        <div class="sidebar-modal-header-close">
            <div
                class="fx-l-modal-header-icon"
                (click)="close()"
            >
                <i class="las la-times icon-size"></i>
            </div>
        </div>
    </div>

    <memory-table [memories]="contextMemory.memories"></memory-table>

    <a
        *ngIf="memoriesEnabled$ | async"
        [routerLink]="['/accounts', accountId, 'memories']"
        class="fx-btn fx-btn--full-width"
        target="_blank"
        role="button"
        style="width: auto; max-width: 220px"
    >
        View all memories
    </a>
</div>
