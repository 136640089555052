<sidebar-modal-layout
    header="{{ account.label }} - {{ (previewTitle$ | async) || 'New workflow' }}"
    (close)="close()"
>
    <loader-icon
        *ngIf="loader.counter"
        color="light"
        [showBackground]="true"
    ></loader-icon>
    <stepper-layout
        [linear]="true"
        endStepLabel="Create"
        (completed)="
            createThread(
                customTitle$,
                account.id,
                participants$,
                dueDates,
                assignees,
                workflowDesignSubject,
                selectedDesignType$,
                selectWorkflowTokenControl$,
                workflowConfiguration$
            )
        "
    >
        <stepper-step
            label="Select service"
            [completed]="isFormCompleted"
        >
            <div
                *ngIf="!loader.counter && referenceCard"
                class="forward-message-preview"
            >
                <!-- Message card preview -->
                <forward-message-preview
                    *ngIf="!referenceReply"
                    [name]="referenceCard.createdBy | userToName | async"
                    [image]="referenceCard.createdBy | profilePicture | async"
                    [timestamp]="referenceCard.createdAt"
                    [message]="referenceCard.description"
                ></forward-message-preview>
                <!-- Card reply preview -->
                <forward-message-preview
                    *ngIf="referenceReply"
                    [name]="referenceReply.actor | userToName | async"
                    [image]="referenceReply.actor | profilePicture | async"
                    [timestamp]="referenceReply.timestamp"
                    [message]="referenceReply.message"
                ></forward-message-preview>
            </div>

            <select-workflow
                *ngIf="account && workflowDesignTypes$ | async as workflowDesignTypes"
                [account]="account"
                [threadType]="defaultThread?.type || threadType"
                [workflowDesignTypes]="workflowDesignTypes"
                [workflowDesignId]="defaultThread?.workflow?.designId || workflowDesignId"
                [workflowConfigurationId]="defaultThread?.workflowConfigurationId || workflowConfigurationId"
                (selectedService)="designSelected($event, selectedDesignControl, workflowDesignTypes)"
                (selectedDesign)="designSelected(selectedServiceControl, $event, workflowDesignTypes)"
                (selectedWorkflowTokens)="workflowTokenSelected($event)"
            ></select-workflow>

            <edit-title
                *ngIf="{
                    selectedDesign: selectedDesignSubject | async,
                    selectedDesignType: selectedDesignType$ | async
                } as context"
                [title]="customTitle$ | async"
                [designType]="context.selectedDesignType"
                [design]="context.selectedDesign"
                [account]="account"
                (titleChange)="setCustomTitle(context.selectedDesign, context.selectedDesignType, $event)"
            ></edit-title>

            <div class="workflow-configuration-banner">
                <warning-banner *ngIf="account && workflowConfigurationId && !isWorkflowValid">
                    Workflow is not configured.
                    <a
                        class="workflow-configuration-banner-invalid-link"
                        (click)="navigateToConfiguration(account.id, workflowConfigurationId)"
                        >Please configure the steps to proceed.</a
                    ></warning-banner
                >
            </div>
        </stepper-step>

        <stepper-step
            *ngIf="selectedHasSlas"
            label="Configure service"
            [completed]="isFormValid"
        >
            <ng-container *ngIf="{ workflowConfiguration: workflowConfiguration$ | async } as context">
                <div class="warning-banner-container">
                    <warning-banner *ngIf="!isWorkflowValid">
                        The workflow design has been revised, and duplicating due dates and assignees is not possible.
                        Please reset to defaults to proceed.
                    </warning-banner>
                </div>

                <configure-workflow
                    [workflowDesign]="selectedDesignSubject | async"
                    [timezone]="account?.metadata?.contactInfo?.timeZone"
                    [workflowConfigurationSteps]="context.workflowConfiguration?.steps"
                    [defaultThread]="defaultThread"
                    (dueDates)="dueDates = $event"
                    (assignees)="setAssignees($event)"
                    (isWorkflowValid)="isWorkflowValid = $event"
                    (isFormValid)="isFormValid = $event"
                    [role]="role"
                ></configure-workflow>
            </ng-container>
        </stepper-step>

        <ng-container *ngIf="participants$ | async; let participants">
            <stepper-step
                label="Edit participants"
                [completed]="participants.length > 0"
            >
                <edit-participants
                    [participants]="participants"
                    (participantsChanged)="updateParticipants($event)"
                ></edit-participants>
            </stepper-step>
        </ng-container>
    </stepper-layout>
</sidebar-modal-layout>
