import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Account, ITimeline, ThreadTypes } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IPluginFactory, RouteHelper } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { InternalChatRouteComponent } from "./components/internal-chat-route/internal-chat-route.component";

const accountsPrefix = "accounts";
const internalChatRoute = "internal-chat";
@Injectable()
export class InternalChatPlugin implements IPluginFactory {
    readonly id = "InternalChatPlugin";
    readonly gaEvents = GA_EVENTS;

    private featureEnabled$: Observable<boolean>;

    constructor(
        private libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private router: Router,
    ) {
        this.featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableInternalChat);

        this.registerExtensions();
    }

    private registerExtensions(): void {
        const dynamicRoutes = RouteHelper.getRoutes(this.libraries.threadViews);
        const accountInternalChat: RouteExtension<Account> = {
            label: "Internal Chat",
            showExtension: () => this.showExtension("ReadInternalChat"),
            route: {
                path: internalChatRoute,
                component: InternalChatRouteComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "activity",
                        pathMatch: "full",
                    },
                    {
                        path: "activity",
                        children: [],
                    },
                    {
                        path: "activity/cards/:cardId",
                        children: [],
                    },
                    ...dynamicRoutes,
                ],
            },
        };

        const threadInternalChatExtention: RouteExtension = {
            label: "Internal chat",
            icon: "la-external-link-alt",
            showExtension: (thread: ITimeline) => this.showExtension("ReadInternalChat", thread),
            redirect: (thread: ITimeline) => this.redirectToAccountInternalChat(thread.accountId),
            route: {
                path: internalChatRoute,
                component: InternalChatRouteComponent,
            },
        };

        this.libraries.accountRoutes.register(internalChatRoute, accountInternalChat);

        this.libraries.threadViews.register(internalChatRoute, threadInternalChatExtention);
    }

    private redirectToAccountInternalChat(accountId: string): void {
        const url = this.router.createUrlTree([accountsPrefix, accountId, internalChatRoute]).toString();
        window.open(url, "_blank");
    }

    private showExtension(permission: string, thread?: ITimeline): Observable<boolean> {
        if (thread?.type === ThreadTypes.InternalChat) {
            return of(false);
        }

        return this.featureEnabled$.pipe(
            switchMap((enabled) => {
                if (!enabled) {
                    return of(false);
                }

                return this.authService.getUser().pipe(
                    filter((user) => !!user),
                    switchMap((user) => this.userHasPermission(user, permission)),
                );
            }),
        );
    }

    private userHasPermission(user: AppUser, permission: string): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, permission);
    }
}
