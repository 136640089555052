import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnalyticsModule } from "../../portal-modules/src/lib/analytics";
import { CommonModule } from "@angular/common";
import { GlobalSearchPageComponent } from "./components/global-search-page/global-search-page.component";
import { GlobalSearchCardComponent } from "./components/global-search-card/global-search-card.component";
import { GlobalSearchGhostLoaderComponent } from "./components/global-search-ghost-loader/global-search-ghost-loader.component";
import { NgModule } from "@angular/core";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { SearchModule } from "../../portal-modules/src/lib/search/search.module";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui";
import { RouterModule } from "@angular/router";
import { QuillModule } from "ngx-quill";
import { ThreadsUiPipesModule } from "projects/portal-modules/src/lib/threads-ui/pipes/threads-ui-pipes.module";
import { TimelineModule } from "projects/portal-modules/src/lib/timeline/timeline.module";
import { EmptyStateModule } from "projects/portal-modules/src/lib/empty-state/empty-state.module";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui/threads-ui.module";
import { SharedPipesModule } from "projects/portal-modules/src/lib/shared/pipes/shared-pipes.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FindexUiModule,
        ReactiveFormsModule,
        AnalyticsModule,
        PortalUiModule,
        SearchModule,
        FormsModule,
        RouterModule,
        QuillModule,
        ThreadsUiModule,
        ThreadsUiPipesModule,
        EmptyStateModule,
        TimelineModule,
        SharedPipesModule,
    ],
    declarations: [GlobalSearchPageComponent, GlobalSearchCardComponent, GlobalSearchGhostLoaderComponent],
    exports: [GlobalSearchPageComponent, GlobalSearchCardComponent, GlobalSearchGhostLoaderComponent],
    providers: [],
})
export class GlobalSearchMobule {}
