import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { IThreadListing } from "@visoryplatform/threads";
import { SystemStepId } from "@visoryplatform/workflow-core";
import { IThreadGroupListing } from "../components/thread-list/thread-grouper";

@Injectable({ providedIn: "root" })
@Pipe({ name: "threadsStatusSearch", pure: true })
export class ThreadsStatusSearchPipe implements PipeTransform {
    transform(groups: IThreadGroupListing[], stateId: string): IThreadGroupListing[] {
        if (!stateId || !groups) {
            return groups;
        }
        return groups.map((group) => {
            return {
                ...group,
                children: group.children.filter((thread) => this.searchThread(thread, stateId)),
            };
        });
    }

    isThreadInState(thread: Partial<IThreadListing>, stateId: string): boolean {
        const notActiveStates: string[] = [SystemStepId.Cancelled, SystemStepId.End];

        if (!thread.workflow?.currentStepId) {
            return false;
        }

        if (stateId === "active") {
            return !notActiveStates.includes(thread.workflow?.currentStepId);
        } else if (stateId === "closed") {
            return thread.workflow?.currentStepId === SystemStepId.End;
        } else if (stateId === "cancelled") {
            return thread.workflow?.currentStepId === SystemStepId.Cancelled;
        } else {
            return thread.workflow?.currentStepId === stateId;
        }
    }

    private searchThread(thread: IThreadListing, stateId: string): boolean {
        const threadContains = this.isThreadInState(thread, stateId);
        return threadContains;
    }
}
