import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { environmentCommon } from "projects/portal-modules/src/lib/environment/environment.common";
import { WindowListenersService } from "../../../../portal-modules/src/lib/shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../../portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "../../../../../src/app/injection-token";
import { VaultCardService } from "../vault-card/services/vault-card.service";

@Component({
    selector: "file-input",
    templateUrl: "./file-input.component.html",
    styleUrls: ["./file-input.component.scss"],
})
export class FileInputComponent {
    @Input() analyticsPrefix: string;
    @Input() supportedFiles: string;
    @Input() allowMultipleFiles = true;
    @Output() file = new EventEmitter<File>();

    readonly uploadFilesizeIsTooLargeMessage = environmentCommon.uploadFilesizeIsTooLargeMessage;
    readonly uploadMaxFilesizeMessage = environmentCommon.uploadMaxFilesizeMessage;
    readonly filesDidNotUploadMessage = environmentCommon.filesDidNotUploadErrorMessage;
    readonly pleaseReattemptUploadMessage = environmentCommon.pleaseReattemptUploadMessage;
    readonly uploadFilesizeLimit = environmentCommon.uploadFilesizeLimitMB;
    readonly gaEvents = GA_EVENTS;

    isMobile = this.windowListenersService.isWindowSmaller(this.environment.featureFlags.windowWidthTabletBreakpoint);
    listOfOversizedFiles: string[] = [];
    listOfFailedFiles: string[] = [];

    constructor(
        private windowListenersService: WindowListenersService,
        private vaultCardService: VaultCardService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async onSelect(event: NgxDropzoneChangeEvent) {
        this.listOfOversizedFiles = [];
        this.listOfFailedFiles = [];

        for (const file of event.addedFiles) {
            const invalidFile = await this.vaultCardService.isFileInvalid(file);
            if (invalidFile) {
                this.listOfFailedFiles.push(file.name);
            } else if (this.isFileTooBig(file)) {
                this.listOfOversizedFiles.push(file.name);
            } else {
                this.file.emit(file);
            }
        }
    }

    private isFileTooBig(file: File): boolean {
        return file.size / (1024 * 1024) > this.uploadFilesizeLimit;
    }
}
