import { Injectable } from "@angular/core";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { ErrorMessagesConstants } from "projects/portal-modules/src/lib/shared/constants/error-messages.constants";

@Injectable()
export class VaultCardService {
    constructor(private errorService: ErrorService) {}

    async getInvalidFilenames(files: File[]): Promise<string[]> {
        const filesWithErrors = await this.getFilesWithErrors(files);
        if (!filesWithErrors.length) {
            return [];
        }

        return filesWithErrors.map((file: File) => file.name);
    }

    async isFileInvalid(file: File): Promise<boolean> {
        if (file.size <= 0) {
            const errorMessage = ErrorMessagesConstants.FileLessThenZero;
            this.errorService.logUploadFileError(file, errorMessage);
            return true;
        }
        return await this.isFileUnreadable(file);
    }

    getFilesUploadErrorToastMessageText(invalidFileNames: string[]): string {
        const isPlural = invalidFileNames.length > 1;
        const fileNamesString = invalidFileNames.join(", ");
        const postFix = isPlural ? "s" : "";
        const article = isPlural ? "are" : "is";
        const articleThe = isPlural ? "" : " the";
        return `${fileNamesString}.
                \nPlease try the following:
                \u2022 Ensure${articleThe} file${postFix} ${article} not open in another program.
                \u2022 Ensure no one else is editing${articleThe} file${postFix}.
                \u2022 Remove and re-attach${articleThe} file${postFix}.`;
    }

    private async isFileUnreadable(file: File): Promise<boolean> {
        try {
            //try to read the first byte, to discover any FS locks
            const firstByte = file.slice(0, 1);
            await firstByte.arrayBuffer();

            return false;
        } catch {
            const errorMessage = ErrorMessagesConstants.EnableToReadFile;
            this.errorService.logUploadFileError(file, errorMessage);
            return true;
        }
    }

    private async getFilesWithErrors(files: File[]): Promise<File[]> {
        const filesWithErrors: File[] = [];
        for (const file of files) {
            const isFileInvalid = await this.isFileInvalid(file);
            if (isFileInvalid) {
                filesWithErrors.push(file);
            }
        }
        return filesWithErrors;
    }
}
