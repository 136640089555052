<mat-drawer-container autosize>
    <ng-content></ng-content>
    <mat-drawer
        #drawer
        *ngIf="config$ | async; let config"
        [mode]="config.mode"
        [position]="config.position"
    >
        <ng-container *ngIf="item$ | async; let item">
            <ng-container *ngComponentOutlet="item.component; injector: item.injector"></ng-container>
        </ng-container>
    </mat-drawer>
</mat-drawer-container>
