import { FormControl, FormGroup } from "@angular/forms";
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { VaultRequestService } from "../../../services/vault-request.service";
import { CompleteRequestModalComponent } from "../../upload/complete-rfi-modal/complete-request-modal.component";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { CardReply, IThreadCard, ITimeline, IVaultRequestCardState, Role } from "@visoryplatform/threads";
import { RequestActionButtonLabel, RequestStatuses } from "../constants/request.constants";
import { ConfirmModalComponent } from "projects/portal-modules/src/lib/shared/components/confirm-modal/confirm-modal.component";
import { switchMap, take } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { IRequestModalData } from "../interfaces/IRequestModalData";
import { IRequestForm } from "../interfaces/IRequestForms";
import { RequestReplyComponent } from "../request-reply/request-reply.component";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

@Component({
    selector: "action-request",
    templateUrl: "./action-request.component.html",
    styleUrls: ["./action-request.component.scss"],
})
export class ActionRequestComponent implements OnInit {
    @ViewChild("requestReplyComponent") requestReplyComponent: RequestReplyComponent;

    @Input() readonly analyticsTags: IRequestAnalyticsTags;
    @Input() readonly completeButtonText: string;
    @Input() readonly requiredReviewPermissions: string[];
    @Input() readonly modalData: IRequestModalData;

    @Input() actionedPercentage: number;
    @Input() actionedRequestItems: number;
    @Input() card$: Observable<IThreadCard>;
    @Input() completedStatusLabel: RequestStatuses;
    @Input() currentUserRole: Role;
    @Input() form: FormGroup<IRequestForm>;
    @Input() readonly: boolean;
    @Input() replies: CardReply[];
    @Input() state: IVaultRequestCardState;
    @Input() thread$: Observable<ITimeline>;
    @Input() userId$: Observable<string>;
    @Output() savePending = new EventEmitter<void>();

    readonly reviewButtonText = RequestActionButtonLabel.Review;

    loader = new Loader();
    replyMessage = new FormControl("");

    private dialogRef: DialogRef;

    constructor(
        private dialog: MatDialog,
        private vaultRequestService: VaultRequestService,
        private analytics: AnalyticsService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private injector: Injector,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
    }

    replySaved(): void {
        this.form.controls?.replyMessage?.markAsPristine();
    }

    sendForReview(threadId: string, cardId: string, type: string, reviewButtonText: string): void {
        const dialogParams = this.vaultRequestService.getConfirmReviewDialogParams(reviewButtonText, type);
        const dialogAfterClosed$ = this.dialog.open(ConfirmModalComponent, dialogParams).afterClosed();
        dialogAfterClosed$
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return of([]);
                    }
                    return this.handleSendForReviewEvent(threadId, cardId, type);
                }),
                take(1),
                switchMap(() => this.savePendingChanges()),
            )
            .subscribe();
    }

    async savePendingChanges(): Promise<void> {
        await this.savePendingRequestReplyChanges();
        this.savePending.emit();
        this.dialogRef.close();
    }

    async save(thread: ITimeline, card: IThreadCard, isClose?: boolean): Promise<void> {
        if (this.canRequestBeCompleted()) {
            const complete = await this.dialog
                .open(CompleteRequestModalComponent, {
                    panelClass: "centered-modal",
                    data: this.vaultRequestService.getCompleteRequestModalData(card.type, this.analyticsTags),
                })
                .afterClosed()
                .toPromise();
            if (complete) {
                await this.handleRequestCompletionOnClose(thread, card);
            } else {
                this.analytics.recordEvent(this.analyticsTags.analyticsPrefix, this.analyticsTags.analyticsEventClose);
                await this.savePendingChanges();
            }
        } else {
            await this.handleIncompleteOnClose(card, isClose, thread.id);
        }
    }

    async savePendingRequestReplyChanges(): Promise<void> {
        this.loader.show();

        try {
            if (this.requestReplyComponent?.threadCardRepliesComponent?.replyControl.value) {
                await this.requestReplyComponent.threadCardRepliesComponent.reply(
                    this.requestReplyComponent.threadCardRepliesComponent.replyControl.value,
                );
            }

            this.requestReplyComponent?.threadCardRepliesComponent?.threadCardRepliesListComponent?.replyComponents?.forEach(
                async (replyComponent) => {
                    const replyValue = replyComponent.editReplyMessage?.value;
                    if (replyValue) {
                        await replyComponent.updateReply(replyComponent.reply, replyComponent.editReplyMessage);
                    }
                },
            );
        } finally {
            this.loader.hide();
        }
    }

    private handleSendForReviewEvent(threadId: string, cardId: string, type: string): Observable<void> {
        this.analytics.recordEvent(this.analyticsTags.analyticsPrefix, this.analyticsTags.analyticsEventReview);

        return this.loader.wrap(
            this.vaultRequestService.sendRequestCardEvent(threadId, cardId, type, {
                body: { isReview: true, isCompleted: false },
            }),
        );
    }

    private canRequestBeCompleted(): boolean {
        return !this.readonly && this.actionedPercentage === 100;
    }

    private async handleIncompleteOnClose(card: IThreadCard, isClose: boolean, threadId: string): Promise<void> {
        const hasReviewPermission = await this.permissionService
            .hasSomePermission(this.currentUserRole, this.requiredReviewPermissions)
            .pipe(take(1))
            .toPromise();

        if (hasReviewPermission && !this.readonly && !isClose) {
            this.sendForReview(threadId, card.id, card.type, this.reviewButtonText);
        } else {
            this.analytics.recordEvent(this.analyticsTags.analyticsPrefix, this.analyticsTags.analyticsEventClose);
            await this.savePendingChanges();
        }
    }

    private async handleRequestCompletionOnClose(thread: ITimeline, card: IThreadCard): Promise<void> {
        await this.vaultRequestService
            .sendRequestCardEvent(thread.id, card.id, card.type, { body: { isCompleted: true } })
            .toPromise();
        this.analytics.recordEvent(this.analyticsTags.analyticsPrefix, this.analyticsTags.analyticsEventComplete);
        await this.savePendingChanges();
    }
}
