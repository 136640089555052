<section
    *ngIf="attachments?.data?.length"
    class="request-attachments"
>
    <div class="request-attachments-list">
        <div
            *ngIf="title"
            class="request-attachments-list-title"
        >
            {{ title }}
        </div>
        <div class="request-attachments-list-items">
            <div
                *ngFor="let attachment of attachments?.data; let i = index"
                class="request-attachments-list-items-file"
            >
                <i class="request-attachments-list-items-file-icon las la-paperclip"></i>
                <a
                    class="request-attachments-list-items-file-name"
                    (click)="downloadFile(attachments.fileId, attachment)"
                >
                    {{ attachment.filename }}
                </a>

                <div
                    *ngIf="attachment | canDeleteAttachment : userId : role : canDelete | async"
                    (click)="deleteAttachment.emit(attachment)"
                    analyticsClick="{{ analyticsPrefix }}_remove-file"
                    class="request-attachments-list-items-file-delete"
                >
                    <i class="las la-times"></i>
                </div>
            </div>
        </div>
    </div>
</section>
