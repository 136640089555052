import { Component, Input, OnDestroy } from "@angular/core";
import { IStepUI } from "../../../../interfaces/IStepUi";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
    selector: "edit-assignees",
    templateUrl: "./edit-assignees.component.html",
    styleUrls: ["./edit-assignees.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EditAssigneesComponent,
        },
    ],
})
export class EditAssigneesComponent implements ControlValueAccessor, OnDestroy {
    @Input() step: IStepUI;

    assigneesControl = new FormControl<string[]>([], [Validators.required]);

    assigneesSub: Subscription;
    onChange: (assignees: string[]) => void;
    onTouch: () => void;

    readonly assignedTo = {
        client: "customer",
        visory: "Visory",
    };

    constructor() {
        this.assigneesSub = this.assigneesControl.valueChanges.subscribe((value) => {
            this.onChange?.(value);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.assigneesSub?.unsubscribe();
    }

    writeValue(assignees: string[]): void {
        this.assigneesControl.setValue(assignees);
    }

    registerOnChange(fn: (assignees: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.assigneesControl.disable() : this.assigneesControl.enable();
    }
}
