<div
    class="meeting"
    @fadeIn
    *ngIf="user$ | async as user"
>
    <div class="meeting-details">
        <div class="meeting-details-info">
            <div
                *ngIf="upcomingMeeting?.title"
                class="meeting-details-thread-title"
            >
                <span>
                    {{ upcomingMeeting.title }}
                </span>
                <i
                    class="las la-video"
                    [ngClass]="{ 'meeting-details-thread-title-active': timeUntilMeetingBegins <= 0 }"
                ></i>
            </div>

            <div class="meeting-details-account-label">
                <span *ngIf="upcomingMeeting?.threadTitle">
                    {{ upcomingMeeting.threadTitle }}
                </span>
                <span *ngIf="upcomingMeeting?.accountLabel"> | {{ upcomingMeeting.accountLabel }} </span>
            </div>

            <div class="meeting-details-bottom-section">
                <div class="meeting-details-bottom-section-left">
                    <div
                        *ngIf="upcomingMeeting?.nextInstance?.start"
                        class="meeting-details-bottom-section-left-time"
                    >
                        <i class="las la-calendar"></i>
                        {{ upcomingMeeting.nextInstance.start | todayDate : dateFormat.long }} -
                        {{ upcomingMeeting.nextInstance.end | date : dateFormat.short }}
                    </div>

                    <a
                        class="meeting-details-bottom-section-left-link"
                        (click)="viewMeeting($event, false)"
                        [analyticsClick]="GA_EVENTS.DASHBOARD_VIEW_MEETING_DETAILS"
                    >
                        View details
                    </a>
                </div>

                <div class="meeting-details-bottom-section-right">
                    <fx-avatar-multi
                        *ngIf="(avatars$ | async)?.length; else createdByAvatar"
                        [content]="avatars$ | async"
                    >
                    </fx-avatar-multi>
                    <ng-template #createdByAvatar>
                        <fx-avatar
                            [name]="(createdBy$ | async)?.name"
                            size="medium"
                            [image]="(createdBy$ | async)?.id | profilePicture | async"
                        ></fx-avatar>
                    </ng-template>
                </div>
            </div>
        </div>

        <div
            *ngIf="(meetingIsImminent$ | async) && !upcomingMeeting?.hasEnded"
            class="meeting-details-join-meeting"
        >
            <button
                class="fx-btn--primary"
                (click)="viewMeeting($event, true)"
                analyticsClick="dashboard_quickjoinmeeting"
            >
                <i class="las la-video"></i>
                <span>JOIN MEETING</span>
            </button>
        </div>
    </div>
</div>
