<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<route-drawer>
    <fx-layout [gutters]="false">
        <fx-layout-column
            [sizes]="{ mobile: 24 }"
            *ngIf="currentThread$ | async; let thread"
        >
            <div *ngIf="referencedThread$ | async; let referencedThread">
                <info-banner>
                    Workflow created from a request in
                    <strong
                        ><a
                            [routerLink]="[
                                '/workflows',
                                thread.referenceFrom.threadId,
                                'cards',
                                thread.referenceFrom.cardId,
                            ]"
                        >
                            {{ referencedThread.title }}</a
                        >
                    </strong>
                </info-banner>
            </div>

            <div *ngIf="(currentThread$ | async).restrictCardsToInternal">
                <warning-banner>
                    <span><strong>INTERNAL</strong> - Not visible to customers</span>
                </warning-banner>
            </div>
            <ng-container *ngIf="currentRole$ | async; let role">
                <div
                    *ngIf="extension$ | async as extension; else activity"
                    class="fx-threads-route"
                >
                    <router-outlet></router-outlet>
                </div>
                <ng-template #activity>
                    <div
                        #threadsContainer
                        class="fx-threads-messages"
                        invertScroll
                    >
                        <thread
                            [ngClass]="{ loading: loader.counter }"
                            [threadId]="thread.id"
                            [thread$]="currentThread$"
                            [role]="role"
                            [routeToCardId]="cardId$ | async"
                            [excludeCardTypes]="['thread']"
                            (loadCardComplete)="scrollToBottom()"
                        ></thread>
                    </div>

                    <div
                        class="fx-threads-new-message"
                        *ngIf="!loader.counter"
                    >
                        <ng-container *ngIf="!(FEATURE_FLAGS.DisableAdhocCreateCardBar | featureEnabled | async)">
                            <create-card
                                *ngIf="thread | isThreadActive"
                                [showLoader]="!!createLoader.counter"
                                [role]="role"
                                [globalRole]="globalRole$ | async"
                                [restrictCardsToInternal]="thread.restrictCardsToInternal"
                                (newCard)="addCard($event, thread)"
                            ></create-card>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
        </fx-layout-column>
    </fx-layout>
</route-drawer>
