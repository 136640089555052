<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div class="back">
    <a (click)="goBack()"><i class="las la-angle-left"></i> BACK</a>
</div>

<ng-container
    *ngIf="
        { report: report$ | async, metricGroupRow: metricGroupRow$ | async, enterprise: enterprise$ | async };
        let context
    "
>
    <div class="scatter">
        <insights-metric-summary
            [tableReportRow]="context.metricGroupRow"
            [report]="context.report"
            [showBenchmarking]="context.enterprise?.hideBenchmarking !== true"
        ></insights-metric-summary>
    </div>

    <div
        *ngIf="!shouldShowDataSource; else viewDataSource"
        class="graphs"
    >
        <div
            class="scatter"
            *ngIf="context.enterprise?.hideBenchmarking !== true"
        >
            <div class="scatter-header">
                <div>
                    <h2 class="title">BENCHMARK</h2>
                    <h3 class="subtitle">
                        <span>{{ context.metricGroupRow?.metric?.label }}</span>
                        <span>{{
                            context.metricGroupRow?.metric?.isHigherBetter ? isHigherBetterLabel : isLowerBetterLabel
                        }}</span>
                    </h3>
                </div>
            </div>

            <scatter-report
                [enterpriseId]="enterpriseId"
                [entityId]="entityId"
                [periodId]="context.metricGroupRow?.result?.periodId"
                [metricId]="metricId"
                [metricName]="context.metricGroupRow?.metric?.label"
                [groupByIndex]="2"
                [sortOrder]="sortOrder$ | async"
            >
            </scatter-report>
        </div>

        <div class="scatter">
            <div class="scatter-header">
                <div>
                    <h2 class="title">TREND OVER TIME</h2>
                    <h3 class="subtitle">
                        <span>{{ context.metricGroupRow?.metric?.label }}</span>
                        <span>{{
                            context.metricGroupRow?.metric?.isHigherBetter ? isHigherBetterLabel : isLowerBetterLabel
                        }}</span>
                    </h3>
                </div>
                <div>
                    <div class="scatter-header-right">
                        <!-- TODO get the source label add source to interface when we get metric-->
                    </div>
                </div>
            </div>

            <historical-report
                [enterpriseId]="enterpriseId"
                [entityId]="entityId"
                [periodId]="context.metricGroupRow?.result?.periodId"
                [row]="context.metricGroupRow"
            >
            </historical-report>
        </div>
    </div>
</ng-container>

<ng-template #viewDataSource>
    <div class="scatter">
        <div class="scatter-header">
            <div class="scatter-header-left"></div>
            <div class="scatter-header-right">
                <button
                    class="scatter-header-right-button"
                    type="button"
                    (click)="toggleShowDataSource()"
                >
                    View Charts
                </button>
            </div>
        </div>

        <mat-table [dataSource]="tableData">
            <ng-container matColumnDef="Column 1">
                <mat-header-cell *matHeaderCellDef> Month </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Column 2">
                <mat-header-cell *matHeaderCellDef> Metric Value </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.weight }} </mat-cell>
            </ng-container>

            <!-- Header and Row Declarations -->
            <mat-header-row *matHeaderRowDef="['Column 1', 'Column 2']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['Column 1', 'Column 2']"></mat-row>
        </mat-table>
    </div>
</ng-template>
