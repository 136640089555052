<div class="onboarding-wizard">
    <multi-component-layout
        *ngIf="!emailVerificationSent"
        [enableBreadCrumb]="false"
        [enableProgressBar]="true"
        [enableClose]="true"
        [enableFooter]="enableFooter"
        [stepConfigurations]="stepConfigurations"
        (closeClicked)="exitOnboarding()"
        (emitTransition)="handleTransition($event)"
        (emitComplete)="signUpUser()"
        [loading]="loader.counter !== 0"
        contentClass="multi-component-layout--onboarding"
        [analyticsPrefix]="gaEventsPrefix.ONBOARDING"
    >
        <ng-container initial-back-step>
            <div class="onboarding-wizard-login-text">
                Have an account?
                <a
                    [mat-dialog-close]="true"
                    class="onboarding-wizard-login-link"
                    [routerLink]="['/login']"
                    [analyticsClick]="gaEventsPrefix.ONBOARDING + gaEvents.LOGIN"
                    >Log in</a
                >
            </div>
        </ng-container>

        <step-layout stepTitle="'Get Started'">
            <app-onboarding-context-screen></app-onboarding-context-screen>
        </step-layout>
        <step-layout stepTitle="'Services'">
            <onboarding-tile-selection
                [tileModel]="servicesConfig.tiles"
                [title]="servicesConfig.title"
                [subtitle]="servicesConfig.subtitle"
                [selectionType]="selectionTypes.Multi"
                (emitMultiTileSelection)="handleSelectServices($event)"
                [fullWidth]="true"
                [analyticsPrefix]="gaEventsPrefix.ONBOARDING_TILE"
            ></onboarding-tile-selection>
        </step-layout>
        <step-layout [stepTitle]="'Select your AE'">
            <app-select-customer-success-manager
                (resetStaffSelection)="handleResetStaff()"
                (modelUpdate)="handleSelectStaff($event)"
                (enableFooter)="enableFooter = $event"
                [analyticsPrefix]="gaEventsPrefix.ONBOARDING"
            ></app-select-customer-success-manager
        ></step-layout>
        <step-layout [stepTitle]="'Book Meeting'">
            <app-onboarding-book-meeting
                (modelUpdate)="handleDateSelected($event)"
                (handleResetStaff)="handleResetStaff()"
                [analyticsPrefix]="gaEventsPrefix.ONBOARDING"
            ></app-onboarding-book-meeting>
        </step-layout>
        <step-layout stepTitle="'Create an account'">
            <create-account
                *ngIf="!loader.counter"
                [errorMessage]="createAccountError"
                (emitCreateAccount)="handleValidAccountDetails($event)"
                (emitValidAccount)="enableCreateAccountStep($event)"
            ></create-account>
        </step-layout>
        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>
    </multi-component-layout>
    <ng-container *ngIf="emailVerificationSent">
        <email-verification-sent
            (emitResendEmail)="resendEmailVerification()"
            [emailAddress]="signUpUserPayload?.emailAddress"
            [verifyEmailMessage]="verifyEmailMessage"
        ></email-verification-sent>
        <loader-icon *ngIf="loader.counter"></loader-icon>
    </ng-container>
</div>
