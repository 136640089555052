import { Component, Input, OnDestroy } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { DateTime } from "luxon";
import { IStepUI } from "../../../../interfaces/IStepUi";
import { Subscription } from "rxjs";
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MAT_LUXON_DATE_FORMATS,
} from "@angular/material-luxon-adapter";
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from "@angular/material/core";
import { CREATE_END_HOUR } from "@visoryplatform/workflow-core";
@Component({
    selector: "edit-due-date",
    templateUrl: "./edit-due-date.component.html",
    styleUrls: ["./edit-due-date.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EditDueDateComponent,
        },
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    ],
})
export class EditDueDateComponent implements ControlValueAccessor, OnDestroy {
    @Input() step: IStepUI;

    dateTimeControl = new FormControl<string>(null);
    disabled = false;
    zone = "local";
    isDifferentZone: boolean;
    userTimezoneDate: DateTime;
    accountTimezoneDate: DateTime;

    private onChange: (value: DateTime) => void;
    private onTouch: () => void;
    private valSub: Subscription;

    constructor() {
        this.valSub = this.dateTimeControl.valueChanges.subscribe((value) => {
            this.accountTimezoneDate = DateTime.fromISO(value).setZone(this.zone, { keepLocalTime: true });
            this.userTimezoneDate = this.accountTimezoneDate.toLocal();

            this.onChange?.(this.accountTimezoneDate);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.valSub?.unsubscribe();
    }

    onStepTimeChange(updatedTime: DateTime): void {
        this.accountTimezoneDate = updatedTime.setZone(this.zone, { keepLocalTime: true });
        this.userTimezoneDate = this.accountTimezoneDate.toLocal();
        const localTime = updatedTime.toISO();
        this.dateTimeControl.setValue(localTime);
        this.dateTimeControl.markAsDirty();
    }

    onDayChanged(dateUpdateMatInput: MatDatepickerInputEvent<DateTime>): void {
        const updatedValue = dateUpdateMatInput.value;

        if (updatedValue === null) {
            return;
        }
        const adjustedTime = updatedValue.set({ hour: CREATE_END_HOUR });
        this.accountTimezoneDate = updatedValue.setZone(this.zone, { keepLocalTime: true });
        this.userTimezoneDate = this.accountTimezoneDate.toLocal();
        this.dateTimeControl.setValue(adjustedTime.toISO());
    }

    writeValue(dateTime: DateTime): void {
        if (!dateTime) {
            return;
        }

        const local = dateTime.setZone("local", { keepLocalTime: true });
        this.zone = dateTime.zoneName;
        this.isDifferentZone = dateTime.offset !== local.offset;
        this.accountTimezoneDate = dateTime;
        this.userTimezoneDate = dateTime.toLocal();
        this.dateTimeControl.setValue(local.toISO());
    }

    registerOnChange(fn: (value: DateTime) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.dateTimeControl.disable();
        } else {
            this.dateTimeControl.enable();
        }
    }
}
