import { ChartConfiguration, ChartDataset, LegendItem, TooltipItem } from "chart.js";
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ChartComponent } from "../chart/chart.component";
import { InsightsMetricService } from "../../../../services/insights-metric.service";

@Component({
    selector: "line-chart",
    templateUrl: "./line-chart.component.html",
})
export class LineChartComponent implements OnChanges {
    @ViewChild("chart", { static: true }) chart: ChartComponent;
    @Input() labels: string[];
    @Input() datasets: ChartDataset<"line">[];
    @Input() unit = "";

    config: ChartConfiguration = {
        type: "line",
        data: {
            datasets: [],
        },
        options: {
            layout: {
                padding: {
                    right: 15,
                },
            },
            datasets: {
                line: {
                    tension: 0.4,
                },
            },
            scales: {
                x: {
                    offset: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        padding: 10,
                    },
                },
                y: {
                    offset: true,
                    suggestedMin: 0,
                    grid: {
                        borderDash: [8, 4],
                        drawBorder: false,
                    },
                    ticks: {
                        callback: (value) => InsightsMetricService.formatMetric(value, this.unit),
                        padding: 10,
                    },
                },
            },
            interaction: {
                mode: "index",
            },
            elements: {
                point: {
                    hoverRadius: 8,
                    hoverBorderWidth: 5,
                    hitRadius: 5,
                    borderWidth: 1,
                },
            },
            plugins: {
                tooltip: {
                    position: "nearest",
                    callbacks: {
                        label: (context: TooltipItem<"line">): string => {
                            const datasetLabel = context.dataset.label;
                            const metric = context.parsed.y;
                            const formattedMetric = InsightsMetricService.formatMetric(metric, this.unit);

                            return `  ${datasetLabel}: ${formattedMetric}`;
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    align: "start",
                    labels: {
                        usePointStyle: true,
                        padding: 20,
                        generateLabels: (chart: any): LegendItem[] => {
                            const datasetsToShow = chart.data.datasets.slice(0, -1);
                            const datasetMetasToShow = chart._getSortedDatasetMetas().slice(0, -1);
                            const {
                                labels: { usePointStyle, pointStyle, textAlign, color },
                            } = chart.legend.options;

                            return datasetMetasToShow.map((meta) => {
                                const style = meta.controller.getStyle(usePointStyle ? 0 : undefined);
                                return {
                                    text: datasetsToShow[meta.index].label,
                                    fillStyle: style.backgroundColor,
                                    fontColor: color,
                                    hidden: !meta.visible,
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    lineWidth: 0,
                                    strokeStyle: "",
                                    pointStyle: pointStyle || style.pointStyle,
                                    rotation: style.rotation,
                                    textAlign: textAlign || style.textAlign,
                                    borderRadius: 0,
                                    datasetIndex: meta.index,
                                };
                            }, this);
                        },
                    },
                },
            },
        },
    };

    ngOnChanges(changes: SimpleChanges) {
        const { labels, datasets } = changes;

        if (labels) {
            this.config.data.labels = labels.currentValue;
        }

        if (datasets) {
            this.config.data.datasets = datasets.currentValue ?? [];
        }

        if (this.chart) {
            this.chart.update();
        }
    }
}
