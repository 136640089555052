<div class="replace-user-container">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>
    <h2 class="replace-user-control-heading">Bulk change</h2>
    <h3 class="replace-user-control-subheading">Update options</h3>
    <div class="replace-user-control-subheading-message">
        <span>Select the replacement options for this change</span>
    </div>
    <div class="replace-user-control-container">
        <form [formGroup]="form">
            <div class="replace-user-controls-table">
                <div class="replace-user-controls-col">
                    <div class="replace-user-controls-row">
                        <div class="replace-user-controls-col-header">
                            <h3>Accounts</h3>
                            <div class="replace-user-controls-col-message">
                                <span>Select the accounts for this update</span>
                            </div>
                        </div>
                    </div>
                    <div class="replace-user-controls-row">
                        <div class="replace-user-controls-col-content">
                            <mat-form-field
                                appearance="outline"
                                placeholder=""
                                class="replace-user-select-filter"
                            >
                                <mat-select
                                    [formControl]="form.controls.selectedAccountIds"
                                    placeholder="Select Accounts"
                                    multiple
                                    [disableOptionCentering]="true"
                                    panelClass="mat-select-panel"
                                >
                                    <mat-select-trigger>
                                        <span>
                                            {{
                                                allAccountsSelected.selected
                                                    ? "All"
                                                    : form.controls.selectedAccountIds.value?.length
                                            }}
                                        </span>
                                        <span>{{
                                            form.controls.selectedAccountIds.value?.length > 1
                                                ? " accounts"
                                                : " account"
                                        }}</span>
                                    </mat-select-trigger>
                                    <mat-option
                                        #allAccountsSelected
                                        [value]="selectAllOption.key"
                                        (click)="toggleSelectAll()"
                                    >
                                        Select all
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let account of accounts$ | async"
                                        [value]="account.key"
                                        (click)="toggleOneItem()"
                                    >
                                        {{ account.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div
                        *ngIf="!form.controls.selectedAccountIds.value?.length"
                        class="replace-user-control-subheading-warning"
                    >
                        <i
                            placement="top"
                            class="las la-exclamation-circle info-icon"
                        ></i>
                        <span>No accounts selected</span>
                    </div>
                </div>
                <div class="replace-user-controls-col">
                    <div class="replace-user-controls-row">
                        <div class="replace-user-controls-col-header">
                            <h3>User</h3>
                            <div class="replace-user-control-subheading-message">
                                <span>Select the user who will be the replacement</span>
                            </div>
                        </div>
                    </div>
                    <div class="replace-user-controls-col-content">
                        <add-participant
                            label=""
                            formControlName="newUsers"
                        ></add-participant>
                    </div>
                </div>
                <div class="replace-user-controls-col">
                    <div class="replace-user-controls-row">
                        <div class="replace-user-controls-col-header">
                            <h3>Options</h3>
                            <div class="replace-user-controls-col-message">
                                <span>Select the conditions for this update</span>
                            </div>
                        </div>
                    </div>
                    <div class="replace-user-controls-row">
                        <div class="replace-user-controls-col-content">
                            <fx-checkbox formControlName="updateActiveWorkflows"
                                >Replace assignee in active workflows</fx-checkbox
                            >
                            <fx-checkbox formControlName="updateConfigurations"
                                >Replace assignee in workflow configurations</fx-checkbox
                            >
                            <fx-checkbox
                                *ngIf="form.value?.updateActiveWorkflows"
                                formControlName="keepAsParticipant"
                                >Keep as participant on active workflows</fx-checkbox
                            >
                        </div>
                    </div>
                </div>
            </div>
            <button
                class="fx-btn fx-btn--primary replace-user-confirm-btn"
                (click)="confirmBulkAndReplace()"
                [disabled]="!form.valid"
            >
                Update
            </button>
        </form>
    </div>
    <div
        *ngIf="form.value?.updateActiveWorkflows && form.value?.selectedAccountIds?.length"
        class="replace-user-workflows-container"
    >
        <bulk-replace-user-workflows
            [formControl]="form.controls.excludeThreadIds"
            [newUserIds]="form.value.newUsers"
            [selectedAccountIds]="form.controls.selectedAccountIds.value"
            [totalUserActiveWorkflows]="totalUserActiveWorkflows$ | async"
        ></bulk-replace-user-workflows>
    </div>
    <div
        *ngIf="form.value?.updateConfigurations && form.value?.selectedAccountIds?.length"
        class="replace-user-configurations-container"
    >
        <bulk-replace-user-configurations
            [formControl]="form.controls.excludeConfigurationIds"
            [newUserIds]="form.value.newUsers"
            [selectedAccountIds]="form.controls.selectedAccountIds.value"
            [totalUserConfigurations]="totalUserConfigurations$ | async"
        ></bulk-replace-user-configurations>
    </div>
</div>
