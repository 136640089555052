import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Entity } from "@visoryplatform/openmeasures-core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OpenMeasuresService } from "../../services/open-measures.service";
import { Router } from "@angular/router";

enum InsightsAccountTableHeaders {
    Entity = "Entity",
}

@Component({
    selector: "app-insights-account-listing",
    templateUrl: "./insights-account-listing.component.html",
    styleUrls: ["./insights-account-listing.component.scss"],
})
export class InsightsAccountListingComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly tableHeaders = InsightsAccountTableHeaders;

    loader = new Loader();
    tableData = new MatTableDataSource<Entity>();

    constructor(private openMeasures: OpenMeasuresService, private router: Router) {}

    async ngOnInit() {
        this.loader.show();

        const enterprises = await this.openMeasures.getEnterprises().toPromise();

        const entities$ = enterprises.map(async (enterprise) => {
            const entities = await this.openMeasures.getEntities(enterprise.id).toPromise();
            return entities.map((entity) => ({
                ...entity,
                label: `${enterprise.label} - ${entity.label}`,
            }));
        });

        const entities = await Promise.all(entities$);
        this.tableData.data = [].concat(...entities);

        if (this.tableData.data?.length === 1) {
            const entity = this.tableData.data[0];
            this.router.navigate(["/insights/scorecard", entity.enterpriseId, "entity", entity.id]);
        }

        this.loader.hide();
    }
}
