import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { MenuItemComponent } from "./components/menu-item//menu-item.component";
import { RouterModule } from "@angular/router";

const exportDeclarations = [SideMenuComponent, MenuItemComponent];

@NgModule({
    imports: [CommonModule, RouterModule],
    exports: [...exportDeclarations],
    declarations: [...exportDeclarations],
})
export class FindexMenuModule {}
