<section class="section">
    <lib-calendar-header></lib-calendar-header>

    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div
        *ngIf="eventsGroups$ | async as eventsGroups"
        class="agenda"
    >
        <div
            *ngFor="let eventGroup of eventsGroups"
            class="agenda-group"
        >
            <div
                *ngIf="eventGroup"
                class="agenda-group-day"
            >
                <div class="agenda-group-day-date">{{ eventGroup.date | date : "EEE d" }}</div>

                <div class="agenda-group-day-events">
                    <div *ngFor="let event of eventGroup.events">
                        <div *ngIf="$any(event.id).includes('milestone')">
                            <lib-calendar-milestone
                                [title]="event.title"
                                [type]="event.meta.type"
                                [color]="event.color.primary"
                                [backgroundColor]="event.color.secondary"
                                [tuiHint]="
                                    (event.meta.type | valueToFriendlyName) + ' - ' + event.meta.account.label
                                        | titlecase
                                "
                                tuiHintDirection="top"
                                (milestoneClicked)="onCalendarEventClicked(event)"
                            >
                            </lib-calendar-milestone>
                        </div>

                        <div *ngIf="$any(event.id).includes('meeting')">
                            <lib-calendar-meeting
                                [title]="event.title"
                                [start]="event.start"
                                [color]="event.color.primary"
                                (calendarMeetingClicked)="onCalendarMeetingClicked(event.meta)"
                            >
                            </lib-calendar-meeting>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
