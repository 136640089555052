import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ViewportObserverService } from "../services/viewport-observer.service";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NotificationsService } from "../services/notifications.service";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { IThread, IThreadCard } from "@visoryplatform/threads";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";

interface IMarkCardAsSeen {
    thread: IThread;
    card: IThreadCard;
    participantId: string;
}

@Directive({
    selector: "[notificationChannel]",
})
export class NotificationChannelDirective implements OnChanges, OnDestroy {
    @Input("notificationChannel") channel: string;
    @Input("enableMarkAsResolved") enableMarkAsResolved: boolean;
    @Input("enableMarkAsSeen") enableMarkAsSeen: boolean;
    @Input("markAsSeen") markAsSeen: IMarkCardAsSeen;

    private readonly isInView$: Observable<boolean>;
    private observerSub: Subscription;
    private markAsSeenSub: Subscription;
    private processedChannel: boolean;

    constructor(
        private viewPortObserver: ViewportObserverService,
        private elm: ElementRef,
        private notificationService: NotificationsService,
        private threadsWebsocketService: ThreadsWebsocketService,
        private uiCardService: UiCardService,
    ) {
        this.isInView$ = this.viewPortObserver.observe(this.elm).pipe(filter((visible) => !!visible));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { channel, enableMarkAsResolved, markAsSeen, enableMarkAsSeen } = changes;
        if (channel || enableMarkAsResolved || markAsSeen || enableMarkAsSeen) {
            this.processedChannel = false;
        }

        if (this.isInView$ && this.channel && this.enableMarkAsResolved) {
            this.initChannel(this.channel);
        }

        if (this.isInView$ && this.markAsSeen && this.enableMarkAsSeen) {
            this.initMarkAsSeen(this.markAsSeen);
        }
    }

    ngOnDestroy(): void {
        this.observerSub?.unsubscribe();
        this.markAsSeenSub?.unsubscribe();
    }

    private initChannel(channel: string): void {
        this.observerSub?.unsubscribe();

        if (!channel) {
            return;
        }

        // If either isInView or notifications$ emit, check if isInView, then mark notifications
        this.observerSub = this.isInView$.subscribe(() => {
            this.markNotificationsAsRead(channel);
        });
    }

    private initMarkAsSeen(markAsSeen: IMarkCardAsSeen): void {
        this.markAsSeenSub?.unsubscribe();

        if (!markAsSeen) {
            return;
        }

        // If card isInView then mark card as seen
        this.markAsSeenSub = this.isInView$.subscribe(() => {
            this.markCardAsSeen(markAsSeen);
        });
    }

    private markNotificationsAsRead(channel: string): void {
        if (!channel) {
            return;
        }

        if (!this.processedChannel) {
            this.notificationService.markAsRead(this.channel);
            this.processedChannel = true;
        }
    }

    private markCardAsSeen({ thread, card, participantId }: IMarkCardAsSeen): void {
        const isCardUnread = this.uiCardService.isCardUnseenByCurrentUser(thread, card, participantId);

        if (!isCardUnread) {
            return;
        }

        this.threadsWebsocketService.markAsSeen(thread.id, card.id, participantId);
    }
}
