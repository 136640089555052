import { Pipe, PipeTransform } from "@angular/core";
import { IRequestFileData, Role } from "@visoryplatform/threads";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({ name: "canDeleteAttachment" })
export class CanDeleteAttachmentPipe implements PipeTransform {
    constructor(private permissionService: PermissionService) {}

    transform(attachment: IRequestFileData, userId: string, role: Role, overrideDelete = false): Observable<boolean> {
        const isFileOwner = !attachment?.actorId ? true : attachment.actorId === userId;
        const hasPermission = this.permissionService.checkPermissions(role, "EditVaultFiles");
        return hasPermission.pipe(map((canEdit) => overrideDelete && (canEdit || isFileOwner)));
    }
}
