import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "findex-slide-menu",
    templateUrl: "./findex-slide-menu.component.html",
    styleUrls: ["./findex-slide-menu.component.scss"],
})
export class FindexSlideMenuComponent {
    @Input() open: boolean;
    @Output() close = new EventEmitter<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
}
