<div class="user-profile">
    <form class="fx-form fx-form--dark">
        <div class="user-profile-header">
            <fx-avatar
                class="user-profile-header-avatar"
                [name]="avatarNameString"
                [image]="userId | profilePicture | async"
                size="medium"
            >
            </fx-avatar>
            <div class="user-profile-header-name">
                {{ avatarNameString }}
            </div>
        </div>
    </form>

    <fx-tabs
        *ngIf="extensions$ | async as extensions"
        class="user-profile-tabs"
        [mobileScroll]="true"
        #tabs
    >
        <fx-tabs-item
            header="Profile"
            #profileTab
            [analyticsClick]="gaEvents.PROFILE_TAB_DETAILS"
            [analyticsTriggerOn]="profileTab.active"
            (selected)="navigateTo('details')"
        >
        </fx-tabs-item>

        <fx-tabs-item
            *ngFor="let extension of extensions; trackBy: 'id' | trackProperty"
            #extensionTab
            [header]="extension.label"
            analyticsClick="profile_tab{{ extension.id }}"
            [analyticsTriggerOn]="extensionTab.active"
            (selected)="navigateTo(extension.path)"
        >
        </fx-tabs-item>

        <fx-tabs-item
            header="Notifications"
            #notificationsTab
            [analyticsClick]="gaEvents.PROFILE_TAB_NOTIFICATIONS"
            [analyticsTriggerOn]="notificationsTab.active"
            (selected)="navigateTo('notifications')"
        >
        </fx-tabs-item>
    </fx-tabs>

    <!-- We need to take child route scenarios into consideration when designing UI components in FX-UI. 
        Angular Material tabs are flexible enough to support this without repeating ng-content/ngIfs or using the content outside the tabs.
        RouterLink also do not work on the header -->
    <div class="account-tabs-container">
        <div class="account-tabs-content">
            <router-outlet></router-outlet>
        </div>

        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>
    </div>
</div>
