<div
    class="account-dropdown"
    *ngIf="accounts?.length > 1"
>
    <label class="fx-form-label">Account:</label>
    <div class="v-select-container">
        <v-select
            placeholder="Select an account"
            [options]="accounts"
            [optionContent]="accountDropdownOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form"
        >
        </v-select>

        <ng-template
            #accountDropdownOptionContent
            let-option
        >
            {{ option.label }}
        </ng-template>
    </div>
    <div
        class="account-dropdown-subtitle"
        *ngIf="!form.value"
    >
        Please select the account this request relates to.
    </div>
</div>
