<div class="insights-reporting">
    <ng-container *ngIf="reportGroups$ | async; let reportGroups">
        <ng-container *ngIf="reportGroups.length === 0">
            <div class="insights-reporting-empty">
                <i class="insights-reporting-empty-icon las la-exclamation-circle"></i>
                <div class="insights-reporting-empty-header">No Reports are available yet</div>
                <div class="insights-reporting-empty-body">
                    Coming soon! Your monthly Insight Reports will appear here to view and download.
                    <div
                        *ngIf="!isMobileView"
                        class="insights-reporting-empty-image"
                    >
                        <img src="{{ emptyStateImage }}" />
                    </div>
                    <div
                        *ngIf="isMobileView"
                        class="insights-reporting-empty-link"
                    >
                        <a
                            [routerLink]="['/dashboard']"
                            analyticsClick="app_viewdashboard"
                            >View Dashboard</a
                        >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="reportGroups.length !== 0">
            <div
                *ngIf="!isMobileView"
                class="insights-reporting-report"
            >
                <insights-report
                    class="insights-reporting-report"
                    (downloadReport)="downloadReport($event)"
                    [selectedReport]="reportToShow"
                    [selectedVaultFile]="reportFile$ | async"
                    [loading]="loader.counter !== 0"
                ></insights-report>
            </div>
            <div class="insights-reporting-list">
                <insights-report-list
                    class="insights-reporting-list"
                    [selectedReport]="reportToShow"
                    [reportGroups]="reportGroups"
                    (reportSelection)="selectInsightsReport($event)"
                    (downloadReport)="downloadReport($event)"
                ></insights-report-list>
            </div>
        </ng-container>
    </ng-container>
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>
</div>
