import { Injectable, Injector } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { COPILOT_TRANSACTIONS_CARD_TYPE } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { CreateTransactionQueriesComponent } from "./components/create-transaction-queries/create-transactions-queries.component";
import { CopilotActions } from "./types/CopilotActions";
import { TransactionCardComponent } from "./components/transactions-card/transactions-card.component";
import { TransactionsActionComponent } from "./components/transactions-action/transactions-action.component";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { UpdateTransactionQueriesComponent } from "./components/update-transaction-queries/update-transactions-queries.component";

type TransactionRequestOptions = { focusReplyInput: boolean };

async function viewTransactionActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    options: TransactionRequestOptions,
): Promise<boolean> {
    const dialogService = injector.get(DialogService);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const focusReplyInput = options?.focusReplyInput || false;
    const data = { state$, thread$, replies$, card$, role, readonly: false, focusReplyInput };

    const config = {
        data,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return dialogService.open<boolean>(TransactionsActionComponent, config).toPromise();
}

async function editTransactionActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    _options: void,
): Promise<boolean> {
    const dialogService = injector.get(DialogService);

    const config = {
        data: cardResources,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return dialogService.open<boolean>(UpdateTransactionQueriesComponent, config).toPromise();
}

@Injectable()
export class CopilotPlugin implements IPluginFactory {
    readonly id = "CopilotPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(private libraries: Libraries) {
        this.registerExtensions();
    }

    private registerExtensions(): void {
        const viewTransactionsAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.COPILOT_TRANSACTIONS_VIEW],
            cardTypes: [COPILOT_TRANSACTIONS_CARD_TYPE],
            action: viewTransactionActionFactory,
            buttonLabel: "View",
            statusIcon: "las la-check-square",
        };

        const editTransactionAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.COPILOT_TRANSACTIONS_EDIT],
            cardTypes: [COPILOT_TRANSACTIONS_CARD_TYPE],
            action: editTransactionActionFactory,
            buttonLabel: "Edit",
            statusIcon: "las la-check-square",
        };

        this.libraries.cardViews.register(COPILOT_TRANSACTIONS_CARD_TYPE, TransactionCardComponent);
        this.libraries.taskActions.register(CopilotActions.ViewTransactionQueries, viewTransactionsAction);
        this.libraries.taskActions.register(CopilotActions.EditTransactionQueries, editTransactionAction);
        this.libraries.createCard.register(COPILOT_TRANSACTIONS_CARD_TYPE, {
            title: "Transactions",
            tooltipMessage: "Ask for more information about transactions",
            analyticsEvent: this.gaEvents.APP_CREATE_TRANSACTIONS_CARD,
            permission: ["CreateCoilotTransactionsCard"],
            featureFlags: [LaunchDarklyFeatureFlags.EnableTransactionQueries],
            icon: "la-coins",
            disableInternalCreation: true,
            componentRef: CreateTransactionQueriesComponent,
            config: {
                panelClass: ["centered-modal"],
                disableClose: false,
                width: "761px",
            },
        });
    }
}
