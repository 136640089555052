<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div
    style="display: flex"
    *ngIf="answer.disabled === true"
>
    <strong>Answer:&nbsp;</strong>
    <quill-view
        *ngIf="answer.value"
        [content]="answer.value"
        aria-label="Answer"
    ></quill-view>
</div>

<div
    *ngIf="answer.enabled === true"
    class="answer-controls"
>
    <div class="editor">
        <quill-editor-wrapper
            [formControl]="answer"
            [autoFocusOnInit]="!isMobileView"
            [messageSizeQuotaInKB]="messageSizeQuotaInKB"
            (error)="quillError = $event"
        ></quill-editor-wrapper>
    </div>

    <file-input
        *ngIf="!vaultDetails?.filename"
        (file)="uploadFile.next($event)"
    ></file-input>
</div>

<div
    *ngIf="vaultDetails?.filename"
    class="file-download-url"
>
    <div>
        <span style="font-weight: bold">File/s uploaded:&nbsp;</span>

        <span
            class="filename"
            (click)="download(vaultDetails)"
        >
            {{ vaultDetails?.filename }}
        </span>
    </div>

    <div
        *ngIf="!answer.disabled"
        class="file-download-delete"
        (click)="deleteFile.emit()"
    >
        <i class="las la-times icon-size"></i>
        Remove
    </div>
</div>

<div
    *ngIf="answer.disabled === true && (answer.value || vaultDetails?.filename)"
    (click)="clearAnswer.emit()"
>
    <i class="las la-times icon-size"></i>
    Clear response
</div>
