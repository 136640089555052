<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>

<sidebar-modal-layout
    header="{{ requestType === requestTypes.Expert ? 'New Expert request' : 'New request' }}"
    (close)="close()"
>
    <stepper-layout
        *ngIf="isStepperVisible$ | async"
        [linear]="true"
        endStepLabel="Submit"
        (completed)="create()"
        (cancel)="close()"
        [disabled]="!!loader.counter"
        [disabledNext]="invalid$ | async"
        #stepper
    >
        <stepper-step
            label=""
            [completed]="true"
            [hideNavigationButtons]="true"
            [showBreadcrumbs]="false"
        >
            <div class="customer-request-inner-container">
                <div class="customer-request-body">
                    <div class="customer-request-subtitle">
                        Create a new request if you need assistance with a <b>new task or issue</b> that's
                        <b>not already part of your active workflows.</b>
                    </div>

                    <div class="customer-request-options-title">Choose your preferred option:</div>

                    <div
                        class="customer-request-options"
                        *ngIf="customerButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-paper-plane"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Create request</div>
                                    <div class="customer-request-option-description">
                                        Send a platform message instantly.
                                    </div>
                                </div>
                            </div>

                            <button
                                class="fx-btn fx-btn--primary customer-request-option-button"
                                (click)="goToCustomerCreate()"
                            >
                                <i class="las la-paper-plane"></i>
                                Create now
                            </button>
                        </div>
                    </div>

                    <div
                        class="customer-request-options"
                        *ngIf="expertButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-paper-plane"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Create Expert request</div>
                                    <div class="customer-request-option-description">
                                        Send a platform message instantly.
                                    </div>
                                </div>
                            </div>

                            <button
                                class="fx-btn fx-btn--primary customer-request-option-button"
                                (click)="goToExpertCreate()"
                            >
                                <i class="las la-paper-plane"></i>
                                Create now
                            </button>
                        </div>
                    </div>

                    <div
                        class="customer-request-options"
                        *ngIf="phoneButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-phone"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Phone</div>
                                    <div class="customer-request-option-description">
                                        Chat to our team 9am to 5pm AEDST.
                                    </div>
                                </div>
                            </div>

                            <div class="customer-request-option-link">
                                Call <a href="tel:+1800847679">1800 VISORY</a>
                            </div>
                        </div>
                    </div>

                    <div
                        class="customer-request-options"
                        *ngIf="emailButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-envelope"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Email</div>
                                    <div class="customer-request-option-description">Send us an email.</div>
                                </div>
                            </div>

                            <div class="customer-request-option-link">
                                Email <a href="mailto: support@visory.com.au">support&#64;visory.com.au</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </stepper-step>

        <stepper-step
            label="Tell us about your request"
            [completed]="true"
            [showBreadcrumbs]="false"
            [disableNextOnClick]="true"
            nextStepLabel="Create"
            (next)="create()"
            (previous)="goBackToOptions()"
        >
            <div class="customer-request-inner-container">
                <customer
                    *ngIf="requestType === requestTypes.Customer"
                    [accounts]="accounts$ | async"
                    [formControl]="form"
                ></customer>
                <expert
                    *ngIf="requestType === requestTypes.Expert"
                    [accounts]="accounts$ | async"
                    [formControl]="form"
                ></expert>
            </div>
        </stepper-step>

        <stepper-step
            label="Request submited"
            [completed]="true"
            [hideFooter]="true"
            [showBreadcrumbs]="false"
        >
            <create-success
                [thread]="createdThread"
                (close)="close()"
                (goToCreate)="goBackToCreate()"
            ></create-success>
        </stepper-step>
    </stepper-layout>
</sidebar-modal-layout>
