<div class="request-response">
    <ng-container *ngIf="!showCompose && message?.length">
        <fx-avatar
            class="request-response-avatar"
            [name]="modifiedBy | userToName | async"
            [image]="modifiedBy | profilePicture | async"
            size="small"
        ></fx-avatar>
        <div class="request-response-details">
            <div class="request-response-actor">
                <div class="request-response-name">{{ modifiedBy | userToName | async }}</div>
                <div class="request-response-time">{{ modifiedAt | timeAgo }}</div>
            </div>
            <quill-view-html
                class="request-response-value"
                [content]="message"
            ></quill-view-html>
        </div>
    </ng-container>
    <div
        class="request-response-edit"
        *ngIf="showCompose && !readonly"
    >
        <div class="request-response-input">
            <quill-editor-wrapper
                [formControl]="editResponse"
                [inline]="true"
                (error)="quillError = $event"
            >
            </quill-editor-wrapper>
        </div>

        <reply-buttons
            (discardOutput)="discard()"
            (submitOutput)="submitResponse()"
            [submitDisabled]="!editResponse.value || editResponse.value === message || quillError"
        >
        </reply-buttons>
    </div>
</div>
<div
    class="request-response-buttons"
    *ngIf="!showCompose && message && !readonly && modifiedByCurrentUser"
>
    <div
        class="request-response-buttons-edit"
        (click)="editMessage()"
    >
        Edit
    </div>
    <div
        class="request-response-buttons-edit"
        (click)="deleteMessage()"
    >
        Delete
    </div>
</div>
