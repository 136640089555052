<div class="insights-accounts">
    <div class="insights-accounts-header">
        <div>
            <h3>Insights</h3>
            <div>Financial performance overview and management reporting</div>
        </div>
    </div>

    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div class="insights-accounts-table">
        <fx-table
            [tableData]="tableData"
            [trackBy]="'id' | trackProperty"
        >
            <ng-container
                *fxColumn="
                    'entity';
                    label: tableHeaders.Entity;
                    sortable: true;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_TITLE;
                    let data
                "
            >
                <div class="threads-title">
                    <a
                        [routerLink]="['/insights/scorecard', data.enterpriseId, 'entity', data.id]"
                        [analyticsClick]="gaEvents.DASHBOARD_TIMELINE"
                    >
                        <span>
                            {{ data.label }}
                        </span>
                    </a>
                </div>
            </ng-container>
        </fx-table>
    </div>
</div>
