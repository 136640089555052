import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ReadStatus } from "@visoryplatform/threads";
import { GA_EVENTS } from "../../../analytics";
import { UiCardService } from "../../../threads-ui/services/ui-card.service";

@Component({
    templateUrl: "card-read-status.component.html",
    selector: "card-read-status",
    styleUrls: ["card-read-status.component.scss"],
})
export class CardReadStatusComponent implements OnChanges {
    @Input() readStatus: ReadStatus;

    readonly gaEvents = GA_EVENTS;

    isResolved: boolean;
    shouldShowHint = false;

    constructor(private uiCardService: UiCardService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { readStatus } = changes;

        if (readStatus) {
            this.isResolved = this.uiCardService.isStatusResolved(this.readStatus);
        }
    }

    toggleShowHint(): void {
        this.shouldShowHint = !this.shouldShowHint;
        setTimeout(() => {
            this.shouldShowHint = false;
        }, 3000);
    }
}
