import { Component, Inject, OnInit } from "@angular/core";
import { IPaginated } from "@visoryplatform/datastore-types";
import {
    ICardTaskDetail,
    InternalRoles,
    ITimeline,
    Role,
    ThreadFilters,
    WebsocketNotification,
} from "@visoryplatform/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { PortalService } from "projects/portal-modules/src/lib/shared/services/portal.service";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { TableThreadListing } from "projects/portal-modules/src/lib/threads-ui/services/threads-enrichment.service";
import { BehaviorSubject, combineLatest, merge, Observable, of } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { VBadgeColors } from "@visoryplatform/portal-ui";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { AssignedTo } from "../../constants/dashboard.constants";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { taskChannelPrefix } from "projects/portal-modules/src/lib/environment/environment.common";
import { TaskNotificationsService } from "projects/portal-modules/src/lib/notifications";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { SearchableThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/searchable-threads.service";

export type ThreadListingJoinTask = TableThreadListing | ICardTaskDetail;

@Component({
    selector: "dashboard-timelines-paginated",
    templateUrl: "./dashboard-timelines-paginated.component.html",
    styleUrls: ["./dashboard-timelines-paginated.component.scss"],
})
export class DashboardTimelinesPaginatedComponent implements OnInit {
    loader = new Loader();
    role$: Observable<Role>;
    assignedToSource$ = new BehaviorSubject<AssignedTo>(AssignedTo.You);
    assignedTo = AssignedTo.You;
    userId$: Observable<string>;
    assignedToYouTotal$: Observable<string>;
    assignedToOthersTotal$: Observable<string>;
    badgeColors = VBadgeColors;
    threadsPaginator: Paginator<TableThreadListing>;
    tasksPaginator: Paginator<ICardTaskDetail>;

    canGoNext$: Observable<boolean>;
    canGoBack$: Observable<boolean>;
    canViewDashboardTasks$: Observable<boolean>;

    threadsJoinTasks$: Observable<ThreadListingJoinTask[]>;

    readonly gaEvents = GA_EVENTS;
    protected readonly AssignedTo = AssignedTo;

    private readonly pageSize = 10;

    constructor(
        @Inject(TASK_ACTION_LIBRARY) private taskActionLibrary: ILibrary<TaskAction<void>>,
        private authService: AuthService,
        private portalService: PortalService,
        private threadsWebsocketService: ThreadsWebsocketService,
        private taskNotificationService: TaskNotificationsService,
        private notificationService: NotificationsService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        private searchableThreadsService: SearchableThreadsService,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getUser().pipe(filter((user) => !!user));

        this.userId$ = user$.pipe(
            map((user) => user.id),
            shareReplay(1),
        );

        this.role$ = user$.pipe(
            map((user) => user.globalRole || Role.Client),
            shareReplay(1),
        );

        this.canViewDashboardTasks$ = this.getCanViewDashboardTasks(this.role$).pipe(shareReplay(1));

        this.threadsPaginator = new Paginator<TableThreadListing>(this.pageSize);
        this.tasksPaginator = new Paginator<ICardTaskDetail>(this.pageSize);

        const tasksPaginated$ = this.tasksPaginator.wrap((page) => this.getAssignedToCardTasks(this.assignedTo, page));
        const threadsPaginated$ = this.threadsPaginator.wrap((page) => this.getThreadListing(page, this.assignedTo));

        const canGoBack$ = combineLatest([this.threadsPaginator.canGoBack$, this.tasksPaginator.canGoBack$]);
        this.canGoBack$ = canGoBack$.pipe(map((values) => values.some((value) => value)));

        const canGoNext$ = combineLatest([this.threadsPaginator.canGoNext$, this.tasksPaginator.canGoNext$]);
        this.canGoNext$ = canGoNext$.pipe(map((values) => values.some((value) => value)));

        this.threadsJoinTasks$ = combineLatest([threadsPaginated$, tasksPaginated$]).pipe(
            map(([threads, tasks]) => [...threads, ...tasks]),
        );

        this.assignedToYouTotal$ = this.userId$.pipe(
            switchMap((userId) => this.getThreadListTotal(userId, AssignedTo.You)),
        );
        this.assignedToOthersTotal$ = this.userId$.pipe(
            switchMap((userId) => this.getThreadListTotal(userId, AssignedTo.Others)),
        );
    }

    setAssignedTo(assignedTo: AssignedTo): void {
        this.assignedTo = assignedTo;

        this.tasksPaginator.refresh((page) => this.getAssignedToCardTasks(assignedTo, page));
        this.threadsPaginator.refresh((page) => this.getThreadListing(page, assignedTo));
    }

    goBackPage(): void {
        this.threadsPaginator.goBack();
        this.tasksPaginator.goBack();
    }

    goNextPage(): void {
        this.threadsPaginator.goNext();
        this.tasksPaginator.goNext();
    }

    private getThreadListing(page: string, assignedTo: AssignedTo): Observable<IPaginated<TableThreadListing>> {
        const userId$ = this.userId$.pipe(shareReplay(1));
        const threadList$ = this.loader.wrap(this.getThreadsPaginated(page, assignedTo));
        const threadListUpdates$ = userId$.pipe(
            switchMap((userId) => {
                return this.searchableThreadsService
                    .threadListUpdates(page, userId)
                    .pipe(switchMap(() => this.getThreadsPaginated(page, assignedTo)));
            }),
        );

        return merge(threadList$, threadListUpdates$).pipe(
            switchMap((listing) => this.searchableThreadsService.getListingCreatedUpdates(userId$, listing)),
            switchMap((listing) => this.searchableThreadsService.getThreadUpdates(listing)),
            map((listing) => this.searchableThreadsService.getEnrichedListings(listing)),
            shareReplay(1),
        );
    }

    private getAssignedToCardTasks(
        assignedTo: AssignedTo,
        page: string,
        pageSize = this.pageSize,
    ): Observable<IPaginated<ICardTaskDetail>> {
        return this.canViewDashboardTasks$.pipe(
            switchMap((canViewTasks) => {
                if (!canViewTasks || assignedTo === AssignedTo.Others) {
                    return of({ result: [], next: null });
                }
                return this.getThreadTasks(page, pageSize);
            }),
        );
    }

    private getThreadTasks(page: string, pageSize: number): Observable<IPaginated<ICardTaskDetail>> {
        const paginatedCardTasks$ = this.getPaginatedCardTasks(page, pageSize);

        const updates$ = this.notificationService
            .subscribeToChannel(taskChannelPrefix)
            .pipe(switchMap(() => this.getPaginatedCardTasks(page, pageSize)));

        const taskUpdates$ = merge(paginatedCardTasks$, updates$).pipe(shareReplay(1));

        return taskUpdates$;
    }

    private getPaginatedCardTasks(page: string, pageSize: number): Observable<IPaginated<ICardTaskDetail>> {
        return this.taskNotificationService
            .getPaginatedCardTasks(page, pageSize)
            .pipe(map((paginated) => this.mapPaginatedCardTaskLabels(paginated)));
    }

    private mapPaginatedCardTaskLabels(paginated: IPaginated<ICardTaskDetail>): IPaginated<ICardTaskDetail> {
        return {
            ...paginated,
            result: paginated.result.map((result) => {
                const resolvedTask = this.taskActionLibrary.resolve(result.taskId);

                return {
                    ...result,
                    taskLabel: resolvedTask?.buttonLabel ?? result.taskLabel,
                };
            }),
        };
    }

    private getThreadsPaginated(
        currentPage: string,
        assignedTo: AssignedTo,
        pageSize = this.pageSize,
    ): Observable<IPaginated<ITimeline>> {
        return combineLatest([this.role$, this.userId$]).pipe(
            take(1),
            switchMap(([userRole, userId]) => {
                const params = this.getSearchableThreadParams(assignedTo, userRole, userId);
                return this.portalService.getDashboardSearchThreadList(currentPage, pageSize, params);
            }),
        );
    }

    private getSearchableThreadParams(assignedTo: AssignedTo, userRole: Role, userId: string): ThreadFilters {
        if (assignedTo === AssignedTo.Others) {
            return null;
        }

        const isInternal = InternalRoles.includes(userRole);
        if (isInternal) {
            return { internalStepAssignees: [userId] };
        } else {
            return { externalStepAssignees: [userId] };
        }
    }

    private getThreadListTotal(userId: string, assignedTo: AssignedTo): Observable<string> {
        return this.getThreadsPaginated(null, assignedTo, 0).pipe(
            switchMap((paginated) => this.threadListTotalUpdates(userId, assignedTo, paginated)),
            map((listing) => listing.total?.toString()),
        );
    }

    private threadListTotalUpdates(
        userId: string,
        assignedTo: AssignedTo,
        paginatedTimelines: IPaginated<ITimeline>,
    ): Observable<IPaginated<ITimeline>> {
        return this.threadsWebsocketService.watchThreadParticipantId(userId).pipe(
            filter((notification) => this.isThreadListAllUpdateEvents(notification)),
            switchMap(() => this.getThreadsPaginated(null, assignedTo, 0)),
            startWith(paginatedTimelines),
        );
    }

    private isThreadListAllUpdateEvents(event: WebsocketNotification): boolean {
        const deletedEvent = this.searchableThreadsService.isThreadListDeletedEvent(event);
        const updatedEvent = this.searchableThreadsService.isThreadListUpdatedEvent(event);
        return deletedEvent || updatedEvent;
    }

    private getCanViewDashboardTasks(role$: Observable<Role>): Observable<boolean> {
        const readTaskPermission$ = role$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "ReadTask")),
        );

        const enableDashboardTasks$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkflowDashboardTasks,
        );

        return combineLatest([readTaskPermission$, enableDashboardTasks$]).pipe(
            map((values) => values.every((value) => value)),
        );
    }
}
