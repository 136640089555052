<ng-container
    *ngIf="{
        slaTime: slaTime$ | async,
    } as context"
>
    <duration-badge
        *ngIf="context.slaTime !== null && context.slaTime !== undefined"
        [label]="showLabel ? 'Time left' : ''"
        [time]="context.slaTime"
        [colour]="slaColour$ | async"
        hint="The time remaining before this task becomes overdue."
    ></duration-badge>
</ng-container>
