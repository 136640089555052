import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
    name: "timeAgo",
    pure: true,
})
export class TimeAgoPipe implements PipeTransform {
    transform(date: string): string {
        if (date) {
            return DateTime.fromISO(date).toRelative().replace(" ago", "");
        }
        return date;
    }
}
