<div class="card-read-status">
    <ng-container *ngIf="readStatus">
        <i
            (click)="toggleShowHint()"
            [analyticsClick]="gaEvents.APP_SEENUNSEEN"
            [tuiHint]="hintTemplate"
            [tuiHintManual]="shouldShowHint"
            tuiHintDirection="left"
            class="card-read-status-icon las"
            [ngClass]="{ 'la-check-double': isResolved, 'la-check': !isResolved }"
        ></i>

        <ng-template #hintTemplate>
            <ng-container *ngIf="isResolved; else isNotResolved">Seen by everyone</ng-container>
            <ng-template #isNotResolved>
                <ng-container *ngIf="readStatus.resolved.participantIds.length === 0; else seenBy">
                    Delivered
                </ng-container>

                <ng-template #seenBy>
                    Seen by
                    <ng-container *ngFor="let participantId of readStatus.resolved.participantIds; let index = index">
                        {{ participantId | userShortName | async }}
                        {{ index === readStatus.resolved.participantIds.length - 1 ? "" : ", " }}
                    </ng-container>
                </ng-template>
            </ng-template>
        </ng-template>
    </ng-container>
</div>
