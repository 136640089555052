<div class="vault-sign-focus">
    <ng-container *ngIf="!loader.counter && !!vaultItem; else loading">
        <file-preview
            class="vault-sign-focus-content"
            [vaultId]="vaultItem.vaultId"
            [file]="vaultFile"
        ></file-preview>

        <ng-container [ngSwitch]="!!isSigned">
            <form
                class="vault-sign-focus-sign-document-grid"
                *ngSwitchCase="false"
                [formGroup]="signingForm"
            >
                <div class="vault-sign-focus-sign-document-name">
                    <label
                        class="fx-form-label"
                        for="name"
                        >Your name</label
                    >
                    <div class="fx-form-control">
                        <input
                            id="name"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your Name"
                            placeholder="e.g. John Doe"
                            formControlName="name"
                        />
                    </div>
                </div>

                <div class="vault-sign-focus-sign-document-title">
                    <label
                        class="fx-form-label"
                        for="title"
                        >Your title</label
                    >
                    <div class="fx-form-control">
                        <input
                            id="title"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your title"
                            placeholder="e.g. Director"
                            formControlName="title"
                        />
                    </div>
                </div>

                <div class="vault-sign-focus-sign-document-download">
                    <label
                        class="fx-form-label"
                        for="download"
                        >Download file</label
                    >
                    <div class="fx-form-control">
                        <a
                            class="vault-sign-focus-sign-document-download-link"
                            (click)="download()"
                            ><i class="las la-cloud-download-alt"></i>
                            {{ vaultItem.displayName }}
                        </a>
                    </div>
                </div>

                <div class="vault-sign-focus-sign-document-checkbox">
                    <fx-checkbox formControlName="agreed">
                        I am the party intended to sign the above document, or am authorised to sign it on their behalf.
                        I acknowledge that the information in the above document is true and accurate. I agree or am
                        authorised to agree to be bound by the terms referenced or contained in the above document.
                        Together, this dialog box and the above document form the agreement.
                    </fx-checkbox>
                </div>

                <div class="vault-sign-focus-sign-document-submit">
                    <button
                        class="fx-btn fx-btn--primary"
                        role="button"
                        [disabled]="signingForm.invalid || !signingForm.value.agreed || !!signLoader.counter"
                        (click)="acceptAndSign()"
                    >
                        ACCEPT & SIGN
                    </button>
                </div>

                <div
                    class="vault-sign-focus-sign-document-error"
                    *ngIf="errorMessage"
                >
                    <div class="vault-sign-focus-sign-document-error-message">
                        {{ errorMessage }}
                    </div>
                </div>
            </form>
            <div
                *ngSwitchCase="true"
                class="vault-sign-focus-sign-document-grid vault-sign-focus-sign-document-grid--signed"
            >
                <signature-icon
                    [theme]="'vault-sign-focus'"
                    [cursor]="false"
                    class="vault-sign-focus-sign-document-signature"
                >
                    <p>
                        Signed by {{ signer | userToName | async }} <span *ngIf="title">({{ title }})</span>
                    </p>
                    <p>{{ signedOn | date : "hh:mma" | lowercase }}{{ signedOn | date : " E dd MMM yyy" }}</p>
                </signature-icon>

                <div class="vault-sign-focus-sign-document-download">
                    <label
                        class="fx-form-label"
                        for="download"
                        >Download file</label
                    >
                    <div class="fx-form-control">
                        <a
                            class="vault-sign-focus-sign-document-download-link"
                            (click)="download()"
                            ><i class="las la-cloud-download-alt"></i>
                            {{ vaultItem.displayName }}
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #loading>
        <div class="vault-sign-focus-content vault-sign-focus-content--loading">
            <loader-icon
                color="light"
                [static]="true"
            ></loader-icon>
        </div>
    </ng-template>
</div>
