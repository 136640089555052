import { Injectable } from "@angular/core";
import { CalendarAction, CreateCalendarCardType, IThread } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ExtensionMenuModalComponent } from "projects/portal-modules/src/lib/extension-shared/components/extension-menu-modal/extension-menu-modal.component";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { CreateMenuExtensionOption, Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Observable } from "rxjs";
import { VideoCallService } from "../video-chat/services/video-call.service";
import { VideoChatService } from "../video-chat/services/video-chat.service";
import { calendarRescheduleTaskAction, calendarScheduleTaskAction, calendarViewDetails } from "./calendar-task-actions";
import { CalendarBookMeetingComponent } from "./components/calendar-book-meeting/calendar-book-meeting.component";
import { CalendarCardComponent } from "./components/calendar-card/calendar-card.component";
import { CalendarMeetingRequestComponent } from "./components/calendar-meeting-request/calendar-meeting-request.component";
import { CalendarMeetingStartedBannerComponent } from "./components/calendar-meeting-started-banner/calendar-meeting-started-banner.component";

@Injectable()
export class CalendarPlugin implements IPluginFactory {
    readonly id = "CalendarPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(
        libraries: Libraries,
        private videoChatService: VideoChatService,
        private videoCallService: VideoCallService,
    ) {
        this.registerCalendarCardOptions(libraries);
        this.registerCalendarMenu(libraries);
        libraries.cardViews.register("calendar", CalendarCardComponent);
        libraries.taskActions.register(CalendarAction.VIEW_DETAILS, calendarViewDetails);
        libraries.taskActions.register(CalendarAction.SCHEDULE, calendarScheduleTaskAction);
        libraries.taskActions.register(CalendarAction.RESCHEDULE, calendarRescheduleTaskAction);

        libraries.banners.register("meeting-started", {
            componentRef: CalendarMeetingStartedBannerComponent,
        });
    }

    private registerCalendarCardOptions(libraries: Libraries): void {
        libraries.createCard.register(CreateCalendarCardType.Booked, {
            title: "Calendar Book",
            tooltipMessage: "Book a meeting",
            analyticsEvent: this.gaEvents.APP_CREATE_SCHEDULE_MEETING,
            permission: ["CreateCalendarCard"],
            icon: "",
            disableInternalCreation: true,
            data: {
                disableEmails: true,
            },
            hideFromMenu: true,
            componentRef: CalendarBookMeetingComponent,
        });

        libraries.createCard.register(CreateCalendarCardType.Request, {
            title: "Calendar Request",
            tooltipMessage: "Request a meeting",
            analyticsEvent: this.gaEvents.APP_CREATE_REQUEST_MEETING,
            permission: ["CreateCalendarRequestCard"],
            icon: "",
            disableInternalCreation: true,
            data: {
                disableEmails: true,
            },
            hideFromMenu: true,
            componentRef: CalendarMeetingRequestComponent,
        });
    }

    private registerCalendarMenu(libraries: Libraries): void {
        libraries.extensionMenu.register("create-meeting", {
            title: "New Meeting",
            description: "Select one of the meeting options.",
            extensions: this.getCreateMeetingExtensions(),
        });

        libraries.createCard.register("calendar-book", {
            title: "Meeting",
            tooltipMessage: "Book or request a meeting.",
            analyticsEvent: this.gaEvents.APP_CREATEMEETING,
            permission: ["CreateCalendarCard"],
            icon: "la-calendar-alt",
            componentRef: ExtensionMenuModalComponent,
            data: {
                extensionConfig: {
                    extensionId: "create-meeting",
                },
            },
            config: {
                panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
                autoFocus: false,
                disableClose: false,
                backdropClass: "modal-backdrop",
                closeOnNavigation: true,
                maxWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%",
                height: "100vh",
            },
        });
    }

    private getCreateMeetingExtensions(): CreateMenuExtensionOption[] {
        return [
            {
                title: "Request a meeting",
                description:
                    "Create a task for your customer or another staff member to book a meeting at a time that suits all participants",
                analyticsEvent: this.gaEvents.APP_CREATE_REQUEST_MEETING,
                componentRef: CalendarMeetingRequestComponent,
                permission: "CreateCalendarRequestCard",
            },
            {
                title: "Book a meeting",
                description: "Book a meeting on a specified date and time",
                analyticsEvent: this.gaEvents.APP_CREATE_SCHEDULE_MEETING,
                componentRef: CalendarBookMeetingComponent,
                permission: "CreateCalendarCard",
            },
            {
                title: "Start a video call now",
                description: "Start an immediate video call",
                analyticsEvent: this.gaEvents.APP_CREATE_VIDEO_CHAT,
                permission: "CreateVcCard",
                action$: (thread: IThread): Observable<null> => this.videoChatService.createChat(thread.id),
            },
            {
                title: "(BETA) Start video call now",
                description: "Zoom-powered video call",
                analyticsEvent: this.gaEvents.APP_CREATE_VIDEO_CALL,
                permission: "CreateVideoCallCard",
                action$: (thread: IThread): Observable<null> => this.videoCallService.createCall(thread.id),
            },
        ];
    }
}
