import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TabsComponent, TabsItemComponent } from "@visoryplatform/fx-ui";
import { combineLatest, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from "rxjs/operators";
import { GA_EVENTS } from "../../../analytics";
import { ILibrary, RouteExtension, RouteHelper, VisibleExtension } from "../../../plugins";
import { Loader } from "../../../shared/services/loader";
import { AppUser } from "../../../findex-auth";
import { UserProfileService } from "../../services/user-profile.service";
import { UserProfileRouteService } from "../../services/user-profile-route.service";
import { PROFILE_ROUTE_LIBRARY } from "src/app/injection-token";

@Component({
    selector: "profile-route",
    templateUrl: "./profile-route.component.html",
    styleUrls: ["./profile-route.component.scss"],
    providers: [UserProfileRouteService],
})
export class ProfileRouteComponent implements OnDestroy, OnInit {
    @ViewChild("profileTab") profileTab: TabsItemComponent;
    @ViewChild("insightsPermissionsTab") insightsPermissionsTab: TabsItemComponent;
    @ViewChild("notificationsTab") notificationsTab: TabsItemComponent;

    @ViewChild("tabs") set tabs(tabs: TabsComponent) {
        if (tabs) {
            this.initTabs(tabs);
        }
    }

    readonly gaEvents = GA_EVENTS;

    loader = new Loader();
    extensions$: Observable<VisibleExtension[]>;

    userId: string;
    avatarNameString = "";

    private routeSub: Subscription;

    constructor(
        @Inject(PROFILE_ROUTE_LIBRARY) library: ILibrary<RouteExtension>,
        private route: ActivatedRoute,
        private router: Router,
        private userProfileService: UserProfileService,
    ) {
        this.extensions$ = RouteHelper.getVisibleExtensions(library);
    }

    ngOnInit(): void {
        const routeUserId$ = this.route.params.pipe(
            map((params) => params?.userId),
            distinctUntilChanged(),
        );

        const userProfile$ = routeUserId$.pipe(switchMap((userId) => this.getProfile(userId)));

        userProfile$.pipe(take(1)).subscribe((user) => {
            this.getUserProfile(user);
        });
    }

    public getUserProfile(userProfile: AppUser): void {
        this.userId = userProfile.id;
        this.avatarNameString = userProfile.name;
    }

    async navigateTo(path: string): Promise<void> {
        await this.router.navigate([`./${path}`], { relativeTo: this.route });
    }

    //See HTML comment, one big hack, should be able to remove this with eco-ui
    initTabs(tabs: TabsComponent): void {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }

        const path$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route.firstChild),
            startWith(this.route.firstChild),
            filter((child) => !!child),
            switchMap((child) => child.url),
            map((url) => url.find((segment) => !!segment)?.path),
        );

        this.routeSub = combineLatest([this.extensions$, path$]).subscribe(([extensions, path]) =>
            Promise.resolve().then(() => this.setTab(extensions, tabs, path)),
        );
    }

    ngOnDestroy(): void {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }

    //^^^^See HTML comment, should be able to remove this with eco-ui
    private setTab(extensions: VisibleExtension[], tabs: TabsComponent, path: string): void {
        switch (path) {
            case "details":
                tabs.selectTab(this.profileTab);
                return;
            case "notifications":
                tabs.selectTab(this.notificationsTab);
                return;
        }

        if (extensions) {
            const matchedExtension = extensions?.find((extension) => extension.path === path);
            const matchedTab = tabs.tabComponents.find((tab) => tab.header === matchedExtension?.label);

            if (matchedTab) {
                tabs.selectTab(matchedTab);
            }
        }
    }

    private getProfile(userId: string): Observable<AppUser> {
        if (userId) {
            return this.loader.wrap(this.userProfileService.getUserProfile(userId));
        } else {
            return this.loader.wrap(this.userProfileService.getCurrentUserProfile());
        }
    }
}
