<centered-modal-layout
    [loading]="loading"
    [cancelButtonTitle]="cancelButtonTitle"
    [disableSave]="disableSave"
    [saveButtonTitle]="saveButtonTitle"
    [analyticsPrefix]="analyticsPrefix"
    (save)="save.emit()"
    (close)="close.emit()"
>
    <div modal-header>
        <request-header
            [title]="state?.title || title"
            [isComplete]="state?.isCompleted"
            [completedStatusLabel]="completedStatusLabel"
            [subTitle]="subtitle"
            [accountName]="accountName"
        ></request-header>
    </div>

    <div modal-subhead>
        <request-subheader
            [card]="card"
            [description]="subheaderDescription"
        >
        </request-subheader>
    </div>

    <div modal-content>
        <ng-content></ng-content>
    </div>
</centered-modal-layout>
