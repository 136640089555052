import { Pipe, PipeTransform } from "@angular/core";
import jsonata from "jsonata";
import { isBoolean } from "lodash";
import { IWorkflow, IWorkflowAction, SystemStepId } from "@visoryplatform/workflow-core";
import { Observable, from, of } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
    name: "showStepAction",
})
export class ShowStepActionPipe implements PipeTransform {
    transform(workflow: IWorkflow, action: IWorkflowAction): Observable<boolean> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (action.id === SystemStepId.Cancelled) {
            return of(false);
        }

        // TODO: Workaround - Please remove when all workflows are migrated
        if (typeof action.hidden === "boolean") {
            return of(!action.hidden);
        }

        const expression = jsonata(action.hidden);
        const result = expression.evaluate(workflow);

        return from(result).pipe(map((isHidden) => (isBoolean(isHidden) ? !isHidden : true)));
    }
}
