import {
    IWorkflowVariation,
    VariationsControlContent,
    WorkflowConfigTokenService,
    WorkflowVariationTypes,
    workflowVariations,
} from "@visoryplatform/threads";

type TokenMap = { [id: string]: IWorkflowVariation };

export class WorkflowVariationsService {
    getWorkflowTokens(tokenIds?: string[]): IWorkflowVariation[] | null {
        const tokenMap = this.getTokenMap();

        const foundTokens: IWorkflowVariation[] = tokenIds?.map((id) => tokenMap[id]).filter((token) => token);
        if (!foundTokens?.length) {
            return null;
        }

        return foundTokens;
    }

    getTokenAffectingScheduling(workflowVariations?: IWorkflowVariation[]): IWorkflowVariation | null {
        if (!workflowVariations?.length) {
            return null;
        }

        const workflowVariationsAffectingScheduled = workflowVariations.find(
            (workflowVariation) => workflowVariation?.affectsScheduling,
        );
        if (!workflowVariationsAffectingScheduled) {
            return null;
        }

        return workflowVariationsAffectingScheduled;
    }

    getRequiredVariations(workflowVariationType?: WorkflowVariationTypes): number | null {
        if (!workflowVariationType) {
            return null;
        }

        const requiredVariations = workflowVariations[workflowVariationType];
        const variations = requiredVariations.filter(WorkflowConfigTokenService.excludeMandatory);

        return variations?.length;
    }

    private getTokenMap(): TokenMap {
        const tokenTypes = Object.keys(workflowVariations);

        return tokenTypes.reduce<TokenMap>((tokenMap, type) => {
            const tokensFromType = this.getTokensFromType(type);
            return this.mergeTokensToMap(tokensFromType, tokenMap);
        }, {});
    }

    private getTokensFromType(type: string): VariationsControlContent[] {
        const controls: VariationsControlContent[] = workflowVariations[type];
        return controls;
    }

    private mergeTokensToMap(tokensFromType: VariationsControlContent[], tokenMap: TokenMap): TokenMap {
        for (const control of tokensFromType) {
            for (const token of control.tokens) {
                tokenMap[token.id] = token;
            }
        }

        return tokenMap;
    }
}
