import { Pipe, PipeTransform } from "@angular/core";
import { Notification, NotificationState } from "@visoryplatform/notifications-core";

@Pipe({ name: "countNotifications" })
export class CountNotificationsPipe implements PipeTransform {
    transform(notifications: Notification[]): number {
        if (!notifications) {
            return 0;
        }

        const notificationsWithoutDeleted = notifications.filter(
            (notification) => notification.state !== NotificationState.Deleted,
        );

        return notificationsWithoutDeleted.length;
    }
}
