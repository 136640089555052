import { Component, Input } from "@angular/core";

@Component({
    selector: "insights-trend-pill",
    templateUrl: "./insights-trend-pill.component.html",
    styleUrls: ["./insights-trend-pill.component.scss"],
})
export class InsightsTrendPillComponent {
    @Input() public isBetterThanTarget: boolean; //driving color of the pill
    @Input() public isBelowTarget: boolean; //driving arrow direction
    @Input() public value: string;

    public trendImg: string;

    constructor() {}

    ngOnChanges(): void {
        this.isBelowTarget
            ? (this.trendImg = this.isBetterThanTarget ? "positive-trending-down" : "negative-trending-down")
            : (this.trendImg = this.isBetterThanTarget ? "positive-trending-up" : "negative-trending-up");
    }
}
