import { Injectable } from "@angular/core";
import { BillApprovalState, InvoiceItem } from "@visoryplatform/threads";
import { FormGroup, FormRecord, FormControl } from "@angular/forms";
import { BillApprovalFormItem, BillApprovalForm } from "../interfaces/BillApproval";

@Injectable()
export class BillApprovalFormStateService {
    setRequestItems(
        formGroup: FormGroup<BillApprovalForm>,
        oldState: BillApprovalState | undefined,
        state: BillApprovalState | undefined,
    ): FormGroup<BillApprovalForm> {
        this.updateRequestItems(formGroup, oldState?.invoiceItems, state?.invoiceItems);

        return formGroup;
    }

    private updateRequestItems(
        formGroup: FormGroup<BillApprovalForm>,
        oldItems: InvoiceItem[],
        currItems: InvoiceItem[],
    ): void {
        const requestItems = formGroup.controls.invoiceItems;
        const removeItems =
            oldItems?.filter((oldItem) => !currItems?.some((currItem) => currItem.fileId === oldItem.fileId)) || [];
        const addItems =
            currItems?.filter(
                (currItem) =>
                    !this.findControlByFileId(currItem.fileId, requestItems) ||
                    !oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId),
            ) || [];
        const updateItems =
            currItems?.filter(
                (currItem) =>
                    this.findControlByFileId(currItem.fileId, requestItems) &&
                    oldItems?.some((oldItem) => oldItem.fileId === currItem.fileId),
            ) || [];

        for (const item of removeItems) {
            const existingItemId = this.findControlByFileId(item.fileId, requestItems);
            requestItems.removeControl(existingItemId);
        }

        for (const item of addItems) {
            this.addItem(item, requestItems);
        }

        for (const item of updateItems) {
            const oldItem = oldItems.find((oItem) => oItem.fileId === item.fileId);
            this.updateItem(oldItem, item, requestItems);
        }
    }

    private addItem(item: InvoiceItem, requestItems: FormRecord<FormGroup<BillApprovalFormItem>>): void {
        const newControl = new FormGroup<BillApprovalFormItem>({
            fileId: new FormControl({ value: item.fileId, disabled: false }),
            description: new FormControl({ value: item.description, disabled: false }),
            approved: new FormControl({ value: item.response.approved.state, disabled: false }),
            declined: new FormControl({ value: item.response.declined.state, disabled: false }),
            externalComment: new FormControl({ value: item.externalComment, disabled: false }),
            internalComment: new FormControl({ value: item.internalComment, disabled: false }),
        });

        requestItems.addControl(item.description, newControl);
    }

    private updateItem(
        oldItem: InvoiceItem,
        item: InvoiceItem,
        requestItems: FormRecord<FormGroup<BillApprovalFormItem>>,
    ): void {
        const existingItemId = this.findControlByFileId(item.fileId, requestItems);
        const requestItemControl = requestItems.controls[existingItemId];

        if (this.hasItemStateChanged(oldItem, item)) {
            requestItemControl.patchValue({
                approved: item.response?.approved?.state,
                declined: item.response?.declined?.state,
            });
        } else {
            requestItemControl.patchValue({
                description: item.description,
            });
        }
    }

    private findControlByFileId(
        fileId: string,
        requestItemControls: FormRecord<FormGroup<BillApprovalFormItem>>,
    ): string | null {
        if (!requestItemControls?.controls) {
            return null;
        }

        const keys = Object.keys(requestItemControls.controls);
        return keys.find((key) => requestItemControls.controls[key].value?.fileId === fileId);
    }

    private hasItemStateChanged(oldItem: Partial<InvoiceItem>, currItem: Partial<InvoiceItem>): boolean {
        return (
            oldItem?.response?.approved?.state !== currItem?.response?.approved?.state ||
            oldItem?.response?.declined?.state !== currItem?.response?.declined?.state
        );
    }
}
