import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { IParticipant } from "@visoryplatform/threads";
import { VaultCardType } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { Subscription } from "rxjs";

@Component({
    selector: "reply-compose",
    templateUrl: "./reply-compose.component.html",
    styleUrls: ["./reply-compose.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ReplyComposeComponent,
            multi: true,
        },
    ],
})
export class ReplyComposeComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() cardType: string;
    @Input() threadType: string;
    @Input() inlineCompose = true;
    @Input() showCompose = false;
    @Input() mentionableUsers: IParticipant[];

    @Output() sendReply = new EventEmitter<string>();

    readonly vaultCardTypes = VaultCardType;

    replyMessage = new FormControl<string>("");
    replyControlSubscription: Subscription;
    quillError: boolean;
    disabled: boolean;

    onChange?: (newVal: string) => void;
    onTouched?: () => void;

    ngOnInit() {
        this.replyControlSubscription = this.replyMessage.valueChanges.subscribe((val) => {
            if (this.onTouched) {
                this.onTouched();
            }

            if (this.onChange) {
                this.onChange(val);
            }
        });
    }

    ngOnDestroy(): void {
        this.replyControlSubscription?.unsubscribe();
    }

    writeValue(newVal: string): void {
        this.replyMessage.setValue(newVal);
    }

    registerOnChange(fn: (newVal: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onUserInput(message: UntypedFormControl) {
        this.replyMessage = message;
    }

    reply(message: string): void {
        if (!message?.trim()) {
            return;
        }

        this.sendReply.emit(message);
        this.reset();
    }

    reset() {
        this.replyMessage.reset();
        this.showCompose = false;
    }
}
