import { IInvitee, IStaff } from "@visoryplatform/calendar-types";

export type CalendarInstance = { start: string; end: string; reference?: string };

export type CalendarState = {
    scheduled?: boolean;
    cancelled?: boolean;
    instances?: CalendarInstance[];
    attendees?: {
        staff: IStaff[];
        invitees: IInvitee[];
    };
    details?: {
        meetingName?: string;
        title?: string;
    };
    vcSessionId?: string;
};

export enum MeetingStatus {
    Request = "Meeting Request",
    Confirmed = "Meeting Confirmed",
    Ended = "Meeting Ended",
    Unknown = "Meeting",
}
