import { Inject, Injectable, Injector } from "@angular/core";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { AnalyticsService } from "../../../analytics";
import { ILibrary } from "../../../plugins/interfaces/ILibrary";
import { TaskAction } from "../../../plugins/services/Libraries";
import { CardResources } from "../../../threads-ui/interfaces/IUiCard";
import { take } from "rxjs/operators";
import { Subject } from "rxjs";
import { CardStatus } from "@visoryplatform/threads";
import { NavigationExtras, Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class TaskActionService {
    actionInProgress$ = new Subject<boolean>();

    constructor(
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<any>>,
        private analyticsService: AnalyticsService,
        private router: Router,
        private injector: Injector,
    ) {}

    async action<ReturnVal, TaskOptions = void>(
        actionId: string,
        cardResources: CardResources,
        options?: TaskOptions,
    ): Promise<ReturnVal> {
        try {
            this.actionInProgress$.next(true);

            const taskAction = this.taskActions.resolve(actionId);
            if (!taskAction) {
                return null;
            }

            const card = await cardResources.card$.pipe(take(1)).toPromise();
            if (!taskAction.cardTypes.includes(card.type)) {
                console.warn("Tried to call an action on a card that doesn't support it.");
                return null;
            }

            const isCardDeleted = card.status === CardStatus.Deleted;
            if (isCardDeleted) {
                console.warn("Tried to call an action on a card that has been deleted");
                return null;
            }

            const { analyticsEvents } = taskAction;

            if (analyticsEvents) {
                analyticsEvents.forEach((event) => {
                    this.analyticsService.recordEvent("mouse_click", event);
                });
            }

            const result = await taskAction.action(
                cardResources,
                Injector.create({ providers: [], parent: this.injector }),
                options,
            );

            return result;
        } catch (err) {
            console.error(err);
            return null;
        } finally {
            this.actionInProgress$.next(false);
        }
    }

    async routeToThreadTask(threadId: string, cardId: string, options?: NavigationExtras): Promise<void> {
        await this.router.navigate(["timelines", threadId, "cards", cardId], options);
    }
}
