import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sentencecase", pure: false })
export class SentenceCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value.length) {
            return "";
        }

        const firstCharacterOfString = value[0];
        const restOfString = value.slice(1);

        return firstCharacterOfString.toUpperCase() + restOfString.toLowerCase();
    }
}
