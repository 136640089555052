<v-badge
    *ngIf="status === states.closed"
    [color]="badgeColors.grey"
    value="CLOSED"
>
</v-badge>

<v-badge
    *ngIf="status === states.cancelled"
    [color]="badgeColors.grey"
    value="CANCELLED"
>
</v-badge>
