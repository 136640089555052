<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<ng-container *ngIf="report$ | async as report; else showLoader">
    <ng-container *ngIf="tableData$ | async as tableData">
        <div class="threads table-container">
            <payrun-request-table
                [report]="report"
                [allowEdit]="false"
                [tableData]="tableData"
            ></payrun-request-table>
        </div>
    </ng-container>
</ng-container>

<ng-template #showLoader>
    <div class="report-ghost">
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
    </div>
</ng-template>
