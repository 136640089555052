import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: "inline-edit",
    templateUrl: "./inline-edit.component.html",
    styleUrls: ["./inline-edit.component.scss"],
})
export class InlineEditComponent implements OnChanges {
    @Input() text: string;
    @Output() updated = new EventEmitter<string>();
    @Output() discard = new EventEmitter();

    updatedText: string;

    ngOnChanges(changes: SimpleChanges): void {
        const { text } = changes;

        if (text) {
            this.updatedText = text.currentValue;
        }
    }

    discardTextUpdate(): void {
        this.updatedText = this.text;
        this.discard.emit();
    }
}
