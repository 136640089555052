import { Inject, Injectable } from "@angular/core";
import { IUpcomingMeeting, IWorkflowEvent } from "@visoryplatform/threads";
import { Observable, of } from "rxjs";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { ThreadsService } from "./threads.service";
import { catchError, map } from "rxjs/operators";
import { CalendarFilters } from "../interfaces/CalendarFilters";

@Injectable({
    providedIn: "root",
})
export class CalendarEventsApiService {
    private readonly BASE_CALENDAR_URL = this.environment.calendarEndpoints.base;
    private readonly CALENDAR_QUERY = environmentCommon.calendarEndpoints;

    constructor(
        private http: HttpClient,
        @Inject(ENVIRONMENT)
        private environment: EnvironmentSpecificConfig,
        private threadsService: ThreadsService,
    ) {}

    public getUpcomingMeetings(): Observable<IUpcomingMeeting[]> {
        const url = `${this.BASE_CALENDAR_URL}/${this.CALENDAR_QUERY.upcomingMeetings}`;
        return this.http.get<IUpcomingMeeting[]>(url);
    }

    public saveCalendarFilter(userId: string, calendarFilters: CalendarFilters): Observable<void> {
        return this.threadsService.putUserData(userId, { calendarFilters });
    }

    public getSavedCalendarFilters(userId: string): Observable<CalendarFilters> {
        return this.threadsService.getUserData(userId).pipe(map((userData) => userData.calendarFilters));
    }

    public getWorkflowEvents(date: string, accounts: string[], services: string[]): Observable<IWorkflowEvent[]> {
        const body = { date, accounts, services };
        const { base } = this.environment.threadsEndpoints;
        const { workflow } = environmentCommon.threadsEndpoints;
        const url = `${base}${workflow}/list`;

        return this.http.post<IWorkflowEvent[]>(url, body).pipe(
            catchError((error) => {
                console.log(error);
                return of([]);
            }),
        );
    }
}
