import { Injectable } from "@angular/core";
import { IThreadListing, ITimeline } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ThreadsService } from "../../services/threads.service";

export interface IThreadGroupListing {
    id: string;
    name?: string;
    active?: boolean;
    isExpanded?: boolean;
    children?: ITimeline[];
}

@Injectable({ providedIn: "root" })
export class ThreadGrouper {
    threadTypes$ = this.threadsService.getThreadTypes().pipe(shareReplay(1));

    constructor(private threadsService: ThreadsService) {}

    static orderThreads(a: IThreadListing, b: IThreadListing): number {
        const aUpdated = a.preview ? a.preview.timestamp : a.createdAt;
        const bUpdated = b.preview ? b.preview.timestamp : b.createdAt;

        if (!aUpdated || !bUpdated) {
            return 0;
        }

        const aTime = new Date(aUpdated);
        const bTime = new Date(bUpdated);

        return bTime.getTime() - aTime.getTime();
    }

    getDisplayName(type: string): Observable<string> {
        return this.threadTypes$.pipe(map((threadTypes) => threadTypes?.[type] || "Other"));
    }
}
