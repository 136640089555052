import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
console.info(`Version: ${require("../package.json").version}`);

async function startApp(): Promise<void> {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));

    const { trackingId, tagManagerId, tagManagerAuth, tagManagerPreview } = environment.analytics.google;

    if (environment.freshmarketer) {
        const { cdnUrl: freshmarketerCdn } = environment.freshmarketer;
        const freshmarketerScriptElement = document.createElement("script");
        freshmarketerScriptElement.src = freshmarketerCdn;
        freshmarketerScriptElement.setAttribute("async", "");
        document.head.appendChild(freshmarketerScriptElement);
    }

    if (trackingId || tagManagerId) {
        const gtagScriptElement = document.createElement("script");

        if (trackingId) {
            const gtagJsScriptElement = document.createElement("script");
            gtagJsScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
            gtagJsScriptElement.setAttribute("async", "");
            document.head.appendChild(gtagJsScriptElement);

            gtagScriptElement.innerHTML = `
        try {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag("config", ${trackingId});
        } catch(error) {}
        `;
            gtagScriptElement.setAttribute("async", "");
        } else if (tagManagerId) {
            if (tagManagerAuth && tagManagerPreview) {
                gtagScriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${tagManagerAuth}&gtm_preview=${tagManagerPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${tagManagerId}');`;
            } else {
                gtagScriptElement.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${tagManagerId}');`;
            }
        }

        document.head.appendChild(gtagScriptElement);
    }
}

startApp();
