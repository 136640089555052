<loader-icon
    *ngIf="loader.counter"
    [static]="true"
    color="light"
></loader-icon>

<ng-container *ngIf="upcomingMeetings$ | async; let upcomingMeetings">
    <div
        class="upcoming-meeting"
        *ngFor="let meeting of upcomingMeetings; trackBy: trackMeeting"
    >
        <upcoming-meeting-detail
            [upcomingMeeting]="meeting"
            [role]="role"
        ></upcoming-meeting-detail>
    </div>

    <div
        *ngIf="!upcomingMeetings?.length"
        class="upcoming-meeting-empty"
    >
        <div class="threads-empty">
            <empty-state
                title=""
                [htmlText]="'<p>You have no upcoming meetings</p>'"
                [imageType]="emptyState.NoMeetings"
            >
            </empty-state>
        </div>
    </div>
</ng-container>
