<main class="fx-l-main">
    <div
        *ngIf="loading"
        class="loader"
    >
        <loader-icon color="light"></loader-icon>
    </div>
    <div class="fx-l-main-content">
        <ng-content></ng-content>
    </div>
</main>
