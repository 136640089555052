import { Component, EventEmitter, Inject, OnInit, Output, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { customValidators } from "projects/portal-modules/src/lib/shared/validators";
import { ForgotPasswordDialogComponent } from "../forgot-password-dialog/forgot-password-dialog.component";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { LoginComponent } from "../login/login.component";

@Component({
    selector: "login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent extends LoginComponent implements OnInit, OnDestroy {
    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;
    readonly showAgreePoliciesLogin = this.environment.featureFlags.showAgreePoliciesLogin;
    readonly policyUrl = this.environment.policyUrl;
    readonly termsUrl = this.environment.termsUrl;
    readonly termsOfBusinessUrl = this.environment.termsOfBusinessUrl;
    readonly disclaimerUrl = this.environment.disclaimerUrl;
    readonly emailDisclaimerUrl = this.environment.emailDisclaimerUrl;
    readonly textResource = this.environment.featureFlags.text;
    readonly showSignup = this.environment.featureFlags.signupEnabled;

    form = new UntypedFormGroup({
        emailAddress: new UntypedFormControl(null, [Validators.required, Validators.email]),
        password: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
    });

    loginMessage: string = null;

    private authSubscription: Subscription;

    @Output() userLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        authService: AuthService,
        activatedRoute: ActivatedRoute,
        router: Router,
        dialog: MatDialog,
        @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig,
    ) {
        super(authService, activatedRoute, router, dialog, environment);
        this.authSubscription = this.authService.onLoginSuccess().subscribe(() => {
            this.userLoggedIn.emit(true);
        });
    }

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.form.setValue({
                emailAddress: params.emailAddress || "",
                password: "",
            });
            if (params.emailExists) {
                this.loginMessage = "A user with this email address already exists, please login below.";
            }
        });
    }

    async login(): Promise<void> {
        try {
            this.loader.show();

            const { emailAddress, password } = this.form.value;
            const { loginRequired, verificationSent, errorMessage } = await this.authService.checkSignUpStatus(
                emailAddress,
            );
            if (loginRequired) {
                await this.handleLogin(emailAddress, password);
            } else if (verificationSent) {
                this.errorMessage = "Verification email sent";
            } else {
                this.errorMessage = errorMessage ? errorMessage : "Incorrect username or password";
            }
        } catch (error) {
            this.errorMessage = "Incorrect username or password";
        } finally {
            this.loader.hide();
        }
    }

    showForgotPassword($event) {
        $event.stopPropagation();
        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "mat-dialog-no-styling"],
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        };

        return this.dialog.open(ForgotPasswordDialogComponent, options);
    }

    loginCancelled() {
        this.userLoggedIn.emit(false);
    }

    loginAsStaff() {
        return this.authService.loginAsStaff();
    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }
}
