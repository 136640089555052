<ng-container *ngIf="workflowIsActive; else hasStatusBadge">
    <div
        *ngIf="hasSteps && shouldShowTitle"
        class="title"
        [ngClass]="{ 'title--full-width': fullWidth, 'title-color-override': overrideStepperTitleColor }"
    >
        {{ currentStep?.title }}
    </div>

    <div
        [ngClass]="{
            'hide-progress-indicator-on-mobile': hideDotStepperOnMobile
        }"
    >
        <v-progress-indicator
            *ngIf="enableDotStepper && hasSteps"
            (click)="manageStatus()"
            [total]="totalSteps"
            [completed]="currentStepIndex"
        ></v-progress-indicator>
    </div>

    <div
        class="progress"
        *ngIf="!enableDotStepper && hasSteps"
    >
        <div
            (click)="manageStatus()"
            [ngClass]="{
                'progress-bar--allow-click': enablePopUp,
                'progress-bar--small': small,
                'progress-bar-overdue':
                    currentStep?.isOverdue && (role | permissions : 'UpdateInternalWorkflow' | async)
            }"
            [analyticsClick]="gaEvents.APP_WORKFLOWPROGRESSBAR"
            class="progress-bar"
        >
            <div
                [style.width]="workflowIsActive ? (currentStepIndex / totalSteps) * 100 + '%' : '100%'"
                [ngClass]="{
                    'progress-bar-track-overdue':
                        currentStep?.isOverdue && (role | permissions : 'UpdateInternalWorkflow' | async)
                }"
                class="progress-bar-track"
            ></div>
        </div>

        <i
            *ngIf="showIcon && workflowIsActive"
            class="progress-icon las la-info-circle"
            (click)="manageStatus()"
        ></i>
    </div>
</ng-container>

<ng-template #hasStatusBadge>
    <status-badge
        (click)="manageStatus()"
        [workflow]="workflow"
    ></status-badge>
</ng-template>
