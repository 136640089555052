<div
    class="time-pill"
    [tuiHint]="hint"
    tuiHintDirection="right"
    [ngClass]="{
        'time-pill--red': colour === 'red',
        'time-pill--yellow': colour === 'yellow',
        'time-pill--green': colour === 'green',
    }"
>
    {{ label }}
    <i
        [ngClass]="{ 'la-hourglass-half': isCountdown, 'la-clock': !isCountdown }"
        class="las time-pill-icon"
    ></i>
    {{ time | duration }}
</div>
