import { MatDialog } from "@angular/material/dialog";
import { Injector } from "@angular/core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import {
    DefaultPermissions,
    PermissionService,
} from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { EMPTY, Observable, of } from "rxjs";
import { switchMap, tap, last, takeUntil, filter, skipUntil, mergeMap } from "rxjs/operators";
import { VcEndSessionModalComponent } from "./components/vc-end-session-modal/vc-end-session-modal.component";
import { VcModalComponent, VCModalModel } from "./components/vc-modal/vc-modal.component";
import { VcStateBuilder } from "./services/vc-state-builder";
import { VideoChatService } from "./services/video-chat.service";
import { CardTaskActionLabel, ICardEvent } from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

const getVcDetails = (events$: Observable<ICardEvent>, threadId: string, cardId: string) => {
    const modelBuilder = new VcStateBuilder();
    modelBuilder.setThreadAndState(threadId, cardId);
    const modelChange$ = events$.pipe(tap((event) => modelBuilder.addEvent(event)));
    return modelChange$.pipe(switchMap(() => modelBuilder.getState()));
};

const callAction = (cardResources: Partial<CardResources>, injector: Injector, { join }: { join: boolean }) => {
    const matDialog = injector.get(MatDialog);
    const { role, events$, threadId, cardId } = cardResources;
    const openedModal = matDialog.open<VcModalComponent, VCModalModel, boolean>(VcModalComponent, {
        maxWidth: "100vw",
        panelClass: "video-chat-modal-timeline",
        disableClose: true,
        closeOnNavigation: false,
        hasBackdrop: false,
        data: { details: getVcDetails(events$, threadId, cardId), join, role },
    });

    return openedModal.afterClosed().toPromise();
};

const startCallAction = () => async (cardResources: Partial<CardResources>, injector: Injector) =>
    callAction(cardResources, injector, { join: false });

const joinCallAction = () => async (cardResources: Partial<CardResources>, injector: Injector) =>
    callAction(cardResources, injector, { join: true });

const endCallAction = () => (cardResources: Partial<CardResources>, injector: Injector) => {
    const matDialog = injector.get(MatDialog);
    const permissionService: PermissionService = injector.get(PermissionService);
    const videoChatService: VideoChatService = injector.get(VideoChatService);
    const { role, events$, threadId, cardId, loader } = cardResources;
    const vcDetails$ = getVcDetails(events$, threadId, cardId);

    return permissionService
        .checkPermissions(role, DefaultPermissions.VcEndSession)
        .pipe(
            switchMap((hasPermission) => {
                if (!hasPermission) {
                    return of(false);
                }

                return showEndSessionModal(matDialog);
            }),
            switchMap((confirmed) => {
                if (!confirmed) {
                    return EMPTY;
                }

                return vcDetails$.pipe(
                    // eslint-disable-next-line rxjs/no-unsafe-takeuntil
                    takeUntil(
                        loader.counter$.pipe(
                            skipUntil(loader.counter$.pipe(filter((counter) => counter > 0))),
                            filter((counter) => counter === 0),
                        ),
                    ),
                    last(),
                    switchMap((vcState) => of(vcState.activeSessionIds)),
                );
            }),
            mergeMap((activeSessionIds: string[]) =>
                terminateActiveSessions(activeSessionIds, loader, videoChatService, threadId, cardId),
            ),
        )
        .toPromise();
};
export const vcStartCallTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_JOINVC],
    cardTypes: ["calendar", "video-chat"],
    action: startCallAction(),
    buttonLabel: CardTaskActionLabel.StartCall,
};

export const vcJoinCallTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_JOINVC],
    cardTypes: ["calendar", "video-chat"],
    action: joinCallAction(),
    buttonLabel: CardTaskActionLabel.JoinCall,
};

export const vcEndSessionTaskAction: TaskAction<any> = {
    analyticsEvents: [GA_EVENTS.APP_ENDVCSESSION],
    cardTypes: ["calendar", "video-chat"],
    action: endCallAction(),
    buttonLabel: CardTaskActionLabel.EndSession,
};

const terminateActiveSessions = (
    activeSessionIds: string[],
    loader: Loader,
    videoChatService: VideoChatService,
    threadId: string,
    cardId: string,
) => activeSessionIds.map((sessionId) => loader.wrap(videoChatService.terminateSession(sessionId, threadId, cardId)));

const showEndSessionModal = (matDialog: MatDialog) =>
    matDialog
        .open<VcEndSessionModalComponent, null, boolean>(VcEndSessionModalComponent, {
            disableClose: false,
            closeOnNavigation: false,
            hasBackdrop: true,
            panelClass: ["centered-modal"],
        })
        .afterClosed();
