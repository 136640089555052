import { Component, Inject, Input, OnInit } from "@angular/core";
import { ITimeline } from "@visoryplatform/threads";
import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";
import { THREAD_LIBRARY } from "src/app/injection-token";
import { GA_EVENTS } from "../../../analytics";
import { ILibrary, RouteExtension } from "../../../plugins";

@Component({
    selector: "workflow-tabs",
    templateUrl: "./workflow-tabs.component.html",
    styleUrls: ["./workflow-tabs.component.scss"],
})
export class WorkflowTabsComponent implements OnInit {
    @Input() thread: ITimeline;

    threadExtensions$: Observable<RouteExtension[]>;
    gaEvents = GA_EVENTS;

    constructor(@Inject(THREAD_LIBRARY) private threadLibrary: ILibrary<RouteExtension>) {}

    ngOnInit(): void {
        const threadExtensionsArray$ = this.threadLibrary.listAll().map(({ extension }) => {
            if (!extension.showExtension) {
                return of(extension);
            }

            return extension.showExtension(this.thread).pipe(map((isShowing) => (isShowing ? extension : null)));
        });

        this.threadExtensions$ = combineLatest(threadExtensionsArray$).pipe(
            map((extensions) => extensions.filter((extension) => extension != null)),
        );
    }

    trackByExtension(_index: number, extension: RouteExtension): string {
        return extension?.label;
    }

    handleRedirect(extention: RouteExtension): void {
        extention.redirect(this.thread);
    }
}
