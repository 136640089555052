import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { DrawerConfig, DrawerItem, DrawerService, defaultConfig } from "../../services/drawer.service";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, startWith } from "rxjs/operators";

@Component({
    selector: "route-drawer",
    templateUrl: "./route-drawer.component.html",
    styleUrls: ["./route-drawer.component.scss"],
})
export class RouteDrawerComponent implements OnInit, OnDestroy {
    item$: Observable<DrawerItem>;
    config$: Observable<DrawerConfig>;

    @ViewChild("drawer") drawer: MatDrawer;

    private itemSub: Subscription;

    constructor(private drawerService: DrawerService) {}

    ngOnInit(): void {
        this.item$ = this.drawerService.item$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

        this.itemSub = this.item$.subscribe((item) => {
            if (item) {
                void this.drawer.open();
            } else {
                void this.drawer.close();
            }
        });

        this.config$ = this.item$.pipe(
            filter((item) => !!item),
            map((item) => item.config),
            startWith({ ...defaultConfig, data: null }),
        );
    }

    ngOnDestroy(): void {
        this.itemSub?.unsubscribe();
    }
}
