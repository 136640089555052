<div
    class="quill-read-more-container"
    [style.maxHeight.px]="setHeight ? MAX_HEIGHT : 'none'"
    [ngClass]="{
        'read-more-container--set-height': setHeight,
        'read-more-expanded': expandCardView || showFullDescription
    }"
>
    <div #replyMessage>
        <ng-content></ng-content>
    </div>
</div>

<div
    class="read-more-toggle"
    (click)="expandCardView = !expandCardView"
    *ngIf="showSeeMore && !showFullDescription"
>
    <div>
        <i class="las la-angle-{{ expandCardView ? 'up' : 'down' }} read-more-toggle-icon"></i
        >{{ expandCardView ? "Read less" : "Read more" }}
    </div>
</div>
