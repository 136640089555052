<div
    class="brand-container"
    [ngClass]="{ 'brand-container--menu': type === 'menu', 'brand-container--onboarding': type === 'onboarding' }"
>
    <a
        class="fx-brand"
        [routerLink]="['/']"
    ></a>
    <ng-container *ngIf="secondaryBranding">
        <div class="secondary-brand-divider"></div>
        <a
            class="secondary-brand"
            [routerLink]="['/']"
        ></a>
    </ng-container>
</div>
