<div class="fx-paginator">
    <button
        class="fx-btn fx-btn--primary-outlined fx-paginator-button fx-paginator-button-left"
        (click)="previousPage()"
        *ngIf="enableBack"
    >
        <i class="las la-angle-left"></i>
        Previous
    </button>
    <button
        class="fx-btn fx-btn--primary-outlined fx-paginator-button fx-paginator-button-right"
        (click)="nextPage()"
        *ngIf="enableNext"
    >
        Next
        <i class="las la-angle-right"></i>
    </button>
</div>
