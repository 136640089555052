import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounce, distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { combineLatest, Subject, Subscription, timer } from "rxjs";

@Component({
    selector: "search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SearchComponent,
        },
    ],
})
export class SearchComponent implements OnDestroy, OnInit, OnChanges, ControlValueAccessor {
    @ViewChild("search", { static: false }) inputElement: ElementRef;
    @Input() analyticsClickEvent: string;
    @Input() placeholder = "Search";
    @Input() tooltip = "";
    @Input() value: string;
    @Input() showClearButton = true;
    @Input() debounceLimit = 500;
    @Input() characterLimit = 3;

    searchSubscription: Subscription;
    formControl = new FormControl<string>("");
    debounceLimit$ = new Subject();

    onChange?: (obj: Partial<string>) => void;
    onTouch?: () => void;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.debounceLimit) {
            this.debounceLimit$.next(this.debounceLimit);
        }
    }

    ngOnInit(): void {
        const debounce$ = this.debounceLimit$.pipe(startWith<number>(this.debounceLimit));

        this.searchSubscription = combineLatest(this.formControl.valueChanges, debounce$)
            .pipe(
                debounce(([, debounceLimit]) => timer(debounceLimit)),
                distinctUntilChanged(),
                filter(([term]) => !term || this.validateCharacterLimit(term)),
                map(([term]) => term),
            )
            .subscribe((value) => {
                this.onChange?.(value);
                this.onTouch?.();
            });
    }

    writeValue(value: string): void {
        this.formControl.setValue(value);
    }

    clearValue(): void {
        this.formControl.setValue("");
        this.inputElement.nativeElement.focus();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }

    private validateCharacterLimit(term: string): boolean {
        const subtractIndex = 1;
        return term.length > this.characterLimit - subtractIndex;
    }
}
