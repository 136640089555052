<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        allChecksPass: allChecksPass$ | async
    } as context"
>
    <centered-modal-layout
        [loading]="false"
        [cancelButtonTitle]="buttonLabels.Close"
        [disableSave]="!context.allChecksPass"
        [saveButtonTitle]="buttonLabels.NextStep"
        [loading]="!!loader.counter"
        (save)="dialogRef.close()"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                [title]="context.state?.label"
                [isComplete]="context.allChecksPass"
                [completedStatusLabel]="requestStatuses.Completed"
            ></request-header>
        </div>
        <div modal-content>
            <div class="transactions-action">
                <h3 class="transactions-action-heading">Quality checks</h3>
                <div class="transactions-action-desc">
                    The following automated quality checks must pass to complete this workflow step. Action any failed
                    checks in accounting software then refresh this list.
                </div>
                <button
                    class="fx-btn fx-btn--primary-outlined transactions-action-refresh"
                    type="button"
                    [disabled]="loader.counter"
                    (click)="refreshData(context.thread.id, context.state.sources)"
                >
                    Refresh
                </button>
            </div>
            <fx-todolist-item
                *ngFor="let transfomer of context.state?.resultTransforms; trackBy: trackTransformerResult"
                [ngModel]="true"
                [error]="context.state.latestResults[transfomer.resultTransformId] !== true"
                [shouldShowDelete]="false"
                [disabled]="true"
            >
                <div class="rfi-todo-list-todo">
                    <div
                        [ngClass]="{
                            'rfi-todo-list-todo-details--checked':
                                context.state.latestResults[transfomer.resultTransformId] === true
                        }"
                        class="rfi-todo-list-todo-details"
                    >
                        {{ transfomer.label }}
                    </div>
                    <i
                        class="las la-exclamation-circle rfi-todo-list-todo-tooltip"
                        [tuiHint]="transfomer?.metadata?.tooltip"
                        tuiHintDirection="top"
                        *ngIf="!!transfomer?.metadata?.tooltip"
                    ></i>
                    <div>
                        <a
                            class="rfi-todo-list-todo-action"
                            (click)="viewResults(transfomer)"
                            *ngIf="
                                (FEATURE_FLAGS.EnableAutomatedCheckViewResultsButton | featureEnabled | async) &&
                                !context.state.latestResults[transfomer.resultTransformId]
                            "
                        >
                            <i class="ai-icon las"></i>
                            View results</a
                        >
                    </div>
                </div>
            </fx-todolist-item>
        </div>
    </centered-modal-layout>
</ng-container>
