import { Component, Inject, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IData } from "../error.component";

@Component({
    selector: "app-error-modal",
    templateUrl: "./error-modal.component.html",
    styleUrls: ["./error-modal.component.scss"],
})
export class ErrorModalComponent {
    constructor(
        private dialogRef: MatDialogRef<ErrorModalComponent>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private ngZone: NgZone,
    ) {}

    // ErrorHandler is run outside of zone
    close(val: boolean, routeBackLink?: string) {
        if (routeBackLink) {
            this.router.navigateByUrl(`${routeBackLink}`);
        }

        this.ngZone.run(() => {
            this.dialogRef.close(val);
        });
    }
}
