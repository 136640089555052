import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DateTime } from "luxon";
import { FormControl, Validators } from "@angular/forms";
import { DAY_HOURS_SELECT } from "projects/portal-modules/src/lib/account/constants/configuration-settings.constants";

@Component({
    selector: "time-picker",
    templateUrl: "./time-picker.component.html",
    styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent implements OnChanges {
    @Input() initialTime: string;
    @Input() isOnlyWorkingHours = true;
    @Input() shouldIncludeHalfHour = false;
    @Input() disabled = false;

    @Output() timeChanged = new EventEmitter<DateTime>();

    selectOptions: string[];
    selectedTime = new FormControl<string>("", Validators.required);
    offsetName: string;
    timeZone: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialTime) {
            this.setLocalVariables(changes.initialTime.currentValue);
        }

        if (changes.disabled && changes.disabled.currentValue === true) {
            const shouldDisable = changes.disabled.currentValue;
            if (shouldDisable) {
                this.selectedTime.disable();
            } else {
                this.selectedTime.enable();
            }
        }
    }

    timeSelected(time: string): void {
        if (this.selectedTime.valid) {
            const timeUpdate = DateTime.fromISO(time).setZone(this.timeZone);
            this.timeChanged.emit(timeUpdate);
        }
    }

    private setLocalVariables(isoTime: string): void {
        const initialTime = DateTime.fromISO(isoTime);

        this.timeZone = initialTime.zoneName;
        this.offsetName = initialTime.offsetNameShort;
        this.selectOptions = this.getSelectOptions(initialTime, this.isOnlyWorkingHours);
        this.selectedTime.setValue(isoTime);
    }

    private getFullDayHoursOptions(shouldIncludeHalfHour: boolean): number[] {
        const hourOptions = [];
        for (let i = 0; i < 24; i++) {
            hourOptions.push(i);
        }
        return shouldIncludeHalfHour ? hourOptions : hourOptions.filter((option) => Number.isInteger(option));
    }

    private getSelectOptions(selectedTime: DateTime, isOnlyWorkingHours: boolean): string[] {
        const workingHoursList = Object.keys(DAY_HOURS_SELECT).map((stringHour) => Number(stringHour));
        const fullHoursList = this.getFullDayHoursOptions(this.shouldIncludeHalfHour);
        const hoursList = isOnlyWorkingHours ? workingHoursList : fullHoursList;

        const hoursOptions = hoursList.map((numberOfHours: number) => {
            const startOfDay = selectedTime.startOf("day");
            return startOfDay.plus({ hours: numberOfHours }).toISO();
        });

        return hoursOptions;
    }
}
