import { Injectable } from "@angular/core";

import {
    CreateInvitationCloseModalComponent,
    ICreateInvitationCloseModalData,
} from "../components/calendar-meeting-request/close-modal/create-invitation-close-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../portal-modules/src/lib/shared/services/dialog.service";
import { take } from "rxjs/operators";
import { IParticipant, Role, InternalRoles } from "@visoryplatform/threads";
import { IMeetingRequestDetails } from "../interfaces/IMeetingRequestDetails";

@Injectable({ providedIn: "root" })
export class CalendarModalService {
    constructor(private dialogService: DialogService, private dialog: MatDialog) {}

    async confirmClose(data: ICreateInvitationCloseModalData): Promise<any> {
        const panelClass = await this.dialogService.getConfirmConfigPanelClass().pipe(take(1)).toPromise();
        const config = {
            data,
            panelClass,
            autoFocus: true,
        };
        return await this.dialog.open(CreateInvitationCloseModalComponent, config).afterClosed().toPromise();
    }

    validateMeetingDetailsEventBody(threadParticipants: IParticipant[], event: IMeetingRequestDetails): boolean {
        const hasClientParticipant = this.hasClientParticipant(threadParticipants);
        const { numberOfOccurrences, title, attendees, duration } = event;
        const isValidNumberOfOccurences = numberOfOccurrences >= 2 && numberOfOccurrences <= 10;
        const isAttendeesValid = attendees.length > 1;

        const isValid =
            isValidNumberOfOccurences &&
            title &&
            isAttendeesValid &&
            duration &&
            this.validateParticipants(hasClientParticipant, attendees);

        return !!isValid;
    }

    validateParticipants(hasClientParticipant: boolean, selectedParticipants: IParticipant[]): boolean {
        const client = selectedParticipants.filter((participant) => participant.role === Role.Client);
        const staff = selectedParticipants.filter((participant) => InternalRoles.includes(participant.role));

        if (!hasClientParticipant && staff?.length) {
            return true;
        }

        return !!client?.length && !!staff?.length;
    }

    hasClientParticipant(threadParticipants: IParticipant[]): boolean {
        const hasClientsInThread = threadParticipants.some((participant) => !InternalRoles.includes(participant.role));
        return hasClientsInThread;
    }
}
