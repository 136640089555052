import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICreateRequestMenu } from "projects/default-plugins/vault/types/CreateRequest";

export interface IPreCreateRequestErrorItem {
    message: string;
    showError: boolean;
}

@Component({
    selector: "pre-create-request-item",
    templateUrl: "./pre-create-request-item.component.html",
    styleUrls: ["./pre-create-request-item.component.scss"],
})
export class PreCreateRequestItemComponent {
    @Output() update = new EventEmitter<ICreateRequestMenu>();

    @Input() disabled: boolean;
    @Input() disabledMessage: IPreCreateRequestErrorItem[];
    @Input() checked: boolean;
    @Input() menuItem: ICreateRequestMenu;
}
