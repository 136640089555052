import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";
import { IParticipantUi } from "../../../../interfaces/IParticipantUi";
import { ParticipantService } from "../../../../services/participant.service";

@Component({
    selector: "edit-participants",
    templateUrl: "./edit-participants.component.html",
    styleUrls: ["./edit-participants.component.scss"],
})
export class EditParticipantsComponent {
    @Input() participants?: IParticipantUi[];

    @Output() participantsChanged = new EventEmitter<IParticipantUi[]>();

    readonly assignedToStep = "Assigned to step";

    constructor(private participantService: ParticipantService) {}

    addParticipant(participant: IParticipant): void {
        const participantUi = this.participantService.getParticipantUi(participant, false);
        this.participantsChanged.emit([...this.participants, participantUi]);
    }

    removeParticipant(participantId: string): void {
        const updatedParticipants = this.participants.filter((participant) => participant.id !== participantId);
        this.participantsChanged.emit(updatedParticipants);
    }
}
