import { Component, Input } from "@angular/core";

@Component({
    selector: "mention-tooltip",
    template: `
        <div class="ql-tooltip-content">
            <div class="ql-mention-item">
                <fx-avatar
                    [name]="name"
                    [image]="userId | profilePicture | async"
                    [border]="false"
                    size="medium"
                ></fx-avatar>
                <div class="ql-mention-item-name">
                    {{ name }}
                    <div
                        *ngIf="title"
                        class="ql-mention-item-title"
                    >
                        {{ title }}
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ["./mention-tooltip.component.scss"],
})
export class MentionTooltipComponent {
    @Input() name: string;
    @Input() userId: string;
    @Input() title: string;
}
