<div
    class="thread-card-replies-button"
    *ngIf="!inlineCompose || (!showCompose && !disabled)"
    (click)="inlineCompose && (showCompose = true)"
    [analyticsClick]="cardType + '_cardreply'"
>
    Reply
</div>

<div
    class="thread-card-replies-compose"
    *ngIf="showCompose && inlineCompose"
>
    <div class="thread-card-replies-input">
        <quill-inline-editor-wrapper
            [threadType]="threadType"
            [participants]="mentionableUsers"
            [formControl]="replyMessage"
            [autoFocusOnInit]="true"
            [readOnly]="disabled"
            [submitDisabled]="!replyMessage?.value || disabled"
            [threadType]="threadType"
            (save)="reply($event)"
            (cancel)="reset()"
        ></quill-inline-editor-wrapper>
    </div>
</div>
