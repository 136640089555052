<sidebar-modal-layout
    [header]="modalHeader"
    *ngIf="data"
    (close)="close()"
>
    <loader-icon
        *ngIf="!!loader.counter"
        [showBackground]="true"
        color="light"
    ></loader-icon>

    <div class="signing-modal">
        <div class="signing-modal-download-header">
            <div>
                <i class="las la-file signing-modal-download-header-icon"></i>
                <span>{{ data.vaultItem?.displayName }}</span>
            </div>

            <button
                class="fx-btn fx-btn--white"
                (click)="download()"
            >
                DOWNLOAD
            </button>
        </div>

        <form
            [formGroup]="form"
            class="fx-form fx-form--outlined"
        >
            <div class="signing-modal-file-sign">
                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="name"
                        >Your name</label
                    >
                    <div class="fx-form-control">
                        <input
                            id="name"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your Name"
                            formControlName="name"
                            placeholder="eg. John doe"
                            [ngClass]="{
                                'fx-form-input--error': !form.controls['name'].valid && form.controls['name'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                    >
                        Please add a name.
                        <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                    </div>
                </div>

                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="title"
                        >Your title</label
                    >
                    <div class="fx-form-control">
                        <input
                            id="title"
                            class="fx-form-input"
                            type="text"
                            aria-label="Your title"
                            placeholder="eg. Director"
                            formControlName="title"
                            [ngClass]="{
                                'fx-form-input--error': !form.controls['title'].valid && form.controls['title'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                    >
                        Please add a title.
                        <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                    </div>
                </div>

                <fx-checkbox
                    class="signing-modal-checkbox"
                    formControlName="agreed"
                >
                    <p class="signing-modal-checkbox-text">
                        I am the party intended to sign the above document, or am authorised to sign it on their behalf.
                        I acknowledge that the information in the above document is true and accurate. I agree or am
                        authorised to agree to be bound by the terms referenced or contained in the above document.
                        Together, this dialog box and the above document form the agreement.
                    </p>
                </fx-checkbox>
            </div>
        </form>
    </div>
    <warning-banner
        *ngIf="warningMessage"
        class="signing-modal-banner"
        >{{ warningMessage }}</warning-banner
    >
    <div class="signing-modal-footer">
        <div class="signing-modal-footer-buttons signing-modal-footer-buttons--mobile">
            <button
                class="fx-btn fx-btn--white"
                role="button"
                (click)="close()"
            >
                Close
            </button>

            <button
                class="fx-btn fx-btn--primary"
                role="button"
                (click)="accept()"
                [disabled]="!form.valid || !form.controls.agreed.value"
            >
                I ACCEPT
            </button>
        </div>
    </div>
</sidebar-modal-layout>
