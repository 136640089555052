<div class="verify-email">
    <!-- The page redirects after success or failure, so always show a loader -->
    <loader-icon
        *ngIf="!errorMessage; else showError"
        color="light"
    ></loader-icon>
    <ng-template #showError>
        <div class="verify-email-error">
            <div class="verify-email-error-message">
                {{ errorMessage }}
            </div>
        </div>
    </ng-template>
</div>
