import { Injectable } from "@angular/core";
import { combineLatest, merge, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, scan, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { PortalService } from "./portal.service";
import { ThreadsWebsocketService } from "./threads-websocket.service";
import { ParticipantCache } from "../../threads-ui/services/participant-cache.service";
import { IThreadPreview, ITimeline, WebsocketNotification, WebsocketSubjectType } from "@visoryplatform/threads";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";

import { AuthService } from "../../findex-auth";
import { IStep, IWorkflow } from "@visoryplatform/workflow-core";
import { Notification, NotificationState, NotificationTopic } from "@visoryplatform/notifications-core";
import { WorkflowApiService } from "../../threads-ui/services/workflow/workflow-api.service";

@Injectable({ providedIn: "root" })
export class ThreadUpdateService {
    constructor(
        private websocketService: ThreadsWebsocketService,
        private participantsCache: ParticipantCache,
        private portalService: PortalService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private workflowApiService: WorkflowApiService,
    ) {}

    getUpdatesByThread(thread: ITimeline): Observable<ITimeline> {
        const threadUpdates$ = this.threadChanges(thread.id).pipe(startWith(thread), shareReplay(1));

        const currentStepId$ = this.workflowCurrentStepChanges(thread.id, thread?.workflow?.currentStepId);
        const unresolvedCount$ = this.unresolvedCountChanges(thread.id, thread.unresolvedNotifications);
        const previewChanges$ = this.previewChanges(thread.id, thread.preview);
        const workflowCurrentStepIdChanges$ = combineLatest([threadUpdates$, currentStepId$]).pipe(
            map(([thread, currentStepId]) => this.mergeWorkflowCurrentStep(thread.workflow, currentStepId)),
        );
        const workflowStepChanges$ = merge(
            this.workflowStepChanges(thread.id),
            this.workflowStepIdChanges(thread.id, thread.workflowId),
        );
        const workflowChanges$ = combineLatest([workflowCurrentStepIdChanges$, workflowStepChanges$]).pipe(
            map(([workflow, step]) => this.mergeWorkflowStep(workflow, step)),
            startWith(thread.workflow),
        );

        return combineLatest([threadUpdates$, unresolvedCount$, previewChanges$, workflowChanges$]).pipe(
            map(([thread, unresolvedNotifications, preview, workflow]) => ({
                ...thread,
                unresolvedNotifications,
                preview,
                workflow,
            })),
            tap((thread) => {
                this.participantsCache.update(thread.participants);
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    getAllUpdates(): Observable<WebsocketNotification> {
        return this.websocketService.connectAllEvents().pipe(filter((event) => this.isUpdatePreview(event)));
    }

    private mergeWorkflowCurrentStep(workflow: IWorkflow, currentStepId: string): IWorkflow {
        if (!workflow) {
            return workflow;
        }

        return {
            ...workflow,
            currentStepId,
        };
    }

    private mergeWorkflowStep(workflow: IWorkflow, step: IStep): IWorkflow {
        if (!workflow || !step) {
            return workflow;
        }
        const existingStep = workflow.steps[step.id];

        return {
            ...workflow,
            steps: {
                ...workflow.steps,
                [step.id]: { ...existingStep, ...step },
            },
        };
    }

    private previewChanges(threadId: string, currentPreview: IThreadPreview): Observable<IThreadPreview> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter((event) => this.isUpdatePreview(event)),
            map(({ preview }) => preview),
            startWith(currentPreview),
        );
    }

    private threadChanges(threadId: string): Observable<ITimeline> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter((socketEvent) => this.isUpdateThread(socketEvent)),
            switchMap(() => this.portalService.getThreadListById(threadId)),
        );
    }

    private workflowCurrentStepChanges(threadId: string, currentStepId: string): Observable<string> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter((socketEvent) => this.isUpdateWorkflowCurrentStepId(socketEvent)),
            map((event) => event.currentStepId),
            startWith(currentStepId),
        );
    }

    private workflowStepChanges(threadId: string): Observable<IStep> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter((socketEvent) => this.isUpdateWorkflowStep(socketEvent)),
            map((event) => event.step),
            startWith(null),
        );
    }

    private workflowStepIdChanges(threadId: string, workflowId: string): Observable<IStep> {
        return this.websocketService.watchThreadId(threadId).pipe(
            filter((socketEvent) => this.isUpdateWorkflowStepId(socketEvent)),
            switchMap((event) => this.workflowApiService.getStep(workflowId, event.stepId)),
            startWith(null),
        );
    }

    private isUpdatePreview(event: WebsocketNotification): boolean {
        return event.subjectType === WebsocketSubjectType.Thread && event.preview != null;
    }

    private isUpdateThread(event: WebsocketNotification): boolean {
        const isThreadUpdate = event.subjectType === WebsocketSubjectType.Thread;
        return isThreadUpdate && !event.preview && !event.currentStepId;
    }

    private isUpdateWorkflowCurrentStepId(event: WebsocketNotification): boolean {
        const isThreadUpdate = event.subjectType === WebsocketSubjectType.Thread;
        return isThreadUpdate && !event.preview && event.currentStepId != null;
    }

    private isUpdateWorkflowStep(event: WebsocketNotification): boolean {
        const isThreadUpdate = event.subjectType === WebsocketSubjectType.Thread;
        return isThreadUpdate && !event.preview && event.step != null;
    }

    private isUpdateWorkflowStepId(event: WebsocketNotification): boolean {
        const isThreadUpdate = event.subjectType === WebsocketSubjectType.Thread;
        return isThreadUpdate && !event.preview && event.stepId != null;
    }

    private unresolvedCountChanges(threadId: string, currentCount: number): Observable<number> {
        const userId$ = this.authService.getUserWithoutRole().pipe(
            filter((user) => !!user),
            map((user) => user?.id),
            distinctUntilChanged(),
        );

        return userId$.pipe(
            switchMap((userId) => this.getUserReadRecipients(threadId, userId)),
            map((notifications) => this.countNotifications(notifications)),
            scan((previousCount, changeInCount) => previousCount + changeInCount, currentCount),
            map((unresolvedNotifications) => unresolvedNotifications),
            startWith(currentCount),
        );
    }

    private getUserReadRecipients(threadId: string, userId: string): Observable<Notification<unknown>[]> {
        return this.notificationsService
            .subscribeToChannel(`activity/threads/${threadId}`)
            .pipe(
                map((notifications) =>
                    notifications.filter((notification) => this.isUserNotification(notification, userId)),
                ),
            );
    }

    private isUserNotification(notification: Notification<unknown>, userId: string): boolean {
        //For some reason for ReadReciept notifications, we set the actorId as the recipientId, and generate a new GUID
        //Not a good time to be changing that
        if (notification.topic === NotificationTopic.ReadReceipt) {
            return notification.actorId === userId;
        } else {
            return true;
        }
    }

    private countNotifications(notification: Notification<unknown>[]): number {
        const count = notification?.reduce((count, notification) => this.countNotification(count, notification), 0);
        return count || 0;
    }

    private countNotification(count: number, notification: Notification<unknown>): number {
        if (notification?.state === NotificationState.Delivered) {
            return count + 1;
        } else if (notification.topic === NotificationTopic.ReadReceipt) {
            return count - 1;
        } else {
            return 0;
        }
    }
}
