<centered-modal-layout
    (save)="createCard()"
    (close)="close()"
    [disableSave]="form.invalid || !!loader.counter"
    [loading]="!!loader.counter"
    analyticsPrefix="payments_createpayment"
    *ngIf="data$ | async; let data"
>
    <div modal-header>Create Payment</div>
    <div modal-content>
        <div class="payment-modal">
            <form
                class="payment-form"
                [formGroup]="form"
            >
                <fx-layout>
                    <div
                        class="fx-layout-col fx-layout-col-mobile--24"
                        [ngClass]="{
                            'fx-layout-col-tablet--12':
                                form.controls.paymentType.value === PaymentTypes.ScheduledPayment,
                            'fx-layout-col-tablet--24':
                                form.controls.paymentType.value !== PaymentTypes.ScheduledPayment
                        }"
                    >
                        <div class="payment-type">
                            <div
                                class="fx-form-group"
                                *ngIf="form.controls.currency; let control"
                            >
                                <label class="fx-form-label">Currency</label>
                                <ng-container *ngIf="customer$ | async as customer; else currencyNotSet">
                                    <div class="fx-form-control">
                                        <fx-select
                                            formControlName="currency"
                                            analyticsClick="payments_createpayment_currency"
                                            [disabled]="!!customer?.currency"
                                        >
                                            <fx-option
                                                *ngIf="customer?.currency; else setCurrency"
                                                [value]="customer.currency"
                                                [disabled]="true"
                                                [selected]="true"
                                                [hidden]="true"
                                            >
                                                {{ customer.currency | uppercase }}
                                            </fx-option>

                                            <ng-template #setCurrency>
                                                <fx-option
                                                    *ngFor="let currency of AcceptedCurrencies | keyvalue"
                                                    [value]="currency.value"
                                                >
                                                    {{ currency.key | uppercase }}
                                                </fx-option>
                                            </ng-template>
                                        </fx-select>
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="customer?.currency"
                                    >
                                        Currency is already set to {{ customer.currency | uppercase }}.
                                    </div>
                                </ng-container>

                                <ng-template #currencyNotSet>
                                    <div class="fx-form-control">
                                        <fx-select
                                            formControlName="currency"
                                            [ngClass]="{
                                                'fx-form-input--error': !control.valid && control.touched
                                            }"
                                            analyticsClick="payments_createpayment_currency"
                                        >
                                            <fx-option
                                                *ngFor="let currency of AcceptedCurrencies | keyvalue"
                                                [value]="currency.value"
                                            >
                                                {{ currency.key | uppercase }}
                                            </fx-option>
                                        </fx-select>
                                    </div>
                                    <div
                                        class="fx-form-validation"
                                        *ngIf="control.hasError('required') && control.touched"
                                    >
                                        Field is required.
                                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="payment-type">
                            <div
                                class="fx-form-group"
                                *ngIf="form.controls.paymentType; let control"
                            >
                                <label class="fx-form-label">Payment Type</label>
                                <div class="fx-form-control">
                                    <fx-select
                                        formControlName="paymentType"
                                        [ngClass]="{ 'fx-form-input--error': !control.valid && control.touched }"
                                        analyticsClick="payments_createpayment_type"
                                    >
                                        <fx-option [value]="PaymentTypes.OnceOffPayment">One off payment</fx-option>
                                        <fx-option [value]="PaymentTypes.RecurringPayment"
                                            >Subscription payment</fx-option
                                        >
                                        <fx-option [value]="PaymentTypes.ScheduledPayment"
                                            >Scheduled Subscription payment</fx-option
                                        >
                                    </fx-select>
                                </div>
                                <div
                                    class="fx-form-validation"
                                    *ngIf="control.hasError('required') && control.touched"
                                >
                                    Field is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                                </div>
                            </div>
                        </div>
                        <div
                            class="payment-amount"
                            *ngIf="
                                form.controls.paymentType.value &&
                                    (form.controls.paymentType.value === PaymentTypes.OnceOffPayment ||
                                        form.controls.paymentType.value === PaymentTypes.RecurringPayment ||
                                        form.controls.paymentType.value === PaymentTypes.ScheduledPayment) &&
                                    form.controls.paymentAmount;
                                let control
                            "
                        >
                            <threads-input
                                formControlName="paymentAmount"
                                [ngClass]="{ 'fx-form-input--error': !control.valid && control.touched }"
                                placeholder="eg 1000"
                            >
                                {{
                                    form.controls.paymentType.value === PaymentTypes.OnceOffPayment
                                        ? "Payment amount"
                                        : "Monthly payment amount"
                                }}
                            </threads-input>

                            <div
                                class="fx-form-validation"
                                *ngIf="control.hasError('required') && control.touched"
                            >
                                Field is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                            <div
                                class="fx-form-validation"
                                *ngIf="control.hasError('pattern') && control.touched"
                            >
                                Please enter a value using only numbers, for example 100.00.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>

                        <div
                            class="payment-description"
                            *ngIf="form.controls.paymentType.value && form.controls.description; let control"
                        >
                            <textarea
                                class="fx-form-input"
                                [ngClass]="{ 'fx-form-input--error': !control.valid && control.touched }"
                                textareaAutoresize
                                placeholder="Type your description here"
                                formControlName="description"
                            ></textarea>
                            <div
                                class="fx-form-validation"
                                *ngIf="control.hasError('required') && control.touched"
                            >
                                Field is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                    </div>

                    <fx-layout-column [sizes]="{ mobile: 24, tablet: 12 }">
                        <div
                            class="payment-date"
                            *ngIf="
                                form.controls.paymentType.value === PaymentTypes.ScheduledPayment &&
                                    form.controls.datePicker;
                                let control
                            "
                        >
                            <p>
                                A card will be created allowing users to add their billing details, after which they
                                will be billed on the specified date.
                            </p>
                            <fx-date-picker
                                class="fixed-width"
                                formControlName="datePicker"
                                [startDate]="tomorrow"
                                [range]="{ start: tomorrow }"
                            ></fx-date-picker>
                            <div
                                class="fx-form-validation"
                                *ngIf="control.hasError('required') && control.touched"
                            >
                                Field is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                            </div>
                        </div>
                    </fx-layout-column>
                    <fx-layout-column [sizes]="{ mobile: 24 }"
                        ><div
                            class="payment-create"
                            *ngIf="errorMessage"
                        >
                            <div class="thread-card-error">
                                {{ errorMessage }}
                            </div>
                        </div>
                    </fx-layout-column>
                </fx-layout>
            </form>
        </div>
    </div>
    <ng-container modal-success-button>Create</ng-container>
</centered-modal-layout>
