<div
    [ngStyle]="{ 'background-color': backgroundColor, color: color }"
    (click)="meetingClicked()"
    class="item"
>
    <fx-avatar
        [color]="color"
        [name]="type"
        [border]="false"
        format="initials"
        size="xs"
    >
    </fx-avatar>
    <span class="item-title">{{ title }}</span>
</div>
