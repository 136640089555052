import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ICreatePaymentCard } from "../../interfaces/ICreatePaymentCard";
import { IThread, IThreadCard } from "@visoryplatform/threads";
import { AcceptedCurrencies, ICustomer, PaymentTypes } from "@visoryplatform/payments-service-sdk";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ENVIRONMENT } from "src/app/injection-token";
import { DateTime } from "luxon";
import { environmentCommon } from "src/environments/environment";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { catchError, shareReplay, take } from "rxjs/operators";
import { PaymentService } from "../../services/payment.service";
import { Observable, throwError } from "rxjs";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { HandledError } from "projects/portal-modules/src/lib/shared/interfaces/errors";

type PaymentModalData = {
    thread?: IThread;
};

@Component({
    selector: "create-payment-modal",
    styleUrls: ["create-payment-modal.component.scss"],
    templateUrl: "create-payment-modal.component.html",
})
export class CreatePaymentModalComponent implements OnInit {
    readonly tailoredSubscriptionPriceId = environmentCommon.paymentsTailoredSubscriptions.tailoredSubscriptionId;
    readonly tomorrow = DateTime.fromJSDate(new Date()).plus({ days: 1 }).toISO();

    AcceptedCurrencies = AcceptedCurrencies;
    customer$: Observable<ICustomer>;
    PaymentTypes = PaymentTypes;
    loader = new Loader();
    errorMessage: string;
    form = new UntypedFormGroup({
        thread: new UntypedFormControl(null, [Validators.required]),
        paymentType: new UntypedFormControl(null, [Validators.required]),
        currency: new UntypedFormControl(null, [Validators.required]),
        paymentAmount: new UntypedFormControl("", [Validators.required, Validators.pattern("[0-9.]*")]),
        description: new UntypedFormControl("", [Validators.required]),
        datePicker: new UntypedFormControl(this.tomorrow),
    });

    data$: Observable<PaymentModalData>;
    private dialogRef: DialogRef;

    constructor(
        private paymentService: PaymentService,
        private router: Router,
        private cardService: ThreadCardService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private unsavedDialogService: UnsavedModalDialogService,
        private injector: Injector,
        private dialogService: DialogService,
    ) {
        this.data$ = this.dialogService.getData<PaymentModalData>(this.injector).pipe(shareReplay(1));
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();

        const data = await this.data$.pipe(take(1)).toPromise();
        const accountId = data.thread.accountId;

        this.loadDefaultCurrency(accountId);
        await this.loadThreads(data);
    }

    loadDefaultCurrency(accountId: string): void {
        this.customer$ = this.paymentService.getAccountCustomer(accountId).pipe(
            catchError((err: unknown) => this.handleError(err)),
            shareReplay(1),
        );
    }

    async createCard(): Promise<void> {
        this.loader.show();

        try {
            const subscriptionItems = this.getSubscriptionItems();
            const { thread, paymentType, description, paymentAmount, currency, datePicker } = this.form.value;

            const centsAmount = this.getCentsAmount(paymentAmount);

            const { payment } = environmentCommon.cardsEndpoints;
            const taxRateId = this.environment.payments.taxRateIds[currency];
            const data = {
                paymentType,
                description,
                amount: centsAmount,
                currency,
                subscriptionItems,
                startDate: paymentType === PaymentTypes.ScheduledPayment && datePicker,
                taxRateId,
            };

            await this.cardService
                .createStackCard<ICreatePaymentCard, IThreadCard>(thread.id, payment, data)
                .toPromise();

            await this.router.navigate(["/timelines", thread.id]);
            this.dialogRef.close();
        } catch (error) {
            this.errorMessage =
                error?.error?.message ||
                "Sorry, payment card could not be created. Please try again or contact support.";
            throw new HandledError(error);
        } finally {
            this.loader.hide();
        }
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payment-create");
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }

    private async loadThreads(data?: PaymentModalData): Promise<void> {
        this.loader.show();

        if (data.thread) {
            this.form.controls.thread.setValue(data.thread);
            this.loader.hide();
        }
    }

    private getCentsAmount(amount: string): string {
        const amountNum = Number(amount);

        return Math.round(amountNum * 100).toString();
    }

    private getSubscriptionItems(): { price: string; taxRateId: string }[] {
        const { paymentType, currency } = this.form.value;

        if (!this.paymentTypeIsSubscriptionBased(paymentType)) {
            return undefined;
        }
        const taxRateId = this.environment.payments.taxRateIds[currency];
        return [{ price: this.tailoredSubscriptionPriceId, taxRateId }];
    }

    private paymentTypeIsSubscriptionBased(paymentType: PaymentTypes): boolean {
        return paymentType === PaymentTypes.RecurringPayment || paymentType === PaymentTypes.ScheduledPayment;
    }

    private handleError(error: any): Observable<any> {
        if (error?.error?.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "A problem occurred fetching the user profile";
        }

        return throwError(new HandledError(error));
    }
}
