import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { filter, take } from "rxjs/operators";
import { IStaffProfile } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ProductTypes, StaffService } from "projects/portal-modules/src/lib/shared/services/staff.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { BehaviorSubject } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

enum SELECT_CSM_STATE {
    Matching = "MATCHING",
    ViewStaff = "VIEW_STAFF",
    BookMeeting = "BOOK_MEETING",
}

export interface ISelectCustomerSuccessManagerPayload {
    activeStaffMember: IStaffProfile;
}

@Component({
    selector: "app-select-customer-success-manager",
    templateUrl: "./select-customer-success-manager.component.html",
    styleUrls: ["./select-customer-success-manager.component.scss"],
})
export class SelectCustomerSuccessManagerComponent {
    @Input() analyticsPrefix = "";

    @Output()
    modelUpdate: EventEmitter<ISelectCustomerSuccessManagerPayload> = new EventEmitter();
    @Output()
    resetStaffSelection: EventEmitter<void> = new EventEmitter();
    @Output()
    enableFooter: EventEmitter<boolean> = new EventEmitter();

    readonly theme = this.environment.theme;
    readonly states = SELECT_CSM_STATE;
    readonly textResource = this.environment.featureFlags?.text;
    activeStaffMember$: BehaviorSubject<IStaffProfile> = new BehaviorSubject(null);

    activeStaffMemberIndex = 0;
    firstChoiceIndex = 0;
    staffMembers: IStaffProfile[];
    error: string;

    state$: BehaviorSubject<SELECT_CSM_STATE> = new BehaviorSubject(SELECT_CSM_STATE.ViewStaff);
    loader = new Loader();

    constructor(
        private staffService: StaffService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    async initialize(selectedStaff: IStaffProfile): Promise<void> {
        if (!selectedStaff) {
            this.loader.show();
            const matchedStaff = await this.match();
            this.loader.hide();
            this.activeStaffMember$.next(matchedStaff);

            this.modelUpdate.emit({
                activeStaffMember: this.activeStaffMember$.getValue(),
            });

            this.matchLoaderComplete();
        } else {
            this.state$.next(SELECT_CSM_STATE.ViewStaff);
        }
    }

    async match(): Promise<IStaffProfile> {
        const staffResponse = await this.staffService.fetchStaff({ product: ProductTypes.Bookkeeping }).toPromise();
        this.staffMembers = staffResponse.matchingStaff;

        const indexOfRecommended = this.staffMembers.indexOf(staffResponse.recommendedStaff);
        this.activeStaffMemberIndex = indexOfRecommended || 0;

        this.firstChoiceIndex = this.activeStaffMemberIndex;
        return this.staffMembers[this.activeStaffMemberIndex];
    }

    matchLoaderComplete(): void {
        this.activeStaffMember$
            .pipe(
                filter((staff) => !!staff),
                take(1),
            )
            .subscribe(() => {
                this.state$.next(SELECT_CSM_STATE.ViewStaff);
            });
    }

    staffMemberSelected(): void {
        this.activeStaffMemberIndex = this.staffMembers[this.activeStaffMemberIndex + 1]
            ? this.activeStaffMemberIndex + 1
            : 0;
        this.activeStaffMember$.next(this.staffMembers[this.activeStaffMemberIndex]);

        this.modelUpdate.emit({
            activeStaffMember: this.activeStaffMember$.getValue(),
        });
    }
}
