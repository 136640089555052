import { Component, Inject, Injector, OnInit } from "@angular/core";
import { IInvitee, IRecurrence } from "@visoryplatform/calendar-types";
import { ISlot } from "@visoryplatform/fx-ui/lib/components/calendar/calendar";
import { IThreadCard } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { ButtonType, IStepConfiguration, MultiComponentService } from "projects/portal-modules/src/lib/ui-layouts";
import { EMPTY, Observable, combineLatest } from "rxjs";
import { catchError, filter, finalize, map, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { IMeetingReviewData } from "../../interfaces/IMeetingReviewData";
import { CalendarService } from "../../services/calendar.service";

export interface ICalendarModalData {
    card: IThreadCard;
    invitationId: string;
    reschedule?: boolean;
}

export interface CalendarModel {
    invitationId?: string;
    participants: IInvitee[];
    rescheduleAppointment: boolean;
    meetingDescription?: string;
    organiser: { name: string; id: string };
    duration: number;
    title: string;
    recurrence: IRecurrence;
    message: { description: string };
}

@Component({
    selector: "app-calendar-modal",
    templateUrl: "./calendar-modal.component.html",
    styleUrls: ["./calendar-modal.component.scss"],
})
export class CalendarModalComponent implements OnInit {
    readonly theme = this.environment.theme;
    readonly CALENDAR_MAX_TIME_AHEAD: number = this.environment.featureFlags.calendarBookingLimits.maxTimeAheadMs;

    slots$: Observable<ISlot[]>;
    minDate: string;
    maxDate: string;
    duration: number;
    meetingReviewData: IMeetingReviewData;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Next",
                    type: ButtonType.Forward,
                    isDisabled: true,
                    isHidden: false,
                },
            ],
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Calendar",
                    type: ButtonType.Backward,
                    isDisabled: false,
                    isHidden: true,
                },
                {
                    title: "Yes, Book Meeting",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: true,
                },
            ],
        },
    ];
    errorMessage: string;
    loader = new Loader();
    data: CalendarModel = null;

    public props: ICalendarModalData;
    public dialogRef: DialogRef<ISlot>;

    private activeStepIndex = 0;

    constructor(
        private calendarService: CalendarService,
        private authService: AuthService,
        private multiComponentService: MultiComponentService,
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private injector: Injector,
        private dialogService: DialogService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef<ISlot>(this.injector).toPromise();
        this.props = await this.dialogService.getData<ICalendarModalData>(this.injector).toPromise();

        this.loader.show();
        const invite = await this.calendarService.getClientInvitation(this.props.invitationId).toPromise(); //TODO: drive from card state, update backend
        const organiser = await this.participantCache
            .getParticipant(this.props.card.createdBy)
            .pipe(take(1))
            .toPromise();

        this.data = {
            invitationId: this.props.invitationId,
            participants: [...invite.invitees, ...invite.staff],
            duration: invite.duration,
            organiser: { name: organiser.profile.name, id: organiser.id },
            rescheduleAppointment: !!this.props.reschedule,
            title: invite.details.title,
            recurrence: invite.recurrence,
            message: {
                description: invite.message.description,
            },
        };

        this.maxDate = new Date(Date.now() + this.CALENDAR_MAX_TIME_AHEAD).toISOString();
        this.minDate = await this.getStartDate().toPromise();
        this.monthChange(this.minDate);
        this.duration = this.data.duration;
        this.loader.hide();
    }

    monthChange(startDate: string | Date) {
        this.loader.show();
        const userRole$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
            take(1),
        );
        const availablity$ = this.calendarService
            .checkAvailability(this.data.invitationId, new Date(startDate).toISOString())
            .pipe(take(1));

        this.slots$ = combineLatest([userRole$, availablity$]).pipe(
            map(([role, availabilityResponse]) => {
                const slots = availabilityResponse.slots;
                return this.calendarService.filterSlotsByRole(role, slots);
            }),
            catchError(() => {
                this.errorMessage = "Sorry, something went wrong. Please contact support.";
                return EMPTY;
            }),
            take(1),
            finalize(() => this.loader.hide()),
        );
    }

    private getStartDate(): Observable<string> {
        return this.calendarService.getClientInvitation(this.data.invitationId).pipe(
            map((invitation) => {
                const today = new Date();
                if (invitation.dateRange.start) {
                    const startDate = new Date(invitation.dateRange.start);
                    if (startDate.getTime() > today.getTime()) {
                        return startDate.toISOString();
                    }
                }
                return today.toISOString();
            }),
        );
    }

    handleTransition(activeStepIndex: number) {
        this.stepConfigurations = [
            ...this.multiComponentService.showCurrentStepButtons(activeStepIndex, this.stepConfigurations),
        ];
        this.activeStepIndex = activeStepIndex;
    }

    handleSelection(event: ISlot) {
        this.meetingReviewData = {
            ...this.data,
            message: this.data.message.description,
            attendees: this.data.participants,
            date: event,
        };
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true),
        ];
    }

    onClose(confirmMeeting: boolean): void {
        if (confirmMeeting) {
            this.dialogRef.close(this.meetingReviewData?.date);
        } else {
            this.dialogRef.close();
        }
    }
}
