import { Injectable } from "@angular/core";
import { MetricBenchmark, TableReportRow } from "@visoryplatform/openmeasures-core";

@Injectable({ providedIn: "root" })
export class InsightsMetricService {
    constructor() {}

    public isMetricBelow(metric: TableReportRow, benchmark?: MetricBenchmark): boolean {
        //drives arrow direction but not the color
        if (benchmark) {
            return metric?.result?.value < benchmark?.percentiles[3];
        }
        return metric?.result?.value < metric?.target?.targetValue;
    }

    public isMetricBetterThanTarget(metric: TableReportRow, benchmark?: MetricBenchmark): boolean {
        //drives color, but NOT the arrow
        const isBelow = benchmark ? this.isMetricBelow(metric, benchmark) : this.isMetricBelow(metric);
        const isHigherBetter = metric?.metric?.isHigherBetter;

        const isHigherBetterAndBelowTarget = isHigherBetter && isBelow; //failure
        const isLowerBetterAndAboveTarget = !isHigherBetter && !isBelow; //failure

        return !isHigherBetterAndBelowTarget && !isLowerBetterAndAboveTarget;
    }

    public static formatMetric(metric: number | string, unit: string): string {
        if (metric == null) {
            return "—";
        }

        const metricNum = Number(metric);
        const isNegative = metricNum < 0;
        const displayVal = Math.abs(metricNum).toLocaleString("en");

        if (!unit) {
            return this.formatNegative(displayVal, isNegative);
        } else if (unit === "$") {
            return this.formatNegative(`${unit}${displayVal}`, isNegative);
        } else {
            return this.formatNegative(`${displayVal}${unit}`, isNegative);
        }
    }

    private static formatNegative(displayVal: string, isNegative: boolean): string {
        if (!isNegative) {
            return displayVal;
        }

        return `-${displayVal}`;
    }
}
