<a
    [routerLink]="['/timelines', threadId, 'cards', cardId]"
    target="_blank"
>
    <app-card
        [description]="message"
        [timestamp]="createdAt"
        [conversation]="false"
        [name]="actorId | userToName | async"
        [image]="actorId | profilePicture | async"
        [isInternal]="isInternal"
    ></app-card>
</a>
