<ng-container *ngIf="thread$ | async; let thread">
    <div
        class="thread"
        *ngIf="!loader.counter"
        [ngClass]="{ 'thread--disabled': !(thread | isThreadActive) }"
        resize-before-interaction
        (resizeBeforeInteraction)="handleContentSizeChange()"
    >
        <ng-container *ngFor="let group of uiCardsByDate; trackBy: trackGroup">
            <div
                class="thread-date-group-title"
                *ngIf="group.cards.length > 1"
            >
                <span class="thread-date-group-line">&nbsp;</span>
                <div class="thread-date-group-title-text">{{ group.date }}</div>
                <span class="thread-date-group-line">&nbsp;</span>
            </div>

            <ng-container *ngFor="let card of group.cards; trackBy: trackId">
                <div
                    class="thread-unread"
                    *ngIf="card.cardId === firstUnreadCardId"
                >
                    <span class="thread-unread-line">&nbsp;</span>
                    <div class="thread-unread-text">Unread messages</div>
                    <span class="thread-unread-line">&nbsp;</span>
                </div>

                <ui-card-portal
                    [uiCard]="card"
                    (cardUpdated)="regroupCards()"
                ></ui-card-portal>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
