<ng-container>
    <div class="request-item-text-response">
        <request-response
            *ngIf="showTextResponse"
            [userId]="userId"
            class="request-item-text-response-view"
            [message]="requestItem.response.text.state"
            [modifiedAt]="requestItem.response.text.modifiedAt"
            [modifiedBy]="requestItem.response.text.modifiedBy"
            [readonly]="readonly"
            (formDeleteResponse)="submitTextResponse(null)"
            (formSubmitResponse)="submitTextResponse($event)"
            (valueChanges)="responseUpdated($event)"
            (discardChanges)="
                toggleTextResponse(requestItem.response.complete.state, requestItem.response.text.state?.length)
            "
        ></request-response>
    </div>
    <div
        *ngIf="requestFilesData$ | async; let requestFiles"
        class="request-item-attached-files"
    >
        <div
            *ngFor="let file of requestFiles; trackBy: 'filename' | trackProperty"
            class="request-item-attached-files-attachment"
        >
            <div class="request-common-file">
                <i class="request-common-file-icon las la-paperclip"></i>
                <a
                    class="request-common-file-name"
                    (click)="downloadFile(file)"
                    >{{ file.filename }}</a
                >
            </div>
            <ng-container *ngIf="!readonly && !requestItem?.response?.complete?.state">
                <button
                    *ngIf="file.isDeleting || file.isLoading; else loading"
                    disabled
                    class="request-item-attached-files-loading"
                >
                    <i class="las la-spinner loading"></i>
                </button>
                <ng-template #loading>
                    <button
                        (click)="removeFile(file, requestFiles)"
                        analyticsClick="{{ analyticsPrefix }}_todo-remove-file"
                        class="request-item-attached-files-delete"
                    >
                        <i class="las la-times"></i>
                    </button>
                </ng-template>
            </ng-container>
        </div>
    </div>
    <div
        *ngIf="!readonly"
        class="request-item-buttons"
    >
        <button
            (click)="toggleTextResponse(requestItem.response.complete.state, requestItem.response.text.state?.length)"
            [disabled]="requestItem.response.complete.state || requestItem.response.text.state?.length"
            class="request-item-buttons-reply"
        >
            <i class="las la-comment-alt"></i>
            Add a response
        </button>
        <button
            (click)="toggleAttach(requestItem.response.complete.state)"
            [disabled]="requestItem.response.complete.state"
            class="request-item-buttons-attach"
        >
            <i class="las la-paperclip"></i>
            Attach files
        </button>
    </div>
    <div
        class="request-item-dropzone"
        *ngIf="showAttach && !requestItem?.response?.complete?.state"
    >
        <file-input
            [analyticsPrefix]="analyticsPrefix"
            (file)="attachFile($event)"
        ></file-input>
    </div>
</ng-container>
