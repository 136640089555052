import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FxContextMenuComponent } from "@visoryplatform/fx-ui";
import { NotificationState, WebsocketData } from "@visoryplatform/notifications-core";
import { INotification } from "src/app/interfaces/INotification";

@Component({
    selector: "notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
    @Input() notification: INotification<WebsocketData>;
    @Output() markAsRead = new EventEmitter<void>();
    @Output() markAsUnread = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() open = new EventEmitter<void>();

    @ViewChild("menu", { static: true }) menu: FxContextMenuComponent;
    NotificationState = NotificationState;

    markReadClick() {
        this.markAsRead.emit();
    }
    markUnreadClick() {
        this.markAsUnread.emit();
    }
    deleteClick() {
        this.delete.emit();
    }
}
