<div
    *ngIf="currentRole$ | async as role"
    class="internal-chat"
>
    <div
        *ngIf="internalChatThread$ | async as thread"
        class="internal-chat-body"
    >
        <section class="internal-chat-body-top-panel">
            <nav>
                <workflow-tabs [thread]="thread"></workflow-tabs>
            </nav>

            <div
                *ngIf="avatars$ | async as avatars"
                class="internal-chat-body-top-panel-avatars"
            >
                <v-avatars
                    size="s"
                    [avatars]="avatars"
                ></v-avatars>
            </div>
        </section>

        <section class="internal-chat-body-thread">
            <thread-route></thread-route>
        </section>
    </div>

    <loader-icon
        *ngIf="!role || loader.counter"
        color="light"
    ></loader-icon>
</div>
