<ng-container *ngIf="selectOptions">
    <select
        (change)="timeSelected(selectedTime.value)"
        [formControl]="selectedTime"
        class="time-picker-select"
    >
        <option
            *ngFor="let option of selectOptions"
            [selected]="option"
            [value]="option"
        >
            {{ option | date : "hh:mma" : offsetName }}
        </option>
    </select>
</ng-container>
