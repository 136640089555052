import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ForwardMessagePreviewComponent } from "./components/forward-message-preview/forward-message-preview.component";
import { ForwardMessageModalComponent } from "./components/forward-message-modal/forward-message-modal.component";
import { QuillModule } from "ngx-quill";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { AnalyticsModule } from "../../../analytics";
import { UiLayoutsModule } from "../../../ui-layouts/ui-layouts.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "../../../findex-ui/findex-ui.module";
import { ReadMoreModule } from "../read-more/read-more.module";
import { SelectServiceModule } from "../select-service/select-service.module";

const exportableDeclarations = [ForwardMessagePreviewComponent, ForwardMessageModalComponent];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [
        CommonModule,
        SharedPipesModule,
        ThreadsUiPipesModule,
        QuillModule,
        FxUiModule,
        ReadMoreModule,
        PortalUiModule,
        AnalyticsModule,
        UiLayoutsModule,
        ReactiveFormsModule,
        FindexUiModule,
        SelectServiceModule,
    ],
})
export class ForwardMessageModule {}
