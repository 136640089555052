<centered-modal-layout
    (save)="close()"
    (close)="close()"
    analyticsPrefix="calendar_empty_services_modal"
    saveButtonTitle="OK"
    cancelButtonTitle=""
>
    <div modal-header>Select an account and services</div>
    <div modal-content>
        <div class="calendar-empty-services-modal">
            <div class="calendar-empty-services-modal-text">
                Select the account you wish to view and one or more services for that account.
            </div>
        </div>
    </div>
</centered-modal-layout>
