import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IAccountListing, ITimeline, Role } from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { Loader } from "../../../shared/services/loader";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { AccountsService } from "../../../threads-ui/services/accounts.service";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { filter, shareReplay, take, map, switchMap, startWith } from "rxjs/operators";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { FeatureFlagService } from "../../../feature-flags";
import { CdkStepper } from "@angular/cdk/stepper";
import { PermissionService } from "../../../threads-ui/services/permissions.service";
import { AuthService } from "../../../findex-auth/services/auth.service";
import { RequestService } from "../../services/request.service";
import { CreateRequestType } from "@visoryplatform/threads";

export type CreateRequest = {
    account: IAccountListing | null;
    message: string;
    relatingToAccount?: boolean;
    requestCategory?: string;
};

@Component({
    selector: "create-request-modal",
    templateUrl: "./create-request-modal.component.html",
    styleUrls: ["./create-request-modal.component.scss"],
})
export class CreateRequestModalComponent {
    @ViewChild("stepper") stepper: CdkStepper;

    readonly gaEvents = GA_EVENTS;
    readonly messageSizeQuotaInKB = 128;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;
    loader = new Loader();
    isMobileView = false;
    createdThread: ITimeline;
    requestType: CreateRequestType;
    requestTypes = CreateRequestType;

    accounts$: Observable<IAccountListing[]>;
    customerButtonVisible$: Observable<boolean>;
    expertButtonVisible$: Observable<boolean>;
    phoneButtonVisible$: Observable<boolean>;
    emailButtonVisible$: Observable<boolean>;
    isStepperVisible$: Observable<boolean>;

    invalid$: Observable<boolean>;

    form: FormControl<CreateRequest> = new FormControl(
        {
            account: null,
            message: "",
            relatingToAccount: true,
            requestCategory: "",
        },
        { nonNullable: true },
    );

    constructor(
        private accountService: AccountsService,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private requestService: RequestService,
        private menuService: MenuService,
        private featureFlagService: FeatureFlagService,
        private permissionService: PermissionService,
        private authService: AuthService,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
        const role$ = this.authService.getGlobalRole();

        this.customerButtonVisible$ = role$.pipe(switchMap((role) => this.canCreateCustomerRequest(role)));
        this.expertButtonVisible$ = role$.pipe(switchMap((role) => this.canCreateExpertRequest(role)));
        this.phoneButtonVisible$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestPhoneButton,
        );
        this.emailButtonVisible$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestEmailButton,
        );
        this.isStepperVisible$ = combineLatest([
            this.customerButtonVisible$,
            this.expertButtonVisible$,
            this.phoneButtonVisible$,
            this.emailButtonVisible$,
        ]).pipe(
            map((res) => res.some(Boolean)),
            shareReplay(1),
        );

        this.invalid$ = this.form.valueChanges.pipe(
            map(() => this.form.invalid),
            startWith(true),
        );
    }

    configure(): void {
        this.accounts$ = this.loader.wrap(this.accountService.listAllAccounts()).pipe(shareReplay(1));

        const firstAccount$ = this.accounts$.pipe(
            filter((accounts) => accounts.length === 1),
            take(1),
        );

        firstAccount$.subscribe((accounts) => {
            const firstAccount = accounts[0];
            const previousValue = this.form.value;
            this.form.setValue({ ...previousValue, account: firstAccount });
        });
    }

    goBackToOptions(): void {
        this.requestType = null;
    }

    close(): void {
        this.menuService.hide(MenuType.CustomerRequest);
    }

    goToExpertCreate(): void {
        this.requestType = CreateRequestType.Expert;
        this.stepper.next();
        this.configure();
    }

    goToCustomerCreate(): void {
        this.requestType = CreateRequestType.Customer;
        this.stepper.next();
        this.configure();
    }

    goBackToCreate(): void {
        this.stepper.previous();
        this.form.reset();
        this.configure();
    }

    async create(): Promise<void> {
        const { account, message, requestCategory, relatingToAccount } = this.form.value;
        const isCustomer = this.requestType === CreateRequestType.Customer;
        const accountId = isCustomer || relatingToAccount ? account?.id : undefined;

        this.loader
            .wrap(this.requestService.createRequest(message, this.requestType, accountId, requestCategory))
            .pipe(take(1))
            .subscribe((thread) => {
                this.createdThread = thread;
                this.stepper.next();
            });
    }

    canCreateCustomerRequest(role: Role): Observable<boolean> {
        const permission = this.permissionService.hasSomePermission(role, ["CreateCustomerRequest", "ThreadUpdateAll"]);
        const featureFlag = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestMessageButton,
        );

        return combineLatest([permission, featureFlag]).pipe(
            map(([hasPermission, isEnabled]) => hasPermission && isEnabled),
        );
    }

    canCreateExpertRequest(role: Role): Observable<boolean> {
        const permission = this.permissionService.hasSomePermission(role, ["CreateExpertRequest", "ThreadUpdateAll"]);
        const featureFlag = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableExpertNewRequest);

        return combineLatest([permission, featureFlag]).pipe(
            map(([hasPermission, isEnabled]) => hasPermission && isEnabled),
        );
    }
}
