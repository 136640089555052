<loader-icon
    *ngIf="loading"
    color="light"
    [static]="true"
>
</loader-icon>

<centered-modal-layout
    *ngIf="!loading"
    [loading]="false"
    [cancelButtonTitle]="!pendingTasks?.length ? 'Go back' : 'Okay, got it'"
    saveButtonTitle="Ok"
    [disableSave]="pendingTasks?.length > 0"
    (save)="selectedThread ? copyMeetingsCards(selectedThread.id) : close(true)"
    (close)="close(false)"
>
    <div modal-header>{{ systemStepIds.End === workflowStepName ? "Close" : "Cancel" }} workflow</div>

    <div
        class="close-thread-prompt"
        modal-content
    >
        <ng-container *ngIf="!loading">
            <ng-container *ngIf="pendingTasks?.length">
                <p>
                    Your client still has pending tasks. You will need to complete these tasks before you are able to
                    {{ systemStepIds.End === workflowStepName ? "close" : "cancel" }} the workflow.
                </p>
            </ng-container>

            <p *ngIf="!hasUpcomingMeetings && !pendingTasks?.length && !loading">
                Are you sure you want to {{ systemStepIds.End === workflowStepName ? "close" : "cancel" }} this
                workflow?
            </p>

            <div
                *ngIf="pendingTasks?.length"
                class="close-thread-prompt-task-list"
            >
                <div
                    *ngFor="let task of pendingTasks"
                    class="close-thread-prompt-task-list-item"
                >
                    <a
                        [href]="'/workflows/' + task.threadId + '/cards/' + task.cardId + '?ignoreModalOpen=true'"
                        target="_blank"
                        ><strong
                            ><i
                                _ngcontent-grg-c449=""
                                class="la-external-link-alt las"
                            ></i>
                            {{ task.taskLabel }}</strong
                        ></a
                    >
                </div>
            </div>

            <ng-container *ngIf="hasUpcomingMeetings">
                <div class="close-thread-prompt-thread-list">
                    <p>
                        {{
                            threads?.length
                                ? "Looks like you have upcoming meetings booked in this workflow. If you would like to copy these meetings to a different workflow, please select from the list below."
                                : "Looks like you have upcoming meetings booked in this workflow. If you would like to copy them, please create a new workflow with the current participants and revisit this screen."
                        }}
                    </p>
                    <div
                        class="fx-form-group"
                        *ngIf="threads?.length"
                    >
                        <label class="fx-form-label">Active workflows</label>
                        <div class="fx-form-control">
                            <select
                                class="fx-form-select"
                                [(ngModel)]="selectedThread"
                            >
                                <option value="">No workflow selected</option>
                                <option
                                    *ngFor="let thread of threads"
                                    [ngValue]="thread"
                                >
                                    {{ thread.title }} - (<span [innerHTML]="thread.id"></span>)
                                </option>
                            </select>
                        </div>
                    </div>

                    <p>
                        <strong>
                            {{
                                threads?.length
                                    ? "If you do not wish to copy all outstanding meetings, these meetings will be cancelled. To cancel all meetings, don't select an active workflow and click 'OK'."
                                    : "If you do not wish to copy all upcoming meetings, please click 'OK' to proceed. All upcoming meetings will be cancelled."
                            }}
                        </strong>
                    </p>
                </div>
                <div
                    *ngIf="error"
                    class="close-thread-prompt-error"
                >
                    {{ error }}
                </div>
            </ng-container>
        </ng-container>
    </div>
</centered-modal-layout>
