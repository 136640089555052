<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div class="transactions">
    <fx-table
        *ngIf="tableData?.data"
        [tableData]="tableData"
        [trackBy]="'id' | trackProperty"
        [rowClickable]="true"
        [sortEnabled]="false"
    >
        <span *fxColumn="'check'; label: ''; mobileCollapse: true; let transaction">
            <div class="table-checkbox">
                <fx-checkbox
                    class="todo-item-green-checkbox"
                    [disabled]="true"
                    [checked]="!!transaction.query?.answer || !!transaction.query?.vaultDetails?.filename"
                >
                </fx-checkbox>
            </div>
        </span>
        <span *fxColumn="'accountLabel'; label: 'Bank account'; mobileCollapse: true; let transaction"
            ><div class="transactions-col">
                <span class="mobile-cell-label">Bank account: </span>{{ transaction.statementLine.accountLabel }}
            </div>
        </span>
        <span *fxColumn="'date'; label: 'Date'; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <span class="mobile-cell-label">Date: </span>
                {{ transaction.statementLine.date | date : "dd/MM/yyyy" }}
            </div>
        </span>
        <span *fxColumn="'amount'; label: 'Amount'; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <span class="mobile-cell-label">Amount: </span>
                {{ transaction.statementLine.amount | currency }}
            </div>
        </span>
        <span *fxColumn="'description'; label: 'Description'; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <span class="mobile-cell-label">Description: </span>
                <div class="transactions-description">
                    {{ transaction.statementLine.description }} {{ transaction.statementLine.reference }}
                </div>
            </div>
        </span>
        <span *fxColumn="'query'; label: 'Query'; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <span class="mobile-cell-label">Query: </span>
                <query-field
                    [formControl]="queryForms.controls[transaction.query.subjectId]"
                    [editMode]="editMode"
                ></query-field>
            </div>
        </span>
        <span *fxColumn="'answer'; label: 'Response'; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <span class="mobile-cell-label">Response: </span>
                <answer-field
                    [formControl]="answerForms.controls[transaction.query.subjectId]"
                    (uploadFile)="attachFile(transaction.query, $event)"
                    (deleteFile)="deleteFile(transaction.query)"
                    (clearAnswer)="clearAnswer(transaction.query)"
                ></answer-field>
            </div>
        </span>
        <span *fxColumn="'remove'; label: ''; mobileCollapse: true; let transaction">
            <div class="transactions-col">
                <i
                    *ngIf="editMode"
                    (click)="remove.emit(transaction.statementLine)"
                    class="las la-trash icon-size"
                ></i>
            </div>
        </span>
    </fx-table>
</div>
