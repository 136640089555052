import { Injectable } from "@angular/core";
import { VAvatar } from "@visoryplatform/portal-ui";
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { ProfilePictureService } from "./profile-picture.service";
import { IParticipantDetail } from "../interfaces/IParticipantDetail";

@Injectable({
    providedIn: "root",
})
export class UserAvatarService {
    constructor(private profilePictureService: ProfilePictureService) {}

    getAvatars(participants: IParticipantDetail[]): Observable<VAvatar[]> {
        if (!participants?.length) {
            const avatars: VAvatar[] = [];
            return of(avatars);
        }

        return forkJoin(participants.map((participant) => this.getParticipantAvatar(participant)));
    }

    private getParticipantAvatar(participant: IParticipantDetail): Observable<VAvatar> {
        return this.profilePictureService.getUserProfilePicture(participant?.id).pipe(
            map((url) => ({
                id: participant?.id,
                name: participant?.name || participant?.profile?.name,
                src: this.getAvatarUrl(url),
            })),
        );
    }

    private getAvatarUrl(url: string, secondary = false, getMaxSize = false): string {
        return `${url}?secondary=${secondary}&getMaxSize=${getMaxSize}`;
    }
}
