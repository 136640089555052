<div class="search-list">
    <div class="search-list-header">
        <h2>Search</h2>
        <div>
            <p>Search for messages across all of your workflows.</p>
        </div>
    </div>

    <div
        [formGroup]="form"
        class="search-list-mobile-search-control"
    >
        <search
            #searchComponent
            [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH"
            formControlName="search"
            placeholder="Search keywords or phrase"
            (keypress)="onKeyPress($event)"
            [debounceLimit]="DEBOUNCE_LIMIT"
            [characterLimit]="SEARCH_CHARACTER_LIMIT"
        ></search>
    </div>

    <global-search-ghost-loader *ngIf="!(results$ | async)"></global-search-ghost-loader>

    <ng-container *ngIf="queryParams$ | async as searchTerm">
        <ng-container *ngIf="results$ | async as response">
            <div
                *ngIf="loader.counter"
                class="search-list-header"
            >
                <h3>Searching for '{{ searchTerm }}'</h3>
                <p>Searching for messages in all of your workflows.</p>
            </div>

            <ng-container *ngIf="!loader.counter; else ghost">
                <div *ngIf="response.result.length; else noResults">
                    <div class="search-list-header">
                        <h3>Search results for '{{ searchTerm }}'</h3>
                        <p>Showing top search results from messages in all of your workflows.</p>
                    </div>
                    <global-search-card
                        *ngFor="let message of response.result"
                        [searchTerm]="searchTerm"
                        [message]="message"
                    ></global-search-card>
                </div>

                <ng-template #noResults>
                    <empty-state
                        title="No results found for '{{ searchTerm }}'"
                        htmlText="We couldn't find any results matching your search.<br>Check spelling or adjust your search keywords."
                        [imageType]="emptyState.NotFound"
                    ></empty-state>

                    <div
                        *ngIf="aiAssistantFeatureEnabled$ | async"
                        class="search-list-ai-assistant-container"
                    >
                        <button
                            [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH_ASK_AI_ASSISTANT"
                            class="fx-btn fx-btn--primary"
                            type="button"
                            (click)="routeToAssistantPage()"
                        >
                            <delphi-logo label="Ask {{ environment.aiName }}"></delphi-logo>
                        </button>
                        <p>Get help with your search from Visory's AI Assistant</p>
                    </div>
                </ng-template>
            </ng-container>

            <ng-template #ghost>
                <global-search-ghost-loader></global-search-ghost-loader>
            </ng-template>
        </ng-container>
    </ng-container>
</div>
