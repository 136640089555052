<div class="fx-onboarding-dialog">
    <div class="fx-onboarding-back">
        <button
            class="fx-onboarding-back-button"
            (click)="closeDialog()"
        >
            <i class="fx-onboarding-back-icon las la-arrow-left"></i>
        </button>
    </div>
    <div class="fx-onboarding-dialog-content">
        <forgot-password-form></forgot-password-form>
    </div>
</div>
