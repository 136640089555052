<centered-modal-layout
    (save)="actionPaymentCard(invoiceId)"
    (close)="close()"
    [disableSave]="form.invalid || !!loader.counter"
    [loading]="!!loader.counter"
    saveButtonTitle="{{ paymentType !== paymentTypes.ScheduledPayment ? 'PAY NOW' : 'ADD PAYMENT METHOD' }}"
    analyticsPrefix="payment_completepayment"
    *ngIf="data"
>
    <div
        class="payment-modal-inner"
        modal-content
    >
        <div class="payment-modal-inner-content">
            <div class="payment-modal-package">
                <div class="payment-modal-package-title">Payment</div>
                <div class="payment-modal-package-name">
                    {{ cardState.packageName }}
                </div>
                <div class="payment-modal-package-description">
                    {{ description }}
                </div>
                <div class="payment-modal-package-price">
                    <div class="payment-modal-package-price-dollar">
                        <div class="payment-modal-package-price-dollar-currency">
                            {{ 0 | currency : (cardState?.currency | uppercase) | slice : 0 : -4 }}
                        </div>
                        <div class="payment-modal-package-price-dollar-number">
                            {{ packagePriceDetails.priceDollars }}.{{ packagePriceDetails.priceCents }}
                        </div>
                    </div>
                    <div class="payment-modal-package-price-description">
                        <ng-container *ngIf="cardState.isRecurring">
                            <i class="las la-sync-alt payment-modal-added-details-period-icon"></i>
                            <div>This is a recurring direct debit</div>
                        </ng-container>
                        <div *ngIf="!cardState.isRecurring">This is a once-off payment</div>
                    </div>
                </div>
            </div>
            <div class="payment-modal-added-details">
                <div class="payment-modal-added-details-card-icon">
                    <div
                        class="payment-modal-added-details-card-icon-text"
                        *ngIf="cardState.isRecurring && paymentType !== paymentTypes.ScheduledPayment"
                    >
                        {{
                            (0 | currency : (cardState?.currency | uppercase) | slice : 0 : -4) +
                                packagePriceDetails.priceDollars
                        }}.{{ packagePriceDetails.priceCents }} will be billed on the
                        {{ billingDate.getDate() | formatOrdinalDate }} of each month
                    </div>
                    <div
                        class="payment-modal-added-details-card-icon-text"
                        *ngIf="cardState.isRecurring && paymentType === paymentTypes.ScheduledPayment"
                    >
                        {{
                            (0 | currency : (cardState?.currency | uppercase) | slice : 0 : -4) +
                                packagePriceDetails.priceDollars
                        }}.{{ packagePriceDetails.priceCents }} will be billed on the
                        {{ billingDate.getDate() | formatOrdinalDate }} of each month beginning on
                        {{ billingDate | date : "MMMM d y" }}
                    </div>
                </div>
            </div>
            <div class="payment-modal-card-details">
                <form [formGroup]="form">
                    <div class="payment-modal-card-details-head">
                        <div class="payment-modal-card-details-head-title">Your billing details</div>
                    </div>
                    <div class="payment-modal-card-details-row">
                        <div class="payment-modal-card-details-row-multi-column">
                            <div class="payment-modal-card-details-name">
                                <label
                                    class="fx-form-label"
                                    for="name"
                                    >Business Name</label
                                >
                                <div class="fx-form-control">
                                    <input
                                        class="fx-form-input"
                                        formControlName="name"
                                        placeholder="John Smith"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['name'].valid && form.controls['name'].touched
                                        }"
                                    />
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                                >
                                    Name is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-card-details-row">
                        <div class="payment-modal-card-details-row-multi-column">
                            <label
                                class="fx-form-label"
                                for="address"
                                >Street Address</label
                            >
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    formControlName="address"
                                    placeholder="300/171 Williams St"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['address'].valid && form.controls['address'].touched
                                    }"
                                />
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    form.controls['address'].hasError('required') && form.controls['address'].touched
                                "
                            >
                                Street Address is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div class="payment-modal-card-details-row-multi-column">
                            <label
                                class="fx-form-label"
                                for="city"
                                >City</label
                            >
                            <div class="fx-form-control">
                                <input
                                    class="fx-form-input"
                                    formControlName="city"
                                    placeholder="Melbourne"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['city'].valid && form.controls['city'].touched
                                    }"
                                />
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched"
                            >
                                City is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-card-details-row">
                        <div
                            class="payment-modal-card-details-row-multi-column payment-modal-card-details-row-multi-column--column-wrap"
                        >
                            <div class="payment-modal-card-details-state">
                                <label
                                    class="fx-form-label"
                                    for="state"
                                    >State</label
                                >
                                <div class="fx-form-control">
                                    <input
                                        class="fx-form-input"
                                        formControlName="state"
                                        placeholder="Victoria"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['state'].valid && form.controls['state'].touched
                                        }"
                                    />
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="
                                        form.controls['state'].hasError('required') && form.controls['state'].touched
                                    "
                                >
                                    State is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                            <div class="payment-modal-card-details-postcode">
                                <label
                                    class="fx-form-label"
                                    for="postcode"
                                    >Postcode</label
                                >
                                <div class="fx-form-control">
                                    <input
                                        class="fx-form-input"
                                        formControlName="postcode"
                                        placeholder="3000"
                                        [ngClass]="{
                                            'fx-form-input--error':
                                                !form.controls['postcode'].valid && form.controls['postcode'].touched
                                        }"
                                    />
                                </div>
                                <div
                                    class="fx-form-validation brand-danger"
                                    *ngIf="
                                        form.controls['postcode'].hasError('required') &&
                                        form.controls['postcode'].touched
                                    "
                                >
                                    Postcode is required.
                                    <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                                </div>
                            </div>
                        </div>
                        <div class="payment-modal-card-details-country">
                            <label
                                class="fx-form-label"
                                for="country"
                                >Country</label
                            >
                            <div class="fx-form-control">
                                <select
                                    class="fx-form-select"
                                    formControlName="country"
                                    [ngClass]="{
                                        'fx-form-input--error':
                                            !form.controls['country'].valid && form.controls['country'].touched
                                    }"
                                >
                                    <option
                                        value=""
                                        [selected]="true"
                                        [disabled]="true"
                                    >
                                        Select
                                    </option>
                                    <ng-container *ngFor="let country of acceptedCountries">
                                        <option [ngValue]="country.countryCode">{{ country.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    form.controls['country'].hasError('required') && form.controls['country'].touched
                                "
                            >
                                Country is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                    </div>
                    <div class="payment-modal-card-details-head">
                        <div class="payment-modal-card-details-head-title">Payment method</div>
                        <div class="payment-modal-card-details-head-description">
                            We accept all major credit and debit cards.
                        </div>
                    </div>
                    <div *ngIf="elementsOptions?.clientSecret">
                        <ngx-stripe-payment
                            [stripe]="stripe"
                            [appearance]="appearance"
                            [clientSecret]="elementsOptions?.clientSecret"
                        ></ngx-stripe-payment>
                    </div>
                </form>
            </div>
            <div
                class="thread-card-error"
                *ngIf="errorMessage"
            >
                {{ errorMessage }}
            </div>
        </div>
    </div>
</centered-modal-layout>
