import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "lib-calendar-milestone",
    templateUrl: "./calendar-milestone.component.html",
    styleUrls: ["./calendar-milestone.component.scss"],
})
export class CalendarMilestoneComponent {
    @Input() public color: string;
    @Input() public backgroundColor: string;
    @Input() public type: string;
    @Input() public title: string;

    @Output() public milestoneClicked = new EventEmitter();

    constructor() {}

    public meetingClicked(): void {
        this.milestoneClicked.emit();
    }
}
