import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class StorageService {
    getItem(key: string): unknown {
        const item = localStorage.getItem(key);
        if (!item) {
            return undefined;
        }
        try {
            return JSON.parse(item);
        } catch (err) {
            return item;
        }
    }

    setItem(key: string, item: string): void {
        localStorage.setItem(key, item);
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}
