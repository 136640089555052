<div class="view-assignees">
    <label class="view-assignees-label">
        <span>Assigned to </span>
        <span>{{ step.isAssignedToClient ? assignedTo.client : assignedTo.visory }}</span>
    </label>
    <div class="view-assignees-container">
        <ng-container *ngFor="let assignee of step.assignees">
            <fx-avatar
                [name]="assignee | userToName | async"
                [image]="assignee | profilePicture | async"
                size="medium"
            ></fx-avatar>
        </ng-container>
    </div>
</div>
