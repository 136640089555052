<div class="create-bill-approval-control-item">
    <label
        class="date-range-label"
        id="dateRangeLabel"
    >
        Due date range
    </label>

    <div class="date-picker">
        <div class="date-picker-input-container">
            <mat-form-field>
                <mat-date-range-input
                    [rangePicker]="picker"
                    [formGroup]="form"
                >
                    <input
                        matStartDate
                        formControlName="startDate"
                        placeholder="Start"
                        aria-labelledby="dateRangeLabel"
                    />
                    <input
                        matEndDate
                        formControlName="endDate"
                        placeholder="End"
                        aria-labelledby="dateRangeLabel"
                    />
                </mat-date-range-input>
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                    ><i
                        class="las la-calendar new-thread-rhythm-icon"
                        matDatepickerToggleIcon
                    ></i
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
