<section>
    <div
        *ngIf="card"
        class="request-subhead"
    >
        <fx-avatar
            [name]="card?.createdBy | userToName | async"
            [image]="card?.createdBy | profilePicture | async"
            size="small"
        ></fx-avatar>
        <span class="request-subhead-name">{{ card?.createdBy | userToName | async }}&nbsp;</span>
        <span>created this request on&nbsp;</span>
        <span class="request-subhead-date">{{ card?.createdAt | date : "EEEE dd MMMM yyyy" }}</span>
    </div>

    <div
        *ngIf="description"
        class="request-subhead-subtitle"
    >
        {{ description }}
    </div>
</section>
