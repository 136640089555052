import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

export const loggedInGuard: CanActivateFn = (): Observable<any> => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.getUserWithoutRole().pipe(
        switchMap((user) => {
            if (user) {
                router.navigateByUrl("/dashboard");
                return of(false);
            } else {
                return of(true);
            }
        }),
    );
};
