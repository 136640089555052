import { Component, Inject, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { InsightsService } from "../../services/insights.service";
import { IVaultReportGroup } from "../../interfaces/IInsightsReportGroup";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { IVaultFile, VaultService } from "@visoryplatform/vault";
import { IVaultReport } from "@visoryplatform/threads";
import { ActivatedRoute, Params } from "@angular/router";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { IVaultContents } from "@visoryplatform/vault-core";

@Component({
    selector: "insights-reporting",
    templateUrl: "insights-reporting.component.html",
    styleUrls: ["insights-reporting.component.scss"],
})
export class InsightsReportingComponent implements OnInit {
    loader = new Loader();
    emptyStateImage = this.environment.featureFlags.insightsConfiguration.emptyStateImage;
    isMobileView: boolean;

    reportToShow: IVaultReport;
    activeReportVaultId: string;
    activeReportFileId: string;

    reportGroups$: Observable<IVaultReportGroup[]>;
    reportFile$: Observable<IVaultFile>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private insightsService: InsightsService,
        private vaultService: VaultService,
        private route: ActivatedRoute,
        private windowListenersService: WindowListenersService,
        private location: Location,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    ngOnInit(): void {
        this.setReportGroups();
        this.setReportFile();
    }

    selectInsightsReport(insightsReport: IVaultReport): void {
        this.reportToShow = insightsReport;
        this.setFileAndVaultId(insightsReport?.vaultId, insightsReport?.file?.id);
        this.setReportFile();
    }

    async downloadReport(selectedReport: IVaultReport): Promise<void> {
        const downloadWindow = window.open("", "_self");
        downloadWindow.location.href = await this.vaultService
            .getDownloadUrl(selectedReport.vaultId, selectedReport.file.id, selectedReport.file.fileName)
            .toPromise();
        this.loader.hide();
    }

    private setReportGroups(): void {
        this.reportGroups$ = this.route.params.pipe(
            switchMap((params: Params) => {
                const { userId, vaultId, fileId } = params;

                const insights$ = this.insightsService.getInsightsReports(userId);
                this.setFileAndVaultId(vaultId, fileId);
                return this.loader.wrap(insights$);
            }),
            // this.setSelectedReport() lives in the "tap" to avoid "error: NG0100: Expression has changed after it was checked"
            tap((reportGroups: IVaultReportGroup[]) => {
                this.setSelectedReport(reportGroups);
            }),
            shareReplay(1),
        );
    }

    private setSelectedReport(reportGroups: IVaultReportGroup[]): void {
        const groupsWithReports = reportGroups.filter((group) => group.reports?.length);
        this.reportToShow = this.getReportToShow(groupsWithReports);
        if (this.reportToShow) {
            this.setFileAndVaultId(this.reportToShow.vaultId, this.reportToShow.file.id);
        }
    }

    private setReportFile(): void {
        this.reportFile$ = this.reportGroups$.pipe(
            switchMap(() => {
                const vault$ = this.vaultService.getVault(this.reportToShow.vaultId);
                return this.loader.wrap(vault$);
            }),
            map((vaultContents: IVaultContents) => {
                this.replaceUrl("/insights/reports");
                return vaultContents.files.find((file) => file.fileId === this.activeReportFileId);
            }),
        );
    }

    private setFileAndVaultId(vaultId: string, fileId: string): void {
        this.activeReportVaultId = vaultId;
        this.activeReportFileId = fileId;
    }

    private getReportToShow(groups: IVaultReportGroup[]): IVaultReport {
        let firstReport = null;
        for (const group of groups) {
            if (!group.reports?.length) {
                continue;
            }
            if (!firstReport) {
                firstReport = group.reports[0];
            }

            const activeReport = group.reports.find((report) => report.file?.id === this.activeReportFileId);
            if (activeReport) {
                return activeReport;
            }
        }
        return firstReport;
    }

    private replaceUrl(url: string): void {
        this.location.replaceState(url);
    }
}
