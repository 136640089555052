<div class="new-thread-rhythm">
    <label class="new-thread-rhythm-date-picker-label"
        >Due date <span *ngIf="isDifferentZone">({{ zone | timezoneLabel }})</span></label
    >

    <div class="new-thread-rhythm-date-picker">
        <div class="date-picker">
            <div class="new-thread-rhythm-date-picker-input-container">
                <mat-form-field>
                    <input
                        matInput
                        (dateChange)="onDayChanged($event)"
                        [formControl]="dateTimeControl"
                        [matDatepicker]="picker"
                        [name]="step.label"
                        class="date-picker-input"
                        required
                    />
                    <mat-datepicker-toggle
                        class="date-picker-toggle"
                        [for]="picker"
                        matSuffix
                    >
                        <i
                            class="las la-calendar new-thread-rhythm-icon"
                            matDatepickerToggleIcon
                        ></i>
                    </mat-datepicker-toggle>
                </mat-form-field>
            </div>
        </div>
        <div
            *ngIf="dateTimeControl.value"
            class="new-thread-rhythm-date-picker-time-container"
        >
            <time-picker
                [initialTime]="dateTimeControl.value"
                (timeChanged)="onStepTimeChange($event)"
            >
            </time-picker>
        </div>

        <mat-datepicker #picker></mat-datepicker>
    </div>
</div>

<div
    *ngIf="isDifferentZone"
    class="different-timezone"
>
    <label class="new-thread-rhythm-date-picker-label">Your timezone</label>
    <div>{{ userTimezoneDate | dateTime : "MMM d, h:mm a" }}</div>
</div>
