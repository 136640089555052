import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmptyStateComponent } from "./components/empty-state.component";

const declarations = [EmptyStateComponent];

@NgModule({
    imports: [CommonModule],
    declarations: [...declarations],
    exports: [...declarations],
})
export class EmptyStateModule {}
