<centered-modal-layout
    (save)="acceptPrompt()"
    (close)="close()"
    [cancelButtonTitle]="declineText"
    [saveButtonTitle]="confirmText"
    [analyticsPrefix]="analyticsPrefix"
    [analyticsPostfix]="analyticsPostfix"
>
    <div modal-header>{{ promptText }}</div>
    <div modal-content>{{ areYouSureText }}</div>
</centered-modal-layout>
