import { Pipe, PipeTransform } from "@angular/core";
import { DateTime, DurationObjectUnits } from "luxon";

@Pipe({
    name: "timeAgo",
    pure: true,
})
export class TimeAgoPipe implements PipeTransform {
    transform(date: string): string {
        const now = DateTime.now();
        const targetDate = DateTime.fromISO(date);
        const diff = now.diff(targetDate, ["years", "months"]).toObject();

        if (diff?.years) {
            const formatYearsAndMonths = this.formatYearsAndMonths(diff);
            return formatYearsAndMonths;
        }

        const relativeTime = targetDate.toRelative();
        const formattedRelativeTime = this.sentenceCase(relativeTime);

        if (this.showLastOrAgo(formattedRelativeTime)) {
            return this.sentenceCase(formattedRelativeTime.replace("1", "last").replace("ago", "")).trim();
        }

        return formattedRelativeTime;
    }

    private sentenceCase(relativeTime: string): string {
        if (!relativeTime) {
            return relativeTime;
        }
        return relativeTime[0].toUpperCase() + relativeTime.substring(1);
    }

    private showLastOrAgo(date: string): boolean {
        if (!date) {
            return false;
        }

        if (
            date.includes("1") &&
            !date.includes("hour") &&
            !date.includes("hours") &&
            !date.includes("day") &&
            !date.includes("days") &&
            !date.includes("minute") &&
            !date.includes("minutes") &&
            !date.includes("second") &&
            !date.includes("seconds") &&
            !date.includes("10") &&
            !date.includes("11")
        ) {
            return true;
        }
        return false;
    }

    private formatYearsAndMonths(diff: DurationObjectUnits): string {
        const diffMonths = Math.floor(diff.months || 0);
        if (diffMonths === 0) {
            return diff.years === 1 ? `Last year` : `${diff.years} years ago`;
        } else {
            return `${diff.years} year${diff.years > 1 ? "s" : ""} ${diffMonths} months ago`;
        }
    }
}
