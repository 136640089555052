<div class="dashboard-header">
    <div class="dashboard-header-title">
        <v-badge-round
            [color]="badgeColors.pink"
            size="m"
        >
            <i class="las la-project-diagram"></i>
        </v-badge-round>
        <span>Active workflows</span>
    </div>
    <div>
        <a
            [routerLink]="['/timelines']"
            [analyticsClick]="gaEvents.DASHBOARD_VIEW_ALL_WORKFLOWS"
            class="dashboard-header-view-all"
            >View all</a
        >
    </div>
</div>
