<div
    class="invite-client"
    *ngIf="!showSuccessModal"
>
    <div class="invite-client-inner">
        <div class="invite-client-close">
            <div
                class="fx-l-modal-header-icon"
                (click)="exitModal()"
            >
                <i class="las la-times invite-client-close-icon"></i>
            </div>
        </div>
        <h2 class="invite-client-title">Invite People</h2>
        <p class="invite-client-text">
            New people will be able to see all chat history, including any documents shared in this workflow.
        </p>

        <ng-container *ngIf="!loader.counter">
            <div class="invite-client-details">
                <div
                    class="invite-client-details-row"
                    *ngFor="let userToAddFormGroup of formArray.controls; index as i"
                >
                    <ng-container [formGroup]="$any(userToAddFormGroup)">
                        <div class="invite-client-details-first">
                            <label
                                for="firstName"
                                class="invite-client-details-header"
                            >
                                First Name
                            </label>
                            <input
                                formControlName="firstName"
                                class="fx-form-input"
                                placeholder="John"
                                aria-label="input first name"
                            />
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    userToAddFormGroup.get('firstName').invalid &&
                                    userToAddFormGroup.get('firstName').touched
                                "
                            >
                                First name is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div class="invite-client-details-last">
                            <label
                                for="lastName"
                                class="invite-client-details-header"
                            >
                                Last Name
                            </label>
                            <input
                                formControlName="lastName"
                                class="fx-form-input"
                                placeholder="Doe"
                                aria-label="input last name"
                            />
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    userToAddFormGroup.get('lastName').invalid &&
                                    userToAddFormGroup.get('lastName').touched
                                "
                            >
                                Last name is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div class="invite-client-details-email">
                            <label
                                for="emailAddress"
                                class="invite-client-details-header"
                            >
                                Email Address
                            </label>
                            <input
                                formControlName="emailAddress"
                                class="fx-form-input"
                                placeholder="johndoe@email.com"
                                aria-label="input email"
                            />
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    userToAddFormGroup.get('emailAddress').invalid &&
                                    userToAddFormGroup.get('emailAddress').touched
                                "
                            >
                                Email is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div class="invite-client-details-mobile">
                            <label
                                for="mobile"
                                class="invite-client-details-header"
                            >
                                Mobile
                            </label>
                            <input
                                formControlName="mobile"
                                class="fx-form-input"
                                aria-label="input mobile"
                                intlTel
                                [intlTelOptions]="{
                                    initialCountry: 'au',
                                    onlyCountries: signupCountries,
                                    allowDropdown: signupCountries.length > 1,
                                    customContainer: 'fx-onboarding-intl-input'
                                }"
                                [intlTelInitialValue]="userToAddFormGroup.get('mobile').value"
                                (intlTelCurrentValue)="userToAddFormGroup.get('mobileInternational').setValue($event)"
                            />
                            <div
                                class="fx-form-validation brand-danger"
                                *ngIf="
                                    userToAddFormGroup.get('mobile').invalid && userToAddFormGroup.get('mobile').touched
                                "
                            >
                                Mobile is required.
                                <i class="fx-form-validation-icon las la-exclamation-triangle brand-danger"></i>
                            </div>
                        </div>
                        <div
                            class="invite-client-details-remove"
                            *ngIf="formArray.controls.length > 1"
                        >
                            <div
                                class="fx-l-modal-header-icon invite-client-details-icon"
                                (click)="removeInvitee(i)"
                            >
                                <i class="las la-times-circle invite-client-details-icon-size"></i>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <p
                class="invite-client-add"
                (click)="addAnotherInvitee()"
            >
                Add another person
            </p>
            <button
                class="fx-btn fx-btn--primary-outlined invite-client-send"
                [disabled]="!formArray.valid"
                (click)="sendInvite()"
            >
                ADD
            </button>
            <ng-container *ngIf="errorMessages?.length">
                <ng-container *ngFor="let errorMessage of errorMessages">
                    <div class="thread-card-error">
                        {{ errorMessage }}
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <loader-icon
            *ngIf="loader.counter"
            color="light"
            [static]="true"
        ></loader-icon>
    </div>
</div>
<div
    class="invite-client"
    *ngIf="showSuccessModal"
>
    <div class="invite-client-inner">
        <div class="brand-gradient-circle">
            <img
                class="brand-gradient-circle-icon"
                src="./assets/images/email/paper-plane.svg"
            />
        </div>
        <div class="invite-client-title invite-client-title-center">Invite link is on the way</div>
        <!-- TODO: re-enable when we have content
        <div class="invite-client-text invite-client-title-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis</div>
    -->
        <button
            class="fx-btn fx-btn--primary-outlined invite-client-done"
            (click)="exitModal()"
        >
            Done
        </button>
        <div
            class="invite-client-more"
            (click)="addMorePeople()"
        >
            ADD MORE PEOPLE
        </div>
    </div>
</div>
