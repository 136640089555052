<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<ng-container *ngIf="!loader.counter && tableData$ | async as tableData">
    <fx-table
        *ngIf="tableData?.data?.length; else noData"
        [tableData]="tableData"
        [selectable]="false"
        [trackBy]="trackMemory"
    >
        <div *fxColumn="'prompt'; label: 'Prompt'; sortable: true; mobileCollapse: true; let memory">
            <fx-badge
                *ngIf="memory?.prompt?.label"
                [label]="memory.prompt.label"
                type="default"
            ></fx-badge>
        </div>
        <div *fxColumn="'badge'; label: 'Service'; sortable: true; mobileCollapse: true; let memory">
            <thread-type-badge [threadType]="(memory.badge | threadTypeName | async) || '...'"></thread-type-badge>
        </div>
        <div *fxColumn="'memory'; label: 'Memory'; sortable: true; mobileCollapse: true; let memory">
            <span>{{ memory.memory }}</span>
        </div>
        <div *fxColumn="'createdAt'; label: 'Created At'; sortable: true; mobileCollapse: true; let memory">
            <span>{{ memory.createdAt | date }}</span>
        </div>
        <div *fxColumn="'path'; label: 'Actions'; sortable: true; mobileCollapse: true; let memory">
            <a
                [href]="memory.path"
                class="fx-btn fx-btn--primary fx-btn--small"
                type="button"
                target="_blank"
            >
                Source
                <i class="las la-external-link-alt"></i>
            </a>
            <button
                *ngIf="canDelete"
                (click)="removeMemory.emit(memory)"
                class="fx-btn fx-btn--outline fx-btn--small"
                type="button"
                target="_blank"
            >
                Remove
            </button>
        </div>
    </fx-table>

    <ng-template #noData>
        <div class="empty">
            <h2>No memories extracted</h2>
        </div>
    </ng-template>
</ng-container>
