import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    readonly protectedResources = this.environment.auth.protectedResources;
    readonly unprotectedResources = this.environment.auth.unprotectedResources || [];
    readonly includeCredentialsEndpoints = this.environment.auth.includeCredentialsEndpoints;
    readonly publicUrl = this.environment.publicEndpoints.base;

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const shouldAuth = !!this.protectedResources.find(
            (endpoint) => req.url.startsWith(endpoint) && !req.url.includes(this.publicUrl),
        );

        const skipAuth = !!this.unprotectedResources.find((endpoint) => req.url.startsWith(endpoint));

        if (!shouldAuth || skipAuth) {
            return next.handle(req);
        }

        const shouldIncludeCredentials = !!this.includeCredentialsEndpoints.find(
            (endpoint) => req.url.startsWith(endpoint) || req.url.includes(this.publicUrl),
        );
        const updatedRequest = req.clone({ withCredentials: shouldIncludeCredentials });
        return this.handleRequest(updatedRequest, next);
    }

    private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.intercept(req, next);
    }
}
