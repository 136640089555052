import { Component, Input } from "@angular/core";
import { ICardEvent } from "@visoryplatform/threads";

@Component({
    selector: "nested-message",
    templateUrl: "./nested-message.component.html",
    styleUrls: ["./nested-message.component.scss"],
})
export class NestedMessageComponent {
    @Input() event: ICardEvent;
}
