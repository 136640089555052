import { Component, Input } from "@angular/core";
import { IThreadCard } from "@visoryplatform/threads";

@Component({
    selector: "request-subheader",
    templateUrl: "./request-subheader.component.html",
    styleUrls: ["./request-subheader.component.scss"],
})
export class RequestSubheaderComponent {
    @Input() card: IThreadCard;
    @Input() description: string;
}
