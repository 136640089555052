import { Pipe, PipeTransform } from "@angular/core";
import { UserToNamePipe } from "../../threads-ui/pipes/user-to-name";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Pipe({ name: "userShortName", pure: true })
export class UserShortNamePipe implements PipeTransform {
    constructor(private userToNamePipe: UserToNamePipe) {}

    /**
     Returns the first name and initial of last name. E.g. "James Madison Smith" returns "James S"
     **/
    transform(value: string): Observable<string> {
        return this.userToNamePipe.transform(value).pipe(
            map((userName) => {
                if (!userName) {
                    return "Deleted";
                }
                const splitName = userName.split(" ");
                if (splitName.length === 1) {
                    return splitName.shift();
                } else {
                    return `${splitName.shift()} ${splitName.pop().charAt(0)}`;
                }
            }),
        );
    }
}
