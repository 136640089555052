import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IVaultReport } from "@visoryplatform/threads";

@Component({
    selector: "insights-report-list-item",
    templateUrl: "./insights-report-list-item.component.html",
    styleUrls: ["./insights-report-list-item.component.scss"],
})
export class InsightsReportListItemComponent {
    @Input() report: IVaultReport;
    @Output() emitSelected: EventEmitter<IVaultReport> = new EventEmitter();
    @Output() emitDownloadReport: EventEmitter<IVaultReport> = new EventEmitter();
    @Input() active = false;
    constructor() {}

    selectReport(report: IVaultReport) {
        if (!this.active) {
            this.emitSelected.next(report);
            this.active = !this.active;
        }
    }
}
