import { Pipe, PipeTransform } from "@angular/core";
import { rrulestr } from "rrule";

@Pipe({
    name: "rruleFrequencyDescription",
})
export class RruleFrequencyDescriptionPipe implements PipeTransform {
    transform(rruleString: string): string {
        if (!rruleString?.length) {
            return "";
        }
        const rrule = rrulestr(rruleString);

        try {
            return rrule.toText() || null;
        } catch (err) {
            console.error("Rrule unable to parse the string - description", err);
            return "";
        }
    }
}
