<ng-container [formGroup]="form">
    <ng-container *ngIf="showWorkflowOptionSelectors">
        <div class="fx-form-group form-radio">
            <ng-container>
                <input
                    type="radio"
                    id="configured-workflow"
                    formControlName="configurationOption"
                    class="form-radio-input"
                    [value]="WorkflowConfigurationOptions.Configured"
                    checked
                />
                <label
                    class="fx-form-label"
                    for="configured-workflow"
                    >Configured workflow</label
                >
                <p class="label-sublabel">Dates and assignees will be as per account configuration</p>

                <input
                    type="radio"
                    id="blank-workflow"
                    formControlName="configurationOption"
                    [value]="WorkflowConfigurationOptions.Blank"
                    class="form-radio-input"
                />

                <label
                    class="fx-form-label"
                    for="blank-workflow"
                    >Blank workflow</label
                >
                <p class="label-sublabel">Assignees will be blank</p>
            </ng-container>
        </div>
    </ng-container>

    <div
        class="v-select-container"
        *ngIf="!!form.value.configurationOption"
    >
        <label class="fx-form-label">Service:</label>
        <div class="v-select-container">
            <v-select
                [disabled]="workflowDesignTypes?.length === 0"
                placeholder="Select a service"
                [options]="threadTypes$ | async | orderBy : ['threadTypeLabel', sortOption.ASC]"
                [optionContent]="optionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="threadType"
            >
            </v-select>
        </div>

        <ng-template
            #optionContent
            let-option
        >
            {{ option.threadTypeLabel }}
        </ng-template>
    </div>

    <div
        class="fx-form-validation"
        *ngIf="!form.controls.threadType.valid && form.controls.threadType.touched"
    >
        Service is required.
        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
    </div>
</ng-container>
