import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IPrompt, Memory } from "@visoryplatform/threads";
import { MemoriesService } from "../../services/memories.service";
import { Observable } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { map, shareReplay } from "rxjs/operators";

type TableRow = Memory & { path: string; badge: string; prompt: IPrompt };

@Component({
    selector: "memory-table",
    templateUrl: "./memory-table.component.html",
    styleUrls: ["./memory-table.component.scss"],
})
export class MemoryTableComponent implements OnChanges {
    @Input() memories: Memory[];
    @Input() canDelete: boolean;
    @Output() removeMemory = new EventEmitter<Memory>();

    loader = new Loader();
    prompts$: Observable<IPrompt[]>;
    tableData$?: Observable<MatTableDataSource<TableRow>>;
    private tableDataRef = new MatTableDataSource<TableRow>(); //Ideally we have a data source that's an observable

    constructor(private memoriesService: MemoriesService) {
        this.prompts$ = this.loader.wrap(this.memoriesService.listPrompts()).pipe(shareReplay(1));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.memories) {
            this.tableData$ = this.prompts$.pipe(map((prompts) => this.mapMemoriesToTable(this.memories, prompts)));
        }
    }

    trackMemory(_index: number, memory: Memory): string {
        return memory.id;
    }

    private mapMemoriesToTable(memories: Memory[], prompts: IPrompt[]): MatTableDataSource<TableRow> {
        const tableRows = memories?.map((memory) => this.mapMemoryToRow(memory, prompts));

        this.tableDataRef.data = tableRows;

        return this.tableDataRef;
    }

    private mapMemoryToRow(memory: Memory, prompts: IPrompt[]): TableRow {
        const prompt = prompts.find((p) => p.id === memory.promptId);
        const [type, threadId, cardId] = memory.key.split("/");
        return {
            ...memory,
            badge: type,
            path: cardId
                ? `/workflows/${threadId}/activity/cards/${cardId}?ignoreModalOpen=true`
                : `/workflows/${threadId}/activity/`,
            prompt,
        };
    }
}
