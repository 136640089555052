<div *ngIf="thread?.participants">
    <button
        class="thread-participants-toggle"
        [ngClass]="{ active: showDialog }"
        (click)="showDialog = !showDialog"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        analyticsClick="app_viewparticipants"
    >
        <i
            class="las"
            [ngClass]="{
                'la-user-plus': role | permissions : 'UpdateParticipants' | async,
                'la-user-friends': !(role | permissions : 'UpdateParticipants' | async)
            }"
        ></i>
        <span
            class="badge"
            *ngIf="showBadge"
            >{{ thread.participants.length }}</span
        >
    </button>

    <ng-template
        cdkConnectedOverlay
        (backdropClick)="showDialog = false"
        cdkConnectedOverlayBackdropClass="transparent-overlay-background"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="showDialog"
        [cdkConnectedOverlayPositions]="[
            {
                originX: originX,
                originY: 'bottom',
                overlayX: originX,
                overlayY: 'top'
            }
        ]"
    >
        <div class="overlay-window">
            <div class="thread-participants">
                <div class="dialog">
                    <div class="dialog-participant-list">
                        <div
                            class="dialog-participant-container"
                            *ngFor="let participant of threadParticipants; trackBy: 'id' | trackProperty"
                        >
                            <div
                                class="dialog-participant"
                                (click)="openProfile(participant.id)"
                            >
                                <fx-avatar
                                    [name]="participant.profile?.name"
                                    [image]="participant.id | profilePicture | async"
                                    size="medium"
                                ></fx-avatar>
                                <div class="dialog-participant-user">
                                    <div
                                        class="dialog-participant-user-name"
                                        [ngClass]="{
                                            'dialog-participant-user-name--link':
                                                role | permissions : 'ReadProfile' | async
                                        }"
                                    >
                                        {{ participant.profile?.name }}
                                    </div>
                                    <div class="dialog-participant-user-title">
                                        {{ participant.profile?.title || participant.businessName }}
                                    </div>
                                    <div
                                        class="dialog-participant-user-title"
                                        *ngIf="threadParticipantDetails"
                                    >
                                        {{ participant.participantDetail }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="dialog-participant-delete"
                                *ngIf="
                                    !participant.isAssignee &&
                                    allowRemoveParticipant &&
                                    (role | permissions : 'UpdateParticipants' | async)
                                "
                                (click)="remove(participant)"
                                [analyticsClick]="gaEvents.APP_REMOVEPARTICIPANT"
                            >
                                <i class="las la-times"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="dialog-add"
                        *ngIf="
                            addParticipantsButton &&
                            (role | permissions : 'UpdateParticipants' | async) &&
                            !thread.restrictCardsToInternal
                        "
                    >
                        <button
                            (click)="openDialog()"
                            class="fx-btn fx-btn--primary"
                            [analyticsClick]="gaEvents.APP_ADDPARTICIPANTS"
                        >
                            ADD PEOPLE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
