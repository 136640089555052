<div
    *ngIf="enterprise && permissions"
    class="fx-form-group enterprise-permissions-form"
>
    <div class="fx-form-label enterprise-permissions-form-heading">Access Level</div>

    <div class="fx-form-control enterprise-permissions-form">
        <form [formGroup]="classificationsForm">
            <label class="enterprise-permissions-form-subheading">
                Select which level of permission this user has access to viewing:

                <div class="fx-form-control">
                    <select
                        formControlName="classificationIndex"
                        class="fx-form-select enterprise-permissions-form-select"
                    >
                        <option
                            *ngFor="let label of enterprise.classificationLabels; let i = index"
                            [ngValue]="i"
                        >
                            {{ label }}
                        </option>

                        <option [ngValue]="null">Entities</option>
                    </select>
                </div>
            </label>

            <div
                class="enterprise-permissions-form-options"
                *ngIf="classificationsControl"
            >
                <ng-container *ngIf="classificationValues && classificationIndex.value !== null">
                    <fx-checkbox
                        *ngFor="let classification of classificationValues[classificationIndex.value]"
                        [checked]="classificationsControl.value.includes(classification)"
                        (change)="toggleClassification(classification)"
                        class="enterprise-permissions-form-options-option"
                    >
                        {{ classification }}
                    </fx-checkbox>
                </ng-container>

                <ng-container *ngIf="classificationIndex.value === null">
                    <fx-checkbox
                        *ngFor="let entity of entities; trackBy: trackEntity"
                        [checked]="classificationsControl.value.includes(entity.id)"
                        (change)="toggleClassification(entity.id)"
                        class="enterprise-permissions-form-options-option"
                    >
                        {{ entity.label }}
                    </fx-checkbox>
                </ng-container>
            </div>
        </form>
    </div>
</div>

<div class="enterprise-permissions-actions">
    <button
        analyticsClick="gaEvents.INSIGHTS_PERMISSIONS_DELETE"
        (click)="delete.emit()"
        class="fx-btn fx-btn--white enterprise-permissions-actions-button"
    >
        Remove Enterprise
    </button>
    <button
        analyticsClick="gaEvents.INSIGHTS_PERMISSIONS_SAVE"
        (click)="save.emit(classificationsForm.value)"
        class="fx-btn fx-btn--primary enterprise-permissions-actions-button"
    >
        Save Changes
    </button>
</div>
