<ng-container *ngIf="{ thread: thread$ | async, timezone: timezone$ | async } as context">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
        [showBackground]="true"
    ></loader-icon>
    <sidebar-modal-layout
        header="Edit workflow"
        (close)="close()"
    >
        <stepper-layout
            [linear]="true"
            endStepLabel="Save"
            (completed)="updateThread(stepConfig, context.thread, participants$)"
        >
            <ng-container *ngIf="selectedHasSlas">
                <stepper-step
                    label="Edit steps"
                    [completed]="stepConfig.valid"
                >
                    <edit-workflow-config
                        (resetDatesClicked)="resetDates()"
                        (calculationFromTimeChanged)="
                            onCalculationFromTimeChanged(
                                context.thread.workflow,
                                context.timezone,
                                editWorkflowControl.value.calculateFromDate,
                                editWorkflowControl.value.datesCalculationPoint
                            )
                        "
                        [timeZone]="context.timezone"
                        [workflow]="context.thread.workflow"
                        [stepConfig]="stepConfig"
                        [canUpdateAssignees]="role | permissions : 'UpdateAssignees' | async"
                        [formControl]="editWorkflowControl"
                        [canEditDueDates]="true"
                    >
                    </edit-workflow-config>
                </stepper-step>
            </ng-container>

            <ng-container *ngIf="participants$ | async; let participants">
                <stepper-step
                    label="Edit participants"
                    [completed]="participants.length > 0"
                >
                    <edit-participants
                        [participants]="participants"
                        (participantsChanged)="updateParticipants($event)"
                    ></edit-participants>
                </stepper-step>
            </ng-container>
        </stepper-layout>
    </sidebar-modal-layout>
</ng-container>
