import { Inject, Injectable, OnDestroy } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { MenuService, MenuType } from "./menu.service";
import { delay, map, startWith, take } from "rxjs/operators";
import { Observable, of, ReplaySubject, Subscription } from "rxjs";
import { ThreadsService } from "../../threads-ui/services/threads.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { WindowListenersService } from "./window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import Step from "shepherd.js/src/types/step";
import { Role } from "@visoryplatform/threads";
import { AnalyticsService } from "../../analytics/services/analytics.service";
import { AppUser } from "../../findex-auth/model/AppUser";
import { Tour, TourStep } from "../interfaces/tours";
import { ILaunchDarklyFeatureFlags } from "../../feature-flags";

@Injectable({ providedIn: "root" })
export class TourService implements OnDestroy {
    tourIsActive$ = new ReplaySubject<boolean>(1);
    roles = Role;
    isTabletWidth: boolean;

    private menuOpenerSubscription: Subscription;

    constructor(
        private shepherdService: ShepherdService,
        private menuService: MenuService,
        private threadsService: ThreadsService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private dialog: MatDialog,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.tourIsActive$.next(false);
    }

    async queueTours(user: AppUser, featureFlags: ILaunchDarklyFeatureFlags, tours: Tour[]): Promise<void> {
        if (!tours) {
            return;
        }

        const completedTours = await this.getCompletedTours(user.id);
        for (const tour of tours) {
            if (!completedTours.includes(tour.id)) {
                await this.startTour(user, featureFlags, tour);
            }
        }
    }

    hideTour(): void {
        if (this.shepherdService.isActive) {
            this.shepherdService.hide();
        }
    }

    isActive(): boolean {
        return this.shepherdService.isActive;
    }

    ngOnDestroy(): void {
        this.menuOpenerSubscription?.unsubscribe();
    }

    private toursForRole(user: AppUser, tour: Tour): Tour {
        const toursForRole = tour.steps.filter((step) => step.rolesToShow.includes(user.globalRole));
        return {
            ...tour,
            steps: toursForRole,
        };
    }

    private hideToursForFeatureFlag(featureFlags: ILaunchDarklyFeatureFlags, tour: Tour): Tour {
        const featureFlagForRole = tour.steps.filter((step) => {
            if (step?.hideForFeatureFlag && featureFlags[step.hideForFeatureFlag]) {
                return false;
            }
            return true;
        });
        return {
            ...tour,
            steps: featureFlagForRole,
        };
    }

    private filterTours(user: AppUser, tour: Tour, featureFlags: ILaunchDarklyFeatureFlags): Tour {
        const tourForRole = this.toursForRole(user, tour);
        const tourForFeatureFlag = this.hideToursForFeatureFlag(featureFlags, tourForRole);
        return tourForFeatureFlag;
    }

    private async startTour(user: AppUser, featureFlags: ILaunchDarklyFeatureFlags, tour: Tour): Promise<void> {
        const filteredTours = this.filterTours(user, tour, featureFlags);

        if (tour?.featureFlag && !featureFlags[tour.featureFlag]) {
            this.tourIsActive$.next(false);
            return;
        }

        this.tourIsActive$.next(true);
        if (this.dialog.openDialogs.length) {
            console.warn("Waiting for dialog to close before starting tour");
            await this.dialog.afterAllClosed.toPromise();
        }

        await this.routeToPreferredRoute(filteredTours);

        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
        this.isTabletWidth = this.getIsTabletWidth();

        const url = this.router.url;
        if (url.includes(filteredTours.tourRoute)) {
            const showNavigationMenu$ = this.menuService.showNavigationMenu();

            const steps = filteredTours.steps.map((step) =>
                this.mapTourStep(step, user, showNavigationMenu$, this.shepherdService),
            );

            this.shepherdService.addSteps(steps);

            this.recordAnalyticsEvent("start");
            await this.showShepherd();

            this.recordAnalyticsEvent("complete");
            this.tourIsActive$.next(false);

            // put nav back on mobile after completing
            if (this.isTabletWidth) {
                this.menuService.hide(MenuType.Navigation);
            }

            await this.markTourComplete(user.id, filteredTours);
        } else {
            this.tourIsActive$.next(false);
        }
    }

    private getIsTabletWidth(): boolean {
        const windowWidthMenuBreakpoint = this.environment.featureFlags.windowWidthMenuBreakpoint;
        return this.windowListenersService.isWindowSmaller(windowWidthMenuBreakpoint);
    }

    private showShepherd(): Promise<void> {
        return new Promise((resolve) => {
            this.shepherdService.tourObject.on("complete", () => resolve());
            this.shepherdService.start();
        });
    }

    private showMenu(show: boolean): void {
        if (show || !this.isTabletWidth) {
            this.menuService.show(MenuType.Navigation);
        } else {
            this.menuService.hide(MenuType.Navigation);
        }
    }

    private showThreadsMenu(show: boolean): void {
        if (show) {
            this.menuService.show(MenuType.ThreadsMenu);
        } else {
            this.menuService.hide(MenuType.ThreadsMenu);
        }
    }

    private async routeToPreferredRoute(tour: Tour): Promise<void> {
        const startingRoute = tour.steps[0]?.routeTo;
        if (startingRoute && startingRoute !== this.router.url) {
            await this.router.navigate([startingRoute]);
        }
    }

    private async getCompletedTours(userId: string): Promise<string[]> {
        const userProfile = await this.threadsService.getUserData(userId).toPromise();

        if (!userProfile || !userProfile.tourConfig || !Array.isArray(userProfile.tourConfig.completedTours)) {
            return [];
        }

        return userProfile.tourConfig.completedTours;
    }

    private async markTourComplete(userId: string, tour: Tour): Promise<boolean> {
        const completedTours = await this.getCompletedTours(userId);
        const userData = { tourConfig: { completedTours: [...completedTours, tour.id] } };
        return await this.threadsService
            .putUserData(userId, userData)
            .pipe(map(() => true))
            .toPromise();
    }

    private recordAnalyticsEvent(category: string): void {
        this.analyticsService.recordEvent("tour", category);
    }

    private async getPromiseFromBeforeShow(tourStep: TourStep, beforeShow$: Observable<any>): Promise<void> {
        this.menuOpenerSubscription?.unsubscribe();
        const windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        this.menuOpenerSubscription = this.windowListenersService.resize.pipe(startWith(windowSize)).subscribe(() => {
            this.showMenu(tourStep.menuStateBeforeShow === "open");
            this.showThreadsMenu(tourStep.threadMenuStateBeforeShow === "open");
        });

        await beforeShow$.toPromise();
    }

    private mapTourStep(
        tourStep: TourStep,
        user: AppUser,
        waitFor: Observable<any>,
        shepherdService: ShepherdService,
    ): Step.StepOptions {
        const { attachTo: attachToConfig } = tourStep;
        const attachTo = attachToConfig ? { element: attachToConfig.selector, on: attachToConfig.side } : undefined;
        const beforeShow$ = tourStep.menuStateBeforeShow ? waitFor.pipe(take(1), delay(400)) : of();
        //This promise resolves when the step is ready to show. For steps where the slide menu needs to be open,
        //it will delay for long enough so that the menu can open. If we don't do this, the tour cannot attach to the
        //element.
        const beforeShowPromise = () => this.getPromiseFromBeforeShow(tourStep, beforeShow$);
        // replace #{username} with their name
        const processedTitleHtml = tourStep.titleHtml.replace(/#{username}/g, user.name);
        const processedContentHtml = tourStep.contentHtml.replace(/#{username}/g, user.name);

        return {
            attachTo,
            beforeShowPromise,
            arrow: false,
            buttons: [
                {
                    classes: "fx-btn fx-btn--primary",
                    text: tourStep.nextButtonText,
                    action: function () {
                        this.next();
                    },
                },
            ],
            cancelIcon: {
                enabled: false,
            },
            scrollTo: { behavior: "smooth", block: "center" },
            text: [
                `<div class="tour-content">
                    <div class="tour-header">${processedTitleHtml}</div>
                    <div class="tour-body">${processedContentHtml}</div>
                </div>`,
            ],
            canClickTarget: tourStep.canClickTarget || false,
            when: {
                show: () => {
                    const tourHasSelector = tourStep.attachTo?.selector;
                    if (tourHasSelector) {
                        this.skipInvalidTourSelectors(tourStep, shepherdService);
                    }
                },
            },
        };
    }

    private skipInvalidTourSelectors(tourStep: TourStep, shepherdService: ShepherdService): void {
        const element = document.querySelector(tourStep?.attachTo?.selector);
        if (!element) {
            shepherdService.next();
        }
    }
}
