import { Component, Input } from "@angular/core";
import { IStepUI } from "../../../../interfaces/IStepUi";

@Component({
    selector: "view-assignees",
    templateUrl: "./view-assignees.component.html",
    styleUrls: ["./view-assignees.component.scss"],
})
export class ViewAssigneesComponent {
    @Input() step: IStepUI;

    readonly assignedTo = {
        client: "customer",
        visory: "Visory",
    };
}
