<centered-modal-layout
    (save)="acceptPrompt()"
    (close)="close()"
    [cancelButtonTitle]="rejectButtonName"
    [saveButtonTitle]="acceptButtonName"
    analyticsPrefix="create-invitation-close"
>
    <div modal-header>{{ confirmTitle }}</div>
    <div modal-content>{{ confirmDescription }}</div>
</centered-modal-layout>
