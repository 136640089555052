import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
export class ToastConfig {
    public content: string;
    public severity: "default" | "success" | "error" | "info";
    public title?: string;
    public acceptButtonLabel?: string = "Accept";
    public dismissButtonLabel?: string = "Ignore";
    constructor(config: ToastConfig) {
        this.content = config.content;
        this.title = config.title || this.title;
        this.severity = config.severity;
        this.acceptButtonLabel = config.acceptButtonLabel || this.acceptButtonLabel;
        this.dismissButtonLabel = config.dismissButtonLabel || this.dismissButtonLabel;
    }
}
@Component({
    selector: "fx-notifier",
    templateUrl: "./fx-notifier.component.html",
    styleUrls: ["./fx-notifier.component.scss"],
})
export class FxNotifierComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public state: ToastConfig,
        public snackRef: MatSnackBarRef<FxNotifierComponent>,
    ) {}
}
