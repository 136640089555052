<div
    class="thread-card-replies thread-card-replies--hide-left-border"
    [ngClass]="{
        'thread-card-replies--show-compose': showCompose,
        'thread-card-replies--readonly': readonly,
        'thread-card-replies--internal': isInternal
    }"
>
    <loader-icon
        *ngIf="replyLoader.counter"
        color="light"
    ></loader-icon>

    <thread-card-replies-list
        #threadCardRepliesListComponent
        [replies]="replies"
        [thread]="thread"
        [card]="card"
        [userId]="userId"
        [readonly]="readonly"
    >
    </thread-card-replies-list>

    <reply-compose
        *ngIf="replyControl"
        [formControl]="replyControl"
        [threadType]="thread.type"
        [mentionableUsers]="thread.participants"
        [cardType]="card?.type"
        [inlineCompose]="true"
        [showCompose]="showCompose"
        (sendReply)="reply($event)"
    >
    </reply-compose>
</div>
