import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { IParticipant, IThread, IVaultListItem, IVaultListItemFile, SortOption } from "@visoryplatform/threads";
import { ThreadsVaultService } from "projects/portal-modules/src/lib/threads-ui/services/threads-vault.service";
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, map, startWith, switchMap, takeUntil, debounceTime } from "rxjs/operators";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

import { DateTime } from "luxon";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { MatOption } from "@angular/material/core";
import { IPaginatorSort } from "projects/portal-modules/src/lib/shared/interfaces/IPaginatorSort";
import { SortDirection } from "@angular/material/sort";

const TIME_RANGE = [
    {
        label: "Last 7 days",
        value: "7",
    },
    {
        label: "Last 30 days",
        value: "30",
    },
    {
        label: "Last 3 months",
        value: "120", // set to roughly 4 months, timeAgo pipe represents 3-4 months as '3 months'.
    },
    {
        label: "Last 12 months",
        value: "365",
    },
];

interface IFilter {
    dateRange?: string;
    accounts?: string[];
}

@Component({
    selector: "app-vault-list-route",
    templateUrl: "./vault-list-route.component.html",
    styleUrls: ["./vault-list-route.component.scss"],
})
export class VaultListRouteComponent implements OnInit, OnDestroy {
    @ViewChild("allAccountsSelected") private allAccountsSelected: MatOption;

    readonly GA_EVENTS = GA_EVENTS;
    readonly TIME_RANGE = TIME_RANGE;

    public calendarFilterSelectAlOptions = environmentCommon.calendarFilterSelectAllOptions;

    form = new FormGroup({
        dateRange: new FormControl<string>(""),
        accounts: new FormControl<string[]>(null),
    });

    isMobileView: boolean;
    searchTerm = new UntypedFormControl();
    filterSubscription: Subscription;
    documents: IVaultListItem[];
    filteredResults: IVaultListItem[];
    accounts: { name: string; id: string }[];
    searchableUsers: IParticipant[];

    private websocketSubs: Subscription[] = [];
    private searchTerm$: Observable<string>;
    private filters$: Observable<IFilter>;
    private unbindState$ = new Subject();
    private sort$ = new BehaviorSubject(null);

    constructor(
        private threadsVaultService: ThreadsVaultService,
        private windowListenersService: WindowListenersService,
        private websocketService: ThreadsWebsocketService,
        private participantCache: ParticipantCache,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    async ngOnInit(): Promise<void> {
        this.documents = await this.threadsVaultService.getListAllVaults().toPromise();

        this.bindWebsocketSubscriptions(this.documents);

        this.accounts = this.listAccounts(this.documents);
        this.searchableUsers = await this.getUniqueParticipants(this.documents);
        this.searchTerm$ = this.searchTerm.valueChanges.pipe(debounceTime(250), distinctUntilChanged(), startWith(""));
        this.filters$ = this.form.valueChanges.pipe(distinctUntilChanged(), startWith({}));
        this.filterSubscription = combineLatest([this.searchTerm$, this.filters$, this.sort$])
            .pipe(map(([searchTerm, filters, sort]) => this.filterResults(this.documents, searchTerm, filters, sort)))
            .subscribe((result) => {
                this.filteredResults = result;
            });
    }

    ngOnDestroy(): void {
        this.unbindState$.next(null);
        this.unbindState$.complete();

        if (this.websocketSubs.length) {
            this.websocketSubs.forEach((sub) => {
                sub.unsubscribe();
            });
        }

        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    sortByClick(config: IPaginatorSort): void {
        this.sort$.next(config);
    }

    public toggleSelectAll(): void {
        if (this.allAccountsSelected.selected) {
            const selectOptions = environmentCommon.calendarFilterSelectAllOptions.allAccounts;

            this.form.controls.accounts.patchValue([selectOptions, ...this.accounts.map((item) => item.id)]);
            this.allAccountsSelected.select();
        } else {
            this.form.controls.accounts.patchValue([]);
            this.allAccountsSelected.deselect();
        }
    }

    public toggleOneItem(): void {
        if (this.allAccountsSelected.selected) {
            this.allAccountsSelected.deselect();
        }
        if (this.form.controls.accounts.value?.length === this.accounts.length) {
            this.allAccountsSelected.select();
        }
    }

    updateSearchTerm(searchTerm: string): void {
        this.searchTerm.setValue(searchTerm);
    }

    private listAccounts(documents: IVaultListItem[]): {
        name: string;
        id: string;
    }[] {
        const accounts = this.filterDuplicateAccounts(documents);
        const sortedAccounts = accounts.sort((a, b) => a.name.localeCompare(b.name));
        return sortedAccounts;
    }

    private async getUniqueParticipants(documents: IVaultListItem[]): Promise<IParticipant[]> {
        const actorIds = [...new Set(documents.map((document) => document.actorId))];
        const participants = await Promise.all(
            actorIds.map((id) => this.participantCache.getParticipant(id).toPromise()),
        );

        return participants;
    }

    private bindWebsocketSubscriptions(documents: IVaultListItem[]): void {
        const uniqueCards = this.filterUniqueCards(documents);
        uniqueCards.forEach((vaultItem) => {
            if (!vaultItem) {
                return;
            }
            const subscription = this.subscribeToSubject(vaultItem.threadId, vaultItem.cardId);
            this.websocketSubs.push(subscription);
        });
    }

    private findParticipantsWithSearchTerm(searchTerm: string): string[] {
        const lowerCasedTerm = searchTerm.toLowerCase();

        return this.searchableUsers
            .filter((user) => {
                const userName = user.profile?.name?.toLowerCase() ?? "";
                return userName.includes(lowerCasedTerm);
            })
            .map((user) => user.id);
    }

    private filterResults(
        documents: IVaultListItem[],
        searchTerm: string,
        filters: IFilter,
        sort?: IPaginatorSort,
    ): IVaultListItem[] {
        const lowerTerm = searchTerm.toLowerCase();
        const { accounts, dateRange } = filters;
        const filteredDocuments = this.filterDocuments(documents, lowerTerm, accounts, dateRange);

        const label = this.getSortLabel(sort);
        const active = this.getSortOrder(sort);

        return this.sortDocuments(filteredDocuments, label, active);
    }

    private getSortOrder(sort: IPaginatorSort): SortDirection {
        return sort?.order || "desc";
    }

    private getSortLabel(sort: IPaginatorSort): string[] {
        return sort?.order ? sort?.sort?.split(".") : ["file", "timestamp"];
    }

    private filterDocuments(
        documents: IVaultListItem[],
        lowerTerm: string,
        accounts: string[],
        dateRange: string,
    ): IVaultListItem[] {
        return documents.filter((item) => {
            const { file, thread, account, actorId } = item;
            const returnMatchingParticipants = this.findParticipantsWithSearchTerm(lowerTerm);
            const searchTerm = this.getSearchTerm(file, lowerTerm, thread, returnMatchingParticipants, actorId);
            const accountFilter = this.getAccountFilter(accounts, account);
            const timeRangeFilter = this.checkRange(file.timestamp, dateRange);

            return searchTerm && accountFilter && timeRangeFilter;
        });
    }

    private getAccountFilter(accounts: string[], account: { name: string; id: string }): boolean {
        const allAccountsFilterOption = environmentCommon.calendarFilterSelectAllOptions.allAccounts;
        const includesAllAccounts = accounts?.includes(allAccountsFilterOption);
        if (accounts && !includesAllAccounts) {
            return accounts.includes(account.id);
        } else {
            return true;
        }
    }

    private getSearchTerm(
        document: IVaultListItemFile,
        lowerTerm: string,
        thread: Partial<Pick<IThread, "id" | "type" | "title">>,
        returnMatchingParticipants: string[],
        actorId: string,
    ): boolean {
        const searchDisplayName = document?.displayName?.toLowerCase().includes(lowerTerm);
        const searchType = thread?.type?.toLowerCase().includes(lowerTerm);
        const searchTitle = thread?.title?.toLowerCase().includes(lowerTerm);
        const searchParticipant = returnMatchingParticipants?.includes(actorId);

        return searchDisplayName || searchType || searchTitle || searchParticipant;
    }

    private sortDocuments(
        documents: IVaultListItem[],
        currentSort: string[],
        direction: SortDirection,
    ): IVaultListItem[] {
        if (!!direction && currentSort?.length == 1) {
            return documents.sort((a, b): number => this.sortProperty(a, currentSort, b, direction));
        }

        if (!!direction && currentSort?.length == 2) {
            return documents.sort((a, b): number => this.sortNestedProperty(a, currentSort, b, direction));
        }

        return documents;
    }

    private sortProperty(a: IVaultListItem, currentSort: string[], b: IVaultListItem, direction: string): number {
        const val1: string = a[currentSort[0]];
        const val2: string = b[currentSort[0]];

        return direction === SortOption.ASC ? this.sortAsc(val1, val2) : this.sortDesc(val2, val1);
    }

    private sortNestedProperty(a: IVaultListItem, currentSort: string[], b: IVaultListItem, direction: string): number {
        const val1: string = a[currentSort[0]][currentSort[1]];
        const val2: string = b[currentSort[0]][currentSort[1]];

        return direction === SortOption.ASC ? this.sortAsc(val1, val2) : this.sortDesc(val2, val1);
    }

    private sortDesc(a: string, b: string): number {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
    }

    private sortAsc(a: string, b: string): number {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
    }

    private checkRange(timeStamp: string, dateRange: string): boolean {
        const timeStampDate = DateTime.fromISO(timeStamp);
        const diff = Math.abs(timeStampDate.diffNow().as("day"));
        const wholeNumberedDateDifference = Math.round(diff);
        return dateRange ? wholeNumberedDateDifference <= Number(dateRange) : true;
    }

    private subscribeToSubject(threadId: string, cardId: string): Subscription {
        return this.websocketService
            .watchCardId(threadId, cardId)
            .pipe(
                switchMap(() => this.threadsVaultService.getListAllVaults()),
                takeUntil(this.unbindState$),
            )
            .subscribe((documents) => {
                this.documents = documents;
                this.filteredResults = this.filterResults(documents, this.searchTerm.value || "", this.form.value);
            });
    }

    private filterDuplicateAccounts(documents: IVaultListItem[]): IVaultListItem["account"][] {
        const accounts = documents
            .filter((val) => val && val.account?.id && val.account?.name)
            .map((val) => val.account);
        const filtered = accounts.filter((item, i) => accounts.findIndex((account) => account.id === item.id) === i);
        return filtered;
    }

    private filterUniqueCards(documents: IVaultListItem[]): {
        cardId: string;
        threadId: string;
    }[] {
        const cards = documents.map((val) => ({
            cardId: val.cardId,
            threadId: val.thread.id,
        }));

        const filtered = cards.filter((item, i) => cards.findIndex((card) => card.cardId === item.cardId) === i);
        return filtered;
    }
}
