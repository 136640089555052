import { Component, Inject, OnInit } from "@angular/core";
import { VAvatar } from "@visoryplatform/portal-ui";
import { IThread, ITimeline, Role } from "@visoryplatform/threads";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ROLE, THREAD } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { UserAvatarService } from "projects/portal-modules/src/lib/threads-ui/services/user-avatar.service";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { InternalChatRouteService } from "./internal-chat-route.service";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";

@Component({
    selector: "app-internal-chat-route",
    templateUrl: "./internal-chat-route.component.html",
    styleUrls: ["./internal-chat-route.component.scss"],
    providers: [
        {
            provide: THREAD,
            useFactory: (
                accountRouteService: AccountRouteService,
                internalChatRouteService: InternalChatRouteService,
                loader: Loader,
            ) => internalChatRouteService.getThread(accountRouteService, loader),
            deps: [AccountRouteService, InternalChatRouteService, Loader],
        },
        {
            provide: ROLE,
            useFactory: (authService: AuthService) => authService.getGlobalRole(),
            deps: [AuthService, InternalChatRouteService, Loader],
        },
    ],
})
export class InternalChatRouteComponent implements OnInit {
    internalChatThread$: Observable<IThread>;
    currentRole$: Observable<Role>;
    loader = new Loader();
    avatars$: Observable<VAvatar[]>;
    threadId$: Observable<string>;

    constructor(
        @Inject(THREAD) private thread$: Observable<ITimeline>,
        @Inject(ROLE) private role$: Observable<Role>,
        private userAvatarService: UserAvatarService,
    ) {}

    ngOnInit(): void {
        this.currentRole$ = this.role$;
        this.internalChatThread$ = this.thread$;
        this.threadId$ = this.internalChatThread$.pipe(map((thread) => thread.id));

        this.avatars$ = this.internalChatThread$.pipe(
            switchMap((thread) => this.userAvatarService.getAvatars(thread.participants)),
        );
    }
}
