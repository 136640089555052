<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        userId: userId$ | async,
        replies: replies$ | async,
        transactions: transactions$ | async
    } as context"
>
    <centered-modal-layout
        [loading]="!!loader.counter"
        [cancelButtonTitle]="buttonLabels.Close"
        [disableSave]="false"
        saveButtonTitle="Save"
        (save)="dialogRef.close()"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                title="Transaction coding"
                [isComplete]="false"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <div class="request-common-group">
                <request-reply
                    *ngIf="context?.thread && context?.card"
                    [replies]="context.replies"
                    [userId$]="userId$"
                    [thread]="context?.thread"
                    [card]="context?.card"
                    [replyMessage]="replyMessage"
                    [focusReplyInput]="modalData?.focusReplyInput"
                ></request-reply>
            </div>

            <ng-container *ngIf="context.transactions as transactions">
                <transaction-list
                    [transactions]="transactions"
                    [editMode]="false"
                    (changeQuery)="updateQuery(context?.thread?.id, context?.card?.id, $event.query)"
                ></transaction-list>
            </ng-container>
        </div>
    </centered-modal-layout>
</ng-container>
