<div
    [ngStyle]="{ 'border-color': color }"
    (click)="meetingClicked()"
    class="item"
>
    <div class="item-meeting-icon">
        <i class="las la-calendar"></i>
    </div>
    <div class="item-title">
        <div>{{ start | date : "hh:mm a" }} - {{ end | date : "hh:mm a" }}</div>
        <div>{{ title }}</div>
    </div>
</div>
