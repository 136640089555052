import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "calendar-instance",
    templateUrl: "./calendar-instance.component.html",
    styleUrls: ["./calendar-instance.component.scss"],
})
export class CalendarInstanceComponent {
    @Input() start: string;
    @Input() end: string;
    @Output() cancel = new EventEmitter<void>();

    cancelMeeting() {
        this.cancel.emit();
    }
}
