import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { TransformersPlugin } from "./transformers.plugin";
import { CreateTransformerCheckComponent } from "./components/create-transformer-check/create-transformer-check.component";
import { TransformerActionComponent } from "./components/transformer-action/transformer-action.component";
import { TransformerCardComponent } from "./components/transformer-card/transformer-card.component";
import { VaultModule } from "../vault/vault.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { InlineEditModule } from "../vault/components/inline-edit/inline-edit.module";
import { QuillModule } from "ngx-quill";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { UiLayoutsModule } from "projects/portal-modules/src/lib/ui-layouts";
import { TransformerResultsModalComponent } from "./components/transformer-results-modal/transformer-results-modal.component";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        PortalUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        VaultModule,
        FxTodoListModule,
        QuillModule,
        InlineEditModule,
        UiLayoutsModule,
        FxTableModule,
    ],
    exports: [],
    declarations: [
        TransformerActionComponent,
        TransformerCardComponent,
        CreateTransformerCheckComponent,
        TransformerResultsModalComponent,
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: TransformersPlugin, multi: true }],
})
export class TransformersModule {}
