<form>
    <edit-workflow-config
        (resetDatesClicked)="resetDates()"
        (calculationFromTimeChanged)="onCalculationFromTimeChanged()"
        (resetToTemplateClicked)="recalculateFromTemplate()"
        [defaultThread]="defaultThread"
        [timeZone]="timezone"
        [workflow]="workflowDesign"
        [stepConfig]="stepConfig"
        [workflowConfigurationSteps]="workflowConfigurationSteps"
        [formControl]="editWorkflowControl"
        [canUpdateAssignees]="role | permissions : 'CreateThread' | async"
        [canEditDueDates]="false"
    >
    </edit-workflow-config>
</form>
