<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div
    class="memories-list"
    *ngIf="thread$ | async as thread"
>
    <button
        (click)="extractMemories(thread.id)"
        class="fx-btn fx-btn--primary-outlined"
        type="button"
        target="_blank"
    >
        Run extract
    </button>

    <ng-container *ngIf="threadMemories$ | async as threadMemories">
        <ng-container *ngIf="threadMemories.length; else noMemories">
            <memory-table
                *ngIf="!loader.counter"
                [memories]="threadMemories"
                [canDelete]="role$ | async | permissions : 'EditAccountMemories' | async"
                (removeMemory)="removeMemory(thread.accountId, $event.key)"
            ></memory-table>
        </ng-container>
    </ng-container>

    <ng-template #noMemories>
        <empty-state
            title="No memories found"
            htmlText="There have been no memories extracted for this workflow"
            [imageType]="emptyStateImages.NoMemories"
        ></empty-state>
    </ng-template>
</div>
