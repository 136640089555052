import { Route } from "@angular/router";
import { combineLatest, Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ILibrary } from "../interfaces/ILibrary";
import { RouteExtension } from "./Libraries";

export type VisibleExtension = { id: string; label: string; icon: string; path: string };

export class RouteHelper {
    static getRoutes(library: ILibrary<RouteExtension>): Route[] {
        return library.listAll().map((extension) => extension.extension.route);
    }

    static getVisibleExtensions(
        library: ILibrary<RouteExtension>,
        routeEntity?: unknown,
    ): Observable<VisibleExtension[]> {
        const extensions = library.listAll();

        const loadedExtensions = extensions.map(({ id, extension }) =>
            RouteHelper.loadExtension(id, extension, routeEntity),
        );

        return combineLatest(loadedExtensions).pipe(
            map((extensions) => extensions.filter((extension) => extension != null)),
            shareReplay(1),
        );
    }

    static loadExtension(
        id: string,
        extension: RouteExtension,
        routeEntity?: unknown,
    ): Observable<VisibleExtension | null> {
        const { showExtension, showIcon } = extension;

        const showExtension$ = showExtension(routeEntity);
        const showIcon$ = showIcon ? showIcon(routeEntity) : of(true);

        return combineLatest([showExtension$, showIcon$]).pipe(
            map(([showExtension, showIcon]) => this.mapVisibleRoute(id, extension, showExtension, showIcon)),
            shareReplay(1),
        );
    }

    private static mapVisibleRoute(
        id: string,
        extension: RouteExtension,
        showExtension: boolean,
        showIcon: boolean,
    ): VisibleExtension | null {
        if (!showExtension) {
            return null;
        }

        const { label, icon, route } = extension;

        return {
            id,
            label,
            path: route.path,
            icon: showIcon && icon,
        };
    }
}
