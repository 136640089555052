import { AfterContentInit, Component, ContentChildren, forwardRef, Input, QueryList } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FxTileButtonComponent } from "./fx-tile-button/fx-tile-button.component";

export enum SelectionTypes {
    Single = "single",
    Multi = "multi",
}
export interface ITileModel {
    value: string;
    title?: string;
    name?: string;
    tooltip?: string;
    image?: string;
    subtitle?: string;
}
export interface IMultiSelect {
    name: string;
    value: string;
}

@Component({
    selector: "fx-tile-component",
    templateUrl: "./fx-tile.component.html",
    styleUrls: ["./fx-tile.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FxTileComponent),
            multi: true,
        },
    ],
})
export class FxTileComponent implements AfterContentInit {
    @ContentChildren(FxTileButtonComponent) templates: QueryList<FxTileButtonComponent>;

    /**
     * Disable form element.
     */
    @Input() disabled = false;

    /**
     * Switch between a single or multi selection. Use enum to set value to either Single or Multi.
     */
    @Input() selectionType: SelectionTypes.Single | SelectionTypes.Multi = SelectionTypes.Single;
    private value: Set<string> | string;

    onChange = (_value: any) => {};
    onTouched = () => {};

    constructor() {}

    unSelect(currentIndex: number) {
        this.templates.forEach((template, index) => {
            if (index === currentIndex) {
                return;
            }
            template.selected = false;
        });
    }

    ngAfterContentInit() {
        this.templates.forEach((template, index) => {
            if (this.disabled) {
                template.disabled = true;
            }

            if (this.selectionType === SelectionTypes.Single && this.value === template.value) {
                template.selected = true;
            } else if (this.selectionType === SelectionTypes.Multi && this.value) {
                const preSelectedValues = Array.from(this.value);

                preSelectedValues.map((val) => {
                    if (val === template.value) {
                        template.selected = true;
                    }
                });
            }

            template.selectionAppend.subscribe((event) => {
                if (this.selectionType === SelectionTypes.Single) {
                    this.writeValue(event);
                    this.unSelect(index);
                } else {
                    (this.value as Set<string>).add(event);
                    this.writeValue(Array.from(this.value));
                }
            });

            template.selectionRemove.subscribe((event) => {
                if (this.selectionType === SelectionTypes.Single) {
                    this.writeValue(undefined);
                } else {
                    (this.value as Set<string>).delete(event);
                    this.writeValue(Array.from(this.value));
                }
            });
        });
    }

    private setInitialValue(value) {
        if (!this.value && this.selectionType === SelectionTypes.Single) {
            this.value = "";
        } else if (!this.value && this.selectionType === SelectionTypes.Multi) {
            if (!Array.isArray(value) && value) {
                console.error("warning - initial value is incorrect type", value);
            }
            this.value = new Set(value) || new Set();
        }
    }

    writeValue(value: any): void {
        this.onChange(value);
        this.setInitialValue(value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
