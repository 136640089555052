import { Component, Input } from "@angular/core";
import { environmentCommon } from "../../../../portal-modules/src/lib/environment/environment.common";

@Component({
    selector: "message",
    templateUrl: "./message.component.html",
    styleUrls: ["./message.component.scss"],
})
export class MessageComponent {
    @Input() threadId: string;
    @Input() cardId: string;
    @Input() message: string;
    @Input() actorId: string;
    @Input() createdAt: string;
    @Input() isInternal: boolean;

    readonly meetingTimeFormat = environmentCommon.dateFormats.short;
    readonly quillStyles = environmentCommon.quillConfig.styling;
}
