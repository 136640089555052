import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Account } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { Observable, of } from "rxjs";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { AppUser, AuthService, authGuard } from "projects/portal-modules/src/lib/findex-auth";
import { filter, switchMap } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { ChatListComponent } from "./components/chat-list/chat-list.component";
import { ChatComponent } from "./components/chat/chat.component";
import { MessageListComponent } from "./components/message-list/message-list.component";
import { Route } from "@angular/router";

@Injectable()
export class AssistantPlugin implements IPluginFactory {
    readonly id = "AssistantPlugin";
    readonly gaEvents = GA_EVENTS;

    private featureEnabled$: Observable<boolean>;
    private brandingEnabled$: Observable<boolean>;

    constructor(
        private libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableAIAssistant);
        this.brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

        this.registerExtensions();
    }

    private registerExtensions(): void {
        const assistantRoute = this.getRoute();

        const delphiAssistant: RouteExtension<Account> = {
            label: "Ask Delphi",
            icon: "ai-icon",
            showIcon: () => this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding),
            showExtension: () =>
                this.brandingEnabled$.pipe(
                    switchMap((branding) => (branding ? this.showExtension("ExecuteAccountAssistant") : of(false))),
                ),
            route: assistantRoute,
        };

        const accountAssistant: RouteExtension<Account> = {
            label: "Ask",
            showExtension: () =>
                this.brandingEnabled$.pipe(
                    switchMap((branding) => (!branding ? this.showExtension("ExecuteAccountAssistant") : of(false))),
                ),
            route: assistantRoute,
        };

        const delphiAppAssistant: RouteExtension = {
            label: "Ask",
            icon: "ai-brand",
            showExtension: () =>
                this.brandingEnabled$.pipe(
                    switchMap((branding) => (branding ? this.showExtension("ExecuteAppAssistant") : of(false))),
                ),
            route: assistantRoute,
        };

        const appAssistant: RouteExtension = {
            label: "Ask",
            icon: "la-concierge-bell",
            showExtension: () =>
                this.brandingEnabled$.pipe(
                    switchMap((branding) => (branding ? of(false) : this.showExtension("ExecuteAppAssistant"))),
                ),
            route: assistantRoute,
        };

        this.libraries.accountRoutes.register("delphi-assistant", delphiAssistant);
        this.libraries.accountRoutes.register("account-assistant", accountAssistant);
        this.libraries.appRoutes.register("delphi-assistant", delphiAppAssistant);
        this.libraries.appRoutes.register("app-assistant", appAssistant);
    }

    private getRoute(): Route {
        return {
            path: "assistant",
            canActivate: [authGuard],
            children: [
                {
                    path: "chats",
                    component: ChatListComponent,
                },
                { path: "chats/:chatId", component: ChatComponent },
                { path: "messages", component: MessageListComponent },
                {
                    path: "",
                    pathMatch: "full",
                    redirectTo: "chats",
                },
            ],
        };
    }

    private showExtension(permission: string): Observable<boolean> {
        return this.featureEnabled$.pipe(
            switchMap((enabled) => {
                if (!enabled) {
                    return of(false);
                }

                return this.authService.getUser().pipe(
                    filter((user) => !!user),
                    switchMap((user) => this.userHasPermission(user, permission)),
                );
            }),
        );
    }

    private userHasPermission(user: AppUser, permission: string): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, permission);
    }
}
