import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "findex-main",
    templateUrl: "./findex-main.component.html",
    styleUrls: ["./findex-main.component.scss"],
})
export class FindexMainComponent implements OnInit {
    @Input() loading: number;

    constructor() {}

    ngOnInit() {}
}
