const defaultEnv = require('../../helpers/env');
const defaultML = require('./ml');
const defaultMediaTransform = require('./mediaTransforms/mediaTransform');
const logging = require('../../helpers/log')('MediaProcessor');

/* eslint-disable no-underscore-dangle */
function MediaProcessor(deps = {}) {
  const {
    ML = defaultML,
    MediaTransform = defaultMediaTransform,
  } = deps;


  let connector;
  let videoFilter;
  let originalVideoTrack;

  this.isValidVideoFilter = (filter) => {
    const { type } = filter;

    return MediaTransform.isSupported(type) && MediaTransform.isValidConfig(filter);
  };

  this.setVideoFilter = async (newVideoFilter) => {
    const { type } = newVideoFilter;
    const configurator = MediaTransform.getConfigurator(type);

    if (!configurator) {
      logging.warn(`Ignoring: filter "${type}" isn't supported`);
      return;
    }

    // The config passed in may be a simplified configuration missing URLs for static
    // assets, for example.  We need the "full configuration".
    const fullConfig = configurator.getConfig(newVideoFilter);

    if (!fullConfig) {
      logging.warn(`Ignoring: couldn't configure filter ${type}`);
      return;
    }

    const hasFilterAlready = !!this.getVideoFilter();

    if (hasFilterAlready) {
      try {
        await this.destroy();
      } catch (err) {
        logging.warn(`Ignoring, couldn't remove previous filter: ${err}`);
        return;
      }
    }

    connector = await ML.createConnector(fullConfig);
    videoFilter = newVideoFilter;
  };

  this.getVideoFilter = () => videoFilter;
  this.getOriginalVideoTrack = () => originalVideoTrack;

  this.setMediaStream = async (stream) => {
    const [videoTrack] = stream.getVideoTracks();

    if (!videoTrack) {
      logging.warn('Ignoring. No video track found');
      return null;
    }

    // Returning null, instead of undefined, to be consistent with the case
    // above
    let filteredVideoTrack = null;

    try {
      filteredVideoTrack = await connector.setTrack(videoTrack);
      originalVideoTrack = videoTrack;
    } catch (err) {
      logging.error(`Error setting media stream: ${err}`);
    }

    return filteredVideoTrack;
  };

  this.setVideoTrack = async (videoTrack) => {
    let filteredVideoTrack = null;

    try {
      filteredVideoTrack = await connector.setTrack(videoTrack);
      originalVideoTrack = videoTrack;
    } catch (err) {
      logging.err(`Error setting video track: ${err}`);
    }

    return filteredVideoTrack;
  };

  // Needed to make unit testing easier
  this._setConnector = (newConnector) => {
    connector = newConnector;
  };

  this.destroy = async () => {
    await connector.destroy();
    connector = null;
    videoFilter = null;
    originalVideoTrack = null;
  };
}

MediaProcessor.isSupported = (deps = {}) => {
  const {
    env = defaultEnv,
    ML = defaultML,
  } = deps;

  // Even though other browsers may support this API, we're going to limit it
  // to just Chrome for now.
  return env.isChrome && ML.isSupported();
};

module.exports = MediaProcessor;
