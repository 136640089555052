import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * USAGE NOTES:
 * 1. formArrayName must be used with formGroup as per Angular guideline "https://angular.io/api/forms/FormArrayName"
 * 2. fx-todolist-item is projected into fx-todolist as ng-content
 * 3. the component that consumes fx-todolist and fx-todolist-item is responsible to manage the state changes
 *
    <form [formGroup]="todoForm">
        <fx-todolist formArrayName="todoItems">
            <ng-container *ngFor="let todoItem of todoItems.controls; let i = index">
                <fx-todolist-item [ngSwitch]="todoState" [formControlName]="i or $any(todoItems.get("completed"))">
                    <div *ngSwitchCase="'view'">
                        <div>{{ "description" }}</div>
                        <div>{{ "FEEDBACK" }}</div>
                    </div>

                    <div *ngSwitchCase="'edit'">
                        <div>{{ "description 2" }}</div>
                        <div>{{ "FEEDBACK" }}</div>
                    </div>
                </fx-todolist-item>
            </ng-container>
        </fx-todolist>
    </form>
 */

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FxTodoListItemComponent),
    multi: true,
};
@Component({
    selector: "fx-todolist-item",
    templateUrl: "./fx-todolist-item.component.html",
    styleUrls: ["./fx-todolist-item.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class FxTodoListItemComponent implements ControlValueAccessor {
    @Input() disabled: boolean;
    @Input() shouldShowDelete = true;
    @Input() error = false;

    completed: boolean;

    @Output() deleteItem = new EventEmitter<void>();
    @Output() private change = new EventEmitter<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: (newValue?: boolean) => void = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};

    trackStatusChanges(value: boolean) {
        this.completed = value;
        this.onChanged(this.completed);
        this.onTouched();
        this.change.emit(value);
    }

    writeValue(value: boolean): void {
        this.completed = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
