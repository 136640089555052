import { HttpErrorResponse } from "@angular/common/http";

export class HandledError {
    constructor(public originalError: any) {}
}
export class ThreadsHttpError extends Error {
    originalHttpErrorResponse: HttpErrorResponse;
    constructor(sourceError: HttpErrorResponse) {
        super((sourceError.error && sourceError.error.message) || sourceError.message);
        this.originalHttpErrorResponse = sourceError;
    }
}
