<div
    class="multi-component-layout-parent"
    [ngClass]="{
        'multi-component-layout-parent--hide-footer': !enableFooter,
        'multi-component-layout-parent--hide-breadcrumb': !enableBreadCrumb,
        'multi-component-layout-parent--hide-header': !enableHeader,
        'multi-component-layout-parent--fullscreen': fullscreen,
        'multi-component-layout-parent--condenseBreadcrumb': condenseBreadcrumb
    }"
>
    <div
        class="multi-component-layout-header"
        *ngIf="enableHeader"
    >
        <ng-content select=".multi-component-layout-header"></ng-content>
    </div>
    <div
        class="multi-component-layout-breadcrumb"
        *ngIf="enableBreadCrumb"
    >
        <ng-container *ngFor="let step of steps; let index = index">
            <div class="multi-component-layout-step-container">
                <div
                    class="multi-component-layout-step"
                    [class.multi-component-layout-step-active]="step.active"
                >
                    {{ step.title }}
                </div>
                <i
                    *ngIf="index < steps.length - 1"
                    class="las la-angle-right multi-component-layout-icon"
                ></i>
            </div>
        </ng-container>
    </div>
    <div
        [class]="'multi-component-layout-content ' + contentClass"
        #mainContent
    >
        <button
            *ngIf="enableClose"
            class="multi-component-layout-close"
            (click)="closeClicked.emit()"
            [mat-dialog-close]="true"
            [analyticsClick]="analyticsPrefix + '_close_step' + (this.activeStepIndex + 1)"
        >
            <i
                class="las la-times"
                title="Close"
            ></i>
        </button>

        <loader-icon
            *ngIf="loading"
            color="light"
        ></loader-icon>

        <ng-content></ng-content>
    </div>
    <!-- TODO: make this more generic, probably by introducing a template input or a link button type -->
    <ng-container *ngIf="this.activeStepIndex === 0">
        <ng-container initial-back-step>
            <div class="multi-component-layout-buttons-initial-container">
                <div class="multi-component-layout-buttons-initial">
                    <ng-content select="[initial-back-step]"></ng-content>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <progress-bar
        *ngIf="(steps.length - 1 !== activeStepIndex || fullscreen) && enableProgressBar; else progressBarPlaceholder"
        class="multi-component-layout-progress-bar"
        [stepText]="progressBarStepText"
        [progress]="progress"
    ></progress-bar>

    <!-- provides background color -->
    <ng-template #progressBarPlaceholder>
        <div class="multi-component-layout-progress-bar-placeholder"></div>
    </ng-template>

    <div
        class="multi-component-layout-buttons-back-container"
        *ngIf="this.activeStepIndex !== 0"
    >
        <ng-container *ngFor="let stepConfig of stepConfigurations">
            <ng-container *ngFor="let button of stepConfig.buttons">
                <ng-container *ngIf="!button.isHidden && button.type === buttonTypes.Backward">
                    <button
                        [ngClass]="['multi-component-layout-buttons-back']"
                        (click)="triggerAction(button.type)"
                        [disabled]="button.isDisabled"
                        [analyticsClick]="
                            analyticsPrefix +
                            '_' +
                            stepConfig.name?.toLowerCase() +
                            '_' +
                            button.analyticsTitle?.toLowerCase()
                        "
                        data-cy="onboarding-prev"
                    >
                        <i class="las la-angle-left multi-component-layout-icon"></i>
                        {{ button.title }}
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="multi-component-layout-buttons-next-container">
        <ng-container *ngFor="let stepConfig of stepConfigurations">
            <ng-container *ngFor="let button of stepConfig.buttons">
                <ng-container *ngIf="!button.isHidden && button.type !== buttonTypes.Backward">
                    <button
                        [ngClass]="['fx-btn fx-btn--primary multi-component-layout-buttons-next']"
                        [disabled]="button.isDisabled"
                        (click)="triggerAction(button.type)"
                        [analyticsClick]="
                            analyticsPrefix +
                            '_' +
                            stepConfig.name?.toLowerCase() +
                            '_' +
                            button.analyticsTitle?.toLowerCase()
                        "
                        data-cy="onboarding-next"
                    >
                        {{ button.title }}
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div
        class="multi-component-layout-footer"
        *ngIf="enableFooter"
    >
        <ng-content select=".multi-component-layout-footer"></ng-content>
    </div>
</div>
