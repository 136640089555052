<centered-modal-layout
    [loading]="false"
    [disableSave]="false"
    cancelButtonTitle="Close"
    saveButtonTitle="Save"
    [loading]="!!loader.counter"
    (save)="
        update(response.startDate, response.endDate, response.description, response.statementLines, response.queries)
    "
    (close)="dialogRef.close()"
>
    <div modal-header>
        <request-header
            title="Edit transaction queries"
            [isComplete]="false"
            [completedStatusLabel]="RequestStatuses.Pending"
            [accountName]="(thread$ | async)?.account?.label || ''"
        ></request-header>
    </div>

    <div modal-content>
        <edit-transaction-queries
            *ngIf="transactions$ | async as transactions"
            [thread]="thread$ | async"
            [cardDescription]="cardDescription$ | async"
            [useDeprecatedId]="(state$ | async)?.useDeprecatedId"
            [transactions]="transactions"
            (update)="handleUpdate($event)"
        ></edit-transaction-queries>
    </div>
</centered-modal-layout>
