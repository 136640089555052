import { Directive } from "@angular/core";
import { SubscriberBaseComponent } from "../subscriber-base.component";
import { CardResources } from "../../../threads-ui/interfaces/IUiCard";
import { TaskActionService } from "./task-action.service";

@Directive()
export class ActionableCardComponent<ActionReturnType> extends SubscriberBaseComponent {
    protected actionInProgress = false;

    constructor(protected resources: CardResources, protected taskActionService: TaskActionService) {
        super();
    }

    async action(actionId: string, actionOptions?: unknown): Promise<ActionReturnType> {
        const result = await this.taskActionService.action<ActionReturnType, unknown>(
            actionId,
            this.resources,
            actionOptions,
        );
        this.actionCallback(actionId, result);
        return result;
    }

    actionCallback(_actionId: string, _value: ActionReturnType): any {
        // override by the child component, useful for afterClosed() etc
        return null;
    }

    async routeToActionTask(): Promise<void> {
        const { threadId, cardId } = this.resources;
        await this.taskActionService.routeToThreadTask(threadId, cardId);
    }
}
