import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "fx-tile-button",
    templateUrl: "./fx-tile-button.component.html",
    styleUrls: ["./fx-tile-button.component.scss"],
})
export class FxTileButtonComponent {
    /**
     * Value to be set and returned as form value.
     */
    @Input() value: string;

    @Output() selectionAppend = new EventEmitter();
    @Output() selectionRemove = new EventEmitter();

    selected: boolean;
    disabled: boolean;

    select(): void {
        this.selected = !this.selected;
        if (this.selected) {
            this.selectionAppend.emit(this.value);
        } else {
            this.selectionRemove.emit(this.value);
        }
    }
}
