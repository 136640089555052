<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>

<sidebar-modal-layout
    header="Forward message"
    (close)="close()"
>
    <div
        class="forward-message-inner-container"
        [formGroup]="form"
    >
        <div class="forward-message-body">
            <div class="forward-message-subtitle">
                Forward this message to another workflow. The original message will include a link to the forwarded
                message.
            </div>

            <div class="forward-message-preview">
                <!-- Message card preview -->
                <forward-message-preview
                    *ngIf="card && !reply"
                    [name]="card.createdBy | userToName | async"
                    [image]="card.createdBy | profilePicture | async"
                    [timestamp]="card.createdAt"
                    [message]="card.description"
                ></forward-message-preview>

                <!-- Card reply preview -->
                <forward-message-preview
                    *ngIf="card && reply"
                    [name]="reply.actor | userToName | async"
                    [image]="reply.actor | profilePicture | async"
                    [timestamp]="reply.timestamp"
                    [message]="reply.message"
                ></forward-message-preview>
            </div>

            <select-service
                formControlName="designControl"
                [workflowDesignTypes]="designTypes$ | async"
                [showWorkflowConfigurationSelectors]="false"
            ></select-service>

            <div>
                <label class="fx-form-label">Workflow:</label>
                <div class="v-select-container">
                    <v-select
                        placeholder="Select an active workflow"
                        [options]="threads$ | async"
                        [optionContent]="workflowSelectContent"
                        [tuiTextfieldCleaner]="false"
                        formControlName="threadId"
                        valueKey="id"
                        [disabled]="!form.value.designControl.threadType"
                    >
                    </v-select>

                    <ng-template
                        #workflowSelectContent
                        let-option
                    >
                        {{ (option | findIn : (threads$ | async) : "id")?.title }}
                    </ng-template>
                </div>
            </div>

            <div class="forward-message-footer">
                <div class="forward-message-footer-buttons forward-message-footer-buttons--mobile">
                    <button
                        [analyticsClick]="gaEvents.FORWARD_CARD_MESSAGE_CANCEL"
                        (click)="close()"
                        class="fx-btn fx-btn--white"
                    >
                        Cancel
                    </button>
                    <button
                        class="fx-btn fx-btn--primary"
                        role="button"
                        [analyticsClick]="gaEvents.FORWARD_CARD_MESSAGE_SEND"
                        (click)="forwardMessage()"
                    >
                        Forward Message
                    </button>
                </div>
            </div>
        </div>
    </div>
</sidebar-modal-layout>
