<div class="buttons-container">
    <button
        *ngIf="discardTitle"
        [disabled]="discardDisabled"
        (click)="onDiscard()"
        class="reply-discard"
    >
        <span>{{ discardTitle }}</span>
    </button>

    <button
        *ngIf="submitTitle"
        [disabled]="submitDisabled"
        (click)="onSubmit()"
        class="reply-submit"
    >
        <span>{{ submitTitle }}</span>
    </button>
</div>
