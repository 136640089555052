import { Injectable, Injector } from "@angular/core";

import { CardResources } from "../../threads-ui/interfaces/IUiCard";
import { ComponentType } from "@angular/cdk/portal";
import { IThread } from "@visoryplatform/threads";
import { LaunchDarklyFeatureFlags } from "../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { MatDialogConfig } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { RegistryLibrary } from "./RegistryLibrary";
import { Route } from "@angular/router";

export type ViewExtension = {
    label: string;
    componentRef: ComponentType<any>;
    showView$?: Observable<boolean>;
};

export type RouteExtension<RouteEntity = unknown> = {
    label: string;
    icon?: string;
    route: Route;
    showIcon?: (entity?: RouteEntity) => Observable<boolean>;
    showExtension: (entity?: RouteEntity) => Observable<boolean>;
    redirect?: (entity?: RouteEntity) => void;
};

export type FocusWizardExtension = {
    componentRef: ComponentType<any>;
    taskLabel: string;
};

interface ICreateMenuExtensionOption {
    title: string;
    description: string;
    analyticsEvent: string;
    permission: string;
}

interface IMenuExtensionWithAction extends ICreateMenuExtensionOption {
    action$: (thread?: IThread) => Observable<any>;
}
interface IMenuExtensionWithComponentRef extends ICreateMenuExtensionOption {
    componentRef: ComponentType<any>;
}

export type CreateMenuExtensionOption = IMenuExtensionWithAction | IMenuExtensionWithComponentRef;

export type CreateMenuExtension = {
    title: string;
    description: string;
    extensions: CreateMenuExtensionOption[];
};

export type TaskActionFn<ActionOptions, ActionResult> = (
    cardResources: CardResources,
    injector?: Injector,
    options?: ActionOptions,
) => Promise<ActionResult>;

export type TaskAction<ActionResult = unknown, ActionInput = unknown> = {
    /**
     * Cards you can run this action on, will fail if not in this array
     */
    cardTypes: string[];
    action: TaskActionFn<ActionInput, ActionResult>;
    buttonLabel?: string;
    analyticsEvents?: string[];
    statusIcon?: string;
};

export type CreateCardExtension = {
    title: string;
    tooltipMessage: string;
    analyticsEvent: string;
    permission: string | string[];
    disableInternalCreation?: boolean;
    featureFlags?: LaunchDarklyFeatureFlags | LaunchDarklyFeatureFlags[];
    icon: string;
    componentRef: ComponentType<any>;
    data?: any;
    config?: MatDialogConfig;
    hideFromMenu?: boolean;
};

export type BannerExtension = {
    componentRef: ComponentType<any>;
};

@Injectable()
export class Libraries {
    readonly createCard = new RegistryLibrary<CreateCardExtension>();
    readonly cardViews = new RegistryLibrary<ComponentType<any>>();
    readonly focusWizardViews = new RegistryLibrary<FocusWizardExtension>();
    readonly threadViews = new RegistryLibrary<RouteExtension>();
    readonly accountRoutes = new RegistryLibrary<RouteExtension>();
    readonly profileRoutes = new RegistryLibrary<RouteExtension>();
    readonly insightsRoutes = new RegistryLibrary<RouteExtension>();
    readonly appRoutes = new RegistryLibrary<RouteExtension>();
    readonly extensionMenu = new RegistryLibrary<CreateMenuExtension>();
    readonly taskActions = new RegistryLibrary<TaskAction>();
    readonly banners = new RegistryLibrary<BannerExtension>();
}
