import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../../../../portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";

@Injectable()
export class VaultCardApiService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    updateFile(
        vaultId: string,
        fileId: string,
        displayName: string,
        type: string,
        category: string,
    ): Observable<unknown> {
        const { base } = this.environment.vaultThreadsEndpoints;
        const payload = {
            displayName: displayName,
            type: type,
            category: category,
        };

        return this.http.put(`${base}/vaults/${vaultId}/${fileId}`, payload);
    }
}
