import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT, THEME } from "src/app/injection-token";

export interface IThemeInfo {
    title: string;
    message: string;
    tileHexColor: string;
    assetDirectory: string;
}

@Injectable({ providedIn: "root" })
export class ThemeService {
    private readonly appVersion = this.environment.appVersion;

    constructor(
        private meta: Meta,
        private title: Title,
        @Inject(DOCUMENT) private doc: Document,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(THEME) private theme: IThemeInfo,
    ) {}

    apply() {
        this.initialiseMetaTags();
        this.setTitle(this.theme.title);
        this.initialiseLinkElements();
    }

    private setTitle(newTitle: string) {
        this.title.setTitle(newTitle);
    }

    private initialiseMetaTags() {
        this.meta.addTag({ name: "application-name", content: this.theme.title });
        this.meta.addTag({ name: "msapplication-TileColor", content: this.theme.tileHexColor });
        this.meta.addTag({
            name: "msapplication-TileImage",
            content: `./assets/icons/mstile-${this.theme.assetDirectory}-144x144.png`,
        });

        this.meta.addTag({ name: "og:url", content: this.environment.searchOptimization.url });
        this.meta.addTag({ name: "og:type", content: "website" });
        this.meta.addTag({ name: "og:title", content: this.theme.title });
        this.meta.addTag({ name: "og:description", content: this.theme.message });
        this.meta.addTag({ name: "og:image", content: `facebook-share-icon-${this.theme.assetDirectory}-70x70.png` });
    }

    private initialiseLinkElements() {
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-57x57.png`,
            57,
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-114x114.png`,
            114,
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-72x72.png`,
            72,
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-144x144.png`,
            144,
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-120x120.png`,
            120,
        );
        this.createLink(
            "apple-touch-icon-precomposed",
            `./assets/icons/${this.theme.assetDirectory}/apple-touch-icon-152x152.png`,
            152,
        );
        this.createLink("icon", `./assets/icons/${this.theme.assetDirectory}/favicon-32x32.png`, 32, "image/png");
        this.createLink("icon", `./assets/icons/${this.theme.assetDirectory}/favicon-16x16.png`, 16, "image/png");
        this.createLink("icon", `favicon-${this.theme.assetDirectory}.ico`);
    }

    private createLink(rel: string, href: string, size?: number, type?: string) {
        const link: HTMLLinkElement = this.doc.createElement("link");

        link.setAttribute("rel", rel);
        link.setAttribute("href", `${href}?v=${this.appVersion}`);
        if (type) {
            link.setAttribute("type", type);
        }
        if (size) {
            link.setAttribute("sizes", `${size}x${size}`);
        }
        this.doc.head.appendChild(link);
    }
}
