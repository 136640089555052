import { Component, Inject, Injector, OnInit } from "@angular/core";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "../../../../portal-modules/src/lib/analytics";
import {
    CardReply,
    CardStatus,
    EditMessageCardOptions,
    IThread,
    IThreadCard,
    Role,
    SubjectType,
} from "@visoryplatform/threads";
import { DocumentCategory } from "../../enums/DocumentCategory";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { catchError, filter, last, map, switchMap, take } from "rxjs/operators";
import { combineLatest, EMPTY, forkJoin, Observable, of } from "rxjs";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { IVaultState } from "../../interfaces/IVaultState";
import { ENVIRONMENT } from "../../../../../src/app/injection-token";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "../../../../portal-modules/src/lib/environment/environment.common";
import { CardResources } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { PreviewAction, VAULT_ACTION, VaultService } from "@visoryplatform/vault";
import { DocumentSignDeclarations } from "../../enums/DocumentSignDeclarations";
import {
    ToastMessage,
    ToastSeverity,
    ToastSummary,
} from "../../../../portal-modules/src/lib/shared/constants/toast.constants";
import { VaultCardDeleteItem, VaultCardRenameItem } from "../../types/EditCardRequests";
import { ThreadCardService } from "../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { MessageService } from "@visoryplatform/portal-ui";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { VaultCardApiService } from "../vault-card/services/vault-card-api.service";
import {
    ICreateReportModalInput,
    ICreateReportModalOutput,
} from "projects/portal-modules/src/lib/threads-ui/interfaces/ICreateReportModal";
import { CreateReportModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/create-report-modal/create-report-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";

@Component({
    selector: "edit-message-modal",
    templateUrl: "./edit-message-modal.component.html",
    styleUrls: ["./edit-message-modal.component.scss"],
})
export class EditMessageModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly modalTitle = "Edit message or attachment";

    allowEdit = this.environment.featureFlags.editCardDescription;
    card$: Observable<IThreadCard>;
    cardStatuses = CardStatus;
    messageFormControl = new FormControl("");
    loader = new Loader();
    quillStyles = environmentCommon.quillConfig.styling;
    replies$: Observable<CardReply[]>;
    role: Role;
    selectedItem: IVaultItem;
    state$: Observable<IVaultState>;
    thread$: Observable<IThread>;
    canSubmit$: Observable<boolean>;
    cardOptions: EditMessageCardOptions = {
        enableMultipleFiles: false,
        autoRequestSignature: true,
        enableMarkFileAsReport: false,
        reportsRequired: false,
    };

    public cardResources: Partial<CardResources<IVaultState>> & EditMessageCardOptions;
    private dialogRef: DialogRef;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private cardService: ThreadCardService,
        private messageService: MessageService,
        private vaultService: VaultService,
        private injector: Injector,
        private dialogService: DialogService,
        private vaultCardApiService: VaultCardApiService,
        private dialog: MatDialog,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.cardResources = await this.dialogService
            .getData<Partial<CardResources<IVaultState>> & EditMessageCardOptions>(this.injector)
            .toPromise();

        const {
            thread$,
            card$,
            state$,
            role,
            replies$,
            enableMultipleFiles,
            autoRequestSignature,
            enableMarkFileAsReport,
            reportsRequired,
        } = this.cardResources;

        this.cardOptions = this.getCustomCardOptions({
            enableMultipleFiles,
            autoRequestSignature,
            enableMarkFileAsReport,
            reportsRequired,
        });

        this.role = role;
        this.thread$ = thread$;
        this.card$ = card$;
        this.replies$ = replies$;
        this.state$ = state$.pipe(filter((vaultState) => !!(vaultState && vaultState.groups)));
        this.canSubmit$ = this.state$.pipe(
            map((state) => state.groups),
            map((groups) => groups.filter(({ displayName }) => displayName === DocumentCategory.Report)),
            map((groups) => groups.flatMap((group) => group.items) ?? []),
            map((items) => items.length > 0),
            map((hasReports) => (this.cardOptions.reportsRequired ? hasReports : true)),
        );

        card$.pipe(take(1)).subscribe((card) => {
            this.messageFormControl.setValue(card.description);
        });
    }

    closeModal(): void {
        return this.dialogRef.close();
    }

    saveCloseAndTransitionFurther(
        threadId: string,
        cardId: string,
        updatedDescription: string,
        description: string,
    ): void {
        if (updatedDescription === description) {
            this.dialogRef.close(true);
        }
        this.loader
            .wrap(this.cardService.updateCardDescription(threadId, cardId, updatedDescription, CardStatus.Edited))
            .pipe(take(1))
            .subscribe(() => {
                this.showSuccessToast(ToastMessage.CardDescriptionUpdated);
                this.dialogRef.close(true);
            });
    }

    uploadFile(card: IThreadCard, file: File, threadId: string): void {
        const firstVaultId = card.subjects.find((subject) => subject.type === SubjectType.Vault)?.id;
        if (!firstVaultId) {
            return;
        }

        this.loader.show();

        const existingFiles$ = this.state$.pipe(
            map((state: IVaultState) => {
                const combinedItems = state.groups.flatMap((group) => group.items);
                return combinedItems || [];
            }),
            take(1),
        );

        const declaration = DocumentSignDeclarations.Authorise;

        existingFiles$
            .pipe(
                catchError(() => {
                    this.showErrorToast();
                    this.loader.hide();
                    return EMPTY;
                }),
                switchMap((existingVaultItems) => this.deleteItems(existingVaultItems, threadId, card.id)),
                switchMap(() => this.vaultService.uploadFile(firstVaultId, file.name, file, DocumentCategory.Document)),
                last(),
                switchMap((fileId: string) => {
                    if (this.cardOptions.autoRequestSignature) {
                        return this.vaultService.setSignable(firstVaultId, fileId, declaration);
                    }

                    return fileId;
                }),
                take(1),
            )
            .subscribe(() => {
                this.loader.hide();
                this.showSuccessToast(ToastMessage.FileUploaded);
            });
    }

    markDocumentAsReport(item: IVaultItem): void {
        const [firstFile] = item.files;
        const modalData: ICreateReportModalInput = {
            title: firstFile.filename,
            fileType: item.informationType,
        };

        this.dialog
            .open<CreateReportModalComponent>(CreateReportModalComponent, {
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                autoFocus: true,
                data: modalData,
                panelClass: ["centered-modal"],
            })
            .afterClosed()
            .pipe(
                switchMap((modalDataOutput: ICreateReportModalOutput) => {
                    if (!modalDataOutput) {
                        return EMPTY;
                    }
                    const newName$ = of(modalDataOutput.description);
                    const convertToReport$ = this.setAction(item, modalDataOutput);
                    return combineLatest([newName$, convertToReport$]);
                }),
                switchMap(([displayName]) => {
                    const { vaultId, fileId, informationType } = item;
                    const category = DocumentCategory.Report;
                    return this.vaultCardApiService.updateFile(vaultId, fileId, displayName, informationType, category);
                }),
                take(1),
            )
            .subscribe(() => {
                this.messageService.add({
                    severity: ToastSeverity.Success,
                    summary: ToastSummary.Success,
                    detail: ToastMessage.MarkedAsReport,
                });
            });
    }

    setAction(item: IVaultItem, modalDataOutput: ICreateReportModalOutput): Observable<null> {
        const actionData = {
            data: modalDataOutput?.description,
            type: item.informationType,
            metaData: {
                reportingPeriod: modalDataOutput.reportingPeriod,
            },
        };

        const { vaultId, fileId } = item;

        return this.vaultService.setAction<PreviewAction>(vaultId, fileId, VAULT_ACTION.Preview, actionData);
    }

    deleteItem(item: IVaultItem, threadId: string, cardId: string): void {
        this.loader
            .wrap(this.deleteVaultItem(item, threadId, cardId))
            .pipe(
                catchError(() => {
                    this.showErrorToast();
                    return EMPTY;
                }),
                take(1),
            )
            .subscribe(() => {
                this.showSuccessToast(ToastMessage.FileRemoved);
            });
    }

    deleteVaultItem(item: IVaultItem, threadId: string, cardId: string): Observable<void> {
        const { vault } = environmentCommon.cardsEndpoints;
        const data: VaultCardDeleteItem = {
            vaultId: item.vaultId,
            fileId: item.fileId,
            editAction: "delete",
        };

        return this.cardService.editCard<VaultCardDeleteItem>(threadId, vault, cardId, data);
    }

    downloadItem(item: IVaultItem): void {
        if (!item.files.length) {
            return;
        }

        const filename = item.files[0].filename;
        const { vaultId, fileId } = item;
        const downloadWindow = window.open("", "_self");
        this.loader
            .wrap(this.vaultService.getDownloadUrl(vaultId, fileId, filename))
            .pipe(take(1))
            .subscribe((url) => (downloadWindow.location.href = url));
    }

    renameItem(item: IVaultItem, displayName: string, threadId: string, cardId: string): void {
        const { vault } = environmentCommon.cardsEndpoints;
        const data: VaultCardRenameItem = {
            vaultId: item.vaultId,
            fileId: item.fileId,
            editAction: "rename",
            displayName,
        };

        this.loader
            .wrap(this.cardService.editCard<VaultCardRenameItem>(threadId, vault, cardId, data))
            .pipe(
                catchError(() => {
                    this.showErrorToast();
                    return EMPTY;
                }),
                take(1),
            )
            .subscribe(() => this.showSuccessToast(ToastMessage.FileRenamed));
    }

    private getCustomCardOptions(customOptions: EditMessageCardOptions): EditMessageCardOptions {
        const customCardOptions = Object.fromEntries(
            Object.entries(customOptions).filter(([_, value]) => value !== undefined),
        );

        return {
            ...this.cardOptions,
            ...customCardOptions,
        };
    }

    private showSuccessToast(message: ToastMessage): void {
        this.messageService.add({
            severity: ToastSeverity.Success,
            summary: ToastSummary.Success,
            detail: message,
        });
    }

    private showErrorToast(): void {
        this.messageService.add({
            severity: ToastSeverity.Error,
            summary: ToastSummary.Error,
            detail: ToastMessage.SomethingWentWrong,
        });
    }

    private deleteItems(existingVaultItems: IVaultItem[], threadId: string, cardId: string): Observable<void[]> {
        if (existingVaultItems?.length && threadId && cardId) {
            const observableArray = existingVaultItems.map((vaultItem) =>
                this.deleteVaultItem(vaultItem, threadId, cardId),
            );
            return forkJoin(observableArray);
        }

        return of(null);
    }
}
