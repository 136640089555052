<div class="payrun-action-request-summary-title">
    <div class="payrun-action-request-summary-title-group">
        <h3 class="payrun-action-request-summary-title-heading">Pay run summary</h3>
        <div class="payrun-action-request-summary-title-desc">
            Review employee details and approve pay run information. Add comments to request changes
        </div>
    </div>
    <div class="payrun-action-request-summary-title-buttons">
        <button
            *ngIf="role | permissions : 'PayrunRequestRefresh' : 'ThreadUpdateAll' | async"
            [analyticsClick]="gaEvents.PAYRUN_REFRESH"
            class="fx-btn fx-btn--primary-outlined"
            type="button"
            (click)="refreshPayrunData()"
        >
            <i class="las la-sync"></i>
            REFRESH REPORT
        </button>
    </div>
</div>

<ng-container *ngIf="report$ | async as report; else loading">
    <ng-container *ngIf="tableData$ | async as tableData">
        <ng-container *ngIf="form$ | async as form">
            <div
                class="threads table-container"
                *ngIf="!loader.counter; else loading"
            >
                <payrun-request-table
                    [form]="form"
                    [report]="report"
                    [readonly]="readonly"
                    [allowEdit]="!isInternal"
                    [tableData]="tableData"
                    (updateControl)="updateControlValue(form, $event)"
                    (approveAll)="approveAllRequestItems(form)"
                ></payrun-request-table>
                <warning-banner *ngIf="report?.region === regions.AU && !isInternal"
                    >Approving this pay run is my authorisation for {{ appName }} to
                    <a
                        href="https://www.visory.com.au/blog/single-touch-payroll-are-you-compliant"
                        target="_blank"
                        >lodge STP with the ATO</a
                    ></warning-banner
                >
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loading>
    <div class="report-ghost">
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
        <ghost-loader
            [showAnimation]="true"
            [width]="100"
            shape="square"
        ></ghost-loader>
    </div>
</ng-template>
