import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-verify-email-success",
    templateUrl: "./verify-email-success.component.html",
    styleUrls: ["./verify-email-success.component.scss"],
})
export class VerifyEmailSuccessComponent implements OnInit {
    emailAddress = "";
    readonly theme = this.environment.theme;
    readonly textResource = this.environment.featureFlags.text;
    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.emailAddress = params.emailAddress || "";
        });
    }
}
