<div class="flex flex-col relative">
    <line-chart
        *ngIf="chartDatasets$ | async; let datasets"
        class="block"
        [labels]="chartLabels$ | async"
        [datasets]="datasets"
        [unit]="row?.metric?.unit"
    ></line-chart>
</div>
<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
