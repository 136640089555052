import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { catchError, filter, map, shareReplay, switchMap } from "rxjs/operators";
import { combineLatest, Observable, Subscription, throwError } from "rxjs";
import { HandledError } from "projects/portal-modules/src/lib/shared/interfaces/errors";
import { ICustomer, IPaymentMethod } from "@visoryplatform/payments-service-sdk";
import { ENVIRONMENT } from "src/app/injection-token";
import { PaymentService } from "projects/default-plugins/payment/services/payment.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Account } from "@visoryplatform/threads";

@Component({
    selector: "account-subscriptions",
    templateUrl: "./account-subscriptions.component.html",
    styleUrls: ["./account-subscriptions.component.scss"],
})
export class AccountSubscriptionComponent implements OnInit, OnDestroy {
    readonly packageDisplayName = this.environment.featureFlags.packageDisplayName;
    readonly theme = this.environment.theme;

    appTheme = this.environment.appTheme;
    customer$: Observable<ICustomer>;
    errorMessage = "";
    loader = new Loader();
    paymentMethods$: Observable<IPaymentMethod[]>;
    showPaymentMethods$: Observable<boolean>;
    userId = "";

    private profileSubscription: Subscription;

    constructor(
        private paymentService: PaymentService,
        private accountRoute: AccountRouteService,
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.initPaymentSubscriptions();
    }

    ngOnDestroy(): void {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    initPaymentSubscriptions(): void {
        this.errorMessage = "";

        const account$ = this.accountRoute.getAccount();

        this.customer$ = account$.pipe(
            switchMap((account) => this.loader.wrap(this.paymentService.getAccountCustomer(account.id))),
            catchError((err: unknown) => this.handleError(err)),
            shareReplay(1),
        );

        const user$ = this.authService.getUser().pipe(filter((user) => !!user));

        this.showPaymentMethods$ = combineLatest([account$, user$]).pipe(
            map(([account, user]) => this.hasPaymentMethodPermission(account, user)),
            shareReplay(1),
        );

        this.paymentMethods$ = this.showPaymentMethods$.pipe(
            filter((showMethods) => showMethods),
            switchMap(() => this.customer$),
            filter((customer) => !!customer),
            switchMap((customer) => this.paymentService.getPaymentMethods(customer.id)),
            catchError((err: unknown) => this.handleError(err)),
        );
    }

    private hasPaymentMethodPermission(account: Account, user: AppUser): boolean {
        const primaryContactId = account.metadata?.contactInfo?.primaryContact;
        return user.id === primaryContactId;
    }

    private handleError(error: any): Observable<any> {
        if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
        } else {
            this.errorMessage = "A problem occurred fetching the user profile";
        }

        return throwError(new HandledError(error));
    }
}
