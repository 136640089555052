import { Component, Input } from "@angular/core";
import { ITimeline, Role, ThreadStatus } from "@visoryplatform/threads";
import { IWorkflow } from "@visoryplatform/workflow-core";

@Component({
    selector: "thread-workflow-status",
    templateUrl: "./thread-workflow-status.component.html",
    styleUrls: ["./thread-workflow-status.component.scss"],
})
export class ThreadWorkflowStatusComponent {
    @Input() workflow: IWorkflow;
    @Input() enablePopUp = false;
    @Input() enableDotStepper = false;
    @Input() showIcon = false;
    @Input() small = false;
    @Input() role: Role;
    @Input() thread: ITimeline;
    @Input() shouldShowTitle?: boolean;
    @Input() fullWidth: boolean;
    @Input() hideDotStepperOnMobile = false;
    @Input() overrideStepperTitleColor = false;

    ThreadStatus = ThreadStatus;
}
