export const CreateBillApprovalCardDescription = `
<p>Hi,</p><p> </p><p>Please review the list of bills below for the specified payment date and mark each as either approved or declined. Be sure to take note of any comments we’ve provided to assist with your review.</p><p> </p><p>Approved bills will be paid in full in the upcoming supplier pay run, while any declined bills will be held and reappear in the next scheduled pay run workflow.</p><p> </p><p>If you’d prefer to make a part payment or have a specific comment regarding a bill, please use the comments field provided. For any general comments or questions, feel free to simply reply to the message.</p><p> </p><p>Thanks!</p>`;

export enum BillApprovalReviewModalData {
    Title = "Are you sure you want to send this bill approval for review?",
    Subhead = "Changes will be saved and sent to your Visory team for attention.",
}

export enum BillApprovalCompleteModalData {
    Title = "Are you sure you want to approve this bill approval?",
    Subhead = "No further changes can be made and we will process this bill approval.",
}
