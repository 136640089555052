export enum QuerySubject {
    BankStatementLine = "bankStatementLine"
}

export type QueryVault = {
    vaultId: string,
    fileId: string,
    filename: string
}

export type Query = {
    id: string,

    createdAt: string,
    createdBy: string,
    updatedBy: string,
    updatedAt: string,

    query: string,

    subjectType: QuerySubject,
    subjectId: string,
    subjectDate: string,

    answer: string|null,
    vaultDetails?: QueryVault,
}