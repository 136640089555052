import { Component, Inject, Injector, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { ENVIRONMENT, MOBILE_VERIFY_SERVICE } from "src/app/injection-token";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { FocusActionWizardService, FOCUS_WIZARD_DATA, IFocusWizardStep } from "./focus-action-wizard.service";
import { IAuthInvitation } from "../../../findex-auth/services/strategies/invitation-auth.strategy";
import { IActionLink } from "@visoryplatform/threads";
import { ActivatedRoute } from "@angular/router";
import { InvitationMobileVerifyService } from "../../../shared/services/mobile-verify/invitation-mobile-verify.service";
import { SubscriberBaseComponent } from "../../../shared/components/subscriber-base.component";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

@Component({
    selector: "app-focus-action-wizard",
    templateUrl: "./focus-action-wizard.component.html",
    styleUrls: ["./focus-action-wizard.component.scss"],
    providers: [
        { provide: MOBILE_VERIFY_SERVICE, useClass: InvitationMobileVerifyService }, // use invitationStrategy instead of cognito
    ],
})
export class FocusActionWizardComponent extends SubscriberBaseComponent implements OnInit, OnDestroy {
    readonly supportEmail = this.environment.featureFlags.supportEmail;
    stepComponents: IFocusWizardStep[] = [];

    expiredAction: boolean;
    activeStepIndex = 1;
    loading: boolean;

    mobileNumber: string;
    isInvitation = false;
    headings?: {
        header: string;
        subheader: string;
    };

    destroySubject = new Subject();

    // share state between children
    state = new BehaviorSubject(null);

    constructor(
        private injector: Injector,
        private windowListenersService: WindowListenersService,
        private menuService: MenuService,
        private focusActionWizardService: FocusActionWizardService,
        private route: ActivatedRoute,
        @Inject(MOBILE_VERIFY_SERVICE) private invitationMobileVerifyService: InvitationMobileVerifyService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        super();
    }

    async ngOnInit() {
        this.windowListenersService.resize.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.menuService.hide(MenuType.Navigation);
            this.menuService.hide(MenuType.CollapsedMenuIcon);
        });

        this.loading = true;
        const actionId$ = this.route.params.pipe(
            map((params) => params.actionId),
            filter((value) => !!value),
        );

        actionId$
            .pipe(
                switchMap((actionId) => this.focusActionWizardService.getActionInvitation(actionId)),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(({ authInvitation, actionLink }) => {
                this.initialize(authInvitation, actionLink);
                this.loading = false;
            });

        actionId$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((actionId) => (this.invitationMobileVerifyService.activeInvitationId = actionId));
    }

    private initialize(invitation: IAuthInvitation, actionLink: IActionLink): void {
        const { header, subheader } = actionLink?.theme;
        if (header) {
            this.headings = { header, subheader };
        }

        if (this.isActionLinkExpired(actionLink)) {
            this.expiredAction = true;
            return null;
        }

        if (invitation.mobileNumber) {
            this.mobileNumber = invitation.mobileNumber;
            this.isInvitation = true;
        }

        const focusWizardProvider = this.focusActionWizardService.buildFocusWizardProvider(
            invitation,
            actionLink,
            this.state,
        );

        const childInjector = Injector.create({
            providers: [{ provide: FOCUS_WIZARD_DATA, useValue: focusWizardProvider }],
            parent: this.injector,
        });

        this.stepComponents = this.focusActionWizardService.buildStepConfiguration(actionLink, childInjector);

        this.activeStepIndex =
            this.stepComponents.sort((a, b) => a.stepIndex - b.stepIndex).find((step) => step?.type !== "dummy")
                .stepIndex || 0;

        focusWizardProvider.complete.pipe(takeUntil(this.ngUnsubscribe)).subscribe((next) => {
            this.goForward(next);
        });

        focusWizardProvider.back.pipe(takeUntil(this.ngUnsubscribe)).subscribe((next) => {
            this.goBack(next);
        });
    }

    private isActionLinkExpired(actionLink: IActionLink): boolean {
        const { expiredAt } = actionLink;

        if (!expiredAt) {
            return false;
        }

        const currentTimestamp = Date.now();
        const expiredAtTimestamp = new Date(expiredAt).getTime();

        return currentTimestamp > expiredAtTimestamp;
    }

    goBack(value: any) {
        if (this.stepComponents.find((step) => step.stepIndex === this.activeStepIndex - 1)) {
            this.state.next(value);
            this.activeStepIndex = this.activeStepIndex - 1;
        }
    }

    goForward(value: any) {
        if (this.stepComponents.find((step) => step.stepIndex === this.activeStepIndex + 1)) {
            this.state.next(value);
            this.activeStepIndex = this.activeStepIndex + 1;
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
