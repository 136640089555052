<div class="file-input">
    <ngx-dropzone
        (change)="onSelect($event)"
        [accept]="supportedFiles || '*'"
        [multiple]="allowMultipleFiles"
    >
        <ngx-dropzone-label>
            <p *ngIf="!isMobile">
                <i class="las la-cloud-upload-alt file-input-icon"></i>
                <span> Drop your files here, or </span>
                <a
                    role="button"
                    analyticsClick="{{ analyticsPrefix }}_browse_files"
                    class="file-input-browse"
                >
                    browse
                </a>
            </p>
            <p *ngIf="isMobile">
                <a
                    role="button"
                    analyticsClick="{{ analyticsPrefix }}_browse_files"
                    class="file-input-browse fx-btn fx-btn--secondary fx-btn--large"
                >
                    <i class="las la-cloud-upload-alt"></i> Upload Files
                </a>
            </p>

            <div
                *ngIf="supportedFiles"
                class="file-input-supported"
            >
                Supports {{ supportedFiles }}
            </div>

            <div *ngIf="listOfFailedFiles.length">
                <div class="file-input-error">{{ filesDidNotUploadMessage }}</div>
                <div
                    *ngFor="let failedFile of listOfFailedFiles"
                    class="file-name"
                >
                    {{ failedFile }}
                </div>
                <div>{{ pleaseReattemptUploadMessage }}</div>
            </div>

            <div *ngIf="listOfOversizedFiles.length">
                <div class="file-input-error">{{ uploadFilesizeIsTooLargeMessage }}</div>
                <div
                    *ngFor="let oversizedFile of listOfOversizedFiles"
                    class="file-name"
                >
                    {{ oversizedFile }}
                </div>
                <div>{{ uploadMaxFilesizeMessage }}</div>
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone>
</div>
