import { Pipe, PipeTransform } from "@angular/core";
import { IStep, IWorkflow } from "@visoryplatform/workflow-core";
import { WorkflowService } from "../services/workflow/workflow.service";

@Pipe({ name: "getCurrentStep" })
export class GetCurrentStepPipe implements PipeTransform {
    constructor(private workflowService: WorkflowService) {}

    transform(workflow: IWorkflow): IStep {
        return this.workflowService.getCurrentStep(workflow);
    }
}
