import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "absolute" })
export class AbsolutePipe implements PipeTransform {
    transform(value: number) {
        if (!value || isNaN(value)) {
            return value;
        }
        return Math.abs(value);
    }
}
