import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "appendComma",
})
export class AppendCommaPipe implements PipeTransform {
    transform(value: string, isLast: boolean): string {
        return isLast ? value : `${value}, `;
    }
}
