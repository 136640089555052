<div class="expert-body">
    <div class="expert-subtitle">Let us know how we can help! Your Visory team will respond as soon as possible.</div>

    <div class="expert-dropdown">
        <label class="fx-form-label">Request type:</label>
        <div class="v-select-container">
            <v-select
                placeholder="Select a request type"
                [options]="requestCategories"
                [optionContent]="requestTypeContent"
                [tuiTextfieldCleaner]="false"
                [formControl]="formGroup.controls.requestCategory"
            >
            </v-select>

            <ng-template
                #requestTypeContent
                let-option
            >
                {{ option }}
            </ng-template>
        </div>
        <div
            class="expert-dropdown-subtitle"
            *ngIf="!formGroup.controls.requestCategory.value"
        >
            Please select the type this request relates to.
        </div>
    </div>

    <ng-container *ngIf="formGroup.controls.requestCategory.value">
        <div class="expert-dropdown">
            <label class="fx-form-label">Relating to a customer account:</label>
            <div class="v-select-container">
                <v-select
                    placeholder="Select an account"
                    [options]="relatingOptions"
                    [optionContent]="relatingOptionContent"
                    [tuiTextfieldCleaner]="false"
                    [formControl]="formGroup.controls.relatingToAccount"
                >
                </v-select>

                <ng-template
                    #relatingOptionContent
                    let-option
                >
                    {{ option.label }}
                </ng-template>
            </div>
        </div>

        <select-account
            [accounts]="accounts"
            [formControl]="formGroup.controls.account"
            *ngIf="formGroup.controls.relatingToAccount.value?.value"
        ></select-account>

        <message-input [formControl]="formGroup.controls.message"></message-input>
    </ng-container>
</div>
