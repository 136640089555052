import { Directive, Input, HostListener, OnChanges, SimpleChanges } from "@angular/core";
import { AnalyticsService } from "../services/analytics.service";

@Directive({ selector: "[analyticsClick]" })
export class AnalyticsClickDirective implements OnChanges {
    @Input("analyticsClick") action: string;
    @Input("analyticsCategory") category = "mouse_click";
    @Input("analyticsLabel") label?: string;
    @Input("analyticsValue") value?: number;
    @Input("analyticsTriggerOn") triggerOn?: any;

    constructor(private analyticsService: AnalyticsService) {}

    @HostListener("click")
    handleClick(): void {
        if (this.triggerOn) {
            return;
        }
        this.emitAnalytics();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { triggerOn } = changes;

        if (triggerOn && triggerOn.currentValue) {
            this.emitAnalytics();
        }
    }

    private emitAnalytics(): void {
        this.analyticsService.recordEvent(this.category, this.action, this.label, this.value);
    }
}
