import { Component, Input } from "@angular/core";

export enum DurationBadgeColour {
    Red = "red",
    Yellow = "yellow",
    Green = "green",
}

@Component({
    selector: "duration-badge",
    templateUrl: "./duration-badge.component.html",
    styleUrls: ["./duration-badge.component.scss"],
})
export class DurationBadgeComponent {
    @Input() label: string;
    @Input() time: number | null;
    @Input() hint: string;
    @Input() colour: DurationBadgeColour;
    @Input() isCountdown = true;
}
