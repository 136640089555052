<ng-container *ngIf="card$ | async; let card">
    <thread-card
        *ngIf="userId$ | async; let userId"
        title="Message"
        [thread]="thread$ | async"
        [card]="card$ | async"
        [replies]="replies$ | async"
        [invertTheme]="userId === card.createdBy"
        [loading]="!!loader.counter"
        [edited]="card.status === cardStatuses.Edited"
        [isInternal]="card.isInternal"
        [edit]="edit$"
        (save)="save($event)"
    >
        <div card-actions>
            <threads-context-menu
                [card]="card"
                [thread]="thread$ | async"
                [hideMenu]="!(canRemoveCard$ | async)"
            >
                <ng-container other-actions>
                    <a
                        (click)="editMessage()"
                        *ngIf="canRemoveCard$ | async"
                        >Edit</a
                    >
                </ng-container>
                <ng-container delete-action>
                    <a
                        (click)="removeMessage()"
                        *ngIf="canRemoveCard$ | async"
                        >Delete</a
                    >
                </ng-container>
            </threads-context-menu>
        </div>
        <div card-loader>
            <loader-icon color="light"></loader-icon>
        </div>
        <div
            class="thread-card-error"
            *ngIf="errorMessage"
        >
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
