<a
    [routerLink]="routerLink"
    [queryParams]="{ ignoreModalOpen: 'true' }"
>
    <div class="search-card">
        <div class="search-card-header">
            <div>{{ message.accountLabel || "Other" }} | {{ message.threadTitle }}</div>
            <thread-type-badge
                [threadType]="(message.threadType | threadTypeName | async) || '...'"
            ></thread-type-badge>
        </div>
        <div class="search-card-body">
            <div class="search-card-body-upper">
                <fx-avatar
                    [name]="message.createdBy | userToName | async"
                    [image]="message.createdBy | profilePicture : false | async"
                    size="small"
                >
                </fx-avatar>

                <div class="search-card-body-lower">
                    <div class="search-card-message-title">
                        <div class="search-card-user-name">
                            {{ message.createdBy | userToName | async }}
                        </div>
                        <div>
                            {{ message.createdAt | date : "dd/MM/yy, h:mm a" }}
                        </div>
                    </div>
                    <div>
                        <quill-view-wrapper
                            ariaLabel="message"
                            [content]="textContent"
                        >
                        </quill-view-wrapper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
