import { DrawerContext } from "./drawer.service";

export class DrawerRef<R = unknown> {
    private context: DrawerContext<R>;

    constructor(context: DrawerContext<R>) {
        this.context = context;
    }

    close(result?: R): void {
        this.context.$implicit.next(result);
        this.context.$implicit.complete();
    }
}
