import { Component, Inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon } from "src/environments/environment";
import { IMeetingReviewData } from "../../interfaces/IMeetingReviewData";

@Component({
    selector: "app-calendar-review-meeting",
    templateUrl: "./calendar-review-meeting.component.html",
    styleUrls: ["./calendar-review-meeting.component.scss"],
})
export class CalendarReviewMeetingComponent implements OnChanges {
    @Input() data: IMeetingReviewData;

    readonly theme = this.environment.theme;
    readonly appName = this.environment.appName;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;

    avatars$: Observable<IAvatarContent[]>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private participantCache: ParticipantCache,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.currentValue?.attendees) {
            const participantIds: string[] = this.data.attendees?.map((attendee) => attendee.id);

            this.avatars$ = this.participantCache
                .getParticipants(participantIds)
                .pipe(switchMap((participants) => this.participantCache.getMultipleAvatars(participants)));
        }
    }
}
