<div
    *ngIf="canCreateNewRequest$ | async"
    class="customer-request"
>
    <div>
        <a
            class="customer-request-mobile-button"
            [analyticsClick]="gaEvents.APP_NEW_REQUEST"
            (click)="newRequest()"
        >
            <i class="las la-comment-medical"></i>
        </a>
    </div>
    <button
        [analyticsClick]="gaEvents.APP_NEW_REQUEST"
        class="fx-btn fx-btn--primary-outlined customer-request-button"
        type="button"
        (click)="newRequest()"
    >
        <i class="las la-comment-medical customer-request-button-icon"></i>
        New request
    </button>
</div>
