import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IParticipant } from "@visoryplatform/threads";
import { Subscription } from "rxjs";

@Component({
    selector: "quill-inline-editor-wrapper",
    templateUrl: "./quill-inline-editor-wrapper.component.html",
    styleUrls: ["./quill-inline-editor-wrapper.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: QuillInlineEditorWrapperComponent }],
})
export class QuillInlineEditorWrapperComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() placeholder = "";
    @Input() autoFocusOnInit = true;
    @Input() readOnly = false;
    @Input() participants?: IParticipant[];
    @Input() submitDisabled = false;
    @Input() threadType?: string;

    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();

    messageFormControl = new FormControl<string>("");
    formSub: Subscription;
    activeMentions = false;
    quillError = false;

    onChange?: (obj: Partial<string>) => void;
    onTouch?: () => void;

    ngOnInit(): void {
        this.formSub = this.messageFormControl.valueChanges.subscribe((value) => {
            this.onChange?.(value);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    writeValue(value: string): void {
        this.messageFormControl.setValue(value);
    }

    clearValue(): void {
        this.messageFormControl.setValue("");
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    saveEdit(): void {
        this.save.emit(this.messageFormControl.value);
    }

    cancelEdit(): void {
        this.cancel.emit(true);
    }

    onActiveMentions(activeMentions: boolean): void {
        this.activeMentions = activeMentions;
    }
}
