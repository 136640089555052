import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { MemoriesPlugin } from "./memories.plugin";
import { VaultModule } from "../vault/vault.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { InlineEditModule } from "../vault/components/inline-edit/inline-edit.module";
import { QuillModule } from "ngx-quill";
import { ListAccountMemoriesComponent } from "./components/list-account-memories/list-account-memories.component";
import { ListThreadMemoriesComponent } from "./components/list-thread-memories/list-thread-memories.component";
import { MemoryTableComponent } from "./components/memory-table/memory-table.component";
import { BannerRelevantMemoriesComponent } from "./components/banner-relevant-memories/banner-relevant-memories.component";
import { ModalRelevantMemoriesComponent } from "./components/modal-relevant-memories/modal-relevant-memories.component";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { SharedPipesModule } from "projects/portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { TimelineModule } from "projects/portal-modules/src/lib/timeline/timeline.module";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        VaultModule,
        FxTodoListModule,
        QuillModule,
        InlineEditModule,
        FxTableModule,
        SharedPipesModule,
        PortalUiModule,
        TimelineModule,
    ],
    exports: [BannerRelevantMemoriesComponent],
    declarations: [
        ListAccountMemoriesComponent,
        ListThreadMemoriesComponent,
        MemoryTableComponent,
        BannerRelevantMemoriesComponent,
        ModalRelevantMemoriesComponent,
    ],
    providers: [{ provide: PLUGIN_FACTORY, useClass: MemoriesPlugin, multi: true }],
})
export class MemoriesModule {}
