<div class="list-user-threads">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <ng-container *ngIf="userTimelines$ | async as userTimelines">
        <timelines-table
            [threads]="userTimelines"
            [globalRole]="role$ | async"
            [userId]="userId$ | async"
        ></timelines-table>
        <div class="fx-dashboard-threads-paginator">
            <fx-paginator
                [enableBack]="paginator.canGoBack$ | async"
                [enableNext]="paginator.canGoNext$ | async"
                (goNext)="paginator.goNext()"
                (goBack)="paginator.goBack()"
            ></fx-paginator>
        </div>
    </ng-container>
</div>
