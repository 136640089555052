<div [formGroup]="form">
    <div class="request-title-info-fields">
        <label class="fx-form-label">{{ title }}</label>
        <div class="fx-form-control">
            <input
                formControlName="title"
                class="fx-form-input"
                placeholder=""
                name="title"
            />
        </div>
        <div
            *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
            class="fx-form-validation"
        >
            {{ errorMessage }}
            <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
        </div>
    </div>
</div>
