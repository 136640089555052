import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { ITimeline, IPayRunReport, IntegrationTypes } from "@visoryplatform/threads";
import { MatTableDataSource } from "@angular/material/table";
import { FormGroup, FormControl } from "@angular/forms";
import { map, shareReplay } from "rxjs/operators";
import { PayrunReportDataService } from "../../services/payrun-report-data.service";
import { ICreatePayrunListItem } from "projects/default-plugins/vault/components/request/interfaces/request.interfaces";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";

@Component({
    selector: "payrun-report-create-request",
    templateUrl: "./payrun-report-create-request.component.html",
    styleUrls: ["./payrun-report-create-request.component.scss"],
})
export class PayrunReportCreateRequestComponent implements OnChanges {
    @Input() provider: IntegrationTypes;
    @Input() reportListItem: ICreatePayrunListItem;
    @Input() thread: ITimeline;
    @Input() cardDescription: FormControl<string>;

    form: FormGroup;
    report$: Observable<IPayRunReport>;
    tableData$: Observable<MatTableDataSource<IPayrunReportLineData>>;

    private readonly dataSource = new MatTableDataSource<IPayrunReportLineData>();

    constructor(
        private payrunDataService: PayrunReportDataService,
        private payrunReportDataService: PayrunReportDataService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { reportListItem, provider } = changes;

        if ((reportListItem || provider) && this.reportListItem && this.provider) {
            this.report$ = this.getPayRunReport(this.reportListItem.id, this.thread?.accountId, this.provider);
            this.tableData$ = this.getDataSource(this.report$);
        }
    }

    private getPayRunReport(
        reportId: string | number,
        accountId: string,
        provider: IntegrationTypes,
    ): Observable<IPayRunReport> {
        const report$ = this.payrunDataService.getPayRunReportById(reportId, accountId, provider);
        return report$.pipe(shareReplay(1));
    }

    private getDataSource(report$: Observable<IPayRunReport>): Observable<MatTableDataSource<IPayrunReportLineData>> {
        return report$.pipe(
            map((report) => {
                const dataSource = this.dataSource;
                dataSource.data = report.lines
                    .sort((a, b) => a.employeeName.localeCompare(b.employeeName))
                    .map((reportLine) => ({
                        ...reportLine,
                        currency: this.payrunReportDataService.getCurrencyByRegion(report.region),
                        hideRowChild: true,
                    }));
                return dataSource;
            }),
        );
    }
}
