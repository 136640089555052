<input
    class="tile-button-checkbox"
    type="checkbox"
    value="option.value"
    [checked]="selected"
    [disabled]="disabled"
    (change)="select()"
/>
<div
    class="tile-button"
    (click)="!disabled ? select() : undefined"
    [ngClass]="{ selected: selected }"
>
    <ng-content></ng-content>
</div>
