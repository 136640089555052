import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActionableCardComponent } from "../../../../portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { Observable, Subscription } from "rxjs";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { TASK_ACTION_LIBRARY } from "../../../../../src/app/injection-token";
import { CardResources, THREAD_CARD_RESOURCES } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ILibrary, TaskAction } from "../../../../portal-modules/src/lib/plugins";
import { PermissionService } from "../../../../portal-modules/src/lib/threads-ui/services/permissions.service";
import { TaskActionService } from "../../../../portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { TransformerActions } from "../../types/TransformerActions";
import { TransformerCardState } from "@visoryplatform/threads";
import { map } from "rxjs/operators";

@Component({
    selector: "transformer-card",
    templateUrl: "./transformer-card.component.html",
    styleUrls: ["./transformer-card.component.scss"],
})
export class TransformerCardComponent extends ActionableCardComponent<boolean> implements OnDestroy, OnInit {
    readonly TRANSFORMER_VIEW = TransformerActions.ViewTransformResults;

    hasPermissionToAction$: Observable<boolean>;
    isThreadActive$: Observable<boolean>;
    loader = new Loader();
    requestItemUploadResponses = 0;

    actionTotal$: Observable<number>;
    actionedPercentage$: Observable<number>;
    actionAmmount$: Observable<number>;
    isComplete$: Observable<boolean>;

    private navigationSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<TransformerCardState>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        private permissionService: PermissionService,
        protected taskActionService: TaskActionService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        const { role, state$ } = this.cardResources;

        this.actionAmmount$ = this.getTotalTransactionAmount(state$);
        this.actionTotal$ = this.getTotalCompletedInvoiceItemAmount(state$);
        this.actionedPercentage$ = this.getCompletedInvoiceItemPercentage(state$);
        this.isComplete$ = state$.pipe(map((state) => !!state.isCompleted));

        this.hasPermissionToAction$ = this.permissionService.checkPermissions(role, "ReadTransformerCard");

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openModal());
        }
    }

    getTotalTransactionAmount(state$: Observable<TransformerCardState>): Observable<number> {
        return state$.pipe(map((state) => Object.values(state.latestResults).filter((value) => !!value).length));
    }

    getCompletedInvoiceItemPercentage(state$: Observable<TransformerCardState>): Observable<number> {
        return state$.pipe(
            map((state) => {
                const invoiceItems = state.resultTransforms;
                const completed = Object.values(state.latestResults).filter((value) => !!value);
                return Math.floor((completed.length / invoiceItems.length) * 100);
            }),
        );
    }

    getTotalCompletedInvoiceItemAmount(state$: Observable<TransformerCardState>): Observable<number> {
        return state$.pipe(map((state) => state.resultTransforms.length));
    }

    ngOnDestroy(): void {
        this.navigationSub?.unsubscribe();
    }

    async openModal(): Promise<void> {
        await this.action(this.TRANSFORMER_VIEW);
    }
}
