import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
@Injectable({
    providedIn: "root",
})
export class ThreadDatesService {
    generateMonths(): string[] {
        const today = DateTime.local();
        const months: string[] = [];

        for (let i = -2; i < 3; i++) {
            const monthString = today.plus({ month: i }).toFormat("LLLL yyyy");
            months.push(monthString);
        }

        return months;
    }
}
