import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup, FormRecord } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { IPayRunReport } from "@visoryplatform/threads";
import {
    PayRunEarningsViewType,
    PayRunReportInfo,
    PayrunReportRegion,
    PayrunTableHeaders,
} from "../../constants/payrun-report-constants";
import { FormRequestItem, FormRequestTable } from "../../../vault/types/RequestTableForm";
import { AnalyticsService, GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { IPayrunReportLineData } from "../../interfaces/IPayrunReportLineData";
import { AcceptedCurrencies } from "@visoryplatform/payments-service-sdk";

@Component({
    selector: "payrun-request-table",
    templateUrl: "./payrun-request-table.component.html",
    styleUrls: ["./payrun-request-table.component.scss"],
})
export class PayrunRequestTableComponent implements OnChanges {
    @Input() tableData: MatTableDataSource<IPayrunReportLineData>;
    @Input() report: IPayRunReport;
    @Input() form?: FormGroup<FormRequestTable> = new FormGroup({ requestItems: new FormRecord({}) });
    @Input() allowEdit = false;
    @Input() readonly: boolean;

    @Output() updateControl = new EventEmitter<FormGroup<FormRequestItem>>();
    @Output() approveAll = new EventEmitter<void>();
    @Output() expandAll = new EventEmitter<void>();

    readonly INFO_TEXT = PayRunReportInfo;
    readonly tableHeaders = PayrunTableHeaders;

    isNzPayrun: boolean;
    payrunAllApproved = false;

    constructor(public analytics: AnalyticsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { report } = changes;

        if (report?.currentValue) {
            this.isNzPayrun = report.currentValue.region === PayrunReportRegion.NZ;
        }

        if (this.form) {
            this.payrunAllApproved = this.checkPayrunApprovalsAllCompleted(this.form);
        }
    }

    get currency(): AcceptedCurrencies {
        return this.tableData?.data[0]?.currency;
    }

    updateValue(requestTableGroup: FormGroup<FormRequestItem>, $event: boolean): void {
        requestTableGroup.patchValue({ completed: $event });
        this.updateControl.emit(requestTableGroup);
    }

    approvalAll(): void {
        this.analytics.recordEvent(GA_EVENTS_PREFIX.PAYRUN, GA_EVENTS.PAYRUN_CARD_EXPAND_LINE);
        this.approveAll.emit();
    }

    expandLineClick(): void {
        this.analytics.recordEvent(GA_EVENTS_PREFIX.PAYRUN, GA_EVENTS.PAYRUN_CARD_EXPAND_LINE);
    }

    expandAllClick(): void {
        this.analytics.recordEvent(GA_EVENTS_PREFIX.PAYRUN, GA_EVENTS.PAYRUN_CARD_EXPAND_ALL);
        this.expandAll.emit();
    }

    toggleViewType(event): void {
        if (event === PayRunEarningsViewType.Breakdown) {
            this.analytics.recordEvent(GA_EVENTS_PREFIX.PAYRUN, GA_EVENTS.PAYRUN_VIEW_BREAKDOWN);
        }

        if (event === PayRunEarningsViewType.Summary) {
            this.analytics.recordEvent(GA_EVENTS_PREFIX.PAYRUN, GA_EVENTS.PAYRUN_VIEW_SUMMARY);
        }
    }

    private checkPayrunApprovalsAllCompleted(form: FormGroup<FormRequestTable>): boolean {
        const requestItems = form.value.requestItems;
        const requestItemsValues = Object.keys(requestItems);
        return requestItemsValues.every((employeeId) => requestItems[employeeId].completed);
    }
}
