import { Component, Input } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "edit-participant-role",
    templateUrl: "./edit-participant-role.component.html",
    styleUrls: ["./edit-participant-role.component.scss"],
})
export class EditParticipantRoleComponent {
    @Input() id: string;
    @Input() name: string;
    @Input() role: Role;

    gaEvents = GA_EVENTS;
}
