import { Pipe, PipeTransform } from "@angular/core";
import { htmlToText } from "html-to-text";

const IMAGE_PLACEHOLDER_TEXT = "Image";
const STRIP_OPTIONS = {
    formatters: {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        outputImage(_elem, _walk, builder) {
            builder.addInline(IMAGE_PLACEHOLDER_TEXT);
        },
    },
    tags: {
        img: { format: "outputImage" },
    },
};
@Pipe({ name: "striphtml" })
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            const sanitizedString = htmlToText(value, STRIP_OPTIONS); // remove html tags and convert html entities
            return sanitizedString;
        } else {
            return value;
        }
    }
}
