import { Pipe, PipeTransform } from "@angular/core";

import { IWorkflowVariation } from "@visoryplatform/threads";

@Pipe({ name: "workflowVariationConfigTokenLabels" })
export class WorkflowVariationConfigTokenLabelsPipe implements PipeTransform {
    transform(workflowVariations?: IWorkflowVariation[]): string[] | null {
        if (!workflowVariations?.length) {
            return null;
        }

        const mappedVariations = workflowVariations.map((variation) => variation.label);
        return mappedVariations;
    }
}
