import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "calendar-empty-services-modal",
    templateUrl: "./calendar-empty-services-modal.component.html",
    styleUrls: ["./calendar-empty-services-modal.component.scss"],
})
export class CalendarEmptyServicesModalComponent {
    constructor(private dialogRef: MatDialogRef<any>) {}

    async close() {
        this.dialogRef.close();
    }
}
