<div class="payrun-expanded-block">
    <table class="payrun-expanded-block-table">
        <tr>
            <th
                class="payrun-expanded-block-table-section-header"
                [attr.colspan]="(data.value[0] | keyvalue).length"
            >
                <div>
                    <h3>{{ data.title }}</h3>
                    <ng-container *ngIf="data.title === payrunCardTitles.Earnings">
                        <mat-button-toggle-group
                            [formControl]="viewTypeControl"
                            aria-label="View Type"
                            class="payrun-expanded-block-table-view-type"
                        >
                            <mat-button-toggle [value]="earningsViewType.Summary"> Summary </mat-button-toggle>
                            <mat-button-toggle [value]="earningsViewType.Breakdown"> Breakdown </mat-button-toggle>
                        </mat-button-toggle-group>
                    </ng-container>
                </div>
            </th>
        </tr>
        <tr>
            <ng-container *ngFor="let item of data.value[0] | keyvalue : sort">
                <th
                    class="payrun-expanded-block-table-header"
                    [ngClass]="{
                        'align-right': PayrunAlignColumnsToLeft.indexOf(item.key) === -1
                    }"
                >
                    {{ item.key | valueToFriendlyName : false | titlecase }}
                </th>
            </ng-container>
        </tr>
        <tr *ngFor="let group of data.value">
            <ng-container *ngFor="let item of group | keyvalue : sort; let first = first">
                <td
                    [ngClass]="{
                        'data-hj-suppress': !first,
                        'align-right': PayrunAlignColumnsToLeft.indexOf(item.key) === -1
                    }"
                >
                    {{ (item.value | checkPayrunTransformedLineTypePipe)?.value || item.value }}
                    <i
                        class="las la-exclamation-circle"
                        [tuiHint]="(item.value | checkPayrunTransformedLineTypePipe).tooltip"
                        tuiHintDirection="top"
                        *ngIf="!!(item.value | checkPayrunTransformedLineTypePipe)"
                    ></i>
                </td>
            </ng-container>
        </tr>
    </table>
</div>
