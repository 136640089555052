<div class="fx-form-group">
    <label
        class="fx-form-label"
        for="{{ id }}"
        #label
        ><ng-content></ng-content
    ></label>
    <input
        class="fx-form-input"
        placeholder="{{ placeholder }}"
        attr.aria-label="{{ label.innerText }}"
        [attr.id]="id"
        [type]="type"
        [value]="value || ''"
        [disabled]="disabled"
        (blur)="blur()"
        (input)="changed($event)"
    />
    <div
        class="fx-form-validation"
        *ngIf="errorMessage"
    >
        {{ errorMessage }}
        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
    </div>
</div>
