<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>

<div
    *ngIf="thread$ | async as thread"
    class="header"
>
    <div
        class="header-close"
        (click)="close()"
    >
        <i class="las la-times"></i>
    </div>
    <h2 class="header-title">
        <a
            [routerLink]="[routes.Accounts, thread.accountId]"
            class="header-subtitle-labels-link"
            >{{ thread.account?.label }}</a
        >
    </h2>

    <div class="header-subtitle">
        <div class="header-subtitle-labels">
            <a
                [routerLink]="[routes.Timelines, thread.id]"
                class="header-subtitle-labels-link"
                ><strong>{{ thread.title }}</strong></a
            >
        </div>

        <div class="header-subtitle-automation">
            <span *ngIf="role | permissions : 'ViewWorkflowTitle' | async">{{ thread.workflow?.label }}</span>
        </div>
    </div>

    <hr />
</div>

<div *ngIf="thread$ | async as thread">
    <workflow-step-list
        [startDate]="thread.createdAt"
        [workflow]="thread.workflow"
        [role]="role"
        [thread]="thread"
        [title]="thread.title"
        (performAction)="performAction(thread, role, thread.workflow, $event)"
        (editDates)="editDates(thread, role)"
    >
    </workflow-step-list>
</div>
