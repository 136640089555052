import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IInvoice, PaymentTypes } from "@visoryplatform/payments-service-sdk";
import { PaymentService } from "projects/default-plugins/payment/services/payment.service";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";
import { GA_EVENTS } from "../../../../portal-modules/src/lib/analytics";
import { IPaymentModalData, PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { convertAmountToPriceString } from "../../payment-task-actions";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { IPaymentCardState } from "../../interfaces/IPaymentCardState";
import { Account, ICardId, IThreadCard, Role } from "@visoryplatform/threads";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";

export enum BillingTableHeaders {
    InvoiceNo = "Invoice No.",
    Item = "Item",
    Date = "Date",
    Method = "Method",
    Status = "Status",
    Amount = "Amount",
    BillingPeriod = "Billing period",
}

@Component({
    selector: "account-billing-history",
    templateUrl: "./account-billing-history.component.html",
    styleUrls: ["./account-billing-history.component.scss"],
})
export class AccountBillingHistoryComponent implements OnInit, OnDestroy {
    readonly gaEvents = GA_EVENTS;

    loader = new Loader();
    tableHeaders = BillingTableHeaders;
    errorMessage: string;
    tableData = new MatTableDataSource<IInvoice>();
    tableSubscription: Subscription;
    role$: Observable<Role>;

    constructor(
        private paymentService: PaymentService,
        private accountRoute: AccountRouteService,
        private dialog: MatDialog,
        private threadCardService: ThreadCardService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.loadInvoices();

        const user$ = this.authService.getUser();

        this.role$ = user$.pipe(
            filter((user) => !!user),
            map((user) => user.globalRole),
        );
    }

    loadInvoices(): void {
        const invoices$: Observable<IInvoice[]> = this.accountRoute.getAccount().pipe(
            switchMap((account) => this.paymentService.getAccountCustomer(account.id)),
            switchMap((customer) => {
                if (customer) {
                    return this.paymentService.listInvoices(customer.id);
                } else {
                    return of([]);
                }
            }),
            catchError(() => {
                this.errorMessage = "Sorry, something went wrong";
                return of([]);
            }),
        );

        this.tableSubscription = this.loader
            .wrap(invoices$)
            .pipe(take(1))
            .subscribe((invoices) => {
                this.tableData.data = invoices;
            });
    }

    ngOnDestroy(): void {
        this.tableSubscription?.unsubscribe();
    }

    trackInvoice(_index: number, invoice: IInvoice): string {
        return invoice?.id;
    }

    payInvoice($event: Event, invoice: IInvoice): void {
        $event.preventDefault();
        const paymentType = this.mapPaymentTypeFromInvoice(invoice);
        const subjectId = this.findSubjectIdInInvoice(paymentType, invoice);
        const cards$ = this.threadCardService.getCardsBySubject(paymentType, subjectId);

        const modalData$ = cards$.pipe(
            switchMap((cards) => {
                const paymentCard = this.mapCardsToPaymentCard(cards);

                const cardState$ = this.threadCardService.getCardState<IPaymentCardState>(
                    paymentCard.threadId,
                    paymentCard.cardId,
                );
                const account$ = this.accountRoute.getAccount().pipe(take(1));
                const card$ = this.threadCardService.getCard(paymentCard.threadId, paymentCard.cardId);

                return combineLatest([of(paymentCard.threadId), card$, cardState$, account$]);
            }),
            take(1),
        );

        this.loader.wrap(modalData$).subscribe(([threadId, card, cardState, account]) => {
            this.openPaymentModal(threadId, card, account, subjectId, cardState.state, invoice.id);
        });
    }

    openPaymentModal(
        threadId: string,
        card: IThreadCard,
        account: Account,
        subjectId: string,
        cardState: IPaymentCardState,
        invoiceId: string,
    ): void {
        this.dialog
            .open<PaymentModalComponent, IPaymentModalData, void>(PaymentModalComponent, {
                panelClass: ["centre-solid-background-modal-timeline"],
                disableClose: true,
                closeOnNavigation: false,
                hasBackdrop: true,
                data: {
                    threadId: threadId,
                    cardId: card.id,
                    description: card.description,
                    paymentsSubjectId: subjectId,
                    accountId: account.id,
                    state: cardState,
                    invoiceId: invoiceId,
                    packagePriceDetails: convertAmountToPriceString(cardState?.amount),
                },
            })
            .afterClosed()
            .subscribe((success) => {
                if (typeof success === "boolean" && success) {
                    this.loadInvoices();
                }
            });
    }

    private mapCardsToPaymentCard(cards: ICardId[]): ICardId {
        if (cards.length === 1) {
            return cards[0];
        }

        throw new Error("Cards list should only have one card per subject id");
    }

    private findSubjectIdInInvoice(paymentType: string, invoice: IInvoice): string {
        if (paymentType === PaymentTypes.RecurringPayment) {
            const subscription = typeof invoice.subscription === "string" && invoice.subscription;
            return subscription;
        } else {
            const paymentIntent = typeof invoice.payment_intent !== "string" && invoice.payment_intent;
            return paymentIntent.id;
        }
    }

    private mapPaymentTypeFromInvoice(invoice: IInvoice): PaymentTypes {
        if (invoice.subscription) {
            return PaymentTypes.RecurringPayment;
        } else {
            return PaymentTypes.OnceOffPayment;
        }
    }
}
