import { Observable } from "rxjs";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "../../../../../../src/app/injection-token";
import { ICardReadStatus, ICardTaskDetail } from "@visoryplatform/threads";
import { HttpClient } from "@angular/common/http";
import { IPaginated } from "@visoryplatform/datastore-types";

@Injectable({ providedIn: "root" })
export class TaskNotificationsService {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, private http: HttpClient) {}

    getCardReadStatus(threadId: string, cardId: string): Observable<ICardReadStatus> {
        const { threads, cards, cardReadStatus } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${threads}/${threadId}${cards}/${cardId}${cardReadStatus}`;
        return this.http.get<ICardReadStatus>(url);
    }

    getCardTasks(): Observable<ICardTaskDetail[]> {
        const { cardTasks } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${cardTasks}`;
        return this.http.get<ICardTaskDetail[]>(url);
    }

    getPaginatedCardTasks(next?: string, limit?: number): Observable<IPaginated<ICardTaskDetail>> {
        const { cardTasks } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${cardTasks}`;
        const params = { next: next || "", limit: limit || "" };
        return this.http.get<IPaginated<ICardTaskDetail>>(url, { params });
    }

    getThreadCardTasks(threadId: string): Observable<ICardTaskDetail[]> {
        const { cardTasks, threads } = environmentCommon.threadsEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${cardTasks}${threads}/${threadId}`;
        return this.http.get<ICardTaskDetail[]>(url);
    }
}
