import { Component, Inject } from "@angular/core";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { AppAssistantService } from "../../services/app-assistant.service";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { AccountAssistantService } from "../../services/account-assistant.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppChatListing } from "@visoryplatform/threads";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { FormControl, Validators } from "@angular/forms";
import { GlobalSearchService } from "projects/default-plugins/global-search/services/global-search.service";

@Component({
    selector: "chat-list",
    templateUrl: "./chat-list.component.html",
    styleUrls: ["./chat-list.component.scss"],
})
export class ChatListComponent {
    loader = new Loader();
    newMessage = new FormControl("", [Validators.required]);

    brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);
    results$: Observable<AppChatListing[]>;
    accountId$: Observable<string>;

    queryParamsSubscription: Subscription;

    constructor(
        private appAssistant: AppAssistantService,
        private accountAssistant: AccountAssistantService,
        private route: ActivatedRoute,
        private featureFlagService: FeatureFlagService,
        private router: Router,
        private globalSearchService: GlobalSearchService,
        @Inject(ENVIRONMENT) public environment: EnvironmentSpecificConfig,
    ) {
        this.accountId$ = this.route.paramMap.pipe(
            map((params) => params.get("accountId")),
            distinctUntilChanged(),
        );

        this.results$ = this.accountId$.pipe(
            switchMap((accountId) => {
                if (accountId) {
                    return this.loader.wrap(this.accountAssistant.listChats(accountId));
                } else {
                    return this.loader.wrap(this.appAssistant.listChats());
                }
            }),
        );

        this.queryParamsSubscription = this.globalSearchService.setControlValueFromSearchQueryParams(this.newMessage);
    }

    newChat(message: string, accountId?: string): void {
        this.createChat(message, accountId).subscribe((chatId) =>
            this.router.navigate([chatId], { relativeTo: this.route }),
        );
    }

    private createChat(message: string, accountId?: string): Observable<string> {
        const createChat$ = accountId
            ? this.accountAssistant.createChat(accountId, message)
            : this.appAssistant.createChat(message);

        return this.loader.wrap(createChat$);
    }
}
