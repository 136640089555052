import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { AvatarPillComponent } from "./components/avatar-pill/avatar-pill.component";
import { AnalyticsModule } from "../analytics";
import { FindexUiModule } from "../findex-ui";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { ThreadsUiPipesModule } from "../threads-ui/pipes/threads-ui-pipes.module";
import { InfoBannerComponent } from "./components/info-banner/info-banner.component";
import { WarningBannerComponent } from "./components/warning-banner/warning-banner.component";

/**
 * ALL COMPONENTS IN THIS MODULE SHOULD EVENTUALLY BE MOVED TO PORTAL UI
 * ADDING AS HOUSEKEEPING TO GET OUT OF THREADS-UI WHICH HAS BECOME A HOT MESS
 */

const exportableComponents = [ProgressBarComponent, AvatarPillComponent, InfoBannerComponent, WarningBannerComponent];

@NgModule({
    imports: [CommonModule, FxUiModule, ThreadsUiPipesModule, SharedPipesModule, AnalyticsModule, FindexUiModule],
    declarations: [...exportableComponents],
    exports: [...exportableComponents],
    providers: [],
})
export class UiComponentsModule {}
