<div class="list-user-threads">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <ng-container *ngIf="workflowConfigs$ | async as workflowConfigs">
        <workflow-configuration-list
            [workflows]="workflowConfigs"
            [showAccountLabel]="true"
        ></workflow-configuration-list>
        <div class="workflow-configuration-paginator">
            <fx-paginator
                [enableBack]="paginator.canGoBack$ | async"
                [enableNext]="paginator.canGoNext$ | async"
                (goNext)="paginator.goNext()"
                (goBack)="paginator.goBack()"
            ></fx-paginator>
        </div>
    </ng-container>
</div>
