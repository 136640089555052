<div
    class="quill-editor-wrapper"
    [ngClass]="{ 'quill-editor-wrapper--default': !inline }"
>
    <div
        class="quill-editor-wrapper-input"
        [ngClass]="{ 'quill-editor-wrapper-input--mentions-active': (activeMentions$ | async) }"
    >
        <quill-editor
            #editor
            *ngIf="modules$ | async as modules"
            [styles]="quillStyles"
            [ngClass]="toolbar ? 'editor-toolbar--show-toolbar' : 'editor-toolbar--hide-toolbar'"
            [placeholder]="placeholder || 'Type a message...'"
            aria-label="message"
            (onEditorCreated)="autoFocus($event); bindKeyboardEvent($event)"
            (onBlur)="toggleToolbar()"
            (onFocus)="toggleToolbar()"
            [formControl]="message"
            [modules]="modules"
            [readOnly]="readOnly"
        >
        </quill-editor>
    </div>
    <mentions-info-bar
        *ngIf="(activeMentions$ | async) && !inline"
        [inline]="inline"
    ></mentions-info-bar>
    <div
        class="quill-editor-wrapper-error"
        *ngIf="characterError"
    >
        <p>Your message is too large. Please reduce the size of your message including images.</p>
    </div>
</div>
