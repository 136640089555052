import { Component, Inject, HostListener, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { from, Observable } from "rxjs";
import { IVCDetails } from "../../interfaces/IVCDetails";
import { filter, switchMap, take, shareReplay } from "rxjs/operators";
import { VideoChatService } from "../../services/video-chat.service";
import { Role } from "@visoryplatform/threads";
import { VcSessionComponent } from "@visoryplatform/video-chat-sdk/components/vc-session/vc-session.component";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { VirtualBackgroundSelectorComponent } from "../virtual-background-selector/virtual-background-selector.component";
import { FxNotifierService } from "projects/portal-modules/src/lib/findex-ui/components/fx-notifier";
import { IVisualEffect } from "@visoryplatform/video-chat-sdk/types/vc-visual-effect";

export type VCModalModel = { details: Observable<IVCDetails>; role: Role; join: boolean };

@Component({
    selector: "vc-modal",
    templateUrl: "./vc-modal.component.html",
    styleUrls: ["./vc-modal.component.scss"],
    providers: [FxNotifierService],
})
export class VcModalComponent implements OnInit {
    loading: boolean;
    errorMessage = "";

    isPreCallComplete = false;
    isPreCallStarted = false;
    publishAudio = true;
    publishVideo = false;

    vcToken: string;
    user$: Observable<AppUser>;
    details$: Observable<IVCDetails>;
    role: Role;
    isMinimised = false;
    isDisabled = true;
    autoJoin: boolean;
    audioDeviceId: string;
    audioOutputDeviceId: string;
    visualEffect: IVisualEffect;
    performanceNotificationShown = false;

    @HostListener("window:beforeunload", ["$event"])
    showMessage($event) {
        $event.returnValue = true;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: VCModalModel,
        private dialog: MatDialogRef<VcModalComponent, boolean>,
        private videoChatService: VideoChatService,
        private authService: AuthService,
        private analytics: AnalyticsService,
        private dialogService: MatDialog, // private fxNotifier: FxNotifierService
    ) {}

    ngOnInit() {
        this.details$ = this.data.details;
        this.role = this.data.role;
        this.user$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            shareReplay(1),
        );

        this.autoJoin = this.data && this.data.join ? this.data.join : false;
        if (this.autoJoin) {
            this.isPreCallStarted = true;
        }
    }

    close() {
        this.dialog.close();
        this.videoChatService.updateIsUserInMeeting(false);
    }

    toggleMinimise() {
        if (this.isMinimised) {
            this.isDisabled = true;
            this.isMinimised = false;
            this.dialog.addPanelClass("video-chat-modal-timeline--maximised");
            this.dialog.removePanelClass("video-chat-modal-timeline--minimised");
        } else {
            this.isDisabled = false;
            this.isMinimised = true;
            this.dialog.removePanelClass("video-chat-modal-timeline--maximised");
            this.dialog.addPanelClass("video-chat-modal-timeline--minimised");
        }
    }

    // log(data: { stats: { stats: { video?: { frameRate: number } } }[] }) {
    //     if (data.stats) {
    //         const stats = data.stats[0]?.stats;
    //         if (stats.video?.frameRate < 10 && !this.performanceNotificationShown) {
    //             this.performanceNotificationShown = true;
    //             this.fxNotifier
    //                 .openToast(
    //                     new ToastConfig({
    //                         title: "Turn virtual background off?",
    //                         content: "If you're experiencing connection issues, try turning your background off.",
    //                         severity: "info",
    //                         acceptButtonLabel: "Turn off",
    //                         dismissButtonLabel: "Not now"
    //                     })
    //                 )
    //                 .onAction()
    //                 .subscribe(_ => {
    //                     this.publishVideo = false;
    //                 });
    //         }
    //     }
    // }
    async joinVc(sessionId: string) {
        this.isPreCallComplete = true;
        this.errorMessage = "";
        this.loading = true;
        try {
            this.vcToken = await this.videoChatService.getVideoChatToken(sessionId);
            this.videoChatService.updateIsUserInMeeting(true);
        } catch (error) {
            if (error.error) {
                this.errorMessage = error.error.message || "An unknown error occurred";
            } else {
                this.errorMessage = "An unknown error occurred";
            }
        }
        this.loading = false;
    }
    togglePreCall() {
        this.isPreCallComplete = false;
        this.isPreCallStarted = true;
    }

    async terminateSession() {
        const detailSub = this.details$
            .pipe(
                take(1),
                switchMap((detail) => {
                    const threadId = detail.threadId;
                    const cardId = detail.cardId;
                    const { sessionId } = detail;
                    return from(this.videoChatService.terminateSession(sessionId, threadId, cardId));
                }),
            )
            .subscribe(() => {
                detailSub.unsubscribe();
            });
    }

    handleError(error, componentRef: VcSessionComponent) {
        if (error === "Permission is required to share screen") {
            return;
        }

        this.videoChatService.handleError(error).subscribe((allowRetry) => {
            if (allowRetry) {
                componentRef.retrySession();
            }
        });
    }

    recordAnalytics() {
        this.analytics.recordEvent("mouse-click", GA_EVENTS.APP_SCREENSHAREVC);
    }

    addBackground() {
        this.dialogService
            .open(VirtualBackgroundSelectorComponent, {
                position: { right: "0" },
                panelClass: ["threads-sidebar"],
                disableClose: false,
                backdropClass: "modal-backdrop",
                closeOnNavigation: true,
                maxWidth: "100%",
                maxHeight: "100%",
                minHeight: "100%",
                height: "100vh",
            })
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.visualEffect = data;
                }
            });
    }
}
