import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "avatar-pill",
    templateUrl: "./avatar-pill.component.html",
    styleUrls: ["./avatar-pill.component.scss"],
})
export class AvatarPillComponent {
    @Input() name: string;
    @Input() id: string;
    @Input() detailText: string;
    @Input() image?: string;
    @Input() inverted?: boolean;
    @Input() avatarBorder = true;
    @Input() pillBorder = true;
    @Input() disabled = false;
    @Output() crossClicked: EventEmitter<string> = new EventEmitter();
}
