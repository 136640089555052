import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { VaultModule } from "../vault/vault.module";
import { FxTodoListModule } from "projects/portal-modules/src/lib/fx-todolist/fx-todolist.module";
import { InlineEditModule } from "../vault/components/inline-edit/inline-edit.module";
import { QuillModule } from "ngx-quill";
import { BillApprovalPlugin } from "./bill-approval.plugin";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { FileInputModule } from "../vault/components/file-input/file-input.module";
import { EmptyStateModule } from "projects/portal-modules/src/lib/empty-state/empty-state.module";
import { MemoriesModule } from "../memories/memories.module";
import { FxTableModule } from "projects/portal-modules/src/lib/fx-table/fx-table.module";
import { CreateBillApprovalComponent } from "./components/create-bill-approval/create-bill-approval.component";
import { BillApprovalListComponent } from "./components/bill-approval-list/bill-approval-list.component";
import { BillApprovalCardComponent } from "./components/bill-approval-card/bill-approval-card.component";
import { BillApprovalService } from "./services/bill-approval.service";
import { ViewBillApprovalComponent } from "./components/view-bill-approval/view-bill-approval.component";
import { EditBillApprovalComponent } from "./components/edit-bill-approval/edit-bill-approval.component";
import { BillApprovalFormStateService } from "./services/bill-approval-form-state.service";
import { PlannedPaymentDateComponent } from "./components/planned-payment-date/planned-payment-date.component";
import { DueDateRangeComponent } from "./components/due-date-range/due-date-range.component";
import { SharedPipesModule } from "projects/portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { BillApprovalSummaryComponent } from "./components/bill-approval-summary/bill-approval-summary.component";
import { CopilotModule } from "../copilot/copilot.module";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        VaultModule,
        FxTodoListModule,
        QuillModule,
        InlineEditModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        EmptyStateModule,
        MemoriesModule,
        FileInputModule,
        FxTableModule,
        SharedPipesModule,
        CopilotModule,
    ],
    exports: [],
    declarations: [
        CreateBillApprovalComponent,
        BillApprovalListComponent,
        BillApprovalCardComponent,
        ViewBillApprovalComponent,
        EditBillApprovalComponent,
        PlannedPaymentDateComponent,
        DueDateRangeComponent,
        BillApprovalSummaryComponent,
    ],
    providers: [
        { provide: PLUGIN_FACTORY, useClass: BillApprovalPlugin, multi: true },
        BillApprovalService,
        BillApprovalFormStateService,
    ],
})
export class BillApprovalModule {}
