import { Pipe, PipeTransform } from "@angular/core";
import { IWorkflowStepUI } from "../../threads-ui/interfaces/IWorkflowStepUI";

@Pipe({
    name: "visibleEntries",
})
export class VisibleEntriesPipe implements PipeTransform {
    transform(entries: IWorkflowStepUI[], currentStepId: string): IWorkflowStepUI[] {
        if (!entries?.length) {
            return [];
        }
        return entries.filter((entry) => this.isEntryVisible(entry, currentStepId));
    }

    private isEntryVisible(entry: IWorkflowStepUI, currentStepId: string): boolean {
        return entry.isMilestone || entry.step.id === currentStepId;
    }
}
