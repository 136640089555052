<div class="inline-edit-controls">
    <input
        type="text"
        [(ngModel)]="updatedText"
        class="fx-form-input"
    />

    <button
        class="fx-btn fx-btn--medium fx-btn--primary"
        [disabled]="updatedText === text"
        (click)="updated.emit(updatedText)"
    >
        <span>Save</span>
    </button>

    <button
        class="fx-btn fx-btn--medium fx-btn--white"
        (click)="discardTextUpdate()"
    >
        <span>Discard</span>
    </button>
</div>
