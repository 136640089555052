import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { Role } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { DocumentCategory } from "projects/default-plugins/vault/enums/DocumentCategory";
import { ENVIRONMENT } from "src/app/injection-token";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

export enum ReportingPeriod {
    Monthly = "Monthly",
    Quarterly = "Quarterly",
}

export type IDocument = {
    description: string;
    file: File;
    signature?: boolean;
    category?: DocumentCategory;
    reportingPeriod?: ReportingPeriod;
};

@Component({
    selector: "create-card-document",
    templateUrl: "./create-card-document.component.html",
    styleUrls: ["./create-card-document.component.scss"],
})
export class CreateCardDocumentComponent {
    @Input() role: Role;
    @Input() errorMessages: string[];
    @Input() documents: IDocument[];
    @Input() markAllAsSignature?: boolean;
    @Input() markAllAsReport?: boolean;

    @Output() remove = new EventEmitter<IDocument>();
    @Output() rename = new EventEmitter<IDocument>();
    @Output() markAsReport = new EventEmitter<number>();

    readonly gaEvents = GA_EVENTS;
    readonly showInsights = this.environment.featureFlags?.insightsConfiguration?.showInsights;

    documentType = DocumentCategory;
    roles = Role;
    uploadIncludesReport = false;
    uploadIncludesSign = false;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    removeDocument(document: IDocument): void {
        this.remove.emit(document);
    }

    isReportPresent(documentList: IDocument[]): boolean {
        return documentList.some((document) => document.category === DocumentCategory.Report);
    }

    isSignPresent(documentList: IDocument[]): boolean {
        return documentList.some((document) => document.signature);
    }

    updateDocumentTypeState(): void {
        this.uploadIncludesReport = this.isReportPresent(this.documents);
        this.uploadIncludesSign = this.isSignPresent(this.documents);
    }

    markDocumentAsReport(documentIndex: number, document: IDocument): void {
        document.signature = false;
        this.markAsReport.emit(documentIndex);
    }

    markDocumentAsSign(document: IDocument): void {
        document.signature = !document.signature;
    }
}
