import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActionableCardComponent } from "../../../../portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { combineLatest, from, Observable, Subscription } from "rxjs";
import {
    CardStatus,
    IThread,
    IThreadCard,
    IVaultRequestCardState,
    Role,
    VaultStateAction,
} from "@visoryplatform/threads";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { TASK_ACTION_LIBRARY } from "../../../../../src/app/injection-token";
import { CardResources, THREAD_CARD_RESOURCES } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ILibrary, TaskAction } from "../../../../portal-modules/src/lib/plugins";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import { ThreadsService } from "../../../../portal-modules/src/lib/threads-ui/services/threads.service";
import { PermissionService } from "../../../../portal-modules/src/lib/threads-ui/services/permissions.service";
import { TaskActionService } from "../../../../portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { filter, map, switchMap, take } from "rxjs/operators";
import { VaultRequestService } from "../../../vault/services/vault-request.service";

@Component({
    selector: "instructions-card",
    templateUrl: "./instructions-card.component.html",
    styleUrls: ["./instructions-card.component.scss"],
})
export class InstructionsCardComponent extends ActionableCardComponent<boolean> implements OnDestroy, OnInit {
    readonly VAULT_INSTRUCTIONS_VIEW = VaultStateAction.InstructionsViewResponse;

    actionedInstructions = 0;
    actionedPercentage = 0;
    canActionInstructions$: Observable<boolean>;
    card$: Observable<IThreadCard>;
    cardStatuses = CardStatus;
    isThreadActive$: Observable<boolean>;
    loader = new Loader();
    requestItemUploadResponses = 0;
    role: Role;
    showAttachments = false;
    state$: Observable<IVaultRequestCardState>;
    thread$: Observable<IThread>;
    userId$: Observable<string>;

    private stateSub: Subscription;
    private navigationSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<IVaultRequestCardState>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        private authService: AuthService,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
        protected taskActionService: TaskActionService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        this.setLocalVariablesFromResources(this.cardResources);

        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openInstructionsModal());
        }
    }

    ngOnDestroy(): void {
        this.stateSub?.unsubscribe();
        this.navigationSub?.unsubscribe();
    }

    openInstructionsModal(): void {
        combineLatest([this.isThreadActive$, this.canActionInstructions$])
            .pipe(
                take(1),
                filter(([threadIsActive, canRespondToRequest]) => threadIsActive && canRespondToRequest),
                switchMap(() => from(this.action(this.VAULT_INSTRUCTIONS_VIEW))),
                take(1),
            )
            .subscribe();
    }

    private updateState(state: IVaultRequestCardState): void {
        if (!state) {
            return;
        }
        const { actionedRequestItems, actionedPercentage } = VaultRequestService.calculateProgress(state.requestItems);
        this.actionedInstructions = actionedRequestItems;
        this.actionedPercentage = actionedPercentage;
    }

    private setLocalVariablesFromResources(cardResources: CardResources<IVaultRequestCardState>): void {
        const { thread$, card$, state$, role } = cardResources;

        this.role = role;
        this.thread$ = thread$;
        this.card$ = card$;
        this.state$ = state$;
        this.stateSub = state$.subscribe((vaultState) => this.updateState(vaultState));
        this.isThreadActive$ = thread$.pipe(map((thread) => this.threadsService.isThreadActive(thread)));

        const hasPermissionToAction$ = this.permissionService.checkPermissions(role, "InstructionsRequestApprove");
        this.canActionInstructions$ = combineLatest([hasPermissionToAction$, card$, this.isThreadActive$]).pipe(
            map(([hasPermissionToAction, card, isThreadActive]) => {
                const cardDeleted = card.status === this.cardStatuses.Deleted;
                return hasPermissionToAction && isThreadActive && !cardDeleted;
            }),
        );
    }
}
