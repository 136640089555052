import { Component, Injector, OnInit } from "@angular/core";
import {
    COPILOT_TRANSACTIONS_CARD_TYPE,
    CopilotTransactionsCreate,
    IThreadCard,
    ITimeline,
} from "@visoryplatform/threads";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { DateTime } from "luxon";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { RequestStatuses } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { StatementLine, Query } from "@visoryplatform/copilot";
import { EditQueriesResponse } from "../edit-transaction-queries/edit-transactions-queries.component";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";

@Component({
    selector: "create-transaction-queries",
    templateUrl: "./create-transactions-queries.component.html",
    styleUrls: ["./create-transactions-queries.component.scss"],
})
export class CreateTransactionQueriesComponent implements OnInit {
    dialogRef: DialogRef;
    thread: ITimeline;
    response: EditQueriesResponse;

    loader = new Loader();
    RequestStatuses = RequestStatuses;
    cardDescription = `<p>Hi,</p>
    <p>Please find below a list of transactions that require your input</p>
    <p></p>
    <p>If you have any queries please let me know.</p>
    <p></p>
    <p>Thanks</p>`;

    enableMemories$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableWorkflowRelevantMemories);

    constructor(
        private injector: Injector,
        private dialogService: DialogService,
        private threadCardService: ThreadCardService,
        private featureFlagService: FeatureFlagService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.loader.wrap(this.dialogService.getRef(this.injector)).toPromise();
        const data = await this.loader.wrap(this.dialogService.getData<ICreateCardEvent>(this.injector)).toPromise();

        const { thread } = data;
        this.thread = thread;
    }

    create(
        startDate: string,
        endDate: string,
        description: string,
        statementLines: Record<string, StatementLine>,
        queries: Partial<Query>[],
    ): void {
        const transactions = queries.map((query) => {
            const statementLine = statementLines[query.subjectId];

            return {
                statementLineId: statementLine.id,
                query: query.query,
                accountId: statementLine.accountId,
            };
        });

        const fromDate = DateTime.fromISO(startDate).toFormat("yyyy-MM-dd");
        const toDate = DateTime.fromISO(endDate).toFormat("yyyy-MM-dd");

        const payload: CopilotTransactionsCreate = {
            label: description,
            queries: transactions,
            fromDate,
            toDate,
        };

        this.loader
            .wrap(
                this.threadCardService.createCard<CopilotTransactionsCreate, IThreadCard>(
                    this.thread.id,
                    COPILOT_TRANSACTIONS_CARD_TYPE,
                    payload,
                ),
            )
            .subscribe((card) => this.dialogRef.close(card.id));
    }

    handleUpdate(response: EditQueriesResponse): void {
        this.response = response;
    }
}
