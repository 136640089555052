import { InjectionToken } from "@angular/core";
import { AppUser } from "../../findex-auth/model/AppUser";

export interface IAnalyticsService {
    configId: string;
    setUserId(user: AppUser);
    init(user: AppUser);
    pageViewed(path: string);
    sendEvent(category: string, action: string, label?: string, value?: number);
}

export const ANALYTICS_SERVICE = new InjectionToken("ANALYTICS_SERVICE");
