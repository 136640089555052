import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";
import { NgChanges } from "../../shared/interfaces/ng-changes.interface";

@Directive({ selector: "[unreadStatus]" })
export class UnreadStatusDirective implements OnInit, OnChanges, OnDestroy {
    @Input() unreadStatus: boolean;

    @HostBinding("class.unread") isUnread: boolean;

    unreadStatusSource = new BehaviorSubject<SimpleChange>(null);
    unreadStatusSub: Subscription;

    private readonly timeToWaitInMs = 12000;

    ngOnInit(): void {
        this.unreadStatusSub = this.unreadStatusSource
            .pipe(
                filter(
                    (unreadStatus: SimpleChange) =>
                        unreadStatus?.previousValue === true || unreadStatus?.currentValue === true,
                ),
                throttleTime(this.timeToWaitInMs, undefined, { leading: true, trailing: true }),
            )
            .subscribe((unreadStatus) => (this.isUnread = unreadStatus.currentValue));
    }

    ngOnChanges(changes: NgChanges<UnreadStatusDirective>): void {
        const { unreadStatus } = changes;
        if (unreadStatus) {
            this.unreadStatusSource.next(unreadStatus);
        }
    }

    ngOnDestroy(): void {
        this.unreadStatusSub?.unsubscribe();
    }
}
