import { Pipe, PipeTransform } from "@angular/core";
import { ProfilePictureService } from "../services/profile-picture.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Pipe({ name: "profilePicture" })
export class ProfilePicturePipe implements PipeTransform {
    constructor(private profilePictureService: ProfilePictureService) {}

    transform(userId: string, secondary = false, getMaxSize = false): Observable<string> {
        return this.profilePictureService
            .getUserProfilePicture(userId)
            .pipe(map((url) => `${url}?secondary=${secondary}&getMaxSize=${getMaxSize}`));
    }
}
