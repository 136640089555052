<h2>We're still setting you up</h2>
<p>Please get in touch with support to finalise your account set up.</p>
<p *ngIf="supportEmail">
    Click
    <a
        [href]="'mailto:' + supportEmail"
        target="_blank"
        analyticsClick="accountsetup_servicedesk"
        >here</a
    >
    to contact support.
</p>
