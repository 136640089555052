import { AuthorizationLevel } from "./AuthorizationLevel";
import { Role } from "@visoryplatform/threads";

export class AppUser {
    id: string;
    name: string;
    emailAddressVerified: boolean;
    mobileNumberVerified: boolean;
    details: {
        givenName: string;
        familyName: string;
        emailAddress?: string;
        mobileNumber?: string;
        lastLoginTimestamp?: string;
        [key: string]: string;
    };
    authorizationLevel: AuthorizationLevel;
    type: AuthStrategy;
    globalRole?: Role;
}

export enum AuthStrategy {
    Cognito = "cognito",
    AzureAD = "azuread",
    Invitation = "invitation",
}
