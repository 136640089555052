<delphi-logo
    *ngIf="brandingEnabled$ | async"
    label="{{ aiName }} Memories"
></delphi-logo>

<ghost-loader
    *ngIf="loader.counter"
    [showAnimation]="true"
    [width]="100"
    shape="square"
    class="ghost-loader"
>
</ghost-loader>

<info-banner
    *ngIf="contextMemories$ | async as context"
    (click)="context?.memories?.length && openModal(context)"
    >{{ context?.memories?.length ? context.summary : "No relevant memories" }}</info-banner
>
