<div class="pre-create-request-item">
    <fx-checkbox
        (change)="update.emit(menuItem)"
        [ngClass]="{
            disabled: disabled
        }"
        [disabled]="disabled"
        [checked]="checked"
    >
        {{ menuItem.title }}
    </fx-checkbox>

    <div
        class="pre-create-request-item-label"
        [ngClass]="{
            disabled: disabled
        }"
    >
        {{ menuItem.description }}
        <div *ngIf="checked">
            <ng-content></ng-content>
        </div>
    </div>
    <div *ngIf="disabled && disabledMessage?.length">
        <ng-container *ngFor="let error of disabledMessage">
            <div
                *ngIf="error.showError"
                class="disabled-report-info"
            >
                <i class="las la-exclamation-circle"></i>
                <span class="disabled-report-info-description">
                    {{ error.message }}
                </span>
            </div>
        </ng-container>
    </div>
</div>
