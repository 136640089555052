<div class="meeting-details">
    <div class="meeting-details-status">{{ status }}</div>
    <div
        *ngIf="(showJoinCall || showEndCall) && !sessionTerminated"
        class="meeting-details-controls"
    >
        <button
            class="fx-btn fx-btn--primary"
            role="button"
            [analyticsClick]="gaEvents.APP_JOINVC"
            [disabled]="disableJoinCall || (isUserJoined$ | async)"
            *ngIf="showJoinCall"
            (click)="join.emit()"
        >
            JOIN CALL
        </button>
        <button
            class="fx-btn fx-btn--primary-outlined"
            role="button"
            *ngIf="showEndCall"
            (click)="terminateSession()"
            [analyticsClick]="gaEvents.APP_ENDVCSESSION"
            [disabled]="disableEndCall"
        >
            END SESSION
        </button>
    </div>
</div>
