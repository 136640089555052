import { Component, Injector } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    CheckGroup,
    CreateTransformerCard,
    ITimeline,
    IntegrationTypes,
    TRANSFORMER_CARD_TYPE,
} from "@visoryplatform/threads";
import { RequestStatuses } from "projects/default-plugins/vault/components/request/constants/request.constants";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ICreateCardEvent } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card.component";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Component({
    selector: "create-transformer-check",
    templateUrl: "./create-transformer-check.component.html",
    styleUrls: ["./create-transformer-check.component.scss"],
})
export class CreateTransformerCheckComponent {
    dialogRef$: Observable<DialogRef<CreateTransformerCheckComponent>>;
    modalData$: Observable<ICreateCardEvent>;

    RequestStatuses = RequestStatuses;
    checkGroups = [{ label: "Supplier pay run", value: CheckGroup.SupplierPayRun }];
    checkDropdown = new FormControl<(typeof this.checkGroups)[0]>(null);
    loader = new Loader();

    constructor(
        private threadCardService: ThreadCardService,
        private dialogService: DialogService,
        private injector: Injector,
    ) {
        this.dialogRef$ = this.dialogService.getRef(this.injector);
        this.modalData$ = this.dialogService.getData<ICreateCardEvent>(this.injector);
    }

    async createCheck(groupId: CheckGroup): Promise<void> {
        const [modalData, dialogRef] = await Promise.all([
            this.modalData$.pipe(take(1)).toPromise(),
            this.dialogRef$.pipe(take(1)).toPromise(),
        ]);
        this.checkTenantId(modalData.thread);

        const payload: CreateTransformerCard = {
            groupId,
        };

        this.loader
            .wrap(this.threadCardService.createCard(modalData.thread.id, TRANSFORMER_CARD_TYPE, payload))
            .pipe(take(1))
            .subscribe(() => dialogRef.close());
    }

    private checkTenantId(timeline: ITimeline): void {
        const integrations = timeline.account?.metadata?.integrations;
        const xeroIntegration = integrations[IntegrationTypes.Xero];

        if (!xeroIntegration) {
            throw new Error("Xero integration not connected");
        }

        const tenantId = xeroIntegration.businessId;
        if (!tenantId || typeof tenantId !== "string") {
            throw new Error("Xero business not connected");
        }
    }
}
