<ng-container [ngSwitch]="shape">
    <div
        [style.width]="width + '%'"
        class="ghost-loader ghost-loader--square"
        [ngClass]="{ secondary: secondary, 'ghost-loader--animated': showAnimation }"
        *ngSwitchCase="GhostLoaderShape.Square"
    >
        &nbsp;
    </div>
    <div
        [style.width]="width + 'px'"
        [style.height]="width + 'px'"
        class="ghost-loader ghost-loader--round"
        [ngClass]="{ secondary: secondary, 'ghost-loader--animated': showAnimation }"
        *ngSwitchCase="GhostLoaderShape.Round"
    >
        &nbsp;
    </div>
</ng-container>
