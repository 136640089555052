import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IVaultReport } from "@visoryplatform/threads";
import { IVaultFile } from "@visoryplatform/vault";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "insights-report",
    templateUrl: "./insights-report.component.html",
    styleUrls: ["./insights-report.component.scss"],
})
export class InsightsReportComponent {
    @Input() loading = true;
    @Input() selectedReport: IVaultReport;
    @Input() selectedVaultFile: IVaultFile;
    @Output() downloadReport = new EventEmitter<IVaultReport>();

    readonly gaEvents = GA_EVENTS;
}
