<centered-modal-layout
    (save)="endSession()"
    (close)="close()"
    cancelButtonTitle="No"
    saveButtonTitle="Yes"
    analyticsPrefix="vc-end-session"
>
    <div modal-header>End session</div>
    <div modal-content>Would you like to end this video call session?</div>
</centered-modal-layout>
