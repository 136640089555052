import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "truncate", pure: true })
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 16, completeWords = false, ellipsis = "..."): string {
        if (!value || value.length <= limit) {
            return value;
        }

        if (completeWords) {
            const lastWordIndex = this.lastWordEnds(value, limit);
            return value.slice(0, lastWordIndex) + ellipsis;
        } else {
            return value.slice(0, limit) + ellipsis;
        }
    }

    private lastWordEnds(value: string, limit: number): number {
        //Plus 1 in case the final character is a space
        return value.slice(0, limit + 1).lastIndexOf(" ");
    }
}
