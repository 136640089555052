<multi-component-layout
    [stepConfigurations]="stepConfigurations"
    [fullscreen]="true"
    [enableFooter]="true"
    [enableBreadCrumb]="false"
    [enableProgressBar]="false"
    [activeStepIndex]="activeStepIndex"
>
    <step-layout [stepTitle]="'Verify Mobile'">
        <app-verify-mobile-form
            [mobileNumber]="mobileNumber$ | async"
            [codeSent]="false"
            (completed)="redirectAfterVerify()"
        >
        </app-verify-mobile-form>
    </step-layout>
</multi-component-layout>
