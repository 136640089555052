import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { DateTime } from "luxon";
import { BILL_APPROVAL_DATE_FORMAT, BillApprovalRangeDateFormControl } from "../../interfaces/BillApproval";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PlannedPaymentDateComponent),
    multi: true,
};

@Component({
    selector: "planned-payment-date",
    templateUrl: "./planned-payment-date.component.html",
    styleUrls: ["./planned-payment-date.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class PlannedPaymentDateComponent implements ControlValueAccessor, OnInit, OnDestroy {
    readonly dateFormat = "yyyy-MM-dd";

    plannedPaymentDate = new FormControl<string>("");

    private onChange?: (val: Partial<BillApprovalRangeDateFormControl>) => void;
    private onTouch?: () => void;
    private valSub?: Subscription;

    ngOnInit(): void {
        this.valSub = this.plannedPaymentDate.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            const plannedDate = value ? DateTime.fromISO(value).toFormat(BILL_APPROVAL_DATE_FORMAT) : null;
            this.onTouch?.();
            this.onChange?.(plannedDate);
        });
    }

    ngOnDestroy(): void {
        this.valSub?.unsubscribe();
    }

    writeValue(val: string): void {
        this.plannedPaymentDate.patchValue(val);
    }

    registerOnChange(fn: (val: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.plannedPaymentDate.disable();
        } else {
            this.plannedPaymentDate.enable();
        }
    }
}
