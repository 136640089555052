import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

export type ConfirmPromptData = {
    header: string;
    message: string;
    analyticsPrefix: string;
    saveButtonTitle?: string;
};

@Component({
    selector: "confirm-prompt",
    templateUrl: "./confirm-prompt.component.html",
    styleUrls: ["./confirm-prompt.component.scss"],
})
export class ConfirmPromptComponent {
    loader = new Loader();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmPromptData,
        private dialogRef: MatDialogRef<ConfirmPromptComponent, boolean>,
    ) {}

    confirm() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}
