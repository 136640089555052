import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SelectAccountExecutiveComponent } from "./components/onboarding/select-account-executive/select-account-executive.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OnboardingModule } from "../../../projects/portal-modules/src/lib/onboarding/onboarding.module";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "../../../projects/portal-modules/src/lib/threads-ui";
import { SelectCustomerSuccessManagerComponent } from "./components/onboarding/select-customer-success-manager/select-customer-success-manager.component";
import { StaffViewerComponent } from "./components/onboarding/select-customer-success-manager/staff-viewer/staff-viewer.component";
import { OnboardingBookMeetingComponent } from "./components/onboarding/select-customer-success-manager/onboarding-book-meeting/onboarding-book-meeting.component";
import { OnboardingContextScreenComponent } from "./components/onboarding/onboarding-context-screen/onboarding-context-screen.component";
import { OnboardingWizardComponent } from "./components/onboarding-wizard/onboarding-wizard.component";
import { CreateAccountComponent } from "./components/onboarding/create-account/create-account.component";
import { RouterModule } from "@angular/router";
import { OnboardingTimelineContainerComponent } from "./components/onboarding-timeline-container/onboarding-timeline-container.component";
import { OnboardingTileSelectionComponent } from "./components/onboarding/onboarding-tile-selection/onboarding-tile-selection.component";
import { EmailVerificationSentComponent } from "./components/onboarding/email-verification-sent/email-verification-sent.component";
import { OnboardingVideoModalComponent } from "./components/onboarding/select-customer-success-manager/onboarding-video-modal/onboarding-video-modal.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    imports: [
        CommonModule,
        FindexUiModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        SharedModule,
        OnboardingModule,
        ThreadsUiModule,
        RouterModule,
        MatDialogModule,
    ],
    declarations: [
        EmailVerificationSentComponent,
        OnboardingTileSelectionComponent,
        CreateAccountComponent,
        SelectAccountExecutiveComponent,
        OnboardingTimelineContainerComponent,
        SelectCustomerSuccessManagerComponent,
        StaffViewerComponent,
        OnboardingBookMeetingComponent,
        OnboardingWizardComponent,
        OnboardingVideoModalComponent,
        OnboardingContextScreenComponent,
    ],
    exports: [
        EmailVerificationSentComponent,
        OnboardingTileSelectionComponent,
        CreateAccountComponent,
        OnboardingWizardComponent,
        OnboardingTimelineContainerComponent,
    ],
})
export class SigmaModule {}
