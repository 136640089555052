import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "illustration",
    templateUrl: "./illustration.component.html",
    styleUrls: ["./illustration.component.scss"],
})
export class IllustrationComponent implements OnChanges {
    @Input() name: string;

    imageUrl: string;

    ngOnChanges(changes: SimpleChanges) {
        const { name } = changes;

        if (name) {
            this.imageUrl = this.getImageUrl(name.currentValue);
        }
    }

    private getImageUrl(name: string) {
        if (!name) {
            return null;
        }

        const required = require(`!file-loader!../../assets/${name}.svg`);
        if (!required?.default) {
            console.error("Could not find illustration", name);
            return null;
        }

        return required.default;
    }
}
