import { Component, Inject, OnInit } from "@angular/core";
import { IParticipant, IThreadCard, NotificationCardType } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";

export interface INotificationCardState<T> {
    notificationCardObject: T;
    notificationType: string;
}

interface IParticipantTimelineUpdate {
    actor: string;
    participants: string[];
}
@Component({
    selector: "notification-card.component",
    templateUrl: "./notification-card.component.html",
    styleUrls: ["./notification-card.component.scss"],
})
export class NotificationCardComponent implements OnInit {
    readonly NOTIFICATION_CARD_TYPES = NotificationCardType;
    readonly appName = this.environment.appName;

    card$: Observable<IThreadCard>;
    state$: Observable<INotificationCardState<any>>;
    notificationCardType: Observable<NotificationCardType>;

    actor$: Observable<IParticipant>;
    participants$: Observable<IParticipant[]>;

    loaded$: Observable<any>;
    participantChannelUpdate$: Observable<string>;

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private participantCache: ParticipantCache,
    ) {}

    ngOnInit() {
        const { card$, state$, thread$ } = this.resources;
        this.card$ = card$;

        this.notificationCardType = state$.pipe(
            map(
                (state: INotificationCardState<IParticipantTimelineUpdate>) =>
                    state.notificationType as NotificationCardType,
            ),
        );

        this.participantChannelUpdate$ = combineLatest([state$, thread$]).pipe(
            switchMap(([state, thread]) => {
                const participantId = state.notificationCardObject.participants.find((val) => val);
                if (state.notificationType === this.NOTIFICATION_CARD_TYPES.USER_ADDED) {
                    return of(`activity/participants/${participantId}/threads/${thread.id}/add`);
                } else if (state.notificationType === this.NOTIFICATION_CARD_TYPES.USER_REMOVED) {
                    return of(`activity/participants/${participantId}/threads/${thread.id}/remove`);
                }
                return of("");
            }),
        );

        this.actor$ = state$.pipe(
            map((state) => state.notificationCardObject),
            switchMap((obj) => this.participantCache.getParticipant(obj?.actor)),
        );

        this.participants$ = state$.pipe(
            filter((state) => !!state?.notificationCardObject),
            map((state) => state.notificationCardObject),
            switchMap((obj) => this.participantCache.getParticipants(obj?.participants)),
        );

        this.loaded$ = combineLatest([card$, this.participants$]).pipe(
            // directly after inviting a user their name is not available yet, filter it out if this is the case
            filter(([_card, participants]) => {
                if (!participants?.length) {
                    return false;
                }
                for (const participant of participants) {
                    if (!participant?.profile?.name) {
                        return false;
                    }
                }
                return true;
            }),
        );
    }
}
