import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth/services/auth.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { getPasswordErrorMessage, customValidators } from "projects/portal-modules/src/lib/shared/validators";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppUser } from "../../model/AppUser";
import { HandledError } from "../../../shared/interfaces/errors";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent extends LoginComponent implements OnInit, OnDestroy {
    readonly theme = this.environment.theme;
    readonly textResource = this.environment.featureFlags.text;

    form = new UntypedFormGroup(
        {
            newPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
            confirmPassword: new UntypedFormControl(null, [Validators.required, customValidators.passwordValidator]),
        },
        customValidators.passwordsMatchValidator("newPassword", "confirmPassword"),
    );

    showLoader: boolean;
    user$: Observable<AppUser>;
    loginSub: Subscription;
    activatedRouteSub: Subscription;

    private userName: string;
    private code: string;

    constructor(
        authService: AuthService,
        activatedRoute: ActivatedRoute,
        dialog: MatDialog,
        router: Router,
        @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig,
    ) {
        super(authService, activatedRoute, router, dialog, environment);
        this.user$ = this.authService.getUserWithoutRole().pipe(filter((user) => !!user));
        this.loginSub = this.authService.onLoginSuccess().subscribe(() => {
            this.router.navigateByUrl("/dashboard");
        });
    }

    ngOnInit() {
        this.activatedRouteSub = this.activatedRoute.queryParams.subscribe((params) => {
            if (!params.userName || !params.code) {
                this.router.navigate(["/"]);
            } else {
                this.userName = params.userName;
                this.code = params.code;
            }
        });
        this.form.setValue({
            newPassword: "",
            confirmPassword: "",
        });
    }

    ngOnDestroy(): void {
        this.loginSub?.unsubscribe();
        this.activatedRouteSub?.unsubscribe();
    }

    async updatePassword() {
        try {
            this.showLoader = true;
            const newPassword = this.form.controls.newPassword.value;
            const result = await this.authService.confirmPasswordReset(this.userName, this.code, newPassword);
            if (result.success) {
                await super.handleLogin(this.userName, newPassword);
            } else {
                this.errorMessage = result.errorMessage || "An unknown error occurred.";
            }
        } catch (error) {
            this.errorMessage = error && error.message ? error.message : "An unknown error occurred.";
            throw new HandledError(error);
        } finally {
            this.showLoader = false;
        }
    }

    getPasswordError(controlName: string, passwordMatchError: boolean): string {
        return getPasswordErrorMessage(controlName, passwordMatchError === false);
    }
}
