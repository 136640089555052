import { NgModule } from "@angular/core";
import { GetAccountDetailsPipe } from "./get-account.pipe";
import { IsThreadActivePipe } from "./is-thread-active.pipe";
import { ListOtherParticipantsPipe } from "./list-other-participants.pipe";
import { ProfilePicturePipe } from "./profile-picture.pipe";
import { ThreadParticipantsPipe } from "./thread-participants.pipe";
import { ThreadParticipantPipe } from "./thread-participant.pipe";
import { ThreadsSearchPipe } from "./threads-search.pipe";
import { ThreadsStatusSearchPipe } from "./threads-status-search.pipe";
import { TimeAgoPipe } from "./time-ago.pipe";
import { UserToNamePipe } from "./user-to-name";
import { ThreadTypeNamePipe } from "./thread-type-name.pipe";
import { GetCurrentStepPipe } from "./get-current-step.pipe";
import { ValueToFriendlyNamePipe } from "./value-to-friendly-name.pipe";
import { TodayDatePipe } from "./today-date.pipe";
import { ParticipantsToMentionPipe } from "./participants-to-mention";

const exportDeclarations = [
    GetAccountDetailsPipe,
    IsThreadActivePipe,
    ListOtherParticipantsPipe,
    ProfilePicturePipe,
    ThreadParticipantsPipe,
    ThreadParticipantPipe,
    ThreadsSearchPipe,
    ThreadsStatusSearchPipe,
    TimeAgoPipe,
    UserToNamePipe,
    ThreadTypeNamePipe,
    GetCurrentStepPipe,
    ValueToFriendlyNamePipe,
    ParticipantsToMentionPipe,
];

@NgModule({
    imports: [],
    declarations: [...exportDeclarations, TodayDatePipe],
    exports: [...exportDeclarations, TodayDatePipe],
    providers: [ListOtherParticipantsPipe],
})
export class ThreadsUiPipesModule {}
