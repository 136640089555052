<div
    class="search-card"
    *ngFor="let item of [0, 1, 2, 3]"
>
    <div class="search-card-header">
        <div [style.width]="'150px'">
            <ghost-loader
                [showAnimation]="true"
                [width]="100"
                shape="square"
                class="ghost-loader"
            >
            </ghost-loader>
        </div>
        <ghost-loader
            [style.width]="'80px'"
            [showAnimation]="true"
            [width]="100"
            shape="square"
            class="ghost-loader"
        >
        </ghost-loader>
    </div>
    <div class="search-card-body">
        <div class="search-card-body-upper">
            <ghost-loader
                [showAnimation]="true"
                [width]="32"
                shape="round"
                class="ghost-loader"
            >
            </ghost-loader>

            <div class="search-card-body-lower">
                <div class="search-card-message-title">
                    <div class="search-card-user-name">
                        <ghost-loader
                            [showAnimation]="true"
                            [style.width]="'100px'"
                            [width]="80"
                            shape="square"
                            class="ghost-loader"
                        >
                        </ghost-loader>

                        <ghost-loader
                            [showAnimation]="true"
                            [style.width]="'100px'"
                            [width]="100"
                            shape="square"
                            class="ghost-loader"
                        >
                        </ghost-loader>
                    </div>
                </div>
                <div>
                    <ghost-loader
                        [showAnimation]="true"
                        [style.width]="'100px'"
                        [width]="100"
                        shape="square"
                        class="ghost-loader"
                    >
                    </ghost-loader>
                </div>
            </div>
        </div>
    </div>
</div>
