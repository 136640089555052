<div class="card-read-status">
    <ng-container *ngIf="cardSeenByParticipants">
        <i
            (click)="toggleShowHint()"
            [analyticsClick]="gaEvents.APP_SEENUNSEEN"
            [tuiHint]="hintTemplate"
            [tuiHintManual]="shouldShowHint"
            tuiHintDirection="left"
            class="card-read-status-icon las"
            [ngClass]="{ 'la-check-double': seenByAllParticipants, 'la-check': !seenByAllParticipants }"
        ></i>

        <ng-template #hintTemplate>
            <ng-switch [ngSwitch]="seenByAllParticipants">
                <ng-container *ngSwitchCase="true">Seen by everyone</ng-container>
                <ng-container *ngSwitchCase="false">
                    <ng-container *ngIf="cardSeenByParticipants.length === 0">Delivered</ng-container>
                    <ng-container *ngIf="cardSeenByParticipants.length > 0">
                        Seen by
                        <ng-container *ngFor="let participant of cardSeenByParticipants; let index = index">
                            {{ participant | userShortName | async }}
                            {{ index === cardSeenByParticipants.length - 1 ? "" : ", " }}
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-switch>
        </ng-template>
    </ng-container>
</div>
