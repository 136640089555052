<div class="customer-body">
    <div class="customer-subtitle">Let us know how we can help! Your Visory team will respond as soon as possible.</div>

    <select-account
        [accounts]="accounts"
        [formControl]="formGroup.controls.account"
    ></select-account>

    <message-input [formControl]="formGroup.controls.message"></message-input>
</div>
