import { Component, Input } from "@angular/core";

@Component({
    selector: "mentions-info-bar",
    templateUrl: "./mentions-info-bar.component.html",
    styleUrls: ["./mentions-info-bar.component.scss"],
})
export class MentionsInfoBarComponent {
    @Input() inline = false;
}
