<div class="participant-picker">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div
        class="participant-picker-search"
        *ngIf="!assignees?.disabled"
    >
        <div class="participant-picker-search-input">
            <label
                class="participant-picker-search-input-label"
                for="clientSearchInput"
            >
                {{ label }}
            </label>
            <input
                #clientSearchInput
                id="clientSearchInput"
                [formControl]="searchTerm"
                class="fx-form-input remove-underline"
                placeholder="Search name or email"
                aria-label="Search name or email"
                analyticsClick="app_searchclients"
                autocomplete="off"
                (blur)="assignees?.markAsTouched()"
            />
        </div>
        <ng-container *ngIf="{ searchResults: searchResults$ | async }; let context">
            <div
                class="participant-picker-search-list-container"
                *ngIf="searchLoader.counter || context.searchResults"
            >
                <participant-list
                    [showLoader]="!!searchLoader.counter"
                    [participants]="context.searchResults"
                    [showInvite]="false"
                    (selectParticipant)="selectUser($event)"
                ></participant-list>
            </div>
        </ng-container>
    </div>

    <div
        *ngIf="assignees?.value?.length"
        class="participant-picker-selected-container"
        [ngClass]="{ 'no-margin disabled': !!assignees?.disabled }"
    >
        <div
            *ngFor="let assignee of assignees.value; index as i"
            class="participant-picker-selected-container-pill"
        >
            <ng-container *ngIf="assignee">
                <avatar-pill
                    [disabled]="assignees?.disabled"
                    [name]="assignee | userToName | async"
                    [id]="assignee"
                    [image]="assignee | profilePicture | async"
                    (crossClicked)="removeAssignee(assignee)"
                ></avatar-pill>
            </ng-container>
        </div>
    </div>

    <div
        *ngIf="assignees?.disabled && disabledDescription"
        class="participant-picker-description"
    >
        <info-banner>{{ disabledDescription }}</info-banner>
    </div>
</div>
