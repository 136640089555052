import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { VaultModule } from "../vault/vault.module";
import { ThreadsUiModule } from "../../portal-modules/src/lib/threads-ui";
import { FindexUiModule } from "../../portal-modules/src/lib/findex-ui";
import { FxTableModule } from "../../portal-modules/src/lib/fx-table/fx-table.module";
import { PLUGIN_FACTORY } from "../../portal-modules/src/lib/plugins";
import { ListUserTimelinesComponent } from "./components/list-user-timelines/list-user-timelines.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { SharedModule } from "../../portal-modules/src/lib/shared";
import { SharedPipesModule } from "../../portal-modules/src/lib/shared/pipes/shared-pipes.module";
import { UserTimelinesPlugin } from "./user-timelines.plugin";
import { UserAssigneeService } from "./services/user-assignee.service";
import { TimelineModule } from "projects/portal-modules/src/lib/timeline/timeline.module";
import { BulkReplaceUserComponent } from "./components/bulk-replace-user/bulk-replace-user.component";
import { ListUserConfigurationsComponent } from "./components/list-user-configurations/list-user-configurations.component";
import { WorkflowConfigurationListModule } from "../../portal-modules/src/lib/workflow-configuration-list/workflow-configuration-list.module";
import { BulkReplaceUserConfigurationsComponent } from "./components/bulk-replace-user-configurations/bulk-replace-user-configurations.component";
import { BulkReplaceUserWorkflowsComponent } from "./components/bulk-replace-user-workflows/bulk-replace-user-workflows.component";

@NgModule({
    declarations: [
        ListUserTimelinesComponent,
        ListUserConfigurationsComponent,
        BulkReplaceUserComponent,
        BulkReplaceUserConfigurationsComponent,
        BulkReplaceUserWorkflowsComponent,
    ],
    imports: [
        CommonModule,
        ThreadsUiModule,
        FindexUiModule,
        VaultModule,
        FxTableModule,
        MatDialogModule,
        MatSelectModule,
        SharedPipesModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        PortalUiModule,
        TimelineModule,
        WorkflowConfigurationListModule,
    ],
    providers: [UserAssigneeService, { provide: PLUGIN_FACTORY, useClass: UserTimelinesPlugin, multi: true }],
})
export class UserTimelinesModules {}
