<div class="scorecard">
    <fx-tabs
        class="scorecard-tabs"
        [mobileScroll]="true"
        #tabs
    >
        <fx-tabs-item
            *ngFor="let extension of extensions$ | async"
            #tab
            [header]="extension.label"
            [analyticsClick]="extension.label"
            [analyticsTriggerOn]="tab.active"
            (selected)="navigateTo(extension.path)"
        ></fx-tabs-item>
    </fx-tabs>

    <div class="scorecard-tabs-container">
        <div class="scorecard-tabs-content">
            <router-outlet></router-outlet>
        </div>

        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>
    </div>
</div>
