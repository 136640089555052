import { FormControl, FormRecord, FormGroup } from "@angular/forms";
import { IThreadCard, CardReply, ITimeline, BillApprovalState, Role } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export const BILL_APPROVAL_DATE_FORMAT = "yyyy-MM-dd";
export const BILL_APPROVAL_DEFAULT_START_DATE = DateTime.local()
    .minus({ months: 3 })
    .startOf("month")
    .toFormat(BILL_APPROVAL_DATE_FORMAT);
export const BILL_APPROVAL_DEFAULT_END_DATE = DateTime.local()
    .plus({ month: 1 })
    .endOf("month")
    .toFormat(BILL_APPROVAL_DATE_FORMAT);

export type BillApprovalModalData = {
    card$: Observable<IThreadCard>;
    thread$: Observable<ITimeline>;
    state$: Observable<BillApprovalState>;
    replies$: Observable<CardReply[]>;
    role: Role;
    readonly?: boolean;
    focusReplyInput: boolean;
    allowRecomplete?: boolean;
};

export type BillApprovalFormValues = {
    fileId: string;
    approved: boolean;
    declined: boolean;
    externalComment: string;
    internalComment: string;
    description: string;
};

export type BillApprovalFormItem = {
    fileId: FormControl<string>;
    internalComment: FormControl<string>;
    externalComment: FormControl<string>;
    approved?: FormControl<boolean>;
    declined?: FormControl<boolean>;
    description?: FormControl<string>;
};

export type BillApprovalForm = {
    invoiceItems: FormRecord<FormGroup<BillApprovalFormItem>>;
};

export type BillApprovalRangeDateFormControl = string;

export type BillApprovalDatePickerFormControl = {
    startDate: string;
    endDate: string;
};
