import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Enterprise, AccessLevel, Entity } from "@visoryplatform/openmeasures-core";
import { SubscriberBaseComponent } from "projects/portal-modules/src/lib/shared/components/subscriber-base.component";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

@Component({
    selector: "enterprise-permissions",
    styleUrls: ["./enterprise-permissions.component.scss"],
    templateUrl: "./enterprise-permissions.component.html",
})
export class EnterprisePermissionsComponent extends SubscriberBaseComponent implements OnChanges {
    @Input() enterprise: Enterprise;
    @Input() entities: Entity[];
    @Input() permissions: AccessLevel;

    @Output() delete = new EventEmitter<void>();
    @Output() save = new EventEmitter<AccessLevel>();

    classificationValues: string[][];
    classificationsControl = new UntypedFormControl([]);
    classificationIndex = new UntypedFormControl(null);

    classificationsForm = new UntypedFormGroup({
        classificationIndex: this.classificationIndex,
        classifications: this.classificationsControl,
    });

    constructor() {
        super();
        this.classificationIndex.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.classificationsControl.setValue([]));
    }

    ngOnChanges(changes: SimpleChanges) {
        const { permissions, entities } = changes;

        if (permissions?.currentValue) {
            const formValue: AccessLevel = {
                classificationIndex: this.permissions.classificationIndex || null,
                classifications: this.permissions.classifications || [],
            };
            this.classificationsForm.setValue(formValue);
        }

        if (entities?.currentValue) {
            this.classificationValues = this.mapClassificationValues(this.entities);
        }
    }

    toggleClassification(classification: string): void {
        const currentClassifications: Set<string> = new Set(this.classificationsControl.value);

        if (currentClassifications.has(classification)) {
            currentClassifications.delete(classification);
        } else {
            currentClassifications.add(classification);
        }

        this.classificationsControl.setValue([...currentClassifications]);
    }

    trackEntity(_index: number, entity: Entity): string {
        return entity.id;
    }

    private mapClassificationValues(entities: Entity[]): string[][] {
        const values: string[][] = [];

        for (const entity of entities) {
            if (!entity.classifications) {
                continue;
            }

            for (
                let classificationIndex = 0;
                classificationIndex < entity.classifications.length;
                classificationIndex++
            ) {
                values[classificationIndex] = values[classificationIndex] || [];

                const val = entity.classifications[classificationIndex];
                if (!values[classificationIndex].includes(val)) {
                    values[classificationIndex].push(val);
                }
            }
        }

        return values;
    }
}
