import { Component, Input } from "@angular/core";
import { ITimeline } from "@visoryplatform/threads";

@Component({
    selector: "workflow-details-header",
    templateUrl: "./workflow-details-header.component.html",
    styleUrls: ["./workflow-details-header.component.scss"],
})
export class WorkflowDetailsHeaderComponent {
    @Input() thread: ITimeline;
}
