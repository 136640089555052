import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from "@angular/router";
import { Observable, combineLatest, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";

import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { OpenMeasuresService } from "./open-measures.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { inject } from "@angular/core";

const insightsPermissionRoute = "insights-permissions";

export const insightsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
): boolean | Observable<boolean | UrlTree> => {
    const openmeasuresService = inject(OpenMeasuresService);
    const authService = inject(AuthService);
    const permissionService = inject(PermissionService);
    const router = inject(Router);
    const environment = inject<EnvironmentSpecificConfig>(ENVIRONMENT);
    const isInsightsPermissionsProfileRoute = of(route.routeConfig?.path === insightsPermissionRoute);

    const hasOpenMeasures = (): Observable<boolean> => {
        return openmeasuresService.getEnterprises().pipe(
            map((hasEnterprises) => !!hasEnterprises?.length),
            catchError(() => of(false)),
        );
    };

    const getShowIcon = (): Observable<boolean> => {
        const showInsights = environment?.featureFlags?.insightsConfiguration?.showInsights;

        if (showInsights) {
            return authService.getUser().pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    const role = user.globalRole;
                    return permissionService
                        .checkPermissions(role, "CreateInsights")
                        .pipe(map((hasPermission) => !hasPermission));
                }),
            );
        } else {
            return of(showInsights);
        }
    };

    if (route.children?.length) {
        return true;
    }

    return combineLatest([hasOpenMeasures(), getShowIcon(), isInsightsPermissionsProfileRoute]).pipe(
        map(([hasOpenMeasures, hasReports, isProfileRoute]) => {
            if (isProfileRoute && hasOpenMeasures) {
                return true;
            } else if (hasOpenMeasures) {
                return router.createUrlTree(["/insights", "scorecard"]);
            } else if (hasReports) {
                return router.createUrlTree(["/insights", "reports"]);
            } else {
                return false;
            }
        }),
    );
};
