<p>
    <fx-avatar
        [name]="event.actor | userToName | async"
        [image]="event.actor | profilePicture | async"
        size="large"
    ></fx-avatar>
    <span class="name"
        ><strong>{{ event.actor | userToName | async }} says:</strong></span
    >
</p>
<p>{{ event.payload.message }}</p>
