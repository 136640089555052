<div class="fx-onboarding-dialog">
    <div class="fx-onboarding-back">
        <button
            class="fx-onboarding-back-button"
            (click)="closeDialog()"
        >
            <i class="fx-onboarding-back-icon las la-times"></i>
        </button>
    </div>
    <div class="fx-onboarding-dialog-content">
        <change-password-form (passwordUpdated)="closeDialog()"></change-password-form>
    </div>
</div>
