<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="state$ | async as state">
        <action-request
            [analyticsTags]="analyticsTags"
            [completeButtonText]="buttonLabel.Complete"
            [requiredReviewPermissions]="requiredReviewPermissions"
            [modalData]="modalData"
            [form]="form"
            [thread$]="thread$"
            [readonly]="readonly"
            [card$]="card$"
            [replies]="replies$ | async"
            [state]="state"
            [userId$]="userId$"
            [currentUserRole]="currentUserRole"
            [completedStatusLabel]="requestStatuses.Completed"
            [actionedRequestItems]="actionedRequestItems"
            [actionedPercentage]="actionedPercentage"
            (savePending)="savePendingChanges()"
        >
            <request-attachments
                title="Attachments"
                [vaultId]="state?.vaultId"
                [attachments]="state?.attachments"
            ></request-attachments>

            <ng-container *ngIf="thread$ | async as thread">
                <rfi-todos
                    #rfiTodosComponent
                    [title]="todosTitle"
                    [state]="state"
                    [actionedRequestItems]="actionedRequestItems"
                    [actionedPercentage]="actionedPercentage"
                    [form]="form"
                    [card]="card"
                    [canUpdateTodoListItem]="canUpdateTodoListItem$ | async"
                    [userId]="userId$ | async"
                    [readonly]="readonly"
                    [thread]="thread"
                    [canReopenRequest]="canReopenRequest$ | async"
                    (updateControl)="updateRFI($event, analyticsTags.analyticsPrefix)"
                ></rfi-todos>

                <div
                    *ngIf="card?.status !== CARD_STATUSES.Disabled || !readonly"
                    [ngClass]="
                        actionedPercentage === 100
                            ? 'request-common-complete-all--disabled'
                            : 'request-common-complete-all'
                    "
                >
                    <button (click)="markAllComplete(true, card, state, thread.id)">
                        {{ buttonLabel.MarkAllAsComplete }}
                    </button>
                </div>
            </ng-container>
        </action-request>
    </ng-container>
</ng-container>
