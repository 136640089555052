import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { NotificationCardComponent } from "./components/notification-card/notification-card.component";

@Injectable()
export class NotificationPlugin implements IPluginFactory {
    readonly id = "NotificationPlugin";

    constructor(libraries: Libraries) {
        libraries.cardViews.register("notification-card", NotificationCardComponent);
    }
}
