<section class="tab-section">
    <div class="accounts">
        <div class="accounts-header">
            <h3>Insights</h3>
        </div>

        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>

        <div *ngIf="account$ | async as account">
            <v-toggle
                [formControl]="enabled"
                (change)="saveForm(account, { enabled: enabled.value })"
                >Enable insights</v-toggle
            >

            <warning-banner *ngIf="!enabled.value && enabled.disabled"
                >Xero intergration required to turn on Insights</warning-banner
            >

            <p *ngIf="enabled.value && (insights$ | async) as insights">
                <br />Enterprise ID:
                <a [routerLink]="['/insights', 'scorecard', insights.enterpriseId, 'entity', insights.entityId]">{{
                    insights.enterpriseId
                }}</a>
            </p>

            <div
                class="refresh"
                *ngIf="enabled.value"
            >
                <label
                    class="date-range-label"
                    id="dateRangeLabel"
                >
                    Date range
                </label>
                <mat-form-field>
                    <mat-date-range-input
                        [rangePicker]="picker"
                        [formGroup]="range"
                    >
                        <input
                            matInput
                            matStartDate
                            formControlName="periodStart"
                            placeholder="Start"
                        />
                        <input
                            matEndDate
                            formControlName="periodEnd"
                            placeholder="End"
                        />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                        matIconSuffix
                        [for]="picker"
                    >
                        <i
                            class="las la-calendar date-picker-icon"
                            matDatepickerToggleIcon
                        ></i>
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <div>
                    <button
                        class="fx-btn fx-btn--primary"
                        type="button"
                        [disabled]="loader.counter"
                        (click)="refreshPeriod(account, range.value)"
                    >
                        Refresh
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
