import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Subject } from "rxjs";

const GUTTER_SIZE_PIXELS = 24;

@Component({
    selector: "html-preview",
    templateUrl: "./html-preview.component.html",
    styleUrls: ["./html-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlPreviewComponent implements OnChanges {
    @Input() url: string;

    @ViewChild("htmlPreviewIframe") iframe: ElementRef<HTMLIFrameElement>;

    dataUrlBypass: SafeHtml;

    height$ = new Subject();

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges) {
        const { url } = changes;

        if (url) {
            if (url.currentValue) {
                //WARNING: if at any point we let users upload previewable documents we need to reconsider
                this.dataUrlBypass = this.sanitizer.bypassSecurityTrustHtml(url.currentValue);
            } else {
                this.dataUrlBypass = null;
            }
        }
    }

    setHeight() {
        // gets height from iframe, then applies that to the content div, making it fill up the available space
        Promise.resolve().then(() => {
            const height = this.iframe?.nativeElement?.contentDocument?.body.clientHeight;
            if (height) {
                this.height$.next(`${height + GUTTER_SIZE_PIXELS}px`);
            }
        });
    }
}
