import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Observable, Subscription, fromEvent, merge } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({ selector: "[resize-before-interaction]" })
export class ResizeBeforeInteractionDirective implements OnInit, OnDestroy {
    @Output() resizeBeforeInteraction = new EventEmitter<ResizeObserverEntry>();

    hostResize$: Observable<ResizeObserverEntry>;
    userInteraction$: Observable<Event>;

    resizeSubscription = new Subscription();

    constructor(private element: ElementRef<Element>) {
        const userWheel$ = fromEvent(window, "wheel");
        const userTouchMove$ = fromEvent(window, "touchmove");
        const userMouseDown$ = fromEvent(window, "mousedown");

        this.userInteraction$ = merge(userWheel$, userTouchMove$, userMouseDown$);
        this.hostResize$ = this.getResizeObservable();
    }

    ngOnInit(): void {
        this.subscribeContentResize();
    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
    }

    private subscribeContentResize(): void {
        this.resizeSubscription = this.hostResize$.pipe(takeUntil(this.userInteraction$)).subscribe((entry) => {
            this.resizeBeforeInteraction.emit(entry);
        });
    }

    private getResizeObservable(): Observable<ResizeObserverEntry> {
        return new Observable((observer) => {
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    observer.next(entry);
                }
            });

            resizeObserver.observe(this.element.nativeElement);

            return () => {
                resizeObserver.disconnect();
            };
        });
    }
}
