import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IParticipant, ParticipantType, Role, UserRoleService } from "@visoryplatform/threads";
import { AdminService } from "projects/portal-modules/src/lib/admin/services/admin.service";
import { AppUser } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";

@Component({
    selector: "search-participants",
    templateUrl: "./search-participants.component.html",
    styleUrls: ["./search-participants.component.scss"],
})
export class SearchParticipantsComponent implements OnInit, OnDestroy {
    @Input() existingParticipants: IParticipant[];
    @Input() participantSearchRole: Role;
    @Input() placeholder: string;
    @Output() inputBlurred = new EventEmitter<boolean>();
    @Output() addParticipant = new EventEmitter<IParticipant>();

    loader = new Loader();
    searchTerm = new FormControl("");
    minimumSearchLength = 2;

    searchPlaceholder: string;
    searchResults$: Observable<IParticipant[]>;
    private searchSubscription: Subscription;

    constructor(private adminService: AdminService) {}

    ngOnInit(): void {
        const distinctTerms$ = this.searchTerm.valueChanges.pipe(distinctUntilChanged());

        const emptyVals$ = distinctTerms$.pipe(
            filter((term) => !term || term?.length <= this.minimumSearchLength),
            map(() => null),
        );

        const searchVals$ = distinctTerms$.pipe(
            filter((term) => term?.length > this.minimumSearchLength),
            debounceTime(500),
            switchMap((term) => this.loader.wrap(this.search(term, this.participantSearchRole))),
        );

        this.searchResults$ = merge(emptyVals$, searchVals$);
        this.searchPlaceholder = this.placeholder || "Search for a participant";
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }

    selectUser(participant: IParticipant): void {
        this.addParticipant.emit(participant);
        this.searchTerm.setValue("");
    }

    handleInputBlurred(): void {
        this.inputBlurred.emit(true);
    }

    private async search(term: string, participantSearchRole: Role): Promise<IParticipant[] | null> {
        const results = await this.searchAllUsers(term, participantSearchRole);

        if (this.existingParticipants) {
            return results?.filter((user) => this.existingParticipants?.every((selected) => selected.id !== user.id));
        }

        return results;
    }

    private async searchAllUsers(searchTerm: string, participantSearchRole): Promise<IParticipant[]> {
        if (!searchTerm || searchTerm.length <= 2) {
            return null;
        }

        const clients = await this.adminService.searchClients(searchTerm).toPromise();

        const mappedSearchResults = clients
            .filter((client) => {
                const userRole = UserRoleService.getUserRole(client.id);
                return !this.participantSearchRole || participantSearchRole === userRole;
            })
            .map((client) => this.mapToParticipant(client));

        return mappedSearchResults;
    }

    private mapToParticipant(user: AppUser): IParticipant {
        return {
            id: user.id,
            type: ParticipantType.User,
            profile: {
                name: user.name,
                emailAddress: user.details.emailAddress,
                mobileNumber: user.details.mobileNumber,
                familyName: user.details.familyName,
                givenName: user.details.givenName,
            },
            //NOTE: We should not be assuming a user from Cognito is client, and Azure a staff
            //Skirting around a UI limitation here, but this should be fixed in the future
            role: UserRoleService.getUserRole(user.id),
        };
    }
}
