<div
    class="create-card-actions"
    [ngClass]="{ 'slide-up': showThreadsMenu$ | async }"
>
    <div class="create-card-actions-controls">
        <div
            class="create-card-actions-controls-toggle create-card-actions-open"
            (click)="toggleContextMenu()"
        >
            <i
                [ngClass]="{ rotate90: showThreadsMenu$ | async }"
                class="las la-plus"
            ></i>
        </div>
    </div>

    <div class="create-card-menu">
        <ng-container *ngFor="let cardExtension of cardExtensions">
            <a
                *ngIf="
                    !cardExtension.extension.hideFromMenu &&
                    (cardExtension.extension.featureFlags | featureEnabled | async) &&
                    (cardExtension.extension.permission
                        ? (role | permissions : cardExtension.extension.permission | async)
                        : true)
                "
                (click)="
                    addCard(
                        cardExtension.extension.componentRef,
                        cardExtension.extension?.data,
                        cardExtension.extension?.config
                    )
                "
                [id]="'create-card-' + cardExtension.extension.title"
                class="create-card-menu-button"
                [analyticsClick]="cardExtension.extension.analyticsEvent"
                [analyticsLabel]="
                    role === roles.Client && cardExtension.extension.title === 'Meeting'
                        ? 'Meeting booked by Client'
                        : ''
                "
                [tuiHint]="cardExtension.extension.tooltipMessage"
                tuiHintDirection="top"
            >
                <i
                    [ngClass]="cardExtension.extension.icon"
                    class="las la-2x"
                ></i>
                {{ cardExtension.extension.title }}
            </a>
        </ng-container>
    </div>
</div>
