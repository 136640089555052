import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { noAuthGuard } from "projects/portal-modules/src/lib/findex-auth/services/no-auth.guard";
import { OnboardingTimelineContainerComponent } from "src/solutions/sigma/components/onboarding-timeline-container/onboarding-timeline-container.component";
import { authGuard } from "../findex-auth";
import { VerifyEmailSuccessComponent } from "./components/verify-email-success/verify-email-success.component";
import { VerifyMobileWizardComponent } from "./components/verify-mobile-wizard/verify-mobile-wizard.component";

export const onboardingTrial = {
    path: "signup",
    header: "SignUp",
};

export const onboardingVerifyEmailSuccess = {
    path: "email-success",
    header: "Verified",
};

export const onboardingVerifyMobile = {
    path: "verify-mobile",
    header: "Verified",
};

export const onboardingVerifyMobileSuccess = {
    path: "mobile-success",
    header: "Verified",
};

const routes: Routes = [
    //User should not be logged in for these screens
    {
        path: onboardingTrial.path,
        component: OnboardingTimelineContainerComponent,
        canActivate: [noAuthGuard],
    },
    { path: onboardingVerifyEmailSuccess.path, component: VerifyEmailSuccessComponent, canActivate: [noAuthGuard] },
    //User should be logged in for these screens
    { path: onboardingVerifyMobile.path, component: VerifyMobileWizardComponent, canActivate: [authGuard] },
    { path: "", redirectTo: onboardingTrial.path, pathMatch: "full" },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OnboardingRoutingModule {}
