import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequestForm } from "../interfaces/IRequestForms";

@Component({
    selector: "edit-request-title",
    templateUrl: "./edit-request-title.component.html",
    styleUrls: ["./edit-request-title.component.scss"],
})
export class EditRequestTitleComponent {
    @Input() form: FormGroup<IRequestForm>;

    readonly title = "Request title";
    readonly errorMessage = "Request title is required.";
}
