import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Notification } from "@visoryplatform/notifications-core";
import { NotificationsService } from "projects/notifications-frontend/src/services/notifications.service";

@Component({
    selector: "list-notification-banners",
    templateUrl: "./list-notification-banners.component.html",
    styleUrls: ["./list-notification-banners.component.scss"],
})
export class ListNotificationBannersComponent implements OnDestroy {
    @Input() notifications: Notification<any>[];
    @Output() bannerDisplayed = new EventEmitter<Notification<any>>();
    @Output() bannerHidden = new EventEmitter<Notification<any>>();

    constructor(private notificationsService: NotificationsService) {}

    close(notification: Notification) {
        this.notificationsService.markAsRead(notification.channel);
        this.bannerHidden.emit(notification);
    }

    trackNotification(_index: number, notification: Notification) {
        return notification?.id;
    }

    ngOnDestroy(): void {
        this.bannerHidden.emit();
    }
}
