import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { IThread } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ENVIRONMENT } from "src/app/injection-token";
import { CalendarInstance, CalendarState } from "../../calendar-state.type";
import { CalendarService, ISlot } from "../../services/calendar.service";
import {
    MultiComponentLayoutComponent,
    IStepConfiguration,
    ButtonType,
    MultiComponentService,
} from "projects/portal-modules/src/lib/ui-layouts";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

export type CalendarInstanceData = {
    invitationId: string;
    state: CalendarState;
    thread: IThread;
};

@Component({
    selector: "calendar-instance-modal",
    templateUrl: "./calendar-instance-modal.component.html",
    styleUrls: ["./calendar-instance-modal.component.scss"],
})
export class CalendarInstanceModalComponent implements OnInit {
    readonly CALENDAR_MAX_TIME_AHEAD: number = this.environment.featureFlags.calendarBookingLimits.maxTimeAheadMs;

    slots: ISlot[];
    minDate: string;
    maxDate: string;
    duration: number;
    selectedSlot: ISlot;
    editInstance?: CalendarInstance;

    @ViewChild(MultiComponentLayoutComponent) multiComponentLayoutComponent: MultiComponentLayoutComponent;
    loader = new Loader();
    activeStepIndex = 0;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Close",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false,
                },
            ],
        },
        {
            stepIndex: 1,
            buttons: [
                {
                    title: "Finish",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false,
                },
            ],
        },
    ];

    data: CalendarInstanceData;
    private dialogRef: DialogRef;

    constructor(
        private calendarService: CalendarService,
        private multiComponentService: MultiComponentService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialogService: DialogService,
        private injector: Injector,
    ) {
        this.maxDate = new Date(Date.now() + this.CALENDAR_MAX_TIME_AHEAD).toISOString();
        this.minDate = new Date().toISOString();
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.data = await this.dialogService.getData<CalendarInstanceData>(this.injector).toPromise();
    }

    async submit() {
        if (!this.editInstance) {
            return this.dialogRef.close();
        }

        if (!this.editInstance.reference) {
            alert("Meeting does not support this feature. Please try on a newer meeting");
            return;
        }

        this.loader.show();

        const instance = {
            reference: this.editInstance.reference,
            start: this.selectedSlot.start,
            end: this.selectedSlot.end,
        };

        await this.calendarService.updateInstance(this.data.invitationId, instance).toPromise();

        this.loader.hide();
        this.dialogRef.close();
    }

    async cancelTime(instance: CalendarInstance) {
        this.loader.show();

        await this.calendarService.cancelInstance(this.data.invitationId, instance).toPromise();

        this.loader.hide();
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

    async monthChange(startDate: string | Date) {
        this.loader.show();

        const startFrom = new Date(startDate).toISOString();
        const staffIds = this.data.state?.attendees?.staff?.map((staff) => staff.id) || [];

        this.slots = [];
        const slots = await this.calendarService
            .checkUserAvailability(staffIds, startFrom, this.duration, this.data.thread.id)
            .toPromise();
        this.slots = slots?.slots || [];
        this.loader.hide();
    }

    async selectTime(instance: CalendarInstance) {
        this.loader.show();
        this.editInstance = instance;
        this.duration = DateTime.fromISO(this.editInstance.end).diff(
            DateTime.fromISO(this.editInstance.start),
            "minutes",
        ).minutes;
        await this.monthChange(this.minDate);
        this.loader.hide();
        this.multiComponentLayoutComponent.selectNextStep();
    }

    async handleTimeSelection(event: ISlot) {
        this.selectedSlot = event;
        this.stepConfigurations = [
            ...this.multiComponentService.toggleForwardButtons(this.activeStepIndex, this.stepConfigurations, true),
        ];
    }
}
