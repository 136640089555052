export enum AssignedTo {
    You = "you",
    Others = "others",
}

export enum DashboardTimelinesTableHeaders {
    Service = "Service",
    Task = "Task",
    DueDate = "Due date",
    Activity = "Activity",
    Workflow = "Workflow",
    Assignee = "Assignee",
    SlaRemaining = "Time left",
}

export enum DashboardTimelinesEmptyState {
    AssignedToYou = "<p>There are no workflows currently assigned to you</p>",
    AssignedToOthers = "<p>There are no workflows currently assigned to others</p>",
}
