import { CenteredModalLayoutComponent } from "./components/centered-modal-layout/centered-modal-layout.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { StepperLayoutComponent } from "./components/stepper-layout/stepper-layout.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { SidebarModalLayoutComponent } from "./components/sidebar-modal-layout/sidebar-modal-layout.component";
import { StepperBreadcrumbsComponent } from "./components/stepper-breadcrumbs/stepper-breadcrumbs.component";
import { StepperFooterComponent } from "./components/stepper-footer/stepper-footer.component";

import { MultiComponentLayoutComponent } from "./components/deprecated/multi-component-layout/multi-component-layout.component";
import { StepLayoutComponent } from "./components/deprecated/multi-component-layout/step-layout/step-layout.component";
import { MultiComponentService } from "./components/deprecated/multi-component-layout/multi-component.service";
import { FindexUiModule } from "../findex-ui";
import { AnalyticsModule } from "../analytics";
import { StepperStepComponent } from "./components/step/stepper-step.component";

@NgModule({
    imports: [CommonModule, MatDialogModule, AnalyticsModule, FindexUiModule, UiComponentsModule, CdkStepperModule],
    declarations: [
        MultiComponentLayoutComponent,
        StepLayoutComponent,
        CenteredModalLayoutComponent,
        StepperLayoutComponent,
        StepperBreadcrumbsComponent,
        SidebarModalLayoutComponent,
        StepperFooterComponent,
        StepperStepComponent,
    ],
    exports: [
        MultiComponentLayoutComponent,
        StepLayoutComponent,
        CenteredModalLayoutComponent,
        StepperLayoutComponent,
        SidebarModalLayoutComponent,
        StepperStepComponent,
        CdkStepperModule,
    ],
    providers: [MultiComponentService],
})
export class UiLayoutsModule {}
