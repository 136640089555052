import { TaskAction, TaskActionFn } from "../../../projects/portal-modules/src/lib/plugins/services/Libraries";
import { GA_EVENTS } from "../../../projects/portal-modules/src/lib/analytics";
import { CardResources } from "../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { CardTaskActionLabel, EditMessageCardOptions } from "@visoryplatform/threads";
import { ActionPayrunRequestComponent } from "./components/request/action-payrun-request/action-payrun-request.component";
import { ActionRfiRequestComponent } from "./components/request/action-rfi-request/action-rfi-request.component";
import { EditRfiRequestComponent } from "./components/request/edit-rfi-request/edit-rfi-request.component";
import { EditPayrunRequestComponent } from "./components/request/edit-payrun-request/edit-payrun-request.component";
import { ISigningModalData, SigningModalComponent } from "./components/signing-modal/signing-modal.component";
import { take } from "rxjs/operators";
import { EditMessageModalComponent } from "./components/edit-message-modal/edit-message-modal.component";
import { InstructionsActionComponent } from "../instructions/components/instructions-action/instructions-action.component";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

type VaultRequestOptions = { focusReplyInput: boolean };

function rfiRequestActionFactory(isEditView: boolean, readonly: boolean): TaskActionFn<VaultRequestOptions, boolean> {
    return async (cardResources: Partial<CardResources>, injector: Injector, options?: VaultRequestOptions) => {
        const dialogService = injector.get(DialogService);
        const { thread$, card$, state$, role, replies$ } = cardResources;
        const focusReplyInput = options?.focusReplyInput || false;
        const data = { state$, thread$, replies$, card$, role, readonly, focusReplyInput };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
            width: "761px",
        };

        const component = isEditView ? EditRfiRequestComponent : ActionRfiRequestComponent;
        return dialogService.open<boolean>(component, config).toPromise();
    };
}

function instructionsRequestActionFactory(): TaskActionFn<VaultRequestOptions, boolean> {
    return async (cardResources: Partial<CardResources>, injector: Injector, options: any) => {
        const dialogService = injector.get(DialogService);
        const { thread$, card$, state$, role, replies$ } = cardResources;
        const data = { ...options, state$, thread$, replies$, card$, role, readonly: false, focusReplyInput: false };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
            width: "761px",
            autoFocus: false,
        };

        const component = InstructionsActionComponent;
        return dialogService.open<boolean>(component, config).toPromise();
    };
}

function payrunRequestActionFactory(
    isEditView: boolean,
    readonly: boolean,
): TaskActionFn<VaultRequestOptions, boolean> {
    return async (cardResources: Partial<CardResources>, injector: Injector, options?: VaultRequestOptions) => {
        const dialogService = injector.get(DialogService);
        const { thread$, card$, state$, role, replies$ } = cardResources;
        const focusReplyInput = options?.focusReplyInput || false;
        const data = { state$, thread$, replies$, card$, role, readonly, focusReplyInput };

        const config = {
            data,
            panelClass: ["centered-modal"],
            disableClose: true,
            width: "1200px",
        };

        const component = isEditView ? EditPayrunRequestComponent : ActionPayrunRequestComponent;
        return dialogService.open<boolean>(component, config).toPromise();
    };
}

export const provideInfoVaultRequestTaskAction: TaskAction<boolean | void> = {
    analyticsEvents: [GA_EVENTS.APP_PROVIDEDOCUMENTS],
    cardTypes: ["vault-request"],
    action: rfiRequestActionFactory(false, false),
    buttonLabel: CardTaskActionLabel.RequestResponse,
    statusIcon: "las la-check-square",
};

export const viewInstructionsRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFI_CARD_VIEW],
    cardTypes: ["vault-request-instructions"],
    action: instructionsRequestActionFactory(),
    buttonLabel: CardTaskActionLabel.ViewDetails,
    statusIcon: "las la-check-square",
};

export const viewVaultRequestTaskAction: TaskAction<boolean | void> = {
    analyticsEvents: [GA_EVENTS.RFI_CARD_VIEW],
    cardTypes: ["vault-request"],
    action: rfiRequestActionFactory(false, true),
    buttonLabel: CardTaskActionLabel.ViewResponse,
    statusIcon: "las la-check-square",
};

export const editVaultRequestTaskAction: TaskAction<boolean | void> = {
    analyticsEvents: [GA_EVENTS.RFI_CARD_EDIT],
    cardTypes: ["vault-request"],
    action: rfiRequestActionFactory(true, true),
    buttonLabel: CardTaskActionLabel.EditResponse,
    statusIcon: "las la-check-square",
};

export const provideInfoVaultPayrunApprovalRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.APP_PROVIDEDOCUMENTS],
    cardTypes: ["vault-request-approval-payrun"],
    action: payrunRequestActionFactory(false, false),
    buttonLabel: CardTaskActionLabel.PayrunApprovalRequestResponse,
    statusIcon: "las la-check-square",
};

export const viewVaultVaultPayrunApprovalRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFI_CARD_VIEW],
    cardTypes: ["vault-request-approval-payrun"],
    action: payrunRequestActionFactory(false, true),
    buttonLabel: CardTaskActionLabel.PayrunApprovalEditResponse,
    statusIcon: "las la-check-square",
};

export const editVaultVaultPayrunApprovalRequestTaskAction: TaskAction<boolean> = {
    analyticsEvents: [GA_EVENTS.RFI_CARD_EDIT],
    cardTypes: ["vault-request-approval-payrun"],
    action: payrunRequestActionFactory(true, true),
    buttonLabel: CardTaskActionLabel.PayrunApprovalViewResponse,
    statusIcon: "las la-check-square",
};

export const signDocumentsTaskAction: TaskAction<boolean> = {
    cardTypes: ["vault"],
    action: async ({ threadId, cardId }, injector) => {
        const router = injector.get(Router);
        return await router.navigate(["timelines", threadId, "cards", cardId]);
    },
    buttonLabel: CardTaskActionLabel.SignFiles,
    statusIcon: "las la-user-edit",
};

export const signSingleDocumentTaskAction: TaskAction<boolean> = {
    cardTypes: ["vault"],
    action: async (cardResources: Partial<CardResources>, injector: Injector) => {
        const dialogService = injector.get(DialogService);
        const state = await cardResources.state$.pipe(take(1)).toPromise();
        const group = state.groups.find((group) => !!group);

        if (group) {
            const item = group.items.find((item) => !!item);
            const signingModalData: ISigningModalData = { vaultItem: item };

            const config = {
                data: signingModalData,
                position: { top: "0px" },
                height: "100vh",
                maxWidth: "100vw",
                panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
            };

            const component = SigningModalComponent;
            return dialogService.open<boolean>(component, config).toPromise();
        } else {
            console.warn("No files found in group");
            return false;
        }
    },
    buttonLabel: CardTaskActionLabel.SignFiles,
    statusIcon: "las la-user-edit",
};

export const editVaultAction: TaskAction<boolean> = {
    cardTypes: ["vault"],
    action: async (cardResources: Partial<CardResources>, injector: Injector, options: EditMessageCardOptions) => {
        const dialogService = injector.get(DialogService);
        const { thread$, card$, state$, role } = cardResources;
        const data = { state$, thread$, card$, role, ...options };
        const config = {
            data,
            position: { top: "0px" },
            height: "100vh",
            maxWidth: "100vw",
            panelClass: ["mat-dialog-no-styling", "threads-sidebar"],
        };

        return dialogService.open<boolean>(EditMessageModalComponent, config).toPromise();
    },
    buttonLabel: "Edit",
    statusIcon: "las la-user-edit",
};
