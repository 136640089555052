<div class="fx-onboarding-back">
    <button class="fx-onboarding-back-button" (click)="closeDialog()">
        <i class="fx-onboarding-back-icon las la-times"></i>
    </button>
</div>
<loader-icon *ngIf="showLoader" color="light"></loader-icon>
<div class="fx-onboarding" *ngIf="!showLoader">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1 class="fx-onboarding-title">Set password</h1>
            <p class="fx-onboarding-body">
                Use this form to set the password for a user. Please ensure that you have sufficiently verified the
                identity of this user before doing so.
            </p>
            <form class="fx-onboarding-form fx-form fx-form--dark" [formGroup]="form" (submit)="updatePassword()">
                <div class="fx-form-group" *ngIf="errorMessage">
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="newPassword">New password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="newPassword"
                            class="fx-form-input"
                            type="password"
                            placeholder="New password"
                            aria-label="newPassword"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                (form.getError('matchingFields') === false || !form.controls['newPassword'].valid) && form.controls['newPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="(form.getError('matchingFields') === false || !form.controls['newPassword'].valid) && form.controls['newPassword'].touched"
                    >
                        {{ getPasswordError("New password", form.getError('matchingFields')) }}
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label class="fx-form-label" for="confirmPassword">Confirm password</label>
                    <div class="fx-form-control">
                        <input
                            formControlName="confirmPassword"
                            class="fx-form-input"
                            type="password"
                            placeholder="Confirm password"
                            aria-label="confirmPassword"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                (form.getError('matchingFields') === false || !form.controls['confirmPassword'].valid) && form.controls['confirmPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="(form.getError('matchingFields') === false || !form.controls['confirmPassword'].valid) && form.controls['confirmPassword'].touched"
                    >
                        {{ getPasswordError("Confirm password", form.getError('matchingFields')) }}
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <button class="fx-btn--primary-outlined fx-btn--full-width" [disabled]="!form.valid" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
