import { Component, Input, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

export enum EmptyStateImageType {
    NotFound = "./assets/images/empty-states/no-files-found.svg",
    UpToDate = "./assets/images/empty-states/workflow-up-to-date.svg",
    NoMeetings = "./assets/images/empty-states/no-meetings.svg",
    NoMemories = "./assets/images/empty-states/no-memories.svg",
    NoRecordings = "./assets/images/empty-states/no-recordings.svg",
    NoRequests = "./assets/images/empty-states/no-requests.svg",
    NoDocuments = "./assets/images/empty-states/no-documents.svg",
}

@Component({
    selector: "empty-state",
    templateUrl: "./empty-state.component.html",
    styleUrls: ["./empty-state.component.scss"],
})
export class EmptyStateComponent implements OnInit {
    @Input() title: string;
    @Input() htmlText: string;
    @Input() imageType: EmptyStateImageType;

    sanitizedHtmlContent: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlText);
    }
}
