<div
    *ngIf="globalSearchEnabled$ | async"
    [formGroup]="form"
>
    <a
        class="global-search-mobile"
        [ngClass]="{ 'global-search-mobile-active': isSearchRouteActive }"
        [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH"
        (click)="navigateToSearchPage()"
    >
        <i class="las la-search"></i>
    </a>

    <div class="global-search-container">
        <search
            #searchComponent
            [analyticsClick]="gaEvents.APP_GLOBAL_SEARCH"
            formControlName="search"
            placeholder="Search keywords or phrase"
            (keypress)="onKeyPress($event)"
            [debounceLimit]="DEBOUNCE_LIMIT"
            [characterLimit]="SEARCH_CHARACTER_LIMIT"
        ></search>
    </div>
</div>
