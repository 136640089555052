import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { Router } from "@angular/router";
import { ITimeline } from "@visoryplatform/threads";

@Component({
    selector: "create-success",
    templateUrl: "./create-success.component.html",
    styleUrls: ["./create-success.component.scss"],
})
export class CreateSuccessComponent {
    @Input() thread: ITimeline;

    @Output() close = new EventEmitter<void>();
    @Output() goToCreate = new EventEmitter<void>();

    readonly gaEvents = GA_EVENTS;

    constructor(private router: Router) {}

    handleCreate(): void {
        this.goToCreate.emit();
    }

    async handleView(): Promise<void> {
        this.handleClose();
        await this.router.navigate(["/workflows", this.thread.id]);
    }

    async goToWorkflowsPage(): Promise<void> {
        this.handleClose();
        await this.router.navigate(["/workflows"]);
    }

    handleClose(): void {
        this.close.emit();
    }
}
