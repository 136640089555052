import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IStep } from "@visoryplatform/workflow-core";

export function dueDateValidator(dueDateSteps: IStep[]): ValidatorFn {
    return (rhythmDatesControl: AbstractControl): ValidationErrors | null => {
        const rhythmDates = rhythmDatesControl.value;
        if (!rhythmDates) {
            return null;
        }

        const inOrder = dueDateSteps.every((step, i, allSteps) => {
            if (i === 0) {
                return true;
            }

            const currentDueDate = rhythmDates[step.id]?.dateTime;
            const previousDueDate = rhythmDates[allSteps[i - 1].id]?.dateTime;

            return currentDueDate >= previousDueDate;
        });

        if (inOrder) {
            return null;
        } else {
            return { datesOutOfOrder: { value: "Dates need to be in order" } };
        }
    };
}
