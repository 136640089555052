import { Injectable } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Observable, of } from "rxjs";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { filter, switchMap } from "rxjs/operators";
import { Account } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { IntegrationsSettingsComponent } from "./components/integrations-settings/integrations-settings.component";

@Injectable()
export class IntegrationsPlugin implements IPluginFactory {
    readonly id = "IntegrationsPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(libraries: Libraries, private authService: AuthService, private permissionService: PermissionService) {
        const integrationsSettings: RouteExtension<Account> = {
            label: "Integrations",
            showExtension: (account) => this.showExtension(account, true),
            route: {
                path: "integrations",
                component: IntegrationsSettingsComponent,
            },
        };

        libraries.accountRoutes.register("integrations", integrationsSettings);
    }

    private showExtension(account: Account, featureFlag: boolean): Observable<boolean> {
        if (!featureFlag || !account) {
            return of(false);
        }

        return this.authService.getUser().pipe(
            filter((user: AppUser) => !!user),
            switchMap((user) => this.userHasPermission(user)),
        );
    }

    private userHasPermission(user: AppUser): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, "ReadPayroll");
    }
}
