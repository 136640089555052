<loader-icon
    *ngIf="loader.counter"
    [static]="true"
    color="light"
></loader-icon>

<ng-container *ngIf="threads$ | async; let threads">
    <ng-container *ngIf="paginator.sort$ | async as sort">
        <div *ngIf="!loader.counter">
            <timelines-table
                [threads]="threads || []"
                [globalRole]="role$ | async"
                [userId]="userId$ | async"
                [sortActive]="sort.sort"
                [sortDirection]="sort.order"
                [hideAccounts]="hideAccounts"
                (sortByClick)="paginator.sort($event)"
            >
            </timelines-table>
            <div class="fx-dashboard-threads-paginator">
                <fx-paginator
                    [enableBack]="paginator.canGoBack$ | async"
                    [enableNext]="paginator.canGoNext$ | async"
                    (goNext)="paginator.goNext()"
                    (goBack)="paginator.goBack()"
                ></fx-paginator>
            </div>
        </div>
    </ng-container>
</ng-container>
