<div class="message-preview">
    <div class="message-preview-header">
        <div class="message-preview-header-avatar">
            <fx-avatar
                class="system-avatar"
                [border]="false"
                *ngIf="name | isSystemName"
            ></fx-avatar>
            <fx-avatar
                [name]="name"
                [image]="image"
                [border]="false"
                *ngIf="!(name | isSystemName) && (image || name)"
            ></fx-avatar>
            <div class="message-preview-header-avatar-name">
                {{ name }}
            </div>
        </div>
        <div class="message-preview-header-timestamp">
            {{ timestamp | date : "dd/MM/yy, h:mm a" }}
        </div>
    </div>
    <div class="message-preview-content">
        <read-more [showFullDescription]="false">
            <quill-view
                [style]="quillStyles"
                [content]="message | allowWhitelistLinks"
                aria-label="message"
            >
            </quill-view>
        </read-more>
    </div>
</div>
