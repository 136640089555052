import { IntegrationTypes } from "@visoryplatform/threads";
import { Integration } from "../types/Integration";

export const integrations: Integration[] = [
    {
        name: "Employment Hero",
        issuerType: IntegrationTypes.EmploymentHero,
        scopes: ["offline_access", "openid", "profile", "email"],
        logoUrl: "/assets/images/integrations/employment-hero-logo.svg",
    },
    {
        name: "Xero",
        issuerType: IntegrationTypes.Xero,
        scopes: [
            "offline_access",
            "openid",
            "profile",
            "accounting.reports.read",
            "accounting.transactions.read",
            "accounting.settings.read",
            "accounting.attachments.read",
            "accounting.contacts.read",
            "accounting.attachments.read",
            "accounting.reports.bankstatement.read",
            "payroll.payruns.read",
            "payroll.payslip.read",
            "payroll.timesheets.read",
            "payroll.employees.read",
            "payroll.settings.read",
        ],
        logoUrl: "/assets/images/integrations/xero.png",
    },
];

export const paramsToConnect = ["iss", "aud", "sub", "issuerType"];
