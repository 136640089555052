import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "app-thread-search",
    templateUrl: "./thread-search.component.html",
    styleUrls: ["./thread-search.component.scss"],
})
export class ThreadSearchComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isMobileView = true;
    @Input() analyticsClickEvent: string;
    @Input() placeholder = "Search";
    @Input() value: string;
    @Input() expandSearch = false;
    @Input() hideCloseButton = false;

    @Output() search = new EventEmitter<string>();
    @Output() active = new EventEmitter<boolean>();

    @HostBinding("class.active") activeClass: boolean;
    @ViewChild("search", { static: false }) inputElement: ElementRef;

    searchSubscription$: Subscription;
    formControl = new FormControl<string>("");
    formGroup = new FormGroup({
        search: this.formControl,
    });

    ngOnInit(): void {
        this.searchSubscription$ = this.formControl.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe((term) => {
                this.search.emit(term);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.formControl.setValue(changes.value.currentValue, { emitEvent: false });
        }
    }

    updateSearchTerm(searchTerm: string): void {
        this.formControl.setValue(searchTerm);
    }

    setActive(active: boolean): void {
        if (!this.isMobileView) {
            return;
        }

        if (active) {
            setTimeout(() => this.inputElement.nativeElement.focus());
        }

        this.expandSearch = active;
        this.activeClass = active;
        this.active.emit(active);
    }

    closeSearch(): void {
        this.setActive(false);
        this.formControl.setValue("");
    }

    ngOnDestroy(): void {
        this.closeSearch();
        this.searchSubscription$?.unsubscribe();
    }
}
