<ng-container *ngIf="loading; else layout">
    <loader-icon color="light"></loader-icon>
</ng-container>
<ng-template #layout>
    <ng-template #unavailable>
        <div class="unavailable-action-container">
            <div class="unavailable-action">
                <i class="unavailable-action-icon las la-exclamation-circle"></i>
                <h2>Sign in link unavailable</h2>
                <p>
                    The link provided to you has either expired or has become unavailable. Please contact us if you
                    require a new link.
                </p>
                <a
                    class="unavailable-action-button fx-btn fx-btn--primary"
                    role="button"
                    routerLink="/"
                    >BACK HOME</a
                >
                <a href="mailto:{{ supportEmail }}">Contact us</a>
            </div>
        </div>
    </ng-template>

    <multi-component-layout
        *ngIf="!expiredAction; else unavailable"
        [stepConfigurations]="stepComponents"
        [fullscreen]="true"
        [enableFooter]="false"
        [enableHeader]="true"
        [enableBreadCrumb]="false"
        [enableProgressBar]="true"
        [activeStepIndex]="activeStepIndex"
    >
        <div
            class="multi-component-layout-header"
            *ngIf="headings?.header"
        >
            <div class="focus-action-wizard-header-container">
                <h2 class="focus-action-wizard-header">{{ headings.header }}</h2>
                <p
                    *ngIf="headings.subheader"
                    class="focus-action-wizard-subheader"
                >
                    {{ headings.subheader }}
                </p>
            </div>
        </div>
        <step-layout *ngFor="let stepComponent of stepComponents">
            <ng-container [ngSwitch]="stepComponent.type">
                <ng-container *ngSwitchCase="'verify'">
                    <app-verify-mobile-form
                        [mobileNumber]="mobileNumber"
                        [codeSent]="false"
                        [isInvitation]="isInvitation"
                        (completed)="goForward($event)"
                        (back)="goBack($event)"
                    >
                    </app-verify-mobile-form>
                </ng-container>
                <ng-container *ngSwitchCase="'dummy'"> </ng-container>
                <ng-container *ngSwitchDefault>
                    <view-extension
                        [componentRef]="stepComponent.component"
                        [injector]="stepComponent.injector"
                    ></view-extension>
                </ng-container>
            </ng-container>
        </step-layout>
    </multi-component-layout>
</ng-template>
