import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AnalyticsModule } from "../analytics";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { SearchComponent } from "./components/search/search.component";

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, AnalyticsModule, PortalUiModule],
    declarations: [SearchComponent],
    exports: [SearchComponent],
    providers: [],
})
export class SearchModule {}
