<centered-modal-layout
    [loading]="!!modalLoader.counter"
    saveButtonTitle="Continue"
    [analyticsPrefix]="gaEventsPrefix.REQUEST_MODAL"
    [disableSave]="
        !currentActionSelection ||
        (currentActionSelection.templates && !currentTemplateSelection) ||
        (currentActionSelection.type === REQUEST_TYPES.PayrunRequest && !selectedPayrollReport.value)
    "
    (save)="openCreateRequestModal()"
    (close)="close()"
>
    <div modal-header>New request</div>
    <div modal-subhead>Create a request from scratch or prefilled data.</div>
    <div modal-content>
        <div class="pre-create-request-menu-options">
            <ng-container *ngFor="let menuItem of menuOptions">
                <!-- Blank and template -->
                <pre-create-request-item
                    *ngIf="
                        menuItem?.title !== requestTitles.CreateBankFile &&
                        menuItem?.title !== requestTitles.PayrollReportTitle
                    "
                    [checked]="currentActionSelection === menuItem"
                    [menuItem]="menuItem"
                    (update)="selectionActionType($event)"
                >
                    <div
                        class="pre-create-request-menu-options-dropdown"
                        *ngIf="menuItem.templates?.length && currentActionSelection === menuItem"
                    >
                        <fx-select
                            (change)="templateSelection($event)"
                            placeholder="Select template"
                            analyticsClick="{{ gaEventsPrefix.RFI }}_dropdown"
                        >
                            <fx-option
                                *ngFor="let template of menuItem.templates; trackBy: 'title' | trackProperty"
                                [value]="template"
                                [selected]="currentTemplateSelection?.id === template.id"
                                analyticsClick="{{ gaEvents.RFI_CREATE_REQUEST_TEMPLATE }}_{{ template.title }}"
                            >
                                {{ template.title }}
                            </fx-option>
                        </fx-select>
                    </div>
                </pre-create-request-item>

                <!-- Payrun report -->
                <pre-create-request-item
                    *ngIf="menuItem?.title === requestTitles.PayrollReportTitle"
                    [checked]="currentActionSelection === menuItem"
                    [menuItem]="menuItem"
                    [disabled]="!isIntegrationSetup || isPayrollAutomated"
                    [disabledMessage]="[
                        {
                            message: integrationStepError,
                            showError: !isIntegrationSetup
                        },
                        {
                            message: payrollAutomationsEnabledError,
                            showError: isPayrollAutomated
                        }
                    ]"
                    (update)="selectionActionType($event)"
                >
                    <loader-icon
                        *ngIf="loader.counter"
                        color="light"
                    ></loader-icon>
                    <div class="pre-create-request-menu-options-dropdown">
                        <fx-select
                            *ngIf="reports.length"
                            [formControl]="selectedPayrollReport"
                            placeholder="Select template"
                            analyticsClick="{{ gaEventsPrefix.PAYRUN }}_dropdown"
                        >
                            <fx-option
                                *ngFor="let report of reports; trackBy: 'id' | trackProperty"
                                [value]="report"
                                analyticsClick="{{ gaEvents.PAYRUN_REPORT_SELECTED }}_{{ report.label }}"
                            >
                                {{ report.label }}
                            </fx-option>
                        </fx-select>
                    </div>
                </pre-create-request-item>

                <!-- Bank File -->
                <pre-create-request-item
                    *ngIf="menuItem?.title === requestTitles.CreateBankFile"
                    [checked]="currentActionSelection === menuItem"
                    [menuItem]="menuItem"
                    [disabled]="
                        accountMetadataBankFileSetting !== BANK_FILE_SETTINGS.CustomerUploadsAba ||
                        !!bankFileListError ||
                        !isIntegrationSetup
                    "
                    [disabledMessage]="[
                        {
                            message: integrationStepError,
                            showError: !isIntegrationSetup
                        },
                        {
                            message: bankFileSettingsError,
                            showError:
                                !!accountMetadataBankFileSetting &&
                                accountMetadataBankFileSetting !== BANK_FILE_SETTINGS.CustomerUploadsAba
                        },
                        {
                            message: bankFileSettingsError,
                            showError: !accountMetadataBankFileSetting
                        },
                        {
                            message: bankFileListError,
                            showError: !!bankFileListError
                        }
                    ]"
                    (update)="selectionActionType($event)"
                >
                    <loader-icon
                        *ngIf="loader.counter"
                        color="light"
                    ></loader-icon>
                    <div class="pre-create-request-menu-options-dropdown">
                        <fx-select
                            *ngIf="bankFileSettings?.length"
                            [formControl]="selectedBankFileSettings"
                            placeholder="Select settings"
                            analyticsClick="{{ gaEventsPrefix.BANKFILE }}_dropdown"
                        >
                            <fx-option
                                *ngFor="let report of bankFileSettings; trackBy: 'id' | trackProperty"
                                [value]="report"
                                analyticsClick="{{ gaEvents.BANKFILE_SETTINGS_SELECTED }}_{{ report.accountName }}"
                            >
                                {{ report.accountName }} - Account: {{ report.accountNumber }}
                            </fx-option>
                        </fx-select>
                    </div>

                    <ng-container *ngIf="bankFileSettings.length && reports.length">
                        <div class="pre-create-request-menu-options-label">Select report:</div>
                        <div class="pre-create-request-menu-options-dropdown">
                            <fx-select
                                [formControl]="selectedPayrollReport"
                                placeholder="Select template"
                                analyticsClick="{{ gaEventsPrefix.PAYRUN }}_dropdown"
                            >
                                <fx-option
                                    *ngFor="let report of reports; trackBy: 'id' | trackProperty"
                                    [value]="report"
                                    analyticsClick="{{ gaEvents.PAYRUN_REPORT_SELECTED }}_{{ report.label }}"
                                >
                                    {{ report.label }}
                                </fx-option>
                            </fx-select>
                        </div>
                    </ng-container>
                </pre-create-request-item>
            </ng-container>
        </div>
    </div>
</centered-modal-layout>
