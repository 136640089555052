import { Observable } from "rxjs";

export type ExtensionEntry<ExtensionType> = {
    id: string;
    extension: ExtensionType;
};

export interface ILibrary<ExtensionType> {
    extensionAdded: Observable<ExtensionEntry<ExtensionType>>;
    register(id: string, extension: ExtensionType): void;
    resolve(id: string): ExtensionType;
    listAll(): ExtensionEntry<ExtensionType>[];
}
