import { CardTaskActionLabel, ICardSubject, SubjectType } from "@visoryplatform/threads";
import { PaymentModalComponent } from "./components/payment-modal/payment-modal.component";
import { TaskAction } from "../../../projects/portal-modules/src/lib/plugins/services/Libraries";
import { GA_EVENTS } from "../../../projects/portal-modules/src/lib/analytics";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { map, take } from "rxjs/operators";
import { Injector } from "@angular/core";
import { Observable } from "rxjs";
import { IPaymentCardState } from "./interfaces/IPaymentCardState";
import { PaymentTypes } from "@visoryplatform/payments-service-sdk";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { IPackagePriceDetails } from "./interfaces/IPackagePriceDetails";

export const convertAmountToPriceString = (amount: string) => {
    if (amount) {
        const cents = parseInt(amount, 10);

        if (!isNaN(cents)) {
            const [priceDollars, priceCents] = (cents / 100).toFixed(2).split(".");
            return { priceDollars, priceCents };
        }
    }

    return {
        priceDollars: "0",
        priceCents: "0",
    };
};

export const buildPackagePriceDetails = (state$: Observable<IPaymentCardState>): Observable<IPackagePriceDetails> =>
    state$.pipe(map((state) => convertAmountToPriceString(state?.amount)));

const action = async ({ thread$, card$, state$ }: Partial<CardResources>, injector: Injector) => {
    const dialogService = injector.get(DialogService);

    const card = await card$.pipe(take(1)).toPromise();
    const thread = await thread$.pipe(take(1)).toPromise();
    const state = await state$.pipe(take(1)).toPromise();
    const packagePriceDetails = await buildPackagePriceDetails(state$).pipe(take(1)).toPromise();
    const accountId = thread.accountId;

    const paymentSubject = findSubject(state, card.subjects);
    const config = {
        panelClass: ["centre-solid-background-modal-timeline"],
        disableClose: true,
        closeOnNavigation: false,
        hasBackdrop: true,
        data: {
            threadId: thread.id,
            cardId: card.id,
            description: card.description,
            paymentsSubjectId: paymentSubject.id,
            accountId,
            state,
            packagePriceDetails,
        },
    };

    return dialogService.open<void>(PaymentModalComponent, config).toPromise();
};

const paymentToSubject = {
    [PaymentTypes.OnceOffPayment]: SubjectType.Payment,
    [PaymentTypes.RecurringPayment]: SubjectType.Subscription,
    [PaymentTypes.ScheduledPayment]: SubjectType.SubscriptionSchedule,
};

function findSubject(state: IPaymentCardState, subjects: ICardSubject[]) {
    if (!state?.paymentType || !(state.paymentType in paymentToSubject)) {
        return null;
    }

    const subjectType = paymentToSubject[state.paymentType];
    return subjects.find((subject) => subject.type === subjectType);
}

export const addPaymentMethodPaymentTaskAction: TaskAction<void> = {
    analyticsEvents: [GA_EVENTS.PAYMENTCARD_ADDPAYMENTMETHOD],
    cardTypes: ["payments"],
    action,
    buttonLabel: CardTaskActionLabel.PaymentMethodRequired,
    statusIcon: "las la-credit-card",
};

export const payNowPaymentTaskAction: TaskAction<void> = {
    analyticsEvents: [GA_EVENTS.PAYMENTCARD_PAYNOW],
    cardTypes: ["payments"],
    action,
    buttonLabel: CardTaskActionLabel.PaymentRequired,
    statusIcon: "las la-credit-card",
};
