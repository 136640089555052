<div>
    <div class="fx-threads-breadcrumbs"></div>
    <div class="fx-threads-tabs">
        <fx-tabs [disabled]="true">
            <fx-tabs-item header="Workflow"></fx-tabs-item>
            <fx-tabs-item header="Requests"></fx-tabs-item>
            <fx-tabs-item header="Files"></fx-tabs-item>
        </fx-tabs>
        <button
            class="thread-participants-toggle"
            [disabled]="true"
        >
            <i class="las la-user-plus"></i> <span class="badge">0</span>
        </button>
    </div>
</div>
<div class="tooltip-column">
    <div class="tooltip-panel">
        <p class="tooltip-header">Select a workflow</p>
        <p>It seems you have no workflow selected. Start by selecting a workflow</p>
    </div>
</div>
