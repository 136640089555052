import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { OpenMeasuresService } from "projects/default-plugins/insights/services/open-measures.service";
import { EnterpriseListing } from "@visoryplatform/openmeasures-core";
import { combineLatest, Observable, Subject } from "rxjs";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { Environment } from "projects/portal-modules/src/lib/environment/environment.common";
import { map, startWith } from "rxjs/operators";

export type AddEnterpriseDialogData = {
    userId: string;
    existingEnterpriseIds: string[];
};

@Component({
    selector: "add-enterprise-dialog",
    templateUrl: "./add-enterprise-dialog.component.html",
    styleUrls: ["./add-enterprise-dialog.component.scss"],
})
export class AddEnterpriseDialogComponent implements OnInit {
    enterprises$: Observable<EnterpriseListing[]>;

    isMobileView: boolean;
    loader = new Loader();
    gaEvents = GA_EVENTS;
    enterprisesControl = new UntypedFormControl([]);
    enterprisesFilter = new Subject<string>();
    form = new UntypedFormGroup({ enterprises: this.enterprisesControl });

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: AddEnterpriseDialogData,
        @Inject(ENVIRONMENT) public environment: Environment,
        private openMeasuresService: OpenMeasuresService,
        private dialogRef: MatDialogRef<AddEnterpriseDialogComponent>,
        private windowListenersService: WindowListenersService,
    ) {}

    ngOnInit() {
        const enterprises$ = this.loader.wrap(this.openMeasuresService.getAllEnterprises());
        const filter$ = this.enterprisesFilter.pipe(startWith(""));

        this.enterprisesControl.setValue(this.dialogData.existingEnterpriseIds);
        this.enterprises$ = combineLatest([enterprises$, filter$]).pipe(
            map(([enterprises, filter]) => this.filterEnterprises(enterprises, filter)),
        );

        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    private filterEnterprises(enterprises: EnterpriseListing[], term: string) {
        if (!term?.trim()) {
            return enterprises;
        }

        const lowerTerm = term.toLowerCase().trim();
        return enterprises.filter((enterprise) => enterprise.label.toLowerCase().includes(lowerTerm));
    }

    toggleEnterprise(enterpriseId: string): void {
        const enterpriseIds: Set<string> = new Set(this.enterprisesControl.value);

        if (enterpriseIds.has(enterpriseId)) {
            enterpriseIds.delete(enterpriseId);
        } else {
            enterpriseIds.add(enterpriseId);
        }

        this.enterprisesControl.setValue([...enterpriseIds]);
    }

    async save(userId: string, enterprises: string[]) {
        this.loader.show();
        await Promise.all(
            enterprises.map((enterpriseId) => this.openMeasuresService.addUser(enterpriseId, userId).toPromise()),
        );
        this.loader.hide();

        this.dialogRef.close();
    }

    updateSearchTerm(term: string) {
        this.enterprisesFilter.next(term);
    }

    close() {
        this.dialogRef.close();
    }
}
