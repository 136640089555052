<div class="quill-inline-editor-wrapper">
    <quill-editor-wrapper
        [mentionableUsers]="participants | participantsToMention | async"
        [formControl]="messageFormControl"
        [placeholder]="placeholder"
        [autoFocusOnInit]="true"
        [readOnly]="readOnly"
        (activeMentions)="onActiveMentions($event)"
        (error)="quillError = $event"
        [inline]="true"
        [threadType]="threadType"
    ></quill-editor-wrapper>

    <div class="card-edit-panel-buttons">
        <button
            class="fx-btn fx-btn--medium fx-btn--primary fx-btn--white"
            type="button"
            role="button"
            (click)="cancelEdit()"
        >
            Discard
        </button>
        <button
            class="fx-btn fx-btn--medium fx-btn--primary-outlined"
            type="button"
            role="button"
            [disabled]="!messageFormControl.dirty || submitDisabled || quillError"
            (click)="saveEdit()"
        >
            Save
        </button>
    </div>
</div>

<mentions-info-bar
    *ngIf="activeMentions"
    [inline]="true"
></mentions-info-bar>
