<div class="sidebar-modal">
    <div class="sidebar-modal-header">
        <div class="sidebar-modal-title">{{ header }}</div>
        <div class="sidebar-modal-header-close">
            <div
                class="fx-l-modal-header-icon"
                (click)="onClose()"
            >
                <i class="las la-times icon-size"></i>
            </div>
        </div>
    </div>

    <ng-content></ng-content>
</div>
