import { Injectable } from "@angular/core";
import { FeatureFlagService, ILaunchDarklyFeatureFlags, LaunchDarklyFeatureFlags } from "../../feature-flags";
import { Role } from "@visoryplatform/threads";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilterOption } from "../../timeline/interfaces/timeline-filters";

export interface RoleOption {
    key: string;
    value: string;
    flag?: LaunchDarklyFeatureFlags;
}

@Injectable({ providedIn: "root" })
export class UserRoleOptionsService {
    private readonly roleOptions: RoleOption[] = [
        {
            key: Role.Administrator,
            value: "Administrator",
        },
        {
            key: Role.TeamLead,
            value: "Team Lead",
        },
        {
            key: Role.Lead,
            value: "Lead",
            flag: LaunchDarklyFeatureFlags.EnableLeadRole,
        },
        {
            key: Role.SuccessManager,
            value: "Success Manager",
            flag: LaunchDarklyFeatureFlags.EnableSuccessManagerRole,
        },
        {
            key: Role.QualityTeam,
            value: "Quality Team",
            flag: LaunchDarklyFeatureFlags.EnableQualityTeamRole,
        },
        {
            key: Role.Staff,
            value: "Staff",
        },
        {
            key: Role.Expert,
            value: "Expert",
            flag: LaunchDarklyFeatureFlags.EnableExpertRole,
        },
        {
            key: Role.Partner,
            value: "Partner",
            flag: LaunchDarklyFeatureFlags.EnablePartnerRole,
        },
        {
            key: null,
            value: "No global role",
        },
    ];

    constructor(private featureFlagsService: FeatureFlagService) {}

    get(): Observable<FilterOption[]> {
        return this.featureFlagsService.getFlags().pipe(map((flags) => this.filterOptions(this.roleOptions, flags)));
    }

    private filterOptions(options: RoleOption[], flags: ILaunchDarklyFeatureFlags): FilterOption[] {
        return options.filter((option) => !option.flag || flags[option.flag]).map(({ key, value }) => ({ key, value }));
    }
}
