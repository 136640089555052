import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindexUiModule } from "../findex-ui/findex-ui.module";
import { AnalyticsModule } from "../analytics/analytics.module";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { ErrorComponent } from "./components/error/error.component";
import { ErrorModalComponent } from "./components/error/error-modal/error-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ErrorService } from "./services/error.service";
import { MouseWheelDirective } from "./directives/mouseheel.directive";
import { VerifyMobileFormComponent } from "./components/verify-mobile-form/verify-mobile-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { NetworkConnectionInterceptor } from "./services/network-connection.interceptor";
import { UnsavedConfirmModalComponent } from "./components/unsaved-confirm-modal/unsaved-confirm-modal.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { UiLayoutsModule } from "../ui-layouts/ui-layouts.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { UiComponentsModule } from "../ui-components";
import { ItemListModalComponent } from "./components/item-list-modal/item-list-modal.component";
import { RouteDrawerComponent } from "./components/route-drawer/route-drawer.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ThreadSearchComponent } from "../threads-ui/components/thread-search/thread-search.component";
import { EmptyStateModule } from "../empty-state/empty-state.module";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FindexUiModule,
        MatDialogModule,
        MatDatepickerModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        AnalyticsModule,
        SharedPipesModule,
        RouterModule,
        UiLayoutsModule,
        PortalUiModule,
        UiComponentsModule,
        MatSidenavModule,
        EmptyStateModule,
    ],
    declarations: [
        ThreadSearchComponent,
        ConfirmModalComponent,
        ErrorComponent,
        ErrorModalComponent,
        VerifyMobileFormComponent,
        MouseWheelDirective,
        UnsavedConfirmModalComponent,
        ItemListModalComponent,
        RouteDrawerComponent,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: NetworkConnectionInterceptor, multi: true }, ErrorService],
    exports: [
        SharedPipesModule,
        ThreadSearchComponent,
        MouseWheelDirective,
        VerifyMobileFormComponent,
        AnalyticsModule,
        MatSidenavModule,
        RouteDrawerComponent,
        EmptyStateModule,
    ],
})
export class SharedModule {}
