import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { combineLatest, EMPTY, merge, Observable, of, zip } from "rxjs";
import { filter, map, mapTo, pairwise, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { LoginChallengeResult, LoginStep, LoginStepDetails } from "../model/LoginStep";
import { AppUser, AuthStrategy } from "../model/AppUser";
import { AzureAdAuthStrategy } from "./strategies/azure-ad-auth.strategy";
import { CognitoAuthStrategy } from "./strategies/cognito-auth.strategy";
import { IAuthInvitation, InvitationAuthStrategy } from "./strategies/invitation-auth.strategy";
import { environmentCommon, EnvironmentSpecificConfig } from "../../environment/environment.common";
import { ThreadsService } from "../../threads-ui/services/threads.service";
import { ENVIRONMENT } from "src/app/injection-token";
import { Role } from "@visoryplatform/threads";
import { AuthorizationLevel } from "../model/AuthorizationLevel";
import { Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { HandledError } from "../../shared/interfaces/errors";

interface WebServiceStatusResponse {
    data: { status: string };
    message?: string;
}

@Injectable({ providedIn: "root" })
export class AuthService implements HttpInterceptor {
    readonly focusWizardPath = environmentCommon.focusWizard.path;

    private readonly user$: Observable<AppUser>;

    constructor(
        private activeDirectoryStrategy: AzureAdAuthStrategy,
        private cognitoStrategy: CognitoAuthStrategy,
        private invitationStrategy: InvitationAuthStrategy,
        private http: HttpClient,
        private errorHandler: ErrorHandler,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private threadsService: ThreadsService,
        private location: Location,
        private router: Router,
    ) {
        const focusUrlChange$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            pairwise(), // keep track of previous nav end event to account for redirects
            map((events) => events.some((event: NavigationEnd) => event.url.includes(`${this.focusWizardPath}`))),
            startWith(true),
        );

        // refresh user context if it's coming or getting out of focus url
        const refreshUser$ = focusUrlChange$.pipe(
            switchMap((hasChanged) => (hasChanged ? this.getUserWithoutRole() : EMPTY)),
        );

        this.user$ = merge(this.getUserWithoutRole(), refreshUser$).pipe(
            switchMap((user) => {
                if (!user) {
                    return of(null);
                }

                if (user.authorizationLevel !== AuthorizationLevel.VERIFIED) {
                    return of(user);
                }

                return this.threadsService
                    .getGlobalRole(user.id)
                    .pipe(map((globalRole) => ({ ...user, globalRole: globalRole ? globalRole : Role.Client })));
            }),
            shareReplay(1),
        );
    }

    getLogin(): Observable<LoginStepDetails> {
        return merge(
            this.activeDirectoryStrategy.getLogin(),
            this.cognitoStrategy.getLogin(),
            this.invitationStrategy.getLogin(),
        );
    }

    onLoginSuccess(): Observable<AppUser> {
        return this.getLogin().pipe(
            filter((loginStepDetails) => loginStepDetails && loginStepDetails.step === LoginStep.LOGIN_COMPLETE),
            switchMap(() => this.getUserWithoutRole()),
        );
    }

    loginAsStaff(): Observable<LoginStepDetails> {
        return this.activeDirectoryStrategy.startLogin();
    }

    switchUser() {
        this.activeDirectoryStrategy.switchUser(); // only for staff atm
    }

    loginWithEmail(emailAddress: string, password: string): Observable<LoginStepDetails> {
        return this.cognitoStrategy.startLogin(emailAddress.toLowerCase(), password);
    }

    completeTwoFactor(code: string, rememberDevice: boolean): Observable<LoginChallengeResult> {
        return this.cognitoStrategy.completeTwoFactor(code, rememberDevice);
    }

    reLogin() {
        return this.cognitoStrategy.reLogin();
    }

    completeNewPassword(userDetails: any, newPassword: string): Observable<void> {
        return this.cognitoStrategy.completeNewPassword(userDetails, newPassword);
    }

    loginWithToken(token: string): Observable<LoginStepDetails> {
        return this.invitationStrategy.startLogin(token);
    }

    verifyCode(code: string): Observable<LoginChallengeResult> {
        return this.invitationStrategy.answerChallenge(code);
    }

    beginVerifyMobileNumber(mobileNumber: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.beginVerifyMobileNumber(mobileNumber);
    }

    confirmMobileNumber(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyMobileNumber(code);
    }

    confirmEmail(code: string): Observable<{ status: string; message: string }> {
        return this.cognitoStrategy.confirmVerifyEmailAddress(code);
    }

    logout(): Observable<void> {
        return zip(
            this.activeDirectoryStrategy.logout(),
            this.cognitoStrategy.logout(),
            this.invitationStrategy.logout(),
        ).pipe(mapTo(null));
    }

    getUser(): Observable<AppUser> {
        return this.user$;
    }

    getValidUser(): Observable<AppUser> {
        return this.user$.pipe(
            filter((user) => !!user),
            shareReplay(1),
        );
    }

    getGlobalRole(): Observable<Role> {
        const user$ = this.getValidUser();
        return user$.pipe(map((user) => user.globalRole));
    }

    getUserId(): Observable<string> {
        const user$ = this.getUser().pipe(filter((user) => !!user));
        return user$.pipe(map((user) => user.id));
    }

    getHttpHeaders(): Observable<any> {
        return this.getUserWithoutRole().pipe(
            take(1),
            switchMap((user) => this.userStrategyHeaders(user)),
        );
    }

    getVerifiedHttpHeaders(): Observable<Record<string, string>> {
        return this.getUserWithoutRole().pipe(
            take(1),
            switchMap((user) => {
                if (user.authorizationLevel < AuthorizationLevel.NOMINAL) {
                    return EMPTY;
                }
                return this.userStrategyHeaders(user);
            }),
        );
    }

    getUserWithoutRole(): Observable<AppUser> {
        if (this.isFocusUrl()) {
            return this.invitationStrategy.getUser();
        }

        return combineLatest([this.activeDirectoryStrategy.getUser(), this.cognitoStrategy.getUser()]).pipe(
            map(([staffUser, cognitoUser]) => cognitoUser || staffUser),
            map((user) => {
                if (!user) {
                    return null;
                }

                return user;
            }),
        );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getUserWithoutRole().pipe(
            take(1),
            switchMap((user) => this.userStrategyInterceptor(user, req, next)),
        );
    }

    async fetchInvitation(invitationId: string): Promise<IAuthInvitation> {
        return await this.invitationStrategy.fetchInvitation(invitationId).toPromise();
    }

    async refreshUserTokens() {
        await this.cognitoStrategy.refreshTokens();
    }

    async checkSignUpStatus(emailAddress: string): Promise<{
        success: boolean;
        loginRequired: boolean;
        verificationSent: boolean;
        errorMessage?: string;
    }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.checkUser}`;

        const body = {
            emailAddress: emailAddress.toLowerCase(),
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            redirectUrl: this.environment.registration.redirectUrl,
            errorRedirectUrl: this.environment.errorRedirectUrl,
            themeName: this.environment.appTheme,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const { status } = result.data ? result.data : { status: "UNKNOWN" };
            switch (status) {
                case "PROCEED_WITH_SIGNUP":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: false,
                    };
                case "VERIFICATION_SENT":
                    return {
                        success: true,
                        loginRequired: false,
                        verificationSent: true,
                    };
                case "LOGIN":
                case "PASSWORD_CHANGE_REQUIRED":
                    return {
                        success: true,
                        loginRequired: true,
                        verificationSent: false,
                    };
            }
        } catch (errorResponse) {
            this.handleError(errorResponse);
        }
        return {
            success: false,
            errorMessage: "Sorry, something went wrong",
            loginRequired: false,
            verificationSent: false,
        };
    }

    async beginForgotPassword(emailAddress: string): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPassword}`;

        const body = {
            emailAddress,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            redirectUrl: this.environment.auth.forgotPasswordRedirect,
            themeName: this.environment.appTheme,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            this.handleError(errorResponse);
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    async confirmPasswordReset(
        userName: string,
        code: string,
        newPassword: string,
    ): Promise<{ success: boolean; errorMessage?: string }> {
        const { base } = this.environment.auth;
        const { endpoints } = environmentCommon.auth;
        const url = `${base}${endpoints.forgotPasswordConfirm}`;

        const body = {
            userName,
            userPoolClientId: this.environment.auth.userPoolWebClientId,
            code,
            newPassword,
        };
        try {
            const result = await this.http.post<WebServiceStatusResponse>(url, body).toPromise();
            const status = result.data ? result.data.status : "";
            if (status === "OK") {
                return { success: true };
            }
            return { success: false, errorMessage: result.message };
        } catch (errorResponse) {
            return { success: false, errorMessage: errorResponse.error.message };
        }
    }

    isExternal(userId: string): boolean {
        return userId?.slice(0, 8) === "azuread-";
    }

    private isFocusUrl(): boolean {
        return this.location.path().includes(`${this.focusWizardPath}`);
    }

    private handleError(errors: Error) {
        try {
            if ("error" in errors) {
                const errorContent = (errors as any).error;
                const status = errorContent.data ? errorContent.data.status : "";
                //Some 4xx responses are expected base on user behaviour, e.g. throttling. Only report actual errors.
                if (!status.toUpperCase().includes("ERROR")) {
                    return;
                }
            }
            this.errorHandler.handleError(new HandledError(errors));
            // eslint-disable-next-line no-empty
        } catch (err) {}
    }

    private userStrategyInterceptor(
        user: AppUser,
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (!user) {
            return next.handle(req);
        }

        if (this.isFocusUrl()) {
            return this.invitationStrategy.intercept(req, next);
        }

        if (user.type === AuthStrategy.Cognito) {
            return this.cognitoStrategy.intercept(req, next);
        }

        return this.activeDirectoryStrategy.intercept(req, next);
    }

    private userStrategyHeaders(user: AppUser): Observable<Record<string, string>> {
        if (!user) {
            return of({});
        }

        if (this.isFocusUrl()) {
            return this.invitationStrategy.getHttpHeaders();
        }

        if (user.type === AuthStrategy.Cognito) {
            return this.cognitoStrategy.getHttpHeaders();
        }

        return this.activeDirectoryStrategy.getHttpHeaders();
    }
}
