import { AnalyticsModule } from "../../../analytics/analytics.module";
import { CommonModule } from "@angular/common";
import { ConfigureWorkflowComponent } from "./components/configure-workflow/configure-workflow.component";
import { CreateThreadModalComponent } from "./components/create-thread-modal/create-thread-modal.component";
import { CreateWorkflowModalComponent } from "./components/create-workflow-modal/create-workflow-modal.component";
import { DueDatesModule } from "../edit-workflow-config";
import { EditParticipantRoleComponent } from "./components/edit-participant-role/edit-participant-role.component";
import { EditParticipantsComponent } from "./components/edit-participants/edit-participants.component";
import { EditThreadModalComponent } from "./components/edit-thread-modal/edit-thread-modal.component";
import { EditTitleComponent } from "./components/edit-title/edit-title.component";
import { EditWorkflowTitleComponent } from "./components/edit-workflow-title/edit-workflow-title.component";
import { FindexUiModule } from "../../../findex-ui";
import { ForwardMessageModule } from "../forward-message/forward-message.module";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { NgModule } from "@angular/core";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { ReactiveFormsModule } from "@angular/forms";
import { SelectServiceComponent } from "../select-service/components/select-service/select-service.component";
import { SelectServiceModule } from "../select-service/select-service.module";
import { SelectWorkflowComponent } from "./components/select-workflow/select-workflow.component";
import { SelectWorkflowFormComponent } from "./components/select-workflow-form/select-workflow-form.component";
import { SelectWorkflowFormLegacyComponent } from "./components/select-workflow-form-legacy/select-workflow-form-legacy.component";
import { SelectWorkflowServiceComponent } from "./components/select-workflow-service/select-workflow-service.component";
import { SelectWorkflowTokenComponent } from "./components/select-workflow-token/select-workflow-token.component";
import { SelectWorkflowTokenLegacyComponent } from "./components/select-workflow-token-legacy/select-workflow-token-legacy.component";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { ThreadParticipantsModule } from "../thread-participants";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";
import { UiComponentsModule } from "../../../ui-components/ui-components.module";
import { UiLayoutsModule } from "../../../ui-layouts/ui-layouts.module";

@NgModule({
    imports: [
        CommonModule,
        FxUiModule,
        ThreadsUiPipesModule,
        SharedPipesModule,
        AnalyticsModule,
        FindexUiModule,
        UiComponentsModule,
        UiLayoutsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        ThreadParticipantsModule,
        DueDatesModule,
        PortalUiModule,
        ForwardMessageModule,
        SelectServiceModule,
    ],
    declarations: [
        CreateThreadModalComponent,
        EditThreadModalComponent,
        SelectWorkflowComponent,
        ConfigureWorkflowComponent,
        EditParticipantsComponent,
        EditParticipantRoleComponent,
        EditTitleComponent,
        EditWorkflowTitleComponent,
        SelectWorkflowServiceComponent,
        SelectWorkflowFormComponent,
        SelectWorkflowFormLegacyComponent,
        SelectWorkflowTokenComponent,
        SelectWorkflowTokenLegacyComponent,
        CreateWorkflowModalComponent,
    ],
    exports: [
        CreateThreadModalComponent,
        SelectWorkflowComponent,
        SelectServiceComponent,
        EditThreadModalComponent,
        SelectWorkflowTokenComponent,
        SelectWorkflowFormComponent,
        SelectWorkflowServiceComponent,
    ],
    providers: [],
})
export class CreateThreadModule {}
