import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-slide-menu-icon",
    templateUrl: "./slide-menu-icon.component.html",
    styleUrls: ["./slide-menu-icon.component.scss"],
})
export class SlideMenuIconComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
