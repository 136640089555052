<div class="toast">
    <i class="las la-exclamation-circle toast-icon"></i>
    <div>
        <div class="toast-message">{{ message }}</div>
        <div
            class="toast-description"
            [innerHTML]="description"
        ></div>
    </div>
    <div
        class="toast-button"
        (click)="closeDialog()"
    >
        {{ buttonLabel }}
    </div>
</div>
