import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IParticipant } from "@visoryplatform/threads";

@Component({
    selector: "request-view-reply",
    templateUrl: "./request-view-reply.component.html",
    styleUrls: ["./request-view-reply.component.scss"],
})
export class RequestViewReplyComponent {
    @Input() cardDescription: FormControl<string>;
    @Input() participants: IParticipant[];
    @Input() threadType: string;
}
