import { Pipe, PipeTransform } from "@angular/core";
import { IDocument } from "projects/portal-modules/src/lib/threads-ui/components/create-card/create-card-document/create-card-document.component";
import { DocumentCategory } from "../enums/DocumentCategory";

@Pipe({ name: "validateMarkFileAsReport" })
export class ValidateMarkFileAsReportPipe implements PipeTransform {
    transform(documents: IDocument[], markAllAsReport: boolean): boolean {
        if (!markAllAsReport) {
            return true;
        }

        return documents.some((document) => document.category === DocumentCategory.Report);
    }
}
