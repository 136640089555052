import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { ThreadsUiPipesModule } from "../../pipes/threads-ui-pipes.module";

import { ParticipantAvatarsComponent } from "./components/participant-avatars/participant-avatars.component";

@NgModule({
    imports: [CommonModule, FxUiModule, ThreadsUiPipesModule, SharedPipesModule],
    declarations: [ParticipantAvatarsComponent],
    exports: [ParticipantAvatarsComponent],
    providers: [],
})
export class ParticipantAvatarsModule {}
