<div class="calendar-meeting-request">
    <multi-component-layout
        [stepConfigurations]="stepConfigurations"
        (emitComplete)="submit()"
        [loading]="loader.counter !== 0"
        [enableFooter]="true"
        [enableHeader]="true"
        contentClass="multi-component-layout--book-meeting"
    >
        <div class="calendar-meeting-request-header multi-component-layout-header">
            <div class="calendar-meeting-request-title">
                {{ modalTitle }}
            </div>
            <div class="calendar-meeting-request-header-close">
                <div
                    class="fx-l-modal-header-icon"
                    (click)="close()"
                >
                    <i class="las la-times icon-size"></i>
                </div>
            </div>
        </div>
        <step-layout [stepTitle]="'Meeting details'">
            <calendar-create-invitation
                [editAttendees]="editAttendees"
                [meetingDetails]="meetingData"
                [participants]="participants"
                [showValidationErrors]="showValidationErrors"
                (emitDetails)="handleMeetingDetailsUpdated($event)"
            ></calendar-create-invitation>
        </step-layout>
        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>
    </multi-component-layout>
</div>
