import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { CardResources } from "../interfaces/IUiCard";
import { filter, map, switchMap, take } from "rxjs/operators";
import { AppUser, AuthService } from "../../findex-auth";
import { CalendarAction, IUpcomingMeeting } from "@visoryplatform/threads";
import { TaskActionService } from "../../shared/components/actionable-card/task-action.service";
import { UiCardService } from "./ui-card.service";
import { ThreadCardService } from "./thread-card.service";
import { PortalService } from "../../shared/services/portal.service";

@Injectable({ providedIn: "root" })
export class CalendarMeetingsService {
    constructor(
        protected taskActionService: TaskActionService,
        private authService: AuthService,
        private uiCardService: UiCardService,
        private portalService: PortalService,
        private threadCardService: ThreadCardService,
    ) {}

    public onViewMeetingDetails(calendarEvent: IUpcomingMeeting): Observable<any> {
        const { threadId, cardId } = calendarEvent;

        return this.getCardResources(threadId, cardId).pipe(
            switchMap((cardResources) => this.taskActionService.action(CalendarAction.VIEW_DETAILS, cardResources)),
        );
    }

    private getCardResources(threadId: string, cardId: string): Observable<CardResources> {
        const thread$ = this.portalService.getThreadListById(threadId);
        const card$ = this.threadCardService.getCard(threadId, cardId);

        const userRole$ = this.authService.getUser().pipe(
            filter((user: AppUser) => !!user),
            map((user: AppUser) => user.globalRole),
            take(1),
        );

        return combineLatest([card$, userRole$, thread$]).pipe(
            map(([card, userRole]) => this.uiCardService.getCardResources(threadId, thread$, card, userRole)),
        );
    }
}
