import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

const dateFormat = "yyyy-MM-dd";

@Pipe({ name: "dateRange" })
export class DateRangePipe implements PipeTransform {
    transform(startDate: string | Date, endDate: string | Date): string {
        if (!startDate || !endDate) {
            return "Start and end date must be valid values";
        }
        const startDateTime = this.mapToDateTime(startDate);
        const endDateTime = this.mapToDateTime(endDate);

        return this.toRangeFormat(startDateTime, endDateTime);
    }

    private mapToDateTime(date: string | Date): DateTime {
        if (typeof date === "string") {
            const isoDate = DateTime.fromISO(date);
            if (isoDate.isValid) {
                return isoDate;
            } else {
                return DateTime.fromFormat(date, dateFormat);
            }
        } else {
            return DateTime.fromJSDate(date);
        }
    }

    private toRangeFormat(startDate: DateTime, endDate: DateTime): string {
        if (startDate.month === endDate.month) {
            return `${startDate.toFormat("d")}-${endDate.toFormat("d MMM y")}`;
        } else {
            return `${startDate.toFormat("d MMM")}-${endDate.toFormat("d MMM y")}`;
        }
    }
}
