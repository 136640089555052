import { Component, Inject, Injector, OnDestroy, OnInit } from "@angular/core";
import { VaultService } from "@visoryplatform/vault";
import { filter, map, take } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { IVaultItemFile } from "../../interfaces/IVaultItemFile";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { IVaultState } from "../../interfaces/IVaultState";
import { Observable, Subscription } from "rxjs";

interface ISignatureDetails {
    name: FormControl<string>;
    title: FormControl<string>;
    agreed: FormControl<boolean>;
}

export interface ISigningModalData {
    vaultItem: IVaultItem;
    state$?: Observable<IVaultState>;
}

@Component({
    selector: "signing-modal",
    templateUrl: "./signing-modal.component.html",
    styleUrls: ["./signing-modal.component.scss"],
    providers: [Loader],
})
export class SigningModalComponent implements OnInit, OnDestroy {
    readonly theme = this.environment.theme;
    readonly signingRequiresFileDownloadEnabled = this.environment.featureFlags.signingRequiresFileDownloadEnabled;
    readonly modalHeader = "Electronic signature required";

    form: FormGroup<ISignatureDetails>;
    warningMessage: string | null;
    userId: string;
    stateSub: Subscription;

    data: ISigningModalData;
    private dialogRef: DialogRef;

    constructor(
        private vaultService: VaultService,
        private authService: AuthService,
        public loader: Loader,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private injector: Injector,
        private dialogService: DialogService,
    ) {
        this.initForm();
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef(this.injector).toPromise();
        this.data = await this.dialogService.getData<ISigningModalData>(this.injector).toPromise();

        this.stateSub = this.data.state$?.subscribe((state) => {
            for (const group of state.groups) {
                for (const item of group.items) {
                    if (item.fileId === this.data.vaultItem.fileId && item.vaultId === this.data.vaultItem.vaultId) {
                        this.data.vaultItem = item;
                    }
                }
            }
        });

        this.authService
            .getUser()
            .pipe(
                take(1),
                map((user) => user && user.id),
            )
            .subscribe((userId) => (this.userId = userId));
    }

    ngOnDestroy(): void {
        this.stateSub?.unsubscribe();
    }

    async download(): Promise<void> {
        const { vaultId, fileId, files } = this.data.vaultItem;
        const filename = files[0].filename;

        if (!files.length) {
            return;
        }

        const downloadWindow = window.open("", "_self");
        const downloadUrl = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
        downloadWindow.location.href = downloadUrl;
    }

    accept(): void {
        this.warningMessage = null;
        const { name, title, agreed } = this.form.controls;
        const signingResult = {
            name: name.value,
            title: title.value,
            agreed: agreed.value,
        };
        const userHasDownloadedFile = this.hasUserDownloadedFile(this.data.vaultItem.files, this.userId);

        if (this.signingRequiresFileDownloadEnabled && !userHasDownloadedFile) {
            this.warningMessage = "Please ensure you have downloaded and read the file.";
            return;
        }

        const signFile$ = this.vaultService.sign(
            this.data.vaultItem.vaultId,
            this.data.vaultItem.fileId,
            signingResult,
        );

        this.loader
            .wrap(signFile$)
            .pipe(
                take(1),
                filter((res) => !!res),
            )
            .subscribe((res) => {
                this.dialogRef.close(res);
            });
    }

    close(): void {
        return this.dialogRef.close();
    }

    private hasUserDownloadedFile(files: IVaultItemFile[], userId: string): boolean {
        const hasUserDownloaded = files.every((file) => {
            if (!file.actionLogs || !Array.isArray(file.actionLogs)) {
                return false;
            }

            const fileDownloadedActions = file.actionLogs.find(
                (log) => log.action === "file-downloaded" && log.actorId === userId,
            );
            return !!fileDownloadedActions;
        });
        return hasUserDownloaded;
    }

    private initForm(): void {
        this.form = new FormGroup<ISignatureDetails>({
            name: new FormControl("", [Validators.required]),
            title: new FormControl("", [Validators.required]),
            agreed: new FormControl(false, [Validators.required]),
        });
    }
}
