<search-participants
    [existingParticipants]="participants"
    (addParticipant)="addParticipant($event)"
>
</search-participants>

<div class="participant-list">
    <div
        *ngFor="let participant of participants; trackBy: 'id' | trackProperty"
        class="participant-list-participant"
    >
        <edit-participant-role
            [id]="participant.id"
            [name]="participant?.profile?.name"
            [role]="participant.role"
        >
        </edit-participant-role>

        <div
            *ngIf="!participant.isAssignee; else assigned"
            class="participant-list-participant-remove"
            (click)="removeParticipant(participant.id)"
        >
            <i class="las la-times"></i>
        </div>

        <ng-template #assigned>
            <span class="participant-list-participant-assigned-to-step">{{ assignedToStep }}</span>
        </ng-template>
    </div>
</div>
