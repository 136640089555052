<div class="recording-link">
    <button
        class="recording-link-button fx-btn fx-btn--primary"
        (click)="downloadUrl()"
    >
        <i class="las la-video"></i>Watch recording
    </button>
    <p
        *ngIf="isUnavailable"
        class="recording-link-error-message"
    >
        Your recording is not available yet. Try again shortly.
    </p>
</div>
