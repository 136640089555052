import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IllustrationComponent } from "./components/illustration/illustration.component";
import { DelphiLogoComponent } from "./components/delphi-logo/delphi-logo.component";

@NgModule({
    imports: [CommonModule],
    exports: [IllustrationComponent, DelphiLogoComponent],
    declarations: [IllustrationComponent, DelphiLogoComponent],
})
export class IllustrationsModule {}
