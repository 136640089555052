<section
    *ngIf="form"
    [formGroup]="form"
    class="rfi"
>
    <div class="rfi-secondary-header">
        <h1>{{ secondaryTitle }}</h1>
    </div>

    <div class="rfi-secondary-sub-header">
        {{ title }}
        <div
            *ngIf="state && !isEditing"
            class="rfi-secondary-sub-header-progress"
        >
            <span class="rfi-secondary-sub-header-progress-actioned">
                {{ actionedRequestItems }} / {{ state.requestItems?.length }} actioned
            </span>
            <progress-bar [progress]="actionedPercentage"></progress-bar>
        </div>
    </div>

    <div
        class="rfi-todo-list"
        formArrayName="requestItems"
    >
        <div
            class="rfi-todo-list-items"
            *ngFor="let group of groupedControls | keyvalue : unsorted; trackBy: trackGroup"
        >
            <h2 *ngIf="group.value[0].groupName !== DEFAULT_GROUP">
                {{ group.value[0].groupName }}
            </h2>

            <ng-container
                *ngFor="let items of group.value; trackBy: trackGroupedControls"
                [formGroupName]="items?.originalIndex"
            >
                <fx-todolist-item
                    *ngIf="items.formGroup?.controls?.completed"
                    formControlName="completed"
                    (change)="updateControlValue(items.formGroup?.controls.requestItem, $event)"
                    (deleteItem)="
                        handleRequestItemRemoved(
                            items.originalIndex,
                            items.formGroup?.controls.requestItem,
                            state?.isCompleted
                        )
                    "
                    [disabled]="!canUpdateTodoListItem || isEditing"
                    [shouldShowDelete]="requestItems.length > 1 && isEditing && !state?.isCompleted"
                >
                    <div class="rfi-todo-list-todo">
                        <div
                            [ngClass]="{
                                'rfi-todo-list-todo-details--checked': items.formGroup?.controls.completed.value
                            }"
                            class="rfi-todo-list-todo-details"
                        >
                            <textarea
                                #todoItemComponents
                                formControlName="description"
                                (keydown)="keyboardEvent($event, items.formGroup?.controls.completed.value)"
                                class="rfi-todo-list-todo-details-field"
                                type="text"
                                placeholder="Type something..."
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="3"
                            ></textarea>
                        </div>
                        <div
                            *ngIf="showActions"
                            class="rfi-todo-list-todo-details-actions"
                        >
                            <request-todo-actions
                                #todoItemActionComponents
                                *ngIf="!isEditing"
                                formControlName="requestItem"
                                [userId]="userId"
                                [analyticsPrefix]="ANALYTICS_PREFIX"
                                [readonly]="readonly"
                                (fileAttached)="handleFileAttached($event.requestItem, $event.file)"
                                (fileRemoved)="handleFileDeleted($event.requestItem, $event.file)"
                                (textInput)="handleTextResponse($event.requestItem, $event.value)"
                                (fileDownloaded)="downloadFile($event.requestItem.fileId, $event.file)"
                                [requestItem]="items.formGroup?.controls.requestItem.value"
                            ></request-todo-actions>
                        </div>
                    </div>
                </fx-todolist-item>
            </ng-container>
        </div>
    </div>
</section>
