<div class="fx-onboarding fx-onboarding-bg-verify-email">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1 class="fx-onboarding-title">Email verified</h1>
            <p class="fx-onboarding-body">Email {{ emailAddress }} has been successfully verified</p>
            <button
                class="fx-onboarding-button fx-btn disable-flex-grow"
                [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                [routerLink]="['/login']"
                [queryParams]="{ redirect: '/register/verify-mobile', emailAddress: emailAddress }"
                analyticsClick="onboarding_continuetologin"
            >
                Continue to login
            </button>
        </div>
    </div>
</div>
