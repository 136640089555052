import { ICardEvent } from "@visoryplatform/threads";
import { Observable, ReplaySubject } from "rxjs";
import { IVCDetails } from "../interfaces/IVCDetails";
export interface IVcEvent {
    sessionId?: string;
    scheduledTime?: string;
}

const LEGACY_FAKE_ID = "video-chat/fakeid";

export class VcStateBuilder {
    private subject = new ReplaySubject<IVCDetails>(1);

    private model: IVCDetails = {
        isTerminated: false,
        mostRecentSession: null,
        sessionEventGroups: {},
        activeSessionIds: [],
    };

    constructor() {
        this.subject.next(this.model);
    }

    getState(): Observable<IVCDetails> {
        return this.subject.asObservable();
    }

    setThreadAndState(threadId: string, cardId: string) {
        this.model.threadId = threadId;
        this.model.cardId = cardId;
        this.subject.next(this.model);
    }

    addEvent(event: ICardEvent<IVcEvent>) {
        const { type, payload, actor, createdAt, correlationId } = event;
        if (!payload || !["video-chat-start", "video-chat-end"].includes(type)) {
            return;
        }

        const legacy = !correlationId || correlationId === LEGACY_FAKE_ID;

        const eventSessionId = payload?.sessionId;

        if (eventSessionId) {
            if (!this.model.sessionEventGroups[eventSessionId]) {
                this.model.sessionEventGroups[eventSessionId] = [];
            }
            this.model.sessionEventGroups[eventSessionId].push(type);
        }

        if (
            !this.model?.mostRecentSession ||
            (this.model?.mostRecentSession?.timestamp &&
                this.model?.mostRecentSession?.timestamp < new Date(createdAt).getTime())
        ) {
            this.model.mostRecentSession = {
                id: payload?.sessionId,
                timestamp: new Date(createdAt).getTime(),
            };
        }

        if (type === "video-chat-start") {
            this.model.activeSessionIds.push(eventSessionId);

            if (this.model?.mostRecentSession?.id === eventSessionId || legacy) {
                this.model.sessionId = payload?.sessionId;
                this.model.isTerminated = false;
                this.model.status = "Call starting...";
            }
        } else if (type === "video-chat-end") {
            const findSessionInArray = this.model.activeSessionIds.indexOf(eventSessionId);
            if (findSessionInArray !== -1) {
                this.model.activeSessionIds.splice(findSessionInArray, 1);
            }

            if (this.model?.mostRecentSession?.id === eventSessionId || legacy) {
                this.model.isTerminated = true;
                this.model.status = "Video session concluded.";
            }
        }

        this.model.actor = actor;
        this.subject.next(this.model);
    }

    setCaller(name: string, position: string) {
        this.model.name = name;
        this.model.position = position;
        this.subject.next(this.model);
    }
}
