import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { AppAssistantService } from "../../services/app-assistant.service";
import { ActivatedRoute } from "@angular/router";
import { distinctUntilChanged, map, mapTo, scan, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { FormControl, Validators } from "@angular/forms";
import { AuthService } from "../../../../portal-modules/src/lib/findex-auth";
import { Observable, Subject, Subscription, combineLatest, merge, of } from "rxjs";
import { AccountAssistantService } from "../../services/account-assistant.service";
import { marked } from "marked";
import {
    ActorId,
    AssistantEvent,
    AssistantEvents,
    ChatMessage,
    ChatMessageDelta,
    MessageCreatedEvent,
    MessageDeltaEvent,
    MessageEndEvent,
    MessageRole,
    ACTIVE_RUN_STATUSES,
    ChatRunStatus,
} from "@visoryplatform/threads";
import { ThreadsWebsocketService } from "projects/portal-modules/src/lib/shared/services/threads-websocket.service";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

type ChatMessageChunked = ChatMessage & { chunks: ChatMessageDelta[]; ended: boolean };

@Component({
    selector: "chat",
    templateUrl: "./chat.component.html",
    styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy {
    readonly systemId = ActorId.System;
    brandingEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableDelphiBranding);

    loader = new Loader();
    aiTyping = new Loader();
    newMessage = new FormControl("", [Validators.required]);

    accountId$: Observable<string>;
    chatId$: Observable<string>;
    actorId$ = this.authService.getValidUser().pipe(map((user) => user.id));

    runPendingChat?: Subscription;
    messages$: Observable<ChatMessage[]>;
    newMessageSource = new Subject<AssistantEvent>();

    constructor(
        private authService: AuthService,
        private appAssistant: AppAssistantService,
        private accountAssistant: AccountAssistantService,
        private route: ActivatedRoute,
        private websocketService: ThreadsWebsocketService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) public environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.accountId$ = this.route.paramMap.pipe(
            map((params) => params.get("accountId")),
            distinctUntilChanged(),
        );

        this.chatId$ = this.route.paramMap.pipe(
            map((params) => params.get("chatId")),
            distinctUntilChanged(),
        );

        // Load existing message list from server
        const initialMessages$ = combineLatest([this.chatId$, this.accountId$]).pipe(
            switchMap(([chatId, accountId]) => this.listMessages(chatId, accountId)),
            shareReplay(1),
        );

        this.messages$ = initialMessages$.pipe(
            switchMap((initialMessages) => this.observeChat(initialMessages)),
            map((messages) => messages.map((message) => this.formatMessage(message))),
        );

        // if last message is user, and no run
        this.runPendingChat = combineLatest([this.chatId$, this.accountId$, this.messages$])
            .pipe(switchMap(([chatId, accountId, messages]) => this.runStalledChat(chatId, accountId, messages)))
            .subscribe();
    }

    ngOnDestroy(): void {
        this.runPendingChat?.unsubscribe();
    }

    addMessage(actorId: string, chatId: string, message: string, accountId?: string): void {
        this.sendMessage(actorId, chatId, message, accountId);
        this.newMessage.reset();
    }

    private runStalledChat(chatId: string, accountId: string, messages: ChatMessage[]): Observable<string | null> {
        return this.isChatRunRequired(chatId, messages, accountId).pipe(
            switchMap((runRequired) => {
                if (runRequired) {
                    return this.runChat(chatId, accountId);
                } else {
                    return of(null);
                }
            }),
        );
    }

    private formatMessage(message: ChatMessage): ChatMessage {
        const body = marked
            .parse(message?.body || "")
            ?.toString()
            ?.replace(/\n/g, "");

        return {
            ...message,
            body,
        };
    }

    private isChatRunRequired(chatId: string, messages: ChatMessage[], accountId?: string): Observable<boolean> {
        if (this.isLastMessageUser(messages)) {
            return this.checkRunStatus(chatId, accountId).pipe(map((status) => this.lastRunInactive(status)));
        } else {
            return of(false);
        }
    }

    private isLastMessageUser(messages: ChatMessage[]): boolean {
        const lastMessage = messages.length && messages[messages.length - 1];
        return lastMessage?.role === MessageRole.User;
    }

    private lastRunInactive(status: ChatRunStatus): boolean {
        if (!status || !ACTIVE_RUN_STATUSES.includes(status)) {
            console.log("Last run", status, "and last message from user");
            return true;
        } else {
            return false;
        }
    }

    private observeChat(initialMessages: ChatMessage[]): Observable<ChatMessage[]> {
        const chunkedMessages: ChatMessageChunked[] = initialMessages.map((message) => ({
            ...message,
            chunks: [],
            ended: true,
        }));

        const websocket$ = this.chatId$.pipe(switchMap((chatId) => this.websocketService.watchAssistantChat(chatId)));
        const observeEvents$ = merge(this.newMessageSource, websocket$);

        const messages$: Observable<ChatMessage[]> = observeEvents$.pipe(
            scan((messages, event) => this.reduceAssistantEvent(messages, event), chunkedMessages),
            startWith(chunkedMessages),
        );

        return messages$;
    }

    private reduceAssistantEvent(messages: ChatMessageChunked[], event: AssistantEvent): ChatMessageChunked[] {
        this.aiTyping.hide();

        if (event.type === AssistantEvents.MessageCreated) {
            return this.startMessage(messages, event);
        } else if (event.type === AssistantEvents.MessageDelta) {
            return this.addToMessageChunk(messages, event);
        } else if (event.type === AssistantEvents.MessageEnd) {
            return this.endMessage(messages, event);
        }
        return messages;
    }

    private startMessage(messages: ChatMessageChunked[], event: MessageCreatedEvent): ChatMessageChunked[] {
        const newMessage: ChatMessageChunked = {
            ...event.payload,
            chunks: [],
            ended: false,
        };
        return [...messages, newMessage];
    }

    private endMessage(messages: ChatMessageChunked[], event: MessageEndEvent): ChatMessageChunked[] {
        const existingMessage = messages.find((message) => message.id === event.payload.id);

        if (existingMessage) {
            const body = event.payload.body;
            const updatedMessage = { ...existingMessage, body, ended: true };
            return messages.map((message) => (message.id === updatedMessage.id ? updatedMessage : message));
        } else {
            console.log("Received delta for non-existent message", event.payload.id);
            return messages;
        }
    }

    private addToMessageChunk(messages: ChatMessageChunked[], event: MessageDeltaEvent): ChatMessageChunked[] {
        const existingMessage = messages.find((message) => message.id === event.payload.id);

        if (existingMessage && !existingMessage.ended) {
            const chunks = [...existingMessage.chunks, event.payload].sort((a, b) => a.order - b.order);
            const body = existingMessage.chunks.map((chunk) => chunk.body).join("");
            const updatedMessage = { ...existingMessage, body, chunks };
            return messages.map((message) => (message.id === updatedMessage.id ? updatedMessage : message));
        } else {
            console.log("Received delta for non-existent message", event.payload.id);
            return messages;
        }
    }

    private sendMessage(actorId: string, chatId: string, message: string, accountId?: string): void {
        this.loader
            .wrap(this.appendMessage(chatId, message, accountId))
            .pipe(
                tap((message) =>
                    this.newMessageSource.next({
                        chatId,
                        type: AssistantEvents.MessageCreated,
                        userId: actorId,
                        payload: message,
                    }),
                ),
                switchMap(() => this.runChat(chatId, accountId)),
                take(1),
            )
            .subscribe();
    }

    private listMessages(chatId: string, accountId?: string): Observable<ChatMessage[]> {
        if (accountId) {
            return this.loader.wrap(this.accountAssistant.listAllMessages(accountId, chatId));
        } else {
            return this.loader.wrap(this.appAssistant.listAllMessages(chatId));
        }
    }

    private checkRunStatus(chatId: string, accountId?: string): Observable<ChatRunStatus> {
        const status$ = accountId
            ? this.accountAssistant.checkRunStatus(accountId, chatId)
            : this.appAssistant.checkRunStatus(chatId);
        return this.loader.wrap(status$);
    }

    private runChat(chatId: string, accountId?: string): Observable<string> {
        const runChat$ = accountId
            ? this.accountAssistant.runChat(accountId, chatId)
            : this.appAssistant.runChat(chatId);

        this.aiTyping.show();
        return this.aiTyping.wrap(runChat$.pipe(mapTo(chatId)));
    }

    private appendMessage(chatId: string, message: string, accountId?: string): Observable<ChatMessage> {
        const newMessage$ = accountId
            ? this.accountAssistant.addMessage(accountId, chatId, message)
            : this.appAssistant.addMessage(chatId, message);

        return this.loader.wrap(newMessage$);
    }
}
