import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { SENTRY_ERROR_HANDLER } from "src/app/injection-token";
import { AnalyticsService } from "../../analytics";
import { ErrorModalComponent } from "../components/error/error-modal/error-modal.component";
import { MessageService } from "@visoryplatform/portal-ui";
import { ErrorMessage, ErrorTitle } from "../components/error/error.component";
import { HandledError } from "../interfaces/errors";

@Injectable({
    providedIn: "root",
})
export class ErrorService extends ErrorHandler {
    public disableDialog = false;

    private static readonly maxErrorDepth = 99;

    constructor(
        private dialog: MatDialog,
        @Inject(SENTRY_ERROR_HANDLER) private sentryErrorHandler: ErrorHandler,
        private analytics: AnalyticsService,
        private messageService: MessageService,
    ) {
        super();
    }

    static isHttpErrorResponse(err: any): err is HttpErrorResponse {
        return err && err.name && err.name === "HttpErrorResponse" && err.ok === false && err.message;
    }

    public showErrorModal(
        title?: string,
        message?: string,
        buttonTitle?: string,
        allowAction?: boolean,
        routeBackLink?: string,
    ): Observable<boolean> {
        const errorAlreadyOpen = this.dialog.openDialogs.some(
            (dialog) => dialog.componentInstance instanceof ErrorModalComponent,
        );

        if (errorAlreadyOpen || this.disableDialog) {
            console.error("Error dialog already open, logging only to console", message);
            return of(false);
        }

        const options = {
            disableClose: true,
            backdropClass: "modal-backdrop",
            panelClass: ["fx-error-modal-dialog"],
            maxWidth: "100%",
            maxHeight: "100%",
            data: {
                title: title || undefined,
                message: message || undefined,
                buttonTitle: buttonTitle || undefined,
                allowAction: allowAction || undefined,
                routeBackLink: routeBackLink || undefined,
            },
        };

        return this.dialog.open(ErrorModalComponent, options).afterClosed();
    }

    showErrorToast(title?: string, message?: string): void {
        this.messageService.add({
            severity: "error",
            summary: title ?? ErrorTitle.GENERIC,
            detail: message ?? ErrorMessage.GENERIC,
            sticky: true,
        });
        // also show error message in console
        console.log(message);
    }

    handleError(error: any): void {
        const err = error?.rejection || error;
        const isHandled = err instanceof HandledError;
        const responseError = this.unwrapError(err);
        // Notes: Global error handler appears to only fire once the unsubscribes
        if (!isHandled) {
            if (ErrorService.isHttpErrorResponse(responseError)) {
                const title =
                    responseError.status && responseError?.error?.error
                        ? `${responseError.status} - ${responseError.error.error}`
                        : null;
                const message = responseError?.error?.message || null;

                this.trackAnalytics(responseError.status);

                if (this.userShouldSeeError(responseError)) {
                    if (!responseError?.status || responseError.status === 500 || responseError.status === 504) {
                        this.showErrorToast(title, message);
                    } else {
                        this.showErrorModal(title, message);
                    }
                }
            } else if (this.isJSError(responseError) && !this.sentryShouldIgnoreError(responseError)) {
                // not sure if this is needed or not, shows unfriendly errors
                if (this.userShouldSeeError(responseError)) {
                    this.showErrorToast(responseError.name, responseError.message);
                }
            }
        }

        if (responseError && this.sentryShouldIgnoreError(responseError)) {
            return;
        }

        this.sentryErrorHandler.handleError(responseError);
    }

    logUploadFileError(fileWithError: File, errorMessage: string): void {
        const error = new HandledError({
            name: errorMessage,
            message: `${fileWithError.name} failed to upload. File type: ${fileWithError.type}. Filesize: ${fileWithError.size}`,
        });
        this.handleError(error);
    }

    trackAnalytics(statusCode: number): void {
        if (statusCode >= 400) {
            this.analytics.recordEvent("error", `app_error${statusCode}`);
        }
    }

    isJSError(err: any): err is Error {
        return err && err.name && err.message;
    }

    /**
     * filter errors we don't want in Sentry
     */
    sentryShouldIgnoreError(error: any): boolean {
        // 403s
        if (ErrorService.isHttpErrorResponse(error) && error.status === 403) {
            return true;
        }
        if (error?.name?.toLowerCase() === "chunkloaderror") {
            return false;
        }
        return false;
    }

    /**
     * filter errors we don't want to show to the user (but still want in Sentry)
     */
    private userShouldSeeError(error: any): boolean {
        // firefox/quill bug (seems harmless)
        if (this.isJSError(error) && error?.message === `Permission denied to access property "parentNode"`) {
            return false;
        }
        if (error?.name?.toLowerCase() === "chunkloaderror") {
            return false;
        }
        return true;
    }

    private unwrapError(error: any, errorDepth = 0): any {
        if (errorDepth > ErrorService.maxErrorDepth) {
            console.error("Error service self-reference loop");
            return error;
        }
        if (error instanceof HandledError) {
            return this.unwrapError(error.originalError, errorDepth++);
        }
        if (error.rejection) {
            return this.unwrapError(error.rejection, errorDepth++);
        }
        return error;
    }
}
