import { Component, Input, EventEmitter, Output } from "@angular/core";
import { AppUser } from "../../../findex-auth";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { Role } from "@visoryplatform/threads";
import { environmentCommon } from "../../../environment/environment.common";

@Component({
    selector: "user-profile-dropdown",
    templateUrl: "user-profile-dropdown.component.html",
    styleUrls: ["user-profile-dropdown.component.scss"],
})
export class UserProfileDropdownComponent {
    @Input() user: AppUser;
    @Input() showSwitchAccount = false;
    @Input() supportEmail: string;
    @Output() switchAccount = new EventEmitter();
    @Output() logout = new EventEmitter();

    readonly staffSupportUrl = environmentCommon.staffSupportUrl;

    constructor(private slideMenuService: MenuService) {}

    hideProfileMenu(): void {
        this.slideMenuService.hide(MenuType.Profile);
    }

    contactSupport(): void {
        if (this.user.globalRole === Role.Client) {
            window.location.href = `mailto:${this.supportEmail}`;
        } else {
            window.open(this.staffSupportUrl);
        }
        this.hideProfileMenu();
    }
}
