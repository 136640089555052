<centered-modal-layout
    [loading]="!!loading"
    [disableSave]="form.invalid"
    cancelButtonTitle="Close"
    saveButtonTitle="Update"
    (save)="update()"
    (close)="close()"
>
    <div modal-header>Billing contact</div>

    <div modal-content>
        <form
            [formGroup]="form"
            class="edit-billing-contact-details fx-form fx-form--dark"
        >
            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="name"
                    >Name</label
                >
                <div class="fx-form-control">
                    <input
                        autocomplete="name"
                        class="fx-form-input"
                        formControlName="name"
                        placeholder="John Doe"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['name'].valid && form.controls['name'].touched,
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                >
                    Name is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <!--        TODO: this is disabled pending ED-826 -->
            <!--        <div class="fx-form-group">-->
            <!--            <label class="fx-form-label" for="name">Email</label>-->
            <!--            <div class="fx-form-control">-->
            <!--                <input-->
            <!--                    autocomplete="email"-->
            <!--                    class="fx-form-input"-->
            <!--                    formControlName="email"-->
            <!--                    placeholder="John.doe@gmail.com"-->
            <!--                    [ngClass]="{-->
            <!--                        'fx-form-input--error': !form.controls['email'].valid && form.controls['email'].touched-->
            <!--                    }"-->
            <!--                />-->
            <!--            </div>-->
            <!--            <div-->
            <!--                class="fx-form-validation"-->
            <!--                *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"-->
            <!--            >-->
            <!--                Email is required.-->
            <!--                <i class="fx-form-validation-icon las la-exclamation-triangle"></i>-->
            <!--            </div>-->
            <!--        </div>-->

            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="address"
                    >Address</label
                >
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="address"
                        placeholder="300/171 Williams St"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['address'].valid && form.controls['address'].touched,
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched"
                >
                    Address is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="city"
                    >City</label
                >
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="city"
                        placeholder="Melbourne"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['city'].valid && form.controls['city'].touched,
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched"
                >
                    City is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="postcode"
                    >Postcode</label
                >
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="postcode"
                        placeholder="3000"
                        [ngClass]="{
                            'fx-form-input--error':
                                !form.controls['postcode'].valid && form.controls['postcode'].touched,
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['postcode'].hasError('required') && form.controls['postcode'].touched"
                >
                    Postcode is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="state"
                    >State</label
                >
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="state"
                        placeholder="Victoria"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['state'].valid && form.controls['state'].touched,
                        }"
                    />
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['state'].hasError('required') && form.controls['state'].touched"
                >
                    State is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
            <div class="fx-form-group">
                <label
                    class="fx-form-label"
                    for="country"
                    >Country</label
                >
                <div class="fx-form-control">
                    <select
                        class="fx-form-select"
                        formControlName="country"
                        [ngClass]="{
                            'fx-form-input--error': !form.controls['country'].valid && form.controls['country'].touched,
                        }"
                    >
                        <ng-container *ngFor="let country of acceptedCountries">
                            <option [ngValue]="country.countryCode">{{ country.name }}</option>
                        </ng-container>
                    </select>
                </div>
                <div
                    class="fx-form-validation"
                    *ngIf="form.controls['state'].hasError('required') && form.controls['state'].touched"
                >
                    State is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>

            <div
                class="edit-billing-contact-details-error"
                *ngIf="errorMessage"
            >
                {{ errorMessage }}
            </div>
        </form>
    </div>
</centered-modal-layout>
