export enum AuthorizationLevel {
    UNKNOWN = -1,
    UNAUTHORIZED = 0,
    NOMINAL = 1,
    VERIFIED = 2,
}

export const VALID_AUTHORIZATION_LEVELS = [AuthorizationLevel.NOMINAL, AuthorizationLevel.VERIFIED];

export const validateAuthorizationLevel = (value: number | string): AuthorizationLevel => {
    const numericValue = Number(value);
    if (VALID_AUTHORIZATION_LEVELS.includes(numericValue)) {
        return numericValue;
    }
    return AuthorizationLevel.UNKNOWN;
};
