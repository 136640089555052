<div class="participant">
    <div class="participant-user">
        <fx-avatar
            [name]="name"
            [image]="id | profilePicture | async"
            size="medium"
        ></fx-avatar>
        <div class="participant-user">
            <div class="participant-user-name">
                {{ name }}
            </div>
        </div>
    </div>
    <div class="participant-role">{{ role }}</div>
</div>
