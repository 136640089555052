import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { CardStatus, IThread, Role } from "@visoryplatform/threads";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { VaultService } from "@visoryplatform/vault";
import { Router } from "@angular/router";
import {
    ThreadsVaultService,
    VaultDocument,
} from "../../../../portal-modules/src/lib/threads-ui/services/threads-vault.service";
import { ROLE, THREAD } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { environmentCommon } from "projects/portal-modules/src/lib/environment/environment.common";
import { VaultCardDeleteItem, VaultCardRenameItem } from "../../types/EditCardRequests";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { fileSave, WellKnownDirectory } from "browser-fs-access";
import JSZip from "jszip";
import { GA_EVENTS, GA_EVENTS_PREFIX } from "projects/portal-modules/src/lib/analytics";
import { EmptyStateImageType } from "projects/portal-modules/src/lib/empty-state/components/empty-state.component";

enum DocumentListTableHeaders {
    Title = "Title",
    UploadedBy = "Uploaded by",
    DateAdded = "Date added",
    Status = "Status",
}

type VaultRow = VaultDocument & { renameMode: boolean };

@Component({
    selector: "document-list",
    templateUrl: "./document-list.component.html",
    styleUrls: ["./document-list.component.scss"],
})
export class DocumentListComponent implements OnInit, OnDestroy {
    readonly gaEvents = GA_EVENTS;
    readonly gaEventsPrefix = GA_EVENTS_PREFIX;
    readonly tableHeaders = DocumentListTableHeaders;
    readonly emptyStateImages = EmptyStateImageType;

    roles = Role;
    loader = new Loader();
    cardStatuses = CardStatus;

    documents$: Observable<VaultDocument[]>;
    userId$: Observable<string>;
    selectedDocuments: Set<VaultDocument>;
    tableData = new MatTableDataSource<VaultRow>();
    documentSubscription: Subscription;
    threadIdSubscription: Subscription;

    constructor(
        @Inject(THREAD) public thread$: Observable<IThread>,
        @Inject(ROLE) public role$: Observable<Role>,
        private router: Router,
        private vaultService: VaultService,
        private threadsVaultService: ThreadsVaultService,
        private cardService: ThreadCardService,
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.threadIdSubscription = this.thread$
            .pipe(
                map((thread) => thread.id),
                distinctUntilChanged(),
            )
            .subscribe(() => (this.selectedDocuments = null));

        this.documents$ = this.thread$.pipe(
            switchMap((thread) => this.threadsVaultService.getDocumentList(thread.id, this.loader)),
            map((vaultDocuments) => vaultDocuments.sort(this.sortDocuments)),
        );

        this.documentSubscription = this.documents$
            .pipe(map((documents) => documents.map((document) => ({ ...document, renameMode: false }))))
            .subscribe((documents) => (this.tableData.data = documents));

        this.userId$ = this.authService.getUserWithoutRole().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );
    }

    ngOnDestroy() {
        if (this.documentSubscription) {
            this.documentSubscription.unsubscribe();
        }

        if (this.threadIdSubscription) {
            this.threadIdSubscription.unsubscribe();
        }
    }

    getSelectedDocuments(selectedRows: Set<VaultDocument>) {
        this.selectedDocuments = selectedRows;
    }

    sign(threadId: string, cardId: string, vaultId: string, fileId: string) {
        this.router.navigate([`/timelines/${threadId}/cards/${cardId}`], {
            queryParams: {
                vaultId,
                fileId,
            },
        });
    }

    async renameDocument(threadId: string, document: VaultRow, displayName: string) {
        document.renameMode = false;

        if (document.title === displayName) {
            return;
        }

        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardRenameItem = {
                vaultId: document.vaultId,
                fileId: document.fileId,
                editAction: "rename",
                displayName,
            };

            await this.cardService.editCard<VaultCardRenameItem>(threadId, vault, document.card.id, data).toPromise();
        } finally {
            this.loader.hide();
        }
    }

    async deleteDocument(threadId: string, document: VaultDocument) {
        const { vault } = environmentCommon.cardsEndpoints;

        this.loader.show();
        try {
            const data: VaultCardDeleteItem = {
                vaultId: document.vaultId,
                fileId: document.fileId,
                editAction: "delete",
            };

            await this.cardService.editCard<VaultCardDeleteItem>(threadId, vault, document.card.id, data).toPromise();
            const newTableData = [...this.tableData.data].filter((doc) => doc.fileId !== document.fileId);
            this.tableData = new MatTableDataSource(newTableData);
        } finally {
            this.loader.hide();
        }
    }

    async download(document: VaultDocument) {
        const downloadWindow = window.open("", "_self");
        const downloadUrl = await this.getDownloadUrl(document);
        downloadWindow.location.href = downloadUrl;
    }

    async downloadAll(document: VaultDocument, documents?: Set<VaultDocument>) {
        if (documents?.size) {
            await this.zipDownload([...documents]);
        } else {
            await this.download(document);
        }
    }

    sortDocuments(documentA: VaultDocument, documentB: VaultDocument): number {
        return Date.parse(documentB.timestamp) - Date.parse(documentA.timestamp);
    }

    trackDocument(_index: number, document: VaultDocument): string {
        return `${document.vaultId}/${document.fileId}/${document.filename}`;
    }

    private async zipDownload(documents: VaultDocument[]) {
        this.loader.show();

        try {
            const files = await Promise.all(documents.map((document) => this.downloadFile(document)));

            const zip = new JSZip();
            for (const file of files) {
                zip.file(file.filename, file.data);
            }
            const zipBlob = await zip.generateAsync({ type: "blob" });

            await this.saveFile("files.zip", zipBlob);
        } finally {
            this.loader.hide();
        }
    }

    private async saveFile(fileName: string, blob: Blob): Promise<void> {
        this.loader.show();

        try {
            const extensions = ["." + fileName.split(".").pop()];
            const startIn: WellKnownDirectory = "downloads";
            const config = { fileName, extensions, startIn };
            await fileSave(blob, config);
        } catch (err) {
            // console.log("File save", err);
        } finally {
            this.loader.hide();
        }
    }

    private async downloadFile(document: VaultDocument): Promise<{ filename: string; data: Blob }> {
        const url = await this.getDownloadUrl(document);
        const data = await this.httpClient.get(url, { responseType: "blob" }).toPromise();

        return {
            filename: `${document.title}${document.title !== document.filename ? " " + document.filename : ""}`,
            data,
        };
    }

    private async getDownloadUrl(document: VaultDocument): Promise<string> {
        this.loader.show();
        const { vaultId, fileId, filename } = document;

        try {
            return await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
        } finally {
            this.loader.hide();
        }
    }
}
