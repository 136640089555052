import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { Query, QueryVault } from "@visoryplatform/copilot";
import { VaultService } from "@visoryplatform/vault";
import { WindowListenersService } from "projects/portal-modules/src/lib/shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { distinctUntilChanged, take } from "rxjs/operators";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

const CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AnswerFieldComponent),
    multi: true,
};

@Component({
    selector: "answer-field",
    templateUrl: "./answer-field.component.html",
    styleUrls: ["./answer-field.component.scss"],
    providers: [CONTROL_VALUE_ACCESSOR],
})
export class AnswerFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Output() uploadFile = new EventEmitter<File>();
    @Output() deleteFile = new EventEmitter<void>();
    @Output() clearAnswer = new EventEmitter<void>();

    answer = new FormControl("");
    vaultDetails?: QueryVault;

    quillError: boolean;

    isMobileView = false;
    loader = new Loader();
    readonly messageSizeQuotaInKB = 128;

    private onChange?: (query: Partial<Query>) => void;
    private onTouch?: () => void;
    private valSub?: Subscription;

    constructor(
        private vaultService: VaultService,
        windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        this.isMobileView = windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
    }

    ngOnInit(): void {
        this.valSub = this.answer.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
            this.onTouch?.();
            this.onChange?.({
                vaultDetails: this.vaultDetails,
                answer: val,
            });
        });
    }

    ngOnDestroy(): void {
        this.valSub?.unsubscribe();
    }

    writeValue(query: Partial<Query>): void {
        this.vaultDetails = query?.vaultDetails;
        this.answer.setValue(query?.answer, { emitEvent: false });
    }

    registerOnChange(fn: (query: Partial<Query>) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.answer.disable();
        } else {
            this.answer.enable();
        }
    }

    download(vaultDetails?: QueryVault): void {
        const { vaultId, fileId, filename } = vaultDetails;
        const downloadWindow = window.open("", "_self");

        this.loader
            .wrap(this.vaultService.getDownloadUrl(vaultId, fileId, filename))
            .pipe(take(1))
            .subscribe((url) => {
                downloadWindow.location.href = url;
            });
    }
}
