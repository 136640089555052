import { Component, Input } from "@angular/core";
import { RequestProgressTypes, RequestStatuses } from "../constants/request.constants";

@Component({
    selector: "request-header",
    templateUrl: "./request-header.component.html",
    styleUrls: ["./request-header.component.scss"],
})
export class RequestHeaderComponent {
    @Input() isComplete: boolean;
    @Input() title: string;
    @Input() completedStatusLabel: RequestStatuses;
    @Input() subTitle: string;
    @Input() accountName: string;

    readonly REQUEST_STATUSES = RequestStatuses;
    readonly requestProgress = RequestProgressTypes;
}
