<ng-container *ngIf="greeting$ | async; let greeting">
    <div class="dashboard-greeting-banner">
        <div class="dashboard-greeting-banner-text">
            {{ greeting }} <span class="dashboard-greeting-banner-text-emoji">👋</span>
        </div>
        <div
            class="dashboard-greeting-banner-request"
            *ngIf="canCreateNewRequest$ | async"
        >
            <div class="dashboard-greeting-banner-request-text">
                <h4 class="dashboard-greeting-banner-request-title">Have a question or request?</h4>
                <div class="dashboard-greeting-banner-request-subtitle">
                    Create a request for your {{ appName }} team
                </div>
            </div>
            <button
                [analyticsClick]="gaEvents.APP_NEW_REQUEST"
                class="fx-btn fx-btn--primary dashboard-greeting-banner-request-button"
                type="button"
                (click)="newRequest()"
            >
                <i class="las la-plus dashboard-greeting-banner-request-button-icon"></i>
                New request
            </button>
        </div>
    </div>
</ng-container>
