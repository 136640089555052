<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="state$ | async as state">
        <action-request
            [analyticsTags]="analyticsTags"
            [completeButtonText]="completeButtonText"
            [requiredReviewPermissions]="requiredReviewPermissions"
            [modalData]="modalData"
            [form]="form"
            [thread$]="thread$"
            [readonly]="readonly || card.isInternal"
            [card$]="card$"
            [replies]="replies$ | async"
            [state]="state"
            [userId$]="userId$"
            [currentUserRole]="currentUserRole"
            [completedStatusLabel]="requestStatuses.Approved"
            [actionedRequestItems]="actionedRequestItems"
            [actionedPercentage]="actionedPercentage"
        >
            <section *ngIf="thread$ | async as thread">
                <payrun-report-action-request
                    [state$]="state$"
                    [threadId]="thread.id"
                    [cardId]="card.id"
                    [role]="currentUserRole"
                    [readonly]="!(canUpdatePayrunItem$ | async)"
                    [card$]="card$"
                    (updateControl)="updatePayrun($event, analyticsTags.analyticsPrefix)"
                    (approveAll)="approveAllPayrun(card.id, thread.id, state)"
                    (requestItemsCompletedChanges)="requestItemsCompletedChanges($event)"
                ></payrun-report-action-request>
            </section>
        </action-request>
    </ng-container>
</ng-container>
