import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ITimeline, Role, WorkflowCurrentStepService, WorkflowStepType } from "@visoryplatform/threads";
import { MatDialog } from "@angular/material/dialog";
import { AssigneeType, IWorkflow } from "@visoryplatform/workflow-core";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { ThreadStateModalComponent } from "../thread-state-modal/thread-state-modal.component";
import { WorkflowStepsService } from "../../../threads-ui/services/workflow/workflow-steps.service";
import { Router } from "@angular/router";

interface IActiveStep {
    title: string;
    isOverdue: boolean;
    isStepOpen: boolean;
    id: string;
}

@Component({
    selector: "thread-workflow-progress-indicator",
    templateUrl: "./thread-workflow-progress-indicator.component.html",
    styleUrls: ["./thread-workflow-progress-indicator.component.scss"],
})
export class ThreadWorkflowProgressIndicatorComponent implements OnChanges {
    @Input() workflow: IWorkflow;
    @Input() small: boolean;
    @Input() enablePopUp: boolean;
    @Input() showIcon: boolean;
    @Input() role: Role;
    @Input() thread: ITimeline;
    @Input() shouldShowTitle: boolean;
    @Input() fullWidth: boolean;
    @Input() isInternal: boolean;
    @Input() enableDotStepper: boolean;
    @Input() hideDotStepperOnMobile: boolean;
    @Input() overrideStepperTitleColor: boolean;

    readonly stepTypes = WorkflowStepType;
    readonly gaEvents = GA_EVENTS;

    activeWorkflowSteps: IWorkflow;
    currentStep: IActiveStep;
    roles = Role;
    showPopUp: boolean;
    workflowIsActive: boolean;
    hasSteps: boolean;
    currentStepIndex: number;
    totalSteps: number;

    constructor(
        private workflowStepsService: WorkflowStepsService,
        private dialog: MatDialog,
        private router: Router,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { workflow, thread, isInternal } = changes;
        const isInputChange = workflow || thread || isInternal;
        const workflowCurrentStepId = this.workflow?.currentStepId;

        if (isInputChange && workflowCurrentStepId && this.thread) {
            const currentStep = this.findCurrentStep(this.thread?.createdAt, this.workflow);
            const workflowStep = this.workflow.steps[this.workflow.currentStepId];
            const assigneeType = this.getAssigneeType(this.isInternal);
            const progress = WorkflowCurrentStepService.getWorkflowProgress(
                this.workflow,
                currentStep?.id,
                assigneeType,
            );

            this.currentStep = currentStep;
            this.totalSteps = progress?.total;
            this.currentStepIndex = progress?.current;
            this.workflowIsActive = WorkflowCurrentStepService.isStepOpen(workflowStep);
            this.hasSteps = WorkflowCurrentStepService.hasSteps(this.workflow);
        } else {
            this.workflowIsActive = false;
        }
    }

    async manageStatus(): Promise<void> {
        if (!this.enablePopUp) {
            await this.router.navigate(["/timelines", this.thread.id]);
            return;
        }

        const config = {
            data: {
                thread: this.thread,
                role: this.role,
            },
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container", "threads-sidebar", "mat-dialog-no-styling"],
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
        };
        this.dialog.open(ThreadStateModalComponent, config);
    }

    private getAssigneeType(isInternal: boolean): AssigneeType {
        return isInternal ? AssigneeType.Internal : AssigneeType.External;
    }

    private findCurrentStep(startDate: string, workflow: IWorkflow): IActiveStep {
        if (!workflow?.steps || !startDate) {
            return null;
        }

        const entries = this.workflowStepsService.getUpdatedStepEntries(startDate, workflow);
        const currentEntry = entries.find((entry) => entry?.step?.id === workflow.currentStepId);

        if (!currentEntry) {
            console.warn("Could not find workflow step for status indicator", workflow?.currentStepId);
            return null;
        }

        const title = currentEntry?.step?.label;
        const isOverdue = currentEntry?.isOverdue;
        const currentStepId = currentEntry?.step?.id;
        const isStepOpen = WorkflowCurrentStepService.isStepOpen(currentEntry?.step);

        return { title, isOverdue, isStepOpen, id: currentStepId };
    }
}
