import { Injectable } from "@angular/core";
import { IWorkflowDesignType } from "@visoryplatform/threads";
import { UniqueThreadType } from "../modules/create-thread/types/UniqueThreadType";

@Injectable({ providedIn: "root" })
export class ThreadsHelperService {
    getUniqueTypes(designTypes: IWorkflowDesignType[]): UniqueThreadType[] {
        const uniqueTypes = designTypes
            .map(({ threadTypeLabel, threadType }) => {
                const unique: UniqueThreadType = {
                    threadType,
                    threadTypeLabel,
                };

                return unique;
            })
            .filter(
                (uniqueType, index, allTypes) =>
                    allTypes.findIndex((allType) => allType.threadType === uniqueType.threadType) === index,
            );

        return uniqueTypes;
    }
}
