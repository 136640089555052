import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { ActorId, CardReply, CardStatus, IThread, IThreadCard, ReadStatus } from "@visoryplatform/threads";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../../findex-auth";
import { ViewportObserverService } from "../../services/viewport-observer.service";
import { VaultCardType } from "../../../../../../default-plugins/vault/components/request/constants/request.constants";
import { UiCardService } from "../../services/ui-card.service";
import { TaskNotificationsService } from "../../../notifications";

@Component({
    selector: "thread-card",
    templateUrl: "./thread-card.component.html",
    styleUrls: ["./thread-card.component.scss"],
})
export class ThreadCardComponent implements OnChanges, OnInit {
    @Input() thread: IThread;
    @Input() card: IThreadCard;
    @Input() replies: CardReply[];
    @Input() title: string;
    @Input() name: string;
    @Input() description: string;
    @Input() invertTheme: boolean;
    @Input() loading: boolean;
    @Input() edited: boolean | Subject<boolean>;
    @Input() edit: Subject<boolean>;
    @Input() inCardReplies = true;
    @Input() isInternal: boolean;
    @Input() showDescription = true;

    @Output() save = new EventEmitter<any>();
    @Output() openRepliesInModal = new EventEmitter<void>();

    readonly cardTypes = VaultCardType;
    readonly ActorId = ActorId;

    cardStatuses = CardStatus;
    cardVisible$: Observable<boolean>;
    userId$: Observable<string>;
    isUnseenByCurrentUser$: Observable<boolean>;
    isUnreadByCurrentUser$: Observable<boolean>;
    cardContentReadStatus$: Observable<ReadStatus>;
    cardSeenBy$: Observable<string[]>;
    cardSource = new ReplaySubject<IThreadCard>(1);
    threadSource = new ReplaySubject<IThread>(1);

    constructor(
        private authService: AuthService,
        private uiCardService: UiCardService,
        viewportObserver: ViewportObserverService,
        elm: ElementRef,
        private taskNotificationsService: TaskNotificationsService,
    ) {
        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        this.cardVisible$ = viewportObserver.observe(elm).pipe(
            filter((visible) => !!visible),
            take(1),
            shareReplay(1),
        );
    }

    ngOnInit(): void {
        const card$ = this.cardSource.asObservable();
        const thread$ = this.threadSource.asObservable();

        this.cardSeenBy$ = card$.pipe(map((card) => this.getCardSeenBy(card)));
        this.setCardSeenStatus(card$);
        this.setCardReadStatus(thread$, card$);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { thread, card } = changes;

        if ((thread || card) && this.thread && this.card) {
            this.cardSource.next(this.card);
            this.threadSource.next(this.thread);
        }
    }

    private getCardSeenBy(card: IThreadCard): string[] {
        if (!card.seenIndicators) {
            return [];
        }

        return card.seenIndicators?.map((seenBy) => seenBy.participant);
    }

    private setCardSeenStatus(card$: Observable<IThreadCard>): void {
        this.isUnseenByCurrentUser$ = combineLatest([this.userId$, card$]).pipe(
            map(([userId, card]) => this.uiCardService.isCardUnseenByCurrentUser(this.thread, card, userId)),
            shareReplay(1),
        );
    }

    private setCardReadStatus(thread$: Observable<IThread>, card$: Observable<IThreadCard>): void {
        const threadId$ = thread$.pipe(
            take(1),
            map((thread) => thread.id),
        );
        const cardId$ = card$.pipe(
            take(1),
            map((card) => card.id),
        );
        const cardContentReadStatus$ = combineLatest([threadId$, cardId$]).pipe(
            switchMap(([threadId, cardId]) => {
                const readStatus$ = this.taskNotificationsService.getCardReadStatus(threadId, cardId);
                return readStatus$.pipe(
                    switchMap((readStatus) =>
                        this.uiCardService.getCardContentReadStatusUpdates(readStatus.card, threadId, cardId),
                    ),
                );
            }),
            shareReplay(1),
        );

        this.cardContentReadStatus$ = cardContentReadStatus$;
        this.isUnreadByCurrentUser$ = combineLatest([cardContentReadStatus$, this.userId$]).pipe(
            map(([cardContentReadStatus, userId]) => {
                const isUnresolved = this.getIsUnresolved(cardContentReadStatus, userId);
                const isNotCreatedBySystem = this.card.createdBy !== ActorId.System;
                return isUnresolved && isNotCreatedBySystem;
            }),
            shareReplay(1),
        );
    }

    private getIsUnresolved(cardContentReadStatus: ReadStatus, userId: string): boolean {
        return cardContentReadStatus?.unresolved?.participantIds?.includes(userId);
    }
}
