import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Tenant } from "@visoryplatform/threads";

@Component({
    selector: "tenant-select",
    templateUrl: "./tenant-select.component.html",
    styleUrls: ["./tenant-select.component.scss"],
})
export class TenantSelectComponent {
    @Input() tenants: Tenant[];
    @Input() loading: boolean;
    @Input() enabled: boolean;
    @Output() selectTenant = new EventEmitter<Tenant | null>();

    tenantControl = new FormControl("", [Validators.required]);

    onSelectTenant(tenants: Tenant[], tenantId: string): void {
        const tenant = tenants.find((obj) => obj.id.toString() === tenantId);
        this.selectTenant.emit(tenant);
    }
}
