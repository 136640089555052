import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    EnvironmentSpecificConfig,
    environmentCommon,
} from "../../../portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { Observable } from "rxjs";

export type TextAnalysisResult = {
    message: string;
    querySimilarity: number;
    accountId: string;
    threadId: string;
    cardId: string;
    actorId: string;
    createdAt: string;
    threadTitle: string;
    threadType: string;
    workflowId: string;
    accountLabel: string;
    actorName: string;
};

@Injectable()
export class VectorSearchService {
    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig, private http: HttpClient) {}

    search(search: Record<string, any>): Observable<TextAnalysisResult[]> {
        const { assistant, messages } = environmentCommon.assistantEndpoints;
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}${assistant}${messages}`;
        const cleanFilters = Object.entries(search).filter(([, val]) => !!val);
        const params = Object.fromEntries(cleanFilters);
        return this.http.get<TextAnalysisResult[]>(url, { params });
    }
}
