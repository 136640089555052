<div class="insights-permissions">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <ng-container *ngIf="userId$ | async as userId">
        <fx-container
            [fluid]="true"
            *ngIf="enterpriseListings$ | async as enterprises"
        >
            <ng-container *ngIf="enterprises?.length; else noEnterprises">
                <div class="insights-permissions-options">
                    <div class="insights-permissions-options-add">
                        <button
                            (click)="addEnterprise(userId, enterprises)"
                            class="fx-btn fx-btn--primary"
                        >
                            ADD ENTERPRISE
                        </button>
                    </div>
                </div>

                <div class="insights-permissions-list">
                    <fx-accordion
                        class="insights-permissions-list-toggle"
                        *ngFor="let enterpriseListing of enterprises; trackBy: 'id' | trackProperty"
                        [toggleGutters]="true"
                        [isExpanded]="false"
                    >
                        <div
                            accordion-header
                            class="insights-permissions-list-toggle-header"
                        >
                            {{ enterpriseListing?.label }}
                            <i class="fx-accordion-toggle-icon las la-angle-down"></i>
                        </div>
                        <div
                            accordion-content
                            class="insights-permissions-list-toggle-content"
                        >
                            <enterprise-permissions
                                [enterprise]="enterpriseListing.enterprise$ | async"
                                [entities]="enterpriseListing.entities$ | async"
                                [permissions]="enterpriseListing.permissions$ | async"
                                (save)="savePermissions(enterpriseListing.id, userId, $event)"
                                (delete)="removeEnterprise(enterpriseListing.id, userId)"
                            >
                            </enterprise-permissions>
                        </div>
                    </fx-accordion>
                </div>
            </ng-container>

            <ng-template #noEnterprises>
                <div class="insights-permissions-empty">
                    <h2>No enterprises have been added yet</h2>
                    <p>Once you add an enterprise, you can start managing their permissions.</p>

                    <div class="insights-permissions-empty-add">
                        <button
                            (click)="addEnterprise(userId, enterprises)"
                            class="fx-btn fx-btn--primary"
                        >
                            ADD ENTERPRISE
                        </button>
                    </div>
                </div>
            </ng-template>
        </fx-container>
    </ng-container>
</div>
