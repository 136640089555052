import { Component, Input } from "@angular/core";

@Component({
    selector: "bill-approval-summary",
    templateUrl: "./bill-approval-summary.component.html",
    styleUrls: ["./bill-approval-summary.component.scss"],
})
export class BillApprovalSummaryComponent {
    @Input() plannedDate: string;
    @Input() totalBills: number;
    @Input() totalApprovedBills: number;
    @Input() currencyCode: string;
}
