<header class="fx-header">
    <div class="fx-header-left-items">
        <a
            class="fx-header-menu-toggle"
            (click)="menu.emit(!layoutOpen); $event.stopPropagation()"
            *ngIf="showMenuToggle"
        >
            <i
                class="las la-bars"
                [ngClass]="{ 'la-times': layoutOpen }"
            ></i>
        </a>
        <portal-brand type="menu"></portal-brand>

        <div
            *ngIf="stage === 'uat'"
            class="fx-header-uat-indicator"
        >
            <span class="fx-header-uat-indicator-desktop">User Acceptance Test</span>
            <span class="fx-header-uat-indicator-mobile">UAT</span>
        </div>
        <div
            class="fx-header-uat-indicator"
            *ngIf="stage === 'qa'"
        >
            <span class="fx-header-uat-indicator-desktop">Quality Assurance</span>
            <span class="fx-header-uat-indicator-mobile">QA</span>
        </div>
    </div>

    <div class="fx-header-middle-items">
        <global-search></global-search>
    </div>

    <div class="fx-header-right-items">
        <ng-content></ng-content>
    </div>
</header>
