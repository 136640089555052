import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";

@Component({
    selector: "login-dialog",
    templateUrl: "./login-dialog.component.html",
    styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent implements OnInit {
    constructor(private dialogRef: MatDialogRef<LoginDialogComponent>, private analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.recordAnalyticsEvent("displayed");
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("login", category);
    }

    dialogClosed() {
        this.dialogRef.close();
    }
}
