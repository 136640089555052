<div
    class="thread-search"
    [ngClass]="{ 'thread-search--active': expandSearch || !isMobileView }"
>
    <div class="fx-form-control">
        <div
            class="fx-form-icon"
            (click)="setActive(true)"
        >
            <i class="las la-search"></i>
        </div>
        <ng-container [formGroup]="formGroup">
            <input
                #search
                class="fx-form-input threads-filter-search"
                [placeholder]="placeholder"
                [attr.aria-label]="placeholder"
                [analyticsClick]="analyticsClickEvent"
                type="text"
                formControlName="search"
                (input)="updateSearchTerm($event.target.value)"
            />
        </ng-container>
        <div
            *ngIf="expandSearch && isMobileView && !hideCloseButton"
            (click)="closeSearch()"
            class="thread-search-close"
        >
            <i class="las la-times cross-icon"></i>
        </div>
    </div>
</div>
