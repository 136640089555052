import { Pipe, PipeTransform } from "@angular/core";
import { ITimeline, Role } from "@visoryplatform/threads";

@Pipe({
    name: "threadRole",
})
export class ThreadRolePipe implements PipeTransform {
    transform(thread: ITimeline, participantId: string): Role {
        return thread?.participants?.find((participant) => participant.id === participantId)?.role;
    }
}
