<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<div
    *ngFor="let reply of repliesUi$ | async; trackBy: replyTrack"
    [unreadStatus]="reply.isUnresolved"
    class="thread-card-replies-message"
>
    <reply
        #replyComponents
        [reply]="reply"
        [thread]="thread"
        [card]="card"
        [userId]="userId"
        [readonly]="readonly"
        [notificationChannel]="reply.channel"
        [enableMarkAsResolved]="reply.isUnresolved"
        [isUnread]="reply.isUnresolved"
        (replyLoading)="toggleReplyLoader($event)"
    ></reply>
</div>
