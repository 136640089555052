<ng-container *ngIf="{ card: card$ | async, state: state$ | async, thread: thread$ | async } as context">
    <ng-container *ngIf="context.state && context.card">
        <ng-container
            *ngIf="{
                isCompleted: context.state?.isCompleted
            } as cardStatus"
        >
            <ng-container *ngIf="userId$ | async as userId">
                <thread-card
                    [title]=""
                    [thread]="thread$ | async"
                    [card]="card$ | async"
                    [loading]="!!loader.counter"
                    [inCardReplies]="false"
                    [isInternal]="true"
                >
                    <div
                        (click)="openInstructionsModal()"
                        [ngClass]="{ 'instructions-panel--active': isThreadActive$ | async }"
                        class="instructions-panel"
                    >
                        <div class="instructions-panel-header">
                            <h4 class="instructions-heading">{{ context.state?.title }}</h4>
                            <fx-badge
                                [label]="cardStatus.isCompleted ? 'Completed' : 'Pending'"
                                [type]="cardStatus.isCompleted ? 'success' : 'default'"
                            ></fx-badge>
                        </div>

                        <div class="instructions-info">
                            <div class="instructions-info-progress">
                                <progress-bar [progress]="actionedPercentage"></progress-bar>
                                <span class="instructions-info-actioned">
                                    {{ actionedInstructions }} /
                                    {{ context.state?.requestItems?.length }} actioned</span
                                >
                            </div>
                            <div
                                *ngIf="showAttachments"
                                class="instructions-info-icons"
                            >
                                <i class="las la-paperclip"></i>
                                <span>{{
                                    (context.state?.attachments?.data?.length || 0) + requestItemUploadResponses
                                }}</span>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="canActionInstructions$ | async">
                        <button
                            class="instructions-button fx-btn fx-btn--primary"
                            (click)="openInstructionsModal()"
                        >
                            REVIEW
                        </button>
                    </ng-container>

                    <div card-loader>
                        <loader-icon color="light"></loader-icon>
                    </div>
                </thread-card>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
