import { Subject } from "rxjs";
import { ExtensionEntry, ILibrary } from "../interfaces/ILibrary";

type Registry<ExtensionType> = {
    [type: string]: ExtensionType;
};

export class RegistryLibrary<ExtensionType> implements ILibrary<ExtensionType> {
    extensionAdded: Subject<ExtensionEntry<ExtensionType>> = new Subject();

    private registry: Registry<ExtensionType> = {};

    register(id: string, extension: ExtensionType): void {
        if (!id) {
            console.error("Extension id is invalid");
            return;
        }

        if (this.registry[id]) {
            console.warn("Registering extension twice", id);
        }

        this.registry[id] = extension;
        this.extensionAdded.next({ id, extension });
    }

    resolve(id: string): ExtensionType {
        const extension = this.registry[id];

        if (!extension) {
            console.warn("No extensions registered for", id);
            return null;
        }

        return extension;
    }

    listAll(): { id: string; extension: ExtensionType }[] {
        return Object.entries(this.registry).map(([id, extension]) => ({ id, extension }));
    }
}
