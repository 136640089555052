import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActionableCardComponent } from "../../../../portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { Observable, Subscription } from "rxjs";
import { Loader } from "../../../../portal-modules/src/lib/shared/services/loader";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "../../../../../src/app/injection-token";
import { CardResources, THREAD_CARD_RESOURCES } from "../../../../portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ILibrary, TaskAction } from "../../../../portal-modules/src/lib/plugins";
import { PermissionService } from "../../../../portal-modules/src/lib/threads-ui/services/permissions.service";
import { TaskActionService } from "../../../../portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { CopilotActions } from "../../types/CopilotActions";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { CardReply, CopilotTransaction, CopilotTransactionsState } from "@visoryplatform/threads";
import { map } from "rxjs/operators";

@Component({
    selector: "transactions-card",
    templateUrl: "./transactions-card.component.html",
    styleUrls: ["./transactions-card.component.scss"],
})
export class TransactionCardComponent extends ActionableCardComponent<boolean> implements OnDestroy, OnInit {
    readonly TRANSACTIONS_VIEW = CopilotActions.ViewTransactionQueries;
    readonly TRANSACTIONS_EDIT = CopilotActions.EditTransactionQueries;
    readonly allowEdit = this.environment.featureFlags.editCardDescription;

    hasPermissionToAction$: Observable<boolean>;
    hasPermissionToEdit$: Observable<boolean>;
    isThreadActive$: Observable<boolean>;
    loader = new Loader();
    requestItemUploadResponses = 0;

    replies$: Observable<CardReply[]>;
    actionTotal$: Observable<number>;
    actionedPercentage$: Observable<number>;
    actionAmmount$: Observable<number>;
    isComplete$: Observable<boolean>;

    private navigationSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources<any>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<boolean>>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        protected taskActionService: TaskActionService,
        private cardsService: ThreadCardService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        const { role, replies$, state$ } = this.cardResources;

        this.isComplete$ = this.getTransactionStatus(state$);
        this.actionAmmount$ = this.getTotalTransactionAmount(state$);
        this.actionTotal$ = this.getTotalCompletedTransactionAmount(state$);
        this.actionedPercentage$ = this.getCompletedTransactionPercentage(state$);

        this.hasPermissionToAction$ = this.permissionService.checkPermissions(role, "ReadCoilotTransactionsCard");
        this.hasPermissionToEdit$ = this.permissionService.checkPermissions(role, "UpdateCoilotTransactionsCard");
        this.replies$ = replies$;

        if (!this.navigationSub) {
            this.navigationSub = this.cardResources.navigateTo$.subscribe(() => this.openModal(false));
        }
    }

    ngOnDestroy(): void {
        this.navigationSub?.unsubscribe();
    }

    async openModal(focusReplyInput: boolean): Promise<void> {
        await this.action(this.TRANSACTIONS_VIEW, { focusReplyInput });
    }

    deleteCard(threadId: string, cardId: string): void {
        this.loader.wrap(this.cardsService.deleteCard(threadId, cardId)).subscribe();
    }

    private getTransactionStatus(state$: Observable<CopilotTransactionsState>): Observable<boolean> {
        return state$.pipe(map((state) => state.isComplete));
    }

    private getTotalTransactionAmount(state$: Observable<CopilotTransactionsState>): Observable<number> {
        return state$.pipe(map((state) => this.getCompletedTransactionAmount(state.transactions).length));
    }

    private getCompletedTransactionPercentage(state$: Observable<CopilotTransactionsState>): Observable<number> {
        return state$.pipe(
            map((state) => {
                const transactions = state.transactions;
                const completed = this.getCompletedTransactionAmount(transactions);
                return Math.floor((completed.length / transactions.length) * 100);
            }),
        );
    }

    private getCompletedTransactionAmount(transactions: CopilotTransaction[]): CopilotTransaction[] {
        return transactions.filter(
            (transaction) => transaction.query.answer || transaction.query.vaultDetails?.filename,
        );
    }

    private getTotalCompletedTransactionAmount(state$: Observable<CopilotTransactionsState>): Observable<number> {
        return state$.pipe(map((state) => state.transactions.length));
    }
}
