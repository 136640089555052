<div class="fx-onboarding-modal">
    <div class="fx-onboarding-modal-image">
        <img src="./assets/images/onboarding-modal/sigma-select-account-executive.svg" />
    </div>
    <div class="fx-onboarding-modal-content">
        <h1 class="fx-onboarding-modal-title">Select your Account Executive</h1>

        <div
            class="slider"
            *ngIf="!loader.counter"
        >
            <div class="slider-slides">
                <div
                    class="slider-slides-item"
                    [id]="'slide-' + i"
                    *ngFor="let staffProfile of accountExecutives; let i = index"
                >
                    <div class="staff-card">
                        <div class="staff-card-content">
                            <app-staff-detail [staffProfile]="staffProfile"></app-staff-detail>
                            <div class="staff-card-buttons">
                                <button
                                    class="fx-btn fx-btn--white"
                                    type="submit"
                                    (click)="requestChat(staffProfile, 'message')"
                                    analyticsClick="onboarding_messagestaff"
                                >
                                    Message
                                </button>
                                <button
                                    class="fx-btn fx-btn--primary fx-btn--primary-outlined"
                                    type="submit"
                                    (click)="requestChat(staffProfile, 'call')"
                                    analyticsClick="onboarding_schedulemeeting"
                                >
                                    Book meeting
                                </button>
                            </div>
                            <div class="slider-slides-bg"></div>
                        </div>
                    </div>
                </div>
            </div>

            <button
                *ngIf="accountExecutives && accountExecutives.length > 1"
                class="slider-next"
                [disabled]="currentSlide === accountExecutives.length - 1"
                (click)="nextSlide()"
            >
                <i class="las la-angle-right"></i>
            </button>

            <button
                *ngIf="accountExecutives && accountExecutives.length > 1"
                class="slider-prev"
                [disabled]="currentSlide === 0"
                (click)="prevSlide()"
            >
                <i class="las la-angle-left"></i>
            </button>
        </div>
        <p
            *ngIf="error"
            class="error-message"
        >
            {{ error }}
        </p>
        <loader-icon
            [static]="true"
            color="light"
            *ngIf="loader.counter"
        ></loader-icon>
    </div>
</div>
