import { Pipe, PipeTransform } from "@angular/core";
import { Account } from "@visoryplatform/threads";

import { AccountsService } from "./../services/accounts.service";

@Pipe({ name: "getAccountDetails" })
export class GetAccountDetailsPipe implements PipeTransform {
    constructor(private accountService: AccountsService) {}

    async transform(accountId: string): Promise<Account> {
        if (!accountId) {
            return null;
        }
        return await this.accountService.getAccount(accountId).toPromise();
    }
}
