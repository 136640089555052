<v-badge-round [color]="badgeColor">
    <i
        *ngIf="!isDelphiGenerated; else aiIcon"
        [tuiHint]="threadType | sentencecase"
        class="las la-project-diagram"
    >
    </i>
    <ng-template #aiIcon>
        <i
            [tuiHint]="threadType | sentencecase"
            class="ai-icon"
        ></i>
    </ng-template>
</v-badge-round>
