<div
    *ngIf="workflowTokenControls?.length"
    [formGroup]="form"
>
    <div *ngFor="let control of workflowTokenControls; let i = index">
        <div class="fx-form-control">
            <label class="fx-form-label">{{ control.controlLabel }}:</label>
            <div class="v-select-container">
                <v-select
                    [placeholder]="selectPackagePlaceholder"
                    [options]="control.tokens"
                    [optionContent]="tokenOptionContent"
                    [tuiTextfieldCleaner]="false"
                    [disabled]="workflowPackagesReadOnly"
                    [formControlName]="'workflowTokenId' + i"
                    (selectionChanged)="onWorkflowTokenSelected($event, 'workflowTokenId' + i)"
                >
                </v-select>

                <ng-template
                    #tokenOptionContent
                    let-option
                >
                    {{ option.label }}
                </ng-template>
            </div>
        </div>
    </div>
</div>
