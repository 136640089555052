import { Injectable } from "@angular/core";
import { AccountsService } from "./accounts.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Loader } from "../../shared/services/loader";
import { Account } from "@visoryplatform/threads";

@Injectable({ providedIn: "root" })
export class UpcomingMeetingService {
    private fifteenMinutes = 15 * 60 * 1000;

    constructor(private accountService: AccountsService, private router: Router, private loader: Loader) {}

    navigateToMeeting(accountId: string, threadId: string, cardId: string, joinMeeting: boolean): Observable<boolean> {
        const route = this.loader.wrap(this.accountService.getAccount(accountId)).pipe(
            switchMap((account) => {
                return this.getMeetingCardIdPath(account, accountId, threadId, cardId);
            }),
            switchMap((path) =>
                this.router.navigate(path, {
                    queryParams: { join: joinMeeting, ignoreModalOpen: true },
                }),
            ),
        );

        return route;
    }

    isMeetingStartingInFifteenMinutes(meetingStart: number, now: number): boolean {
        const timeUntilMeetingBegins = this.timeUntilMeetingStarts(meetingStart, now);
        return timeUntilMeetingBegins <= this.fifteenMinutes;
    }

    isMeetingInProgress(meetingStart: number, meetingEnd: number, now: number): boolean {
        return now >= meetingStart && now <= meetingEnd;
    }

    timeUntilMeetingStarts(meetingStart: number, now: number): number {
        return meetingStart - now;
    }

    private getMeetingCardIdPath(
        account: Account,
        accountId: string,
        threadId: string,
        cardId: string,
    ): Observable<string[]> {
        const isInternalChat = this.isInternalChat(account, threadId);

        if (isInternalChat) {
            const path = ["accounts", accountId, "internal-chat", "activity", "cards", cardId];
            return of(path);
        } else {
            const path = ["timelines", threadId, "cards", cardId];
            return of(path);
        }
    }

    private isInternalChat(account: Account, threadId: string): boolean {
        const internalChatId = account.metadata?.internalChat?.internalChatId;
        return internalChatId === threadId;
    }
}
