<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>

<div class="insights-overview">
    <div class="back">
        <a (click)="goBack()"><i class="las la-angle-left"></i> BACK</a>
    </div>
    <div
        [formGroup]="form"
        class="insights-overview-title"
    >
        <h2>{{ (entity$ | async)?.label }}</h2>
        <div class="metric-result-period">
            <v-select
                [options]="periods$ | async"
                [optionContent]="periodOptionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="selectedPeriod"
            >
            </v-select>
            <ng-template
                #periodOptionContent
                let-option
            >
                {{ option.label }}
            </ng-template>
        </div>
    </div>
</div>

<ng-container *ngIf="report$ | async; let report">
    <div
        class="insights-overview"
        *ngFor="let group of report?.groups"
    >
        <div class="insights-overview-header">
            <div>
                <h3>{{ group.kra.label }}</h3>
            </div>
        </div>

        <div class="insights-overview-widgets">
            <insights-metric-widget
                *ngFor="let row of group?.rows"
                (click)="goToMetric(row?.metric)"
                [tableReportRow]="row"
                [periods]="report?.periods"
            >
            </insights-metric-widget>
        </div>
    </div>
</ng-container>
