<div
    *ngIf="uiCard.card$ | async; let card"
    [class]="'thread-card ' + card.type"
    [ngClass]="{
        'fx-threads-message--odd': (userId$ | async) === card.createdBy,
        'thread-card-loading': uiCard.loader.counter
    }"
>
    <loader-icon
        *ngIf="uiCard.loader.counter"
        color="light"
    ></loader-icon>

    <view-extension
        #viewExtension
        [componentRef]="uiCard.component"
        [injector]="injector"
    ></view-extension>
</div>
