import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { Component, Input, OnDestroy } from "@angular/core";

import { IWorkflowConfiguration } from "@visoryplatform/threads";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { SelectDesignControl } from "../../types/SelectDesignType";
import { Subscription } from "rxjs";

type FormDesignGroup = {
    designId: FormControl<string>;
    workflowConfigurationId: FormControl<string>;
};

type DesignConfig = {
    designId: string;
    workflowConfigurationId: string;
};

type SelectDesignChange = (obj: SelectDesignControl) => void;

@Component({
    selector: "select-workflow-form-legacy",
    templateUrl: "./select-workflow-form-legacy.component.html",
    styleUrls: ["./select-workflow-form-legacy.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectWorkflowFormLegacyComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectWorkflowFormLegacyComponent,
        },
    ],
})
export class SelectWorkflowFormLegacyComponent implements ControlValueAccessor, Validator, OnDestroy {
    @Input() workflowConfigurations?: IWorkflowConfiguration[];
    @Input() workflowDesigns?: IWorkflowDesign[];

    onChange?: (obj: Partial<DesignConfig>) => void;
    onTouch?: () => void;
    validatorFn?: () => void;

    readonly selectPlaceholder = "Select a workflow";
    readonly noWorkflowsPlaceholder = "There is no workflow configured for the selected service";
    formSub: Subscription;

    form = new FormGroup<FormDesignGroup>(
        {
            designId: new FormControl<string>(null),
            workflowConfigurationId: new FormControl<string>(null),
        },
        Validators.required,
    );

    constructor() {
        this.formSub = this.form.valueChanges.subscribe((value) => {
            if (this.onTouch) {
                this.onTouch();
            }

            if (this.onChange) {
                this.onChange(value);
            }
        });
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
    }

    validate(_control: AbstractControl<any, any>): ValidationErrors | null {
        return this.form.valid ? null : { invalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.validatorFn = fn;
    }

    writeValue(obj: DesignConfig): void {
        this.form.patchValue(obj);
    }

    registerOnChange(fn: SelectDesignChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
