<div class="header">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <div class="header-left">
        <div class="header-left-controls">
            <div class="header-left-controls">
                <button
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="changeMonthClicked()"
                >
                    <i class="las la-angle-left"></i>
                </button>

                <div class="header-left-controls-month">
                    <h3>{{ viewDate | date : "MMM YYYY" }}</h3>
                </div>

                <button
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="changeMonthClicked()"
                >
                    <i class="las la-angle-right"></i>
                </button>
            </div>
        </div>

        <div
            class="header-left-filters"
            [formGroup]="calendarFilters"
        >
            <mat-form-field
                appearance="outline"
                placeholder=""
            >
                <mat-select
                    [formControl]="selectedAccounts"
                    placeholder="Select Accounts"
                    [compareWith]="checkSelected"
                    multiple
                    [disableOptionCentering]="true"
                    panelClass="mat-select-panel"
                >
                    <mat-select-trigger>
                        <span>
                            {{
                                allAccountsSelected.selected
                                    ? selectedAccounts?.value?.length - 1
                                    : selectedAccounts?.value?.length
                            }}
                        </span>
                        <span>{{ selectedAccounts?.value?.length > 1 ? " Accounts" : " Account" }}</span>
                    </mat-select-trigger>
                    <mat-option
                        #allAccountsSelected
                        [value]="calendarFilterSelectAlOptions.allAccounts"
                        (click)="toggleSelectAll(filterTypes.account)"
                    >
                        Select All
                    </mat-option>
                    <mat-option
                        *ngFor="let account of accounts$ | async; trackBy: trackAccount"
                        [value]="account.id"
                        (click)="toggleOneItem(filterTypes.account)"
                    >
                        {{ account.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                *ngIf="userRole === roleTypes.Administrator || userRole === roleTypes.Staff"
                appearance="outline"
                placeholder=""
            >
                <mat-select
                    [formControl]="selectedServices"
                    placeholder="Select Services"
                    [compareWith]="checkSelected"
                    multiple
                    [disableOptionCentering]="true"
                    panelClass="mat-select-panel"
                >
                    <mat-select-trigger>
                        <span>
                            {{
                                allServicesSelected.selected
                                    ? selectedServices?.value?.length - 1
                                    : selectedServices?.value?.length
                            }}
                        </span>
                        <span>{{ selectedServices?.value?.length > 1 ? " Services" : " Service" }}</span>
                    </mat-select-trigger>
                    <mat-option
                        #allServicesSelected
                        [value]="calendarFilterSelectAlOptions.allServices"
                        (click)="toggleSelectAll(filterTypes.service)"
                    >
                        Select All
                    </mat-option>
                    <mat-option
                        *ngFor="let service of services$ | async; trackBy: trackService"
                        (click)="toggleOneItem(filterTypes.service)"
                        [value]="service.value"
                    >
                        {{ service.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
