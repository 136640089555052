import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from "@angular/core";
import {
    IThreadCard,
    ITimeline,
    Role,
    WebsocketNotification,
    WebsocketSubjectType,
    CrudTypes,
} from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { Observable, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { IUiCard } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { UiCardService } from "projects/portal-modules/src/lib/threads-ui/services/ui-card.service";
import { ThreadsWebsocketService } from "../../../shared/services/threads-websocket.service";
import { Notification, NotificationState } from "@visoryplatform/notifications-core";
import { ThreadCardService } from "../../services/thread-card.service";
import { ActivityNotificationsService } from "../../../notifications/services/activity-notifications.service";
import { ActivatedRoute } from "@angular/router";
import { UiCardPortalComponent } from "../ui-card-portal/ui-card-portal.component";

type CardKeyGroups = {
    [date: string]: IUiCard[];
};

type CardGroup = {
    date: string;
    cards: IUiCard[];
};

@Component({
    selector: "thread",
    templateUrl: "./thread.component.html",
    styleUrls: ["./thread.component.scss"],
})
export class ThreadComponent implements OnChanges, OnDestroy {
    @Input() threadId: string;
    @Input() thread$: Observable<ITimeline>;
    @Input() role: Role;
    @Input() routeToCardId?: string;
    @Input() excludeCardTypes: string[];
    @Output() loadCardComplete = new EventEmitter<void>();
    @ViewChildren(UiCardPortalComponent) cardItems: QueryList<UiCardPortalComponent>;

    uiCardsByDate: CardGroup[];
    loader = new Loader();
    firstUnreadCardId: string;
    isFirstLoad = true;

    activityNotifications$: Observable<Notification[]>;

    private uiCards: IUiCard[] = [];
    private clearNewTimeout: any;

    private wsSubscription: Subscription;
    private eventSubscription: Subscription;
    private cardRouteSubscription: Subscription;
    private notificationsSub: Subscription;

    constructor(
        private uiCardService: UiCardService,
        private cardService: ThreadCardService,
        private websocketService: ThreadsWebsocketService,
        private activityNotifications: ActivityNotificationsService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.activityNotifications$ = this.activityNotifications.getActivity();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { threadId, routeToCardId } = changes;

        if (threadId?.currentValue) {
            this.isFirstLoad = threadId?.previousValue !== threadId.currentValue;

            this.initThread(threadId.currentValue, this.thread$);

            if (this.notificationsSub) {
                this.notificationsSub.unsubscribe();
            }

            this.notificationsSub = this.activityNotifications$.subscribe((notifications) =>
                this.setFirstUnread(notifications),
            );
        }

        if (routeToCardId && this.uiCards && this.uiCards.length) {
            this.uiCardService.routeToCard(this.uiCards, routeToCardId.currentValue);
        }
    }

    ngOnDestroy(): void {
        if (this.cardRouteSubscription) {
            this.cardRouteSubscription.unsubscribe();
        }

        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }

        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }

        if (this.notificationsSub) {
            this.notificationsSub.unsubscribe();
        }
    }

    trackId(_index: number, data: IUiCard): string {
        return data.cardId;
    }

    trackGroup(_index: number, group: CardGroup): string {
        return group.date;
    }

    async regroupCards(): Promise<void> {
        await this.recheckFirstUnread();
    }

    handleContentSizeChange(): void {
        const { cardId } = this.activatedRoute.snapshot.firstChild?.params || this.activatedRoute.snapshot.params;

        if (typeof cardId === "string") {
            this.scrollToCard(cardId);
        }
    }

    private scrollToCard(cardId: string): void {
        const cardComponentInstance = this.cardItems.find(({ uiCard }) => uiCard.cardId === cardId);

        cardComponentInstance?.elementRef?.nativeElement.scrollIntoView({
            block: "center",
        });
    }

    private async loadCards(threadId: string, thread$: Observable<ITimeline>): Promise<void> {
        if (!this.isFirstLoad) {
            return;
        }

        this.uiCards = [];
        this.loader.show();

        const threadCards = await this.cardService.getCards(this.threadId).toPromise();
        const filteredCards = this.excludeCardTypes
            ? threadCards.filter((card) => !this.excludeCardTypes.includes(card.type))
            : threadCards;

        for (const card of filteredCards.reverse()) {
            this.loadCard(threadId, thread$, card);
        }

        if (this.routeToCardId) {
            this.uiCardService.routeToCard(this.uiCards, this.routeToCardId);
        }

        this.uiCardsByDate = this.groupCardsByDate(this.uiCards);
        await this.recheckFirstUnread();

        this.isFirstLoad = false;

        this.loader.hide();
    }

    private async loadCard(threadId: string, thread$: Observable<ITimeline>, card: IThreadCard): Promise<void> {
        const uiCard = this.uiCardService.mapCard(threadId, thread$, card, this.role);
        if (!uiCard || this.uiCards.find((c) => c.cardId === uiCard.cardId)) {
            return;
        }

        this.uiCards.push(uiCard);
        this.loadCardComplete.emit();
    }

    private async handleCardCreated(
        threadId: string,
        notification: WebsocketNotification,
        thread$?: Observable<ITimeline>,
    ): Promise<void> {
        const { threadId: notificationThreadId, cardId } = notification;
        if (notificationThreadId !== threadId) {
            return console.error("Received event for different thread", notificationThreadId, threadId);
        }

        const card = await this.cardService.getCard(notificationThreadId, cardId).toPromise();
        this.loadCard(threadId, thread$, card);

        this.uiCardsByDate = this.groupCardsByDate(this.uiCards);
        await this.recheckFirstUnread();
    }

    private async initThread(threadId: string, thread$: Observable<ITimeline>): Promise<void> {
        await this.loadCards(threadId, thread$);

        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }
        this.wsSubscription = this.websocketService
            .watchThreadId(threadId)
            .pipe(
                filter((notification) => !notification.state),
                filter((notification) => !!notification.cardId),
                filter(
                    (notification) =>
                        notification.eventType === CrudTypes.Created &&
                        notification.subjectType === WebsocketSubjectType.Card,
                ),
            )
            .subscribe((notification) => this.handleCardCreated(threadId, notification, thread$));
    }

    private groupCardsByDate(uiCards: IUiCard[]): CardGroup[] {
        // sort the cards on first load only for a given thread
        const sortedCards = this.isFirstLoad ? uiCards.sort((a, b) => this.uiCardService.compareCards(a, b)) : uiCards;
        const uiCardsByDate = sortedCards.reduce(
            (groups, card) => this.reduceByDate(groups, card),
            {} as CardKeyGroups,
        );

        return Object.entries(uiCardsByDate).map(([date, cards]) => ({ date, cards }));
    }

    private reduceByDate(groups: CardKeyGroups, uiCard: IUiCard): CardKeyGroups {
        const date = this.formatMillis(uiCard.timestamp);

        if (!groups[date]) {
            groups[date] = [];
        }

        groups[date].push(uiCard);
        return groups;
    }

    private async recheckFirstUnread(): Promise<void> {
        const notifications = await this.activityNotifications$.pipe(take(1)).toPromise();
        this.setFirstUnread(notifications);
    }

    private setFirstUnread(notifications: Notification[]): void {
        const threadId = this.threadId;

        const unreadNotifications = notifications.filter(
            (notification) => notification.state === NotificationState.Delivered,
        );

        const firstCard = this.uiCards.find((uiCard) =>
            unreadNotifications.some((notification) =>
                notification.channel.endsWith(`${threadId}/cards/${uiCard.cardId}`),
            ),
        );

        if (this.clearNewTimeout) {
            clearTimeout(this.clearNewTimeout);
        }

        if (firstCard) {
            this.firstUnreadCardId = firstCard.cardId;
        } else {
            this.clearNewTimeout = setTimeout(() => (this.firstUnreadCardId = null), 10000);
        }
    }

    private formatMillis(millis: number): string {
        return DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_HUGE);
    }
}
