<centered-modal-layout
    (save)="close()"
    (close)="close()"
    [cancelButtonTitle]="null"
    saveButtonTitle="Close"
    analyticsPrefix="download-processing"
>
    <div modal-header>Download Recording</div>
    <div modal-subhead>Your recording is not available yet. Try again shortly.</div>
</centered-modal-layout>
