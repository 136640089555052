<div
    class="fx-error-modal-dialog-content"
    *ngIf="isModal; else errorRoute"
>
    <div class="fx-error-modal-dialog-image"></div>
    <h1>{{ errorTitle }}</h1>
    <p>{{ errorMessage }}</p>
    <button
        class="fx-onboarding-button fx-btn"
        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
        (click)="modalButtonClick.emit()"
    >
        {{ buttonTitle }}
    </button>
</div>
<ng-template #errorRoute>
    <div class="fx-onboarding">
        <div class="fx-onboarding-container">
            <div class="fx-onboarding-content">
                <portal-brand type="onboarding"></portal-brand>
                <h1 class="fx-onboarding-title">{{ errorTitle }}</h1>
                <p class="fx-onboarding-body">{{ errorMessage }}</p>
                <div *ngIf="buttonTitle">
                    <button
                        class="fx-onboarding-button fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        (click)="buttonClick(buttonTitle)"
                    >
                        {{ buttonTitle }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
