import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { IntegrationsPlugin } from "./integrations.plugin";
import { IntegrationsSettingsComponent } from "./components/integrations-settings/integrations-settings.component";
import { IntegrationsAccountComponent } from "./components/integrations-account/integrations-account.component";
import { TenantSelectComponent } from "./components/tenant-select/tenant-select.component";
import { IntegrationsService } from "./services/integrations.service";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FxUiModule,
        ThreadsUiModule,
        FindexUiModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ],
    exports: [],
    declarations: [IntegrationsSettingsComponent, IntegrationsAccountComponent, TenantSelectComponent],
    providers: [{ provide: PLUGIN_FACTORY, useClass: IntegrationsPlugin, multi: true }, IntegrationsService],
})
export class IntegrationsModule {}
