import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { FxTodoListComponent } from "./components/fx-todolist/fx-todolist.component";
import { FxTodoListItemComponent } from "./components/fx-todolist-item/fx-todolist-item.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [FxTodoListComponent, FxTodoListItemComponent],
    exports: [FxTodoListComponent, FxTodoListItemComponent],
    imports: [
        CommonModule,
        FxUiModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ],
})
export class FxTodoListModule {}
