import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AppUser } from "../model/AppUser";

const redirectUrls = ["/register/signup", "/register/email-success"];

export const noAuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean> => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const getRedirectUrl = (user: AppUser, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string => {
        if (redirectUrls.includes(state.url) && !user.mobileNumberVerified) {
            return "/register/verify-mobile";
        }

        if (route.queryParams && route.queryParams.redirect) {
            return route.queryParams.redirect;
        }

        // redirect to dashboard for all logged in users
        return "/dashboard";
    };

    return authService.getUserWithoutRole().pipe(
        switchMap((user: AppUser) => {
            if (!user) {
                return of(true);
            }

            const redirectUrl = getRedirectUrl(user, route, state);
            router.navigateByUrl(redirectUrl);

            return of(false);
        }),
    );
};
