import { AppendCommaPipe } from "./pipes/append-comma.pipe";
import { AssigneeVariationsService } from "./services/assignee-variations.service";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SlaVariationsService } from "./services/sla-variations.service";
import { WorkflowVariationConfigTokenLabelsPipe } from "./pipes/workflow-variation-config-token-labels.pipe";
import { WorkflowVariationStepLabelPipe } from "./pipes/workflow-variation-step-label.pipe";
import { WorkflowVariationTokenToLabelPipe } from "./pipes/workflow-variation-token-to-label.pipe";
import { WorkflowVariationsService } from "./services/workflow-variations.service";

@NgModule({
    imports: [CommonModule],
    exports: [
        WorkflowVariationConfigTokenLabelsPipe,
        WorkflowVariationStepLabelPipe,
        WorkflowVariationTokenToLabelPipe,
        AppendCommaPipe,
    ],
    declarations: [
        WorkflowVariationConfigTokenLabelsPipe,
        WorkflowVariationStepLabelPipe,
        WorkflowVariationTokenToLabelPipe,
        AppendCommaPipe,
    ],
    providers: [WorkflowVariationsService, AssigneeVariationsService, SlaVariationsService],
})
export class WorkflowVariationsModule {}
