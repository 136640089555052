<div class="integrations-account">
    <div class="integrations-account-logo">
        <img
            src="{{ integration.logoUrl }}"
            alt="{{ integration.name }} logo"
        />
    </div>

    <div class="integrations-account-details">
        <div class="integrations-account-name">{{ integration.name }}</div>

        <button
            *ngIf="strategy === strategies.Default"
            (click)="onConnectIntegration()"
            type="button"
            class="fx-btn fx-btn--primary integrations-account-connect-button"
            [disabled]="!enabled"
        >
            Connect
        </button>

        <ng-container *ngIf="strategy === strategies.Select">
            <tenant-select
                [tenants]="tenants$ | async"
                [loading]="loading || loader.counter > 0"
                [enabled]="enabled"
                (selectTenant)="$event ? onSelectTenant($event) : onDisconnectIntegration()"
            ></tenant-select>
        </ng-container>

        <ng-container *ngIf="strategy === strategies.Connected">
            <div class="integrations-account-business">
                {{ connectedBusiness }}
            </div>
            <div class="integrations-account-connected-label">
                <i class="las la-check"></i>
                Connected
            </div>
            <button
                (click)="onDisconnectIntegration()"
                type="button"
                class="integrations-account-disconnect-button"
                [disabled]="!enabled"
            >
                Disconnect
            </button>
        </ng-container>
    </div>
</div>
