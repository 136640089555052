import { CdkStepper } from "@angular/cdk/stepper";
import { Component, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { StepperStepComponent } from "../step/stepper-step.component";

@Component({
    selector: "stepper-layout",
    templateUrl: "stepper-layout.component.html",
    styleUrls: ["stepper-layout.component.scss"],
    providers: [{ provide: CdkStepper, useExisting: StepperLayoutComponent }],
})
export class StepperLayoutComponent extends CdkStepper {
    @Input() endStepLabel?: string;
    @Input() disabled?: boolean;
    @Input() disabledNext?: boolean;

    @Output() completed = new EventEmitter<CdkStepper>();
    @Output() cancel = new EventEmitter<CdkStepper>();

    override steps: QueryList<StepperStepComponent>;

    handleClickNext(stepper: CdkStepper): void {
        const currentStep = this.steps?.get(this.selectedIndex);

        currentStep?.next.emit(stepper);

        if (!currentStep?.disableNextOnClick) {
            stepper.next();
        }
    }

    handleClickBack(stepper: CdkStepper): void {
        const currentStep = this.steps?.get(this.selectedIndex);
        currentStep?.previous.emit(stepper);

        stepper.previous();
    }
}
