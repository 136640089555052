import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { OpenMeasuresService } from "../../services/open-measures.service";
import { Entity, LatestTableData, Metric, Period } from "@visoryplatform/openmeasures-core";
import { Observable } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { Location } from "@angular/common";
import { FormControl, FormGroup } from "@angular/forms";
import { LATEST_PERIOD_ID } from "../../interfaces/IInsightsReportGroup";

@Component({
    selector: "app-insights-overview",
    templateUrl: "./insights-overview.component.html",
    styleUrls: ["./insights-overview.component.scss"],
})
// rename to scorecard
export class InsightsOverviewComponent implements OnInit {
    form: FormGroup;
    loader = new Loader();
    periods$: Observable<Period[]>;
    report$: Observable<LatestTableData>;
    entity$: Observable<Entity>;
    enterpriseId: string;
    entityId: string;
    selectedPeriod = new FormControl<Period | null>(null);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private openMeasures: OpenMeasuresService,
        private location: Location,
    ) {
        this.form = new FormGroup({
            selectedPeriod: this.selectedPeriod,
        });
    }

    ngOnInit(): void {
        const latestPeriod = { id: LATEST_PERIOD_ID, label: "Latest", enterpriseId: null, start: null, end: null };

        this.enterpriseId = this.route.snapshot?.params?.enterpriseId;
        this.entityId = this.route.snapshot?.params?.entityId;
        this.periods$ = this.loader.wrap(
            this.openMeasures.getPeriods(this.enterpriseId).pipe(
                map((periods) => periods.reverse()),
                map((periods) => [latestPeriod, ...periods]),
                shareReplay(1),
            ),
        );

        this.entity$ = this.loader.wrap(this.openMeasures.getEntities(this.enterpriseId)).pipe(
            switchMap((entities) => entities),
            filter((entity) => entity.id == this.entityId),
        );

        this.report$ = this.periods$.pipe(
            take(1),
            map((periods) => periods.find((period) => !!period)),
            tap((period) => this.selectedPeriod.setValue(period)),
            switchMap((period) => {
                return this.selectedPeriod.valueChanges.pipe(
                    startWith(period),
                    switchMap((period) =>
                        this.loader.wrap(this.getLatestTableData(this.enterpriseId, this.entityId, period.id)),
                    ),
                );
            }),
        );
    }

    goToMetric(metric: Metric): void {
        this.navigateTo("metric/" + metric?.id + "/period/" + this.selectedPeriod.value.id);
    }

    navigateTo(path: string): void {
        this.router.navigate([`./${path}`], { relativeTo: this.route });
    }

    goBack(): void {
        this.location.back();
    }

    getLatestTableData(enterpriseId: string, entityId: string, periodId: string): Observable<LatestTableData> {
        if (periodId === LATEST_PERIOD_ID) {
            return this.openMeasures.getMetrics(enterpriseId, entityId);
        } else {
            return this.getReportsByPeriod(enterpriseId, entityId, periodId);
        }
    }

    getReportsByPeriod(enterpriseId: string, entityId: string, periodId: string): Observable<LatestTableData> {
        const report$ = this.openMeasures.getReportsByPeriod(enterpriseId, periodId, entityId);
        return report$.pipe(map((report) => ({ ...report, periods: [report.period] })));
    }
}
