<div
    class="notification"
    [ngClass]="{
        'notification--unread': notification.state === NotificationState.Delivered
    }"
>
    <div
        class="notification-body"
        (click)="open.emit()"
    >
        <div class="notification-body-menu">
            <fx-context-menu
                #menu
                [gutter]="false"
            >
                <a
                    *ngIf="notification.state === NotificationState.Resolved; else markAsRead"
                    (click)="markUnreadClick()"
                >
                    <i class="las la-eye-slash"></i>
                    Mark as unread
                </a>

                <ng-template #markAsRead>
                    <a (click)="markReadClick()">
                        <i class="las la-eye"></i>
                        Mark as read
                    </a>
                </ng-template>

                <a (click)="deleteClick()">Delete</a>
            </fx-context-menu>
        </div>

        <div class="notification-body-header">
            <div
                *ngIf="
                    notification.deliveryData?.metadata?.threadName || notification.deliveryData?.metadata?.accountName
                "
                class="notification-body-header-info"
            >
                <span
                    class="notification-thread-name"
                    *ngIf="notification.deliveryData?.metadata?.threadName"
                >
                    {{ notification.deliveryData.metadata.threadName }}
                </span>
                <span
                    *ngIf="notification.deliveryData?.metadata?.accountName"
                    class="notification-divider"
                    >|</span
                >
                <span
                    class="notification-account-name"
                    *ngIf="notification.deliveryData?.metadata?.accountName"
                >
                    {{ notification.deliveryData.metadata.accountName }}
                </span>
            </div>
        </div>

        <div
            class="notification-body-description"
            (click)="open.emit()"
        >
            <fx-avatar
                size="medium"
                [image]="notification.avatarImage"
            ></fx-avatar>

            <div class="notification-body-description-info">
                <div class="notification-body-description-info-message">
                    <div
                        [ngClass]="{
                            'notification-label--single-line':
                                notification.deliveryData?.metadata?.threadName ||
                                notification.deliveryData?.metadata?.accountName
                        }"
                        class="notification-label"
                        [innerHTML]="notification.label"
                    ></div>
                    <div class="notification-controls-time-ago">
                        <span>{{ notification.createdAt | timeAgo }}</span>
                    </div>
                </div>
                <div
                    *ngIf="notification.deliveryData?.metadata?.body"
                    class="notification-summary"
                >
                    {{ notification.deliveryData.metadata.body }}
                </div>
            </div>
        </div>
    </div>
</div>
