import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ThreadStatus } from "@visoryplatform/threads";
import { environmentCommon } from "src/environments/environment";
import { IWorkflow, SystemStepId } from "@visoryplatform/workflow-core";
import { WorkflowService } from "../../../threads-ui/services/workflow/workflow.service";
import { VBadgeColors } from "@visoryplatform/portal-ui";

@Component({
    selector: "status-badge",
    templateUrl: "./status-badge.component.html",
    styleUrls: ["./status-badge.component.scss"],
})
export class StatusBadgeComponent implements OnChanges {
    @Input() workflow: IWorkflow;

    readonly states = environmentCommon.workflow.states;
    readonly badgeColors = VBadgeColors;
    status: ThreadStatus;

    constructor(private workflowService: WorkflowService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { workflow } = changes;
        if (workflow && workflow.currentValue) {
            const updatedWorkflow = workflow.currentValue;
            const currentStep = this.workflowService.getCurrentStep(updatedWorkflow);
            const currentStatus = currentStep?.id;

            switch (currentStatus) {
                case SystemStepId.End:
                    this.status = ThreadStatus.closed;
                    break;
                case SystemStepId.Cancelled:
                    this.status = ThreadStatus.cancelled;
                    break;
                default:
                    this.status = ThreadStatus.active;
                    break;
            }
        }
    }
}
