<list-notifications
    *ngIf="showMenu"
    [notifications]="notifications$ | async"
    [currentFilter]="currentFilter.value"
    [showUnreadOnly]="showUnreadNotificationsOnly"
    (getNextPage)="getNextActivityPage()"
    (open)="openNotification($any($event))"
    (markAllAsRead)="markAllNotificationsAsRead()"
    (markAsUnread)="recordEvent('unread')"
    (deleteMessage)="recordEvent('delete')"
    (markAsRead)="recordEvent('read')"
    (settingsNavigate)="navigateToSettings()"
    (filterChange)="setCurrentFilter($event)"
    [unreadNotificationCount]="unreadNotificationCount"
    class="notification-list"
>
</list-notifications>
