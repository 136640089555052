import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
    selector: "request-response",
    styleUrls: ["request-response.component.scss"],
    templateUrl: "request-response.component.html",
})
export class RequestResponseComponent implements OnChanges {
    @Input() modifiedBy: string;
    @Input() modifiedAt: string;
    @Input() message: string;
    @Input() userId: string;
    @Input() readonly = false;
    @Output() valueChanges = new EventEmitter<string>();
    @Output() discardChanges = new EventEmitter<string>();
    @Output() formSubmitResponse = new EventEmitter<string>();
    @Output() formDeleteResponse = new EventEmitter();

    showCompose = true;
    editResponse = new UntypedFormControl("");
    quillError: boolean;
    modifiedByCurrentUser: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.message?.currentValue) {
            this.editResponse.setValue(changes.message.currentValue);
            this.showCompose = false;
        }
        if (changes?.modifiedBy?.currentValue === this.userId) {
            this.modifiedByCurrentUser = true;
        }
    }

    setResponse(control: UntypedFormControl): void {
        this.editResponse = control;
        this.valueChanges.emit(this.editResponse?.value);
    }

    public discard(): void {
        this.editResponse.setValue(this.message);
        this.showCompose = false;
        this.discardChanges.emit();
    }

    submitResponse(): void {
        this.message = this.getResponseValue();
        this.showCompose = false;
        this.modifiedBy = this.userId;
        this.modifiedAt = new Date().toISOString();
        this.formSubmitResponse.emit(this.getResponseValue());
        this.quillError = null;
    }

    getResponseValue(): string {
        return this.editResponse.value;
    }

    editMessage(): void {
        this.showCompose = !this.showCompose;
    }

    deleteMessage(): void {
        this.showCompose = true;
        this.formDeleteResponse.emit();
    }
}
