import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "participant-list",
    templateUrl: "./participant-list.component.html",
    styleUrls: ["./participant-list.component.scss"],
})
export class ParticipantListComponent {
    @Input() showInvite = false;
    @Input() showLoader = false;
    @Input() participants: IParticipant[];
    @Output() selectParticipant: EventEmitter<IParticipant> = new EventEmitter();
    @Output() inviteClicked: EventEmitter<boolean> = new EventEmitter();

    appName: string;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {
        this.appName = this.environment.appName;
    }

    invite() {
        this.inviteClicked.emit(true);
    }
}
