import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ICalendarParticipant, IParticipant, Role } from "@visoryplatform/threads";
import { isEqual } from "lodash-es";
import {
    EnvironmentSpecificConfig,
    ICalendarMeetingDuration,
    defaultCalendarMeetingDurations,
    environmentCommon,
} from "projects/portal-modules/src/lib/environment/environment.common";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { SubscriberBaseComponent } from "../../../../portal-modules/src/lib/shared/components/subscriber-base.component";
import { IMeetingRequestDetails } from "../../interfaces/IMeetingRequestDetails";
import { ReccurrenceOptions } from "../../interfaces/ReccurrenceOptions";
import { CalendarModalService } from "../../services/calendar-modal.service";

export type IFormCreateInvitation = {
    title: FormControl<string>;
    numberOfOccurrences: FormControl<number>;
    meetingDescription: FormControl<string>;
    recurrenceType: FormControl<ReccurrenceOptions>;
};

@Component({
    selector: "calendar-create-invitation",
    templateUrl: "./create-invitation.component.html",
    styleUrls: ["./create-invitation.component.scss"],
})
export class CreateInvitationComponent extends SubscriberBaseComponent implements OnChanges {
    @Input() participants: IParticipant[] = [];
    @Input() showValidationErrors = false;
    @Input() meetingDetails?: IMeetingRequestDetails;
    @Input() editAttendees: boolean;

    @Output() emitDetails = new EventEmitter<IMeetingRequestDetails>();

    readonly attendeeValidationError =
        "Invitation must have a minimum of 2 invitees and must include a staff and client";
    readonly attendeeValidationNonClientError = "Invitation must have a minimum of 2 invitees";
    readonly theme = this.environment.theme;
    readonly timeSlots: ICalendarMeetingDuration[] =
        this.environment.featureFlags.calendarMeetingDurations || defaultCalendarMeetingDurations;
    readonly recurrenceOptions = [
        { value: "never", name: "Never" },
        { value: "weekly", name: "Weekly" },
        { value: "fortnightly", name: "Fortnightly" },
        { value: "monthly", name: "Every 4 weeks" },
    ];
    readonly textAreaCharacterLimit = environmentCommon.textareaCharacterLimit;

    attendees: ICalendarParticipant[] = [];
    currentUserId: string;
    form: FormGroup<IFormCreateInvitation>;
    globalRoles = Role;
    isValidOccurrence = false;
    loader = new Loader();
    meetingDuration =
        this.meetingDetails?.duration ?? this.timeSlots.find((timeSlot) => timeSlot.defaultSelection)?.durationMinutes;
    quillError = false;
    userRole: Role;
    invalidAttendees: ICalendarParticipant[];
    hasClientsInThread: boolean;

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private calendarModalService: CalendarModalService,
    ) {
        super();
        this.setForm();
        this.setValidOccurrence();
        this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.setValidOccurrence();
            this.emitChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.participants?.currentValue) {
            this.hasClientsInThread = this.calendarModalService.hasClientParticipant(this.participants);
            this.getCurrentUser()
                .pipe(take(1))
                .subscribe((currentUser: AppUser) => {
                    this.currentUserId = currentUser.id;
                    this.userRole = currentUser.globalRole;

                    if (!this.meetingDetails?.attendees?.length) {
                        this.setInitialAttendee();
                    } else {
                        this.attendees = this.meetingDetails.attendees;
                        this.invalidAttendees = this.meetingDetails.invalidAttendees;
                    }
                });
        }

        if (changes?.meetingDetails?.currentValue) {
            const { title, duration, recurrenceType, numberOfOccurrences, meetingDescription } = this.meetingDetails;
            const update = {
                title: title || "",
                recurrenceType: recurrenceType || "never",
                numberOfOccurrences: numberOfOccurrences || 2,
                meetingDescription: meetingDescription || "",
            };

            if (!isEqual(update, this.form.value)) {
                this.meetingDuration = duration;
                this.form.setValue(update);
            }
        }
    }

    emitChanges(): void {
        this.emitDetails.emit({
            ...this.form.getRawValue(),
            attendees: this.attendees,
            duration: this.meetingDuration,
            organiser: this.currentUserId,
        });
    }

    setInitialAttendee(): void {
        this.attendees = this.participants
            .filter((participant) => participant.id === this.currentUserId)
            .map((participant) => ({ ...participant, required: true }));
    }

    selectedParticipants(participants: IParticipant[]): void {
        this.attendees = participants;
        this.emitDetails.emit({
            ...this.form.getRawValue(),
            attendees: this.attendees,
            duration: this.meetingDuration,
            organiser: this.currentUserId,
        });
    }

    private getCurrentUser(): Observable<AppUser> {
        return this.authService.getUser();
    }

    private setForm(): void {
        const initialValues: Partial<IMeetingRequestDetails> = {
            title: this.meetingDetails?.title ?? "",
            recurrenceType: this.meetingDetails?.recurrenceType ?? "never",
            numberOfOccurrences: this.meetingDetails?.numberOfOccurrences ?? 10,
            meetingDescription: this.meetingDetails?.meetingDescription ?? "",
        };

        // Form controls
        this.form = new FormGroup<IFormCreateInvitation>({
            title: new FormControl(initialValues.title, [Validators.required]),
            recurrenceType: new FormControl(initialValues.recurrenceType, [Validators.required]),
            numberOfOccurrences: new FormControl(initialValues.numberOfOccurrences, []),
            meetingDescription: new FormControl(initialValues.meetingDescription, [
                Validators.required,
                Validators.maxLength(this.textAreaCharacterLimit),
            ]),
        });
    }

    private setValidOccurrence(): void {
        const { recurrenceType, numberOfOccurrences } = this.form.getRawValue();
        const isValid = recurrenceType === "never" || (numberOfOccurrences > 1 && numberOfOccurrences <= 10);
        this.isValidOccurrence = isValid;
    }
}
