import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { ICalendarParticipant } from "@visoryplatform/threads";

export enum AttendeeType {
    Required = "required",
    Optional = "optional",
}

//TODO: this component should be generic, single picklist, not the calendar one. Required participants shouldn't be referenced at all, a calendar picklist can use 2xpicklist to get required
@Component({
    selector: "participant-picker",
    templateUrl: "./participant-picker.component.html",
    styleUrls: ["./participant-picker.component.scss"],
})
export class ParticipantPickerComponent implements OnChanges {
    @Input() participants: ICalendarParticipant[];
    @Input() placeholderText = "Add participants";
    @Input() selectedParticipants?: ICalendarParticipant[] = [];
    @Input() invalidParticipants?: ICalendarParticipant[] = [];
    @Input() disableRequired = false;
    @Input() disableOptional = false;
    @Input() enableOptionalParticipants = false;
    @Output() selectedParticipantOutput: EventEmitter<ICalendarParticipant[]> = new EventEmitter<
        ICalendarParticipant[]
    >();

    readonly USER_NOT_IN_THREAD_EROR = "These participants are not in this workflow and will be removed on update";

    showOptionalParticipantList = false;
    showRequiredParticipantList = false;
    unselectedParticipants: ICalendarParticipant[] = [];
    filteredRequiredParticipants: ICalendarParticipant[] = [];
    filteredOptionalParticipants: ICalendarParticipant[] = [];
    filterRequiredString = "";
    filterOptionalString = "";
    attendeeTypes = AttendeeType;

    constructor(private el: ElementRef) {}

    @HostListener("document:click", ["$event"])
    click(event: MouseEvent) {
        if (!this.el.nativeElement.contains(event.target)) {
            this.showRequiredParticipantList = false;
            this.showOptionalParticipantList = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const { participants } = changes;

        if (participants && participants.currentValue) {
            this.unselectedParticipants = this.participants;
            this.filteredRequiredParticipants = this.participants;
            this.filteredOptionalParticipants = this.participants;
        }

        if (
            this.selectedParticipants &&
            this.unselectedParticipants &&
            this.filteredOptionalParticipants &&
            this.filteredRequiredParticipants
        ) {
            const unselected = this.participants.filter(
                (participant) => !this.selectedParticipants.some((selected) => selected.id === participant.id),
            );
            this.unselectedParticipants = unselected;
            this.filteredRequiredParticipants = unselected.filter((participant) => participant.required !== false);
            this.filteredOptionalParticipants = unselected.filter((participant) => participant.required !== true);
        }
    }

    unselectParticipant(participantId: string) {
        const selectedParticipants = this.removeParticipant(participantId, this.selectedParticipants);
        const unselectedParticipant = this.participants.find((participant) => participant.id === participantId);

        this.unselectedParticipants.push(unselectedParticipant);
        this.filteredRequiredParticipants.push(unselectedParticipant);
        this.filteredOptionalParticipants.push(unselectedParticipant);
        this.selectedParticipantOutput.emit(selectedParticipants);
    }

    removeParticipant(participantId: string, participantArray: ICalendarParticipant[]): ICalendarParticipant[] {
        return participantArray.filter((participant) => participant.id !== participantId);
    }

    selectParticipant(participant: ICalendarParticipant, required: boolean) {
        const selectedParticipant = {
            ...participant,
            required,
        };
        this.filterRequiredString = "";
        this.selectedParticipants.push(selectedParticipant);
        this.unselectedParticipants = this.removeParticipant(participant.id, this.unselectedParticipants);
        this.filteredRequiredParticipants = this.removeParticipant(participant.id, this.filteredRequiredParticipants);
        this.filteredOptionalParticipants = this.removeParticipant(participant.id, this.filteredOptionalParticipants);
        this.selectedParticipantOutput.emit(this.selectedParticipants);
        this.closeParticipantLists();
    }

    closeParticipantLists() {
        this.showRequiredParticipantList = false;
        this.showOptionalParticipantList = false;
    }

    filterTextUpdated(event: string, type: AttendeeType) {
        const searchTerm = event.toLowerCase();
        switch (type) {
            case AttendeeType.Optional:
                this.filterOptionalString = event;
                this.filteredOptionalParticipants = this.unselectedParticipants.filter((participant) =>
                    participant.profile?.name.toLocaleLowerCase().includes(searchTerm),
                );
                return;
            case AttendeeType.Required:
                this.filterRequiredString = event;
                this.filteredRequiredParticipants = this.unselectedParticipants.filter((participant) =>
                    participant.profile?.name.toLocaleLowerCase().includes(searchTerm),
                );
                return;
            default:
                return;
        }
    }

    toggleRequiredParticipantList() {
        this.showOptionalParticipantList = false;
        this.showRequiredParticipantList = !this.showRequiredParticipantList;
    }

    toggleOptionalParticipants() {
        this.enableOptionalParticipants = !this.enableOptionalParticipants;
    }

    toggleOptionalParticipantList() {
        this.showRequiredParticipantList = false;
        this.showOptionalParticipantList = !this.showOptionalParticipantList;
    }

    isRequiredParticipantSelected(): boolean {
        const requiredParticipant = this.selectedParticipants.find((participant) => participant.required === true);
        const hasEnoughRequiredParticipants = this.selectedParticipants.length >= 2;

        return requiredParticipant && hasEnoughRequiredParticipants;
    }

    isOptionalParticipantSelected() {
        return this.selectedParticipants.find((participant) => participant.required === false);
    }
}
