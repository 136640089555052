<div [formGroup]="form">
    <ng-container *ngIf="!requiresCustomMonth">
        <div>
            <label class="fx-form-label">Workflow title:</label>
            <div class="fx-form-flex">
                <input
                    class="fx-form-input title-selector"
                    placeholder="Enter workflow title..."
                    formControlName="title"
                    [readonly]="!selectedDesignType?.titleTemplate"
                    required
                />
            </div>
        </div>
        <div
            *ngIf="form.controls.title.dirty && !form.controls.title.valid"
            class="fx-form-validation"
        >
            Please enter a workflow title less than {{ CUSTOM_TITLE_MAX_LENGTH }} characters.
            <em class="fx-form-validation-icon las la-exclamation-triangle"></em>
        </div>
    </ng-container>

    <ng-container *ngIf="requiresCustomMonth">
        <label class="fx-form-label">Month:</label>

        <div class="v-select-container">
            <v-select
                placeholder="Select a month"
                [options]="workflowMonths"
                [optionContent]="optionContent"
                [tuiTextfieldCleaner]="false"
                formControlName="title"
                required
            >
            </v-select>

            <ng-template
                #optionContent
                let-option
            >
                {{ option }}
            </ng-template>
        </div>
        <div
            *ngIf="form.controls.title.dirty && !form.controls.title.valid"
            class="fx-form-validation"
        >
            Please select a month for this workflow.
            <em class="fx-form-validation-icon las la-exclamation-triangle"></em>
        </div>
    </ng-container>
</div>
