<div class="summary">
    <div class="summary-column">
        <div class="summary-column-title">
            <span>Payment date</span>
        </div>
        <div class="summary-column-value">
            {{ plannedDate | date : "EEE dd MMM, YYYY" }}
        </div>
    </div>
    <div class="summary-column">
        <div class="summary-column-title">
            <span>Total bills</span>
        </div>
        <div
            data-hj-suppress
            class="summary-column-value"
        >
            {{ totalBills | currency : currencyCode.toUpperCase() }}
        </div>
    </div>
    <div class="summary-column">
        <div class="summary-column-title">
            <span>Total approved bills</span>
        </div>
        <div
            data-hj-suppress
            class="summary-column-value"
        >
            {{ totalApprovedBills | currency : currencyCode.toUpperCase() }}
        </div>
    </div>
</div>
