<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="thread$ | async as thread">
        <request
            *ngIf="state$ | async as state"
            (save)="updateRequestState(card, thread, state)"
            (close)="close()"
            [loading]="!!loader?.counter"
            [cancelButtonTitle]="null"
            [completedStatusLabel]="requestStatuses.Approved"
            [subtitle]="thread.title | titlecase"
            [accountName]="thread.account.label"
            [disableSave]="!form.valid"
            [saveButtonTitle]="!form.dirty ? buttonLabel.Close : buttonLabel.SaveClose"
            [analyticsPrefix]="
                actionedPercentage === 100 ? analyticsTags.analyticsEventComplete : analyticsTags.analyticsEventPending
            "
            [card]="card"
            [state]="state"
        >
            <div class="request-common-group request-common-group--narrow">
                <div class="request-common-label request-common-group-header">Note or message</div>
                <request-view-reply
                    [participants]="thread?.participants"
                    [cardDescription]="form.controls.cardDescription"
                    [threadType]="thread.type"
                ></request-view-reply>
            </div>
            <payrun-report-edit-request [state$]="state$"></payrun-report-edit-request>
        </request>
    </ng-container>
</ng-container>
