import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { HttpClient } from "@angular/common/http";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable, of } from "rxjs";
import { RelevantMemories, IPrompt, Memory } from "@visoryplatform/threads";
import { IPaginated } from "@visoryplatform/datastore-types";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class MemoriesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    listPrompts(): Observable<IPrompt[]> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/prompts`;

        return this.http.get<IPrompt[]>(url);
    }

    getThreadRelevantMemories(threadId: string): Observable<RelevantMemories> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/memories/relevant`;

        return this.http.get<RelevantMemories>(url).pipe(
            catchError((err: unknown) => {
                console.error(err);
                return of({
                    summary: "",
                    memories: [],
                });
            }),
        );
    }

    listAccountMemories(accountId: string, next?: string, limit?: number): Observable<IPaginated<Memory>> {
        const url = `${this.environment.threadsEndpoints.base}/accounts/${accountId}/memories`;
        const params = {
            ...(next ? { next } : {}),
            ...(limit ? { limit: limit?.toString() } : {}),
        };
        return this.http.get<IPaginated<Memory>>(url, { params });
    }

    listThreadMemories(threadId: string, next?: string, limit?: number): Observable<IPaginated<Memory>> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/memories`;
        const params = {
            ...(next ? { next } : {}),
            ...(limit ? { limit: limit?.toString() } : {}),
        };
        return this.http.get<IPaginated<Memory>>(url, { params });
    }

    extractThreadMemories(threadId: string): Observable<void> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/memories`;
        return this.http.post<void>(url, {});
    }

    deleteMemory(accountId: string, memoryKey: string): Observable<void> {
        const url = `${this.environment.threadsEndpoints.base}/accounts/${accountId}/memories/${memoryKey}`;
        return this.http.delete<void>(url);
    }
}
