import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "video-call-end-session-modal",
    templateUrl: "./video-call-end-session-modal.component.html",
    styleUrls: ["./video-call-end-session-modal.component.scss"],
})
export class VideoCallEndSessionModalComponent {
    constructor(private dialogRef: MatDialogRef<VideoCallEndSessionModalComponent, boolean>) {}

    endSession() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close();
    }
}
