<div class="app-container">
    <div class="conversation-header">
        <h3 [ngSwitch]="brandingEnabled$ | async">
            <delphi-logo
                *ngSwitchCase="true"
                label="Ask {{ environment.aiName }}"
            ></delphi-logo>
            <span *ngSwitchDefault>Ask</span>
        </h3>
    </div>

    <div class="message-controls">
        <textarea
            class="fx-form-input"
            textareaAutoresize
            placeholder="Ask a question"
            [formControl]="newMessage"
        ></textarea>

        <div class="message-controls-send">
            <button
                *ngIf="{ accountId: accountId$ | async } as context"
                (click)="newChat(newMessage.value, context.accountId)"
                class="fx-btn fx-btn--full-width fx-btn--primary"
                role="button"
            >
                Send
            </button>
        </div>
    </div>

    <div class="messages">
        <h4>Previous chats</h4>

        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>

        <ng-container *ngIf="results$ | async as results">
            <div
                class="conversation-listing"
                *ngFor="let result of results; trackBy: 'id' | trackProperty"
            >
                <a [routerLink]="result.id">{{ result.createdAt | date : "dd/MM/yy, h:mm a" }} - {{ result.label }}</a>
            </div>

            <div
                *ngIf="!results?.length"
                class="conversation-empty"
            >
                <span>No chats. Get started by asking a question</span>
            </div>
        </ng-container>
    </div>
</div>
